import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Link as MuiLink,
} from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import { connect } from "react-redux";
import { Height } from "@mui/icons-material";
import { motion } from "framer-motion";

const pages = [
  {
    page: "Classes",
    path: "/classes",
  },
  {
    page: "Policy",
    path: "/policy",
  },
  {
    page: "Terms and Conditions",
    path: "/conditions",
  },

  {
    page: "Services",
    path: "/services",
  },
  {
    page: "Shop",
    path: "/shop",
  },
  {
    page: "Media",
    path: "/media",
  },
  {
    page: "Education",
    path: "https://education.mooreexotics.com",
  },
  {
    page: "About Us",
    path: "/about",
  },
  {
    page: "Contact Us",
    path: "/contact",
  },
  {
    page: "Pricing",
    path: "/pricing",
  },
];

const Footer = ({ user, backgroundColor }) => {
  return (
    <Box sx={backgroundColor}>
      <FooterWrap
        sx={{
          padding: "0px 0 12px 0",
        }}
      >
        <Container fixed maxWidth={false} sx={{ textAlign: "center" }}>
          <Box className="logo">
            <Link to={"/"}>
              <img
                className="logo-img"
                src="https://res.cloudinary.com/dwezij7u7/image/upload/v1675115795/exoticslogo_w2oi9z.png"
                alt="Moore Exotics logo"
              />
            </Link>
          </Box>

          {/* Desktop Footer */}

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              typography: "body1",
              marginTop: "0px",
              marginBottom: "10px",
              "& .menulinksfooter": {
                color: "#000000",
                textDecorationColor: "white",
                fontSize: 18,
                lineHeight: "0px",
                display: { xs: "none", lg: "block" },
              },
              "& .menulinksfooter:hover": {
                color: "#FFC001",
              },
              "& > :not(style) + :not(style)": {
                ml: 3,
              },
            }}
          >
            {pages.map((page) => (
              <motion.Grid
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <Link
                  key={page.page}
                  to={page.path}
                  className="menulinksfooter"
                >
                  {page.page}
                </Link>
              </motion.Grid>
            ))}
            {user && user.email ? (
              ""
            ) : (
              <motion.Grid
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <Link key="login" to="/auth/login" className="menulinksfooter">
                  Login
                </Link>
              </motion.Grid>
            )}
          </Box>

          {/* Desktop Footer */}
          {/* Mobile Footer  */}

          <Box className="MobileFooterContent" sx={{ textAlign: "center" }}>
            <Box
              className="MobileFooter"
              sx={{ display: { xs: "flex", lg: "none" } }}
            >
              <Grid container columnSpacing={2}>
                <Grid
                  xs={4}
                  sm={4}
                  lg={12}
                  item
                  direction={"column"}
                  sx={{
                    borderRight: "2px solid grey",
                  }}
                >
                  <Box
                    className="Achors"
                    sx={{ fontFamily: "sans-serif", fontStyle: "normal" }}
                  >
                    <Link to={"/classes"}>Classes</Link>
                    <Link to={"about"}>About Us</Link>
                    <Link to={"services"}>Services</Link>
                    <Link to={"/shop"}>Shop</Link>
                    <Link to={"/conditions"}>Conditions</Link>
                  </Box>
                </Grid>
                <Grid
                  xs={4}
                  sm={4}
                  lg={12}
                  item
                  direction={"column"}
                  display={"flex"}
                  justifyContent={"left"}
                  alignItems="left"
                  sx={{
                    textAlign: "left",
                    borderRight: "2px solid grey",
                  }}
                >
                  <Box
                    className="Achors"
                    sx={{ fontFamily: "sans-serif", fontStyle: "normal" }}
                  >
                    <MuiLink
                      href={"https://education.mooreexotics.com"}
                      target="_self"
                    >
                      Education
                    </MuiLink>
                    <Link to={"/contact"}>Contact Us</Link>
                    <Link to={"/pricing"}>Pricing</Link>
                    <Link to={"/policy"}>Policy</Link>

                    {user && user.email ? (
                      ""
                    ) : (
                      <Link
                        key="login"
                        to="/auth/login"
                        className="menulinksfooter"
                      >
                        Login
                      </Link>
                    )}
                  </Box>
                </Grid>

                <Grid
                  xs={4}
                  sm={4}
                  lg={12}
                  item
                  direction={"column"}
                  display={"flex"}
                  justifyContent={"left"}
                  alignItems="left"
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <Box className="socialIcons">
                    <SocialIcons
                      sx={{
                        display: { xs: "flex", lg: "none" },
                      }}
                    >
                      <Typography className="icon">
                        <img
                          alt="facebook"
                          src="https://res.cloudinary.com/dwezij7u7/image/upload/v1674835977/Moore%20Exotics%20Assets/footer/facebook-icon_de0xgu.png"
                        />
                        <Link to="www.google.com" className="icons-link">
                          Facebook
                        </Link>
                      </Typography>
                      <Typography>
                        <img
                          alt="intagram"
                          src="https://res.cloudinary.com/dwezij7u7/image/upload/v1674836042/Moore%20Exotics%20Assets/footer/instagram-icon_xozp3t.png"
                        />
                        <Link
                          to="https://instagram.com/mooreexotics?igshid=MjEwN2IyYWYwYw=="
                          className="icons-link"
                        >
                          Instagram
                        </Link>
                      </Typography>
                      <Typography>
                        <img
                          alt="youtube"
                          src="https://res.cloudinary.com/dwezij7u7/image/upload/v1674836115/Moore%20Exotics%20Assets/footer/youtube-icon_ycent1.png"
                        />
                        <Link
                          to="https://www.youtube.com/channel/UCcv8tPRfz8XW-FD4lePXjww"
                          className="icons-link"
                        >
                          {" "}
                          Youtube
                        </Link>
                      </Typography>
                      <Typography>
                        <img
                          alt="twitter"
                          src="https://res.cloudinary.com/dwezij7u7/image/upload/v1674836173/Moore%20Exotics%20Assets/footer/twitter-icon_qrjqed.png"
                        />
                        <Link to="www.twitter.com" className="icons-link">
                          Twitter
                        </Link>
                      </Typography>
                    </SocialIcons>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* Mobile Footer end  */}

          <SocialIcons
            sx={{
              display: { xs: "none", lg: "flex" },
              justifyContent: "center",
              mb: "0px",
            }}
          >
            <motion.Grid
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <Link
                style={{ width: "30%" }}
                to="www.facebook.com"
                className="icons-link"
              >
                <img
                  alt="facebook logo"
                  src="https://res.cloudinary.com/dwezij7u7/image/upload/v1674835977/Moore%20Exotics%20Assets/footer/facebook-icon_de0xgu.png"
                />
              </Link>
            </motion.Grid>
            <motion.Grid
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.2 }}
            >
              <Link
                style={{ width: "30%" }}
                to="https://instagram.com/mooreexotics?igshid=MjEwN2IyYWYwYw=="
                className="icons-link"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.4 }}
              >
                <img
                  alt="instagram logo"
                  src="https://res.cloudinary.com/dwezij7u7/image/upload/v1674836042/Moore%20Exotics%20Assets/footer/instagram-icon_xozp3t.png"
                  style={{ marginLeft: "10px" }}
                />
              </Link>
            </motion.Grid>
            <motion.Grid
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.6 }}
            >
              <Link
                style={{ width: "30%" }}
                to="https://www.youtube.com/channel/UCcv8tPRfz8XW-FD4lePXjww"
                className="icons-link"
                target="_blank"
              >
                <img
                  alt="youtube logo"
                  src="https://res.cloudinary.com/dwezij7u7/image/upload/v1674836115/Moore%20Exotics%20Assets/footer/youtube-icon_ycent1.png"
                  style={{ marginLeft: "10px" }}
                />
              </Link>
            </motion.Grid>
            <motion.Grid
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.8 }}
            >
              <Link
                style={{ width: "30%" }}
                to="www.twitter.com"
                className="icons-link"
              >
                <img
                  alt="twitter logo"
                  src="https://res.cloudinary.com/dwezij7u7/image/upload/v1674836173/Moore%20Exotics%20Assets/footer/twitter-icon_qrjqed.png"
                  style={{ marginLeft: "10px" }}
                />
              </Link>
            </motion.Grid>
          </SocialIcons>

          <Typography sx={{ mt: 2, fontSize: "10px" }}>
            Copyrights {new Date().getFullYear()} © Moore Exotics. All Rights
            Reserved
          </Typography>
        </Container>
      </FooterWrap>
    </Box>
  );
};

const mapStateToProps = ({ Auth }) => ({
  user: Auth.user,
});

const mapDispatchToProps = () => ({});

const SocialIcons = styled(Box)`
  margin-top: 0px !important;
  width: 24%;
  margin: auto;
  & img {
    margin: 20px auto;
  }
  & .icons-link {
    text-decoration: none !important;
    color: black;
  }
  & .icons-link:hover {
    color: #ffc001;
  }
`;
const FooterWrap = styled(Box)`
  & .logo-img {
    width: 359px;
    height: auto;
  }
  & .socialIcons > div {
    width: 100%;
    flex-direction: column;
  }
  & .socialIcons > div > p {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #000000;
  }
  & .socialIcons > div > p > img {
    margin: auto 0;
    width: 20px;
    margin-right: 10px;
  }
  & .icon {
    margin-top: 0px;
  }
  & .Achors {
    display: flex;
    flex-direction: column;
    align-items: left;
    background-size: 2% 50% !important;
    background-position: 100px 28px !important;
    background-repeat: no-repeat !important;
    justify-content: space-evenly !important;
    padding-left: 2%;
    text-align: left;
    font-style: normal !important;
  }
  & .Achors > a {
    text-decoration: none;
    font-style: normal !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #000000;
  }
  & .Achors > a:hover {
    color: #ffc001 !important;
  }
  @media screen and (max-width: 899px) {
    .logo-img {
      width: 359px;
      height: auto;
    }
    .MobileFooter {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center !important;
      text-decoration: none !important;
    }
    .MobileFooter {
      font-family: "sans-serif";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 32px;
      letter-spacing: 0.05em;
      color: #000000;
      opacity: 0.75;
      position: relative;
    }
    .copyRight {
      font-family: "sans-serif" !important;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 30px;
      letter-spacing: 0.02em;
      text-align: center;
      color: #000000;
      text-decoration: none !important;
    }
    & .Achors > a:hover {
      color: #ffc001;
    }
  }
  @media screen and (max-width: 425px) {
    .logo-img {
      width: 280px;
      height: auto;
    }
  }
  @media screen and (min-width: 1200px) {
    .MobileFooter {
      display: none !important;
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
