import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { AccountBalanceWallet, Menu } from "@mui/icons-material";
import { navItems } from "./SettingItemData";
import { connect } from "react-redux";

const SettingDashBoardDrawer = ({ user }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = (e) => {
    e.preventDefault();
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2, color: "rgb(255, 192, 1)" }}>
        Moore Exotics
      </Typography>

      <List>
        {navItems.map((item) => (
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to={item.path}
              style={{
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <ListItemIcon
                sx={{
                  m: 0,
                  padding: "5px 5px 5px 10px",
                  minWidth: 0,
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: "14px",
                }}
                primary={item.text}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/"
            style={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <ListItemIcon
              sx={{
                m: 0,
                padding: "5px",
                minWidth: 0,
              }}
            >
              <ArrowBackIcon fontSize={"16px"} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: {
                  xs: "14px",
                },
                fontWeight: "bold",
              }}
              primary={"Home"}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  return (
    <Stack
      sx={{
        display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AppBar component="nav" position="sticky" style={{ background: "#ffff" }}>
        <Toolbar direction="row">
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
            }}
          >
            <Menu />
          </IconButton>
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
              },
            }}
          >
            <Link to="/">
              <img
                style={{ width: "30vw" }}
                alt="Exotics logo"
                src="https://res.cloudinary.com/dwezij7u7/image/upload/v1675115795/exoticslogo_w2oi9z.png"
              />
            </Link>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Stack>
  );
};

const mapStateToProps = ({ Auth }) => ({
  user: Auth.user,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingDashBoardDrawer);
