import * as Types from "../types/index";

const preState = {
  user: null,
  responseError: null,
  jwtToken: null,
  isLoggedIn: false,
};

const CategoriesReducer = (state = preState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_USER:
      localStorage.setItem("access_token", payload.token);
      return {
        ...state,
        user: payload.user,
        isLoggedIn: true,
        jwtToken: payload.token,
      };
    case Types.SET_ERROR:
      return {
        ...state,
        responseError: payload.responseError,
      };
    case Types.RESET_ERROR:
      return preState;
    case Types.LOGOUT:
      localStorage.removeItem("access_token");
      return {
        ...preState,
        isLoggedIn: false,
      };
    case Types.RESET_TOKEN:
      localStorage.removeItem("access_token");
      return preState;
    case Types.SET_USER_IS_SUBSCRIBED:
      return {
        ...state,
        user: { ...state.user, is_subscribed: true },
      };
    case Types.SET_USER_BILLING_PORTAL:
      return {
        ...state,
        user: { ...state.user, billingPortalUrl: payload.billingPortalUrl },
      };

    default:
      return state;
  }
};

export default CategoriesReducer;
