import React, { useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { navItems } from "./SettingItemData";
import { AccountBalanceWallet } from "@mui/icons-material";
import { connect } from "react-redux";

const SettingNavList = ({ user }) => {
  const [active, setActive] = useState(navItems[0]);

  return (
    <Box
      sx={{
        width: "100vw",
        maxWidth: "16vw",
        minHeight: "67vh",
        bgcolor: "white",
      }}
    >
      <nav aria-label="meun list">
        <Divider />

        <Box
          sx={{
            display: "flex",
            marginTop: "40px",
          }}
        >
          <List>
            {navItems.map((item) => (
              <ListItem
                sx={{
                  transition: "0.3s all",
                  "&:hover": { color: "rgb(255, 192, 1)" },
                }}
              >
                <ListItemButton
                  component={Link}
                  to={item.path}
                  onClick={() => {
                    setActive(item);
                  }}
                  sx={active === item && { color: "rgb(255, 192, 1)" }}
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    width: "100%",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: "inherit",
                      m: 0,
                      padding: {
                        lg: "5px 5px 5px 10px",
                        md: "2px 2px 0 5px",
                      },
                      minWidth: 0,
                      fontSize: "20px",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{ m: { md: 0 } }}
                    primaryTypographyProps={{
                      fontSize: { md: "12px", lg: "16px", xl: "20px" },
                    }}
                    primary={item.text}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </nav>
    </Box>
  );
};

const mapStateToProps = ({ Auth }) => ({
  user: Auth.user,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SettingNavList);
