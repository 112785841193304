import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import config from "../../config";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { Menu, MenuItem } from "@mui/material";

const TopBanner = ({ user, seller, jwtToken, classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchData, setSearchData] = useState();

  const open = Boolean(anchorEl);

  useEffect(() => {
    const getSearch = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/v1/all_listings`
        );
        const { data: registerRequestData } = registerRequest;
        if (registerRequest.status === 200) {
          console.log("register request", registerRequestData);
          setSearchData(registerRequestData.data);
        }
      } catch ({ response }) {}
    };

    setSearchData([]);
    getSearch();
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ width: "100vh" }}>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {searchData &&
            searchData
              .filter((item) => {
                if (search === "") {
                  return true;
                } else if (
                  item.name.toLowerCase().includes(search.toLowerCase())
                ) {
                  return true;
                }
                return false;
              })
              .map((item) => (
                <MenuItem sx={{ minWidth: "360px" }}>{item.name}</MenuItem>
              ))}
        </Menu>
      </Box>
      <Grid
        pt={{ xs: 8, sm: 10, md: 10 }}
        container
        spacing={2}
        direction={"row"}
        alignItems="center"
        justifyContent="center"
        px={{ xs: 8, sm: 10, md: 10 }}
        pb={{ xs: 8, sm: 10, md: 10 }}
        sx={{ backgroundColor: "black" }}
      >
        <Box
          alignItems="center"
          justifyContent="center"
          sx={{
            textAlign: "center",
            display: {
              xs: "flex",
              sm: "flex",
              md: "none",
              lg: "none",
            },
          }}
        >
          <Grid item xl={6} lg={6} md={6} xs={12} sm={12} direction="column">
            <Box display={"flex"}>
              <Box display={"column"}>
                <Box display={"flex"}>
                  <Box>
                    <img
                      // key={cert.image}
                      // alt={cert.image}
                      src={
                        "https://res.cloudinary.com/dzsz395eb/image/upload/v1692984546/Rectangle_28_lyjffs.png"
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "5px",
                      }}
                    ></img>
                  </Box>
                  <Box
                    ml={{ md: 3, xs: 1 }}
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-end"}
                  >
                    <img
                      // key={cert.image}
                      // alt={cert.image}
                      src={
                        "https://res.cloudinary.com/dzsz395eb/image/upload/v1692984546/Rectangle_32_x2tgm0.png"
                      }
                      style={{
                        width: "80%",
                        height: "70%",
                        borderRadius: "5px",
                      }}
                    ></img>
                  </Box>
                </Box>

                <Box display={"flex"} mt={{ md: 4, xs: 1 }}>
                  <Box
                    flex={0.5}
                    sx={{ flexDirection: "row-reverse", display: "flex" }}
                  >
                    <img
                      // key={cert.image}
                      // alt={cert.image}
                      src={
                        "https://res.cloudinary.com/dzsz395eb/image/upload/v1692984546/Rectangle_34_weprip.png"
                      }
                      style={{
                        width: "100%",
                        height: "80%",
                        borderRadius: "5px",
                      }}
                    ></img>
                  </Box>
                  <Box
                    flex={0.7}
                    ml={{ md: 2, xs: 1 }}
                    display={"flex"}
                    // justifyContent={"flex-start"}
                  >
                    <img
                      // key={cert.image}
                      // alt={cert.image}
                      style={{
                        width: "70%",
                        height: "80%",
                        borderRadius: "5px",
                      }}
                      src={
                        "https://res.cloudinary.com/dzsz395eb/image/upload/v1692985850/photo-1504450874802-0ba2bcd9b5ae_zmzciv.jpg"
                      }
                    ></img>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          xs={12}
          sm={12}
          direction="column"
          sx={{
            height: "100%",
            textAlign: { xs: "center", sm: "center", md: "left" },
          }}
        >
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 2 }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontWeight: "700",
                lineHeight: "1em",
                fontSize: { xs: "20px", sm: "36px", md: "44px", lg: "50px" },
              }}
              component={"span"}
            >
              Selective{" "}
              <Link
                to="/listings"
                style={{
                  color: "black",
                  textDecorationColor: "white",
                }}
              >
                <Typography
                  sx={{
                    // color: "#fff",
                    fontWeight: "700",
                    lineHeight: "1em",
                    fontSize: {
                      xs: "20px",
                      sm: "36px",
                      md: "44px",
                      lg: "50px",
                    },
                    color: "white",
                  }}
                  component={"span"}
                >
                  {" "}
                  Animals
                </Typography>
              </Link>{" "}
              born in captivity through{" "}
              <Link
                to="/neighbour"
                style={{
                  color: "black",
                  textDecorationColor: "white",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: {
                      xs: "20px",
                      sm: "36px",
                      md: "44px",
                      lg: "50px",
                    },
                    color: "white",
                    fontWeight: "700",
                  }}
                >
                  breeders
                </Typography>
              </Link>
              <Typography
                component={"span"}
                sx={{
                  fontSize: { xs: "20px", sm: "36px", md: "44px", lg: "50px" },
                  color: "#fff",
                  fontWeight: "700",
                }}
              >
                {" "}
                nationwide
              </Typography>
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 3 }}
          >
            <Typography
              component={"p"}
              sx={{
                color: "#fff",
                fontWeight: "600",
                fontSize: { xs: "14px", sm: "16px", md: "16px", lg: "18px" },
                lineHeight: "30px",
                marginTop: "5px",
                marginBottom: "34px",
              }}
            >
              We link breeders with{" "}
              <Link
                to="/listings/classes/28"
                style={{
                  color: "black",
                  textDecorationColor: "white",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    color: "#fff",
                    fontWeight: "600",
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "16px",
                      lg: "18px",
                    },
                    lineHeight: "30px",
                    marginTop: "5px",
                    marginBottom: "34px",
                  }}
                >
                  Plant
                </Typography>{" "}
              </Link>
              <Typography
                component={"span"}
                sx={{
                  color: "#fff",
                  fontWeight: "600",
                  fontSize: { xs: "14px", sm: "16px", md: "16px", lg: "18px" },
                  lineHeight: "30px",
                  marginTop: "5px",
                  marginBottom: "34px",
                }}
              >
                {" "}
                &
              </Typography>{" "}
              <Link
                to="/listings"
                style={{
                  color: "black",
                  textDecorationColor: "white",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    color: "#fff",
                    fontWeight: "600",
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "16px",
                      lg: "18px",
                    },
                    lineHeight: "30px",
                    marginTop: "5px",
                    marginBottom: "34px",
                  }}
                >
                  Animal
                </Typography>
              </Link>{" "}
              Enthusiasts.
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 4 }}
          >
            {user && user.email ? (
              <>
                {seller && seller.approval_status === "approved" ? (
                  <Button
                    component={Link}
                    to="/seller/dashboard"
                    size="large"
                    sx={{
                      width: { md: 250, lg: 300 },
                      height: 50,
                      textTransform: "none",
                      backgroundColor: "rgb(255, 192, 1) !important",
                      color: "black !important",
                      "&:hover": {
                        backgroundColor: "rgb(255, 192, 1)",
                      },
                    }}
                    variant="contained"
                  >
                    Seller Dashboard
                  </Button>
                ) : (
                  <Button
                    component={Link}
                    to={
                      user && user.is_subscribed
                        ? "/seller/register"
                        : "/pricing"
                    }
                    size="large"
                    sx={{
                      width: { md: 250, lg: 300 },
                      height: 50,
                      textTransform: "none",
                      backgroundColor: "rgb(255, 192, 1) !important",
                      color: "black !important",
                      "&:hover": {
                        backgroundColor: "rgb(255, 192, 1)",
                      },
                    }}
                    variant="contained"
                  >
                    Register as Seller
                  </Button>
                )}
              </>
            ) : (
              <Button
                component={Link}
                to="/auth/signup"
                size="large"
                sx={{
                  width: { md: 250, lg: 300 },
                  height: 50,
                  textTransform: "none",
                  backgroundColor: "rgb(255, 192, 1) !important",
                  color: "black !important",
                  "&:hover": {
                    backgroundColor: "rgb(255, 192, 1)",
                  },
                }}
                variant="contained"
              >
                Sign up or Login
              </Button>
            )}
          </motion.div>
        </Grid>

        <Grid item xl={6} lg={6} md={6} xs={12} sm={12} direction="column">
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 2 }}
          >
            <Box
              alignItems="center"
              justifyContent="center"
              sx={{
                textAlign: "center",
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                },
              }}
            >
              <Box display={"flex"}>
                <Box display={"column"}>
                  <Box display={"flex"} sx={{ height: "220px" }}>
                    <Box>
                      <img
                        // key={cert.image}
                        // alt={cert.image}
                        src={
                          "https://res.cloudinary.com/dzsz395eb/image/upload/v1692984546/Rectangle_28_lyjffs.png"
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                        }}
                      ></img>
                    </Box>
                    <Box
                      ml={3}
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"flex-end"}
                    >
                      <img
                        // key={cert.image}
                        // alt={cert.image}
                        src={
                          "https://res.cloudinary.com/dzsz395eb/image/upload/v1692984546/Rectangle_32_x2tgm0.png"
                        }
                        style={{
                          width: "100%",
                          height: "80%",
                          borderRadius: "10px",
                        }}
                      ></img>
                    </Box>
                  </Box>

                  <Box display={"flex"} mt={4} sx={{ height: "200px" }}>
                    <Box
                      flex={0.45}
                      sx={{ flexDirection: "row-reverse", display: "flex" }}
                    >
                      <img
                        // key={cert.image}
                        // alt={cert.image}
                        src={
                          "https://res.cloudinary.com/dzsz395eb/image/upload/v1692984546/Rectangle_34_weprip.png"
                        }
                        style={{
                          width: "100%",
                          height: "70%",
                          borderRadius: "10px",
                          right: 0,
                        }}
                      ></img>
                    </Box>
                    <Box
                      flex={0.55}
                      ml={2}
                      display={"flex"}
                      justifyContent={"flex-start"}
                    >
                      <img
                        // key={cert.image}
                        // alt={cert.image}
                        src={
                          "https://res.cloudinary.com/dzsz395eb/image/upload/v1692985850/photo-1504450874802-0ba2bcd9b5ae_zmzciv.jpg"
                        }
                        style={{
                          width: "100%",
                          height: "80%",
                          borderRadius: "10px",
                        }}
                      ></img>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </motion.div>
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = ({ Auth, Seller, Categories }) => ({
  user: Auth.user,
  seller: Seller.seller,
  classes: Categories.classes,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TopBanner);
