import React, { useState } from "react";
import {
  Stack,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Snackbar,
  Alert,
  Slide,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import RoomIcon from "@mui/icons-material/Room";
import { Spinner } from "../../components/spinner/Spinner";
import axios from "axios";
import config from "../../config";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const ContactUs = () => {
  const classes = useStyles();
  const isLargeScreen = useMediaQuery("(min-width: 900px)");
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [loadImage, setLoadImage] = useState(true);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const fields = ["name", "subject", "email", "message"];
    const formElements = e.target.elements;

    const formValues = fields
      .map((field) => ({
        [field]: formElements.namedItem(field).value,
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    try {
      registerRequest = await axios.post(
        `${config.SERVER_URL}/api/v1/contacts`,
        {
          contact: {
            ...formValues,
          },
        }
      );
    } catch ({ response }) {
      registerRequest = response;
      if (registerRequest.status === 422)
        setSnackbarStates({
          open: true,
          message:
            Object.keys(registerRequest.data.errors) +
            " " +
            Object.values(registerRequest.data.errors),
          severity: "danger",
        });
    }

    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 201) {
      setSnackbarStates({
        open: true,
        message: registerRequestData.message,
        severity: "success",
      });
      e.target.reset();
    }
  };

  const handleImageLoad = () => {
    setLoadImage(false);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarStates({ ...snackbarStates, open: false });
  };
  const imageSrcLarge =
    "https://stagingappdata.s3.amazonaws.com/contact-us/jordan-heinrichs-UpA6QvGpLHc-unsplash.jpg";

  const imageSrcSmall =
    "https://stagingappdata.s3.amazonaws.com/contact-us/shayd-johnson-3SYi9YfTXdU-unsplash.jpg";

  const gridStyles = {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    blur: "20px",
  };

  return (
    <>
      {loadImage && <Spinner />}
      <Box sx={{ position: "relative" }}>
        <img
          src={isLargeScreen ? imageSrcLarge : imageSrcSmall}
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            objectPosition: "center",
            objectFit: "cover",
          }}
          onLoad={handleImageLoad}
        />
        <Helmet>
          <meta
            property="og:image"
            content="https://prodappdata.s3.amazonaws.com/preview-images/contact-us-preview.png"
          />
          <meta property="og:title" content="Contact Us" />
          <meta property="og:description" content="Moore Exotics Contact Us" />
        </Helmet>

        <Stack>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alginItem="center"
          >
            <Grid item display="flex">
              <Snackbar
                transitionDuration={{ enter: 1000, exit: 2000 }}
                TransitionComponent={Slide}
                TransitionProps={{ enter: true, exit: false }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarStates.open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  variant="filled"
                  severity={snackbarStates.severity}
                  sx={{ width: "100%" }}
                >
                  {snackbarStates.message}
                </Alert>
              </Snackbar>
              <Grid container>
                <Grid item xs={12} md={6} mt={10} zIndex={10}>
                  <Box
                    mt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "inline-block",
                        paddingRight: "80px",
                      }}
                    >
                      <Typography
                        textAlign={"center"}
                        sx={{
                          fontSize: "32px",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        Contact Form
                      </Typography>
                    </Box>
                  </Box>

                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid item>
                      <Box mt={2} display={"flex"}>
                        <RoomIcon fontSize="large" sx={{ color: "white" }} />
                      </Box>
                      <Box mt={8} display={"flex"}>
                        <Box>
                          <EmailIcon fontSize="large" sx={{ color: "white" }} />
                        </Box>
                      </Box>
                      <Box mt={5} display={"flex"}>
                        <LocalPhoneIcon
                          fontSize="large"
                          sx={{ color: "white" }}
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box mt={4} ml={1}>
                        <Typography sx={{ color: "white", fontWeight: "bold" }}>
                          ADDRESS
                        </Typography>
                        <Typography sx={{ color: "white", fontWeight: "bold" }}>
                          Moore M&ore LLC.
                        </Typography>
                        <Typography sx={{ color: "white", fontWeight: "bold" }}>
                          PO box 122416 Arlington, Tx 76012
                        </Typography>
                      </Box>
                      <Box mt={4} ml={1}>
                        <Typography sx={{ color: "white", fontWeight: "bold" }}>
                          EMAIL
                        </Typography>
                        <Link
                          to="mailto:admin@mooreexotics.com"
                          style={{
                            textDecorationColor: "white",
                          }}
                        >
                          <Typography
                            sx={{ color: "white", fontWeight: "bold" }}
                          >
                            admin@mooreexotics.com
                          </Typography>
                        </Link>
                      </Box>
                      <Box mt={4} ml={1}>
                        <Typography sx={{ color: "white", fontWeight: "bold" }}>
                          PHONE
                        </Typography>
                        <Typography sx={{ color: "white", fontWeight: "bold" }}>
                          <Link
                            to="tel:+18444444112"
                            style={{
                              textDecorationColor: "white",
                            }}
                          >
                            <Typography
                              sx={{ color: "white", fontWeight: "bold" }}
                            >
                              + 1 (844) 444-4112{" "}
                            </Typography>
                          </Link>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  mt={2}
                  mb={4}
                  alignItems={"center"}
                  justifyContent={"center"}
                  display={"flex"}
                  p={{ xs: 1, md: 0 }}
                  zIndex={10}
                >
                  <Card
                    sx={{
                      padding: "20px",
                      // margin: "auto",
                      width: { sm: "50%", md: "50%" },
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h4"
                        align="center"
                        className={classes.headerStyle}
                      >
                        Get In Touch
                      </Typography>
                      <form onSubmit={handleSubmit}>
                        <Typography component={"p"}>Name</Typography>

                        <TextField
                          placeholder="Enter your name"
                          name="name"
                          type="text"
                          size="small"
                          fullWidth
                          InputProps={{
                            classes: {
                              root: classes.textField,
                            },
                          }}
                          required
                        />
                        <Typography component={"p"} sx={{ mt: "15px" }}>
                          Subject
                        </Typography>
                        <TextField
                          placeholder="Enter your subject"
                          name="subject"
                          type="text"
                          size="small"
                          fullWidth
                          InputProps={{
                            classes: {
                              root: classes.textField,
                            },
                          }}
                          required
                        />
                        <Typography component={"p"} sx={{ mt: "15px" }}>
                          Email
                        </Typography>
                        <TextField
                          placeholder="Enter your email"
                          name="email"
                          type="email"
                          size="small"
                          fullWidth
                          InputProps={{
                            classes: {
                              root: classes.textField,
                            },
                          }}
                          required
                        />
                        <Typography component={"p"} sx={{ mt: "15px" }}>
                          Message
                        </Typography>
                        <TextField
                          placeholder="How can we help?"
                          name="message"
                          type="text"
                          size="small"
                          fullWidth
                          multiline={true}
                          rows={3}
                          InputProps={{
                            classes: {
                              root: classes.textField,
                            },
                          }}
                          required
                        />

                        <Button
                          type="submit"
                          variant="contained"
                          className={classes.loginButton}
                          fullWidth
                        >
                          Send a message
                        </Button>
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  headerBox: {
    backgroundColor: "#000",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerStyle: {
    fontStyle: "normal",
    color: "grey",
    fontWeight: "900 !important",
    marginBottom: "15px !important",
  },
  textField: {
    "&:hover": {
      borderColor: "rgb(255, 192, 1)",
    },
  },
  loginButton: {
    marginTop: "15px !important",
    height: "40px",
    color: "#000",
    fontSize: "15px !important",
    fontWeight: "900 !important",
    textTransform: "none !important",
    backgroundColor: "rgb(255, 192, 1)",
    "&:hover": {
      backgroundColor: "rgb(255, 192, 1)",
      color: "white !important",
    },
  },
});

export default ContactUs;
