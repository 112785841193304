import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Actions from "../redux/actions";

const AuthVerify = ({ resetToken, jwtToken }) => {
  let location = useLocation();

  useEffect(() => {
    if (jwtToken) {
      const decodedJwt = jwtDecode(jwtToken);

      if (decodedJwt.exp * 1000 < Date.now()) {
        resetToken();
      }
    }
  }, [location]);

  return;
};

const mapStateToProps = ({ Auth }) => ({
  jwtToken: Auth.jwtToken,
});

const mapDispatchToProps = (dispatch) => ({
  resetToken: () => dispatch(Actions.resetToken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthVerify);
