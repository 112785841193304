export const Conditions = [
  {
    pages: [
      {
        width: "612",
        height: "792",
        num: "1",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Last",
                        width: "20.66",
                        height: "10.54",
                        left: "72",
                        top: "649.51",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Modified:",
                        width: "47.99",
                        height: "10.54",
                        left: "95.66",
                        top: "649.51",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "24th",
                        width: "21.34",
                        height: "10.54",
                        left: "146.65",
                        top: "649.51",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "August,",
                        width: "37.67",
                        height: "10.54",
                        left: "170.99",
                        top: "649.51",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "2023",
                        width: "24",
                        height: "10.54",
                        left: "211.66",
                        top: "649.51",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Acceptance",
                        width: "58.64",
                        height: "10.54",
                        left: "72",
                        top: "621.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "133.64",
                        top: "621.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "146.64",
                        top: "621.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "32.22",
                        height: "10.54",
                        left: "165.63",
                        top: "621.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "200.85",
                        top: "621.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "213.85",
                        top: "621.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "These",
                        width: "28.65",
                        height: "10.54",
                        left: "72",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "105.9",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "137.81",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "153.06",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "174.3",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "entered",
                        width: "35.31",
                        height: "10.54",
                        left: "194.2",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "into",
                        width: "18.67",
                        height: "10.54",
                        left: "234.76",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "258.68",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "275.93",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "between",
                        width: "39.97",
                        height: "10.54",
                        left: "298.51",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "343.73",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "366.98",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "389.56",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "M&ore,",
                        width: "38.33",
                        height: "10.54",
                        left: "426.8",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LLC",
                        width: "22.66",
                        height: "10.54",
                        left: "469.63",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(“",
                        width: "9.33",
                        height: "10.54",
                        left: "496.79",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "33.76",
                        height: "10.54",
                        left: "506.12",
                        top: "593.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Exotics",
                        width: "37.33",
                        height: "10.55",
                        left: "72",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”,",
                        width: "8.33",
                        height: "10.55",
                        left: "109.33",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "“",
                        width: "5.32",
                        height: "10.55",
                        left: "125.16",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.55",
                        left: "130.48",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”,",
                        width: "8.33",
                        height: "10.55",
                        left: "148.48",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "“",
                        width: "5.32",
                        height: "10.55",
                        left: "164.31",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.55",
                        left: "169.63",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”,",
                        width: "8.32",
                        height: "10.55",
                        left: "183.63",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "199.45",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "“",
                        width: "5.33",
                        height: "10.55",
                        left: "216.2",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "11.34",
                        height: "10.55",
                        left: "221.53",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”).",
                        width: "12.32",
                        height: "10.55",
                        left: "232.87",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.55",
                        left: "251.94",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "following",
                        width: "46.66",
                        height: "10.55",
                        left: "277.35",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.55",
                        left: "330.76",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "364.17",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "conditions,",
                        width: "53",
                        height: "10.55",
                        left: "388.25",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "together",
                        width: "39.31",
                        height: "10.55",
                        left: "448",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.55",
                        left: "494.06",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.55",
                        left: "522.15",
                        top: "580.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "documents",
                        width: "51.99",
                        height: "10.54",
                        left: "72",
                        top: "566.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "they",
                        width: "20.66",
                        height: "10.54",
                        left: "127.74",
                        top: "566.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "expressly",
                        width: "45.32",
                        height: "10.54",
                        left: "152.15",
                        top: "566.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "incorporate",
                        width: "54.64",
                        height: "10.54",
                        left: "201.22",
                        top: "566.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "259.61",
                        top: "566.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reference",
                        width: "44.62",
                        height: "10.54",
                        left: "275.36",
                        top: "566.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(collectively,",
                        width: "62.19",
                        height: "10.54",
                        left: "323.73",
                        top: "566.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "389.67",
                        top: "566.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "“",
                        width: "5.32",
                        height: "10.54",
                        left: "418.08",
                        top: "566.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "32.22",
                        height: "10.54",
                        left: "423.4",
                        top: "566.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "459.38",
                        top: "566.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "472.37",
                        top: "566.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”),",
                        width: "12.33",
                        height: "10.54",
                        left: "491.03",
                        top: "566.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "govern",
                        width: "33.32",
                        height: "10.54",
                        left: "506.36",
                        top: "566.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "72",
                        top: "552.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "100.75",
                        top: "552.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "138.14",
                        top: "552.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "154.22",
                        top: "552.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "178.3",
                        top: "552.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "201.05",
                        top: "552.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "217.04",
                        top: "552.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "website",
                        width: "36.66",
                        height: "10.54",
                        left: "239.04",
                        top: "552.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "located",
                        width: "34.64",
                        height: "10.54",
                        left: "281.7",
                        top: "552.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "322.34",
                        top: "552.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "https://mooreexotics.com/,",
                        width: "127.98",
                        height: "10.54",
                        left: "337",
                        top: "552.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "470.98",
                        top: "552.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "522.31",
                        top: "552.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "subscriptions,",
                        width: "66.33",
                        height: "10.54",
                        left: "72",
                        top: "538.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content,",
                        width: "38.32",
                        height: "10.54",
                        left: "148.08",
                        top: "538.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "functionality,",
                        width: "63.54",
                        height: "10.54",
                        left: "195.4",
                        top: "538.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "267.94",
                        top: "538.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.65",
                        height: "10.54",
                        left: "294.27",
                        top: "538.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offered",
                        width: "34.42",
                        height: "10.54",
                        left: "341.92",
                        top: "538.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "385.34",
                        top: "538.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "406.34",
                        top: "538.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "425.34",
                        top: "538.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "471.67",
                        top: "538.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "website",
                        width: "36.66",
                        height: "10.54",
                        left: "502.66",
                        top: "538.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "(collectively,",
                        width: "62.19",
                        height: "10.54",
                        left: "72",
                        top: "524.92",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "137.19",
                        top: "524.92",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "“",
                        width: "5.33",
                        height: "10.54",
                        left: "154.85",
                        top: "524.92",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "40.66",
                        height: "10.54",
                        left: "160.18",
                        top: "524.92",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”),",
                        width: "12.32",
                        height: "10.54",
                        left: "200.84",
                        top: "524.92",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "whether",
                        width: "38.65",
                        height: "10.54",
                        left: "216.16",
                        top: "524.92",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "257.81",
                        top: "524.92",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "270.81",
                        top: "524.92",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "guest",
                        width: "25.33",
                        height: "10.54",
                        left: "279.13",
                        top: "524.92",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "307.46",
                        top: "524.92",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "320.46",
                        top: "524.92",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "registered",
                        width: "47.31",
                        height: "10.54",
                        left: "328.79",
                        top: "524.92",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user.",
                        width: "22.33",
                        height: "10.54",
                        left: "379.1",
                        top: "524.92",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Please",
                        width: "30.66",
                        height: "10.54",
                        left: "72",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "read",
                        width: "20.64",
                        height: "10.54",
                        left: "107.16",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "132.3",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "151.46",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "185.78",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "200.28",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "carefully",
                        width: "42.64",
                        height: "10.54",
                        left: "223.44",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "before",
                        width: "30.64",
                        height: "10.54",
                        left: "270.58",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "305.72",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "start",
                        width: "20.66",
                        height: "10.54",
                        left: "328.22",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "353.38",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "367.22",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.67",
                        height: "10.54",
                        left: "387.71",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "406.88",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "451.23",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USING",
                        width: "38.01",
                        height: "10.54",
                        left: "472.4",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "514.16",
                        top: "497.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "WEBSITE",
                        width: "55.36",
                        height: "10.54",
                        left: "72",
                        top: "483.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "134.86",
                        top: "483.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "160.36",
                        top: "483.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLICKING",
                        width: "62.01",
                        height: "10.54",
                        left: "183.78",
                        top: "483.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "252.54",
                        top: "483.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACCEPT",
                        width: "49.34",
                        height: "10.54",
                        left: "276.41",
                        top: "483.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "332.5",
                        top: "483.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AGREE",
                        width: "42.67",
                        height: "10.54",
                        left: "357.25",
                        top: "483.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "406.67",
                        top: "483.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THESE",
                        width: "40.02",
                        height: "10.54",
                        left: "430.54",
                        top: "483.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TERMS",
                        width: "42.68",
                        height: "10.54",
                        left: "473.34",
                        top: "483.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "522.77",
                        top: "483.32",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "USE",
                        width: "23.34",
                        height: "10.54",
                        left: "72",
                        top: "469.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WHEN",
                        width: "38",
                        height: "10.54",
                        left: "98.13",
                        top: "469.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THIS",
                        width: "28.68",
                        height: "10.54",
                        left: "142.88",
                        top: "469.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OPTION",
                        width: "47.34",
                        height: "10.54",
                        left: "178.31",
                        top: "469.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IS",
                        width: "11.34",
                        height: "10.54",
                        left: "232.4",
                        top: "469.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MADE",
                        width: "36.67",
                        height: "10.54",
                        left: "250.49",
                        top: "469.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AVAILABLE",
                        width: "68.25",
                        height: "10.54",
                        left: "293.91",
                        top: "469.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "368.91",
                        top: "469.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOU,",
                        width: "29.67",
                        height: "10.54",
                        left: "392.78",
                        top: "469.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOU",
                        width: "26.67",
                        height: "10.54",
                        left: "425",
                        top: "469.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACCEPT",
                        width: "49.34",
                        height: "10.54",
                        left: "458.42",
                        top: "469.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "25.99",
                        height: "10.54",
                        left: "513.76",
                        top: "469.52",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "AGREE",
                        width: "42.67",
                        height: "10.55",
                        left: "72",
                        top: "455.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.55",
                        left: "118.42",
                        top: "455.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BE",
                        width: "16.01",
                        height: "10.55",
                        left: "139.29",
                        top: "455.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BOUND",
                        width: "43.34",
                        height: "10.55",
                        left: "159.05",
                        top: "455.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "26",
                        height: "10.55",
                        left: "206.14",
                        top: "455.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ABIDE",
                        width: "38.01",
                        height: "10.55",
                        left: "235.14",
                        top: "455.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.55",
                        left: "276.15",
                        top: "455.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THESE",
                        width: "40.02",
                        height: "10.55",
                        left: "295.82",
                        top: "455.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TERMS",
                        width: "42.67",
                        height: "10.55",
                        left: "338.84",
                        top: "455.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.55",
                        left: "384.51",
                        top: "455.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USE",
                        width: "23.35",
                        height: "10.55",
                        left: "404.17",
                        top: "455.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "26",
                        height: "10.55",
                        left: "430.52",
                        top: "455.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OUR",
                        width: "26.66",
                        height: "10.55",
                        left: "459.52",
                        top: "455.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRIVACY",
                        width: "50.46",
                        height: "10.55",
                        left: "489.18",
                        top: "455.72",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "POLICY",
                        width: "43.33",
                        height: "10.54",
                        left: "72",
                        top: "441.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ",",
                        width: "3",
                        height: "10.54",
                        left: "115.33",
                        top: "441.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "FOUND",
                        width: "42.66",
                        height: "10.54",
                        left: "124.33",
                        top: "441.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AT",
                        width: "15.78",
                        height: "10.54",
                        left: "172.99",
                        top: "441.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "https://mooreexotics.com/policy",
                        width: "162.08",
                        height: "10.54",
                        left: "194.77",
                        top: "441.93",
                        color: "#1154cc",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                        link: "https://mooreexotics.com/policy",
                      },
                      {
                        text: "INCORPORATED",
                        width: "97.78",
                        height: "10.54",
                        left: "367.35",
                        top: "441.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "HEREIN",
                        width: "47.35",
                        height: "10.54",
                        left: "470.38",
                        top: "441.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "522.98",
                        top: "441.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "REFERENCE.",
                        width: "77.01",
                        height: "10.54",
                        left: "72",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "152.01",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "163.75",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "184.75",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "199.75",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "want",
                        width: "23.32",
                        height: "10.54",
                        left: "218.09",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "244.41",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "256.75",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "285.72",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "298.05",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "325.71",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "358.53",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "371.53",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "393.19",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "406.18",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRIVACY",
                        width: "47.11",
                        height: "10.54",
                        left: "423.84",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "POLICY",
                        width: "41.34",
                        height: "10.54",
                        left: "473.95",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ",",
                        width: "3",
                        height: "10.54",
                        left: "515.29",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "521.29",
                        top: "428.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "must",
                        width: "23.34",
                        height: "10.54",
                        left: "72",
                        top: "414.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "98.34",
                        top: "414.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.65",
                        height: "10.54",
                        left: "116.67",
                        top: "414.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "150.32",
                        top: "414.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "163.31",
                        top: "414.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "182.31",
                        top: "414.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "199.97",
                        top: "414.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "This",
                        width: "21.33",
                        height: "10.54",
                        left: "72",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "97.08",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "139.19",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offered",
                        width: "34.43",
                        height: "10.54",
                        left: "150.94",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "189.12",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.31",
                        height: "10.54",
                        left: "210.19",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "257.25",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.67",
                        height: "10.54",
                        left: "270.33",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.66",
                        height: "10.54",
                        left: "298.75",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "323.16",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "18",
                        width: "12",
                        height: "10.54",
                        left: "341.56",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "years",
                        width: "25.32",
                        height: "10.54",
                        left: "357.31",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "386.38",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "age",
                        width: "16.65",
                        height: "10.54",
                        left: "400.13",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "419.78",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "older",
                        width: "24.66",
                        height: "10.54",
                        left: "432.77",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "460.43",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reside",
                        width: "28.65",
                        height: "10.54",
                        left: "480.76",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "512.41",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.74",
                        top: "386.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "United",
                        width: "32.66",
                        height: "10.54",
                        left: "72",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "States",
                        width: "28.66",
                        height: "10.54",
                        left: "109.16",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "142.32",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "156.82",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "178.65",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "11.34",
                        height: "10.54",
                        left: "193.14",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "territories",
                        width: "46.65",
                        height: "10.54",
                        left: "208.98",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "260.13",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "possessions.",
                        width: "59.01",
                        height: "10.54",
                        left: "274.62",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "By",
                        width: "14.01",
                        height: "10.54",
                        left: "338.13",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "using",
                        width: "26",
                        height: "10.54",
                        left: "356.64",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "386.39",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.35",
                        height: "10.54",
                        left: "407.48",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "452.58",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "represent",
                        width: "43.98",
                        height: "10.54",
                        left: "474.33",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "522.06",
                        top: "372.73",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "warrant",
                        width: "36.64",
                        height: "10.54",
                        left: "72",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "112.39",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "134.14",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "155.89",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "174.29",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "legal",
                        width: "23.32",
                        height: "10.54",
                        left: "188.03",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "age",
                        width: "16.66",
                        height: "10.54",
                        left: "214.35",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "234.01",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "form",
                        width: "23.33",
                        height: "10.54",
                        left: "246.34",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "272.67",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "binding",
                        width: "36.67",
                        height: "10.54",
                        left: "280.99",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contract",
                        width: "38.64",
                        height: "10.54",
                        left: "320.66",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "362.3",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "386.64",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "400.31",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "meet",
                        width: "23.32",
                        height: "10.54",
                        left: "420.63",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "446.95",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "461.95",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "474.94",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "foregoing",
                        width: "46.66",
                        height: "10.54",
                        left: "492.6",
                        top: "358.93",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "eligibility",
                        width: "46.66",
                        height: "10.54",
                        left: "72",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "requirements.",
                        width: "65.65",
                        height: "10.54",
                        left: "123.91",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "194.81",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "207.3",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "229.8",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "246.3",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "meet",
                        width: "23.32",
                        height: "10.54",
                        left: "266.13",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "293.95",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "310.45",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "324.94",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "requirements,",
                        width: "65.64",
                        height: "10.54",
                        left: "354.1",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "424.24",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.34",
                        height: "10.54",
                        left: "446.74",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "474.58",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.65",
                        height: "10.54",
                        left: "494.41",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "529.56",
                        top: "345.13",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "72",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "91",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "110.16",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "160.51",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "183.73",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsible",
                        width: "54.65",
                        height: "10.54",
                        left: "202.13",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "260.53",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "278.27",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "299.35",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "320.43",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "336.17",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activity",
                        width: "35.99",
                        height: "10.54",
                        left: "377.23",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "conducted",
                        width: "49.31",
                        height: "10.54",
                        left: "416.97",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "470.03",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "485.78",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "minor",
                        width: "28.66",
                        height: "10.54",
                        left: "494.86",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "527.27",
                        top: "331.33",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "72",
                        top: "317.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account.",
                        width: "40.31",
                        height: "10.55",
                        left: "97",
                        top: "317.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "About",
                        width: "32.01",
                        height: "10.54",
                        left: "72",
                        top: "289.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "33.76",
                        height: "10.54",
                        left: "107.01",
                        top: "289.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "37.33",
                        height: "10.54",
                        left: "143.77",
                        top: "289.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "72",
                        top: "261.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "36",
                        height: "10.54",
                        left: "111.49",
                        top: "261.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provides",
                        width: "41.32",
                        height: "10.54",
                        left: "154.99",
                        top: "261.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "203.81",
                        top: "261.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "platform",
                        width: "41.32",
                        height: "10.54",
                        left: "216.64",
                        top: "261.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "where",
                        width: "29.31",
                        height: "10.54",
                        left: "265.46",
                        top: "261.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "302.27",
                        top: "261.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "31.98",
                        height: "10.54",
                        left: "332.44",
                        top: "261.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interested",
                        width: "46.65",
                        height: "10.54",
                        left: "371.17",
                        top: "261.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "424.57",
                        top: "261.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owning,",
                        width: "39",
                        height: "10.54",
                        left: "440.65",
                        top: "261.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "caring",
                        width: "29.99",
                        height: "10.54",
                        left: "486.4",
                        top: "261.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for,",
                        width: "16.51",
                        height: "10.54",
                        left: "523.14",
                        top: "261.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "purchasing,",
                        width: "55.65",
                        height: "10.54",
                        left: "72",
                        top: "248.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "131.4",
                        top: "248.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "selling",
                        width: "32",
                        height: "10.54",
                        left: "152.48",
                        top: "248.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "domestic,",
                        width: "46.32",
                        height: "10.54",
                        left: "187.48",
                        top: "248.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "farm,",
                        width: "25.65",
                        height: "10.54",
                        left: "236.8",
                        top: "248.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and/or",
                        width: "30.66",
                        height: "10.54",
                        left: "265.45",
                        top: "248.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "exotic",
                        width: "29.32",
                        height: "10.54",
                        left: "299.11",
                        top: "248.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals",
                        width: "37.32",
                        height: "10.54",
                        left: "331.43",
                        top: "248.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "371.75",
                        top: "248.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interact,",
                        width: "38.98",
                        height: "10.54",
                        left: "395.41",
                        top: "248.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "network,",
                        width: "42.32",
                        height: "10.54",
                        left: "437.39",
                        top: "248.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "market,",
                        width: "36.32",
                        height: "10.54",
                        left: "482.71",
                        top: "248.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "list,",
                        width: "17.67",
                        height: "10.54",
                        left: "522.03",
                        top: "248.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "234.34",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "complete",
                        width: "43.98",
                        height: "10.54",
                        left: "93.08",
                        top: "234.34",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchase",
                        width: "42.64",
                        height: "10.54",
                        left: "140.81",
                        top: "234.34",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "187.2",
                        top: "234.34",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sale",
                        width: "18.65",
                        height: "10.54",
                        left: "208.28",
                        top: "234.34",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transactions",
                        width: "57.32",
                        height: "10.54",
                        left: "230.68",
                        top: "234.34",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "291.75",
                        top: "234.34",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respect",
                        width: "33.98",
                        height: "10.54",
                        left: "316.83",
                        top: "234.34",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "354.56",
                        top: "234.34",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "367.65",
                        top: "234.34",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals.",
                        width: "40.33",
                        height: "10.54",
                        left: "393.39",
                        top: "234.34",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Users",
                        width: "27.32",
                        height: "10.54",
                        left: "440.47",
                        top: "234.34",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "470.79",
                        top: "234.34",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "483.79",
                        top: "234.34",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "website",
                        width: "36.65",
                        height: "10.54",
                        left: "502.79",
                        top: "234.34",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "include",
                        width: "35.32",
                        height: "10.54",
                        left: "72",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "111.82",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "31.99",
                        height: "10.54",
                        left: "138.98",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.67",
                        height: "10.54",
                        left: "175.47",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "list",
                        width: "14.67",
                        height: "10.54",
                        left: "200.64",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "219.81",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sell",
                        width: "16.67",
                        height: "10.54",
                        left: "241.63",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals",
                        width: "37.32",
                        height: "10.54",
                        left: "262.8",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "304.62",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "related",
                        width: "32.64",
                        height: "10.54",
                        left: "319.12",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "items",
                        width: "26",
                        height: "10.54",
                        left: "355.51",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "385.26",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "426.34",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "444.75",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(“",
                        width: "9.32",
                        height: "10.54",
                        left: "486.86",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Sellers",
                        width: "33.99",
                        height: "10.54",
                        left: "496.18",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”)",
                        width: "9.3200000000001",
                        height: "10.54",
                        left: "530.17",
                        top: "220.54",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "206.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "94.58",
                        top: "206.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "31.99",
                        height: "10.54",
                        left: "122.49",
                        top: "206.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.66",
                        height: "10.54",
                        left: "159.73",
                        top: "206.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchase",
                        width: "42.65",
                        height: "10.54",
                        left: "185.64",
                        top: "206.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals",
                        width: "37.32",
                        height: "10.54",
                        left: "233.54",
                        top: "206.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "276.11",
                        top: "206.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "related",
                        width: "32.64",
                        height: "10.54",
                        left: "291.36",
                        top: "206.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "items",
                        width: "26",
                        height: "10.54",
                        left: "329.25",
                        top: "206.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "360.5",
                        top: "206.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "403.08",
                        top: "206.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "422.99",
                        top: "206.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(“",
                        width: "9.33",
                        height: "10.54",
                        left: "465.84",
                        top: "206.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Purchaser",
                        width: "52.43",
                        height: "10.54",
                        left: "475.17",
                        top: "206.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”).",
                        width: "12.32",
                        height: "10.54",
                        left: "527.6",
                        top: "206.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "72",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "36",
                        height: "10.54",
                        left: "107.74",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "146.74",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "neither",
                        width: "33.32",
                        height: "10.54",
                        left: "157.74",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "194.06",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "party",
                        width: "24.66",
                        height: "10.54",
                        left: "202.38",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "230.04",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contracts",
                        width: "43.32",
                        height: "10.54",
                        left: "242.37",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "288.69",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchase",
                        width: "42.64",
                        height: "10.54",
                        left: "305.68",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "351.32",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sale",
                        width: "18.65",
                        height: "10.54",
                        left: "364.32",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "between",
                        width: "39.98",
                        height: "10.54",
                        left: "385.97",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Purchasers",
                        width: "51.99",
                        height: "10.54",
                        left: "428.95",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "483.94",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Sellers,",
                        width: "35.66",
                        height: "10.54",
                        left: "504.26",
                        top: "192.94",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "nor",
                        width: "16",
                        height: "10.54",
                        left: "72",
                        top: "179.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.32",
                        height: "10.54",
                        left: "95.5",
                        top: "179.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "auctioneer.",
                        width: "52.31",
                        height: "10.54",
                        left: "114.32",
                        top: "179.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Any",
                        width: "20.66",
                        height: "10.54",
                        left: "181.63",
                        top: "179.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "guidance",
                        width: "43.32",
                        height: "10.54",
                        left: "209.79",
                        top: "179.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "259.86",
                        top: "179.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "280.6",
                        top: "179.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "324.01",
                        top: "179.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "part",
                        width: "18.66",
                        height: "10.54",
                        left: "340.75",
                        top: "179.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "366.16",
                        top: "179.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "382.9",
                        top: "179.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "405.65",
                        top: "179.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.64",
                        height: "10.54",
                        left: "450.76",
                        top: "179.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "496.15",
                        top: "179.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "solely",
                        width: "28.66",
                        height: "10.54",
                        left: "510.91",
                        top: "179.14",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "informational,",
                        width: "68.31",
                        height: "10.54",
                        left: "72",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "144.81",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "166.64",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "189.14",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "decide",
                        width: "31.31",
                        height: "10.54",
                        left: "214.3",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "250.11",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "follow",
                        width: "31.33",
                        height: "10.54",
                        left: "263.95",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "6.67",
                        height: "10.54",
                        left: "299.03",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "309.45",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not.",
                        width: "18.34",
                        height: "10.54",
                        left: "323.19",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "345.28",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "364.72",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "help",
                        width: "20.66",
                        height: "10.54",
                        left: "389.13",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "facilitate",
                        width: "41.97",
                        height: "10.54",
                        left: "413.54",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "459.26",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "resolution",
                        width: "47.99",
                        height: "10.54",
                        left: "477.67",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "529.41",
                        top: "165.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "disputes",
                        width: "39.33",
                        height: "10.54",
                        left: "72",
                        top: "151.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "between",
                        width: "39.98",
                        height: "10.54",
                        left: "116.58",
                        top: "151.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Purchasers",
                        width: "51.99",
                        height: "10.54",
                        left: "161.81",
                        top: "151.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "219.05",
                        top: "151.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Sellers",
                        width: "32.66",
                        height: "10.54",
                        left: "240.87",
                        top: "151.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "278.03",
                        top: "151.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "various",
                        width: "35.33",
                        height: "10.54",
                        left: "319.86",
                        top: "151.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "programs",
                        width: "45.32",
                        height: "10.54",
                        left: "359.69",
                        top: "151.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(see",
                        width: "19.32",
                        height: "10.54",
                        left: "409.51",
                        top: "151.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.96",
                        height: "10.54",
                        left: "433.33",
                        top: "151.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "42",
                        height: "10.54",
                        left: "497.79",
                        top: "151.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Disputes,",
                        width: "45",
                        height: "10.54",
                        left: "72",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below).",
                        width: "36.32",
                        height: "10.54",
                        left: "120.75",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "160.82",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.66",
                        height: "10.54",
                        left: "180.26",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "205.92",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "control",
                        width: "33.99",
                        height: "10.54",
                        left: "220.92",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "over",
                        width: "21.32",
                        height: "10.54",
                        left: "257.91",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "282.23",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "302.55",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "317.55",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "guarantee:",
                        width: "49.97",
                        height: "10.54",
                        left: "335.89",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "388.86",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "existence,",
                        width: "47.64",
                        height: "10.54",
                        left: "406.52",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "quality,",
                        width: "35.55",
                        height: "10.54",
                        left: "457.16",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "safety,",
                        width: "30.87",
                        height: "10.54",
                        left: "495.71",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.58",
                        top: "137.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "legality",
                        width: "35.99",
                        height: "10.54",
                        left: "72",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "113.24",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "items",
                        width: "26",
                        height: "10.54",
                        left: "128.48",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertised;",
                        width: "52.65",
                        height: "10.54",
                        left: "159.73",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "217.63",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "truth",
                        width: "22.66",
                        height: "10.54",
                        left: "237.54",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "265.45",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accuracy",
                        width: "42.63",
                        height: "10.54",
                        left: "280.7",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "328.58",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users'",
                        width: "26.83",
                        height: "10.54",
                        left: "343.82",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "375.9",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "416.47",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "listings;",
                        width: "38.01",
                        height: "10.54",
                        left: "431.71",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "474.97",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ability",
                        width: "30.66",
                        height: "10.54",
                        left: "494.13",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "529.29",
                        top: "123.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Sellers",
                        width: "32.66",
                        height: "10.54",
                        left: "72",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "108.41",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sell",
                        width: "16.67",
                        height: "10.54",
                        left: "121.49",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "items;",
                        width: "29.33",
                        height: "10.54",
                        left: "141.91",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "174.99",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ability",
                        width: "30.66",
                        height: "10.54",
                        left: "193.4",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "227.81",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Purchasers",
                        width: "51.98",
                        height: "10.54",
                        left: "241.56",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "297.29",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pay",
                        width: "17.32",
                        height: "10.54",
                        left: "310.38",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.54",
                        left: "331.45",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "items;",
                        width: "29.33",
                        height: "10.54",
                        left: "349.2",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "382.28",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "396.02",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "417.77",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Purchaser",
                        width: "47.32",
                        height: "10.54",
                        left: "426.84",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "477.16",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Seller",
                        width: "27.99",
                        height: "10.54",
                        left: "490.15",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "521.14",
                        top: "110.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "actually",
                        width: "37.98",
                        height: "10.54",
                        left: "72",
                        top: "96.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "complete",
                        width: "43.98",
                        height: "10.54",
                        left: "115.98",
                        top: "96.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "165.96",
                        top: "96.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transaction",
                        width: "52.64",
                        height: "10.54",
                        left: "177.29",
                        top: "96.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "235.93",
                        top: "96.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "return",
                        width: "28.65",
                        height: "10.54",
                        left: "251.93",
                        top: "96.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "286.58",
                        top: "96.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "item.",
                        width: "24.33",
                        height: "10.54",
                        left: "303.91",
                        top: "96.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.54",
                        left: "334.24",
                        top: "96.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "more",
                        width: "24.65",
                        height: "10.54",
                        left: "356.16",
                        top: "96.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "59.66",
                        height: "10.54",
                        left: "386.06",
                        top: "96.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "see",
                        width: "15.32",
                        height: "10.54",
                        left: "450.97",
                        top: "96.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Disclaimer",
                        width: "52.65",
                        height: "10.54",
                        left: "471.54",
                        top: "96.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.9899999999999",
                        height: "10.54",
                        left: "529.44",
                        top: "96.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Warranties,",
                        width: "54.67",
                        height: "10.54",
                        left: "72",
                        top: "82.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below.",
                        width: "31.55",
                        height: "10.54",
                        left: "129.67",
                        top: "82.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,720",
                    "lineto,540,720",
                    "lineto,540,704",
                    "lineto,72,704",
                    "lineto,72,720",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,705",
                    "lineto,540,705",
                    "lineto,540,662",
                    "lineto,72,662",
                    "lineto,72,705",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,663",
                    "lineto,540,663",
                    "lineto,540,634",
                    "lineto,72,634",
                    "lineto,72,663",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,636",
                    "lineto,540,636",
                    "lineto,540,607",
                    "lineto,72,607",
                    "lineto,72,636",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,608",
                    "lineto,540,608",
                    "lineto,540,593",
                    "lineto,72,593",
                    "lineto,72,608",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,594",
                    "lineto,540,594",
                    "lineto,540,579",
                    "lineto,72,579",
                    "lineto,72,594",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,580",
                    "lineto,540,580",
                    "lineto,540,565",
                    "lineto,72,565",
                    "lineto,72,580",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,566",
                    "lineto,540,566",
                    "lineto,540,551",
                    "lineto,72,551",
                    "lineto,72,566",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,553",
                    "lineto,540,553",
                    "lineto,540,538",
                    "lineto,72,538",
                    "lineto,72,553",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,539",
                    "lineto,540,539",
                    "lineto,540,510",
                    "lineto,72,510",
                    "lineto,72,539",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,511",
                    "lineto,540,511",
                    "lineto,540,496",
                    "lineto,72,496",
                    "lineto,72,511",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,497",
                    "lineto,540,497",
                    "lineto,540,482",
                    "lineto,72,482",
                    "lineto,72,497",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,483",
                    "lineto,540,483",
                    "lineto,540,468",
                    "lineto,72,468",
                    "lineto,72,483",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,470",
                    "lineto,540,470",
                    "lineto,540,455",
                    "lineto,72,455",
                    "lineto,72,470",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,456",
                    "lineto,540,456",
                    "lineto,540,441",
                    "lineto,72,441",
                    "lineto,72,456",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,442",
                    "lineto,540,442",
                    "lineto,540,427",
                    "lineto,72,427",
                    "lineto,72,442",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,428",
                    "lineto,540,428",
                    "lineto,540,399",
                    "lineto,72,399",
                    "lineto,72,428",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,400",
                    "lineto,540,400",
                    "lineto,540,385",
                    "lineto,72,385",
                    "lineto,72,400",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,387",
                    "lineto,540,387",
                    "lineto,540,372",
                    "lineto,72,372",
                    "lineto,72,387",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,373",
                    "lineto,540,373",
                    "lineto,540,358",
                    "lineto,72,358",
                    "lineto,72,373",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,359",
                    "lineto,540,359",
                    "lineto,540,344",
                    "lineto,72,344",
                    "lineto,72,359",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,345",
                    "lineto,540,345",
                    "lineto,540,330",
                    "lineto,72,330",
                    "lineto,72,345",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,331",
                    "lineto,540,331",
                    "lineto,540,302",
                    "lineto,72,302",
                    "lineto,72,331",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,304",
                    "lineto,540,304",
                    "lineto,540,275",
                    "lineto,72,275",
                    "lineto,72,304",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,134.86,497.12",
                    "lineto,160.36,497.12",
                    "lineto,160.36,483.32",
                    "lineto,134.86,483.32",
                    "lineto,134.86,497.12",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,400.33",
                    "lineto,539.4,400.33",
                    "lineto,539.4,386.53",
                    "lineto,72,386.53",
                    "lineto,72,400.33",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,386.53",
                    "lineto,539.38,386.53",
                    "lineto,539.38,372.73",
                    "lineto,72,372.73",
                    "lineto,72,386.53",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,372.73",
                    "lineto,539.26,372.73",
                    "lineto,539.26,358.93",
                    "lineto,72,358.93",
                    "lineto,72,372.73",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,358.93",
                    "lineto,539.55,358.93",
                    "lineto,539.55,345.13",
                    "lineto,72,345.13",
                    "lineto,72,358.93",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,345.13",
                    "lineto,160.51,345.13",
                    "lineto,160.51,331.33",
                    "lineto,72,331.33",
                    "lineto,72,345.13",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,181.63,192.94",
                    "lineto,539.57,192.94",
                    "lineto,539.57,179.14",
                    "lineto,181.63,179.14",
                    "lineto,181.63,192.94",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,179.14",
                    "lineto,539.41,179.14",
                    "lineto,539.41,165.35",
                    "lineto,72,165.35",
                    "lineto,72,179.14",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,165.35",
                    "lineto,539.79,165.35",
                    "lineto,539.79,151.55",
                    "lineto,72,151.55",
                    "lineto,72,165.35",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,151.55",
                    "lineto,539.58,151.55",
                    "lineto,539.58,137.75",
                    "lineto,72,137.75",
                    "lineto,72,151.55",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,137.75",
                    "lineto,539.29,137.75",
                    "lineto,539.29,123.95",
                    "lineto,72,123.95",
                    "lineto,72,137.75",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,123.95",
                    "lineto,539.81,123.95",
                    "lineto,539.81,110.15",
                    "lineto,72,110.15",
                    "lineto,72,123.95",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,110.15",
                    "lineto,539.43,110.15",
                    "lineto,539.43,96.35",
                    "lineto,72,96.35",
                    "lineto,72,110.15",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,96.35",
                    "lineto,164.22,96.35",
                    "lineto,164.22,82.55",
                    "lineto,72,82.55",
                    "lineto,72,96.35",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,622.5", "lineto,233,622.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#1154cc",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,195,443.5", "lineto,357,443.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,291.5", "lineto,181,291.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,433,152.5", "lineto,540,152.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,139.5", "lineto,114,139.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,472,97.5", "lineto,539,97.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,83.5", "lineto,124,83.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "2",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Changes",
                        width: "44.01",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "119.01",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "132.01",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "32.22",
                        height: "10.54",
                        left: "151",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "186.22",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "199.22",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "92.94",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "revise",
                        width: "28.65",
                        height: "10.54",
                        left: "118.85",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "152.75",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "update",
                        width: "31.99",
                        height: "10.54",
                        left: "175.33",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "212.57",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "242.47",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "277.54",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "292.79",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "316.7",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.32",
                        height: "10.54",
                        left: "345.28",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "371.85",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "385.69",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "411.52",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "425.35",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "445.85",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion.",
                        width: "50.32",
                        height: "10.54",
                        left: "469.68",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "All",
                        width: "15.33",
                        height: "10.54",
                        left: "524.5",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "changes",
                        width: "38.65",
                        height: "10.54",
                        left: "72",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "115.15",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "effective",
                        width: "41.74",
                        height: "10.54",
                        left: "134.3",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "immediately",
                        width: "59.99",
                        height: "10.54",
                        left: "180.54",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "244.28",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "274.02",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "post",
                        width: "20.01",
                        height: "10.54",
                        left: "291.76",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "them",
                        width: "23.99",
                        height: "10.54",
                        left: "315.52",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "342.51",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "apply",
                        width: "26.66",
                        height: "10.54",
                        left: "363.59",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "394",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "407.08",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.65",
                        height: "10.54",
                        left: "422.82",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "457.22",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "470.3",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "491.38",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "511.13",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.87",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.55",
                        left: "72",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "thereafter.",
                        width: "48.29",
                        height: "10.55",
                        left: "116.36",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "However,",
                        width: "46.5",
                        height: "10.55",
                        left: "169.9",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.55",
                        left: "221.65",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "changes",
                        width: "38.65",
                        height: "10.55",
                        left: "244.23",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "288.13",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "302.71",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "dispute",
                        width: "34.67",
                        height: "10.55",
                        left: "322.62",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "resolution",
                        width: "47.99",
                        height: "10.55",
                        left: "362.54",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provisions",
                        width: "50.01",
                        height: "10.55",
                        left: "415.78",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "set",
                        width: "13.33",
                        height: "10.55",
                        left: "471.04",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "out",
                        width: "15.33",
                        height: "10.55",
                        left: "489.62",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.55",
                        left: "510.2",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "524.78",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Governing",
                        width: "51.32",
                        height: "10.54",
                        left: "72",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Law",
                        width: "21.32",
                        height: "10.54",
                        left: "128.57",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "155.14",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Jurisdiction",
                        width: "56",
                        height: "10.54",
                        left: "177.72",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "section,",
                        width: "36.99",
                        height: "10.54",
                        left: "238.97",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below,",
                        width: "31.55",
                        height: "10.54",
                        left: "281.21",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "318.01",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "341.92",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "apply",
                        width: "26.66",
                        height: "10.54",
                        left: "361.76",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "392.92",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "406.75",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disputes",
                        width: "39.33",
                        height: "10.54",
                        left: "428.58",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "472.41",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "29.33",
                        height: "10.54",
                        left: "490.9",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.73",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "parties",
                        width: "31.99",
                        height: "10.54",
                        left: "72",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "106.99",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "actual",
                        width: "28.65",
                        height: "10.54",
                        left: "132.64",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notice",
                        width: "29.32",
                        height: "10.54",
                        left: "164.29",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "196.61",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "211.61",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "before",
                        width: "30.65",
                        height: "10.54",
                        left: "224.6",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "258.25",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "date",
                        width: "19.98",
                        height: "10.54",
                        left: "275.91",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "298.89",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "change",
                        width: "33.98",
                        height: "10.54",
                        left: "316.55",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "353.53",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "posted",
                        width: "31.33",
                        height: "10.54",
                        left: "364.54",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "398.87",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "413.87",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "431.53",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.54",
                        left: "72",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "continued",
                        width: "47.32",
                        height: "10.54",
                        left: "98.46",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "148.78",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "167.78",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "180.77",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "198.43",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "242.79",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "291.12",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.65",
                        height: "10.54",
                        left: "311.44",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offered",
                        width: "34.43",
                        height: "10.54",
                        left: "353.09",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "390.52",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "430.85",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.35",
                        height: "10.54",
                        left: "448.51",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "following",
                        width: "46.67",
                        height: "10.54",
                        left: "492.86",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "72",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "posting",
                        width: "35.34",
                        height: "10.54",
                        left: "91.16",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "131",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "revised",
                        width: "34.66",
                        height: "10.54",
                        left: "145.49",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.81",
                        height: "10.54",
                        left: "184.65",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "218.96",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "233.46",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "means",
                        width: "30.66",
                        height: "10.54",
                        left: "256.62",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "291.78",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "314.27",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accept",
                        width: "30.64",
                        height: "10.54",
                        left: "336.77",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "371.91",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "393.74",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "424.21",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "438.05",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "changes.",
                        width: "41.65",
                        height: "10.54",
                        left: "456.46",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "501.86",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "525.07",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "expected",
                        width: "42.64",
                        height: "10.54",
                        left: "72",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "119.89",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "check",
                        width: "27.98",
                        height: "10.54",
                        left: "134.47",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "167.7",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "page",
                        width: "22.65",
                        height: "10.54",
                        left: "190.29",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "218.19",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "246.77",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "273.35",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "287.18",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "so",
                        width: "10.67",
                        height: "10.54",
                        left: "313.01",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "328.18",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "350.68",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "aware",
                        width: "28.64",
                        height: "10.54",
                        left: "369.83",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "402.97",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "417.46",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "changes,",
                        width: "41.65",
                        height: "10.54",
                        left: "439.29",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "485.44",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "they",
                        width: "20.66",
                        height: "10.54",
                        left: "499.93",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "525.09",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "binding",
                        width: "36.67",
                        height: "10.54",
                        left: "72",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "111.67",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you.",
                        width: "21",
                        height: "10.54",
                        left: "126.67",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Your",
                        width: "25.34",
                        height: "10.54",
                        left: "72",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "39.31",
                        height: "10.54",
                        left: "100.34",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "All",
                        width: "15.33",
                        height: "10.55",
                        left: "72",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.55",
                        left: "92.58",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.55",
                        left: "154.49",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "177.73",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.55",
                        left: "200.98",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "242.89",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.55",
                        left: "257.47",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "273.39",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.55",
                        left: "288.64",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.55",
                        left: "311.13",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.55",
                        left: "329.63",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.55",
                        left: "366.11",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.55",
                        left: "407.94",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.55",
                        left: "434.43",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.55",
                        left: "454.93",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "469.42",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.55",
                        left: "488.58",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.55",
                        left: "531.44",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "governed",
                        width: "44.65",
                        height: "10.54",
                        left: "72",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "121.15",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "137.65",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRIVACY",
                        width: "47.11",
                        height: "10.54",
                        left: "158.14",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "POLICY,",
                        width: "43.24",
                        height: "10.54",
                        left: "209.75",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.31",
                        height: "10.54",
                        left: "257.49",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "305.3",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "https://mooreexotics.com/policy",
                        width: "162.08",
                        height: "10.54",
                        left: "318.46",
                        top: "484.62",
                        color: "#1154cc",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                        link: "https://mooreexotics.com/policy",
                      },
                      {
                        text: ",",
                        width: "3",
                        height: "10.54",
                        left: "485.04",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "488.04",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "By",
                        width: "14",
                        height: "10.54",
                        left: "495.54",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "using",
                        width: "26.01",
                        height: "10.54",
                        left: "513.29",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "72",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "90.41",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "135.52",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "consent",
                        width: "36.65",
                        height: "10.54",
                        left: "157.27",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "197.67",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "210.76",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "actions",
                        width: "33.99",
                        height: "10.54",
                        left: "226.5",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "264.24",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "take",
                        width: "19.99",
                        height: "10.54",
                        left: "281.98",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "305.72",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respect",
                        width: "33.98",
                        height: "10.54",
                        left: "330.8",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "368.53",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "381.62",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "407.36",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "consistent",
                        width: "47.99",
                        height: "10.54",
                        left: "467.02",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "518.01",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "72",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRIVACY",
                        width: "47.1",
                        height: "10.54",
                        left: "91",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "POLICY",
                        width: "41.34",
                        height: "10.54",
                        left: "141.1",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "182.44",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Accessing",
                        width: "49.99",
                        height: "10.54",
                        left: "72",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "124.99",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "40.66",
                        height: "10.54",
                        left: "143.99",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "187.65",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Account",
                        width: "42.66",
                        height: "10.54",
                        left: "210",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Security",
                        width: "42.66",
                        height: "10.54",
                        left: "255.66",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reserve",
                        width: "34.64",
                        height: "10.54",
                        left: "91.44",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "129.83",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.67",
                        height: "10.54",
                        left: "148.24",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "174.66",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "withdraw",
                        width: "45.32",
                        height: "10.54",
                        left: "187.74",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "236.81",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amend",
                        width: "31.98",
                        height: "10.54",
                        left: "250.56",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "286.29",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "307.38",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "352.49",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "373.56",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "33.98",
                        height: "10.54",
                        left: "393.89",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "430.87",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "443.87",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "486.18",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.65",
                        height: "10.54",
                        left: "503.17",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "72",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "87.75",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "106.16",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "151.27",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "163.6",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "182.6",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion",
                        width: "47.32",
                        height: "10.54",
                        left: "204.93",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.66",
                        height: "10.54",
                        left: "255.25",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notice.",
                        width: "32.32",
                        height: "10.54",
                        left: "294.91",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.7",
                        height: "10.54",
                        left: "330.23",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "348.93",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "370.59",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "388.93",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liable",
                        width: "26.66",
                        height: "10.54",
                        left: "403.25",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "432.91",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "443.24",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "460.23",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reason",
                        width: "31.32",
                        height: "10.54",
                        left: "480.56",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "514.88",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.87",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "373.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "part",
                        width: "18.65",
                        height: "10.54",
                        left: "93.08",
                        top: "373.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "115.48",
                        top: "373.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "129.23",
                        top: "373.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "147.64",
                        top: "373.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(including",
                        width: "49.33",
                        height: "10.54",
                        left: "189.74",
                        top: "373.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "242.07",
                        top: "373.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.65",
                        height: "10.54",
                        left: "262.39",
                        top: "373.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offered",
                        width: "34.43",
                        height: "10.54",
                        left: "304.04",
                        top: "373.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "341.47",
                        top: "373.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "connection",
                        width: "52.65",
                        height: "10.54",
                        left: "353.8",
                        top: "373.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "therewith)",
                        width: "49.31",
                        height: "10.54",
                        left: "409.45",
                        top: "373.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "461.76",
                        top: "373.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unavailable",
                        width: "55.31",
                        height: "10.54",
                        left: "472.76",
                        top: "373.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "531.07",
                        top: "373.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.32",
                        height: "10.54",
                        left: "93.08",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "117.4",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "130.4",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "147.39",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "period.",
                        width: "33.66",
                        height: "10.54",
                        left: "167.72",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "From",
                        width: "26",
                        height: "10.54",
                        left: "204.38",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "233.38",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "257.71",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time,",
                        width: "24.33",
                        height: "10.54",
                        left: "270.04",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "297.37",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "314.36",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "restrict",
                        width: "33.32",
                        height: "10.54",
                        left: "338.02",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "374.34",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "407.98",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "some",
                        width: "25.33",
                        height: "10.54",
                        left: "420.32",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parts",
                        width: "23.32",
                        height: "10.54",
                        left: "448.65",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "474.97",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "487.97",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parts",
                        width: "23.33",
                        height: "10.54",
                        left: "502.96",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "529.29",
                        top: "360.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "72",
                        top: "346.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.55",
                        left: "89.66",
                        top: "346.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "131.02",
                        top: "346.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users,",
                        width: "27.66",
                        height: "10.55",
                        left: "143.35",
                        top: "346.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.55",
                        left: "174.01",
                        top: "346.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "registered",
                        width: "47.31",
                        height: "10.55",
                        left: "222.34",
                        top: "346.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users.",
                        width: "27.66",
                        height: "10.55",
                        left: "272.65",
                        top: "346.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "95.21",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsible",
                        width: "54.66",
                        height: "10.54",
                        left: "112.86",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "170.52",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "both:",
                        width: "24.67",
                        height: "10.54",
                        left: "187.51",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(a)",
                        width: "13.32",
                        height: "10.54",
                        left: "215.18",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "making",
                        width: "35.99",
                        height: "10.54",
                        left: "231.5",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "270.49",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arrangements",
                        width: "64.63",
                        height: "10.54",
                        left: "285.49",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "necessary",
                        width: "46.64",
                        height: "10.54",
                        left: "353.12",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "402.76",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "419.75",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "440.75",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "453.09",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "478.74",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "512.38",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.72",
                        top: "318.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website;",
                        width: "41.69",
                        height: "10.54",
                        left: "72",
                        top: "304.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "120.44",
                        top: "304.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(b)",
                        width: "13.99",
                        height: "10.54",
                        left: "144.52",
                        top: "304.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ensuring",
                        width: "41.32",
                        height: "10.54",
                        left: "165.26",
                        top: "304.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "213.33",
                        top: "304.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "238.08",
                        top: "304.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "persons",
                        width: "36.66",
                        height: "10.54",
                        left: "256.82",
                        top: "304.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.67",
                        height: "10.54",
                        left: "300.23",
                        top: "304.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "327.65",
                        top: "304.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.67",
                        height: "10.54",
                        left: "365.04",
                        top: "304.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "386.46",
                        top: "304.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "431.56",
                        top: "304.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "474.89",
                        top: "304.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "internet",
                        width: "36.65",
                        height: "10.54",
                        left: "502.89",
                        top: "304.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "connection",
                        width: "52.65",
                        height: "10.54",
                        left: "72",
                        top: "290.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.64",
                        height: "10.54",
                        left: "127.65",
                        top: "290.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "aware",
                        width: "28.65",
                        height: "10.54",
                        left: "145.29",
                        top: "290.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "176.94",
                        top: "290.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "189.93",
                        top: "290.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "217.59",
                        top: "290.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "250.41",
                        top: "290.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "263.4",
                        top: "290.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "285.06",
                        top: "290.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "comply",
                        width: "35.99",
                        height: "10.54",
                        left: "305.39",
                        top: "290.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "344.38",
                        top: "290.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "them.",
                        width: "26.99",
                        height: "10.54",
                        left: "368.72",
                        top: "290.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.67",
                        height: "10.54",
                        left: "95.21",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "119.63",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "139.37",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "157.78",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.66",
                        height: "10.54",
                        left: "199.89",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "registering",
                        width: "51.32",
                        height: "10.54",
                        left: "240.3",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "295.37",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "313.11",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account.",
                        width: "40.31",
                        height: "10.54",
                        left: "328.19",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "371.5",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.65",
                        height: "10.54",
                        left: "386.99",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "420.64",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "participate",
                        width: "50.64",
                        height: "10.54",
                        left: "440.96",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "494.6",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "506.94",
                        top: "263.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "features",
                        width: "37.97",
                        height: "10.54",
                        left: "72",
                        top: "249.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "115.22",
                        top: "249.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "130.47",
                        top: "249.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "150.38",
                        top: "249.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "196.99",
                        top: "249.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "220.24",
                        top: "249.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "need",
                        width: "22.66",
                        height: "10.54",
                        left: "243.4",
                        top: "249.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "270.56",
                        top: "249.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "create",
                        width: "28.64",
                        height: "10.54",
                        left: "284.39",
                        top: "249.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.3200000000001",
                        height: "10.54",
                        left: "317.53",
                        top: "249.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "password­protected",
                        width: "93.97",
                        height: "10.54",
                        left: "327.35",
                        top: "249.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account.",
                        width: "40.31",
                        height: "10.54",
                        left: "425.82",
                        top: "249.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "In",
                        width: "10",
                        height: "10.54",
                        left: "470.63",
                        top: "249.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "creating",
                        width: "38.64",
                        height: "10.54",
                        left: "485.13",
                        top: "249.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "528.27",
                        top: "249.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "account,",
                        width: "40.31",
                        height: "10.54",
                        left: "72",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "117.56",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "140.81",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "166.72",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "asked",
                        width: "27.32",
                        height: "10.54",
                        left: "183.3",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "215.12",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.65",
                        height: "10.54",
                        left: "228.96",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "270.11",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "registration",
                        width: "54.65",
                        height: "10.54",
                        left: "307.25",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "details",
                        width: "31.33",
                        height: "10.54",
                        left: "366.4",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "402.23",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "416.72",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information.",
                        width: "59.66",
                        height: "10.54",
                        left: "445.88",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "It",
                        width: "7.33",
                        height: "10.54",
                        left: "510.04",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "521.87",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "534.37",
                        top: "235.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "condition",
                        width: "45.33",
                        height: "10.54",
                        left: "72",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "122.58",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "137.82",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "165.07",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "186.32",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "201.56",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "221.47",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "265.08",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "288.32",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "305.57",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "325.48",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "387.38",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "410.63",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "452.54",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "469.79",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "488.95",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "531.8",
                        top: "221.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "correct,",
                        width: "36.3",
                        height: "10.54",
                        left: "72",
                        top: "207.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "current,",
                        width: "36.98",
                        height: "10.54",
                        left: "112.05",
                        top: "207.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "152.78",
                        top: "207.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "complete.",
                        width: "46.98",
                        height: "10.54",
                        left: "173.86",
                        top: "207.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "224.59",
                        top: "207.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.98",
                        height: "10.54",
                        left: "247.8",
                        top: "207.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "277.53",
                        top: "207.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "299.27",
                        top: "207.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "315.02",
                        top: "207.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "375.42",
                        top: "207.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "397.17",
                        top: "207.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "436.83",
                        top: "207.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "register",
                        width: "35.98",
                        height: "10.54",
                        left: "449.16",
                        top: "207.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "488.14",
                        top: "207.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "502.47",
                        top: "207.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "72",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "97.83",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "116.99",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "159.85",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise,",
                        width: "49.65",
                        height: "10.54",
                        left: "174.35",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including,",
                        width: "48.33",
                        height: "10.54",
                        left: "228.5",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "but",
                        width: "15.33",
                        height: "10.54",
                        left: "281.33",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "301.16",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limited",
                        width: "34",
                        height: "10.54",
                        left: "320.99",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to,",
                        width: "12.33",
                        height: "10.54",
                        left: "358.74",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "374.82",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "415.9",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "434.31",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "454.06",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "467.81",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interactive",
                        width: "50.64",
                        height: "10.54",
                        left: "488.88",
                        top: "194.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "features",
                        width: "37.97",
                        height: "10.54",
                        left: "72",
                        top: "180.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "122.72",
                        top: "180.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "147.47",
                        top: "180.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "174.88",
                        top: "180.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "228.99",
                        top: "180.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "governed",
                        width: "44.65",
                        height: "10.54",
                        left: "249.74",
                        top: "180.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "307.14",
                        top: "180.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "331.89",
                        top: "180.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRIVACY",
                        width: "47.1",
                        height: "10.54",
                        left: "360.64",
                        top: "180.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "POLICY,",
                        width: "43.24",
                        height: "10.54",
                        left: "420.49",
                        top: "180.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.31",
                        height: "10.54",
                        left: "475.73",
                        top: "180.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.6600000000001",
                        height: "10.54",
                        left: "531.04",
                        top: "180.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "https://mooreexotics.com/policy",
                        width: "162.08",
                        height: "10.54",
                        left: "72",
                        top: "166.44",
                        color: "#1154cc",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                        link: "https://mooreexotics.com/policy",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "243.08",
                        top: "166.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "264.91",
                        top: "166.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "consent",
                        width: "36.65",
                        height: "10.54",
                        left: "287.41",
                        top: "166.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "328.56",
                        top: "166.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "342.4",
                        top: "166.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "actions",
                        width: "33.99",
                        height: "10.54",
                        left: "358.89",
                        top: "166.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "397.38",
                        top: "166.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "take",
                        width: "19.98",
                        height: "10.54",
                        left: "415.88",
                        top: "166.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "440.36",
                        top: "166.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respect",
                        width: "33.97",
                        height: "10.54",
                        left: "466.2",
                        top: "166.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "504.67",
                        top: "166.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "517.76",
                        top: "166.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "72",
                        top: "152.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "consistent",
                        width: "48",
                        height: "10.54",
                        left: "131.65",
                        top: "152.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "182.65",
                        top: "152.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "206.98",
                        top: "152.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRIVACY",
                        width: "47.1",
                        height: "10.54",
                        left: "225.98",
                        top: "152.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "POLICY",
                        width: "41.35",
                        height: "10.54",
                        left: "276.08",
                        top: "152.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "317.43",
                        top: "152.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "72",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "83.74",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "choose,",
                        width: "36.32",
                        height: "10.54",
                        left: "105.49",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "145.56",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "159.31",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided",
                        width: "42.66",
                        height: "10.54",
                        left: "177.71",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with,",
                        width: "24.33",
                        height: "10.54",
                        left: "224.12",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "252.2",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "username,",
                        width: "48.97",
                        height: "10.54",
                        left: "261.28",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "password,",
                        width: "48.33",
                        height: "10.54",
                        left: "314",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "366.08",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "379.83",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "400.9",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "piece",
                        width: "25.31",
                        height: "10.54",
                        left: "428.56",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "456.87",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "469.87",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "529.52",
                        top: "124.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "part",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "111.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "95.16",
                        top: "111.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "109.65",
                        top: "111.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "security",
                        width: "37.98",
                        height: "10.54",
                        left: "130.15",
                        top: "111.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "procedures,",
                        width: "55.65",
                        height: "10.54",
                        left: "172.63",
                        top: "111.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "232.78",
                        top: "111.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.33",
                        height: "10.54",
                        left: "255.28",
                        top: "111.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "treat",
                        width: "21.32",
                        height: "10.54",
                        left: "283.11",
                        top: "111.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "308.93",
                        top: "111.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "335.43",
                        top: "111.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "395.83",
                        top: "111.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "confidential,",
                        width: "60.31",
                        height: "10.54",
                        left: "409.58",
                        top: "111.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "473.64",
                        top: "111.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "494.71",
                        top: "111.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.34",
                        height: "10.54",
                        left: "516.46",
                        top: "111.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "72",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclose",
                        width: "38.66",
                        height: "10.54",
                        left: "91.08",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "6.67",
                        height: "10.54",
                        left: "133.49",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "143.91",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "157",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "178.07",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "person",
                        width: "31.99",
                        height: "10.54",
                        left: "206.48",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "242.22",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "entity.",
                        width: "29.55",
                        height: "10.54",
                        left: "255.97",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "289.27",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "19.33",
                        height: "10.54",
                        left: "312.48",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "acknowledge",
                        width: "63.31",
                        height: "10.54",
                        left: "335.56",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "401.87",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "422.86",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "447.86",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "488.17",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "499.17",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "72",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "85.83",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "108.33",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "130.16",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "160.63",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "180.47",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "194.3",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "235.46",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "257.29",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "person",
                        width: "31.99",
                        height: "10.54",
                        left: "286.44",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "322.93",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "348.77",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "383.91",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "397.75",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "416.91",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "459.76",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "portions",
                        width: "39.33",
                        height: "10.54",
                        left: "474.26",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "518.09",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "6.67",
                        height: "10.54",
                        left: "532.59",
                        top: "83.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,720",
                    "lineto,540,720",
                    "lineto,540,691",
                    "lineto,72,691",
                    "lineto,72,720",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,692",
                    "lineto,540,692",
                    "lineto,540,677",
                    "lineto,72,677",
                    "lineto,72,692",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,678",
                    "lineto,540,678",
                    "lineto,540,663",
                    "lineto,72,663",
                    "lineto,72,678",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,665",
                    "lineto,540,665",
                    "lineto,540,650",
                    "lineto,72,650",
                    "lineto,72,665",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,651",
                    "lineto,540,651",
                    "lineto,540,636",
                    "lineto,72,636",
                    "lineto,72,651",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,637",
                    "lineto,540,637",
                    "lineto,540,608",
                    "lineto,72,608",
                    "lineto,72,637",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,609",
                    "lineto,540,609",
                    "lineto,540,594",
                    "lineto,72,594",
                    "lineto,72,609",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,595",
                    "lineto,540,595",
                    "lineto,540,580",
                    "lineto,72,580",
                    "lineto,72,595",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,582",
                    "lineto,540,582",
                    "lineto,540,567",
                    "lineto,72,567",
                    "lineto,72,582",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,568",
                    "lineto,540,568",
                    "lineto,540,539",
                    "lineto,72,539",
                    "lineto,72,568",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,540",
                    "lineto,540,540",
                    "lineto,540,511",
                    "lineto,72,511",
                    "lineto,72,540",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,512",
                    "lineto,540,512",
                    "lineto,540,497",
                    "lineto,72,497",
                    "lineto,72,512",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,498",
                    "lineto,540,498",
                    "lineto,540,483",
                    "lineto,72,483",
                    "lineto,72,498",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,485",
                    "lineto,540,485",
                    "lineto,540,470",
                    "lineto,72,470",
                    "lineto,72,485",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,471",
                    "lineto,540,471",
                    "lineto,540,442",
                    "lineto,72,442",
                    "lineto,72,471",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,443",
                    "lineto,540,443",
                    "lineto,540,414",
                    "lineto,72,414",
                    "lineto,72,443",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,415",
                    "lineto,540,415",
                    "lineto,540,400",
                    "lineto,72,400",
                    "lineto,72,415",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,401",
                    "lineto,540,401",
                    "lineto,540,386",
                    "lineto,72,386",
                    "lineto,72,401",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,388",
                    "lineto,540,388",
                    "lineto,540,373",
                    "lineto,72,373",
                    "lineto,72,388",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,374",
                    "lineto,540,374",
                    "lineto,540,359",
                    "lineto,72,359",
                    "lineto,72,374",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,360",
                    "lineto,540,360",
                    "lineto,540,331",
                    "lineto,72,331",
                    "lineto,72,360",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,332",
                    "lineto,540,332",
                    "lineto,540,317",
                    "lineto,72,317",
                    "lineto,72,332",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,318",
                    "lineto,540,318",
                    "lineto,540,303",
                    "lineto,72,303",
                    "lineto,72,318",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,305",
                    "lineto,540,305",
                    "lineto,540,282",
                    "lineto,72,282",
                    "lineto,72,305",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,283",
                    "lineto,540,283",
                    "lineto,540,262",
                    "lineto,72,262",
                    "lineto,72,283",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,263",
                    "lineto,540,263",
                    "lineto,540,248",
                    "lineto,72,248",
                    "lineto,72,263",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,249",
                    "lineto,540,249",
                    "lineto,540,234",
                    "lineto,72,234",
                    "lineto,72,249",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,235",
                    "lineto,540,235",
                    "lineto,540,220",
                    "lineto,72,220",
                    "lineto,72,235",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,222",
                    "lineto,540,222",
                    "lineto,540,207",
                    "lineto,72,207",
                    "lineto,72,222",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,208",
                    "lineto,540,208",
                    "lineto,540,193",
                    "lineto,72,193",
                    "lineto,72,208",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,194",
                    "lineto,540,194",
                    "lineto,540,179",
                    "lineto,72,179",
                    "lineto,72,194",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,180",
                    "lineto,540,180",
                    "lineto,540,165",
                    "lineto,72,165",
                    "lineto,72,180",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,166",
                    "lineto,540,166",
                    "lineto,540,137",
                    "lineto,72,137",
                    "lineto,72,166",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,139",
                    "lineto,540,139",
                    "lineto,540,124",
                    "lineto,72,124",
                    "lineto,72,139",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,125",
                    "lineto,540,125",
                    "lineto,540,110",
                    "lineto,72,110",
                    "lineto,72,125",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,111",
                    "lineto,540,111",
                    "lineto,540,96",
                    "lineto,72,96",
                    "lineto,72,111",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,97",
                    "lineto,540,97",
                    "lineto,540,82",
                    "lineto,72,82",
                    "lineto,72,97",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,707.5", "lineto,218,707.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,638.5", "lineto,234,638.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,527.5", "lineto,140,527.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#1154cc",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,318,485.5", "lineto,481,485.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,430.5", "lineto,298,430.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#1154cc",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,167.5", "lineto,234,167.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "3",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "using",
                        width: "26",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "106.25",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "username,",
                        width: "48.98",
                        height: "10.54",
                        left: "136.5",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "password,",
                        width: "48.33",
                        height: "10.54",
                        left: "193.73",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "250.31",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "268.55",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "security",
                        width: "37.99",
                        height: "10.54",
                        left: "301.46",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information.",
                        width: "59.65",
                        height: "10.54",
                        left: "347.7",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "415.6",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.98",
                        height: "10.54",
                        left: "442.56",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "476.04",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notify",
                        width: "28.67",
                        height: "10.54",
                        left: "492.87",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "529.04",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "immediately",
                        width: "59.98",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "136.48",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "150.98",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unauthorized",
                        width: "62.65",
                        height: "10.54",
                        left: "172.8",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "239.95",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "275.09",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "288.93",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "303.42",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "323.92",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "338.41",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "username",
                        width: "45.98",
                        height: "10.54",
                        left: "364.91",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "415.39",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "password",
                        width: "45.33",
                        height: "10.54",
                        left: "429.88",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "479.71",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "494.21",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "515.29",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "breach",
                        width: "31.97",
                        height: "10.54",
                        left: "72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "108.47",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "security.",
                        width: "40.21",
                        height: "10.54",
                        left: "122.97",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "167.68",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "19.33",
                        height: "10.54",
                        left: "191.64",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.98",
                        height: "10.54",
                        left: "214.72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "244.45",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ensure",
                        width: "31.32",
                        height: "10.54",
                        left: "257.53",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "292.6",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "314.34",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "exit",
                        width: "18",
                        height: "10.54",
                        left: "336.09",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.32",
                        height: "10.54",
                        left: "357.84",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "384.91",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "410.66",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "451.72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "464.13",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "end",
                        width: "17.33",
                        height: "10.54",
                        left: "482.54",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "503.62",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "each",
                        width: "21.97",
                        height: "10.54",
                        left: "517.37",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "session.",
                        width: "37.67",
                        height: "10.55",
                        left: "72",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.55",
                        left: "113.42",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "should",
                        width: "32.01",
                        height: "10.55",
                        left: "136.63",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.55",
                        left: "172.39",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "caution",
                        width: "35.33",
                        height: "10.55",
                        left: "192.13",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.55",
                        left: "231.21",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accessing",
                        width: "45.98",
                        height: "10.55",
                        left: "260.95",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.55",
                        left: "310.68",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.55",
                        left: "336.42",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.55",
                        left: "377.48",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.55",
                        left: "404.56",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "public",
                        width: "29.99",
                        height: "10.55",
                        left: "413.64",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "446.63",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shared",
                        width: "31.32",
                        height: "10.55",
                        left: "459.63",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "computer",
                        width: "45.31",
                        height: "10.55",
                        left: "493.95",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "so",
                        width: "10.67",
                        height: "10.54",
                        left: "72",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "85.67",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "others",
                        width: "29.33",
                        height: "10.54",
                        left: "106.66",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "138.99",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "156.64",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "able",
                        width: "19.99",
                        height: "10.54",
                        left: "174.97",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "197.96",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "view",
                        width: "23.33",
                        height: "10.54",
                        left: "210.29",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "236.62",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "record",
                        width: "30.64",
                        height: "10.54",
                        left: "249.62",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "283.26",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "password",
                        width: "45.32",
                        height: "10.54",
                        left: "308.26",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "356.58",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "369.58",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "397.24",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information.",
                        width: "59.65",
                        height: "10.54",
                        left: "440.89",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "91.44",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "117.84",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.67",
                        height: "10.54",
                        left: "136.25",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "162.67",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disable",
                        width: "33.99",
                        height: "10.54",
                        left: "175.75",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "213.49",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "username,",
                        width: "48.98",
                        height: "10.54",
                        left: "234.57",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "password,",
                        width: "48.33",
                        height: "10.54",
                        left: "287.3",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "338.63",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "351.62",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identifier,",
                        width: "46.5",
                        height: "10.54",
                        left: "379.28",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "whether",
                        width: "38.65",
                        height: "10.54",
                        left: "428.78",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "chosen",
                        width: "33.32",
                        height: "10.54",
                        left: "470.43",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "506.75",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "521.75",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided",
                        width: "42.65",
                        height: "10.54",
                        left: "86.5",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "133.65",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us,",
                        width: "13.67",
                        height: "10.54",
                        left: "150.15",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "168.32",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "181.48",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "203.31",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "229.14",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "242.97",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "263.47",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion",
                        width: "47.32",
                        height: "10.54",
                        left: "287.3",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "339.12",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "357.61",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "379.44",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "393.93",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reason,",
                        width: "34.32",
                        height: "10.54",
                        left: "409.68",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "447.75",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if,",
                        width: "10.33",
                        height: "10.54",
                        left: "496.83",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "510.91",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "523.99",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "opinion,",
                        width: "39.67",
                        height: "10.54",
                        left: "72",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "114.67",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "135.67",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "violated",
                        width: "38.65",
                        height: "10.54",
                        left: "161.32",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "202.97",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provision",
                        width: "45.33",
                        height: "10.54",
                        left: "223.3",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "271.63",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "284.63",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "312.29",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "345.11",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use.",
                        width: "21.66",
                        height: "10.54",
                        left: "358.1",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Intellectual",
                        width: "57.99",
                        height: "10.54",
                        left: "72",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Property",
                        width: "45.76",
                        height: "10.54",
                        left: "132.99",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "95.16",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "138.01",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "11.34",
                        height: "10.54",
                        left: "159.84",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "entire",
                        width: "27.31",
                        height: "10.54",
                        left: "175.68",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contents,",
                        width: "42.99",
                        height: "10.54",
                        left: "207.49",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "features,",
                        width: "40.98",
                        height: "10.54",
                        left: "254.98",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "299.71",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "functionality",
                        width: "61.32",
                        height: "10.54",
                        left: "320.78",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(including",
                        width: "49.33",
                        height: "10.54",
                        left: "385.85",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "but",
                        width: "15.33",
                        height: "10.54",
                        left: "438.93",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "458.01",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limited",
                        width: "34",
                        height: "10.54",
                        left: "477.09",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.3299999999999",
                        height: "10.54",
                        left: "514.84",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "527.92",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information,",
                        width: "59.65",
                        height: "10.54",
                        left: "72",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "software,",
                        width: "44.32",
                        height: "10.54",
                        left: "136.9",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "text,",
                        width: "20.99",
                        height: "10.54",
                        left: "186.47",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "displays,",
                        width: "42.34",
                        height: "10.54",
                        left: "212.71",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "images,",
                        width: "36.99",
                        height: "10.54",
                        left: "260.3",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "video,",
                        width: "29.66",
                        height: "10.54",
                        left: "302.54",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "336.7",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "audio,",
                        width: "29.66",
                        height: "10.54",
                        left: "358.52",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "392.68",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "414.51",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "design,",
                        width: "34.33",
                        height: "10.54",
                        left: "433.67",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "selection,",
                        width: "45.65",
                        height: "10.54",
                        left: "472.5",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "522.65",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "arrangement",
                        width: "59.96",
                        height: "10.55",
                        left: "72",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "thereof)",
                        width: "37.98",
                        height: "10.55",
                        left: "137.96",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.55",
                        left: "181.94",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owned",
                        width: "31.99",
                        height: "10.55",
                        left: "202.59",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.55",
                        left: "240.58",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.55",
                        left: "257.83",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics,",
                        width: "39",
                        height: "10.55",
                        left: "295.07",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "11.33",
                        height: "10.55",
                        left: "339.32",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "licensors,",
                        width: "45.66",
                        height: "10.55",
                        left: "355.9",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "406.81",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.55",
                        left: "422.06",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providers",
                        width: "45.33",
                        height: "10.55",
                        left: "451.96",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.55",
                        left: "502.54",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.55",
                        left: "517.78",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "72",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(collectively,",
                        width: "62.19",
                        height: "10.54",
                        left: "115.81",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "“",
                        width: "5.33",
                        height: "10.54",
                        left: "182.5",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "41.33",
                        height: "10.54",
                        left: "187.83",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”).",
                        width: "12.32",
                        height: "10.54",
                        left: "229.16",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "245.98",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "269.14",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "311.99",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "11.34",
                        height: "10.54",
                        left: "333.82",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "349.66",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.64",
                        height: "10.54",
                        left: "392.16",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "protected",
                        width: "44.65",
                        height: "10.54",
                        left: "410.55",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "458.95",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "United",
                        width: "32.66",
                        height: "10.54",
                        left: "474.7",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "States",
                        width: "28.66",
                        height: "10.54",
                        left: "511.11",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "484.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "international",
                        width: "60.64",
                        height: "10.54",
                        left: "94.58",
                        top: "484.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copyright,",
                        width: "48.99",
                        height: "10.54",
                        left: "160.47",
                        top: "484.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "trademark,",
                        width: "51.64",
                        height: "10.54",
                        left: "214.71",
                        top: "484.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "patent,",
                        width: "32.32",
                        height: "10.54",
                        left: "271.6",
                        top: "484.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "trade",
                        width: "23.98",
                        height: "10.54",
                        left: "309.17",
                        top: "484.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "secret,",
                        width: "30.98",
                        height: "10.54",
                        left: "338.4",
                        top: "484.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "374.63",
                        top: "484.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "397.21",
                        top: "484.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "intellectual",
                        width: "53.31",
                        height: "10.54",
                        left: "426.36",
                        top: "484.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "property",
                        width: "40.65",
                        height: "10.54",
                        left: "484.17",
                        top: "484.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.32",
                        top: "484.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "proprietary",
                        width: "53.31",
                        height: "10.54",
                        left: "72",
                        top: "471.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights",
                        width: "27.33",
                        height: "10.54",
                        left: "128.31",
                        top: "471.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "laws.",
                        width: "25",
                        height: "10.54",
                        left: "158.64",
                        top: "471.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "grant",
                        width: "24.66",
                        height: "10.54",
                        left: "91.44",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "119.1",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "140.1",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limited,",
                        width: "37",
                        height: "10.54",
                        left: "148.42",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "non­exclusive,",
                        width: "70.31",
                        height: "10.54",
                        left: "188.42",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "non­transferable,",
                        width: "81.63",
                        height: "10.54",
                        left: "261.73",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "346.36",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "revocable",
                        width: "46.63",
                        height: "10.54",
                        left: "366.69",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "license",
                        width: "33.32",
                        height: "10.54",
                        left: "416.32",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "452.64",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "464.97",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "483.97",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "501.63",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "11.33",
                        height: "10.54",
                        left: "93.08",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "108.16",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "–",
                        width: "6",
                        height: "10.54",
                        left: "149.91",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "subject",
                        width: "33.99",
                        height: "10.54",
                        left: "159.66",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "197.4",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "210.49",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "238.89",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "272.46",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use.",
                        width: "21.66",
                        height: "10.54",
                        left: "286.21",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Except",
                        width: "33.32",
                        height: "10.54",
                        left: "311.62",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "348.69",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "361.68",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "389.34",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "422.16",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "435.15",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "expressly",
                        width: "45.33",
                        height: "10.54",
                        left: "456.81",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "permit,",
                        width: "34.32",
                        height: "10.54",
                        left: "505.14",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "72",
                        top: "415.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.34",
                        height: "10.54",
                        left: "93",
                        top: "415.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not:",
                        width: "18.67",
                        height: "10.54",
                        left: "119.34",
                        top: "415.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "388.3",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Copy,",
                        width: "28.22",
                        height: "10.54",
                        left: "108",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reproduce,",
                        width: "50.98",
                        height: "10.54",
                        left: "140.72",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "download,",
                        width: "50.32",
                        height: "10.54",
                        left: "196.2",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "251.02",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "store",
                        width: "23.32",
                        height: "10.54",
                        left: "265.52",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "293.34",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content,",
                        width: "41",
                        height: "10.54",
                        left: "314.42",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "except",
                        width: "31.31",
                        height: "10.54",
                        left: "359.17",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "394.23",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "follows:",
                        width: "39.33",
                        height: "10.54",
                        left: "407.98",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(1)",
                        width: "13.99",
                        height: "10.54",
                        left: "451.06",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "468.8",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "computer",
                        width: "45.32",
                        height: "10.54",
                        left: "494.55",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "108",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "temporarily",
                        width: "55.98",
                        height: "10.54",
                        left: "132.41",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "store",
                        width: "23.33",
                        height: "10.54",
                        left: "192.14",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copies",
                        width: "30.65",
                        height: "10.54",
                        left: "219.22",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "253.62",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "267.37",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials",
                        width: "43.98",
                        height: "10.54",
                        left: "293.12",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "340.85",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "RAM",
                        width: "27.34",
                        height: "10.54",
                        left: "353.93",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "incidental",
                        width: "47.31",
                        height: "10.54",
                        left: "385.02",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "436.08",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "448.42",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accessing",
                        width: "45.98",
                        height: "10.54",
                        left: "473.41",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "522.39",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "viewing",
                        width: "38.66",
                        height: "10.55",
                        left: "108",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "those",
                        width: "25.33",
                        height: "10.55",
                        left: "151.16",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials;",
                        width: "47.31",
                        height: "10.55",
                        left: "180.99",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(2)",
                        width: "14",
                        height: "10.55",
                        left: "232.8",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "251.3",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "273.8",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "store",
                        width: "23.32",
                        height: "10.55",
                        left: "298.96",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "files",
                        width: "20.66",
                        height: "10.55",
                        left: "326.78",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.55",
                        left: "351.94",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.55",
                        left: "374.44",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "automatically",
                        width: "65.3",
                        height: "10.55",
                        left: "393.59",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cached",
                        width: "33.31",
                        height: "10.55",
                        left: "463.39",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.55",
                        left: "501.2",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "517.7",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Web",
                        width: "21.69",
                        height: "10.54",
                        left: "108",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "browser",
                        width: "38.66",
                        height: "10.54",
                        left: "133.44",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "175.1",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "display",
                        width: "34.66",
                        height: "10.54",
                        left: "192.09",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enhancement",
                        width: "63.3",
                        height: "10.54",
                        left: "229.75",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes;",
                        width: "46",
                        height: "10.54",
                        left: "296.05",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(3)",
                        width: "13.99",
                        height: "10.54",
                        left: "345.05",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "362.04",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "383.04",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "print",
                        width: "22.66",
                        height: "10.54",
                        left: "406.7",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "432.36",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "download",
                        width: "47.33",
                        height: "10.54",
                        left: "445.36",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "one",
                        width: "17.32",
                        height: "10.54",
                        left: "495.69",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copy",
                        width: "23.33",
                        height: "10.54",
                        left: "516.01",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "108",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "122.5",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reasonable",
                        width: "51.31",
                        height: "10.54",
                        left: "131.57",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "number",
                        width: "36.65",
                        height: "10.54",
                        left: "186.63",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "227.03",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pages",
                        width: "27.32",
                        height: "10.54",
                        left: "240.78",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "271.85",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "285.6",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "304.01",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "345.76",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "363.5",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "own",
                        width: "20.67",
                        height: "10.54",
                        left: "389.24",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal,",
                        width: "43.65",
                        height: "10.54",
                        left: "413.66",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "non­commercial",
                        width: "78.64",
                        height: "10.54",
                        left: "461.06",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "108",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "130.75",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "154.82",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "176.91",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "further",
                        width: "32.65",
                        height: "10.54",
                        left: "196.9",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reproduction,",
                        width: "64.31",
                        height: "10.54",
                        left: "235.55",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "publication,",
                        width: "56.99",
                        height: "10.54",
                        left: "305.86",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "368.85",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "distribution;",
                        width: "58.67",
                        height: "10.54",
                        left: "384.84",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(4)",
                        width: "14",
                        height: "10.54",
                        left: "449.51",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "469.51",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "482.84",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.65",
                        height: "10.54",
                        left: "502.83",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "desktop,",
                        width: "40.33",
                        height: "10.54",
                        left: "108",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "mobile,",
                        width: "36.33",
                        height: "10.54",
                        left: "152.08",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "192.16",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "205.9",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applications",
                        width: "57.98",
                        height: "10.54",
                        left: "234.31",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.54",
                        left: "296.04",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "download,",
                        width: "50.32",
                        height: "10.54",
                        left: "313.79",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "367.86",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "389.61",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "download",
                        width: "47.33",
                        height: "10.54",
                        left: "414.02",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "464.35",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "single",
                        width: "28.66",
                        height: "10.54",
                        left: "472.68",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copy",
                        width: "23.33",
                        height: "10.54",
                        left: "504.34",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "530.67",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "108",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "computer",
                        width: "45.31",
                        height: "10.54",
                        left: "136",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "187.31",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "mobile",
                        width: "33.33",
                        height: "10.54",
                        left: "203.31",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "device",
                        width: "31.31",
                        height: "10.54",
                        left: "242.64",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "solely",
                        width: "28.66",
                        height: "10.54",
                        left: "279.95",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.54",
                        left: "314.61",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "334.61",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "own",
                        width: "20.67",
                        height: "10.54",
                        left: "362.6",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal,",
                        width: "43.65",
                        height: "10.54",
                        left: "389.27",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "non­commercial",
                        width: "78.63",
                        height: "10.54",
                        left: "438.92",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use,",
                        width: "19",
                        height: "10.54",
                        left: "520.55",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "provided",
                        width: "42.66",
                        height: "10.54",
                        left: "108",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "154.41",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "176.16",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "205.88",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "218.96",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bound",
                        width: "30",
                        height: "10.54",
                        left: "234.04",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "267.79",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "283.54",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "end",
                        width: "17.32",
                        height: "10.54",
                        left: "303.29",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "20",
                        height: "10.54",
                        left: "324.36",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "license",
                        width: "33.31",
                        height: "10.54",
                        left: "347.36",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agreement",
                        width: "49.97",
                        height: "10.54",
                        left: "383.67",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "436.64",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "453.63",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applications;",
                        width: "61.32",
                        height: "10.54",
                        left: "478.63",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "108",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(5)",
                        width: "13.99",
                        height: "10.54",
                        left: "131.33",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "150.57",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "163.15",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "182.39",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "social",
                        width: "27.99",
                        height: "10.54",
                        left: "224.3",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "media",
                        width: "29.32",
                        height: "10.54",
                        left: "257.54",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "features",
                        width: "37.97",
                        height: "10.54",
                        left: "292.11",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "335.33",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "361.92",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content,",
                        width: "38.32",
                        height: "10.54",
                        left: "399.81",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "443.38",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "466.63",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "take",
                        width: "19.99",
                        height: "10.54",
                        left: "492.54",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "517.78",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "actions",
                        width: "33.99",
                        height: "10.54",
                        left: "108",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "144.99",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.64",
                        height: "10.54",
                        left: "157.99",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enabled",
                        width: "37.32",
                        height: "10.54",
                        left: "175.63",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "215.95",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "230.95",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "features",
                        width: "37.98",
                        height: "10.54",
                        left: "255.94",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "236.51",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Modify,",
                        width: "38.22",
                        height: "10.54",
                        left: "108",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "translate,",
                        width: "43.65",
                        height: "10.54",
                        left: "149.22",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "adapt,",
                        width: "28.98",
                        height: "10.54",
                        left: "195.87",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "227.85",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "240.85",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "create",
                        width: "28.64",
                        height: "10.54",
                        left: "290.5",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "derivative",
                        width: "47.97",
                        height: "10.54",
                        left: "322.14",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "works",
                        width: "29.34",
                        height: "10.54",
                        left: "373.11",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "405.45",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "418.44",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content.",
                        width: "41",
                        height: "10.54",
                        left: "438.77",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "222.71",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Distribute,",
                        width: "50.99",
                        height: "10.54",
                        left: "108",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclose,",
                        width: "41.66",
                        height: "10.54",
                        left: "164.24",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sell,",
                        width: "19.67",
                        height: "10.54",
                        left: "211.15",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "publish,",
                        width: "38.34",
                        height: "10.54",
                        left: "235.32",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transfer,",
                        width: "39.16",
                        height: "10.54",
                        left: "278.16",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "publicly",
                        width: "39.33",
                        height: "10.54",
                        left: "321.82",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "display,",
                        width: "36.88",
                        height: "10.54",
                        left: "365.65",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "publicly",
                        width: "39.33",
                        height: "10.54",
                        left: "407.03",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "perform,",
                        width: "41.65",
                        height: "10.54",
                        left: "450.86",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transmit,",
                        width: "42.33",
                        height: "10.54",
                        left: "497.01",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "rent,",
                        width: "21.66",
                        height: "10.54",
                        left: "108",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "lease,",
                        width: "26.98",
                        height: "10.54",
                        left: "132.66",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "lend,",
                        width: "23.66",
                        height: "10.54",
                        left: "162.64",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sublicense,",
                        width: "52.99",
                        height: "10.54",
                        left: "189.3",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "245.29",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "258.28",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.99",
                        height: "10.54",
                        left: "307.93",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.31",
                        height: "10.54",
                        left: "336.92",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "383.23",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "403.55",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "444.55",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "456.88",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "477.21",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "party.",
                        width: "26.87",
                        height: "10.54",
                        left: "502.88",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "195.12",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Reverse",
                        width: "38.65",
                        height: "10.54",
                        left: "108",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "engineer,",
                        width: "43.83",
                        height: "10.54",
                        left: "150.4",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "dissemble,",
                        width: "50.99",
                        height: "10.54",
                        left: "197.98",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "decompile,",
                        width: "52.98",
                        height: "10.54",
                        left: "252.72",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "decode",
                        width: "33.98",
                        height: "10.54",
                        left: "309.45",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "347.18",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "adapt,",
                        width: "28.98",
                        height: "10.54",
                        left: "360.93",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "393.66",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "407.41",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "attempt",
                        width: "35.99",
                        height: "10.54",
                        left: "457.81",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "497.55",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "derive",
                        width: "29.99",
                        height: "10.54",
                        left: "509.88",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "108",
                        top: "180.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "gain",
                        width: "20.66",
                        height: "10.54",
                        left: "121",
                        top: "180.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "144.66",
                        top: "180.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "178.3",
                        top: "180.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "190.63",
                        top: "180.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "source",
                        width: "31.32",
                        height: "10.54",
                        left: "210.96",
                        top: "180.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "code",
                        width: "22.65",
                        height: "10.54",
                        left: "245.28",
                        top: "180.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "270.93",
                        top: "180.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "285.93",
                        top: "180.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "303.59",
                        top: "180.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "344.95",
                        top: "180.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "357.94",
                        top: "180.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content.",
                        width: "41",
                        height: "10.54",
                        left: "378.27",
                        top: "180.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "167.52",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Remove,",
                        width: "42.99",
                        height: "10.54",
                        left: "108",
                        top: "167.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "delete,",
                        width: "31.65",
                        height: "10.54",
                        left: "154.74",
                        top: "167.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "alter,",
                        width: "23.83",
                        height: "10.54",
                        left: "190.14",
                        top: "167.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "217.72",
                        top: "167.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "obscure",
                        width: "37.32",
                        height: "10.54",
                        left: "231.47",
                        top: "167.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "272.54",
                        top: "167.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copyright,",
                        width: "48.99",
                        height: "10.54",
                        left: "293.61",
                        top: "167.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "trademark,",
                        width: "51.64",
                        height: "10.54",
                        left: "346.35",
                        top: "167.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "401.74",
                        top: "167.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "414.74",
                        top: "167.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "intellectual",
                        width: "53.31",
                        height: "10.54",
                        left: "442.39",
                        top: "167.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "property",
                        width: "40.66",
                        height: "10.54",
                        left: "498.7",
                        top: "167.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "108",
                        top: "153.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "proprietary",
                        width: "53.3",
                        height: "10.54",
                        left: "121",
                        top: "153.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights",
                        width: "27.34",
                        height: "10.54",
                        left: "177.3",
                        top: "153.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notices",
                        width: "33.99",
                        height: "10.54",
                        left: "207.64",
                        top: "153.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "244.63",
                        top: "153.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials",
                        width: "43.98",
                        height: "10.54",
                        left: "257.63",
                        top: "153.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "304.61",
                        top: "153.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "319.61",
                        top: "153.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "338.6",
                        top: "153.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "379.96",
                        top: "153.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "392.95",
                        top: "153.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content.",
                        width: "41",
                        height: "10.54",
                        left: "413.28",
                        top: "153.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "139.92",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "108",
                        top: "139.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "131.16",
                        top: "139.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "150.32",
                        top: "139.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "193.18",
                        top: "139.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "207.67",
                        top: "139.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "229.5",
                        top: "139.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "272",
                        top: "139.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(i)",
                        width: "11.33",
                        height: "10.54",
                        left: "290.49",
                        top: "139.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes",
                        width: "42.66",
                        height: "10.54",
                        left: "306.32",
                        top: "139.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "353.48",
                        top: "139.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "developing",
                        width: "53.32",
                        height: "10.54",
                        left: "367.97",
                        top: "139.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "425.79",
                        top: "139.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "competing",
                        width: "50.66",
                        height: "10.54",
                        left: "434.87",
                        top: "139.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "product",
                        width: "36.65",
                        height: "10.54",
                        left: "489.28",
                        top: "139.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.68",
                        top: "139.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "service;",
                        width: "37.31",
                        height: "10.55",
                        left: "108",
                        top: "125.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "148.31",
                        top: "125.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(ii)",
                        width: "14.66",
                        height: "10.55",
                        left: "161.31",
                        top: "125.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.55",
                        left: "178.97",
                        top: "125.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.55",
                        left: "199.29",
                        top: "125.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purpose",
                        width: "37.99",
                        height: "10.55",
                        left: "226.95",
                        top: "125.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.55",
                        left: "267.94",
                        top: "125.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.55",
                        left: "288.94",
                        top: "125.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.55",
                        left: "299.94",
                        top: "125.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.55",
                        left: "312.28",
                        top: "125.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "detriment",
                        width: "45.99",
                        height: "10.55",
                        left: "331.27",
                        top: "125.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.55",
                        left: "380.26",
                        top: "125.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "commercial",
                        width: "56.64",
                        height: "10.55",
                        left: "393.25",
                        top: "125.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disadvantage.",
                        width: "65.64",
                        height: "10.55",
                        left: "452.89",
                        top: "125.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "72",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "83.74",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "print,",
                        width: "25.67",
                        height: "10.54",
                        left: "104.74",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copy,",
                        width: "25.54",
                        height: "10.54",
                        left: "133.41",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "modify,",
                        width: "36.89",
                        height: "10.54",
                        left: "161.95",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "download,",
                        width: "50.32",
                        height: "10.54",
                        left: "201.84",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "255.16",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "268.16",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "317.81",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "336.81",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "349.8",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "389.46",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "409.79",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "person",
                        width: "32",
                        height: "10.54",
                        left: "437.44",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "472.44",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "496.77",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "530.41",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "part",
                        width: "18.65",
                        height: "10.54",
                        left: "93.83",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "116.98",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "131.48",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "150.64",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "193.49",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "207.99",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "37.99",
                        height: "10.54",
                        left: "229.82",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "272.31",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "breach",
                        width: "31.97",
                        height: "10.54",
                        left: "286.15",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "322.62",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "337.12",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.81",
                        height: "10.54",
                        left: "366.28",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "400.59",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use,",
                        width: "21.66",
                        height: "10.54",
                        left: "414.34",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "439.75",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.66",
                        height: "10.54",
                        left: "465.5",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "491.91",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "505",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.74",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,720",
                    "lineto,540,720",
                    "lineto,540,705",
                    "lineto,72,705",
                    "lineto,72,720",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,706",
                    "lineto,540,706",
                    "lineto,540,691",
                    "lineto,72,691",
                    "lineto,72,706",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,692",
                    "lineto,540,692",
                    "lineto,540,677",
                    "lineto,72,677",
                    "lineto,72,692",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,679",
                    "lineto,540,679",
                    "lineto,540,664",
                    "lineto,72,664",
                    "lineto,72,679",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,665",
                    "lineto,540,665",
                    "lineto,540,636",
                    "lineto,72,636",
                    "lineto,72,665",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,637",
                    "lineto,540,637",
                    "lineto,540,622",
                    "lineto,72,622",
                    "lineto,72,637",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,623",
                    "lineto,540,623",
                    "lineto,540,608",
                    "lineto,72,608",
                    "lineto,72,623",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,609",
                    "lineto,540,609",
                    "lineto,540,580",
                    "lineto,72,580",
                    "lineto,72,609",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,582",
                    "lineto,540,582",
                    "lineto,540,553",
                    "lineto,72,553",
                    "lineto,72,582",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,554",
                    "lineto,540,554",
                    "lineto,540,539",
                    "lineto,72,539",
                    "lineto,72,554",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,540",
                    "lineto,540,540",
                    "lineto,540,525",
                    "lineto,72,525",
                    "lineto,72,540",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,526",
                    "lineto,540,526",
                    "lineto,540,511",
                    "lineto,72,511",
                    "lineto,72,526",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,512",
                    "lineto,540,512",
                    "lineto,540,497",
                    "lineto,72,497",
                    "lineto,72,512",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,499",
                    "lineto,540,499",
                    "lineto,540,484",
                    "lineto,72,484",
                    "lineto,72,499",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,485",
                    "lineto,540,485",
                    "lineto,540,456",
                    "lineto,72,456",
                    "lineto,72,485",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,457",
                    "lineto,540,457",
                    "lineto,540,442",
                    "lineto,72,442",
                    "lineto,72,457",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,443",
                    "lineto,540,443",
                    "lineto,540,428",
                    "lineto,72,428",
                    "lineto,72,443",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,429",
                    "lineto,540,429",
                    "lineto,540,414",
                    "lineto,72,414",
                    "lineto,72,429",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,402",
                    "lineto,540,402",
                    "lineto,540,387",
                    "lineto,90,387",
                    "lineto,90,402",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,388",
                    "lineto,540,388",
                    "lineto,540,373",
                    "lineto,90,373",
                    "lineto,90,388",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,374",
                    "lineto,540,374",
                    "lineto,540,359",
                    "lineto,90,359",
                    "lineto,90,374",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,360",
                    "lineto,540,360",
                    "lineto,540,345",
                    "lineto,90,345",
                    "lineto,90,360",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,346",
                    "lineto,540,346",
                    "lineto,540,331",
                    "lineto,90,331",
                    "lineto,90,346",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,333",
                    "lineto,540,333",
                    "lineto,540,318",
                    "lineto,90,318",
                    "lineto,90,333",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,319",
                    "lineto,540,319",
                    "lineto,540,304",
                    "lineto,90,304",
                    "lineto,90,319",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,305",
                    "lineto,540,305",
                    "lineto,540,290",
                    "lineto,90,290",
                    "lineto,90,305",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,291",
                    "lineto,540,291",
                    "lineto,540,276",
                    "lineto,90,276",
                    "lineto,90,291",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,277",
                    "lineto,540,277",
                    "lineto,540,262",
                    "lineto,90,262",
                    "lineto,90,277",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,264",
                    "lineto,540,264",
                    "lineto,540,249",
                    "lineto,90,249",
                    "lineto,90,264",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,250",
                    "lineto,540,250",
                    "lineto,540,235",
                    "lineto,90,235",
                    "lineto,90,250",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,236",
                    "lineto,540,236",
                    "lineto,540,221",
                    "lineto,90,221",
                    "lineto,90,236",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,222",
                    "lineto,540,222",
                    "lineto,540,207",
                    "lineto,90,207",
                    "lineto,90,222",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,208",
                    "lineto,540,208",
                    "lineto,540,193",
                    "lineto,90,193",
                    "lineto,90,208",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,195",
                    "lineto,540,195",
                    "lineto,540,180",
                    "lineto,90,180",
                    "lineto,90,195",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,181",
                    "lineto,540,181",
                    "lineto,540,166",
                    "lineto,90,166",
                    "lineto,90,181",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,167",
                    "lineto,540,167",
                    "lineto,540,152",
                    "lineto,90,152",
                    "lineto,90,167",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,153",
                    "lineto,540,153",
                    "lineto,540,138",
                    "lineto,90,138",
                    "lineto,90,153",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,139",
                    "lineto,540,139",
                    "lineto,540,124",
                    "lineto,90,124",
                    "lineto,90,139",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,112",
                    "lineto,540,112",
                    "lineto,540,97",
                    "lineto,72,97",
                    "lineto,72,112",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,98",
                    "lineto,540,98",
                    "lineto,540,83",
                    "lineto,72,83",
                    "lineto,72,98",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,472.84,332.63",
                    "lineto,539.48,332.63",
                    "lineto,539.48,318.83",
                    "lineto,472.84,318.83",
                    "lineto,472.84,332.63",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,108,318.83",
                    "lineto,540,318.83",
                    "lineto,540,305.03",
                    "lineto,108,305.03",
                    "lineto,108,318.83",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,108,305.03",
                    "lineto,539.55,305.03",
                    "lineto,539.55,291.23",
                    "lineto,108,291.23",
                    "lineto,108,305.03",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,108,291.23",
                    "lineto,539.95,291.23",
                    "lineto,539.95,277.43",
                    "lineto,108,277.43",
                    "lineto,108,291.23",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,108,277.43",
                    "lineto,145.32,277.43",
                    "lineto,145.32,263.63",
                    "lineto,108,263.63",
                    "lineto,108,277.43",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,569.5", "lineto,179,569.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "4",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "114.86",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "136.68",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "179.18",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "stop",
                        width: "20",
                        height: "10.54",
                        left: "202.35",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "immediately",
                        width: "59.98",
                        height: "10.54",
                        left: "226.85",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "291.33",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "313.16",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must,",
                        width: "26.34",
                        height: "10.54",
                        left: "335.66",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "366.5",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "379.66",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "option,",
                        width: "33.67",
                        height: "10.54",
                        left: "399.4",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "return",
                        width: "28.65",
                        height: "10.54",
                        left: "436.82",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "469.22",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "destroy",
                        width: "35.33",
                        height: "10.54",
                        left: "482.97",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "522.05",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "copies",
                        width: "30.66",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "106.41",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "119.4",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "137.06",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "178.06",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "191.06",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "211.38",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials",
                        width: "43.98",
                        height: "10.54",
                        left: "239.04",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "286.02",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "307.02",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made.",
                        width: "28.99",
                        height: "10.54",
                        left: "332.67",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "No",
                        width: "14.66",
                        height: "10.54",
                        left: "364.66",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right,",
                        width: "25.67",
                        height: "10.54",
                        left: "382.32",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "title,",
                        width: "21.66",
                        height: "10.54",
                        left: "410.99",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "435.65",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interest",
                        width: "35.32",
                        height: "10.54",
                        left: "448.65",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "486.97",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "499.3",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "512.3",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.63",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "114.11",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "127.85",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "148.93",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "190.68",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transferred",
                        width: "51.97",
                        height: "10.54",
                        left: "202.43",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "258.15",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you,",
                        width: "21",
                        height: "10.54",
                        left: "271.23",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "295.23",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "315.56",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights",
                        width: "27.33",
                        height: "10.54",
                        left: "330.55",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "360.88",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "expressly",
                        width: "45.32",
                        height: "10.54",
                        left: "379.22",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "granted",
                        width: "35.98",
                        height: "10.54",
                        left: "427.54",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "466.52",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reserved",
                        width: "40.64",
                        height: "10.54",
                        left: "484.17",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "527.81",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "us.",
                        width: "13.67",
                        height: "10.55",
                        left: "72",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Any",
                        width: "20.67",
                        height: "10.55",
                        left: "90.17",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.55",
                        left: "115.34",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "135.83",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "150.33",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.55",
                        left: "169.49",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "212.34",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.55",
                        left: "226.84",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "37.99",
                        height: "10.55",
                        left: "248.67",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.55",
                        left: "290.41",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "expressly",
                        width: "45.32",
                        height: "10.55",
                        left: "309.5",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "permitted",
                        width: "45.98",
                        height: "10.55",
                        left: "358.57",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.55",
                        left: "408.3",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.55",
                        left: "424.05",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.55",
                        left: "452.46",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "486.03",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.55",
                        left: "499.78",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.55",
                        left: "522.19",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.3299999999999",
                        height: "10.55",
                        left: "533.94",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "breach",
                        width: "31.97",
                        height: "10.54",
                        left: "72",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "106.97",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "119.97",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "147.63",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "180.45",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "193.44",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "215.1",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "235.43",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "violate",
                        width: "32.65",
                        height: "10.54",
                        left: "259.09",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copyright,",
                        width: "48.99",
                        height: "10.54",
                        left: "294.74",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "trademark,",
                        width: "51.64",
                        height: "10.54",
                        left: "346.73",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "401.37",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "421.7",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "laws.",
                        width: "24.99",
                        height: "10.54",
                        left: "449.36",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Trademarks",
                        width: "63.11",
                        height: "10.54",
                        left: "72",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "“Moore",
                        width: "37.32",
                        height: "10.54",
                        left: "72",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics”",
                        width: "41.32",
                        height: "10.54",
                        left: "113.82",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "159.64",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "180.71",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "related",
                        width: "32.64",
                        height: "10.54",
                        left: "196.46",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "names,",
                        width: "33.66",
                        height: "10.54",
                        left: "232.85",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "logos,",
                        width: "29",
                        height: "10.54",
                        left: "270.26",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "product",
                        width: "36.66",
                        height: "10.54",
                        left: "303.01",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "343.42",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "33.98",
                        height: "10.54",
                        left: "364.49",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "names,",
                        width: "33.66",
                        height: "10.54",
                        left: "402.22",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "designs,",
                        width: "39",
                        height: "10.54",
                        left: "439.63",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "482.38",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "slogans",
                        width: "36",
                        height: "10.54",
                        left: "503.46",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "72",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "trademarks",
                        width: "53.31",
                        height: "10.54",
                        left: "91.9",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "150.46",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "32",
                        height: "10.54",
                        left: "165.7",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "35.99",
                        height: "10.54",
                        left: "202.95",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "244.19",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "259.44",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "affiliates",
                        width: "41.76",
                        height: "10.54",
                        left: "280.68",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "326.94",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "licensors.",
                        width: "45.65",
                        height: "10.54",
                        left: "341.44",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "391.59",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.34",
                        height: "10.54",
                        left: "415.56",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "443.4",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "463.23",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "483.73",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "marks",
                        width: "29.33",
                        height: "10.54",
                        left: "510.22",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "without",
                        width: "36.67",
                        height: "10.54",
                        left: "72",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "111.67",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prior",
                        width: "23.33",
                        height: "10.54",
                        left: "130.66",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "written",
                        width: "33.99",
                        height: "10.54",
                        left: "156.99",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "permission.",
                        width: "55.66",
                        height: "10.54",
                        left: "193.98",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "All",
                        width: "15.34",
                        height: "10.54",
                        left: "252.64",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "270.98",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "names,",
                        width: "33.66",
                        height: "10.54",
                        left: "298.63",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "logos,",
                        width: "29",
                        height: "10.54",
                        left: "335.29",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "product",
                        width: "36.66",
                        height: "10.54",
                        left: "367.29",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "406.95",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "33.98",
                        height: "10.54",
                        left: "427.28",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "names,",
                        width: "33.65",
                        height: "10.54",
                        left: "464.26",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "designs,",
                        width: "39",
                        height: "10.54",
                        left: "500.91",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "slogans",
                        width: "36",
                        height: "10.54",
                        left: "92.33",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "131.33",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.33",
                        height: "10.54",
                        left: "146.33",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "166.66",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "208.02",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "225.67",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "trademarks",
                        width: "53.31",
                        height: "10.54",
                        left: "243.33",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "299.64",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "their",
                        width: "21.99",
                        height: "10.54",
                        left: "312.63",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respective",
                        width: "48.64",
                        height: "10.54",
                        left: "337.62",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owners.",
                        width: "37.66",
                        height: "10.54",
                        left: "389.26",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Requirements",
                        width: "71.77",
                        height: "10.54",
                        left: "93.66",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "168.43",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Restrictions.",
                        width: "64.32",
                        height: "10.54",
                        left: "190.78",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.55",
                        left: "72",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.67",
                        height: "10.55",
                        left: "95.96",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.55",
                        left: "121.13",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "140.87",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.55",
                        left: "159.28",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "only",
                        width: "21.33",
                        height: "10.55",
                        left: "201.39",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.55",
                        left: "226.47",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "lawful",
                        width: "30.66",
                        height: "10.55",
                        left: "244.21",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes",
                        width: "42.66",
                        height: "10.55",
                        left: "278.62",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "325.03",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.55",
                        left: "346.11",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accordance",
                        width: "53.95",
                        height: "10.55",
                        left: "359.19",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.55",
                        left: "416.89",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.55",
                        left: "441.98",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.55",
                        left: "470.38",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "503.95",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use.",
                        width: "21.66",
                        height: "10.55",
                        left: "517.7",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.98",
                        height: "10.54",
                        left: "94.46",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "123.44",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "141.77",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "154.11",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "173.1",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website:",
                        width: "41.69",
                        height: "10.54",
                        left: "190.76",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "457.29",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "In",
                        width: "10",
                        height: "10.54",
                        left: "108",
                        top: "456.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "124",
                        top: "456.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "way",
                        width: "19.99",
                        height: "10.54",
                        left: "147.32",
                        top: "456.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "173.31",
                        top: "456.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "violates",
                        width: "37.32",
                        height: "10.54",
                        left: "197.31",
                        top: "456.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "240.63",
                        top: "456.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applicable",
                        width: "49.31",
                        height: "10.54",
                        left: "263.96",
                        top: "456.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "federal,",
                        width: "36.3",
                        height: "10.54",
                        left: "319.27",
                        top: "456.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "state,",
                        width: "24.99",
                        height: "10.54",
                        left: "361.57",
                        top: "456.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "local,",
                        width: "26.32",
                        height: "10.54",
                        left: "392.56",
                        top: "456.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "424.88",
                        top: "456.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "international",
                        width: "60.64",
                        height: "10.54",
                        left: "440.88",
                        top: "456.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law",
                        width: "17.33",
                        height: "10.54",
                        left: "506.77",
                        top: "456.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "529.35",
                        top: "456.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "regulation",
                        width: "48.65",
                        height: "10.54",
                        left: "108",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(including,",
                        width: "52.32",
                        height: "10.54",
                        left: "163.4",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.67",
                        height: "10.54",
                        left: "222.47",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limitation,",
                        width: "49.67",
                        height: "10.54",
                        left: "265.89",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "322.31",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "laws",
                        width: "22",
                        height: "10.54",
                        left: "346.38",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "regarding",
                        width: "45.98",
                        height: "10.54",
                        left: "375.13",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "427.86",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "export",
                        width: "30.65",
                        height: "10.54",
                        left: "449.27",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "486.67",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "19.99",
                        height: "10.54",
                        left: "503.42",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "529.41",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "software",
                        width: "41.31",
                        height: "10.54",
                        left: "108",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "153.06",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "166.15",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "187.22",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "214.3",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "US",
                        width: "15.34",
                        height: "10.54",
                        left: "232.71",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "251.8",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "265.55",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "countries).",
                        width: "50.99",
                        height: "10.54",
                        left: "293.95",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.54",
                        left: "352.44",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "example,",
                        width: "43.64",
                        height: "10.54",
                        left: "372.86",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it’s",
                        width: "14.67",
                        height: "10.54",
                        left: "419.5",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "437.17",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsibility",
                        width: "65.33",
                        height: "10.54",
                        left: "462.17",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "530.5",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "obtain",
                        width: "29.99",
                        height: "10.54",
                        left: "108",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "140.99",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "permits",
                        width: "35.99",
                        height: "10.54",
                        left: "161.32",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "200.31",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "licenses",
                        width: "37.99",
                        height: "10.54",
                        left: "213.31",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "254.3",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "275.29",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "300.29",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "340.6",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "360.93",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "business",
                        width: "40.67",
                        height: "10.54",
                        left: "381.25",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "conducted",
                        width: "49.31",
                        height: "10.54",
                        left: "424.92",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "477.23",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "517.56",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "108",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "152.81",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "require,",
                        width: "36.98",
                        height: "10.54",
                        left: "180.97",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "225.45",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "250.28",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "meet",
                        width: "23.32",
                        height: "10.54",
                        left: "267.11",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applicable",
                        width: "49.31",
                        height: "10.54",
                        left: "297.93",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "legal",
                        width: "23.32",
                        height: "10.54",
                        left: "354.74",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "requirements",
                        width: "62.64",
                        height: "10.54",
                        left: "385.56",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "455.7",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "471.79",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applicable",
                        width: "49.31",
                        height: "10.54",
                        left: "490.53",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "jurisdiction(s).",
                        width: "70.32",
                        height: "10.54",
                        left: "108",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "This",
                        width: "21.34",
                        height: "10.54",
                        left: "183.57",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "includes",
                        width: "39.99",
                        height: "10.54",
                        left: "210.16",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "255.4",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sale,",
                        width: "21.65",
                        height: "10.54",
                        left: "275.31",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchase,",
                        width: "45.65",
                        height: "10.54",
                        left: "302.21",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "353.11",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "delivery",
                        width: "39.31",
                        height: "10.54",
                        left: "375.69",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "420.25",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "435.5",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals",
                        width: "37.33",
                        height: "10.54",
                        left: "458.07",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "500.65",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "515.14",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "items.",
                        width: "29",
                        height: "10.54",
                        left: "108",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "146",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "169.96",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "195.12",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sell",
                        width: "16.66",
                        height: "10.54",
                        left: "213.46",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "234.62",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animal",
                        width: "32.65",
                        height: "10.54",
                        left: "256.45",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "293.6",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "308.1",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "item",
                        width: "21.33",
                        height: "10.54",
                        left: "337.25",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "362.33",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "violates",
                        width: "37.32",
                        height: "10.54",
                        left: "384.08",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "425.15",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applicable",
                        width: "49.3",
                        height: "10.54",
                        left: "446.23",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law,",
                        width: "19.55",
                        height: "10.54",
                        left: "499.28",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "522.58",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "108",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "132",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.55",
                        left: "158.66",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "engage",
                        width: "33.98",
                        height: "10.55",
                        left: "179.99",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.55",
                        left: "219.97",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fraud",
                        width: "25.32",
                        height: "10.55",
                        left: "235.31",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(including",
                        width: "49.32",
                        height: "10.55",
                        left: "266.63",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "false",
                        width: "22.65",
                        height: "10.55",
                        left: "321.2",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claims",
                        width: "31.33",
                        height: "10.55",
                        left: "349.1",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.55",
                        left: "385.68",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "infringement",
                        width: "61.98",
                        height: "10.55",
                        left: "400.92",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notices),",
                        width: "40.99",
                        height: "10.55",
                        left: "468.15",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "theft,",
                        width: "24.99",
                        height: "10.55",
                        left: "514.39",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "anti­competitive",
                        width: "78.64",
                        height: "10.54",
                        left: "108",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "conduct,",
                        width: "40.99",
                        height: "10.54",
                        left: "193.39",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "threatening",
                        width: "53.97",
                        height: "10.54",
                        left: "241.13",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "conduct,",
                        width: "40.99",
                        height: "10.54",
                        left: "301.1",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "348.09",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "364.08",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "387.41",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unlawful",
                        width: "42.65",
                        height: "10.54",
                        left: "418.07",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "acts",
                        width: "18.66",
                        height: "10.54",
                        left: "466.72",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "491.38",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "crimes",
                        width: "31.98",
                        height: "10.54",
                        left: "507.38",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "against",
                        width: "33.99",
                        height: "10.54",
                        left: "108",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "144.99",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics,",
                        width: "39",
                        height: "10.54",
                        left: "179.98",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "another",
                        width: "35.98",
                        height: "10.54",
                        left: "221.98",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "260.96",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "36",
                        height: "10.54",
                        left: "295.95",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user,",
                        width: "22.51",
                        height: "10.54",
                        left: "334.95",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "360.46",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "373.45",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "381.78",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "party.",
                        width: "26.88",
                        height: "10.54",
                        left: "407.44",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "319.3",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "engage",
                        width: "33.98",
                        height: "10.54",
                        left: "124.24",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "161.97",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "175.05",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "196.13",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "conduct",
                        width: "37.98",
                        height: "10.54",
                        left: "224.54",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "266.27",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "restricts",
                        width: "37.98",
                        height: "10.54",
                        left: "288.02",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "329",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inhibits",
                        width: "36.01",
                        height: "10.54",
                        left: "342",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "anyone’s",
                        width: "42.65",
                        height: "10.54",
                        left: "381.01",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "426.66",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "445.66",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enjoyment",
                        width: "50.66",
                        height: "10.54",
                        left: "458.65",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "512.31",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.3",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "108",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "152.36",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which,",
                        width: "32.33",
                        height: "10.54",
                        left: "165.35",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "200.68",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "determined",
                        width: "53.98",
                        height: "10.54",
                        left: "213.67",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "270.65",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us,",
                        width: "13.67",
                        height: "10.54",
                        left: "285.65",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "302.32",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "harm",
                        width: "24.66",
                        height: "10.54",
                        left: "325.98",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "353.64",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "367.31",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.66",
                        height: "10.54",
                        left: "380.3",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "407.96",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "420.96",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "438.62",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "479.98",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "expose",
                        width: "33.32",
                        height: "10.54",
                        left: "492.97",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "529.29",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "108",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "them",
                        width: "23.99",
                        height: "10.54",
                        left: "121",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "147.99",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liability.",
                        width: "39.56",
                        height: "10.54",
                        left: "160.32",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "277.91",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transmit,",
                        width: "42.33",
                        height: "10.54",
                        left: "124.24",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "170.32",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "procure",
                        width: "36.64",
                        height: "10.54",
                        left: "184.07",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "223.71",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sending",
                        width: "37.33",
                        height: "10.54",
                        left: "241.37",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of,",
                        width: "13",
                        height: "10.54",
                        left: "281.7",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "297.7",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertising",
                        width: "53.32",
                        height: "10.54",
                        left: "318.02",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "374.34",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promotional",
                        width: "58.66",
                        height: "10.54",
                        left: "387.34",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material,",
                        width: "42.31",
                        height: "10.54",
                        left: "449",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "494.31",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "108",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"junk',
                        width: "26.23",
                        height: "10.54",
                        left: "128.33",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: 'mail,"',
                        width: "29.23",
                        height: "10.54",
                        left: "157.56",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"chain',
                        width: "30.88",
                        height: "10.54",
                        left: "189.79",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: 'letter,"',
                        width: "32.07",
                        height: "10.54",
                        left: "223.67",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"spam,"',
                        width: "38.13",
                        height: "10.54",
                        left: "258.74",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "299.87",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "312.86",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "333.19",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "similar",
                        width: "33.33",
                        height: "10.54",
                        left: "360.84",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "solicitation.",
                        width: "56.33",
                        height: "10.54",
                        left: "397.17",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "250.31",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "impersonate",
                        width: "58.65",
                        height: "10.54",
                        left: "124.24",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "186.64",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "attempt",
                        width: "35.99",
                        height: "10.54",
                        left: "200.38",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "240.12",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "impersonate",
                        width: "58.64",
                        height: "10.54",
                        left: "253.21",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us,",
                        width: "13.67",
                        height: "10.54",
                        left: "315.6",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "333.02",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "354.1",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "367.85",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "employees,",
                        width: "54.32",
                        height: "10.54",
                        left: "387.59",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "another",
                        width: "35.98",
                        height: "10.54",
                        left: "444.91",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user,",
                        width: "22.51",
                        height: "10.54",
                        left: "483.89",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "509.4",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "522.4",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "108",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "person",
                        width: "31.99",
                        height: "10.54",
                        left: "140.16",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "179.65",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "entity",
                        width: "27.33",
                        height: "10.54",
                        left: "197.14",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(including,",
                        width: "52.33",
                        height: "10.54",
                        left: "231.97",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.66",
                        height: "10.54",
                        left: "291.8",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limitation,",
                        width: "49.67",
                        height: "10.54",
                        left: "335.21",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "391.63",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "using",
                        width: "26",
                        height: "10.54",
                        left: "410.38",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.66",
                        height: "10.54",
                        left: "443.13",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "addresses",
                        width: "45.98",
                        height: "10.54",
                        left: "476.54",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.27",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "usernames",
                        width: "50.65",
                        height: "10.54",
                        left: "108",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "associated",
                        width: "49.31",
                        height: "10.54",
                        left: "161.65",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "213.96",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "238.29",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "258.62",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "271.62",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "foregoing).",
                        width: "53.65",
                        height: "10.54",
                        left: "289.28",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Additionally,",
                        width: "62.88",
                        height: "10.54",
                        left: "90",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "155.88",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.98",
                        height: "10.54",
                        left: "176.88",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "205.86",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to:",
                        width: "12.67",
                        height: "10.54",
                        left: "224.19",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "167.12",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "108",
                        top: "166.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "131.91",
                        top: "166.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "151.82",
                        top: "166.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "195.43",
                        top: "166.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "210.01",
                        top: "166.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "manner",
                        width: "35.98",
                        height: "10.54",
                        left: "231.84",
                        top: "166.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "272.32",
                        top: "166.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "could",
                        width: "26.66",
                        height: "10.54",
                        left: "294.81",
                        top: "166.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disable,",
                        width: "36.99",
                        height: "10.54",
                        left: "325.97",
                        top: "166.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "overburden,",
                        width: "57.65",
                        height: "10.54",
                        left: "367.46",
                        top: "166.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "damage,",
                        width: "40.31",
                        height: "10.54",
                        left: "429.61",
                        top: "166.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "474.42",
                        top: "166.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "impair",
                        width: "31.32",
                        height: "10.54",
                        left: "488.92",
                        top: "166.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.74",
                        top: "166.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "108",
                        top: "152.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "150.11",
                        top: "152.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interfere",
                        width: "40.64",
                        height: "10.54",
                        left: "163.85",
                        top: "152.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "208.24",
                        top: "152.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "233.32",
                        top: "152.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "254.4",
                        top: "152.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "party’s",
                        width: "32.66",
                        height: "10.54",
                        left: "282.8",
                        top: "152.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "319.21",
                        top: "152.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "338.96",
                        top: "152.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "352.71",
                        top: "152.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.35",
                        height: "10.54",
                        left: "371.12",
                        top: "152.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "415.47",
                        top: "152.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "463.8",
                        top: "152.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "their",
                        width: "21.99",
                        height: "10.54",
                        left: "484.13",
                        top: "152.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ability",
                        width: "30.66",
                        height: "10.54",
                        left: "509.12",
                        top: "152.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "108",
                        top: "139.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "engage",
                        width: "33.98",
                        height: "10.54",
                        left: "120.33",
                        top: "139.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "157.31",
                        top: "139.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "real",
                        width: "17.98",
                        height: "10.54",
                        left: "169.65",
                        top: "139.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "190.63",
                        top: "139.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activities",
                        width: "43.32",
                        height: "10.54",
                        left: "214.96",
                        top: "139.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "261.28",
                        top: "139.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "301.61",
                        top: "139.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "319.27",
                        top: "139.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "125.72",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "108",
                        top: "125.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "130.41",
                        top: "125.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "robot,",
                        width: "28.33",
                        height: "10.54",
                        left: "151.49",
                        top: "125.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "spider,",
                        width: "31.84",
                        height: "10.54",
                        left: "183.57",
                        top: "125.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "219.16",
                        top: "125.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "232.91",
                        top: "125.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "automatic",
                        width: "47.31",
                        height: "10.54",
                        left: "261.32",
                        top: "125.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "device,",
                        width: "34.31",
                        height: "10.54",
                        left: "311.63",
                        top: "125.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "process,",
                        width: "38.99",
                        height: "10.54",
                        left: "348.94",
                        top: "125.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "390.93",
                        top: "125.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "means",
                        width: "30.65",
                        height: "10.54",
                        left: "403.93",
                        top: "125.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "437.58",
                        top: "125.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "449.92",
                        top: "125.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "483.56",
                        top: "125.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "501.22",
                        top: "125.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "108",
                        top: "111.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "126.49",
                        top: "111.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purpose,",
                        width: "40.99",
                        height: "10.54",
                        left: "148.32",
                        top: "111.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "193.81",
                        top: "111.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "monitoring,",
                        width: "56.33",
                        height: "10.54",
                        left: "243.64",
                        top: "111.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "scraping,",
                        width: "43.65",
                        height: "10.54",
                        left: "304.47",
                        top: "111.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "352.62",
                        top: "111.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copying",
                        width: "38.66",
                        height: "10.54",
                        left: "367.12",
                        top: "111.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "410.28",
                        top: "111.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "432.11",
                        top: "111.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "446.6",
                        top: "111.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "465.76",
                        top: "111.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "509.57",
                        top: "111.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.32",
                        top: "111.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.55",
                        left: "108",
                        top: "97.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "84.32",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "108",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "130.41",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "manual",
                        width: "35.32",
                        height: "10.54",
                        left: "151.49",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "process",
                        width: "35.99",
                        height: "10.54",
                        left: "190.56",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "230.3",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "monitor,",
                        width: "40.52",
                        height: "10.54",
                        left: "242.63",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "scrape,",
                        width: "33.64",
                        height: "10.54",
                        left: "286.15",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "322.79",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copy",
                        width: "23.33",
                        height: "10.54",
                        left: "335.79",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "362.12",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "382.44",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "395.44",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "413.1",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "455.41",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "470.41",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "488.07",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.42",
                        top: "83.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,720",
                    "lineto,540,720",
                    "lineto,540,705",
                    "lineto,72,705",
                    "lineto,72,720",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,706",
                    "lineto,540,706",
                    "lineto,540,691",
                    "lineto,72,691",
                    "lineto,72,706",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,692",
                    "lineto,540,692",
                    "lineto,540,677",
                    "lineto,72,677",
                    "lineto,72,692",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,679",
                    "lineto,540,679",
                    "lineto,540,664",
                    "lineto,72,664",
                    "lineto,72,679",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,665",
                    "lineto,540,665",
                    "lineto,540,636",
                    "lineto,72,636",
                    "lineto,72,665",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,637",
                    "lineto,540,637",
                    "lineto,540,608",
                    "lineto,72,608",
                    "lineto,72,637",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,609",
                    "lineto,540,609",
                    "lineto,540,594",
                    "lineto,72,594",
                    "lineto,72,609",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,595",
                    "lineto,540,595",
                    "lineto,540,580",
                    "lineto,72,580",
                    "lineto,72,595",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,582",
                    "lineto,540,582",
                    "lineto,540,567",
                    "lineto,72,567",
                    "lineto,72,582",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,568",
                    "lineto,540,568",
                    "lineto,540,539",
                    "lineto,72,539",
                    "lineto,72,568",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,540",
                    "lineto,540,540",
                    "lineto,540,511",
                    "lineto,72,511",
                    "lineto,72,540",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,512",
                    "lineto,540,512",
                    "lineto,540,497",
                    "lineto,72,497",
                    "lineto,72,512",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,498",
                    "lineto,540,498",
                    "lineto,540,483",
                    "lineto,72,483",
                    "lineto,72,498",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,471",
                    "lineto,540,471",
                    "lineto,540,456",
                    "lineto,90,456",
                    "lineto,90,471",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,457",
                    "lineto,540,457",
                    "lineto,540,442",
                    "lineto,90,442",
                    "lineto,90,457",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,443",
                    "lineto,540,443",
                    "lineto,540,428",
                    "lineto,90,428",
                    "lineto,90,443",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,429",
                    "lineto,540,429",
                    "lineto,540,414",
                    "lineto,90,414",
                    "lineto,90,429",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,415",
                    "lineto,540,415",
                    "lineto,540,400",
                    "lineto,90,400",
                    "lineto,90,415",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,402",
                    "lineto,540,402",
                    "lineto,540,387",
                    "lineto,90,387",
                    "lineto,90,402",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,388",
                    "lineto,540,388",
                    "lineto,540,373",
                    "lineto,90,373",
                    "lineto,90,388",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,374",
                    "lineto,540,374",
                    "lineto,540,359",
                    "lineto,90,359",
                    "lineto,90,374",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,360",
                    "lineto,540,360",
                    "lineto,540,345",
                    "lineto,90,345",
                    "lineto,90,360",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,346",
                    "lineto,540,346",
                    "lineto,540,331",
                    "lineto,90,331",
                    "lineto,90,346",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,333",
                    "lineto,540,333",
                    "lineto,540,318",
                    "lineto,90,318",
                    "lineto,90,333",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,319",
                    "lineto,540,319",
                    "lineto,540,304",
                    "lineto,90,304",
                    "lineto,90,319",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,305",
                    "lineto,540,305",
                    "lineto,540,290",
                    "lineto,90,290",
                    "lineto,90,305",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,291",
                    "lineto,540,291",
                    "lineto,540,276",
                    "lineto,90,276",
                    "lineto,90,291",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,277",
                    "lineto,540,277",
                    "lineto,540,262",
                    "lineto,90,262",
                    "lineto,90,277",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,264",
                    "lineto,540,264",
                    "lineto,540,249",
                    "lineto,90,249",
                    "lineto,90,264",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,250",
                    "lineto,540,250",
                    "lineto,540,235",
                    "lineto,90,235",
                    "lineto,90,250",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,236",
                    "lineto,540,236",
                    "lineto,540,207",
                    "lineto,90,207",
                    "lineto,90,236",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,208",
                    "lineto,540,208",
                    "lineto,540,179",
                    "lineto,90,179",
                    "lineto,90,208",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,180",
                    "lineto,540,180",
                    "lineto,540,165",
                    "lineto,90,165",
                    "lineto,90,180",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,167",
                    "lineto,540,167",
                    "lineto,540,152",
                    "lineto,90,152",
                    "lineto,90,167",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,153",
                    "lineto,540,153",
                    "lineto,540,138",
                    "lineto,90,138",
                    "lineto,90,153",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,139",
                    "lineto,540,139",
                    "lineto,540,124",
                    "lineto,90,124",
                    "lineto,90,139",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,125",
                    "lineto,540,125",
                    "lineto,540,110",
                    "lineto,90,110",
                    "lineto,90,125",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,111",
                    "lineto,540,111",
                    "lineto,540,96",
                    "lineto,90,96",
                    "lineto,90,111",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,98",
                    "lineto,540,98",
                    "lineto,540,83",
                    "lineto,90,83",
                    "lineto,90,98",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,624.5", "lineto,135,624.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,527.5", "lineto,252,527.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "5",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "108",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "124.99",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "145.32",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unauthorized",
                        width: "62.65",
                        height: "10.54",
                        left: "172.97",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purpose",
                        width: "37.99",
                        height: "10.54",
                        left: "238.62",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.67",
                        height: "10.54",
                        left: "279.61",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "319.28",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prior",
                        width: "23.33",
                        height: "10.54",
                        left: "338.27",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "written",
                        width: "33.99",
                        height: "10.54",
                        left: "364.6",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "consent.",
                        width: "39.66",
                        height: "10.54",
                        left: "401.59",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "692.88",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "108",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "132.66",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "device,",
                        width: "34.31",
                        height: "10.54",
                        left: "155.99",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "software,",
                        width: "44.32",
                        height: "10.54",
                        left: "196.3",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "246.62",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "routine",
                        width: "33.99",
                        height: "10.54",
                        left: "262.61",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "302.6",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interferes",
                        width: "45.3",
                        height: "10.54",
                        left: "326.6",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "377.9",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "405.23",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "proper",
                        width: "31.32",
                        height: "10.54",
                        left: "425.89",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "working",
                        width: "40",
                        height: "10.54",
                        left: "463.21",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "509.21",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.21",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "108",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "665.28",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Introduce",
                        width: "45.98",
                        height: "10.55",
                        left: "108",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.55",
                        left: "159.98",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "viruses,",
                        width: "37",
                        height: "10.55",
                        left: "183.3",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Trojan",
                        width: "31.57",
                        height: "10.55",
                        left: "226.3",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "horses,",
                        width: "33.66",
                        height: "10.55",
                        left: "263.87",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "worms,",
                        width: "35.66",
                        height: "10.55",
                        left: "303.53",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "logic",
                        width: "24",
                        height: "10.55",
                        left: "345.19",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bombs,",
                        width: "35",
                        height: "10.55",
                        left: "375.19",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "416.19",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.55",
                        left: "432.19",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.55",
                        left: "462.84",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.55",
                        left: "508.15",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.55",
                        left: "531.4",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "malicious",
                        width: "46.66",
                        height: "10.54",
                        left: "108",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "157.66",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "technologically",
                        width: "73.98",
                        height: "10.54",
                        left: "170.65",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "harmful.",
                        width: "40.99",
                        height: "10.54",
                        left: "247.63",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.2800000000001",
                        left: "90",
                        top: "637.68",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Attempt",
                        width: "39.33",
                        height: "10.54",
                        left: "108",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "151.08",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "gain",
                        width: "20.66",
                        height: "10.54",
                        left: "163.41",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unauthorized",
                        width: "62.64",
                        height: "10.54",
                        left: "187.07",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.65",
                        height: "10.54",
                        left: "252.71",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to,",
                        width: "12.33",
                        height: "10.54",
                        left: "286.36",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interfere",
                        width: "40.64",
                        height: "10.54",
                        left: "301.69",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with,",
                        width: "24.33",
                        height: "10.54",
                        left: "345.33",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "damage,",
                        width: "40.31",
                        height: "10.54",
                        left: "372.66",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "415.97",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disrupt",
                        width: "33.33",
                        height: "10.54",
                        left: "428.97",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "465.3",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parts",
                        width: "23.33",
                        height: "10.54",
                        left: "485.63",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.9900000000001",
                        height: "10.54",
                        left: "511.96",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.95",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "108",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "153.86",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "server",
                        width: "29.31",
                        height: "10.54",
                        left: "172.27",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "205.33",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "29.33",
                        height: "10.54",
                        left: "221.08",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "254.16",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "272.57",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "314.67",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "stored,",
                        width: "32.32",
                        height: "10.54",
                        left: "326.43",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "362.5",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "376.25",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "server,",
                        width: "31.84",
                        height: "10.54",
                        left: "397.32",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "computer,",
                        width: "47.83",
                        height: "10.54",
                        left: "432.91",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "484.49",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "database",
                        width: "41.31",
                        height: "10.54",
                        left: "498.24",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "connected",
                        width: "48.64",
                        height: "10.54",
                        left: "108",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "159.64",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "171.97",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "189.63",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "596.29",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Attack",
                        width: "31.99",
                        height: "10.54",
                        left: "108",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "142.99",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "160.65",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "via",
                        width: "14.66",
                        height: "10.54",
                        left: "202",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "219.66",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "denial­of­service",
                        width: "81.29",
                        height: "10.54",
                        left: "227.99",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "attack",
                        width: "28.64",
                        height: "10.54",
                        left: "312.28",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "343.92",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "356.92",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "distributed",
                        width: "51.33",
                        height: "10.54",
                        left: "365.24",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "denial­of­service",
                        width: "81.29",
                        height: "10.54",
                        left: "419.57",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "attack.",
                        width: "31.65",
                        height: "10.54",
                        left: "503.86",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "582.49",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Otherwise",
                        width: "49.32",
                        height: "10.54",
                        left: "108",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "attempt",
                        width: "35.99",
                        height: "10.54",
                        left: "160.32",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "199.31",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interfere",
                        width: "40.64",
                        height: "10.54",
                        left: "211.64",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "255.28",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "279.61",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "proper",
                        width: "31.32",
                        height: "10.54",
                        left: "297.27",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "working",
                        width: "39.99",
                        height: "10.54",
                        left: "331.59",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "374.58",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "387.58",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "405.24",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Finally,",
                        width: "36.22",
                        height: "10.54",
                        left: "72",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "113.47",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "136.72",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsible",
                        width: "54.66",
                        height: "10.54",
                        left: "156.62",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "216.53",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "paying",
                        width: "32.66",
                        height: "10.54",
                        left: "235.77",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "273.68",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees",
                        width: "19.32",
                        height: "10.54",
                        left: "290.92",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "315.49",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "338.74",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owe",
                        width: "19.99",
                        height: "10.54",
                        left: "361.99",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "387.23",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "401.81",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics.",
                        width: "39",
                        height: "10.54",
                        left: "439.05",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "In",
                        width: "9.99",
                        height: "10.54",
                        left: "482.55",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "addition,",
                        width: "42.33",
                        height: "10.54",
                        left: "497.04",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "except",
                        width: "31.31",
                        height: "10.55",
                        left: "72",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.55",
                        left: "109.31",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "set",
                        width: "13.33",
                        height: "10.55",
                        left: "125.31",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "forth",
                        width: "23.32",
                        height: "10.55",
                        left: "144.64",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below,",
                        width: "31.55",
                        height: "10.55",
                        left: "173.96",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "211.51",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.55",
                        left: "235.51",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "19.33",
                        height: "10.55",
                        left: "256.16",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "solely",
                        width: "28.66",
                        height: "10.55",
                        left: "281.49",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsible",
                        width: "54.66",
                        height: "10.55",
                        left: "316.15",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.55",
                        left: "376.81",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collecting",
                        width: "47.32",
                        height: "10.55",
                        left: "396.05",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and/or",
                        width: "30.65",
                        height: "10.55",
                        left: "448.62",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "paying",
                        width: "32.66",
                        height: "10.55",
                        left: "484.52",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.55",
                        left: "522.43",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "applicable",
                        width: "49.31",
                        height: "10.54",
                        left: "72",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "taxes",
                        width: "24.65",
                        height: "10.54",
                        left: "125.81",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(including",
                        width: "49.33",
                        height: "10.54",
                        left: "154.96",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "208.79",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "local",
                        width: "23.32",
                        height: "10.54",
                        left: "230.61",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sales",
                        width: "23.33",
                        height: "10.54",
                        left: "258.43",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "taxes)",
                        width: "28.65",
                        height: "10.54",
                        left: "286.26",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "319.41",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "337.9",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchases",
                        width: "47.31",
                        height: "10.54",
                        left: "358.98",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "410.04",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sales",
                        width: "23.33",
                        height: "10.54",
                        left: "423.79",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "450.87",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.98",
                        height: "10.54",
                        left: "472.62",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "502.35",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "72",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "91",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "User",
                        width: "23.99",
                        height: "10.54",
                        left: "72",
                        top: "485.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contributions",
                        width: "72.02",
                        height: "10.54",
                        left: "98.99",
                        top: "485.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "94.41",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "136.51",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "allow",
                        width: "26.66",
                        height: "10.54",
                        left: "160.92",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.66",
                        height: "10.54",
                        left: "191.33",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "219.74",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "post,",
                        width: "23",
                        height: "10.54",
                        left: "232.83",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "submit,",
                        width: "35.67",
                        height: "10.54",
                        left: "258.83",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "publish,",
                        width: "38.34",
                        height: "10.54",
                        left: "297.5",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "display,",
                        width: "36.89",
                        height: "10.54",
                        left: "338.84",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "378.73",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transmit",
                        width: "39.33",
                        height: "10.54",
                        left: "391.72",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "434.05",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "446.38",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.66",
                        height: "10.54",
                        left: "474.04",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "501.7",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "514.7",
                        top: "457.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "persons",
                        width: "36.66",
                        height: "10.54",
                        left: "72",
                        top: "443.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(hereinafter,",
                        width: "58.48",
                        height: "10.54",
                        left: "116.16",
                        top: "443.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "“",
                        width: "5.32",
                        height: "10.54",
                        left: "182.14",
                        top: "443.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "post",
                        width: "21.34",
                        height: "10.54",
                        left: "187.46",
                        top: "443.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”)",
                        width: "9.33",
                        height: "10.54",
                        left: "208.8",
                        top: "443.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "224.88",
                        top: "443.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "266.95",
                        top: "443.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials,",
                        width: "46.98",
                        height: "10.54",
                        left: "283.69",
                        top: "443.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including,",
                        width: "48.33",
                        height: "10.54",
                        left: "337.42",
                        top: "443.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.67",
                        height: "10.54",
                        left: "392.5",
                        top: "443.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limitation,",
                        width: "49.66",
                        height: "10.54",
                        left: "435.92",
                        top: "443.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sales",
                        width: "23.33",
                        height: "10.54",
                        left: "492.33",
                        top: "443.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "522.41",
                        top: "443.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "marketing",
                        width: "48.65",
                        height: "10.54",
                        left: "72",
                        top: "429.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials,",
                        width: "46.98",
                        height: "10.54",
                        left: "128.9",
                        top: "429.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "listing",
                        width: "30.01",
                        height: "10.54",
                        left: "184.13",
                        top: "429.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "photos,",
                        width: "35",
                        height: "10.54",
                        left: "222.39",
                        top: "429.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "listing",
                        width: "30.01",
                        height: "10.54",
                        left: "265.64",
                        top: "429.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "descriptions,",
                        width: "60.99",
                        height: "10.54",
                        left: "303.9",
                        top: "429.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reviews,",
                        width: "40.32",
                        height: "10.54",
                        left: "373.14",
                        top: "429.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "comments,",
                        width: "52.32",
                        height: "10.54",
                        left: "420.96",
                        top: "429.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "suggestions,",
                        width: "59",
                        height: "10.54",
                        left: "480.78",
                        top: "429.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "discussion",
                        width: "50",
                        height: "10.54",
                        left: "72",
                        top: "415.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "threads,",
                        width: "37.66",
                        height: "10.54",
                        left: "126.5",
                        top: "415.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "168.66",
                        top: "415.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "190.48",
                        top: "415.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials",
                        width: "43.98",
                        height: "10.54",
                        left: "219.64",
                        top: "415.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(collectively,",
                        width: "62.19",
                        height: "10.54",
                        left: "268.12",
                        top: "415.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "“",
                        width: "5.33",
                        height: "10.54",
                        left: "334.81",
                        top: "415.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "23.99",
                        height: "10.54",
                        left: "340.14",
                        top: "415.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contributions",
                        width: "72.01",
                        height: "10.54",
                        left: "368.63",
                        top: "415.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”)",
                        width: "9.32",
                        height: "10.54",
                        left: "440.64",
                        top: "415.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "454.46",
                        top: "415.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "470.21",
                        top: "415.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "483.96",
                        top: "415.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.04",
                        top: "415.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "72",
                        top: "402.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "All",
                        width: "15.33",
                        height: "10.54",
                        left: "123.11",
                        top: "402.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "142.94",
                        top: "402.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contributions",
                        width: "66",
                        height: "10.54",
                        left: "170.1",
                        top: "402.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.34",
                        height: "10.54",
                        left: "240.6",
                        top: "402.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "comply",
                        width: "36",
                        height: "10.54",
                        left: "268.44",
                        top: "402.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "308.94",
                        top: "402.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "334.77",
                        top: "402.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "standards",
                        width: "45.32",
                        height: "10.54",
                        left: "353.93",
                        top: "402.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "403.75",
                        top: "402.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "requirements",
                        width: "62.64",
                        height: "10.54",
                        left: "425.58",
                        top: "402.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "set",
                        width: "13.33",
                        height: "10.54",
                        left: "492.72",
                        top: "402.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "out",
                        width: "15.33",
                        height: "10.54",
                        left: "510.55",
                        top: "402.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "530.38",
                        top: "402.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.55",
                        left: "72",
                        top: "388.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.81",
                        height: "10.55",
                        left: "99.66",
                        top: "388.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "132.47",
                        top: "388.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use.",
                        width: "21.66",
                        height: "10.55",
                        left: "145.47",
                        top: "388.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Any",
                        width: "20.67",
                        height: "10.54",
                        left: "72",
                        top: "360.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.65",
                        height: "10.54",
                        left: "97.17",
                        top: "360.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contribution",
                        width: "61.34",
                        height: "10.54",
                        left: "124.32",
                        top: "360.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "190.16",
                        top: "360.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "post",
                        width: "20",
                        height: "10.54",
                        left: "211.91",
                        top: "360.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "235.66",
                        top: "360.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "251.41",
                        top: "360.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "265.16",
                        top: "360.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "306.24",
                        top: "360.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "324.65",
                        top: "360.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "366.76",
                        top: "360.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "389.17",
                        top: "360.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "considered",
                        width: "51.98",
                        height: "10.54",
                        left: "404.25",
                        top: "360.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "non­confidential",
                        width: "79.31",
                        height: "10.54",
                        left: "459.98",
                        top: "360.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "346.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "non­proprietary.",
                        width: "77.52",
                        height: "10.54",
                        left: "93.83",
                        top: "346.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "By",
                        width: "14.01",
                        height: "10.54",
                        left: "175.85",
                        top: "346.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providing",
                        width: "46.66",
                        height: "10.54",
                        left: "194.36",
                        top: "346.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "244.77",
                        top: "346.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.65",
                        height: "10.54",
                        left: "265.85",
                        top: "346.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contribution",
                        width: "61.34",
                        height: "10.54",
                        left: "292.25",
                        top: "346.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "357.34",
                        top: "346.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "373.09",
                        top: "346.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "386.84",
                        top: "346.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "427.92",
                        top: "346.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "446.33",
                        top: "346.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "488.43",
                        top: "346.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "502.18",
                        top: "346.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "72",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.65",
                        height: "10.54",
                        left: "87.08",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address",
                        width: "35.99",
                        height: "10.54",
                        left: "117.48",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "157.22",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "was",
                        width: "18.67",
                        height: "10.54",
                        left: "178.96",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made",
                        width: "25.98",
                        height: "10.54",
                        left: "201.38",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.31",
                        height: "10.54",
                        left: "231.11",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "278.17",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "291.25",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "313",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "328.75",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "347.16",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "392.27",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "grant",
                        width: "24.65",
                        height: "10.54",
                        left: "414.02",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "442.42",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "456.84",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "477.92",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "affiliates",
                        width: "41.76",
                        height: "10.54",
                        left: "497.67",
                        top: "332.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "319.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "33.97",
                        height: "10.54",
                        left: "93.08",
                        top: "319.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providers,",
                        width: "48.33",
                        height: "10.54",
                        left: "130.8",
                        top: "319.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "182.88",
                        top: "319.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "each",
                        width: "21.98",
                        height: "10.54",
                        left: "203.95",
                        top: "319.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "229.68",
                        top: "319.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "their",
                        width: "21.99",
                        height: "10.54",
                        left: "243.43",
                        top: "319.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "269.17",
                        top: "319.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "290.24",
                        top: "319.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respective",
                        width: "48.64",
                        height: "10.54",
                        left: "309.24",
                        top: "319.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "licensees,",
                        width: "46.31",
                        height: "10.54",
                        left: "360.88",
                        top: "319.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "successors,",
                        width: "53.66",
                        height: "10.54",
                        left: "410.19",
                        top: "319.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "466.85",
                        top: "319.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "assigns",
                        width: "34.67",
                        height: "10.54",
                        left: "487.17",
                        top: "319.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.84",
                        top: "319.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "right",
                        width: "22.66",
                        height: "10.54",
                        left: "72",
                        top: "305.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "98.41",
                        top: "305.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use,",
                        width: "18.99",
                        height: "10.54",
                        left: "111.5",
                        top: "305.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copy,",
                        width: "25.55",
                        height: "10.54",
                        left: "134.24",
                        top: "305.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reproduce,",
                        width: "50.97",
                        height: "10.54",
                        left: "163.54",
                        top: "305.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "modify,",
                        width: "36.89",
                        height: "10.54",
                        left: "218.26",
                        top: "305.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "adapt,",
                        width: "28.98",
                        height: "10.54",
                        left: "258.9",
                        top: "305.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "publish,",
                        width: "38.34",
                        height: "10.54",
                        left: "291.63",
                        top: "305.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "perform,",
                        width: "41.65",
                        height: "10.54",
                        left: "333.72",
                        top: "305.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "display,",
                        width: "36.88",
                        height: "10.54",
                        left: "379.12",
                        top: "305.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "distribute,",
                        width: "48.33",
                        height: "10.54",
                        left: "419.75",
                        top: "305.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "471.83",
                        top: "305.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "492.91",
                        top: "305.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "disclose",
                        width: "38.66",
                        height: "10.54",
                        left: "72",
                        top: "291.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "115.16",
                        top: "291.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "128.99",
                        top: "291.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "31.98",
                        height: "10.54",
                        left: "156.16",
                        top: "291.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "191.89",
                        top: "291.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "212.97",
                        top: "291.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "238.72",
                        top: "291.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "281.78",
                        top: "291.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "299.52",
                        top: "291.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purpose.",
                        width: "40.99",
                        height: "10.54",
                        left: "320.6",
                        top: "291.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Without",
                        width: "38.85",
                        height: "10.54",
                        left: "365.34",
                        top: "291.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limiting",
                        width: "38",
                        height: "10.54",
                        left: "407.94",
                        top: "291.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "449.69",
                        top: "291.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "foregoing,",
                        width: "49.65",
                        height: "10.54",
                        left: "468.1",
                        top: "291.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "521.5",
                        top: "291.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "72",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "granting",
                        width: "39.99",
                        height: "10.54",
                        left: "94.15",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "141.64",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "159.81",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.66",
                        height: "10.54",
                        left: "181.97",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "212.13",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.65",
                        height: "10.54",
                        left: "228.97",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "273.12",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promote",
                        width: "39.99",
                        height: "10.54",
                        left: "297.95",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "345.44",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "368.94",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "414.79",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "439.62",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.99",
                        height: "10.54",
                        left: "455.7",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "488.44",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "517.18",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Contributions",
                        width: "66.01",
                        height: "10.54",
                        left: "72",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.3",
                        height: "10.54",
                        left: "141.76",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "188.81",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "201.9",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "31.99",
                        height: "10.54",
                        left: "228.31",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "264.05",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "distribution,",
                        width: "58.33",
                        height: "10.54",
                        left: "281.79",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promotion,",
                        width: "52.99",
                        height: "10.54",
                        left: "343.88",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "400.62",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "publication.",
                        width: "56.99",
                        height: "10.54",
                        left: "414.37",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "475.11",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shall",
                        width: "22.67",
                        height: "10.54",
                        left: "498.32",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "523.99",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "72",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "entitled",
                        width: "35.98",
                        height: "10.54",
                        left: "86.33",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "125.31",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "compensation",
                        width: "66.65",
                        height: "10.54",
                        left: "137.65",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "207.3",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "224.29",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "249.29",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "268.28",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "281.28",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.65",
                        height: "10.54",
                        left: "306.28",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contributions.",
                        width: "69.01",
                        height: "10.54",
                        left: "331.93",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "represent,",
                        width: "46.98",
                        height: "10.54",
                        left: "94.46",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "warrant,",
                        width: "39.64",
                        height: "10.54",
                        left: "144.44",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "187.08",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "covenant",
                        width: "43.31",
                        height: "10.54",
                        left: "207.41",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that:",
                        width: "21.33",
                        height: "10.54",
                        left: "253.72",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "194.91",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "108",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "own",
                        width: "20.67",
                        height: "10.54",
                        left: "131.21",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "155.63",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "control",
                        width: "33.99",
                        height: "10.54",
                        left: "169.38",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "207.12",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights",
                        width: "27.34",
                        height: "10.54",
                        left: "222.86",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "253.95",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "267.03",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "288.11",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "301.19",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "318.85",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contributions",
                        width: "66",
                        height: "10.54",
                        left: "344.51",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "413.51",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "433.84",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "459.49",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.67",
                        height: "10.54",
                        left: "477.15",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "502.82",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "grant",
                        width: "24.66",
                        height: "10.54",
                        left: "515.15",
                        top: "194.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "108",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "license",
                        width: "33.32",
                        height: "10.54",
                        left: "126.41",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "granted",
                        width: "35.98",
                        height: "10.54",
                        left: "163.48",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "above",
                        width: "28.65",
                        height: "10.54",
                        left: "203.21",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "235.61",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "248.7",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "263.12",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "284.19",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "affiliates",
                        width: "41.76",
                        height: "10.54",
                        left: "303.94",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "349.45",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "33.98",
                        height: "10.54",
                        left: "370.52",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providers,",
                        width: "48.32",
                        height: "10.54",
                        left: "408.25",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "459.57",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "each",
                        width: "21.98",
                        height: "10.54",
                        left: "479.9",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "504.88",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "their",
                        width: "21.99",
                        height: "10.54",
                        left: "517.88",
                        top: "180.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "108",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "128.33",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respective",
                        width: "48.64",
                        height: "10.54",
                        left: "147.32",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "licensees,",
                        width: "46.31",
                        height: "10.54",
                        left: "198.96",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "successors,",
                        width: "53.66",
                        height: "10.54",
                        left: "248.27",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "304.93",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "assigns.",
                        width: "37.67",
                        height: "10.54",
                        left: "325.25",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "153.52",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.54",
                        left: "108",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "138.21",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contributions",
                        width: "66.01",
                        height: "10.54",
                        left: "167.62",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "240.38",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "265.79",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contain",
                        width: "35.32",
                        height: "10.54",
                        left: "287.13",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "328.45",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "351.77",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "397.08",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "421.08",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "defamatory,",
                        width: "56.86",
                        height: "10.54",
                        left: "435.08",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "obscene,",
                        width: "41.65",
                        height: "10.54",
                        left: "497.94",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "indecent,",
                        width: "43.65",
                        height: "10.54",
                        left: "108",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "abusive,",
                        width: "39.65",
                        height: "10.54",
                        left: "159.15",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offensive,",
                        width: "47.43",
                        height: "10.54",
                        left: "206.3",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "harassing,",
                        width: "48.33",
                        height: "10.54",
                        left: "261.23",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "violent,",
                        width: "36.32",
                        height: "10.54",
                        left: "317.06",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "hateful,",
                        width: "36.32",
                        height: "10.54",
                        left: "360.88",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inflammatory",
                        width: "65.32",
                        height: "10.54",
                        left: "403.95",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "476.02",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "492.76",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "objectionable.",
                        width: "67.64",
                        height: "10.54",
                        left: "108",
                        top: "125.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "112.12",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Neither",
                        width: "35.98",
                        height: "10.55",
                        left: "108",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "149.23",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.55",
                        left: "176.48",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contributions",
                        width: "66",
                        height: "10.55",
                        left: "204.39",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "nor",
                        width: "16",
                        height: "10.55",
                        left: "275.64",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "296.89",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.55",
                        left: "316.8",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "338.04",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "353.29",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.55",
                        left: "380.54",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contributions",
                        width: "66",
                        height: "10.55",
                        left: "408.45",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "does",
                        width: "22",
                        height: "10.55",
                        left: "479.7",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.9900000000001",
                        height: "10.55",
                        left: "506.2",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.55",
                        left: "520.69",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "infringe",
                        width: "37.99",
                        height: "10.54",
                        left: "108",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "150.49",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "patent,",
                        width: "32.32",
                        height: "10.54",
                        left: "172.31",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "trademark,",
                        width: "51.64",
                        height: "10.54",
                        left: "209.13",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "trade",
                        width: "23.98",
                        height: "10.54",
                        left: "265.27",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "secret,",
                        width: "30.98",
                        height: "10.54",
                        left: "293.75",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copyright,",
                        width: "48.99",
                        height: "10.54",
                        left: "329.23",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "382.72",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "397.22",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "intellectual",
                        width: "53.3",
                        height: "10.54",
                        left: "426.38",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "property",
                        width: "40.66",
                        height: "10.54",
                        left: "484.18",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "529.34",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "108",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights",
                        width: "27.33",
                        height: "10.54",
                        left: "135.66",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "165.99",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "178.99",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "199.31",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "person",
                        width: "31.99",
                        height: "10.54",
                        left: "226.97",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "261.96",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "entity.",
                        width: "29.55",
                        height: "10.54",
                        left: "274.96",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,720",
                    "lineto,540,720",
                    "lineto,540,705",
                    "lineto,90,705",
                    "lineto,90,720",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,706",
                    "lineto,540,706",
                    "lineto,540,691",
                    "lineto,90,691",
                    "lineto,90,706",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,692",
                    "lineto,540,692",
                    "lineto,540,677",
                    "lineto,90,677",
                    "lineto,90,692",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,679",
                    "lineto,540,679",
                    "lineto,540,664",
                    "lineto,90,664",
                    "lineto,90,679",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,665",
                    "lineto,540,665",
                    "lineto,540,650",
                    "lineto,90,650",
                    "lineto,90,665",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,651",
                    "lineto,540,651",
                    "lineto,540,636",
                    "lineto,90,636",
                    "lineto,90,651",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,637",
                    "lineto,540,637",
                    "lineto,540,622",
                    "lineto,90,622",
                    "lineto,90,637",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,623",
                    "lineto,540,623",
                    "lineto,540,608",
                    "lineto,90,608",
                    "lineto,90,623",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,610",
                    "lineto,540,610",
                    "lineto,540,595",
                    "lineto,90,595",
                    "lineto,90,610",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,596",
                    "lineto,540,596",
                    "lineto,540,581",
                    "lineto,90,581",
                    "lineto,90,596",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,568",
                    "lineto,540,568",
                    "lineto,540,553",
                    "lineto,72,553",
                    "lineto,72,568",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,554",
                    "lineto,540,554",
                    "lineto,540,539",
                    "lineto,72,539",
                    "lineto,72,554",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,540",
                    "lineto,540,540",
                    "lineto,540,525",
                    "lineto,72,525",
                    "lineto,72,540",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,527",
                    "lineto,540,527",
                    "lineto,540,498",
                    "lineto,72,498",
                    "lineto,72,527",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,499",
                    "lineto,540,499",
                    "lineto,540,470",
                    "lineto,72,470",
                    "lineto,72,499",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,471",
                    "lineto,540,471",
                    "lineto,540,456",
                    "lineto,72,456",
                    "lineto,72,471",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,457",
                    "lineto,540,457",
                    "lineto,540,442",
                    "lineto,72,442",
                    "lineto,72,457",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,443",
                    "lineto,540,443",
                    "lineto,540,428",
                    "lineto,72,428",
                    "lineto,72,443",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,430",
                    "lineto,540,430",
                    "lineto,540,415",
                    "lineto,72,415",
                    "lineto,72,430",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,416",
                    "lineto,540,416",
                    "lineto,540,401",
                    "lineto,72,401",
                    "lineto,72,416",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,402",
                    "lineto,540,402",
                    "lineto,540,373",
                    "lineto,72,373",
                    "lineto,72,402",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,374",
                    "lineto,540,374",
                    "lineto,540,359",
                    "lineto,72,359",
                    "lineto,72,374",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,360",
                    "lineto,540,360",
                    "lineto,540,345",
                    "lineto,72,345",
                    "lineto,72,360",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,347",
                    "lineto,540,347",
                    "lineto,540,332",
                    "lineto,72,332",
                    "lineto,72,347",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,333",
                    "lineto,540,333",
                    "lineto,540,318",
                    "lineto,72,318",
                    "lineto,72,333",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,319",
                    "lineto,540,319",
                    "lineto,540,304",
                    "lineto,72,304",
                    "lineto,72,319",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,305",
                    "lineto,540,305",
                    "lineto,540,290",
                    "lineto,72,290",
                    "lineto,72,305",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,291",
                    "lineto,540,291",
                    "lineto,540,276",
                    "lineto,72,276",
                    "lineto,72,291",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,278",
                    "lineto,540,278",
                    "lineto,540,263",
                    "lineto,72,263",
                    "lineto,72,278",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,264",
                    "lineto,540,264",
                    "lineto,540,235",
                    "lineto,72,235",
                    "lineto,72,264",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,236",
                    "lineto,540,236",
                    "lineto,540,221",
                    "lineto,72,221",
                    "lineto,72,236",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,208",
                    "lineto,540,208",
                    "lineto,540,193",
                    "lineto,90,193",
                    "lineto,90,208",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,194",
                    "lineto,540,194",
                    "lineto,540,179",
                    "lineto,90,179",
                    "lineto,90,194",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,181",
                    "lineto,540,181",
                    "lineto,540,166",
                    "lineto,90,166",
                    "lineto,90,181",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,167",
                    "lineto,540,167",
                    "lineto,540,152",
                    "lineto,90,152",
                    "lineto,90,167",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,153",
                    "lineto,540,153",
                    "lineto,540,138",
                    "lineto,90,138",
                    "lineto,90,153",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,139",
                    "lineto,540,139",
                    "lineto,540,124",
                    "lineto,90,124",
                    "lineto,90,139",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,125",
                    "lineto,540,125",
                    "lineto,540,110",
                    "lineto,90,110",
                    "lineto,90,125",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,112",
                    "lineto,540,112",
                    "lineto,540,97",
                    "lineto,90,97",
                    "lineto,90,112",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,98",
                    "lineto,540,98",
                    "lineto,540,83",
                    "lineto,90,83",
                    "lineto,90,98",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,123.11,415.82",
                    "lineto,539.72,415.82",
                    "lineto,539.72,402.02",
                    "lineto,123.11,402.02",
                    "lineto,123.11,415.82",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,402.02",
                    "lineto,167.13,402.02",
                    "lineto,167.13,388.22",
                    "lineto,72,388.22",
                    "lineto,72,402.02",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,486.5", "lineto,171,486.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "6",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.2800000000001",
                        left: "90",
                        top: "706.68",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.54",
                        left: "108",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "137.46",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contributions",
                        width: "66.01",
                        height: "10.54",
                        left: "165.37",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "does",
                        width: "21.99",
                        height: "10.54",
                        left: "236.63",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "263.87",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "284.46",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "307.03",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "330.95",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "violate",
                        width: "32.66",
                        height: "10.54",
                        left: "351.53",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "389.44",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "legal",
                        width: "23.32",
                        height: "10.54",
                        left: "409.35",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights",
                        width: "27.33",
                        height: "10.54",
                        left: "437.92",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(including",
                        width: "49.33",
                        height: "10.54",
                        left: "470.5",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.08",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "rights",
                        width: "27.33",
                        height: "10.54",
                        left: "108",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "139.83",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "publicity",
                        width: "42.66",
                        height: "10.54",
                        left: "154.33",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "201.49",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "privacy)",
                        width: "39.98",
                        height: "10.54",
                        left: "223.32",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "267.8",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "others",
                        width: "29.33",
                        height: "10.54",
                        left: "282.29",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "315.37",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contain",
                        width: "35.32",
                        height: "10.54",
                        left: "329.12",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "368.19",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "389.26",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "432.32",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "could",
                        width: "26.66",
                        height: "10.54",
                        left: "454.07",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "give",
                        width: "20.66",
                        height: "10.54",
                        left: "484.48",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rise",
                        width: "17.32",
                        height: "10.54",
                        left: "508.89",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.3399999999999",
                        height: "10.54",
                        left: "529.96",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "108",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "civil",
                        width: "21.32",
                        height: "10.54",
                        left: "128.33",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "152.65",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "criminal",
                        width: "39.98",
                        height: "10.54",
                        left: "165.65",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liability",
                        width: "37.33",
                        height: "10.54",
                        left: "208.63",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.33",
                        height: "10.54",
                        left: "248.96",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applicable",
                        width: "49.3",
                        height: "10.54",
                        left: "279.29",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "laws",
                        width: "22",
                        height: "10.54",
                        left: "331.59",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "356.59",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "regulations.",
                        width: "56.32",
                        height: "10.54",
                        left: "369.59",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "665.28",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.55",
                        left: "108",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.55",
                        left: "137.46",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.55",
                        left: "166.12",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "does",
                        width: "21.99",
                        height: "10.55",
                        left: "210.12",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.55",
                        left: "238.11",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.55",
                        left: "259.45",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.55",
                        left: "282.77",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.55",
                        left: "307.44",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promote",
                        width: "39.99",
                        height: "10.55",
                        left: "328.78",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.55",
                        left: "374.77",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "illegal",
                        width: "29.99",
                        height: "10.55",
                        left: "398.09",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activity,",
                        width: "38.21",
                        height: "10.55",
                        left: "434.08",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "478.29",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advocate,",
                        width: "45.63",
                        height: "10.55",
                        left: "494.29",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "promote,",
                        width: "42.99",
                        height: "10.54",
                        left: "108",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "153.99",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "assist",
                        width: "26",
                        height: "10.54",
                        left: "166.99",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "195.99",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unlawful",
                        width: "42.65",
                        height: "10.54",
                        left: "216.32",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "act.",
                        width: "16.99",
                        height: "10.54",
                        left: "261.97",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.2800000000001",
                        left: "90",
                        top: "637.68",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.54",
                        left: "108",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "136.71",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "163.87",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "does",
                        width: "21.99",
                        height: "10.54",
                        left: "206.37",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "232.86",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "252.7",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "274.52",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "297.69",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cause",
                        width: "26.64",
                        height: "10.54",
                        left: "317.53",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "annoyance,",
                        width: "54.31",
                        height: "10.54",
                        left: "348.67",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inconvenience,",
                        width: "72.3",
                        height: "10.54",
                        left: "407.48",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "484.28",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "needless",
                        width: "40.66",
                        height: "10.54",
                        left: "498.77",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "anxiety",
                        width: "35.32",
                        height: "10.54",
                        left: "108",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "146.32",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "159.32",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "likely",
                        width: "27.33",
                        height: "10.54",
                        left: "173.64",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "203.97",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "upset,",
                        width: "28.33",
                        height: "10.54",
                        left: "216.3",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "embarrass,",
                        width: "51.65",
                        height: "10.54",
                        left: "247.63",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "alarm,",
                        width: "30.32",
                        height: "10.54",
                        left: "302.28",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "335.6",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "annoy",
                        width: "29.33",
                        height: "10.54",
                        left: "348.59",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "380.92",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "401.24",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "person.",
                        width: "34.99",
                        height: "10.54",
                        left: "428.9",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "610.09",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.54",
                        left: "108",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "137.46",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "166.12",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "210.12",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "234.04",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "impersonate",
                        width: "58.65",
                        height: "10.54",
                        left: "254.62",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "318.52",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "person",
                        width: "31.99",
                        height: "10.54",
                        left: "341.09",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "378.33",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "misrepresent",
                        width: "61.31",
                        height: "10.54",
                        left: "393.58",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "460.14",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identity",
                        width: "36.66",
                        height: "10.54",
                        left: "487.39",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.3",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "affiliation",
                        width: "47.1",
                        height: "10.54",
                        left: "108",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "158.1",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "182.43",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "person",
                        width: "31.99",
                        height: "10.54",
                        left: "202.76",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "237.75",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "organization.",
                        width: "62.76",
                        height: "10.54",
                        left: "250.75",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "582.49",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.54",
                        left: "108",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "135.96",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "163.12",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "205.62",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "228.79",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "give",
                        width: "20.66",
                        height: "10.54",
                        left: "247.87",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "272.28",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "impression",
                        width: "52.66",
                        height: "10.54",
                        left: "290.69",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "347.1",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "they",
                        width: "20.66",
                        height: "10.54",
                        left: "368.85",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "emanate",
                        width: "39.97",
                        height: "10.54",
                        left: "393.26",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "436.98",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "464.06",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "477.8",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "endorsed",
                        width: "43.32",
                        height: "10.54",
                        left: "496.2",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "108",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "123",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "136.67",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "149.67",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "169.99",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "person",
                        width: "31.99",
                        height: "10.54",
                        left: "197.65",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "232.64",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "entity,",
                        width: "29.55",
                        height: "10.54",
                        left: "245.64",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "278.19",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.33",
                        height: "10.54",
                        left: "288.52",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "308.85",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "319.86",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "338.19",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "case.",
                        width: "23.65",
                        height: "10.54",
                        left: "355.85",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "554.89",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "All",
                        width: "15.33",
                        height: "10.55",
                        left: "108",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "126.33",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.55",
                        left: "151.33",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contributions",
                        width: "66",
                        height: "10.55",
                        left: "176.99",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.55",
                        left: "245.99",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "260.99",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.55",
                        left: "281.32",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "comply",
                        width: "35.99",
                        height: "10.55",
                        left: "302.99",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.55",
                        left: "341.98",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.55",
                        left: "366.32",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.55",
                        left: "393.97",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "426.79",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use.",
                        width: "21.66",
                        height: "10.55",
                        left: "439.79",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "understand",
                        width: "52.66",
                        height: "10.54",
                        left: "94.46",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "150.12",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "acknowledge",
                        width: "63.31",
                        height: "10.54",
                        left: "170.44",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "236.75",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "257.74",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "278.74",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsible",
                        width: "54.66",
                        height: "10.54",
                        left: "296.39",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "354.05",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "371.04",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.65",
                        height: "10.54",
                        left: "391.37",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contributions",
                        width: "66.01",
                        height: "10.54",
                        left: "417.02",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "486.03",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "submit",
                        width: "32.67",
                        height: "10.54",
                        left: "507.03",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contribute,",
                        width: "51.65",
                        height: "10.54",
                        left: "85.75",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "141.15",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you,",
                        width: "21",
                        height: "10.54",
                        left: "162.22",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "186.97",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "206.06",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics,",
                        width: "38.99",
                        height: "10.54",
                        left: "241.05",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.66",
                        height: "10.54",
                        left: "283.04",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "full",
                        width: "16.66",
                        height: "10.54",
                        left: "308.7",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsibility",
                        width: "65.33",
                        height: "10.54",
                        left: "328.36",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "396.69",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "413.68",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content,",
                        width: "38.32",
                        height: "10.54",
                        left: "438.68",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "480",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "11.34",
                        height: "10.54",
                        left: "528.33",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "legality,",
                        width: "38.21",
                        height: "10.54",
                        left: "72",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reliability,",
                        width: "48.87",
                        height: "10.54",
                        left: "113.21",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accuracy,",
                        width: "44.85",
                        height: "10.54",
                        left: "165.08",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "212.93",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "appropriateness.",
                        width: "78.3",
                        height: "10.54",
                        left: "233.26",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "93.69",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "113.59",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsible",
                        width: "54.66",
                        height: "10.54",
                        left: "134.17",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "194.08",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liable",
                        width: "26.65",
                        height: "10.54",
                        left: "209.33",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "241.23",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "255.81",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "278.39",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "party",
                        width: "24.66",
                        height: "10.54",
                        left: "306.3",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "336.21",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "355.45",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "375.36",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "415.93",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accuracy",
                        width: "42.63",
                        height: "10.54",
                        left: "431.18",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "479.06",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "494.3",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "516.88",
                        top: "471.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Contributions",
                        width: "66.01",
                        height: "10.54",
                        left: "72",
                        top: "457.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "posted",
                        width: "31.33",
                        height: "10.54",
                        left: "141.01",
                        top: "457.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "175.34",
                        top: "457.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "190.34",
                        top: "457.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "211.34",
                        top: "457.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "224.33",
                        top: "457.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "244.66",
                        top: "457.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "20",
                        height: "10.54",
                        left: "272.31",
                        top: "457.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "295.31",
                        top: "457.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "308.3",
                        top: "457.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "325.96",
                        top: "457.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Monitoring",
                        width: "58.66",
                        height: "10.54",
                        left: "72",
                        top: "429.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "133.66",
                        top: "429.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Enforcement;",
                        width: "70.43",
                        height: "10.54",
                        left: "156.01",
                        top: "429.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Termination",
                        width: "63.56",
                        height: "10.54",
                        left: "229.44",
                        top: "429.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "401.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "90.69",
                        top: "401.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "116.34",
                        top: "401.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.67",
                        height: "10.54",
                        left: "134",
                        top: "401.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to:",
                        width: "12.67",
                        height: "10.54",
                        left: "159.67",
                        top: "401.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "374.5",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Remove",
                        width: "39.99",
                        height: "10.54",
                        left: "108",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "153.99",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "refuse",
                        width: "29.31",
                        height: "10.54",
                        left: "169.99",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "205.3",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "post",
                        width: "20.01",
                        height: "10.54",
                        left: "220.63",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "246.64",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "269.96",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contributions",
                        width: "66.01",
                        height: "10.54",
                        left: "298.62",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "370.63",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "390.62",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "413.95",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "429.94",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reason",
                        width: "31.32",
                        height: "10.54",
                        left: "447.94",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "484.51",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "499.1",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "520.34",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "discretion.",
                        width: "50.32",
                        height: "10.55",
                        left: "108",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "346.9",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Disclose",
                        width: "41.33",
                        height: "10.54",
                        left: "108",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "153.08",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identity",
                        width: "36.66",
                        height: "10.54",
                        left: "178.82",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "219.23",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "232.98",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "261.39",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "321.79",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "352.2",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "373.95",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "387.04",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "408.11",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "party",
                        width: "24.65",
                        height: "10.54",
                        left: "434.53",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.67",
                        height: "10.54",
                        left: "462.93",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claims",
                        width: "31.32",
                        height: "10.54",
                        left: "487.35",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "521.67",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "108",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "posted",
                        width: "31.33",
                        height: "10.54",
                        left: "151.06",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "186.14",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "201.89",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "violates",
                        width: "37.32",
                        height: "10.54",
                        left: "223.64",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "their",
                        width: "22",
                        height: "10.54",
                        left: "264.71",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights,",
                        width: "30.33",
                        height: "10.54",
                        left: "290.46",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "324.54",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "their",
                        width: "21.99",
                        height: "10.54",
                        left: "373.62",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "intellectual",
                        width: "53.31",
                        height: "10.54",
                        left: "399.36",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "property",
                        width: "40.65",
                        height: "10.54",
                        left: "455.67",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights",
                        width: "27.33",
                        height: "10.54",
                        left: "499.32",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.65",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "their",
                        width: "21.99",
                        height: "10.54",
                        left: "108",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.66",
                        height: "10.54",
                        left: "132.99",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "158.65",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "privacy.",
                        width: "38.2",
                        height: "10.54",
                        left: "170.99",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "305.51",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Take",
                        width: "23.14",
                        height: "10.54",
                        left: "108",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "appropriate",
                        width: "54.64",
                        height: "10.54",
                        left: "135.64",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "legal",
                        width: "23.32",
                        height: "10.54",
                        left: "194.78",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "action,",
                        width: "32.32",
                        height: "10.54",
                        left: "222.6",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "259.42",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.67",
                        height: "10.54",
                        left: "309.25",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limitation,",
                        width: "49.66",
                        height: "10.54",
                        left: "349.67",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "referral",
                        width: "35.3",
                        height: "10.54",
                        left: "403.08",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "442.13",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law",
                        width: "17.33",
                        height: "10.54",
                        left: "455.21",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enforcement,",
                        width: "62.97",
                        height: "10.54",
                        left: "476.29",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "108",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "124.99",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "illegal",
                        width: "29.99",
                        height: "10.54",
                        left: "145.32",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "178.31",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unauthorized",
                        width: "62.65",
                        height: "10.54",
                        left: "191.3",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "256.95",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "275.94",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "288.94",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "306.6",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "277.91",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Terminate",
                        width: "48.47",
                        height: "10.54",
                        left: "108",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "161.72",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "suspend",
                        width: "38.67",
                        height: "10.54",
                        left: "176.96",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "220.88",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "248.13",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "284.02",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "298.61",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "315.85",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "part",
                        width: "18.65",
                        height: "10.54",
                        left: "331.1",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "355",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "370.25",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "390.16",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.54",
                        left: "433.76",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "452.26",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "474.08",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "488.58",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reason,",
                        width: "34.32",
                        height: "10.54",
                        left: "505.08",
                        top: "277.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "108",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.67",
                        height: "10.54",
                        left: "156.33",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limitation,",
                        width: "49.66",
                        height: "10.54",
                        left: "196",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "248.66",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "violation",
                        width: "42.66",
                        height: "10.54",
                        left: "268.99",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "314.65",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "327.64",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "355.3",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "388.12",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use.",
                        width: "21.66",
                        height: "10.54",
                        left: "401.12",
                        top: "263.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "250.31",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Take",
                        width: "23.14",
                        height: "10.54",
                        left: "108",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "137.89",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "action",
                        width: "29.32",
                        height: "10.54",
                        left: "161.97",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "198.04",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respect",
                        width: "33.97",
                        height: "10.54",
                        left: "226.13",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "266.1",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "281.44",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "304.76",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contribution",
                        width: "61.34",
                        height: "10.54",
                        left: "333.42",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "400.76",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "424.75",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "deem",
                        width: "25.99",
                        height: "10.54",
                        left: "444.74",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "necessary",
                        width: "46.64",
                        height: "10.54",
                        left: "476.73",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.37",
                        top: "249.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "appropriate",
                        width: "54.64",
                        height: "10.54",
                        left: "108",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "167.89",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "182.47",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "203.72",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion,",
                        width: "50.32",
                        height: "10.54",
                        left: "228.3",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "283.12",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "332.95",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "344.78",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "believe",
                        width: "34.65",
                        height: "10.54",
                        left: "363.27",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "402.42",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "424.91",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.65",
                        height: "10.54",
                        left: "451.41",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contribution",
                        width: "61.34",
                        height: "10.54",
                        left: "478.56",
                        top: "236.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "violates",
                        width: "37.32",
                        height: "10.54",
                        left: "108",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "149.82",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "168.98",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "203.3",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use,",
                        width: "21.66",
                        height: "10.54",
                        left: "217.8",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "infringes",
                        width: "42.66",
                        height: "10.54",
                        left: "243.96",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "291.12",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "intellectual",
                        width: "53.31",
                        height: "10.54",
                        left: "312.19",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "property",
                        width: "40.65",
                        height: "10.54",
                        left: "369.25",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.67",
                        height: "10.54",
                        left: "413.65",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "440.07",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "453.81",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.66",
                        height: "10.54",
                        left: "482.22",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "508.63",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "522.38",
                        top: "222.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "person",
                        width: "31.99",
                        height: "10.54",
                        left: "108",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "144.49",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "entity,",
                        width: "29.55",
                        height: "10.54",
                        left: "158.99",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "threatens",
                        width: "43.31",
                        height: "10.54",
                        left: "193.04",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "240.85",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "260.01",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "safety",
                        width: "28.65",
                        height: "10.54",
                        left: "305.16",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "338.31",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.66",
                        height: "10.54",
                        left: "352.81",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "381.97",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "396.47",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "415.63",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "458.48",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "472.98",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "public,",
                        width: "32.99",
                        height: "10.54",
                        left: "492.14",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.63",
                        top: "208.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "could",
                        width: "26.66",
                        height: "10.54",
                        left: "108",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "create",
                        width: "28.63",
                        height: "10.54",
                        left: "137.66",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liability",
                        width: "37.34",
                        height: "10.54",
                        left: "169.29",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "209.63",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us.",
                        width: "13.67",
                        height: "10.54",
                        left: "226.62",
                        top: "194.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Without",
                        width: "38.85",
                        height: "10.54",
                        left: "72",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limiting",
                        width: "38",
                        height: "10.54",
                        left: "115.35",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "157.85",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "foregoing,",
                        width: "49.65",
                        height: "10.54",
                        left: "177.01",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "231.16",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "249.66",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "276.81",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.66",
                        height: "10.54",
                        left: "295.97",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "322.38",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cooperate",
                        width: "46.63",
                        height: "10.54",
                        left: "335.47",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fully",
                        width: "22.67",
                        height: "10.54",
                        left: "385.85",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "412.27",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "437.35",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law",
                        width: "17.32",
                        height: "10.54",
                        left: "458.43",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enforcement",
                        width: "59.97",
                        height: "10.54",
                        left: "479.5",
                        top: "166.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "authorities",
                        width: "50.65",
                        height: "10.54",
                        left: "72",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "127.15",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "court",
                        width: "24.66",
                        height: "10.54",
                        left: "140.9",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "order",
                        width: "25.32",
                        height: "10.54",
                        left: "169.31",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "requesting",
                        width: "49.98",
                        height: "10.54",
                        left: "198.38",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "252.11",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "directing",
                        width: "42.65",
                        height: "10.54",
                        left: "265.86",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "312.26",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "326.68",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclose",
                        width: "38.66",
                        height: "10.54",
                        left: "339.76",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "382.17",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identity",
                        width: "36.66",
                        height: "10.54",
                        left: "400.58",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "440.99",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "454.74",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "483.15",
                        top: "153.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "anyone",
                        width: "34.65",
                        height: "10.54",
                        left: "88.75",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "posting",
                        width: "35.34",
                        height: "10.54",
                        left: "130.15",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "172.24",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials",
                        width: "43.98",
                        height: "10.54",
                        left: "196.31",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "247.04",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "265.79",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "282.54",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "326.62",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "348.03",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOU",
                        width: "26",
                        height: "10.54",
                        left: "396.13",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WAIVE",
                        width: "38.66",
                        height: "10.54",
                        left: "428.88",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "26",
                        height: "10.54",
                        left: "474.29",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "HOLD",
                        width: "33.32",
                        height: "10.54",
                        left: "506.29",
                        top: "139.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "HARMLESS",
                        width: "64.01",
                        height: "10.54",
                        left: "72",
                        top: "125.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "43.34",
                        height: "10.54",
                        left: "142.01",
                        top: "125.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXOTICS",
                        width: "50.67",
                        height: "10.54",
                        left: "191.35",
                        top: "125.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "25.99",
                        height: "10.54",
                        left: "248.02",
                        top: "125.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OUR",
                        width: "25.34",
                        height: "10.54",
                        left: "280.01",
                        top: "125.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AFFILIATES,",
                        width: "69.01",
                        height: "10.54",
                        left: "311.35",
                        top: "125.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LICENSEES,",
                        width: "66.33",
                        height: "10.54",
                        left: "386.36",
                        top: "125.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "26",
                        height: "10.54",
                        left: "458.69",
                        top: "125.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SERVICE",
                        width: "49.04",
                        height: "10.54",
                        left: "490.69",
                        top: "125.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "PROVIDERS",
                        width: "66.68",
                        height: "10.55",
                        left: "72",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FROM",
                        width: "34.01",
                        height: "10.55",
                        left: "143.18",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.55",
                        left: "181.69",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLAIMS",
                        width: "45.34",
                        height: "10.55",
                        left: "212.19",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "RESULTING",
                        width: "65.56",
                        height: "10.55",
                        left: "262.03",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FROM",
                        width: "34.02",
                        height: "10.55",
                        left: "332.09",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "25.99",
                        height: "10.55",
                        left: "370.61",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACTION",
                        width: "45.33",
                        height: "10.55",
                        left: "400.35",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TAKEN",
                        width: "39.7",
                        height: "10.55",
                        left: "449.43",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.55",
                        left: "492.88",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.55",
                        left: "513.3",
                        top: "111.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OF",
                        width: "15.34",
                        height: "10.54",
                        left: "72",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "23.33",
                        height: "10.54",
                        left: "95.59",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FOREGOING",
                        width: "69.33",
                        height: "10.54",
                        left: "127.17",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PARTIES",
                        width: "46.85",
                        height: "10.54",
                        left: "204.75",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DURING,",
                        width: "49.67",
                        height: "10.54",
                        left: "259.1",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "16.67",
                        height: "10.54",
                        left: "316.27",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TAKEN",
                        width: "39.69",
                        height: "10.54",
                        left: "340.44",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AS",
                        width: "15.34",
                        height: "10.54",
                        left: "387.63",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.67",
                        height: "10.54",
                        left: "410.47",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONSEQUENCE",
                        width: "88",
                        height: "10.54",
                        left: "426.64",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF,",
                        width: "17.38",
                        height: "10.54",
                        left: "522.14",
                        top: "97.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "INVESTIGATIONS",
                        width: "97.99",
                        height: "10.54",
                        left: "72",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "184.24",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EITHER",
                        width: "42.66",
                        height: "10.54",
                        left: "215.16",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SUCH",
                        width: "32.01",
                        height: "10.54",
                        left: "272.07",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PARTIES",
                        width: "46.85",
                        height: "10.54",
                        left: "318.33",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "16.67",
                        height: "10.54",
                        left: "379.43",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LAW",
                        width: "26.36",
                        height: "10.54",
                        left: "410.35",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ENFORCEMENT",
                        width: "88.67",
                        height: "10.54",
                        left: "450.96",
                        top: "84.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,720",
                    "lineto,540,720",
                    "lineto,540,705",
                    "lineto,90,705",
                    "lineto,90,720",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,706",
                    "lineto,540,706",
                    "lineto,540,691",
                    "lineto,90,691",
                    "lineto,90,706",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,692",
                    "lineto,540,692",
                    "lineto,540,677",
                    "lineto,90,677",
                    "lineto,90,692",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,679",
                    "lineto,540,679",
                    "lineto,540,664",
                    "lineto,90,664",
                    "lineto,90,679",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,665",
                    "lineto,540,665",
                    "lineto,540,650",
                    "lineto,90,650",
                    "lineto,90,665",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,651",
                    "lineto,540,651",
                    "lineto,540,636",
                    "lineto,90,636",
                    "lineto,90,651",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,637",
                    "lineto,540,637",
                    "lineto,540,622",
                    "lineto,90,622",
                    "lineto,90,637",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,623",
                    "lineto,540,623",
                    "lineto,540,608",
                    "lineto,90,608",
                    "lineto,90,623",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,610",
                    "lineto,540,610",
                    "lineto,540,595",
                    "lineto,90,595",
                    "lineto,90,610",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,596",
                    "lineto,540,596",
                    "lineto,540,581",
                    "lineto,90,581",
                    "lineto,90,596",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,582",
                    "lineto,540,582",
                    "lineto,540,567",
                    "lineto,90,567",
                    "lineto,90,582",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,568",
                    "lineto,540,568",
                    "lineto,540,553",
                    "lineto,90,553",
                    "lineto,90,568",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,540",
                    "lineto,540,540",
                    "lineto,540,525",
                    "lineto,72,525",
                    "lineto,72,540",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,527",
                    "lineto,540,527",
                    "lineto,540,512",
                    "lineto,72,512",
                    "lineto,72,527",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,513",
                    "lineto,540,513",
                    "lineto,540,484",
                    "lineto,72,484",
                    "lineto,72,513",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,485",
                    "lineto,540,485",
                    "lineto,540,470",
                    "lineto,72,470",
                    "lineto,72,485",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,471",
                    "lineto,540,471",
                    "lineto,540,442",
                    "lineto,72,442",
                    "lineto,72,471",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,443",
                    "lineto,540,443",
                    "lineto,540,414",
                    "lineto,72,414",
                    "lineto,72,443",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,416",
                    "lineto,540,416",
                    "lineto,540,401",
                    "lineto,72,401",
                    "lineto,72,416",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,388",
                    "lineto,540,388",
                    "lineto,540,373",
                    "lineto,90,373",
                    "lineto,90,388",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,374",
                    "lineto,540,374",
                    "lineto,540,359",
                    "lineto,90,359",
                    "lineto,90,374",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,360",
                    "lineto,540,360",
                    "lineto,540,345",
                    "lineto,90,345",
                    "lineto,90,360",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,346",
                    "lineto,540,346",
                    "lineto,540,331",
                    "lineto,90,331",
                    "lineto,90,346",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,333",
                    "lineto,540,333",
                    "lineto,540,318",
                    "lineto,90,318",
                    "lineto,90,333",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,319",
                    "lineto,540,319",
                    "lineto,540,304",
                    "lineto,90,304",
                    "lineto,90,319",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,305",
                    "lineto,540,305",
                    "lineto,540,290",
                    "lineto,90,290",
                    "lineto,90,305",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,291",
                    "lineto,540,291",
                    "lineto,540,276",
                    "lineto,90,276",
                    "lineto,90,291",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,277",
                    "lineto,540,277",
                    "lineto,540,262",
                    "lineto,90,262",
                    "lineto,90,277",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,264",
                    "lineto,540,264",
                    "lineto,540,249",
                    "lineto,90,249",
                    "lineto,90,264",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,250",
                    "lineto,540,250",
                    "lineto,540,235",
                    "lineto,90,235",
                    "lineto,90,250",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,236",
                    "lineto,540,236",
                    "lineto,540,221",
                    "lineto,90,221",
                    "lineto,90,236",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,222",
                    "lineto,540,222",
                    "lineto,540,207",
                    "lineto,90,207",
                    "lineto,90,222",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,208",
                    "lineto,540,208",
                    "lineto,540,193",
                    "lineto,90,193",
                    "lineto,90,208",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,181",
                    "lineto,540,181",
                    "lineto,540,166",
                    "lineto,72,166",
                    "lineto,72,181",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,167",
                    "lineto,540,167",
                    "lineto,540,152",
                    "lineto,72,152",
                    "lineto,72,167",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,153",
                    "lineto,540,153",
                    "lineto,540,138",
                    "lineto,72,138",
                    "lineto,72,153",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,139",
                    "lineto,540,139",
                    "lineto,540,124",
                    "lineto,72,124",
                    "lineto,72,139",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,125",
                    "lineto,540,125",
                    "lineto,540,110",
                    "lineto,72,110",
                    "lineto,72,125",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,112",
                    "lineto,540,112",
                    "lineto,540,97",
                    "lineto,72,97",
                    "lineto,72,112",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,98",
                    "lineto,540,98",
                    "lineto,540,83",
                    "lineto,72,83",
                    "lineto,72,98",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,430.5", "lineto,293,430.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "7",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "AUTHORITIES.",
                        width: "82.32",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "However,",
                        width: "46.5",
                        height: "10.54",
                        left: "72",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "123.75",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "142.99",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "160.24",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "undertake",
                        width: "47.3",
                        height: "10.54",
                        left: "180.83",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "233.38",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "review",
                        width: "32.65",
                        height: "10.54",
                        left: "247.97",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "285.87",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "303.11",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "before",
                        width: "30.65",
                        height: "10.54",
                        left: "347.67",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "6.66",
                        height: "10.54",
                        left: "383.57",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "395.48",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "posted",
                        width: "31.33",
                        height: "10.54",
                        left: "407.99",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "443.82",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "460.32",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "479.48",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "522.33",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "cannot",
                        width: "31.99",
                        height: "10.54",
                        left: "72",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ensure",
                        width: "31.31",
                        height: "10.54",
                        left: "108.49",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prompt",
                        width: "34.67",
                        height: "10.54",
                        left: "144.3",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "removal",
                        width: "39.32",
                        height: "10.54",
                        left: "183.47",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "227.29",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "objectionable",
                        width: "64.64",
                        height: "10.54",
                        left: "241.78",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "310.92",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "after",
                        width: "21.98",
                        height: "10.54",
                        left: "354.73",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "6.67",
                        height: "10.54",
                        left: "381.21",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "has",
                        width: "16",
                        height: "10.54",
                        left: "392.38",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "been",
                        width: "22.65",
                        height: "10.54",
                        left: "412.88",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "posted.",
                        width: "34.33",
                        height: "10.54",
                        left: "439.28",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Accordingly,",
                        width: "62.2",
                        height: "10.54",
                        left: "477.36",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.55",
                        left: "72",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "assume",
                        width: "35.33",
                        height: "10.55",
                        left: "90.49",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.55",
                        left: "130.32",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liability",
                        width: "37.33",
                        height: "10.55",
                        left: "146.82",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.55",
                        left: "188.65",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.55",
                        left: "207.14",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "action",
                        width: "29.32",
                        height: "10.55",
                        left: "228.97",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.55",
                        left: "262.79",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inaction",
                        width: "38.66",
                        height: "10.55",
                        left: "277.28",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "regarding",
                        width: "45.98",
                        height: "10.55",
                        left: "320.44",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transmissions,",
                        width: "68.33",
                        height: "10.55",
                        left: "370.92",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "communications,",
                        width: "82.32",
                        height: "10.55",
                        left: "443.75",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "529.82",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "72",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided",
                        width: "42.66",
                        height: "10.54",
                        left: "111.07",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "157.48",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "173.23",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "19.99",
                        height: "10.54",
                        left: "194.3",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "218.04",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "231.79",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "party.",
                        width: "26.87",
                        height: "10.54",
                        left: "258.21",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "288.83",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.66",
                        height: "10.54",
                        left: "308.27",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "334.68",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liability",
                        width: "37.33",
                        height: "10.54",
                        left: "350.43",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "391.51",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsibility",
                        width: "65.33",
                        height: "10.54",
                        left: "405.25",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "474.33",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "anyone",
                        width: "34.65",
                        height: "10.54",
                        left: "487.42",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "525.82",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "performance",
                        width: "60.63",
                        height: "10.54",
                        left: "72",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "135.63",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "nonperformance",
                        width: "78.63",
                        height: "10.54",
                        left: "148.62",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "230.25",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "243.25",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activities",
                        width: "43.31",
                        height: "10.54",
                        left: "260.91",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "described",
                        width: "45.98",
                        height: "10.54",
                        left: "307.22",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "356.2",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.33",
                        height: "10.54",
                        left: "368.54",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "section.",
                        width: "36.99",
                        height: "10.54",
                        left: "388.88",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Copyright",
                        width: "52.67",
                        height: "10.54",
                        left: "72",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Infringement",
                        width: "67.99",
                        height: "10.54",
                        left: "127.67",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "take",
                        width: "19.99",
                        height: "10.54",
                        left: "91.44",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claims",
                        width: "31.32",
                        height: "10.54",
                        left: "115.18",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "150.25",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copyright",
                        width: "45.99",
                        height: "10.54",
                        left: "163.25",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "infringement",
                        width: "61.98",
                        height: "10.54",
                        left: "212.24",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "seriously.",
                        width: "45.55",
                        height: "10.54",
                        left: "277.22",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "325.77",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "336.76",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "believe",
                        width: "34.65",
                        height: "10.54",
                        left: "357.76",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "395.41",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials",
                        width: "43.98",
                        height: "10.54",
                        left: "415.73",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accessible",
                        width: "48.64",
                        height: "10.54",
                        left: "462.71",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "514.35",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.35",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "72",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "101.33",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "123.32",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "infringes",
                        width: "42.65",
                        height: "10.54",
                        left: "167.68",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "215.58",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copyright,",
                        width: "48.99",
                        height: "10.54",
                        left: "242.83",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "297.07",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "320.32",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "request",
                        width: "34.65",
                        height: "10.54",
                        left: "346.23",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "removal",
                        width: "39.32",
                        height: "10.54",
                        left: "386.13",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "430.7",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "those",
                        width: "25.33",
                        height: "10.54",
                        left: "445.95",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials",
                        width: "43.98",
                        height: "10.54",
                        left: "476.53",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(or",
                        width: "13.99",
                        height: "10.54",
                        left: "525.76",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "72",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "107.89",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "them)",
                        width: "27.99",
                        height: "10.54",
                        left: "122.48",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.32",
                        height: "10.54",
                        left: "155.72",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.67",
                        height: "10.54",
                        left: "184.29",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "204.21",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "247.81",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "submitting",
                        width: "51.34",
                        height: "10.54",
                        left: "265.06",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "written",
                        width: "33.99",
                        height: "10.54",
                        left: "321.65",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notification",
                        width: "55.32",
                        height: "10.54",
                        left: "360.89",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "421.46",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "436.04",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Copyright",
                        width: "48.67",
                        height: "10.54",
                        left: "457.29",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Agent",
                        width: "29.32",
                        height: "10.54",
                        left: "510.46",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "designated",
                        width: "51.32",
                        height: "10.54",
                        left: "72",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below.",
                        width: "31.54",
                        height: "10.54",
                        left: "126.32",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "It",
                        width: "7.33",
                        height: "10.54",
                        left: "160.86",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "171.19",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "182.2",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "policy",
                        width: "30",
                        height: "10.54",
                        left: "201.19",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "234.19",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terminate",
                        width: "45.31",
                        height: "10.54",
                        left: "246.52",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "294.83",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accounts",
                        width: "41.98",
                        height: "10.54",
                        left: "312.49",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "357.47",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "repeat",
                        width: "29.31",
                        height: "10.54",
                        left: "370.47",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "infringers.",
                        width: "49.65",
                        height: "10.54",
                        left: "402.78",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "In",
                        width: "10",
                        height: "10.55",
                        left: "72",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accordance",
                        width: "53.95",
                        height: "10.55",
                        left: "87.25",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.55",
                        left: "146.45",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "173.03",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Online",
                        width: "32.66",
                        height: "10.55",
                        left: "192.19",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Copyright",
                        width: "48.67",
                        height: "10.55",
                        left: "229.35",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Infringement",
                        width: "62.64",
                        height: "10.55",
                        left: "282.52",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Liability",
                        width: "41.33",
                        height: "10.55",
                        left: "349.66",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Limitation",
                        width: "50.66",
                        height: "10.55",
                        left: "395.49",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Act",
                        width: "17.33",
                        height: "10.55",
                        left: "450.65",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.55",
                        left: "472.48",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "486.97",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Digital",
                        width: "33.33",
                        height: "10.55",
                        left: "506.13",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Millennium",
                        width: "56.67",
                        height: "10.54",
                        left: "72",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Copyright",
                        width: "48.66",
                        height: "10.54",
                        left: "134.67",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Act",
                        width: "17.33",
                        height: "10.54",
                        left: "189.33",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(17",
                        width: "16",
                        height: "10.54",
                        left: "212.66",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "U.S.C.",
                        width: "32.34",
                        height: "10.54",
                        left: "234.66",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "§",
                        width: "6",
                        height: "10.54",
                        left: "273",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "512)",
                        width: "22",
                        height: "10.54",
                        left: "285",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(“",
                        width: "9.32",
                        height: "10.54",
                        left: "313",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "DMCA",
                        width: "37.32",
                        height: "10.54",
                        left: "322.32",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”),",
                        width: "12.32",
                        height: "10.54",
                        left: "359.64",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.67",
                        height: "10.54",
                        left: "377.96",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "written",
                        width: "33.99",
                        height: "10.54",
                        left: "398.63",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notice",
                        width: "29.32",
                        height: "10.54",
                        left: "438.62",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(the",
                        width: "18.65",
                        height: "10.54",
                        left: "473.19",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "“",
                        width: "5.33",
                        height: "10.54",
                        left: "497.09",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "DMCA",
                        width: "37.32",
                        height: "10.54",
                        left: "502.42",
                        top: "484.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Notice",
                        width: "32.65",
                        height: "10.54",
                        left: "72",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”)",
                        width: "9.32",
                        height: "10.54",
                        left: "104.65",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.34",
                        height: "10.54",
                        left: "116.97",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "include",
                        width: "35.32",
                        height: "10.54",
                        left: "143.31",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "substantially",
                        width: "60.66",
                        height: "10.54",
                        left: "181.63",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "245.29",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "following:",
                        width: "50",
                        height: "10.54",
                        left: "262.95",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "443.49",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.54",
                        left: "108",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "physical",
                        width: "39.99",
                        height: "10.54",
                        left: "134.46",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "177.45",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "electronic",
                        width: "47.3",
                        height: "10.54",
                        left: "190.45",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "signature.",
                        width: "46.99",
                        height: "10.54",
                        left: "240.75",
                        top: "443.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "429.7",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Identification",
                        width: "64.64",
                        height: "10.54",
                        left: "108",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "176.39",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "190.14",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copyrighted",
                        width: "57.31",
                        height: "10.54",
                        left: "208.55",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "work",
                        width: "24.67",
                        height: "10.54",
                        left: "269.61",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "298.03",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "believe",
                        width: "34.64",
                        height: "10.54",
                        left: "319.78",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "358.17",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "371.26",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "been",
                        width: "22.65",
                        height: "10.54",
                        left: "396.91",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "infringed",
                        width: "43.99",
                        height: "10.54",
                        left: "422.56",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or,",
                        width: "12.51",
                        height: "10.54",
                        left: "469.55",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "485.06",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "495.39",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claim",
                        width: "26.66",
                        height: "10.54",
                        left: "513.05",
                        top: "429.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "involves",
                        width: "40.66",
                        height: "10.54",
                        left: "108",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "multiple",
                        width: "40",
                        height: "10.54",
                        left: "151.66",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "works",
                        width: "29.33",
                        height: "10.54",
                        left: "194.66",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "226.99",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "241.99",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "259.65",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "304.01",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "representative",
                        width: "67.3",
                        height: "10.54",
                        left: "312.33",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "list",
                        width: "14.67",
                        height: "10.54",
                        left: "382.63",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "400.3",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "413.3",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "works.",
                        width: "32.34",
                        height: "10.54",
                        left: "438.29",
                        top: "415.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "402.1",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Identification",
                        width: "64.64",
                        height: "10.54",
                        left: "108",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "176.39",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "190.14",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "208.55",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "251.61",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "believe",
                        width: "34.64",
                        height: "10.54",
                        left: "273.36",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "311",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "323.34",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "infringing",
                        width: "48",
                        height: "10.54",
                        left: "337.66",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "388.66",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "400.99",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sufficiently",
                        width: "54.43",
                        height: "10.54",
                        left: "409.32",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "precise",
                        width: "33.98",
                        height: "10.54",
                        left: "466.75",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "manner",
                        width: "35.98",
                        height: "10.54",
                        left: "503.73",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "108",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "allow",
                        width: "26.66",
                        height: "10.54",
                        left: "120.33",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "149.99",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "163.66",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "locate",
                        width: "28.64",
                        height: "10.54",
                        left: "176",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "207.64",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material.",
                        width: "42.31",
                        height: "10.54",
                        left: "228.64",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "374.5",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Adequate",
                        width: "45.98",
                        height: "10.54",
                        left: "108",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "157.73",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "217.38",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "29.33",
                        height: "10.54",
                        left: "232.38",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "264.71",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "can",
                        width: "16.65",
                        height: "10.54",
                        left: "281.7",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contact",
                        width: "34.65",
                        height: "10.54",
                        left: "301.35",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "339",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(including",
                        width: "49.32",
                        height: "10.54",
                        left: "360",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "412.32",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "name,",
                        width: "28.99",
                        height: "10.54",
                        left: "437.32",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "postal",
                        width: "28.66",
                        height: "10.54",
                        left: "469.31",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address,",
                        width: "38.99",
                        height: "10.54",
                        left: "500.97",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "telephone",
                        width: "46.65",
                        height: "10.55",
                        left: "108",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "number,",
                        width: "39.17",
                        height: "10.55",
                        left: "157.65",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and,",
                        width: "20.33",
                        height: "10.55",
                        left: "199.82",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.55",
                        left: "223.15",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available,",
                        width: "46.31",
                        height: "10.55",
                        left: "233.48",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.65",
                        height: "10.55",
                        left: "282.79",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address).",
                        width: "42.98",
                        height: "10.55",
                        left: "312.44",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "346.9",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.67",
                        height: "10.54",
                        left: "108",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "statement",
                        width: "45.98",
                        height: "10.54",
                        left: "121.17",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "170.9",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "192.64",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.66",
                        height: "10.54",
                        left: "214.39",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "240.8",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "good",
                        width: "24",
                        height: "10.54",
                        left: "249.87",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "faith",
                        width: "21.99",
                        height: "10.54",
                        left: "277.62",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "belief",
                        width: "27.32",
                        height: "10.54",
                        left: "303.36",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "334.43",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "356.17",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "375.92",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "389.67",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copyrighted",
                        width: "57.31",
                        height: "10.54",
                        left: "408.08",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "469.14",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "512.2",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "523.96",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "authorized",
                        width: "50.64",
                        height: "10.54",
                        left: "108",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "161.64",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "176.64",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copyright",
                        width: "45.99",
                        height: "10.54",
                        left: "194.3",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owner,",
                        width: "32.51",
                        height: "10.54",
                        left: "243.29",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "11.34",
                        height: "10.54",
                        left: "278.8",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agent,",
                        width: "28.98",
                        height: "10.54",
                        left: "293.14",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "325.13",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "338.12",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law.",
                        width: "19.55",
                        height: "10.54",
                        left: "355.78",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "319.3",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.67",
                        height: "10.54",
                        left: "108",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "statement",
                        width: "45.98",
                        height: "10.54",
                        left: "119.67",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "168.65",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "189.64",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "207.3",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "266.96",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "279.29",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "written",
                        width: "33.99",
                        height: "10.54",
                        left: "296.95",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notice",
                        width: "29.32",
                        height: "10.54",
                        left: "333.94",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "366.26",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accurate.",
                        width: "42.96",
                        height: "10.54",
                        left: "377.27",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "305.51",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.67",
                        height: "10.54",
                        left: "108",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "statement,",
                        width: "48.98",
                        height: "10.54",
                        left: "121.92",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "176.15",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "penalty",
                        width: "35.32",
                        height: "10.54",
                        left: "208.72",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "249.29",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "perjury,",
                        width: "36.87",
                        height: "10.54",
                        left: "264.54",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "306.66",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "329.91",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.64",
                        height: "10.54",
                        left: "353.16",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "authorized",
                        width: "50.65",
                        height: "10.54",
                        left: "372.3",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "427.45",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "act",
                        width: "13.99",
                        height: "10.54",
                        left: "441.28",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "459.77",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "behalf",
                        width: "29.98",
                        height: "10.54",
                        left: "476.27",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "510.75",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.25",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "copyright",
                        width: "45.99",
                        height: "10.54",
                        left: "108",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owner.",
                        width: "32.33",
                        height: "10.54",
                        left: "156.99",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "72",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "83.74",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fail",
                        width: "15.99",
                        height: "10.54",
                        left: "105.49",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "125.23",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "comply",
                        width: "35.99",
                        height: "10.54",
                        left: "138.32",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "178.06",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "203.14",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "218.89",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "requirements",
                        width: "62.64",
                        height: "10.54",
                        left: "237.3",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "303.69",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Section",
                        width: "35.99",
                        height: "10.54",
                        left: "317.44",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "512(c)(3)",
                        width: "45.31",
                        height: "10.54",
                        left: "357.18",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "406.24",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "419.24",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DMCA,",
                        width: "39",
                        height: "10.54",
                        left: "436.9",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "478.9",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DMCA",
                        width: "36.01",
                        height: "10.54",
                        left: "503.9",
                        top: "263.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Notice",
                        width: "31.99",
                        height: "10.54",
                        left: "72",
                        top: "249.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "107.74",
                        top: "249.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "132.15",
                        top: "249.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "151.23",
                        top: "249.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "effective.",
                        width: "44.74",
                        height: "10.54",
                        left: "166.31",
                        top: "249.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Please",
                        width: "30.66",
                        height: "10.54",
                        left: "214.8",
                        top: "249.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "249.21",
                        top: "249.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "aware",
                        width: "28.64",
                        height: "10.54",
                        left: "264.29",
                        top: "249.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "296.68",
                        top: "249.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "318.42",
                        top: "249.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "329.5",
                        top: "249.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "knowingly",
                        width: "51.34",
                        height: "10.54",
                        left: "351.25",
                        top: "249.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materially",
                        width: "48.64",
                        height: "10.54",
                        left: "405.59",
                        top: "249.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "misrepresent",
                        width: "61.31",
                        height: "10.54",
                        left: "457.23",
                        top: "249.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "521.54",
                        top: "249.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "72",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "116.56",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activity",
                        width: "35.98",
                        height: "10.54",
                        left: "131.81",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "173.04",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.67",
                        height: "10.54",
                        left: "190.29",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "210.21",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "253.81",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "infringing",
                        width: "48",
                        height: "10.54",
                        left: "267.06",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "320.31",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copyright,",
                        width: "48.99",
                        height: "10.54",
                        left: "347.55",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "401.79",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.67",
                        height: "10.54",
                        left: "425.04",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "450.96",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "held",
                        width: "20.66",
                        height: "10.54",
                        left: "467.53",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liable",
                        width: "26.66",
                        height: "10.54",
                        left: "493.44",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "525.35",
                        top: "235.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "damages",
                        width: "41.98",
                        height: "10.54",
                        left: "72",
                        top: "222.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(including",
                        width: "49.33",
                        height: "10.54",
                        left: "116.98",
                        top: "222.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "costs",
                        width: "24",
                        height: "10.54",
                        left: "169.31",
                        top: "222.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "196.31",
                        top: "222.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "attorneys’",
                        width: "47.99",
                        height: "10.54",
                        left: "216.63",
                        top: "222.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees)",
                        width: "23.31",
                        height: "10.54",
                        left: "267.62",
                        top: "222.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "293.93",
                        top: "222.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Section",
                        width: "36",
                        height: "10.54",
                        left: "324.25",
                        top: "222.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "512(f)",
                        width: "29.98",
                        height: "10.54",
                        left: "363.25",
                        top: "222.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "396.23",
                        top: "222.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "409.23",
                        top: "222.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DMCA.",
                        width: "39.01",
                        height: "10.54",
                        left: "426.89",
                        top: "222.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "DMCA",
                        width: "36.01",
                        height: "10.54",
                        left: "72",
                        top: "194.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Notices",
                        width: "36.65",
                        height: "10.54",
                        left: "111.01",
                        top: "194.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.34",
                        height: "10.54",
                        left: "150.66",
                        top: "194.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "177",
                        top: "194.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "delivered",
                        width: "44.64",
                        height: "10.54",
                        left: "191.33",
                        top: "194.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "via",
                        width: "14.66",
                        height: "10.54",
                        left: "238.97",
                        top: "194.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.65",
                        height: "10.54",
                        left: "256.63",
                        top: "194.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "286.28",
                        top: "194.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "298.62",
                        top: "194.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "designated",
                        width: "51.32",
                        height: "10.54",
                        left: "317.61",
                        top: "194.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Copyright",
                        width: "48.67",
                        height: "10.54",
                        left: "371.93",
                        top: "194.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Agent:",
                        width: "32.66",
                        height: "10.54",
                        left: "423.6",
                        top: "194.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Attn:",
                        width: "24.67",
                        height: "10.54",
                        left: "96",
                        top: "166.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Copyright",
                        width: "48.67",
                        height: "10.54",
                        left: "123.67",
                        top: "166.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Agent",
                        width: "29.32",
                        height: "10.54",
                        left: "175.34",
                        top: "166.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Moore,",
                        width: "22.66",
                        height: "10.54",
                        left: "172.32",
                        top: "152.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: " M&ore LLC. PO box 122416 Arlington, Tx 76012",
                        width: "22.66",
                        height: "10.54",
                        left: "172.32",
                        top: "152.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Texas",
                        width: "27.82",
                        height: "10.54",
                        left: "257.29",
                        top: "138.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "admin@mooreexotics.com",
                        width: "129.35",
                        height: "10.54",
                        left: "96",
                        top: "125.04",
                        color: "#1154cc",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                        link: "mailto:admin@mooreexotics.com",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Changes",
                        width: "44.01",
                        height: "10.54",
                        left: "72",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "119.01",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "132.01",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "40.67",
                        height: "10.54",
                        left: "151",
                        top: "97.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,720",
                    "lineto,540,720",
                    "lineto,540,691",
                    "lineto,72,691",
                    "lineto,72,720",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,692",
                    "lineto,540,692",
                    "lineto,540,677",
                    "lineto,72,677",
                    "lineto,72,692",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,678",
                    "lineto,540,678",
                    "lineto,540,663",
                    "lineto,72,663",
                    "lineto,72,678",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,665",
                    "lineto,540,665",
                    "lineto,540,650",
                    "lineto,72,650",
                    "lineto,72,665",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,651",
                    "lineto,540,651",
                    "lineto,540,636",
                    "lineto,72,636",
                    "lineto,72,651",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,637",
                    "lineto,540,637",
                    "lineto,540,608",
                    "lineto,72,608",
                    "lineto,72,637",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,609",
                    "lineto,540,609",
                    "lineto,540,580",
                    "lineto,72,580",
                    "lineto,72,609",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,581",
                    "lineto,540,581",
                    "lineto,540,566",
                    "lineto,72,566",
                    "lineto,72,581",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,568",
                    "lineto,540,568",
                    "lineto,540,553",
                    "lineto,72,553",
                    "lineto,72,568",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,554",
                    "lineto,540,554",
                    "lineto,540,539",
                    "lineto,72,539",
                    "lineto,72,554",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,540",
                    "lineto,540,540",
                    "lineto,540,511",
                    "lineto,72,511",
                    "lineto,72,540",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,512",
                    "lineto,540,512",
                    "lineto,540,497",
                    "lineto,72,497",
                    "lineto,72,512",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,498",
                    "lineto,540,498",
                    "lineto,540,483",
                    "lineto,72,483",
                    "lineto,72,498",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,485",
                    "lineto,540,485",
                    "lineto,540,470",
                    "lineto,72,470",
                    "lineto,72,485",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,457",
                    "lineto,540,457",
                    "lineto,540,442",
                    "lineto,90,442",
                    "lineto,90,457",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,443",
                    "lineto,540,443",
                    "lineto,540,428",
                    "lineto,90,428",
                    "lineto,90,443",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,429",
                    "lineto,540,429",
                    "lineto,540,414",
                    "lineto,90,414",
                    "lineto,90,429",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,415",
                    "lineto,540,415",
                    "lineto,540,400",
                    "lineto,90,400",
                    "lineto,90,415",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,402",
                    "lineto,540,402",
                    "lineto,540,387",
                    "lineto,90,387",
                    "lineto,90,402",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,388",
                    "lineto,540,388",
                    "lineto,540,373",
                    "lineto,90,373",
                    "lineto,90,388",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,374",
                    "lineto,540,374",
                    "lineto,540,359",
                    "lineto,90,359",
                    "lineto,90,374",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,360",
                    "lineto,540,360",
                    "lineto,540,345",
                    "lineto,90,345",
                    "lineto,90,360",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,346",
                    "lineto,540,346",
                    "lineto,540,331",
                    "lineto,90,331",
                    "lineto,90,346",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,333",
                    "lineto,540,333",
                    "lineto,540,318",
                    "lineto,90,318",
                    "lineto,90,333",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,319",
                    "lineto,540,319",
                    "lineto,540,304",
                    "lineto,90,304",
                    "lineto,90,319",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,305",
                    "lineto,540,305",
                    "lineto,540,290",
                    "lineto,90,290",
                    "lineto,90,305",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,277",
                    "lineto,540,277",
                    "lineto,540,262",
                    "lineto,72,262",
                    "lineto,72,277",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,263",
                    "lineto,540,263",
                    "lineto,540,248",
                    "lineto,72,248",
                    "lineto,72,263",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,250",
                    "lineto,540,250",
                    "lineto,540,235",
                    "lineto,72,235",
                    "lineto,72,250",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,236",
                    "lineto,540,236",
                    "lineto,540,207",
                    "lineto,72,207",
                    "lineto,72,236",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,208",
                    "lineto,540,208",
                    "lineto,540,193",
                    "lineto,72,193",
                    "lineto,72,208",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,96,180",
                    "lineto,540,180",
                    "lineto,540,165",
                    "lineto,96,165",
                    "lineto,96,180",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,96,166",
                    "lineto,540,166",
                    "lineto,540,151",
                    "lineto,96,151",
                    "lineto,96,166",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,96,153",
                    "lineto,540,153",
                    "lineto,540,138",
                    "lineto,96,138",
                    "lineto,96,153",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,96,139",
                    "lineto,540,139",
                    "lineto,540,124",
                    "lineto,96,124",
                    "lineto,96,139",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,111",
                    "lineto,540,111",
                    "lineto,540,82",
                    "lineto,72,82",
                    "lineto,72,111",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,596.5", "lineto,196,596.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#1154cc",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,96,126.5", "lineto,225,126.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,98.5", "lineto,192,98.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "8",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "92.19",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "update",
                        width: "31.99",
                        height: "10.54",
                        left: "116.6",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "152.34",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "170.75",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "209.82",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "225.57",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "246.66",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "288.76",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "315.84",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "340.92",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time,",
                        width: "24.33",
                        height: "10.54",
                        left: "354",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "but",
                        width: "15.33",
                        height: "10.54",
                        left: "382.08",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "11.34",
                        height: "10.54",
                        left: "401.16",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "416.25",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "455.32",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "467.07",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "necessarily",
                        width: "53.31",
                        height: "10.54",
                        left: "486.16",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "complete",
                        width: "43.98",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "119.73",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "up",
                        width: "12",
                        height: "10.54",
                        left: "133.48",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "149.23",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "date.",
                        width: "22.99",
                        height: "10.54",
                        left: "162.31",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Any",
                        width: "20.66",
                        height: "10.54",
                        left: "189.05",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "213.46",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "227.21",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "244.87",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "287.18",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "302.18",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "319.84",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "361.2",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "384.86",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "out",
                        width: "15.34",
                        height: "10.54",
                        left: "399.18",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "417.52",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "date",
                        width: "19.99",
                        height: "10.54",
                        left: "430.51",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "453.5",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "465.16",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "given",
                        width: "26.66",
                        height: "10.54",
                        left: "485.48",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time,",
                        width: "24.33",
                        height: "10.54",
                        left: "515.14",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "92.33",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "109.32",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "126.97",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "157.29",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "obligation",
                        width: "48.66",
                        height: "10.54",
                        left: "172.29",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "223.95",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "update",
                        width: "31.98",
                        height: "10.54",
                        left: "236.29",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "271.27",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material.",
                        width: "42.31",
                        height: "10.54",
                        left: "296.27",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Online",
                        width: "34.68",
                        height: "10.55",
                        left: "72",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Purchases",
                        width: "51.78",
                        height: "10.55",
                        left: "109.68",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "91.44",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.99",
                        height: "10.54",
                        left: "115.85",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "145.59",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "subscriptions,",
                        width: "66.33",
                        height: "10.54",
                        left: "181.98",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "products,",
                        width: "44.33",
                        height: "10.54",
                        left: "252.06",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "features,",
                        width: "40.98",
                        height: "10.54",
                        left: "299.39",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "343.37",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.65",
                        height: "10.54",
                        left: "363.69",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.31",
                        height: "10.54",
                        left: "405.34",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "451.65",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchase",
                        width: "42.64",
                        height: "10.54",
                        left: "468.64",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "514.28",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.28",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "72",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "113.08",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "131.49",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Third",
                        width: "26.66",
                        height: "10.54",
                        left: "176.6",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "party",
                        width: "24.65",
                        height: "10.54",
                        left: "207.01",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "products,",
                        width: "44.33",
                        height: "10.54",
                        left: "235.41",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "283.49",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals,",
                        width: "40.32",
                        height: "10.54",
                        left: "332.57",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "376.64",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "19.33",
                        height: "10.54",
                        left: "401.05",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "424.13",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.3",
                        height: "10.54",
                        left: "438.46",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "484.76",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.09",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "72",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(see",
                        width: "19.31",
                        height: "10.54",
                        left: "116.36",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.97",
                        height: "10.54",
                        left: "141.67",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payments,",
                        width: "49.66",
                        height: "10.54",
                        left: "207.64",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below).",
                        width: "36.32",
                        height: "10.54",
                        left: "263.3",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "305.63",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "331.09",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "363.06",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pay",
                        width: "17.32",
                        height: "10.54",
                        left: "378.4",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "401.72",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applicable",
                        width: "49.31",
                        height: "10.54",
                        left: "424.3",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees",
                        width: "19.32",
                        height: "10.54",
                        left: "478.86",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "503.43",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "522.67",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "purchases",
                        width: "47.31",
                        height: "10.54",
                        left: "72",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "122.31",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make,",
                        width: "28.99",
                        height: "10.54",
                        left: "143.31",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "175.3",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "223.63",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applicable",
                        width: "49.3",
                        height: "10.54",
                        left: "243.96",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "taxes.",
                        width: "27.66",
                        height: "10.54",
                        left: "296.26",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "326.92",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "represent",
                        width: "43.98",
                        height: "10.54",
                        left: "349.38",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "396.36",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "warrant",
                        width: "36.65",
                        height: "10.54",
                        left: "416.68",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "456.33",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "468.66",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "36",
                        height: "10.54",
                        left: "503.65",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "72",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "93.74",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "114.82",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "159.89",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "220.29",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "242.04",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "282.45",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "295.54",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "35.99",
                        height: "10.54",
                        left: "331.28",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "371.02",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "true",
                        width: "18.65",
                        height: "10.54",
                        left: "382.78",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "405.18",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "correct",
                        width: "33.3",
                        height: "10.54",
                        left: "426.26",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "462.56",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "482.89",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "503.88",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "524.88",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "authorized",
                        width: "50.64",
                        height: "10.54",
                        left: "72",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "126.39",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "139.48",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "159.22",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "184.97",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "method.",
                        width: "38.99",
                        height: "10.54",
                        left: "230.04",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "272.03",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "294.5",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "323.47",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promptly",
                        width: "43.99",
                        height: "10.54",
                        left: "335.81",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "update",
                        width: "31.99",
                        height: "10.54",
                        left: "382.8",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "417.79",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "442.79",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "483.1",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "72",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "97.08",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "changes",
                        width: "38.65",
                        height: "10.54",
                        left: "118.16",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "160.56",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "182.3",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "206.71",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "necessary",
                        width: "46.64",
                        height: "10.54",
                        left: "221.79",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(for",
                        width: "17.99",
                        height: "10.54",
                        left: "272.18",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "example,",
                        width: "43.64",
                        height: "10.54",
                        left: "293.92",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "341.31",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "change",
                        width: "33.98",
                        height: "10.54",
                        left: "350.39",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "388.12",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "401.2",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "billing",
                        width: "31.34",
                        height: "10.54",
                        left: "426.2",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address",
                        width: "35.98",
                        height: "10.54",
                        left: "460.54",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "499.52",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "credit",
                        width: "27.32",
                        height: "10.54",
                        left: "512.52",
                        top: "540.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "card",
                        width: "20.65",
                        height: "10.55",
                        left: "72",
                        top: "526.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "expiration",
                        width: "48.65",
                        height: "10.55",
                        left: "95.65",
                        top: "526.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "date).",
                        width: "26.98",
                        height: "10.55",
                        left: "147.3",
                        top: "526.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Subscription",
                        width: "65.36",
                        height: "10.54",
                        left: "72",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms.",
                        width: "35.22",
                        height: "10.54",
                        left: "140.36",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offer",
                        width: "23.1",
                        height: "10.54",
                        left: "92.19",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "digital",
                        width: "30.66",
                        height: "10.54",
                        left: "119.79",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "subscriptions",
                        width: "63.34",
                        height: "10.54",
                        left: "154.95",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "222.79",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchase",
                        width: "42.64",
                        height: "10.54",
                        left: "240.53",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "286.92",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "328",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "347.75",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "389.85",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "411.6",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.65",
                        height: "10.54",
                        left: "434.02",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "474.42",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.65",
                        height: "10.54",
                        left: "496.17",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.3299999999999",
                        height: "10.54",
                        left: "530.57",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "72",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content,",
                        width: "41",
                        height: "10.54",
                        left: "108.39",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "potentially",
                        width: "51.32",
                        height: "10.54",
                        left: "153.14",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "208.21",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "257.29",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ability",
                        width: "30.66",
                        height: "10.54",
                        left: "275.7",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "310.11",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "list,",
                        width: "17.67",
                        height: "10.54",
                        left: "323.2",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sell,",
                        width: "19.66",
                        height: "10.54",
                        left: "344.62",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "view,",
                        width: "25.55",
                        height: "10.54",
                        left: "367.28",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "395.83",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchase",
                        width: "42.64",
                        height: "10.54",
                        left: "408.83",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals",
                        width: "37.32",
                        height: "10.54",
                        left: "454.47",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "494.79",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "515.12",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "items",
                        width: "26",
                        height: "10.54",
                        left: "72",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "101.75",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "114.08",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.32",
                        height: "10.54",
                        left: "127.08",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "153.4",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "party",
                        width: "24.65",
                        height: "10.54",
                        left: "179.07",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.67",
                        height: "10.54",
                        left: "206.72",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(the",
                        width: "18.65",
                        height: "10.54",
                        left: "234.39",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "“",
                        width: "5.33",
                        height: "10.54",
                        left: "256.04",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Subscriptions",
                        width: "70.02",
                        height: "10.54",
                        left: "261.37",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”).",
                        width: "12.33",
                        height: "10.54",
                        left: "331.39",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "This",
                        width: "21.33",
                        height: "10.54",
                        left: "346.72",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Section",
                        width: "35.99",
                        height: "10.54",
                        left: "371.05",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applies",
                        width: "34",
                        height: "10.54",
                        left: "410.04",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "447.04",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "459.37",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchase",
                        width: "42.64",
                        height: "10.54",
                        left: "477.03",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "522.67",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "sale",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "93.66",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscriptions.",
                        width: "68.34",
                        height: "10.54",
                        left: "106.65",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "1.",
                        width: "9",
                        height: "10.54",
                        left: "90",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscriptions",
                        width: "65.34",
                        height: "10.54",
                        left: "108",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "173.34",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "By",
                        width: "14",
                        height: "10.54",
                        left: "179.34",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "submitting",
                        width: "51.34",
                        height: "10.54",
                        left: "196.34",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "250.68",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "order",
                        width: "25.32",
                        height: "10.54",
                        left: "265.01",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "293.33",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "333.66",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "351.32",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "392.67",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchase",
                        width: "42.64",
                        height: "10.54",
                        left: "405.01",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscriptions,",
                        width: "68.34",
                        height: "10.54",
                        left: "450.65",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "521.99",
                        top: "401.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "108",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "137.72",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "158.72",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "order",
                        width: "25.32",
                        height: "10.54",
                        left: "183.71",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "212.03",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.32",
                        height: "10.54",
                        left: "223.04",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offer",
                        width: "23.1",
                        height: "10.54",
                        left: "237.36",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "263.46",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "buy,",
                        width: "20.23",
                        height: "10.54",
                        left: "275.79",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "299.02",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "329.34",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "356.99",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "389.81",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use,",
                        width: "21.66",
                        height: "10.54",
                        left: "402.81",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "427.47",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscriptions",
                        width: "65.34",
                        height: "10.54",
                        left: "445.13",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "listed",
                        width: "26",
                        height: "10.54",
                        left: "513.47",
                        top: "387.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "108",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "121.83",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "order.",
                        width: "27.66",
                        height: "10.54",
                        left: "148.33",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "All",
                        width: "15.33",
                        height: "10.54",
                        left: "180.49",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "orders",
                        width: "29.99",
                        height: "10.54",
                        left: "200.32",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.34",
                        height: "10.54",
                        left: "234.81",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "262.65",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accepted",
                        width: "41.97",
                        height: "10.54",
                        left: "278.47",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "324.94",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "340.69",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "355.11",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "368.85",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "386.6",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "409.01",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "428.1",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "obligated",
                        width: "44.66",
                        height: "10.54",
                        left: "443.17",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "491.58",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sell",
                        width: "16.67",
                        height: "10.54",
                        left: "504.66",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.08",
                        top: "374.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Subscriptions",
                        width: "65.34",
                        height: "10.55",
                        left: "108",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "178.59",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you.",
                        width: "21",
                        height: "10.55",
                        left: "192.42",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.55",
                        left: "217.92",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "238.11",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "choose",
                        width: "33.33",
                        height: "10.55",
                        left: "263.27",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.55",
                        left: "301.1",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "320.93",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accept",
                        width: "30.64",
                        height: "10.55",
                        left: "334.76",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "orders",
                        width: "29.99",
                        height: "10.55",
                        left: "369.9",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.55",
                        left: "404.39",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.55",
                        left: "417.55",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.55",
                        left: "438.05",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion,",
                        width: "50.32",
                        height: "10.55",
                        left: "461.88",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "even",
                        width: "22.65",
                        height: "10.55",
                        left: "516.7",
                        top: "360.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "after",
                        width: "21.98",
                        height: "10.54",
                        left: "108",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "133.73",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "send",
                        width: "22",
                        height: "10.54",
                        left: "151.47",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "177.22",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "198.97",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "confirmation",
                        width: "61.98",
                        height: "10.54",
                        left: "208.04",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.66",
                        height: "10.54",
                        left: "273.77",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "304.18",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "329.26",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "order",
                        width: "25.32",
                        height: "10.54",
                        left: "355.01",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "number",
                        width: "36.65",
                        height: "10.54",
                        left: "384.08",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "424.48",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "details.",
                        width: "34.32",
                        height: "10.54",
                        left: "445.56",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "When",
                        width: "28.66",
                        height: "10.54",
                        left: "485.88",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "517.54",
                        top: "346.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "108",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terminates,",
                        width: "52.98",
                        height: "10.54",
                        left: "173.17",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "230.65",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.65",
                        height: "10.54",
                        left: "257.14",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "292.29",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "305.37",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "323.78",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "365.53",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "386.61",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "37.99",
                        height: "10.54",
                        left: "413.02",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(including",
                        width: "49.33",
                        height: "10.54",
                        left: "454.76",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "profile",
                        width: "31.98",
                        height: "10.54",
                        left: "507.84",
                        top: "332.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "pages",
                        width: "27.32",
                        height: "10.54",
                        left: "108",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "140.57",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sale",
                        width: "18.65",
                        height: "10.54",
                        left: "163.15",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "listings)",
                        width: "38.68",
                        height: "10.54",
                        left: "187.05",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "obtained",
                        width: "41.32",
                        height: "10.54",
                        left: "230.98",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "276.8",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made",
                        width: "25.99",
                        height: "10.54",
                        left: "291.29",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.31",
                        height: "10.54",
                        left: "321.78",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "369.59",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "411.42",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "437.91",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "503.08",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "528.24",
                        top: "318.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "deleted",
                        width: "34.65",
                        height: "10.54",
                        left: "108",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "146.4",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disabled",
                        width: "39.99",
                        height: "10.54",
                        left: "160.14",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "so",
                        width: "10.67",
                        height: "10.54",
                        left: "203.88",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "218.3",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "6.66",
                        height: "10.54",
                        left: "240.05",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "250.46",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "262.22",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "longer",
                        width: "30.66",
                        height: "10.54",
                        left: "277.97",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accessible",
                        width: "48.63",
                        height: "10.54",
                        left: "312.38",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "364.76",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you.",
                        width: "21",
                        height: "10.54",
                        left: "377.85",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "401.4",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "can",
                        width: "16.65",
                        height: "10.54",
                        left: "424.62",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "find",
                        width: "19.33",
                        height: "10.54",
                        left: "445.02",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "specific",
                        width: "37.31",
                        height: "10.54",
                        left: "468.1",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "details",
                        width: "31.33",
                        height: "10.54",
                        left: "508.41",
                        top: "305.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "regarding",
                        width: "45.98",
                        height: "10.54",
                        left: "108",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "165.98",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "199.97",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "272.64",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "visiting",
                        width: "36.01",
                        height: "10.54",
                        left: "296.64",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "344.65",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "372.64",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "423",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "clicking",
                        width: "38.65",
                        height: "10.54",
                        left: "451.58",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "501.48",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.73",
                        top: "291.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "https://mooreexotics.com/seller/dashboard",
                        width: "203.62",
                        height: "10.54",
                        left: "108",
                        top: "277.43",
                        color: "#1154cc",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                        link: "https://mooreexotics.com/seller/dashboard",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "2.",
                        width: "9",
                        height: "10.54",
                        left: "90",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Billing",
                        width: "32.67",
                        height: "10.54",
                        left: "108",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "140.67",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "The",
                        width: "18.65",
                        height: "10.54",
                        left: "147.42",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fee",
                        width: "14.65",
                        height: "10.54",
                        left: "169.82",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "188.22",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "205.96",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "230.96",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "294.63",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "314.95",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "335.28",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "charges",
                        width: "36.42",
                        height: "10.54",
                        left: "362.94",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "402.36",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "423.36",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "incur",
                        width: "24.66",
                        height: "10.54",
                        left: "447.02",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "474.68",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "connection",
                        width: "52.65",
                        height: "10.54",
                        left: "487.01",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "108",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "134.58",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "161.83",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "183.08",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "198.32",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription,",
                        width: "63.67",
                        height: "10.54",
                        left: "217.48",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "285.65",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "312.15",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "taxes",
                        width: "24.66",
                        height: "10.54",
                        left: "326.64",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "355.8",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "possible",
                        width: "39.33",
                        height: "10.54",
                        left: "377.63",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transaction",
                        width: "52.65",
                        height: "10.54",
                        left: "421.46",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees,",
                        width: "22.31",
                        height: "10.54",
                        left: "478.61",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "505.42",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "528.59",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "charged",
                        width: "37.76",
                        height: "10.54",
                        left: "108",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "151.76",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "167.09",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "chosen",
                        width: "33.32",
                        height: "10.54",
                        left: "195.09",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "234.41",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "method",
                        width: "35.99",
                        height: "10.54",
                        left: "281.73",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "323.72",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "341.72",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "first",
                        width: "19.33",
                        height: "10.54",
                        left: "362.38",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "day",
                        width: "17.33",
                        height: "10.54",
                        left: "387.71",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "411.04",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "each",
                        width: "21.98",
                        height: "10.54",
                        left: "427.04",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "billing",
                        width: "31.33",
                        height: "10.54",
                        left: "454.27",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cycle.",
                        width: "28.31",
                        height: "10.54",
                        left: "490.85",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "All",
                        width: "15.34",
                        height: "10.54",
                        left: "524.41",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Subscriptions",
                        width: "65.34",
                        height: "10.54",
                        left: "108",
                        top: "210.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "176.34",
                        top: "210.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "renewed",
                        width: "40.64",
                        height: "10.54",
                        left: "193.99",
                        top: "210.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "automatically",
                        width: "65.31",
                        height: "10.54",
                        left: "237.63",
                        top: "210.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "until",
                        width: "22",
                        height: "10.54",
                        left: "305.94",
                        top: "210.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "canceled.",
                        width: "44.96",
                        height: "10.54",
                        left: "330.94",
                        top: "210.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Visit",
                        width: "22.62",
                        height: "10.54",
                        left: "378.9",
                        top: "210.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "404.52",
                        top: "210.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "website",
                        width: "36.65",
                        height: "10.54",
                        left: "423.52",
                        top: "210.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "463.17",
                        top: "210.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "click",
                        width: "23.32",
                        height: "10.54",
                        left: "483.5",
                        top: "210.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "509.82",
                        top: "210.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.82",
                        top: "210.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: '"',
                        width: "4.9",
                        height: "10.54",
                        left: "108",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Billing",
                        width: "34.01",
                        height: "10.54",
                        left: "112.9",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"',
                        width: "4.9",
                        height: "10.54",
                        left: "146.91",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "link",
                        width: "18.67",
                        height: "10.54",
                        left: "156.31",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "178.73",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "194.48",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"',
                        width: "4.9",
                        height: "10.54",
                        left: "220.22",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Seller",
                        width: "29.32",
                        height: "10.54",
                        left: "225.12",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Dashboard",
                        width: "56.69",
                        height: "10.54",
                        left: "258.19",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"',
                        width: "4.9",
                        height: "10.54",
                        left: "314.88",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "page",
                        width: "22.65",
                        height: "10.54",
                        left: "323.53",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "349.93",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "see",
                        width: "15.32",
                        height: "10.54",
                        left: "363.01",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "382.08",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "next",
                        width: "20.66",
                        height: "10.54",
                        left: "407.83",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "432.24",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "date.",
                        width: "22.99",
                        height: "10.54",
                        left: "477.31",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Posted",
                        width: "32",
                        height: "10.54",
                        left: "507.8",
                        top: "196.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "prices",
                        width: "28.65",
                        height: "10.54",
                        left: "108",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "140.4",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "156.15",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "include",
                        width: "35.32",
                        height: "10.54",
                        left: "174.49",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "taxes",
                        width: "24.65",
                        height: "10.54",
                        left: "212.81",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "240.46",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transaction",
                        width: "52.65",
                        height: "10.54",
                        left: "253.46",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees.",
                        width: "22.31",
                        height: "10.54",
                        left: "309.11",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "All",
                        width: "15.34",
                        height: "10.54",
                        left: "337.42",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "355.76",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "taxes",
                        width: "24.66",
                        height: "10.54",
                        left: "380.75",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "408.41",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transaction",
                        width: "52.64",
                        height: "10.54",
                        left: "428.74",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees",
                        width: "19.32",
                        height: "10.54",
                        left: "484.38",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "506.7",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "528.37",
                        top: "182.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "added",
                        width: "28.65",
                        height: "10.54",
                        left: "108",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "141.15",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "154.99",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "order",
                        width: "25.32",
                        height: "10.54",
                        left: "181.48",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "total,",
                        width: "24.33",
                        height: "10.54",
                        left: "211.3",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "240.13",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "261.96",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "285.12",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "itemized",
                        width: "41.31",
                        height: "10.54",
                        left: "300.95",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "346.01",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "359.1",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shopping",
                        width: "44.01",
                        height: "10.54",
                        left: "384.84",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cart",
                        width: "17.98",
                        height: "10.54",
                        left: "432.6",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "454.33",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "475.41",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "488.49",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "order",
                        width: "25.31",
                        height: "10.54",
                        left: "514.24",
                        top: "168.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "confirmation",
                        width: "61.98",
                        height: "10.54",
                        left: "108",
                        top: "155.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email.",
                        width: "29.65",
                        height: "10.54",
                        left: "173.73",
                        top: "155.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.7",
                        height: "10.54",
                        left: "206.38",
                        top: "155.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "strive",
                        width: "26.66",
                        height: "10.54",
                        left: "225.08",
                        top: "155.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "254.74",
                        top: "155.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "display",
                        width: "34.66",
                        height: "10.54",
                        left: "267.07",
                        top: "155.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accurate",
                        width: "39.97",
                        height: "10.54",
                        left: "304.73",
                        top: "155.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "price",
                        width: "23.98",
                        height: "10.54",
                        left: "347.7",
                        top: "155.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information;",
                        width: "59.99",
                        height: "10.54",
                        left: "374.68",
                        top: "155.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "however,",
                        width: "43.83",
                        height: "10.54",
                        left: "437.67",
                        top: "155.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "484.5",
                        top: "155.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may,",
                        width: "22.88",
                        height: "10.54",
                        left: "501.49",
                        top: "155.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "527.37",
                        top: "155.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "occasion,",
                        width: "44.98",
                        height: "10.54",
                        left: "108",
                        top: "141.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.99",
                        height: "10.54",
                        left: "158.23",
                        top: "141.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inadvertent",
                        width: "53.98",
                        height: "10.54",
                        left: "189.47",
                        top: "141.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "typographical",
                        width: "65.97",
                        height: "10.54",
                        left: "248.7",
                        top: "141.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "errors,",
                        width: "30.99",
                        height: "10.54",
                        left: "319.92",
                        top: "141.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inaccuracies,",
                        width: "62.29",
                        height: "10.54",
                        left: "356.16",
                        top: "141.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "423.7",
                        top: "141.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "omissions",
                        width: "48.02",
                        height: "10.54",
                        left: "438.94",
                        top: "141.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "related",
                        width: "32.64",
                        height: "10.54",
                        left: "492.21",
                        top: "141.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.3299999999999",
                        height: "10.54",
                        left: "530.1",
                        top: "141.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "pricing",
                        width: "33.99",
                        height: "10.54",
                        left: "108",
                        top: "127.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "148.74",
                        top: "127.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "availability.",
                        width: "56.2",
                        height: "10.54",
                        left: "172.82",
                        top: "127.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "235.02",
                        top: "127.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reserve",
                        width: "34.64",
                        height: "10.54",
                        left: "256.71",
                        top: "127.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "297.35",
                        top: "127.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.67",
                        height: "10.54",
                        left: "318.01",
                        top: "127.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "346.68",
                        top: "127.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "correct",
                        width: "33.3",
                        height: "10.54",
                        left: "362.01",
                        top: "127.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "401.31",
                        top: "127.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "errors,",
                        width: "30.99",
                        height: "10.54",
                        left: "424.64",
                        top: "127.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inaccuracies,",
                        width: "62.29",
                        height: "10.54",
                        left: "461.63",
                        top: "127.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "529.92",
                        top: "127.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "omissions",
                        width: "48.01",
                        height: "10.55",
                        left: "108",
                        top: "113.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.55",
                        left: "159.01",
                        top: "113.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.55",
                        left: "170.67",
                        top: "113.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.55",
                        left: "191",
                        top: "113.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.55",
                        left: "215.33",
                        top: "113.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.55",
                        left: "235.65",
                        top: "113.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cancel",
                        width: "30.64",
                        height: "10.55",
                        left: "247.99",
                        top: "113.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.55",
                        left: "281.63",
                        top: "113.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "orders",
                        width: "29.99",
                        height: "10.55",
                        left: "301.95",
                        top: "113.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arising",
                        width: "32.66",
                        height: "10.55",
                        left: "334.94",
                        top: "113.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.55",
                        left: "370.6",
                        top: "113.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.55",
                        left: "396.93",
                        top: "113.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "occurrences.",
                        width: "60.29",
                        height: "10.55",
                        left: "421.92",
                        top: "113.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "3.",
                        width: "9",
                        height: "10.54",
                        left: "90",
                        top: "87.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "41.98",
                        height: "10.54",
                        left: "108",
                        top: "87.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Methods",
                        width: "41.33",
                        height: "10.54",
                        left: "153.73",
                        top: "87.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "195.06",
                        top: "87.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "201.81",
                        top: "87.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "235.38",
                        top: "87.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "249.12",
                        top: "87.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "293.44",
                        top: "87.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "within",
                        width: "30.67",
                        height: "10.54",
                        left: "311.09",
                        top: "87.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "344.76",
                        top: "87.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "363.76",
                        top: "87.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion",
                        width: "47.32",
                        height: "10.54",
                        left: "386.09",
                        top: "87.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and,",
                        width: "20.32",
                        height: "10.54",
                        left: "436.41",
                        top: "87.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unless",
                        width: "30",
                        height: "10.54",
                        left: "459.73",
                        top: "87.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "492.73",
                        top: "87.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "agreed",
                        width: "31.97",
                        height: "10.54",
                        left: "108",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "145.22",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "162.47",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "178.39",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "writing,",
                        width: "37.66",
                        height: "10.54",
                        left: "192.23",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "234.39",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.34",
                        height: "10.54",
                        left: "280.21",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "308.05",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "received",
                        width: "40.63",
                        height: "10.54",
                        left: "323.88",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "369.01",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "385.51",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "before",
                        width: "30.65",
                        height: "10.54",
                        left: "400.68",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "435.83",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "acceptance",
                        width: "52.62",
                        height: "10.54",
                        left: "456.32",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "513.44",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.32",
                        height: "10.54",
                        left: "527.94",
                        top: "74.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,720",
                    "lineto,540,720",
                    "lineto,540,705",
                    "lineto,72,705",
                    "lineto,72,720",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,706",
                    "lineto,540,706",
                    "lineto,540,691",
                    "lineto,72,691",
                    "lineto,72,706",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,692",
                    "lineto,540,692",
                    "lineto,540,663",
                    "lineto,72,663",
                    "lineto,72,692",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,665",
                    "lineto,540,665",
                    "lineto,540,636",
                    "lineto,72,636",
                    "lineto,72,665",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,637",
                    "lineto,540,637",
                    "lineto,540,622",
                    "lineto,72,622",
                    "lineto,72,637",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,623",
                    "lineto,540,623",
                    "lineto,540,608",
                    "lineto,72,608",
                    "lineto,72,623",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,609",
                    "lineto,540,609",
                    "lineto,540,594",
                    "lineto,72,594",
                    "lineto,72,609",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,595",
                    "lineto,540,595",
                    "lineto,540,580",
                    "lineto,72,580",
                    "lineto,72,595",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,582",
                    "lineto,540,582",
                    "lineto,540,567",
                    "lineto,72,567",
                    "lineto,72,582",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,568",
                    "lineto,540,568",
                    "lineto,540,553",
                    "lineto,72,553",
                    "lineto,72,568",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,554",
                    "lineto,540,554",
                    "lineto,540,539",
                    "lineto,72,539",
                    "lineto,72,554",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,540",
                    "lineto,540,540",
                    "lineto,540,511",
                    "lineto,72,511",
                    "lineto,72,540",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,512",
                    "lineto,540,512",
                    "lineto,540,483",
                    "lineto,72,483",
                    "lineto,72,512",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,485",
                    "lineto,540,485",
                    "lineto,540,470",
                    "lineto,72,470",
                    "lineto,72,485",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,471",
                    "lineto,540,471",
                    "lineto,540,456",
                    "lineto,72,456",
                    "lineto,72,471",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,457",
                    "lineto,540,457",
                    "lineto,540,442",
                    "lineto,72,442",
                    "lineto,72,457",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,443",
                    "lineto,540,443",
                    "lineto,540,428",
                    "lineto,72,428",
                    "lineto,72,443",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,415",
                    "lineto,540,415",
                    "lineto,540,400",
                    "lineto,90,400",
                    "lineto,90,415",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,402",
                    "lineto,540,402",
                    "lineto,540,387",
                    "lineto,90,387",
                    "lineto,90,402",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,388",
                    "lineto,540,388",
                    "lineto,540,373",
                    "lineto,90,373",
                    "lineto,90,388",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,374",
                    "lineto,540,374",
                    "lineto,540,359",
                    "lineto,90,359",
                    "lineto,90,374",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,360",
                    "lineto,540,360",
                    "lineto,540,345",
                    "lineto,90,345",
                    "lineto,90,360",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,346",
                    "lineto,540,346",
                    "lineto,540,331",
                    "lineto,90,331",
                    "lineto,90,346",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,333",
                    "lineto,540,333",
                    "lineto,540,318",
                    "lineto,90,318",
                    "lineto,90,333",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,319",
                    "lineto,540,319",
                    "lineto,540,304",
                    "lineto,90,304",
                    "lineto,90,319",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,305",
                    "lineto,540,305",
                    "lineto,540,290",
                    "lineto,90,290",
                    "lineto,90,305",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,291",
                    "lineto,540,291",
                    "lineto,540,264",
                    "lineto,90,264",
                    "lineto,90,291",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,265",
                    "lineto,540,265",
                    "lineto,540,250",
                    "lineto,90,250",
                    "lineto,90,265",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,252",
                    "lineto,540,252",
                    "lineto,540,237",
                    "lineto,90,237",
                    "lineto,90,252",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,238",
                    "lineto,540,238",
                    "lineto,540,223",
                    "lineto,90,223",
                    "lineto,90,238",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,224",
                    "lineto,540,224",
                    "lineto,540,209",
                    "lineto,90,209",
                    "lineto,90,224",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,210",
                    "lineto,540,210",
                    "lineto,540,195",
                    "lineto,90,195",
                    "lineto,90,210",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,196",
                    "lineto,540,196",
                    "lineto,540,181",
                    "lineto,90,181",
                    "lineto,90,196",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,183",
                    "lineto,540,183",
                    "lineto,540,168",
                    "lineto,90,168",
                    "lineto,90,183",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,169",
                    "lineto,540,169",
                    "lineto,540,154",
                    "lineto,90,154",
                    "lineto,90,169",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,155",
                    "lineto,540,155",
                    "lineto,540,140",
                    "lineto,90,140",
                    "lineto,90,155",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,141",
                    "lineto,540,141",
                    "lineto,540,126",
                    "lineto,90,126",
                    "lineto,90,141",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,127",
                    "lineto,540,127",
                    "lineto,540,100",
                    "lineto,90,100",
                    "lineto,90,127",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,102",
                    "lineto,540,102",
                    "lineto,540,87",
                    "lineto,90,87",
                    "lineto,90,102",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,88",
                    "lineto,540,88",
                    "lineto,540,73",
                    "lineto,90,73",
                    "lineto,90,88",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,652.5", "lineto,161,652.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,142,596.5", "lineto,254,596.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,499.5", "lineto,173,499.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,108,402.5", "lineto,173,402.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#1154cc",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,108,278.5", "lineto,312,278.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,108,252.5", "lineto,141,252.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,108,89.5", "lineto,195,89.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "9",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "order.",
                        width: "27.66",
                        height: "10.54",
                        left: "108",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "141.66",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "currently",
                        width: "43.31",
                        height: "10.54",
                        left: "163.35",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accept",
                        width: "30.64",
                        height: "10.54",
                        left: "212.66",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "credit",
                        width: "27.32",
                        height: "10.54",
                        left: "249.3",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cards",
                        width: "25.31",
                        height: "10.54",
                        left: "281.87",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(Visa",
                        width: "25.28",
                        height: "10.54",
                        left: "312.43",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "342.96",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Mastercard)",
                        width: "57.97",
                        height: "10.54",
                        left: "365.53",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "428.75",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payments",
                        width: "45.99",
                        height: "10.54",
                        left: "451.32",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "502.56",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "electronic",
                        width: "47.3",
                        height: "10.54",
                        left: "108",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "161.3",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providers",
                        width: "45.33",
                        height: "10.54",
                        left: "208.62",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(Stripe",
                        width: "32.66",
                        height: "10.54",
                        left: "259.95",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "298.61",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PayPal)",
                        width: "37.33",
                        height: "10.54",
                        left: "314.6",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "357.18",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "376.42",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "393.67",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchases.",
                        width: "50.31",
                        height: "10.54",
                        left: "459.59",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "520.4",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "represent",
                        width: "43.97",
                        height: "10.54",
                        left: "108",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "155.72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "warrant",
                        width: "36.65",
                        height: "10.54",
                        left: "176.8",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "217.2",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(i)",
                        width: "11.33",
                        height: "10.54",
                        left: "238.94",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "254.02",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "credit",
                        width: "27.31",
                        height: "10.54",
                        left: "271.68",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "card",
                        width: "20.65",
                        height: "10.54",
                        left: "301.99",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "325.64",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "385.29",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "supply",
                        width: "32.01",
                        height: "10.54",
                        left: "406.29",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "441.3",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "453.63",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "467.3",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "true,",
                        width: "21.65",
                        height: "10.54",
                        left: "478.31",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "correct,",
                        width: "36.31",
                        height: "10.54",
                        left: "502.96",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "108",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "complete,",
                        width: "46.98",
                        height: "10.55",
                        left: "129.83",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(ii)",
                        width: "14.66",
                        height: "10.55",
                        left: "181.31",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "200.47",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.55",
                        left: "222.97",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "duly",
                        width: "21.33",
                        height: "10.55",
                        left: "242.12",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "authorized",
                        width: "50.64",
                        height: "10.55",
                        left: "267.95",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.55",
                        left: "322.34",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.55",
                        left: "335.43",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.55",
                        left: "355.17",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "credit",
                        width: "27.31",
                        height: "10.55",
                        left: "380.92",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "card",
                        width: "20.65",
                        height: "10.55",
                        left: "411.98",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.55",
                        left: "436.38",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "454.13",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchase,",
                        width: "45.64",
                        height: "10.55",
                        left: "472.54",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(iii)",
                        width: "17.99",
                        height: "10.55",
                        left: "521.93",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "charges",
                        width: "36.43",
                        height: "10.54",
                        left: "108",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "incurred",
                        width: "39.98",
                        height: "10.54",
                        left: "148.18",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "191.91",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "207.66",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "229.41",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "251.82",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "honored",
                        width: "39.32",
                        height: "10.54",
                        left: "266.9",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "309.97",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "325.72",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "credit",
                        width: "27.32",
                        height: "10.54",
                        left: "351.47",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "card",
                        width: "20.64",
                        height: "10.54",
                        left: "382.54",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "company,",
                        width: "46.21",
                        height: "10.54",
                        left: "406.93",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "456.89",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(iv)",
                        width: "17.32",
                        height: "10.54",
                        left: "477.97",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "499.04",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "520.79",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "pay",
                        width: "17.33",
                        height: "10.54",
                        left: "108",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "charges",
                        width: "36.42",
                        height: "10.54",
                        left: "128.33",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "incurred",
                        width: "39.98",
                        height: "10.54",
                        left: "167.75",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "210.73",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "225.73",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "246.73",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "258.39",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "posted",
                        width: "31.33",
                        height: "10.54",
                        left: "276.05",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prices,",
                        width: "31.66",
                        height: "10.54",
                        left: "310.38",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.32",
                        height: "10.54",
                        left: "345.04",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shipping",
                        width: "41.34",
                        height: "10.54",
                        left: "393.36",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "437.7",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "handling",
                        width: "41.99",
                        height: "10.54",
                        left: "458.03",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "charges",
                        width: "36.43",
                        height: "10.54",
                        left: "503.02",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "108",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "130.58",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applicable",
                        width: "49.31",
                        height: "10.54",
                        left: "147.07",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "taxes,",
                        width: "27.65",
                        height: "10.54",
                        left: "200.88",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "233.03",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any,",
                        width: "19.55",
                        height: "10.54",
                        left: "244.86",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "regardless",
                        width: "48.64",
                        height: "10.54",
                        left: "268.91",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "322.05",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "336.55",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amount",
                        width: "36",
                        height: "10.54",
                        left: "355.71",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "quoted",
                        width: "32.66",
                        height: "10.54",
                        left: "396.21",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "433.37",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "449.87",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "469.03",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "511.88",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.04",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "108",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "133.83",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "148.32",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "order.",
                        width: "27.66",
                        height: "10.54",
                        left: "174.82",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "211.48",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "223.97",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.33",
                        height: "10.54",
                        left: "250.46",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "method",
                        width: "35.99",
                        height: "10.54",
                        left: "296.29",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "336.78",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "declined",
                        width: "40.65",
                        height: "10.54",
                        left: "349.28",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "394.43",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "408.93",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "invalid,",
                        width: "36.33",
                        height: "10.54",
                        left: "459.33",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "499.41",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "521.16",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "remain",
                        width: "33.32",
                        height: "10.54",
                        left: "108",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsible",
                        width: "54.65",
                        height: "10.54",
                        left: "145.07",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "203.47",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "221.21",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "uncollected",
                        width: "55.31",
                        height: "10.54",
                        left: "242.29",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amounts.",
                        width: "43.66",
                        height: "10.54",
                        left: "301.35",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "348.76",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "360.5",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "369.58",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "414.65",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "425.65",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "successfully",
                        width: "58.65",
                        height: "10.54",
                        left: "443.99",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "settled,",
                        width: "34.32",
                        height: "10.54",
                        left: "505.64",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "108",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "124.99",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "suspend",
                        width: "38.67",
                        height: "10.54",
                        left: "148.65",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "190.32",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terminate",
                        width: "45.32",
                        height: "10.54",
                        left: "203.31",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "251.63",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription.",
                        width: "63.67",
                        height: "10.54",
                        left: "276.62",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "4.",
                        width: "9",
                        height: "10.54",
                        left: "90",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Updating",
                        width: "45.33",
                        height: "10.54",
                        left: "108",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "160.83",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "41.98",
                        height: "10.54",
                        left: "189.58",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Methods;",
                        width: "45.32",
                        height: "10.54",
                        left: "238.31",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "41.99",
                        height: "10.54",
                        left: "290.38",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Method",
                        width: "36.65",
                        height: "10.54",
                        left: "339.12",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Storage",
                        width: "37.33",
                        height: "10.54",
                        left: "382.52",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "419.85",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "429.6",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "can",
                        width: "16.65",
                        height: "10.54",
                        left: "455.82",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "update",
                        width: "31.99",
                        height: "10.54",
                        left: "479.22",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "517.96",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.55",
                        left: "108",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "methods",
                        width: "40.66",
                        height: "10.55",
                        left: "154.57",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.55",
                        left: "199.73",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "going",
                        width: "27.34",
                        height: "10.55",
                        left: "216.23",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "248.07",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "261.9",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"Billing"',
                        width: "43.14",
                        height: "10.55",
                        left: "288.4",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "page.",
                        width: "25.65",
                        height: "10.55",
                        left: "336.04",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.55",
                        left: "366.19",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "386.38",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "19.33",
                        height: "10.55",
                        left: "411.54",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "update",
                        width: "31.99",
                        height: "10.55",
                        left: "435.37",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.55",
                        left: "471.86",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.55",
                        left: "498.35",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "methods",
                        width: "40.66",
                        height: "10.54",
                        left: "108",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "using",
                        width: "26.01",
                        height: "10.54",
                        left: "153.91",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "185.17",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided",
                        width: "42.66",
                        height: "10.54",
                        left: "247.07",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "294.98",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "312.23",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "331.39",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "33.98",
                        height: "10.54",
                        left: "377.21",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providers.",
                        width: "48.32",
                        height: "10.54",
                        left: "415.69",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Following",
                        width: "49.34",
                        height: "10.54",
                        left: "468.51",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "522.35",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "108",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "update,",
                        width: "34.98",
                        height: "10.54",
                        left: "133.75",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "172.48",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "authorize",
                        width: "44.65",
                        height: "10.54",
                        left: "193.48",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.66",
                        height: "10.54",
                        left: "241.13",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "254.79",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "charge",
                        width: "31.76",
                        height: "10.54",
                        left: "267.13",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "301.89",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "319.55",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "method(s)",
                        width: "48.65",
                        height: "10.54",
                        left: "363.87",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "415.52",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.66",
                        height: "10.54",
                        left: "436.52",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided.",
                        width: "45.65",
                        height: "10.54",
                        left: "462.18",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "When",
                        width: "28.65",
                        height: "10.54",
                        left: "510.83",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "108",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "store",
                        width: "23.33",
                        height: "10.54",
                        left: "132",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "161.33",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "172.65",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "method",
                        width: "36",
                        height: "10.54",
                        left: "219.97",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "261.97",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us,",
                        width: "13.67",
                        height: "10.54",
                        left: "288.55",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "307.47",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.98",
                        height: "10.54",
                        left: "330.72",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "361.95",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "376.53",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "396.44",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "set",
                        width: "13.33",
                        height: "10.54",
                        left: "428.35",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "forth",
                        width: "23.33",
                        height: "10.54",
                        left: "446.93",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "475.51",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stored",
                        width: "31.33",
                        height: "10.54",
                        left: "508.08",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Payment,",
                        width: "44.99",
                        height: "10.54",
                        left: "108",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below.",
                        width: "31.55",
                        height: "10.54",
                        left: "155.99",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "5.",
                        width: "9",
                        height: "10.54",
                        left: "90",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Cancellation",
                        width: "61.99",
                        height: "10.54",
                        left: "108",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "169.99",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "177.49",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "201.46",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cancel",
                        width: "30.64",
                        height: "10.54",
                        left: "226.62",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "261.01",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "286.75",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "351.17",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "363.58",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.32",
                        height: "10.54",
                        left: "384.66",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "409.73",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "going",
                        width: "27.34",
                        height: "10.54",
                        left: "425.48",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "456.57",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "469.65",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "“Billing”",
                        width: "43.99",
                        height: "10.54",
                        left: "495.4",
                        top: "461.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "page",
                        width: "22.65",
                        height: "10.54",
                        left: "108",
                        top: "447.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "134.4",
                        top: "447.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "150.15",
                        top: "447.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "169.15",
                        top: "447.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "210.5",
                        top: "447.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "following",
                        width: "46.66",
                        height: "10.54",
                        left: "230.83",
                        top: "447.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "280.49",
                        top: "447.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "instructions",
                        width: "56",
                        height: "10.54",
                        left: "298.15",
                        top: "447.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "357.15",
                        top: "447.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cancellation.",
                        width: "60.97",
                        height: "10.54",
                        left: "374.14",
                        top: "447.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "438.11",
                        top: "447.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cancellation",
                        width: "57.96",
                        height: "10.54",
                        left: "459.77",
                        top: "447.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "520.73",
                        top: "447.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "become",
                        width: "37.31",
                        height: "10.54",
                        left: "108",
                        top: "433.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "effective",
                        width: "41.75",
                        height: "10.54",
                        left: "149.81",
                        top: "433.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "195.31",
                        top: "433.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "207.72",
                        top: "433.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "end",
                        width: "17.33",
                        height: "10.54",
                        left: "226.13",
                        top: "433.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "247.21",
                        top: "433.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "260.95",
                        top: "433.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "current",
                        width: "33.98",
                        height: "10.54",
                        left: "286.7",
                        top: "433.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "billing",
                        width: "31.33",
                        height: "10.54",
                        left: "324.43",
                        top: "433.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cycle.",
                        width: "28.32",
                        height: "10.54",
                        left: "359.51",
                        top: "433.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Accordingly,",
                        width: "62.2",
                        height: "10.54",
                        left: "391.58",
                        top: "433.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "457.53",
                        top: "433.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "487.27",
                        top: "433.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cancel",
                        width: "30.64",
                        height: "10.54",
                        left: "509.02",
                        top: "433.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "108",
                        top: "420.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "131.25",
                        top: "420.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "continue",
                        width: "41.32",
                        height: "10.54",
                        left: "154.42",
                        top: "420.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "200.24",
                        top: "420.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "214.07",
                        top: "420.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.65",
                        height: "10.54",
                        left: "241.22",
                        top: "420.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "276.37",
                        top: "420.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "290.2",
                        top: "420.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "316.7",
                        top: "420.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "381.87",
                        top: "420.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "423.7",
                        top: "420.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "end",
                        width: "17.32",
                        height: "10.54",
                        left: "442.86",
                        top: "420.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "464.68",
                        top: "420.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "479.18",
                        top: "420.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "current",
                        width: "33.97",
                        height: "10.54",
                        left: "505.68",
                        top: "420.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "billing",
                        width: "31.34",
                        height: "10.55",
                        left: "108",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cycle.",
                        width: "28.31",
                        height: "10.55",
                        left: "143.09",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.55",
                        left: "175.15",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.55",
                        left: "198.36",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.55",
                        left: "220.78",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "receive",
                        width: "34.63",
                        height: "10.55",
                        left: "239.87",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.55",
                        left: "278.25",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "refund",
                        width: "31.31",
                        height: "10.55",
                        left: "286.58",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.55",
                        left: "320.89",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.55",
                        left: "337.89",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.55",
                        left: "358.21",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees",
                        width: "19.32",
                        height: "10.55",
                        left: "421.88",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "already",
                        width: "35.31",
                        height: "10.55",
                        left: "444.2",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "paid.",
                        width: "23.66",
                        height: "10.55",
                        left: "482.51",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.55",
                        left: "509.17",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "see",
                        width: "15.32",
                        height: "10.55",
                        left: "524.66",
                        top: "406.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "108",
                        top: "392.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "136.99",
                        top: "392.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "161.99",
                        top: "392.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "225.66",
                        top: "392.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "end,",
                        width: "20.33",
                        height: "10.54",
                        left: "247.32",
                        top: "392.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "click",
                        width: "23.32",
                        height: "10.54",
                        left: "270.65",
                        top: "392.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"Billing"',
                        width: "43.14",
                        height: "10.54",
                        left: "296.97",
                        top: "392.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "343.11",
                        top: "392.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "358.11",
                        top: "392.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"Seller',
                        width: "32.89",
                        height: "10.54",
                        left: "383.1",
                        top: "392.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: 'Dashboard"',
                        width: "56.89",
                        height: "10.54",
                        left: "418.99",
                        top: "392.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "page.",
                        width: "25.65",
                        height: "10.54",
                        left: "478.88",
                        top: "392.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "6.",
                        width: "9",
                        height: "10.54",
                        left: "90",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Free",
                        width: "22.21",
                        height: "10.54",
                        left: "108",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Trials",
                        width: "28.02",
                        height: "10.54",
                        left: "133.96",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "18",
                        height: "10.54",
                        left: "165.73",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Promotions",
                        width: "55.56",
                        height: "10.54",
                        left: "187.48",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "243.04",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "249.79",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "269.23",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offer",
                        width: "23.09",
                        height: "10.54",
                        left: "293.64",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "320.48",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "347.56",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "372.64",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time,",
                        width: "24.33",
                        height: "10.54",
                        left: "385.72",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "413.8",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "426.13",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "445.13",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion,",
                        width: "50.32",
                        height: "10.54",
                        left: "467.46",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "free",
                        width: "18.65",
                        height: "10.54",
                        left: "520.78",
                        top: "366.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "trials",
                        width: "23.99",
                        height: "10.54",
                        left: "108",
                        top: "352.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "135.74",
                        top: "352.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscriptions",
                        width: "65.34",
                        height: "10.54",
                        left: "151.49",
                        top: "352.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "220.58",
                        top: "352.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "241.66",
                        top: "352.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promotions",
                        width: "54.67",
                        height: "10.54",
                        left: "270.06",
                        top: "352.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "328.48",
                        top: "352.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "affect",
                        width: "27.08",
                        height: "10.54",
                        left: "350.23",
                        top: "352.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pricing",
                        width: "33.99",
                        height: "10.54",
                        left: "381.06",
                        top: "352.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "418.8",
                        top: "352.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "439.88",
                        top: "352.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.64",
                        height: "10.54",
                        left: "461.63",
                        top: "352.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "governed",
                        width: "44.65",
                        height: "10.54",
                        left: "480.02",
                        top: "352.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "527.67",
                        top: "352.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "108",
                        top: "339.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "139.16",
                        top: "339.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "conditions",
                        width: "49.99",
                        height: "10.54",
                        left: "160.99",
                        top: "339.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "separate",
                        width: "39.31",
                        height: "10.54",
                        left: "215.48",
                        top: "339.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "259.29",
                        top: "339.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "286.37",
                        top: "339.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "314.77",
                        top: "339.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "348.34",
                        top: "339.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use.",
                        width: "21.66",
                        height: "10.54",
                        left: "362.09",
                        top: "339.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "All",
                        width: "15.33",
                        height: "10.54",
                        left: "391.25",
                        top: "339.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "410.33",
                        top: "339.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "trials",
                        width: "23.99",
                        height: "10.54",
                        left: "436.08",
                        top: "339.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "463.82",
                        top: "339.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promotions",
                        width: "54.67",
                        height: "10.54",
                        left: "484.9",
                        top: "339.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "108",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "subject",
                        width: "33.99",
                        height: "10.54",
                        left: "126.4",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "164.14",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "177.22",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "205.63",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "239.2",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.67",
                        height: "10.54",
                        left: "252.94",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "except",
                        width: "31.31",
                        height: "10.54",
                        left: "275.36",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "310.42",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.66",
                        height: "10.54",
                        left: "324.16",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "stated",
                        width: "27.99",
                        height: "10.54",
                        left: "374.57",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "406.31",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "419.39",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "additional",
                        width: "47.99",
                        height: "10.54",
                        left: "440.47",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "492.21",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "522.62",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "conditions",
                        width: "50",
                        height: "10.54",
                        left: "108",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "162.5",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accompany",
                        width: "54.64",
                        height: "10.54",
                        left: "184.99",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "244.13",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promotion.",
                        width: "53",
                        height: "10.54",
                        left: "263.29",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "320.79",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "there",
                        width: "23.98",
                        height: "10.54",
                        left: "333.28",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "361.76",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "374.27",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "conflict",
                        width: "36.65",
                        height: "10.54",
                        left: "383.34",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "between",
                        width: "39.98",
                        height: "10.54",
                        left: "423.74",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "467.47",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "485.88",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.54",
                        left: "516.29",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.3200000000001",
                        height: "10.54",
                        left: "534.04",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "promotion",
                        width: "50",
                        height: "10.54",
                        left: "108",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "162.5",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "184.32",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "213.48",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "247.8",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use,",
                        width: "21.67",
                        height: "10.54",
                        left: "262.29",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "288.46",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promotion",
                        width: "50",
                        height: "10.54",
                        left: "307.62",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "362.12",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "393.28",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "govern.",
                        width: "36.33",
                        height: "10.54",
                        left: "416.44",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Unless",
                        width: "32.66",
                        height: "10.54",
                        left: "456.52",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "492.93",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "stated",
                        width: "27.99",
                        height: "10.54",
                        left: "108",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "139.74",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "155.49",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "169.91",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respect",
                        width: "33.98",
                        height: "10.54",
                        left: "182.99",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "220.72",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "specific",
                        width: "37.31",
                        height: "10.54",
                        left: "234.47",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promotions,",
                        width: "57.67",
                        height: "10.54",
                        left: "275.53",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "free",
                        width: "18.64",
                        height: "10.54",
                        left: "336.95",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "trials",
                        width: "24",
                        height: "10.54",
                        left: "359.34",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "387.09",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.3",
                        height: "10.54",
                        left: "405.49",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "452.54",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "new",
                        width: "19.99",
                        height: "10.54",
                        left: "465.63",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.66",
                        height: "10.54",
                        left: "488.62",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "only.",
                        width: "23.56",
                        height: "10.54",
                        left: "516.28",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "108",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reserve",
                        width: "34.64",
                        height: "10.54",
                        left: "127.44",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "165.83",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.67",
                        height: "10.54",
                        left: "184.24",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "210.66",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "determine",
                        width: "47.98",
                        height: "10.54",
                        left: "222.99",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "273.97",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "eligibility",
                        width: "46.67",
                        height: "10.54",
                        left: "298.96",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "348.63",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "365.62",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "free",
                        width: "18.64",
                        height: "10.54",
                        left: "373.95",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "trial",
                        width: "19.32",
                        height: "10.54",
                        left: "395.59",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "417.91",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "430.91",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promotion",
                        width: "49.99",
                        height: "10.54",
                        left: "458.57",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "511.56",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "523.9",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "108",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion",
                        width: "47.32",
                        height: "10.54",
                        left: "134.83",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "189.65",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "214.48",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "235.97",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limit",
                        width: "22.67",
                        height: "10.54",
                        left: "264.13",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "eligibility",
                        width: "46.66",
                        height: "10.54",
                        left: "294.3",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "348.46",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "duration",
                        width: "39.99",
                        height: "10.54",
                        left: "365.96",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "413.45",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prevent",
                        width: "35.99",
                        height: "10.54",
                        left: "430.28",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "abuse",
                        width: "27.32",
                        height: "10.54",
                        left: "473.02",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.9900000000001",
                        height: "10.54",
                        left: "507.09",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "523.83",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "promotions.",
                        width: "57.67",
                        height: "10.54",
                        left: "108",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "170.17",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "190.36",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "revoke",
                        width: "32.65",
                        height: "10.54",
                        left: "215.52",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "251.92",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promotion",
                        width: "50",
                        height: "10.54",
                        left: "260.99",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "314.74",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "put",
                        width: "15.33",
                        height: "10.54",
                        left: "335.82",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "354.9",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "380.65",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "421.71",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "hold",
                        width: "21.33",
                        height: "10.54",
                        left: "437.46",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "462.54",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "473.63",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "determine",
                        width: "47.97",
                        height: "10.54",
                        left: "491.37",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "108",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "129.74",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "151.49",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "169.89",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "eligible.",
                        width: "38.98",
                        height: "10.54",
                        left: "188.98",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Members",
                        width: "45.33",
                        height: "10.54",
                        left: "231.71",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "280.79",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "households",
                        width: "54",
                        height: "10.54",
                        left: "294.53",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "352.28",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.32",
                        height: "10.54",
                        left: "377.37",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "existing",
                        width: "38",
                        height: "10.54",
                        left: "392.44",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "433.44",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "recent",
                        width: "29.31",
                        height: "10.54",
                        left: "446.44",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.66",
                        height: "10.54",
                        left: "478.75",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "108",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "126.4",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "eligible.",
                        width: "38.99",
                        height: "10.54",
                        left: "145.48",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Any",
                        width: "20.67",
                        height: "10.54",
                        left: "191.97",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "attempts",
                        width: "40.65",
                        height: "10.54",
                        left: "216.39",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "260.79",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "register",
                        width: "35.98",
                        height: "10.54",
                        left: "273.88",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "313.61",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "331.35",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "further",
                        width: "32.65",
                        height: "10.54",
                        left: "339.68",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "free",
                        width: "18.64",
                        height: "10.54",
                        left: "375.33",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "trial",
                        width: "19.33",
                        height: "10.54",
                        left: "396.97",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "419.3",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "434.3",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "same",
                        width: "24.65",
                        height: "10.54",
                        left: "451.96",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "20",
                        height: "10.54",
                        left: "479.61",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "502.61",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "108",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "using",
                        width: "26",
                        height: "10.54",
                        left: "124",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "156",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "same",
                        width: "24.66",
                        height: "10.54",
                        left: "176.66",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.65",
                        height: "10.54",
                        left: "207.32",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address",
                        width: "35.99",
                        height: "10.54",
                        left: "239.22",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "280.46",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "result",
                        width: "26.66",
                        height: "10.54",
                        left: "306.37",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "338.28",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "352.86",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "being",
                        width: "26.66",
                        height: "10.54",
                        left: "376.11",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "charged",
                        width: "37.76",
                        height: "10.54",
                        left: "408.02",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "451.03",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "470.27",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applicable",
                        width: "49.31",
                        height: "10.54",
                        left: "490.18",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "subscription",
                        width: "58.66",
                        height: "10.54",
                        left: "108",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees.",
                        width: "22.32",
                        height: "10.54",
                        left: "171.16",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "197.98",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "217.42",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "241.83",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "261.58",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "321.98",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "347.73",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "device",
                        width: "31.31",
                        height: "10.54",
                        left: "361.48",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ID,",
                        width: "15.66",
                        height: "10.54",
                        left: "396.54",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "method",
                        width: "36",
                        height: "10.54",
                        left: "415.95",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "455.7",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "469.44",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "514.51",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.32",
                        height: "10.54",
                        left: "528.26",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "108",
                        top: "173.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.66",
                        height: "10.54",
                        left: "152.81",
                        top: "173.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address",
                        width: "35.99",
                        height: "10.54",
                        left: "186.97",
                        top: "173.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "used",
                        width: "21.99",
                        height: "10.54",
                        left: "230.46",
                        top: "173.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "259.95",
                        top: "173.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.32",
                        height: "10.54",
                        left: "288.04",
                        top: "173.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "existing",
                        width: "38",
                        height: "10.54",
                        left: "306.11",
                        top: "173.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "350.86",
                        top: "173.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "recent",
                        width: "29.3",
                        height: "10.54",
                        left: "367.61",
                        top: "173.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscriptions",
                        width: "65.34",
                        height: "10.54",
                        left: "403.66",
                        top: "173.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "475.75",
                        top: "173.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "determine",
                        width: "47.97",
                        height: "10.54",
                        left: "491.84",
                        top: "173.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "eligibility.",
                        width: "48.88",
                        height: "10.55",
                        left: "108",
                        top: "159.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "At",
                        width: "12",
                        height: "10.55",
                        left: "160.63",
                        top: "159.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "176.38",
                        top: "159.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "end",
                        width: "17.33",
                        height: "10.55",
                        left: "194.79",
                        top: "159.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "215.12",
                        top: "159.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.55",
                        left: "228.12",
                        top: "159.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promotion,",
                        width: "53",
                        height: "10.55",
                        left: "253.11",
                        top: "159.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "309.11",
                        top: "159.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.55",
                        left: "334.11",
                        top: "159.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.55",
                        left: "397.78",
                        top: "159.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "automatically",
                        width: "65.31",
                        height: "10.55",
                        left: "419.44",
                        top: "159.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "renew,",
                        width: "31.54",
                        height: "10.55",
                        left: "487.75",
                        top: "159.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.55",
                        left: "522.29",
                        top: "159.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "108",
                        top: "145.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "131.25",
                        top: "145.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "155.17",
                        top: "145.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "billed",
                        width: "27.33",
                        height: "10.54",
                        left: "171.74",
                        top: "145.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "204.32",
                        top: "145.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "218.23",
                        top: "145.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "then­current,",
                        width: "61.64",
                        height: "10.54",
                        left: "238.14",
                        top: "145.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "non­promotional",
                        width: "80.65",
                        height: "10.54",
                        left: "305.03",
                        top: "145.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "price,",
                        width: "26.98",
                        height: "10.54",
                        left: "390.93",
                        top: "145.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unless",
                        width: "30",
                        height: "10.54",
                        left: "423.16",
                        top: "145.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "458.41",
                        top: "145.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cancel",
                        width: "30.64",
                        height: "10.54",
                        left: "481.66",
                        top: "145.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "517.55",
                        top: "145.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "108",
                        top: "132.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prior",
                        width: "23.32",
                        height: "10.54",
                        left: "172.42",
                        top: "132.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "199.49",
                        top: "132.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "212.58",
                        top: "132.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "end",
                        width: "17.32",
                        height: "10.54",
                        left: "230.99",
                        top: "132.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "252.06",
                        top: "132.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "265.81",
                        top: "132.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promotion.",
                        width: "52.99",
                        height: "10.54",
                        left: "291.56",
                        top: "132.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.5",
                        height: "10.54",
                        left: "348.3",
                        top: "132.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "view",
                        width: "23.32",
                        height: "10.54",
                        left: "364.55",
                        top: "132.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "391.62",
                        top: "132.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applicable",
                        width: "49.31",
                        height: "10.54",
                        left: "409.28",
                        top: "132.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "461.59",
                        top: "132.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fee",
                        width: "14.65",
                        height: "10.54",
                        left: "525.26",
                        top: "132.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "108",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "end",
                        width: "17.32",
                        height: "10.54",
                        left: "128.33",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "date",
                        width: "19.99",
                        height: "10.54",
                        left: "148.65",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "171.64",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "184.63",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promotion,",
                        width: "53",
                        height: "10.54",
                        left: "209.63",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "visit",
                        width: "20.67",
                        height: "10.54",
                        left: "265.63",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "289.3",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "website",
                        width: "36.65",
                        height: "10.54",
                        left: "308.3",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "347.95",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "click",
                        width: "23.32",
                        height: "10.54",
                        left: "368.28",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "394.6",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "409.6",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"',
                        width: "4.89",
                        height: "10.54",
                        left: "434.6",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Pricing",
                        width: "37.33",
                        height: "10.54",
                        left: "439.49",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"',
                        width: "4.9",
                        height: "10.54",
                        left: "476.82",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "page.",
                        width: "25.65",
                        height: "10.54",
                        left: "484.72",
                        top: "118.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "7.",
                        width: "9",
                        height: "10.54",
                        left: "90",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Changes",
                        width: "42",
                        height: "10.54",
                        left: "108",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "156",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "171.33",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "191.99",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Fees",
                        width: "22.65",
                        height: "10.54",
                        left: "258.66",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "18",
                        height: "10.54",
                        left: "286.56",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "309.81",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "375.73",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "413.73",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "421.98",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reserve",
                        width: "34.64",
                        height: "10.54",
                        left: "442.92",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "482.81",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.67",
                        height: "10.54",
                        left: "502.72",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "530.64",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "change",
                        width: "33.98",
                        height: "10.54",
                        left: "108",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "145.73",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "164.14",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "194.55",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "208.29",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription,",
                        width: "63.67",
                        height: "10.54",
                        left: "234.04",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "300.71",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "349.04",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "374.03",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees",
                        width: "19.32",
                        height: "10.54",
                        left: "437.7",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "460.02",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "471.68",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time.",
                        width: "24.32",
                        height: "10.54",
                        left: "492.01",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Any",
                        width: "20.67",
                        height: "10.54",
                        left: "519.33",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,720",
                    "lineto,540,720",
                    "lineto,540,705",
                    "lineto,90,705",
                    "lineto,90,720",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,706",
                    "lineto,540,706",
                    "lineto,540,691",
                    "lineto,90,691",
                    "lineto,90,706",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,692",
                    "lineto,540,692",
                    "lineto,540,677",
                    "lineto,90,677",
                    "lineto,90,692",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,679",
                    "lineto,540,679",
                    "lineto,540,664",
                    "lineto,90,664",
                    "lineto,90,679",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,665",
                    "lineto,540,665",
                    "lineto,540,650",
                    "lineto,90,650",
                    "lineto,90,665",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,651",
                    "lineto,540,651",
                    "lineto,540,636",
                    "lineto,90,636",
                    "lineto,90,651",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,637",
                    "lineto,540,637",
                    "lineto,540,622",
                    "lineto,90,622",
                    "lineto,90,637",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,623",
                    "lineto,540,623",
                    "lineto,540,608",
                    "lineto,90,608",
                    "lineto,90,623",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,610",
                    "lineto,540,610",
                    "lineto,540,595",
                    "lineto,90,595",
                    "lineto,90,610",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,596",
                    "lineto,540,596",
                    "lineto,540,569",
                    "lineto,90,569",
                    "lineto,90,596",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,570",
                    "lineto,540,570",
                    "lineto,540,555",
                    "lineto,90,555",
                    "lineto,90,570",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,556",
                    "lineto,540,556",
                    "lineto,540,541",
                    "lineto,90,541",
                    "lineto,90,556",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,542",
                    "lineto,540,542",
                    "lineto,540,527",
                    "lineto,90,527",
                    "lineto,90,542",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,529",
                    "lineto,540,529",
                    "lineto,540,514",
                    "lineto,90,514",
                    "lineto,90,529",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,515",
                    "lineto,540,515",
                    "lineto,540,500",
                    "lineto,90,500",
                    "lineto,90,515",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,501",
                    "lineto,540,501",
                    "lineto,540,474",
                    "lineto,90,474",
                    "lineto,90,501",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,475",
                    "lineto,540,475",
                    "lineto,540,460",
                    "lineto,90,460",
                    "lineto,90,475",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,461",
                    "lineto,540,461",
                    "lineto,540,446",
                    "lineto,90,446",
                    "lineto,90,461",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,448",
                    "lineto,540,448",
                    "lineto,540,433",
                    "lineto,90,433",
                    "lineto,90,448",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,434",
                    "lineto,540,434",
                    "lineto,540,419",
                    "lineto,90,419",
                    "lineto,90,434",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,420",
                    "lineto,540,420",
                    "lineto,540,405",
                    "lineto,90,405",
                    "lineto,90,420",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,406",
                    "lineto,540,406",
                    "lineto,540,379",
                    "lineto,90,379",
                    "lineto,90,406",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,380",
                    "lineto,540,380",
                    "lineto,540,365",
                    "lineto,90,365",
                    "lineto,90,380",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,367",
                    "lineto,540,367",
                    "lineto,540,352",
                    "lineto,90,352",
                    "lineto,90,367",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,353",
                    "lineto,540,353",
                    "lineto,540,338",
                    "lineto,90,338",
                    "lineto,90,353",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,339",
                    "lineto,540,339",
                    "lineto,540,324",
                    "lineto,90,324",
                    "lineto,90,339",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,325",
                    "lineto,540,325",
                    "lineto,540,310",
                    "lineto,90,310",
                    "lineto,90,325",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,311",
                    "lineto,540,311",
                    "lineto,540,296",
                    "lineto,90,296",
                    "lineto,90,311",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,298",
                    "lineto,540,298",
                    "lineto,540,283",
                    "lineto,90,283",
                    "lineto,90,298",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,284",
                    "lineto,540,284",
                    "lineto,540,269",
                    "lineto,90,269",
                    "lineto,90,284",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,270",
                    "lineto,540,270",
                    "lineto,540,255",
                    "lineto,90,255",
                    "lineto,90,270",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,256",
                    "lineto,540,256",
                    "lineto,540,241",
                    "lineto,90,241",
                    "lineto,90,256",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,242",
                    "lineto,540,242",
                    "lineto,540,227",
                    "lineto,90,227",
                    "lineto,90,242",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,229",
                    "lineto,540,229",
                    "lineto,540,214",
                    "lineto,90,214",
                    "lineto,90,229",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,215",
                    "lineto,540,215",
                    "lineto,540,200",
                    "lineto,90,200",
                    "lineto,90,215",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,201",
                    "lineto,540,201",
                    "lineto,540,186",
                    "lineto,90,186",
                    "lineto,90,201",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,187",
                    "lineto,540,187",
                    "lineto,540,172",
                    "lineto,90,172",
                    "lineto,90,187",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,173",
                    "lineto,540,173",
                    "lineto,540,158",
                    "lineto,90,158",
                    "lineto,90,173",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,160",
                    "lineto,540,160",
                    "lineto,540,145",
                    "lineto,90,145",
                    "lineto,90,160",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,146",
                    "lineto,540,146",
                    "lineto,540,131",
                    "lineto,90,131",
                    "lineto,90,146",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,132",
                    "lineto,540,132",
                    "lineto,540,105",
                    "lineto,90,105",
                    "lineto,90,132",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,106",
                    "lineto,540,106",
                    "lineto,540,91",
                    "lineto,90,91",
                    "lineto,90,106",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,92",
                    "lineto,540,92",
                    "lineto,540,77",
                    "lineto,90,77",
                    "lineto,90,92",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,108,557.5", "lineto,420,557.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,508,502.5", "lineto,539,502.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,108,488.5", "lineto,150,488.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,108,462.5", "lineto,170,462.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,108,367.5", "lineto,243,367.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,108,93.5", "lineto,414,93.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "10",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "changes",
                        width: "38.65",
                        height: "10.54",
                        left: "108",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "151.15",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "164.98",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "184.14",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "215.3",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "229.8",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "256.29",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "321.46",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "take",
                        width: "19.99",
                        height: "10.54",
                        left: "344.63",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "effect",
                        width: "27.09",
                        height: "10.54",
                        left: "369.12",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "following",
                        width: "46.66",
                        height: "10.54",
                        left: "400.71",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notice",
                        width: "29.32",
                        height: "10.54",
                        left: "451.87",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "485.69",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you.",
                        width: "21",
                        height: "10.54",
                        left: "499.52",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "524.27",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "reserve",
                        width: "34.64",
                        height: "10.54",
                        left: "108",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "151.64",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.66",
                        height: "10.54",
                        left: "174.55",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "205.46",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "add,",
                        width: "20.33",
                        height: "10.54",
                        left: "223.05",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "remove,",
                        width: "38.98",
                        height: "10.54",
                        left: "251.63",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "298.86",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "modify",
                        width: "34.67",
                        height: "10.54",
                        left: "317.1",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "360.02",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "382.93",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.3",
                        height: "10.54",
                        left: "426.5",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "478.05",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "523.63",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Subscriptions",
                        width: "65.34",
                        height: "10.54",
                        left: "108",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "177.09",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "189.5",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "210.57",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.67",
                        height: "10.54",
                        left: "234.9",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notice",
                        width: "29.32",
                        height: "10.54",
                        left: "274.57",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "306.89",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.98",
                        height: "10.54",
                        left: "327.22",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "356.2",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "guarantee",
                        width: "46.64",
                        height: "10.54",
                        left: "371.2",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "420.84",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "433.83",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "446.17",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "type",
                        width: "20.66",
                        height: "10.54",
                        left: "463.83",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "487.49",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "quantity",
                        width: "39.33",
                        height: "10.54",
                        left: "500.48",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "108",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.55",
                        left: "124.75",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.3",
                        height: "10.55",
                        left: "166.82",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.55",
                        left: "216.87",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.55",
                        left: "260.95",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscriptions.",
                        width: "68.34",
                        height: "10.55",
                        left: "283.7",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.55",
                        left: "358.79",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reserve",
                        width: "34.64",
                        height: "10.55",
                        left: "381.23",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "422.62",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.66",
                        height: "10.55",
                        left: "444.03",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.55",
                        left: "473.44",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "change",
                        width: "33.98",
                        height: "10.55",
                        left: "489.53",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.55",
                        left: "529.51",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "terminate",
                        width: "45.31",
                        height: "10.54",
                        left: "108",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "156.31",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offered",
                        width: "34.42",
                        height: "10.54",
                        left: "176.64",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscriptions",
                        width: "65.34",
                        height: "10.54",
                        left: "214.06",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "282.4",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "294.06",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time.",
                        width: "24.33",
                        height: "10.54",
                        left: "314.38",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "8.",
                        width: "9",
                        height: "10.54",
                        left: "90",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "No",
                        width: "14",
                        height: "10.54",
                        left: "108",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Refunds",
                        width: "38.66",
                        height: "10.54",
                        left: "127.25",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "165.91",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Unless",
                        width: "32.67",
                        height: "10.54",
                        left: "174.16",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "212.08",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "stated",
                        width: "27.99",
                        height: "10.54",
                        left: "263.98",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "297.22",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "314.47",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "330.39",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "344.98",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "364.89",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "396.8",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "412.04",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promotion",
                        width: "50",
                        height: "10.54",
                        left: "434.62",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offered",
                        width: "34.42",
                        height: "10.54",
                        left: "489.87",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.54",
                        top: "625.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "108",
                        top: "611.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "determined",
                        width: "53.98",
                        height: "10.54",
                        left: "159.15",
                        top: "611.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "217.63",
                        top: "611.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "234.13",
                        top: "611.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "248.55",
                        top: "611.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "261.63",
                        top: "611.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "281.38",
                        top: "611.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion,",
                        width: "50.32",
                        height: "10.54",
                        left: "304.46",
                        top: "611.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "358.53",
                        top: "611.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sales",
                        width: "23.33",
                        height: "10.54",
                        left: "374.27",
                        top: "611.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "401.35",
                        top: "611.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscriptions",
                        width: "65.33",
                        height: "10.54",
                        left: "415.1",
                        top: "611.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "484.18",
                        top: "611.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.26",
                        top: "611.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "108",
                        top: "597.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.64",
                        height: "10.54",
                        left: "150.86",
                        top: "597.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "final,",
                        width: "24.99",
                        height: "10.54",
                        left: "170",
                        top: "597.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "199.49",
                        top: "597.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "221.32",
                        top: "597.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees",
                        width: "19.32",
                        height: "10.54",
                        left: "286.49",
                        top: "597.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "310.31",
                        top: "597.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "nonrefundable",
                        width: "69.3",
                        height: "10.54",
                        left: "329.46",
                        top: "597.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "403.26",
                        top: "597.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "there",
                        width: "23.98",
                        height: "10.54",
                        left: "425.09",
                        top: "597.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "453.57",
                        top: "597.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "472.72",
                        top: "597.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "refunds",
                        width: "35.99",
                        height: "10.54",
                        left: "489.22",
                        top: "597.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "529.71",
                        top: "597.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "credits",
                        width: "31.99",
                        height: "10.54",
                        left: "108",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "145.24",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "partially",
                        width: "39.98",
                        height: "10.54",
                        left: "164.48",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "used",
                        width: "22",
                        height: "10.54",
                        left: "209.71",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "periods.",
                        width: "38.33",
                        height: "10.54",
                        left: "236.96",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "At",
                        width: "12",
                        height: "10.54",
                        left: "280.54",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "297.79",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time,",
                        width: "24.33",
                        height: "10.54",
                        left: "319.61",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "348.44",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "370.27",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "388.76",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reason,",
                        width: "34.32",
                        height: "10.54",
                        left: "410.58",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "449.4",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "467.89",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "493.05",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.3299999999999",
                        height: "10.54",
                        left: "534.21",
                        top: "583.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "refund,",
                        width: "34.32",
                        height: "10.54",
                        left: "108",
                        top: "570.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discount,",
                        width: "43.66",
                        height: "10.54",
                        left: "148.32",
                        top: "570.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "197.98",
                        top: "570.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "213.98",
                        top: "570.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "consideration",
                        width: "64.65",
                        height: "10.54",
                        left: "244.63",
                        top: "570.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "314.53",
                        top: "570.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "some",
                        width: "25.33",
                        height: "10.54",
                        left: "329.12",
                        top: "570.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "359.7",
                        top: "570.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "374.94",
                        top: "570.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "392.19",
                        top: "570.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "members",
                        width: "43.99",
                        height: "10.54",
                        left: "413.43",
                        top: "570.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '("credits").',
                        width: "52.77",
                        height: "10.54",
                        left: "462.67",
                        top: "570.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "520.69",
                        top: "570.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "amount",
                        width: "35.99",
                        height: "10.54",
                        left: "108",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "148.49",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "form",
                        width: "23.33",
                        height: "10.54",
                        left: "170.32",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "198.15",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "212.64",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "credits,",
                        width: "34.98",
                        height: "10.54",
                        left: "239.14",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "278.63",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "300.45",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "decision",
                        width: "39.99",
                        height: "10.54",
                        left: "319.61",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "364.1",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.65",
                        height: "10.54",
                        left: "377.19",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "them,",
                        width: "27",
                        height: "10.54",
                        left: "417.59",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.64",
                        height: "10.54",
                        left: "448.34",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "466.73",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "479.14",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "498.89",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "521.97",
                        top: "556.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "absolute",
                        width: "39.99",
                        height: "10.55",
                        left: "108",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion.",
                        width: "50.32",
                        height: "10.55",
                        left: "151.74",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.55",
                        left: "205.06",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provision",
                        width: "45.33",
                        height: "10.55",
                        left: "226.72",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "275.05",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "credits",
                        width: "31.98",
                        height: "10.55",
                        left: "288.05",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.55",
                        left: "323.03",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "one",
                        width: "17.32",
                        height: "10.55",
                        left: "335.37",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "instance",
                        width: "39.32",
                        height: "10.55",
                        left: "355.69",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "does",
                        width: "22",
                        height: "10.55",
                        left: "398.01",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.55",
                        left: "423.01",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "entitle",
                        width: "29.99",
                        height: "10.55",
                        left: "441.34",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "474.33",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "495.33",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "credits",
                        width: "31.99",
                        height: "10.55",
                        left: "507.66",
                        top: "542.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "108",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "121.08",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "future",
                        width: "28.66",
                        height: "10.54",
                        left: "139.49",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "171.9",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "similar",
                        width: "33.33",
                        height: "10.54",
                        left: "189.64",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "instances,",
                        width: "46.98",
                        height: "10.54",
                        left: "226.72",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "nor",
                        width: "16",
                        height: "10.54",
                        left: "277.45",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "does",
                        width: "22",
                        height: "10.54",
                        left: "297.2",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "6.66",
                        height: "10.54",
                        left: "322.95",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "obligate",
                        width: "38.66",
                        height: "10.54",
                        left: "333.36",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "375.77",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "390.19",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "403.27",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "credits",
                        width: "31.98",
                        height: "10.54",
                        left: "442.93",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "477.91",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "490.25",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "future,",
                        width: "31.65",
                        height: "10.54",
                        left: "507.91",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "108",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "138.32",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "circumstance.",
                        width: "66.3",
                        height: "10.54",
                        left: "158.65",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Marketplace",
                        width: "65.31",
                        height: "10.54",
                        left: "72",
                        top: "487.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payments",
                        width: "49.99",
                        height: "10.54",
                        left: "140.31",
                        top: "487.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "458.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "95.16",
                        top: "458.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "138.01",
                        top: "458.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "facilitate",
                        width: "41.97",
                        height: "10.54",
                        left: "163.17",
                        top: "458.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payments",
                        width: "45.99",
                        height: "10.54",
                        left: "209.64",
                        top: "458.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "between",
                        width: "39.98",
                        height: "10.54",
                        left: "260.13",
                        top: "458.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.66",
                        height: "10.54",
                        left: "304.61",
                        top: "458.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "333.77",
                        top: "458.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "347.52",
                        top: "458.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "365.93",
                        top: "458.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.66",
                        height: "10.54",
                        left: "408.04",
                        top: "458.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enter",
                        width: "23.98",
                        height: "10.54",
                        left: "432.45",
                        top: "458.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "into",
                        width: "18.67",
                        height: "10.54",
                        left: "460.18",
                        top: "458.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transactions",
                        width: "57.32",
                        height: "10.54",
                        left: "482.6",
                        top: "458.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "72",
                        top: "444.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "116.08",
                        top: "444.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "137.49",
                        top: "444.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(“",
                        width: "9.32",
                        height: "10.54",
                        left: "182.6",
                        top: "444.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "65.31",
                        height: "10.54",
                        left: "191.92",
                        top: "444.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payments",
                        width: "49.99",
                        height: "10.54",
                        left: "263.98",
                        top: "444.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”)",
                        width: "9.32",
                        height: "10.54",
                        left: "313.97",
                        top: "444.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "330.04",
                        top: "444.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "374.12",
                        top: "444.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "396.87",
                        top: "444.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "processing",
                        width: "51.32",
                        height: "10.54",
                        left: "444.94",
                        top: "444.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "partner,",
                        width: "36.5",
                        height: "10.54",
                        left: "503.01",
                        top: "444.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Stripe;",
                        width: "32",
                        height: "10.54",
                        left: "72",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "however,",
                        width: "43.83",
                        height: "10.54",
                        left: "107.75",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "155.33",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "173.07",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "191.47",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "210.56",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "party",
                        width: "24.66",
                        height: "10.54",
                        left: "219.63",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "248.04",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "261.12",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "282.2",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.96",
                        height: "10.54",
                        left: "307.95",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payments",
                        width: "46.66",
                        height: "10.54",
                        left: "371.66",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "422.07",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "435.82",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sales",
                        width: "23.32",
                        height: "10.54",
                        left: "456.15",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transactions",
                        width: "57.32",
                        height: "10.54",
                        left: "482.47",
                        top: "430.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "related",
                        width: "32.64",
                        height: "10.54",
                        left: "72",
                        top: "416.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "thereto.",
                        width: "36.32",
                        height: "10.54",
                        left: "109.14",
                        top: "416.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.96",
                        height: "10.54",
                        left: "149.96",
                        top: "416.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payments",
                        width: "46.67",
                        height: "10.54",
                        left: "214.42",
                        top: "416.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "265.59",
                        top: "416.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "290.75",
                        top: "416.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made",
                        width: "25.99",
                        height: "10.54",
                        left: "306.57",
                        top: "416.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "337.06",
                        top: "416.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals",
                        width: "37.33",
                        height: "10.54",
                        left: "355.55",
                        top: "416.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "397.38",
                        top: "416.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "411.87",
                        top: "416.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "items",
                        width: "26",
                        height: "10.54",
                        left: "440.28",
                        top: "416.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchased",
                        width: "48.64",
                        height: "10.54",
                        left: "470.03",
                        top: "416.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "522.42",
                        top: "416.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "sold",
                        width: "20",
                        height: "10.54",
                        left: "72",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "between",
                        width: "39.98",
                        height: "10.54",
                        left: "95.75",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.66",
                        height: "10.54",
                        left: "139.48",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "only.",
                        width: "23.56",
                        height: "10.54",
                        left: "167.14",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "By",
                        width: "14",
                        height: "10.54",
                        left: "193.7",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agreeing",
                        width: "41.31",
                        height: "10.54",
                        left: "210.7",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "255.01",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "267.35",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "295",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "324.66",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "continuing",
                        width: "51.33",
                        height: "10.54",
                        left: "337.66",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "391.99",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "operate",
                        width: "35.31",
                        height: "10.54",
                        left: "404.32",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "442.63",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "455.63",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Seller",
                        width: "27.99",
                        height: "10.54",
                        left: "463.95",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "494.94",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "509.94",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "527.6",
                        top: "403.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "72",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.55",
                        left: "91.16",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "137.02",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "hereby",
                        width: "32.64",
                        height: "10.55",
                        left: "159.52",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "appoint",
                        width: "36",
                        height: "10.55",
                        left: "196.66",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.55",
                        left: "237.16",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "35.99",
                        height: "10.55",
                        left: "273.65",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.55",
                        left: "314.14",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "act",
                        width: "13.98",
                        height: "10.55",
                        left: "327.23",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.55",
                        left: "344.96",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "358.71",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agent",
                        width: "25.98",
                        height: "10.55",
                        left: "384.46",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.55",
                        left: "414.19",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "431.94",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limited",
                        width: "33.99",
                        height: "10.55",
                        left: "450.35",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purpose",
                        width: "37.99",
                        height: "10.55",
                        left: "488.09",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "529.83",
                        top: "389.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "facilitating",
                        width: "51.98",
                        height: "10.54",
                        left: "72",
                        top: "375.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "128.48",
                        top: "375.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "receiving",
                        width: "44.65",
                        height: "10.54",
                        left: "150.3",
                        top: "375.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.96",
                        height: "10.54",
                        left: "198.7",
                        top: "375.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payments",
                        width: "46.67",
                        height: "10.54",
                        left: "262.41",
                        top: "375.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "312.83",
                        top: "375.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "328.58",
                        top: "375.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "behalf",
                        width: "29.98",
                        height: "10.54",
                        left: "354.32",
                        top: "375.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "388.05",
                        top: "375.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stripe",
                        width: "28.67",
                        height: "10.54",
                        left: "429.13",
                        top: "375.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "461.55",
                        top: "375.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contemplated",
                        width: "64.65",
                        height: "10.54",
                        left: "475.29",
                        top: "375.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "herein.",
                        width: "32.98",
                        height: "10.54",
                        left: "72",
                        top: "361.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "113.23",
                        top: "361.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "acknowledge",
                        width: "63.3",
                        height: "10.54",
                        left: "140.95",
                        top: "361.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "212.5",
                        top: "361.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "238.08",
                        top: "361.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that,",
                        width: "21",
                        height: "10.54",
                        left: "272.3",
                        top: "361.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "subject",
                        width: "33.99",
                        height: "10.54",
                        left: "300.8",
                        top: "361.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "342.29",
                        top: "361.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "359.12",
                        top: "361.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights",
                        width: "27.33",
                        height: "10.54",
                        left: "383.95",
                        top: "361.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "418.78",
                        top: "361.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "444.28",
                        top: "361.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pertaining",
                        width: "48.65",
                        height: "10.54",
                        left: "474.43",
                        top: "361.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.3399999999999",
                        height: "10.54",
                        left: "530.58",
                        top: "361.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Marketplace",
                        width: "59.96",
                        height: "10.54",
                        left: "72",
                        top: "347.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "42",
                        height: "10.54",
                        left: "139.46",
                        top: "347.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disputes,",
                        width: "42.33",
                        height: "10.54",
                        left: "188.96",
                        top: "347.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "once",
                        width: "22.66",
                        height: "10.54",
                        left: "238.79",
                        top: "347.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "268.2",
                        top: "347.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.97",
                        height: "10.54",
                        left: "280.27",
                        top: "347.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "41.99",
                        height: "10.54",
                        left: "346.99",
                        top: "347.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "has",
                        width: "16",
                        height: "10.54",
                        left: "395.73",
                        top: "347.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "been",
                        width: "22.65",
                        height: "10.54",
                        left: "418.48",
                        top: "347.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made",
                        width: "25.99",
                        height: "10.54",
                        left: "447.88",
                        top: "347.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "480.62",
                        top: "347.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.7",
                        top: "347.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "72",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "117.86",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "137.02",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "obligation",
                        width: "48.66",
                        height: "10.54",
                        left: "182.84",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "236",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "250.49",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payor",
                        width: "27.33",
                        height: "10.54",
                        left: "269.65",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "19.99",
                        height: "10.54",
                        left: "301.48",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shall",
                        width: "22.66",
                        height: "10.54",
                        left: "325.97",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "353.13",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "deemed",
                        width: "37.31",
                        height: "10.54",
                        left: "368.96",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "satisfied",
                        width: "39.99",
                        height: "10.54",
                        left: "410.02",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "453.76",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "469.51",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payee",
                        width: "27.98",
                        height: "10.54",
                        left: "487.92",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "19.99",
                        height: "10.54",
                        left: "519.65",
                        top: "334.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "upon",
                        width: "24",
                        height: "10.54",
                        left: "72",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "receipt",
                        width: "32.64",
                        height: "10.54",
                        left: "102.75",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "142.14",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "158.89",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.96",
                        height: "10.54",
                        left: "180.3",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "42",
                        height: "10.54",
                        left: "247.01",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "295.76",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "314.51",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "331.93",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "though",
                        width: "33.34",
                        height: "10.54",
                        left: "348.67",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "388.76",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payor",
                        width: "27.32",
                        height: "10.54",
                        left: "410.17",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "19.99",
                        height: "10.54",
                        left: "443.49",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "had",
                        width: "17.33",
                        height: "10.54",
                        left: "469.48",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made",
                        width: "25.98",
                        height: "10.54",
                        left: "492.81",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.67",
                        height: "10.54",
                        left: "524.79",
                        top: "320.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Marketplace",
                        width: "59.96",
                        height: "10.54",
                        left: "72",
                        top: "306.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "42",
                        height: "10.54",
                        left: "134.96",
                        top: "306.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "directly",
                        width: "36.65",
                        height: "10.54",
                        left: "179.96",
                        top: "306.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "219.61",
                        top: "306.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "231.94",
                        top: "306.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payee",
                        width: "27.98",
                        height: "10.54",
                        left: "249.6",
                        top: "306.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user.",
                        width: "22.33",
                        height: "10.54",
                        left: "280.58",
                        top: "306.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Notwithstanding",
                        width: "80",
                        height: "10.54",
                        left: "72",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "159.5",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics’",
                        width: "39.99",
                        height: "10.54",
                        left: "198.99",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "role",
                        width: "18.66",
                        height: "10.54",
                        left: "246.48",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "272.64",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "290.13",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "302.96",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "facilitator,",
                        width: "49.16",
                        height: "10.54",
                        left: "351.78",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "each",
                        width: "21.98",
                        height: "10.54",
                        left: "408.44",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Seller",
                        width: "27.99",
                        height: "10.54",
                        left: "437.92",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.66",
                        height: "10.54",
                        left: "472.66",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "receives",
                        width: "39.31",
                        height: "10.54",
                        left: "500.07",
                        top: "277.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Marketplace",
                        width: "59.96",
                        height: "10.54",
                        left: "72",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payments",
                        width: "46.67",
                        height: "10.54",
                        left: "137.96",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "understands",
                        width: "57.32",
                        height: "10.54",
                        left: "190.63",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "253.95",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agrees",
                        width: "30.64",
                        height: "10.54",
                        left: "277.28",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "313.92",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "337.17",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Seller",
                        width: "27.99",
                        height: "10.54",
                        left: "364.41",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "397.65",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "obligated",
                        width: "44.65",
                        height: "10.54",
                        left: "410.91",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "460.81",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "complete",
                        width: "43.99",
                        height: "10.54",
                        left: "475.39",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.63",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "transaction",
                        width: "52.65",
                        height: "10.54",
                        left: "72",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "129.9",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "outlined",
                        width: "39.33",
                        height: "10.54",
                        left: "145.14",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "189.72",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "their",
                        width: "21.99",
                        height: "10.54",
                        left: "204.3",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contract",
                        width: "38.65",
                        height: "10.54",
                        left: "231.54",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "275.44",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "302.02",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Purchaser",
                        width: "47.32",
                        height: "10.54",
                        left: "321.18",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "373",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "work",
                        width: "24.66",
                        height: "10.54",
                        left: "394.82",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "423.98",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "remedy",
                        width: "35.98",
                        height: "10.54",
                        left: "437.82",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "478.3",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "500.13",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "deviations",
                        width: "49.32",
                        height: "10.54",
                        left: "72",
                        top: "236.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "124.32",
                        top: "236.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "150.65",
                        top: "236.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contract.",
                        width: "41.64",
                        height: "10.54",
                        left: "175.65",
                        top: "236.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Sellers",
                        width: "32.66",
                        height: "10.54",
                        left: "72",
                        top: "208.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.67",
                        height: "10.54",
                        left: "109.16",
                        top: "208.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "receive",
                        width: "34.63",
                        height: "10.54",
                        left: "134.33",
                        top: "208.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.97",
                        height: "10.54",
                        left: "173.46",
                        top: "208.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payments",
                        width: "46.66",
                        height: "10.54",
                        left: "237.93",
                        top: "208.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "289.09",
                        top: "208.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Purchasers",
                        width: "51.98",
                        height: "10.54",
                        left: "316.17",
                        top: "208.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "371.9",
                        top: "208.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "412.98",
                        top: "208.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "431.39",
                        top: "208.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.33",
                        height: "10.54",
                        left: "473.5",
                        top: "208.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.98",
                        height: "10.54",
                        left: "500.58",
                        top: "208.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "530.31",
                        top: "208.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "72",
                        top: "194.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stipe",
                        width: "24.67",
                        height: "10.54",
                        left: "89.66",
                        top: "194.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Connected",
                        width: "51.31",
                        height: "10.54",
                        left: "118.83",
                        top: "194.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Account",
                        width: "40.66",
                        height: "10.54",
                        left: "174.64",
                        top: "194.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Agreement",
                        width: "53.31",
                        height: "10.54",
                        left: "219.8",
                        top: "194.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(available",
                        width: "47.3",
                        height: "10.54",
                        left: "276.86",
                        top: "194.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "327.91",
                        top: "194.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "https://stripe.com/legal/connect­account),",
                        width: "199.26",
                        height: "10.54",
                        left: "340.32",
                        top: "194.84",
                        color: "#0462c1",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "which",
                        width: "29.33",
                        height: "10.54",
                        left: "72",
                        top: "181.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "includes",
                        width: "39.99",
                        height: "10.54",
                        left: "105.83",
                        top: "181.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "150.32",
                        top: "181.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stripe",
                        width: "28.66",
                        height: "10.54",
                        left: "169.48",
                        top: "181.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Services",
                        width: "40.65",
                        height: "10.54",
                        left: "202.64",
                        top: "181.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Agreement",
                        width: "53.31",
                        height: "10.54",
                        left: "247.04",
                        top: "181.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(available",
                        width: "47.3",
                        height: "10.54",
                        left: "304.1",
                        top: "181.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "355.15",
                        top: "181.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "https://stripe.com/legal/ssa).",
                        width: "135.32",
                        height: "10.54",
                        left: "367.56",
                        top: "181.04",
                        color: "#0462c1",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Sellers",
                        width: "32.66",
                        height: "10.54",
                        left: "506.63",
                        top: "181.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "should",
                        width: "32",
                        height: "10.54",
                        left: "72",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "never",
                        width: "26.65",
                        height: "10.54",
                        left: "108.5",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.98",
                        height: "10.54",
                        left: "139.65",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "170.13",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "receive",
                        width: "34.64",
                        height: "10.54",
                        left: "183.96",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "223.1",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.97",
                        height: "10.54",
                        left: "232.92",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment,",
                        width: "44.99",
                        height: "10.54",
                        left: "297.39",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "346.88",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "deliver",
                        width: "33.31",
                        height: "10.54",
                        left: "361.38",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "399.19",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animal",
                        width: "32.65",
                        height: "10.54",
                        left: "421.02",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "458.17",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "471.92",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "item",
                        width: "21.32",
                        height: "10.54",
                        left: "500.33",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.54",
                        left: "525.4",
                        top: "167.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "sale",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "153.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "95.16",
                        top: "153.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "expectation",
                        width: "55.31",
                        height: "10.54",
                        left: "108.99",
                        top: "153.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "thereof,",
                        width: "36.98",
                        height: "10.54",
                        left: "168.8",
                        top: "153.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "before",
                        width: "30.64",
                        height: "10.54",
                        left: "210.28",
                        top: "153.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "they",
                        width: "20.66",
                        height: "10.54",
                        left: "245.42",
                        top: "153.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "270.58",
                        top: "153.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "confirmed",
                        width: "49.31",
                        height: "10.54",
                        left: "297.73",
                        top: "153.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "351.54",
                        top: "153.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "they",
                        width: "20.66",
                        height: "10.54",
                        left: "374.04",
                        top: "153.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "can",
                        width: "16.65",
                        height: "10.54",
                        left: "399.2",
                        top: "153.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "comply",
                        width: "36",
                        height: "10.54",
                        left: "420.35",
                        top: "153.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "460.1",
                        top: "153.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "485.18",
                        top: "153.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stripe",
                        width: "28.66",
                        height: "10.54",
                        left: "510.93",
                        top: "153.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "agreements.",
                        width: "57.64",
                        height: "10.55",
                        left: "72",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "As",
                        width: "13.33",
                        height: "10.55",
                        left: "134.14",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.55",
                        left: "151.97",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Seller,",
                        width: "30.51",
                        height: "10.55",
                        left: "161.8",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.55",
                        left: "196.81",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agreeing",
                        width: "41.31",
                        height: "10.55",
                        left: "213.31",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "259.12",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.55",
                        left: "272.95",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.55",
                        left: "302.11",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "333.27",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "continuing",
                        width: "51.32",
                        height: "10.55",
                        left: "347.77",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.55",
                        left: "402.84",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "operate",
                        width: "35.31",
                        height: "10.55",
                        left: "415.93",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.55",
                        left: "454.99",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.55",
                        left: "468.73",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Seller",
                        width: "27.99",
                        height: "10.55",
                        left: "477.81",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.55",
                        left: "509.55",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "525.3",
                        top: "139.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "72",
                        top: "125.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "117.11",
                        top: "125.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "138.86",
                        top: "125.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "168.58",
                        top: "125.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "180.91",
                        top: "125.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bound",
                        width: "30",
                        height: "10.54",
                        left: "195.24",
                        top: "125.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "228.24",
                        top: "125.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "243.24",
                        top: "125.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stripe",
                        width: "28.66",
                        height: "10.54",
                        left: "260.9",
                        top: "125.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Connected",
                        width: "51.32",
                        height: "10.54",
                        left: "292.56",
                        top: "125.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Account",
                        width: "40.65",
                        height: "10.54",
                        left: "346.88",
                        top: "125.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Agreement",
                        width: "53.31",
                        height: "10.54",
                        left: "390.53",
                        top: "125.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "446.84",
                        top: "125.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stripe",
                        width: "28.66",
                        height: "10.54",
                        left: "467.17",
                        top: "125.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Services",
                        width: "40.65",
                        height: "10.54",
                        left: "498.83",
                        top: "125.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Agreement,",
                        width: "56.31",
                        height: "10.54",
                        left: "72",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "132.06",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "those",
                        width: "25.33",
                        height: "10.54",
                        left: "145.8",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agreements",
                        width: "54.64",
                        height: "10.54",
                        left: "174.88",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "233.27",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "257.68",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "modified",
                        width: "43.32",
                        height: "10.54",
                        left: "272.76",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "319.83",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stripe",
                        width: "28.67",
                        height: "10.54",
                        left: "334.83",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.32",
                        height: "10.54",
                        left: "366.5",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "392.82",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "417.15",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time.",
                        width: "24.32",
                        height: "10.54",
                        left: "429.49",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "As",
                        width: "13.34",
                        height: "10.54",
                        left: "456.81",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "473.15",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "condition",
                        width: "45.32",
                        height: "10.54",
                        left: "481.48",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "529.8",
                        top: "112.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "receiving",
                        width: "44.64",
                        height: "10.54",
                        left: "72",
                        top: "98.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "120.39",
                        top: "98.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "processing",
                        width: "51.33",
                        height: "10.54",
                        left: "165.46",
                        top: "98.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.64",
                        height: "10.54",
                        left: "220.54",
                        top: "98.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "262.93",
                        top: "98.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stripe,",
                        width: "31.67",
                        height: "10.54",
                        left: "304.01",
                        top: "98.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "339.43",
                        top: "98.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "360.43",
                        top: "98.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "389.4",
                        top: "98.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.65",
                        height: "10.54",
                        left: "401.74",
                        top: "98.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "441.39",
                        top: "98.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "455.06",
                        top: "98.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accurate",
                        width: "39.96",
                        height: "10.54",
                        left: "479.4",
                        top: "98.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "522.36",
                        top: "98.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "complete",
                        width: "43.98",
                        height: "10.54",
                        left: "72",
                        top: "84.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "123.48",
                        top: "84.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "187.63",
                        top: "84.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "221.79",
                        top: "84.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "247.29",
                        top: "84.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "272.12",
                        top: "84.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "business,",
                        width: "43.67",
                        height: "10.54",
                        left: "301.62",
                        top: "84.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "352.79",
                        top: "84.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "377.61",
                        top: "84.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "authorize",
                        width: "44.65",
                        height: "10.54",
                        left: "403.11",
                        top: "84.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "455.26",
                        top: "84.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "473.43",
                        top: "84.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "share",
                        width: "25.32",
                        height: "10.54",
                        left: "490.26",
                        top: "84.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "522.33",
                        top: "84.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,720",
                    "lineto,540,720",
                    "lineto,540,705",
                    "lineto,90,705",
                    "lineto,90,720",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,706",
                    "lineto,540,706",
                    "lineto,540,691",
                    "lineto,90,691",
                    "lineto,90,706",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,692",
                    "lineto,540,692",
                    "lineto,540,677",
                    "lineto,90,677",
                    "lineto,90,692",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,679",
                    "lineto,540,679",
                    "lineto,540,664",
                    "lineto,90,664",
                    "lineto,90,679",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,665",
                    "lineto,540,665",
                    "lineto,540,638",
                    "lineto,90,638",
                    "lineto,90,665",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,639",
                    "lineto,540,639",
                    "lineto,540,624",
                    "lineto,90,624",
                    "lineto,90,639",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,625",
                    "lineto,540,625",
                    "lineto,540,610",
                    "lineto,90,610",
                    "lineto,90,625",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,611",
                    "lineto,540,611",
                    "lineto,540,596",
                    "lineto,90,596",
                    "lineto,90,611",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,598",
                    "lineto,540,598",
                    "lineto,540,583",
                    "lineto,90,583",
                    "lineto,90,598",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,584",
                    "lineto,540,584",
                    "lineto,540,569",
                    "lineto,90,569",
                    "lineto,90,584",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,570",
                    "lineto,540,570",
                    "lineto,540,555",
                    "lineto,90,555",
                    "lineto,90,570",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,556",
                    "lineto,540,556",
                    "lineto,540,541",
                    "lineto,90,541",
                    "lineto,90,556",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,542",
                    "lineto,540,542",
                    "lineto,540,527",
                    "lineto,90,527",
                    "lineto,90,542",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,529",
                    "lineto,540,529",
                    "lineto,540,514",
                    "lineto,90,514",
                    "lineto,90,529",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,501",
                    "lineto,540,501",
                    "lineto,540,472",
                    "lineto,72,472",
                    "lineto,72,501",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,108,626.5", "lineto,166,626.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,488.5", "lineto,190,488.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#0462c1",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,340,196.5", "lineto,533,196.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#0462c1",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,368,182.5", "lineto,496,182.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "11",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "133.15",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transaction",
                        width: "52.65",
                        height: "10.54",
                        left: "154.98",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "212.13",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "related",
                        width: "32.64",
                        height: "10.54",
                        left: "273.28",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "310.42",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "323.51",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "349.25",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "369",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "382.75",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "401.16",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "processing",
                        width: "51.32",
                        height: "10.54",
                        left: "446.23",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.65",
                        height: "10.54",
                        left: "501.3",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "provided",
                        width: "42.66",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "118.41",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stripe.",
                        width: "31.66",
                        height: "10.54",
                        left: "134.16",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Sellers",
                        width: "32.66",
                        height: "10.54",
                        left: "169.57",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "19.33",
                        height: "10.54",
                        left: "205.98",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.98",
                        height: "10.54",
                        left: "229.06",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "258.79",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "280.53",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "298.27",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "debit",
                        width: "24",
                        height: "10.54",
                        left: "322.68",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "their",
                        width: "21.99",
                        height: "10.54",
                        left: "350.43",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stripe",
                        width: "28.66",
                        height: "10.54",
                        left: "376.17",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "408.58",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "449.64",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "connection",
                        width: "52.64",
                        height: "10.54",
                        left: "462.73",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "518.37",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "disputes",
                        width: "39.33",
                        height: "10.54",
                        left: "72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "117.33",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Purchasers.",
                        width: "54.98",
                        height: "10.54",
                        left: "144.67",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "205.65",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reserve",
                        width: "34.64",
                        height: "10.54",
                        left: "227.34",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "267.98",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.67",
                        height: "10.54",
                        left: "287.89",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "315.81",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "process",
                        width: "35.99",
                        height: "10.54",
                        left: "330.39",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "371.63",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "debits",
                        width: "28.66",
                        height: "10.54",
                        left: "398.88",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "432.79",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "455.37",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "withhold",
                        width: "42.67",
                        height: "10.54",
                        left: "469.95",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "final",
                        width: "21.99",
                        height: "10.54",
                        left: "517.87",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "payments",
                        width: "45.99",
                        height: "10.55",
                        left: "72",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "until",
                        width: "22",
                        height: "10.55",
                        left: "121.74",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.55",
                        left: "147.49",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.66",
                        height: "10.55",
                        left: "165.23",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "investigated",
                        width: "57.98",
                        height: "10.55",
                        left: "191.64",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disputes.",
                        width: "42.34",
                        height: "10.55",
                        left: "253.37",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.55",
                        left: "299.46",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "318.9",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.98",
                        height: "10.55",
                        left: "343.31",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.55",
                        left: "373.04",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "final",
                        width: "21.99",
                        height: "10.55",
                        left: "382.12",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "decision",
                        width: "39.99",
                        height: "10.55",
                        left: "407.86",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.55",
                        left: "451.6",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disputes",
                        width: "39.33",
                        height: "10.55",
                        left: "467.35",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.55",
                        left: "510.43",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.55",
                        left: "523.52",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "72",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion",
                        width: "47.32",
                        height: "10.54",
                        left: "95.08",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and,",
                        width: "20.33",
                        height: "10.54",
                        left: "146.15",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "170.23",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "183.97",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Seller,",
                        width: "30.51",
                        height: "10.54",
                        left: "193.05",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "227.31",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "understand",
                        width: "52.65",
                        height: "10.54",
                        left: "249.06",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "305.46",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "327.21",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.96",
                        height: "10.54",
                        left: "342.95",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payments",
                        width: "46.67",
                        height: "10.54",
                        left: "406.66",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "457.08",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "478.83",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "earn",
                        width: "20.65",
                        height: "10.54",
                        left: "503.24",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "527.64",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "72",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "91.16",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.64",
                        height: "10.54",
                        left: "134.02",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "subject",
                        width: "33.99",
                        height: "10.54",
                        left: "153.16",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "191.65",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "205.49",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "decisions.",
                        width: "47.66",
                        height: "10.54",
                        left: "231.98",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Additionally,",
                        width: "62.89",
                        height: "10.54",
                        left: "284.14",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Sellers",
                        width: "32.66",
                        height: "10.54",
                        left: "351.53",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "388.69",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "419.16",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "441.66",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may,",
                        width: "22.88",
                        height: "10.54",
                        left: "460.15",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "487.53",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "500.61",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "520.36",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "discretion,",
                        width: "50.32",
                        height: "10.54",
                        left: "72",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reverse",
                        width: "34.64",
                        height: "10.54",
                        left: "126.07",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payments",
                        width: "45.99",
                        height: "10.54",
                        left: "164.46",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "213.45",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "234.45",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "been",
                        width: "22.65",
                        height: "10.54",
                        left: "260.1",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made",
                        width: "25.99",
                        height: "10.54",
                        left: "285.75",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "314.74",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "327.07",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Seller",
                        width: "27.99",
                        height: "10.54",
                        left: "335.4",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "366.39",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "395.38",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "determine",
                        width: "47.98",
                        height: "10.54",
                        left: "412.37",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "463.35",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "484.34",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Purchaser",
                        width: "47.31",
                        height: "10.54",
                        left: "492.67",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "refund",
                        width: "31.32",
                        height: "10.54",
                        left: "72",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "109.32",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "warranted,",
                        width: "50.97",
                        height: "10.54",
                        left: "123.32",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "180.29",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "230.87",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "connection",
                        width: "52.64",
                        height: "10.54",
                        left: "245.46",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "303.35",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "329.94",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Seller’s",
                        width: "36.44",
                        height: "10.54",
                        left: "357.18",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "actions",
                        width: "33.99",
                        height: "10.54",
                        left: "398.87",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "438.11",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inactions",
                        width: "43.32",
                        height: "10.54",
                        left: "453.36",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "501.93",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.64",
                        height: "10.54",
                        left: "525.18",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "prohibited",
                        width: "49.32",
                        height: "10.54",
                        left: "72",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "124.32",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "139.32",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "166.98",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "199.8",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use.",
                        width: "21.67",
                        height: "10.54",
                        left: "212.79",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "As",
                        width: "13.34",
                        height: "10.54",
                        left: "72",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "89.09",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Purchaser",
                        width: "47.32",
                        height: "10.54",
                        left: "98.16",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "making",
                        width: "35.99",
                        height: "10.54",
                        left: "149.23",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "188.97",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.96",
                        height: "10.54",
                        left: "197.3",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "42",
                        height: "10.54",
                        left: "260.26",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "305.26",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stripe,",
                        width: "31.66",
                        height: "10.54",
                        left: "345.59",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "380.25",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "401.25",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "430.22",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pay",
                        width: "17.32",
                        height: "10.54",
                        left: "442.56",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "462.88",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amounts",
                        width: "40.66",
                        height: "10.54",
                        left: "477.88",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "521.54",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "owe",
                        width: "19.99",
                        height: "10.55",
                        left: "72",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.55",
                        left: "95.74",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "due",
                        width: "17.33",
                        height: "10.55",
                        left: "125.48",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "using",
                        width: "26",
                        height: "10.55",
                        left: "145.81",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "174.81",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "preferred",
                        width: "43.96",
                        height: "10.55",
                        left: "199.81",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.55",
                        left: "246.77",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "method.",
                        width: "39",
                        height: "10.55",
                        left: "291.09",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.55",
                        left: "333.09",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "further",
                        width: "32.65",
                        height: "10.55",
                        left: "355.55",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "authorize",
                        width: "44.64",
                        height: "10.55",
                        left: "391.2",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stripe",
                        width: "28.67",
                        height: "10.55",
                        left: "438.84",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "470.51",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "charge",
                        width: "31.76",
                        height: "10.55",
                        left: "482.84",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "517.6",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "preferred",
                        width: "43.96",
                        height: "10.54",
                        left: "72",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "119.71",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "method",
                        width: "36",
                        height: "10.54",
                        left: "164.78",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "204.53",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amounts",
                        width: "40.66",
                        height: "10.54",
                        left: "222.27",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "266.68",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owe",
                        width: "20",
                        height: "10.54",
                        left: "288.43",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "312.18",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "they",
                        width: "20.66",
                        height: "10.54",
                        left: "341.92",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "366.33",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "due.",
                        width: "20.32",
                        height: "10.54",
                        left: "384.73",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "408.8",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.34",
                        height: "10.54",
                        left: "431.27",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "keep",
                        width: "22.65",
                        height: "10.54",
                        left: "457.61",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "483.26",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "498.25",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "72",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "134.65",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "158.65",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "201.31",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "up­to­date,",
                        width: "52.31",
                        height: "10.54",
                        left: "217.98",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accurate,",
                        width: "42.96",
                        height: "10.54",
                        left: "276.29",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "325.25",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "complete.",
                        width: "46.98",
                        height: "10.54",
                        left: "348.58",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "When",
                        width: "28.65",
                        height: "10.54",
                        left: "401.56",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "436.21",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "store",
                        width: "23.33",
                        height: "10.54",
                        left: "459.46",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "488.04",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "498.62",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "method",
                        width: "35.99",
                        height: "10.54",
                        left: "72",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "111.74",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us,",
                        width: "13.67",
                        height: "10.54",
                        left: "136.83",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "153.5",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "174.5",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "203.47",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "215.81",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "233.47",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "set",
                        width: "13.33",
                        height: "10.54",
                        left: "263.13",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "forth",
                        width: "23.32",
                        height: "10.54",
                        left: "279.46",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.33",
                        height: "10.54",
                        left: "305.78",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stored",
                        width: "31.33",
                        height: "10.54",
                        left: "336.11",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment,",
                        width: "44.99",
                        height: "10.54",
                        left: "370.44",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below.",
                        width: "31.55",
                        height: "10.54",
                        left: "418.43",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Do",
                        width: "14.66",
                        height: "10.54",
                        left: "452.98",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "470.64",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "share",
                        width: "25.31",
                        height: "10.54",
                        left: "488.98",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "517.29",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "72",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "card,",
                        width: "23.65",
                        height: "10.54",
                        left: "117.07",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "23.32",
                        height: "10.54",
                        left: "144.47",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account,",
                        width: "40.32",
                        height: "10.54",
                        left: "171.54",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "215.61",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "229.35",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "financial",
                        width: "41.98",
                        height: "10.54",
                        left: "257.76",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "303.49",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "363.89",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "388.22",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "408.55",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user.",
                        width: "22.33",
                        height: "10.54",
                        left: "436.21",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "461.54",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "take",
                        width: "19.98",
                        height: "10.54",
                        left: "480.23",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "steps",
                        width: "24",
                        height: "10.54",
                        left: "503.21",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.3399999999999",
                        height: "10.54",
                        left: "530.21",
                        top: "499.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "secure",
                        width: "30.64",
                        height: "10.54",
                        left: "72",
                        top: "485.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "106.39",
                        top: "485.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "122.14",
                        top: "485.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "methods",
                        width: "40.66",
                        height: "10.54",
                        left: "167.21",
                        top: "485.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "211.62",
                        top: "485.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "232.7",
                        top: "485.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "261.11",
                        top: "485.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "financial",
                        width: "41.97",
                        height: "10.54",
                        left: "305.51",
                        top: "485.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "59.66",
                        height: "10.54",
                        left: "351.23",
                        top: "485.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "but",
                        width: "15.33",
                        height: "10.54",
                        left: "414.64",
                        top: "485.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "433.72",
                        top: "485.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "expressly",
                        width: "45.33",
                        height: "10.54",
                        left: "450.71",
                        top: "485.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclaim",
                        width: "40.66",
                        height: "10.54",
                        left: "499.04",
                        top: "485.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liability",
                        width: "37.33",
                        height: "10.54",
                        left: "94.58",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "137.16",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you,",
                        width: "21",
                        height: "10.54",
                        left: "150.99",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "176.49",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "198.32",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "220.82",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "251.29",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "hold",
                        width: "21.33",
                        height: "10.54",
                        left: "265.13",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "290.96",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "harmless",
                        width: "42.66",
                        height: "10.54",
                        left: "306.13",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "353.29",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "371.78",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "damages",
                        width: "41.99",
                        height: "10.54",
                        left: "393.6",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "440.09",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "462.59",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "suffer",
                        width: "27.76",
                        height: "10.54",
                        left: "487.75",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "520.01",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "534.51",
                        top: "471.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "result",
                        width: "26.66",
                        height: "10.54",
                        left: "72",
                        top: "457.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "101.66",
                        top: "457.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "114.66",
                        top: "457.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclosure",
                        width: "48.65",
                        height: "10.54",
                        left: "132.32",
                        top: "457.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "183.97",
                        top: "457.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "196.97",
                        top: "457.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.66",
                        height: "10.54",
                        left: "221.96",
                        top: "457.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "financial",
                        width: "41.97",
                        height: "10.54",
                        left: "265.62",
                        top: "457.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "310.59",
                        top: "457.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "370.25",
                        top: "457.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "382.58",
                        top: "457.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unintended",
                        width: "53.32",
                        height: "10.54",
                        left: "402.91",
                        top: "457.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "recipients.",
                        width: "49.65",
                        height: "10.54",
                        left: "459.23",
                        top: "457.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "92.94",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enable",
                        width: "31.31",
                        height: "10.54",
                        left: "118.85",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "155.41",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "178.66",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.98",
                        height: "10.54",
                        left: "193.25",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.97",
                        height: "10.54",
                        left: "224.48",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payments",
                        width: "46.66",
                        height: "10.54",
                        left: "289.7",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "using",
                        width: "26.01",
                        height: "10.54",
                        left: "341.61",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "credit,",
                        width: "30.31",
                        height: "10.54",
                        left: "372.87",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "debit,",
                        width: "27",
                        height: "10.54",
                        left: "408.43",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "439.93",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prepaid",
                        width: "35.99",
                        height: "10.54",
                        left: "454.42",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cards,",
                        width: "28.31",
                        height: "10.54",
                        left: "494.91",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "527.72",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "linking",
                        width: "34",
                        height: "10.55",
                        left: "72",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "109.75",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "23.32",
                        height: "10.55",
                        left: "135.5",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account,",
                        width: "40.32",
                        height: "10.55",
                        left: "162.57",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.55",
                        left: "206.64",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.55",
                        left: "220.38",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.55",
                        left: "236.13",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.55",
                        left: "257.21",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.55",
                        left: "285.62",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "method",
                        width: "35.99",
                        height: "10.55",
                        left: "330.69",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.55",
                        left: "370.43",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "support.",
                        width: "39",
                        height: "10.55",
                        left: "388.17",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.55",
                        left: "430.92",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reserve",
                        width: "34.64",
                        height: "10.55",
                        left: "449.61",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "487.25",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.67",
                        height: "10.55",
                        left: "504.91",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.3299999999999",
                        height: "10.55",
                        left: "530.58",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "cancel",
                        width: "30.64",
                        height: "10.54",
                        left: "72",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "108.64",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ability",
                        width: "30.67",
                        height: "10.54",
                        left: "136.63",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "173.3",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.99",
                        height: "10.54",
                        left: "188.63",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payments",
                        width: "45.99",
                        height: "10.54",
                        left: "220.62",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "271.86",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "one",
                        width: "17.33",
                        height: "10.54",
                        left: "298.44",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "321.02",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "more",
                        width: "24.66",
                        height: "10.54",
                        left: "336.26",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "366.17",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "381.42",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "401.33",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "methods",
                        width: "40.66",
                        height: "10.54",
                        left: "447.9",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "493.81",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "517.06",
                        top: "401.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "authorized",
                        width: "50.64",
                        height: "10.54",
                        left: "72",
                        top: "387.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "125.64",
                        top: "387.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "137.98",
                        top: "387.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "156.97",
                        top: "387.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "179.3",
                        top: "387.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "absolute",
                        width: "39.99",
                        height: "10.54",
                        left: "199.63",
                        top: "387.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion.",
                        width: "50.32",
                        height: "10.54",
                        left: "242.62",
                        top: "387.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "72",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "83.74",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "choose",
                        width: "33.32",
                        height: "10.54",
                        left: "105.49",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "142.56",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "23.33",
                        height: "10.54",
                        left: "168.31",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "195.39",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "236.45",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "250.2",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.97",
                        height: "10.54",
                        left: "275.94",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "41.99",
                        height: "10.54",
                        left: "339.66",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "method,",
                        width: "38.99",
                        height: "10.54",
                        left: "385.4",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "428.14",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "authorize",
                        width: "44.65",
                        height: "10.54",
                        left: "449.89",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stripe",
                        width: "28.66",
                        height: "10.54",
                        left: "498.29",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.3399999999999",
                        height: "10.54",
                        left: "529.95",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "make",
                        width: "25.99",
                        height: "10.54",
                        left: "72",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Automated",
                        width: "53.32",
                        height: "10.54",
                        left: "102.49",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Clearing",
                        width: "41.32",
                        height: "10.54",
                        left: "160.31",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "House",
                        width: "30.66",
                        height: "10.54",
                        left: "206.13",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(“",
                        width: "9.32",
                        height: "10.54",
                        left: "241.29",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "ACH",
                        width: "26.67",
                        height: "10.54",
                        left: "250.61",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”)",
                        width: "9.3200000000001",
                        height: "10.54",
                        left: "277.28",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "withdrawals",
                        width: "58.65",
                        height: "10.54",
                        left: "291.1",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "354.25",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "382.08",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "23.33",
                        height: "10.54",
                        left: "408.57",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account,",
                        width: "40.31",
                        height: "10.54",
                        left: "435.65",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "479.71",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "500.79",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.99",
                        height: "10.54",
                        left: "513.87",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inquiries",
                        width: "41.99",
                        height: "10.54",
                        left: "93.08",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "138.82",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "consider",
                        width: "40.65",
                        height: "10.54",
                        left: "156.56",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "necessary",
                        width: "46.65",
                        height: "10.54",
                        left: "200.96",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "251.36",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "validate",
                        width: "37.98",
                        height: "10.54",
                        left: "264.44",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "306.17",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "dispute",
                        width: "34.66",
                        height: "10.54",
                        left: "327.25",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "involving",
                        width: "46",
                        height: "10.54",
                        left: "365.66",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payments",
                        width: "45.99",
                        height: "10.54",
                        left: "415.41",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "465.15",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.99",
                        height: "10.54",
                        left: "486.9",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "516.64",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "528.97",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "86.5",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "100.33",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user,",
                        width: "22.51",
                        height: "10.54",
                        left: "110.16",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "29.32",
                        height: "10.54",
                        left: "137.17",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "170.99",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "include",
                        width: "35.32",
                        height: "10.54",
                        left: "196.15",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ordering",
                        width: "40.66",
                        height: "10.54",
                        left: "235.97",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "281.13",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "credit",
                        width: "27.32",
                        height: "10.54",
                        left: "290.95",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "report",
                        width: "28.65",
                        height: "10.54",
                        left: "322.77",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "355.17",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "performing",
                        width: "53.98",
                        height: "10.54",
                        left: "376.25",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "433.98",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "credit",
                        width: "27.31",
                        height: "10.54",
                        left: "462.39",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "checks",
                        width: "32.65",
                        height: "10.54",
                        left: "493.45",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.85",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "verifying",
                        width: "43.99",
                        height: "10.54",
                        left: "72",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "120.49",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "139.65",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "200.8",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "223.3",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "263.71",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "against",
                        width: "33.99",
                        height: "10.54",
                        left: "278.13",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third­party",
                        width: "51.31",
                        height: "10.54",
                        left: "315.87",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "databases.",
                        width: "48.98",
                        height: "10.54",
                        left: "370.93",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "423.66",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "authorize",
                        width: "44.64",
                        height: "10.54",
                        left: "446.88",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "495.27",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "509.69",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "523.44",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "provider",
                        width: "40.65",
                        height: "10.54",
                        left: "72",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "116.4",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "initiate",
                        width: "33.32",
                        height: "10.54",
                        left: "129.49",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "one",
                        width: "17.32",
                        height: "10.54",
                        left: "166.56",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "187.63",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "more",
                        width: "24.66",
                        height: "10.54",
                        left: "201.38",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACH",
                        width: "25.33",
                        height: "10.54",
                        left: "229.79",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "debit",
                        width: "24",
                        height: "10.54",
                        left: "258.12",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "entries",
                        width: "31.98",
                        height: "10.54",
                        left: "285.12",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(withdrawals)",
                        width: "66.65",
                        height: "10.54",
                        left: "320.1",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "389.75",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "402.74",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "creation",
                        width: "38.65",
                        height: "10.54",
                        left: "420.4",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "462.05",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "475.04",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "equivalent",
                        width: "49.98",
                        height: "10.54",
                        left: "489.37",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "bank",
                        width: "23.33",
                        height: "10.54",
                        left: "72",
                        top: "276.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "draft",
                        width: "22.65",
                        height: "10.54",
                        left: "99.83",
                        top: "276.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "126.98",
                        top: "276.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "145.47",
                        top: "276.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "specified",
                        width: "43.31",
                        height: "10.54",
                        left: "164.63",
                        top: "276.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amount(s)",
                        width: "48.66",
                        height: "10.54",
                        left: "211.69",
                        top: "276.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "264.1",
                        top: "276.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "291.18",
                        top: "276.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "23.33",
                        height: "10.54",
                        left: "316.92",
                        top: "276.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account,",
                        width: "40.31",
                        height: "10.54",
                        left: "344",
                        top: "276.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "388.06",
                        top: "276.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "409.14",
                        top: "276.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "authorize",
                        width: "44.64",
                        height: "10.54",
                        left: "430.89",
                        top: "276.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "479.28",
                        top: "276.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "financial",
                        width: "41.98",
                        height: "10.54",
                        left: "497.69",
                        top: "276.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "institution",
                        width: "48.67",
                        height: "10.54",
                        left: "72",
                        top: "262.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "125.17",
                        top: "262.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "holds",
                        width: "26",
                        height: "10.54",
                        left: "147.67",
                        top: "262.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "178.17",
                        top: "262.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "23.32",
                        height: "10.54",
                        left: "204.67",
                        top: "262.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.32",
                        height: "10.54",
                        left: "232.49",
                        top: "262.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "274.31",
                        top: "262.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "deduct",
                        width: "31.99",
                        height: "10.54",
                        left: "288.14",
                        top: "262.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "324.63",
                        top: "262.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payments.",
                        width: "48.99",
                        height: "10.54",
                        left: "350.37",
                        top: "262.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "403.11",
                        top: "262.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "19.33",
                        height: "10.54",
                        left: "426.33",
                        top: "262.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "authorize",
                        width: "44.64",
                        height: "10.54",
                        left: "449.41",
                        top: "262.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "497.8",
                        top: "262.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "23.33",
                        height: "10.54",
                        left: "516.21",
                        top: "262.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "72",
                        top: "248.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "holds",
                        width: "26.01",
                        height: "10.54",
                        left: "96.74",
                        top: "248.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "128.75",
                        top: "248.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "23.33",
                        height: "10.54",
                        left: "156.74",
                        top: "248.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "186.07",
                        top: "248.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "229.38",
                        top: "248.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "deduct",
                        width: "31.98",
                        height: "10.54",
                        left: "244.72",
                        top: "248.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "282.7",
                        top: "248.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "306.03",
                        top: "248.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payments",
                        width: "45.99",
                        height: "10.54",
                        left: "334.03",
                        top: "248.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "386.02",
                        top: "248.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "401.35",
                        top: "248.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amounts",
                        width: "40.66",
                        height: "10.54",
                        left: "422.01",
                        top: "248.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "468.67",
                        top: "248.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "frequency",
                        width: "47.97",
                        height: "10.54",
                        left: "492",
                        top: "248.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "designated",
                        width: "51.32",
                        height: "10.54",
                        left: "72",
                        top: "234.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "127.07",
                        top: "234.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "140.15",
                        top: "234.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account.",
                        width: "40.31",
                        height: "10.54",
                        left: "165.9",
                        top: "234.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.54",
                        left: "209.96",
                        top: "234.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "authorization",
                        width: "63.31",
                        height: "10.54",
                        left: "237.17",
                        top: "234.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "304.23",
                        top: "234.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "remain",
                        width: "33.31",
                        height: "10.54",
                        left: "326.65",
                        top: "234.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "363.71",
                        top: "234.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "effect",
                        width: "27.09",
                        height: "10.54",
                        left: "376.8",
                        top: "234.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "until",
                        width: "22",
                        height: "10.54",
                        left: "407.64",
                        top: "234.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "canceled",
                        width: "41.96",
                        height: "10.54",
                        left: "432.64",
                        top: "234.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "477.6",
                        top: "234.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "writing",
                        width: "34.66",
                        height: "10.54",
                        left: "489.94",
                        top: "234.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "527.6",
                        top: "234.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "you.",
                        width: "21",
                        height: "10.54",
                        left: "72",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "97.5",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.98",
                        height: "10.54",
                        left: "121.46",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "151.94",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notify",
                        width: "28.67",
                        height: "10.54",
                        left: "165.77",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "198.94",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "213.36",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "227.1",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "changes",
                        width: "38.65",
                        height: "10.54",
                        left: "248.18",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "290.58",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "303.66",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "329.41",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "370.47",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "430.88",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "termination",
                        width: "55.32",
                        height: "10.54",
                        left: "444.62",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "503.69",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "517.44",
                        top: "220.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "authorization",
                        width: "63.31",
                        height: "10.54",
                        left: "72",
                        top: "207.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "138.31",
                        top: "207.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "least",
                        width: "21.99",
                        height: "10.54",
                        left: "149.97",
                        top: "207.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "five",
                        width: "18.66",
                        height: "10.54",
                        left: "174.96",
                        top: "207.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "days",
                        width: "21.99",
                        height: "10.54",
                        left: "196.62",
                        top: "207.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prior",
                        width: "23.33",
                        height: "10.54",
                        left: "221.61",
                        top: "207.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "247.94",
                        top: "207.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "260.27",
                        top: "207.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "next",
                        width: "20.66",
                        height: "10.54",
                        left: "285.27",
                        top: "207.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment.",
                        width: "44.32",
                        height: "10.54",
                        left: "308.93",
                        top: "207.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "72",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "83.74",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "full",
                        width: "16.66",
                        height: "10.54",
                        left: "109.49",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "129.9",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "174.97",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "186.73",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "processed",
                        width: "47.31",
                        height: "10.54",
                        left: "205.81",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "256.88",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.66",
                        height: "10.54",
                        left: "272.63",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.67",
                        height: "10.54",
                        left: "287.04",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "299.46",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "same",
                        width: "24.65",
                        height: "10.54",
                        left: "317.87",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time,",
                        width: "24.33",
                        height: "10.54",
                        left: "345.52",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "372.85",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "hereby",
                        width: "32.65",
                        height: "10.54",
                        left: "393.85",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "authorize",
                        width: "44.64",
                        height: "10.54",
                        left: "429.5",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "partial",
                        width: "30.65",
                        height: "10.54",
                        left: "477.14",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "debits",
                        width: "28.67",
                        height: "10.54",
                        left: "510.79",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "72",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "98.33",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "23.33",
                        height: "10.54",
                        left: "123.32",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account,",
                        width: "40.31",
                        height: "10.54",
                        left: "149.65",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "192.96",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "211.29",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "exceed",
                        width: "33.3",
                        height: "10.54",
                        left: "223.63",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "259.93",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "total",
                        width: "21.33",
                        height: "10.54",
                        left: "277.59",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amount",
                        width: "36",
                        height: "10.54",
                        left: "301.92",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "340.92",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "353.91",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "order.",
                        width: "27.65",
                        height: "10.54",
                        left: "378.91",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "409.56",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "432.03",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "461",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pay",
                        width: "17.32",
                        height: "10.54",
                        left: "473.34",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "493.66",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACH",
                        width: "25.34",
                        height: "10.54",
                        left: "513.99",
                        top: "164.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "fees",
                        width: "19.32",
                        height: "10.55",
                        left: "72",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.55",
                        left: "95.07",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fines",
                        width: "23.33",
                        height: "10.55",
                        left: "108.81",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "135.89",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "156.89",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.55",
                        left: "169.89",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "incur",
                        width: "24.66",
                        height: "10.55",
                        left: "186.88",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "associated",
                        width: "49.31",
                        height: "10.55",
                        left: "214.54",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.55",
                        left: "266.85",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transactions",
                        width: "57.32",
                        height: "10.55",
                        left: "291.18",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "351.5",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "authorize.",
                        width: "47.64",
                        height: "10.55",
                        left: "372.5",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "This",
                        width: "21.33",
                        height: "10.55",
                        left: "423.14",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "return",
                        width: "28.66",
                        height: "10.55",
                        left: "447.47",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fee",
                        width: "14.65",
                        height: "10.55",
                        left: "479.13",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.55",
                        left: "496.78",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "vary",
                        width: "21.33",
                        height: "10.55",
                        left: "518.44",
                        top: "150.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "based",
                        width: "27.32",
                        height: "10.54",
                        left: "72",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "103.82",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "29.33",
                        height: "10.54",
                        left: "120.32",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "state",
                        width: "21.99",
                        height: "10.54",
                        left: "154.15",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "179.89",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "201.64",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "located",
                        width: "34.64",
                        height: "10.54",
                        left: "220.04",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in.",
                        width: "12.34",
                        height: "10.54",
                        left: "258.43",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "The",
                        width: "18.65",
                        height: "10.54",
                        left: "274.52",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "return",
                        width: "28.66",
                        height: "10.54",
                        left: "296.92",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fee",
                        width: "14.64",
                        height: "10.54",
                        left: "329.33",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "347.72",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "372.13",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "added",
                        width: "28.65",
                        height: "10.54",
                        left: "387.21",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "419.61",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "432.7",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "458.44",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amount",
                        width: "36",
                        height: "10.54",
                        left: "503.51",
                        top: "136.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "debited",
                        width: "35.32",
                        height: "10.54",
                        left: "93.08",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.32",
                        height: "10.54",
                        left: "132.15",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "159.22",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "23.32",
                        height: "10.54",
                        left: "184.97",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.32",
                        height: "10.54",
                        left: "212.04",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "253.11",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "264.19",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "resubmit",
                        width: "41.99",
                        height: "10.54",
                        left: "281.93",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "327.67",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACH",
                        width: "25.34",
                        height: "10.54",
                        left: "342.75",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "debit",
                        width: "23.99",
                        height: "10.54",
                        left: "371.84",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "due",
                        width: "17.33",
                        height: "10.54",
                        left: "399.58",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "420.66",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "insufficient",
                        width: "54.43",
                        height: "10.54",
                        left: "433.74",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "funds.",
                        width: "29.67",
                        height: "10.54",
                        left: "491.17",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "523.84",
                        top: "123.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "72",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "initiate",
                        width: "33.32",
                        height: "10.54",
                        left: "97.16",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "134.98",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collection",
                        width: "47.31",
                        height: "10.54",
                        left: "144.81",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "process",
                        width: "35.99",
                        height: "10.54",
                        left: "196.62",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "237.11",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "legal",
                        width: "23.32",
                        height: "10.54",
                        left: "250.86",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "action",
                        width: "29.32",
                        height: "10.54",
                        left: "277.93",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "311",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "324.08",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "359.81",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "money",
                        width: "32.66",
                        height: "10.54",
                        left: "380.89",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owed",
                        width: "25.99",
                        height: "10.54",
                        left: "417.3",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "447.04",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us.",
                        width: "13.66",
                        height: "10.54",
                        left: "460.13",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "477.54",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "500.76",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "530.48",
                        top: "109.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "pay",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "92.33",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "107.32",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "costs",
                        width: "24",
                        height: "10.54",
                        left: "126.32",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "153.32",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "170.31",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "action,",
                        width: "32.33",
                        height: "10.54",
                        left: "195.3",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.32",
                        height: "10.54",
                        left: "230.63",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "278.95",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reasonable",
                        width: "51.3",
                        height: "10.54",
                        left: "299.28",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "attorneys'",
                        width: "46.15",
                        height: "10.54",
                        left: "353.58",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees.",
                        width: "22.32",
                        height: "10.54",
                        left: "402.73",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Federal",
                        width: "35.98",
                        height: "10.54",
                        left: "428.05",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law",
                        width: "17.33",
                        height: "10.54",
                        left: "467.03",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limits",
                        width: "27.34",
                        height: "10.54",
                        left: "487.36",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "517.7",
                        top: "95.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "liability",
                        width: "37.33",
                        height: "10.54",
                        left: "72",
                        top: "81.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "115.33",
                        top: "81.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "135.32",
                        top: "81.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fraudulent,",
                        width: "52.31",
                        height: "10.54",
                        left: "158.65",
                        top: "81.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "erroneous,",
                        width: "50.32",
                        height: "10.54",
                        left: "216.96",
                        top: "81.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "272.53",
                        top: "81.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unauthorized",
                        width: "62.64",
                        height: "10.54",
                        left: "287.77",
                        top: "81.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transactions",
                        width: "57.32",
                        height: "10.54",
                        left: "355.66",
                        top: "81.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "418.23",
                        top: "81.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "446.81",
                        top: "81.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "23.33",
                        height: "10.54",
                        left: "474.05",
                        top: "81.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "502.63",
                        top: "81.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,336,514.5", "lineto,412,514.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "12",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "based",
                        width: "27.32",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "103.07",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "how",
                        width: "20.67",
                        height: "10.54",
                        left: "118.82",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "quickly",
                        width: "35.99",
                        height: "10.54",
                        left: "143.24",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "182.98",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "report",
                        width: "28.65",
                        height: "10.54",
                        left: "204.73",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "6.67",
                        height: "10.54",
                        left: "237.13",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "247.55",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "260.64",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "financial",
                        width: "41.98",
                        height: "10.54",
                        left: "286.38",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "institution.",
                        width: "51.67",
                        height: "10.54",
                        left: "332.11",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "386.78",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.34",
                        height: "10.54",
                        left: "409.25",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "report",
                        width: "28.65",
                        height: "10.54",
                        left: "435.59",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "467.24",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fraudulent,",
                        width: "52.32",
                        height: "10.54",
                        left: "487.56",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "erroneous",
                        width: "47.31",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "124.56",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unauthorized",
                        width: "62.64",
                        height: "10.54",
                        left: "139.81",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transactions",
                        width: "57.32",
                        height: "10.54",
                        left: "207.7",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "270.27",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "284.85",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "23.33",
                        height: "10.54",
                        left: "311.35",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "339.18",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "more",
                        width: "24.65",
                        height: "10.54",
                        left: "355.68",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "than",
                        width: "20.66",
                        height: "10.54",
                        left: "384.83",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "60",
                        width: "12",
                        height: "10.54",
                        left: "409.99",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "days",
                        width: "22",
                        height: "10.54",
                        left: "426.49",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "after",
                        width: "21.98",
                        height: "10.54",
                        left: "452.99",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "479.47",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disputed",
                        width: "40.66",
                        height: "10.54",
                        left: "498.63",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "transaction",
                        width: "52.65",
                        height: "10.54",
                        left: "72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "appeared",
                        width: "43.3",
                        height: "10.54",
                        left: "131.4",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "181.45",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "200.2",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "23.33",
                        height: "10.54",
                        left: "228.94",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "259.02",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "statement.",
                        width: "48.99",
                        height: "10.54",
                        left: "303.08",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Please",
                        width: "30.65",
                        height: "10.54",
                        left: "358.82",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contact",
                        width: "34.65",
                        height: "10.54",
                        left: "396.22",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "437.62",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "23.32",
                        height: "10.54",
                        left: "465.62",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "494.94",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "more",
                        width: "24.66",
                        height: "10.54",
                        left: "514.93",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.55",
                        left: "72",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.55",
                        left: "132.4",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "162.81",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "policies",
                        width: "37.33",
                        height: "10.55",
                        left: "181.22",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "221.55",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "procedures",
                        width: "52.64",
                        height: "10.55",
                        left: "241.88",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.55",
                        left: "297.52",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "apply",
                        width: "26.66",
                        height: "10.55",
                        left: "318.51",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unauthorized",
                        width: "62.64",
                        height: "10.55",
                        left: "348.17",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transactions",
                        width: "57.32",
                        height: "10.55",
                        left: "413.81",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "474.13",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "494.46",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limits",
                        width: "27.34",
                        height: "10.55",
                        left: "512.12",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "72",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "87",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liability.",
                        width: "39.55",
                        height: "10.54",
                        left: "112",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Marketplace",
                        width: "65.31",
                        height: "10.54",
                        left: "72",
                        top: "622.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "45.32",
                        height: "10.54",
                        left: "140.31",
                        top: "622.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Disputes",
                        width: "44.01",
                        height: "10.54",
                        left: "188.63",
                        top: "622.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "All",
                        width: "15.33",
                        height: "10.54",
                        left: "72",
                        top: "594.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "requests",
                        width: "39.33",
                        height: "10.54",
                        left: "94.83",
                        top: "594.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "141.66",
                        top: "594.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.96",
                        height: "10.54",
                        left: "163.15",
                        top: "594.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "42",
                        height: "10.54",
                        left: "230.61",
                        top: "594.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "chargebacks,",
                        width: "62.08",
                        height: "10.54",
                        left: "280.11",
                        top: "594.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "errors,",
                        width: "30.98",
                        height: "10.54",
                        left: "349.69",
                        top: "594.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claims,",
                        width: "34.33",
                        height: "10.54",
                        left: "388.17",
                        top: "594.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "refunds,",
                        width: "38.98",
                        height: "10.54",
                        left: "430",
                        top: "594.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "476.48",
                        top: "594.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disputes",
                        width: "39.33",
                        height: "10.54",
                        left: "500.56",
                        top: "594.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "(“",
                        width: "9.32",
                        height: "10.54",
                        left: "72",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "45.32",
                        height: "10.54",
                        left: "81.32",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Disputes",
                        width: "44.01",
                        height: "10.54",
                        left: "130.39",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”)",
                        width: "9.33",
                        height: "10.54",
                        left: "174.4",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "187.48",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "209.89",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "subject",
                        width: "33.99",
                        height: "10.54",
                        left: "224.97",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "262.71",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "review",
                        width: "32.65",
                        height: "10.54",
                        left: "275.79",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "312.19",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "32",
                        height: "10.54",
                        left: "327.94",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "35.99",
                        height: "10.54",
                        left: "363.69",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "403.43",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accordance",
                        width: "53.96",
                        height: "10.54",
                        left: "416.51",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "474.22",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "498.55",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rules",
                        width: "23.33",
                        height: "10.54",
                        left: "516.21",
                        top: "580.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "applicable",
                        width: "49.31",
                        height: "10.54",
                        left: "72",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "126.56",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "140.39",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "159.55",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "method",
                        width: "36",
                        height: "10.54",
                        left: "205.37",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "245.87",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "used",
                        width: "21.99",
                        height: "10.54",
                        left: "268.37",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "294.86",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.98",
                        height: "10.54",
                        left: "308.7",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "339.18",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.97",
                        height: "10.54",
                        left: "358.34",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "41.99",
                        height: "10.54",
                        left: "422.81",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "469.3",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "491.13",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "514.29",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "530.12",
                        top: "567.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "72",
                        top: "553.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics’",
                        width: "39.99",
                        height: "10.54",
                        left: "108.49",
                        top: "553.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "152.98",
                        top: "553.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "176.81",
                        top: "553.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "absolute",
                        width: "39.99",
                        height: "10.54",
                        left: "198.64",
                        top: "553.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion.",
                        width: "50.32",
                        height: "10.54",
                        left: "243.13",
                        top: "553.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "297.2",
                        top: "553.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "316.64",
                        top: "553.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "335.04",
                        top: "553.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liable",
                        width: "26.66",
                        height: "10.54",
                        left: "354.12",
                        top: "553.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "384.53",
                        top: "553.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "397.61",
                        top: "553.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "419.36",
                        top: "553.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "450.43",
                        top: "553.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "circumstances",
                        width: "67.97",
                        height: "10.54",
                        left: "471.51",
                        top: "553.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.55",
                        left: "72",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "42",
                        height: "10.55",
                        left: "90.49",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Disputes",
                        width: "42",
                        height: "10.55",
                        left: "136.99",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.55",
                        left: "183.49",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.55",
                        left: "201.98",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unable",
                        width: "31.98",
                        height: "10.55",
                        left: "220.38",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.55",
                        left: "256.11",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "resolve",
                        width: "34.65",
                        height: "10.55",
                        left: "269.2",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.55",
                        left: "307.6",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "320.68",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "favor.",
                        width: "27.66",
                        height: "10.55",
                        left: "346.43",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.55",
                        left: "377.84",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.55",
                        left: "397.28",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "normally",
                        width: "43.32",
                        height: "10.55",
                        left: "419.7",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "process",
                        width: "35.99",
                        height: "10.55",
                        left: "466.77",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.55",
                        left: "506.51",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "valid",
                        width: "24",
                        height: "10.55",
                        left: "515.58",
                        top: "539.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "written",
                        width: "33.99",
                        height: "10.54",
                        left: "72",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "41.99",
                        height: "10.54",
                        left: "110.49",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Dispute",
                        width: "37.33",
                        height: "10.54",
                        left: "156.98",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "request",
                        width: "34.66",
                        height: "10.54",
                        left: "198.81",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "within",
                        width: "30.66",
                        height: "10.54",
                        left: "237.97",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "30",
                        width: "12",
                        height: "10.54",
                        left: "273.13",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "days",
                        width: "22",
                        height: "10.54",
                        left: "289.63",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "after",
                        width: "21.98",
                        height: "10.54",
                        left: "316.13",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "342.61",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "receive",
                        width: "34.64",
                        height: "10.54",
                        left: "361.1",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it,",
                        width: "9.66",
                        height: "10.54",
                        left: "400.24",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unless",
                        width: "30",
                        height: "10.54",
                        left: "414.4",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "448.9",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shorter",
                        width: "33.32",
                        height: "10.54",
                        left: "458.73",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "period",
                        width: "30.66",
                        height: "10.54",
                        left: "496.55",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "531.71",
                        top: "525.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "required",
                        width: "39.98",
                        height: "10.54",
                        left: "72",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "120.98",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law.",
                        width: "19.55",
                        height: "10.54",
                        left: "141.23",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "169.03",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "196.74",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "file",
                        width: "15.99",
                        height: "10.54",
                        left: "225.65",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "249.89",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "41.99",
                        height: "10.54",
                        left: "263.47",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Dispute",
                        width: "37.33",
                        height: "10.54",
                        left: "313.71",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "359.29",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "emailing",
                        width: "41.99",
                        height: "10.54",
                        left: "379.54",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "6.67",
                        height: "10.54",
                        left: "429.78",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "444.7",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "462.28",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "support",
                        width: "36",
                        height: "10.54",
                        left: "486.53",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.6600000000001",
                        height: "10.54",
                        left: "530.78",
                        top: "511.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "admin@mooreexotics.com",
                        width: "129.35",
                        height: "10.54",
                        left: "72",
                        top: "498.02",
                        color: "#1154cc",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                        link: "mailto:admin@mooreexotics.com",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "205.1",
                        top: "498.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "211.85",
                        top: "498.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "223.59",
                        top: "498.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "close",
                        width: "24.66",
                        height: "10.54",
                        left: "245.34",
                        top: "498.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "273.75",
                        top: "498.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "deactivate",
                        width: "48.63",
                        height: "10.54",
                        left: "287.5",
                        top: "498.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "339.88",
                        top: "498.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "365.63",
                        top: "498.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "406.69",
                        top: "498.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cancel",
                        width: "30.64",
                        height: "10.54",
                        left: "420.43",
                        top: "498.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "454.07",
                        top: "498.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscription",
                        width: "60.67",
                        height: "10.54",
                        left: "479.07",
                        top: "498.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "before",
                        width: "30.64",
                        height: "10.54",
                        left: "72",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "107.14",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "adjudicate",
                        width: "49.3",
                        height: "10.54",
                        left: "125.64",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "179.44",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "41.99",
                        height: "10.54",
                        left: "205.94",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Dispute,",
                        width: "40.33",
                        height: "10.54",
                        left: "251.68",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "295.76",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "313.51",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "335.92",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "355.01",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "able",
                        width: "19.99",
                        height: "10.54",
                        left: "370.08",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "393.82",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "issue",
                        width: "24",
                        height: "10.54",
                        left: "406.9",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "434.65",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "456.4",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amounts",
                        width: "40.66",
                        height: "10.54",
                        left: "477.48",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "521.89",
                        top: "484.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "72",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owed.",
                        width: "28.99",
                        height: "10.54",
                        left: "91.15",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "124.64",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "144.83",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "attempt",
                        width: "35.99",
                        height: "10.54",
                        left: "168",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "208.49",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pay",
                        width: "17.33",
                        height: "10.54",
                        left: "222.32",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "244.15",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "266.65",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "42",
                        height: "10.54",
                        left: "287.72",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Disputed",
                        width: "43.33",
                        height: "10.54",
                        left: "333.47",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amounts",
                        width: "40.66",
                        height: "10.54",
                        left: "380.55",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "424.96",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "446.71",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owed",
                        width: "25.99",
                        height: "10.54",
                        left: "465.11",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "using",
                        width: "26.01",
                        height: "10.54",
                        left: "494.85",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.61",
                        top: "470.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "method",
                        width: "35.99",
                        height: "10.54",
                        left: "72",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "112.49",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "29.32",
                        height: "10.54",
                        left: "138.33",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "172.15",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made",
                        width: "25.99",
                        height: "10.54",
                        left: "194.65",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "225.14",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disputed",
                        width: "40.66",
                        height: "10.54",
                        left: "244.3",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment,",
                        width: "44.33",
                        height: "10.54",
                        left: "289.46",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "but",
                        width: "15.33",
                        height: "10.54",
                        left: "338.29",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "358.12",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cannot",
                        width: "31.99",
                        height: "10.54",
                        left: "376.61",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "guarantee",
                        width: "46.63",
                        height: "10.54",
                        left: "413.1",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "464.23",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "486.73",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "505.22",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "528.39",
                        top: "456.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "able",
                        width: "19.99",
                        height: "10.54",
                        left: "72",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "98.74",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "114.82",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "so",
                        width: "10.67",
                        height: "10.54",
                        left: "133.57",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "150.99",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "164.32",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "192.32",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "method",
                        width: "35.99",
                        height: "10.54",
                        left: "239.64",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "281.63",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "344.29",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inaccurate,",
                        width: "52.29",
                        height: "10.54",
                        left: "358.29",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "incomplete,",
                        width: "56.32",
                        height: "10.54",
                        left: "416.58",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "478.9",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "has",
                        width: "16",
                        height: "10.54",
                        left: "494.89",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "been",
                        width: "22.65",
                        height: "10.54",
                        left: "516.89",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "canceled.",
                        width: "44.96",
                        height: "10.54",
                        left: "72",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.55",
                        left: "72",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "83.74",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "actions",
                        width: "33.99",
                        height: "10.55",
                        left: "109.49",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "result",
                        width: "26.66",
                        height: "10.55",
                        left: "147.23",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in,",
                        width: "12.33",
                        height: "10.55",
                        left: "177.64",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "193.72",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.55",
                        left: "207.47",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "likely",
                        width: "27.33",
                        height: "10.55",
                        left: "225.87",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "256.95",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "result",
                        width: "26.66",
                        height: "10.55",
                        left: "270.03",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in,",
                        width: "12.33",
                        height: "10.55",
                        left: "300.44",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.55",
                        left: "316.52",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "41.99",
                        height: "10.55",
                        left: "325.6",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Dispute,",
                        width: "40.33",
                        height: "10.55",
                        left: "371.34",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.55",
                        left: "415.42",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "violation",
                        width: "42.66",
                        height: "10.55",
                        left: "423.75",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "469.41",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.55",
                        left: "482.41",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.55",
                        left: "510.06",
                        top: "400.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use,",
                        width: "21.66",
                        height: "10.54",
                        left: "85.75",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "111.16",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "124.15",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "creation",
                        width: "38.65",
                        height: "10.54",
                        left: "141.81",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "183.46",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "196.45",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "risks",
                        width: "22.67",
                        height: "10.54",
                        left: "224.11",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "249.78",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "262.11",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "275.78",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "288.78",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "307.78",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "processing",
                        width: "51.32",
                        height: "10.54",
                        left: "352.1",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "partners,",
                        width: "41.65",
                        height: "10.54",
                        left: "406.42",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "451.07",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "464.06",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "474.39",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "determine",
                        width: "47.98",
                        height: "10.54",
                        left: "491.38",
                        top: "386.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "72",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "94.49",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "120.99",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "has",
                        width: "16",
                        height: "10.54",
                        left: "162.8",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "been",
                        width: "22.65",
                        height: "10.54",
                        left: "183.3",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "used",
                        width: "22",
                        height: "10.54",
                        left: "210.45",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "236.95",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "engage",
                        width: "33.98",
                        height: "10.54",
                        left: "250.78",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "289.26",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "deceptive,",
                        width: "48.98",
                        height: "10.54",
                        left: "303.09",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fraudulent,",
                        width: "52.31",
                        height: "10.54",
                        left: "355.82",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "411.88",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "illegal",
                        width: "29.98",
                        height: "10.54",
                        left: "425.63",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activity,",
                        width: "38.21",
                        height: "10.54",
                        left: "459.36",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "then",
                        width: "20.66",
                        height: "10.54",
                        left: "501.32",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "525.73",
                        top: "372.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "72",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "permanently",
                        width: "59.98",
                        height: "10.54",
                        left: "97.16",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "withhold",
                        width: "42.66",
                        height: "10.54",
                        left: "161.64",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "208.8",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amounts",
                        width: "40.66",
                        height: "10.54",
                        left: "230.63",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owed",
                        width: "26",
                        height: "10.54",
                        left: "275.79",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "306.29",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "319.37",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "341.12",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "354.21",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "373.95",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion.",
                        width: "50.32",
                        height: "10.54",
                        left: "397.03",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "451.1",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "462.84",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.66",
                        height: "10.54",
                        left: "484.59",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.3200000000001",
                        height: "10.54",
                        left: "511",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "past",
                        width: "19.33",
                        height: "10.54",
                        left: "520.07",
                        top: "358.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "due",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "balance",
                        width: "36.63",
                        height: "10.54",
                        left: "92.33",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "due",
                        width: "17.33",
                        height: "10.54",
                        left: "131.96",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "152.29",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "167.29",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account,",
                        width: "40.31",
                        height: "10.54",
                        left: "187.62",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "230.93",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "243.93",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "260.92",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "additional",
                        width: "47.99",
                        height: "10.54",
                        left: "281.24",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amounts",
                        width: "40.67",
                        height: "10.54",
                        left: "332.23",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "375.9",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "396.89",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "determine",
                        width: "47.98",
                        height: "10.54",
                        left: "413.88",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "464.86",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owe",
                        width: "19.99",
                        height: "10.54",
                        left: "485.86",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us,",
                        width: "13.67",
                        height: "10.54",
                        left: "508.85",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "525.52",
                        top: "345.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "may,",
                        width: "22.88",
                        height: "10.54",
                        left: "72",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.67",
                        height: "10.54",
                        left: "100.13",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limiting",
                        width: "38",
                        height: "10.54",
                        left: "142.05",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "185.3",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "207.88",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights",
                        width: "27.33",
                        height: "10.54",
                        left: "237.79",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "270.37",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "remedies:",
                        width: "46.64",
                        height: "10.54",
                        left: "285.62",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(a)",
                        width: "13.32",
                        height: "10.54",
                        left: "337.51",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "charge",
                        width: "31.76",
                        height: "10.54",
                        left: "355.33",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "one",
                        width: "17.32",
                        height: "10.54",
                        left: "391.59",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "413.41",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "more",
                        width: "24.66",
                        height: "10.54",
                        left: "427.91",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "457.07",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "471.56",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "498.06",
                        top: "331.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "methods;",
                        width: "44",
                        height: "10.54",
                        left: "72",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(b)",
                        width: "13.99",
                        height: "10.54",
                        left: "119.75",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offset",
                        width: "27.11",
                        height: "10.54",
                        left: "137.49",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "168.35",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amounts",
                        width: "40.67",
                        height: "10.54",
                        left: "189.42",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "233.84",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owe",
                        width: "19.99",
                        height: "10.54",
                        left: "255.59",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "279.33",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "against",
                        width: "33.99",
                        height: "10.54",
                        left: "293.75",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amounts",
                        width: "40.66",
                        height: "10.54",
                        left: "331.49",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "375.9",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "396.9",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "420.56",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owed;",
                        width: "29.32",
                        height: "10.54",
                        left: "434.89",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(c)",
                        width: "13.32",
                        height: "10.54",
                        left: "467.21",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "invoice",
                        width: "35.32",
                        height: "10.54",
                        left: "483.53",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "521.85",
                        top: "317.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "72",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amounts",
                        width: "40.67",
                        height: "10.54",
                        left: "90.49",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "due",
                        width: "17.32",
                        height: "10.54",
                        left: "135.66",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "157.48",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us,",
                        width: "13.67",
                        height: "10.54",
                        left: "171.32",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "29.32",
                        height: "10.54",
                        left: "189.49",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "223.31",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amounts",
                        width: "40.66",
                        height: "10.54",
                        left: "249.81",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "294.97",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "318.14",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "due",
                        width: "17.32",
                        height: "10.54",
                        left: "333.22",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "upon",
                        width: "24",
                        height: "10.54",
                        left: "354.29",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "receipt;",
                        width: "35.98",
                        height: "10.54",
                        left: "382.04",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(d)",
                        width: "13.99",
                        height: "10.54",
                        left: "421.77",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reverse",
                        width: "34.64",
                        height: "10.54",
                        left: "439.51",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "477.9",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "block",
                        width: "26.66",
                        height: "10.54",
                        left: "491.65",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "522.06",
                        top: "303.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "credits",
                        width: "31.99",
                        height: "10.54",
                        left: "72",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "106.99",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "119.32",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "23.32",
                        height: "10.54",
                        left: "144.32",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account;",
                        width: "40.65",
                        height: "10.54",
                        left: "170.64",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "214.29",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(e)",
                        width: "13.31",
                        height: "10.54",
                        left: "227.29",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "243.6",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "278.58",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "322.9",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "349.23",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "370.23",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "385.23",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "405.56",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "lawful",
                        width: "30.66",
                        height: "10.54",
                        left: "433.21",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "means.",
                        width: "33.66",
                        height: "10.54",
                        left: "466.87",
                        top: "289.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "72",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "83.74",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fail",
                        width: "15.99",
                        height: "10.54",
                        left: "105.49",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "125.23",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.98",
                        height: "10.54",
                        left: "138.32",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Marketplace",
                        width: "59.97",
                        height: "10.54",
                        left: "168.05",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payments",
                        width: "46.66",
                        height: "10.54",
                        left: "231.77",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "282.18",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owe",
                        width: "19.99",
                        height: "10.54",
                        left: "303.93",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "26",
                        height: "10.54",
                        left: "327.67",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "due,",
                        width: "20.32",
                        height: "10.54",
                        left: "357.42",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "381.49",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "395.24",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "405.57",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "422.56",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unable",
                        width: "31.99",
                        height: "10.54",
                        left: "440.21",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "475.2",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "charge",
                        width: "31.76",
                        height: "10.54",
                        left: "487.53",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "one",
                        width: "17.32",
                        height: "10.54",
                        left: "522.29",
                        top: "261.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "85.75",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "111.49",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "methods",
                        width: "40.67",
                        height: "10.54",
                        left: "156.56",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "200.98",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "218.72",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reason,",
                        width: "34.32",
                        height: "10.54",
                        left: "239.79",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "277.86",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reserve",
                        width: "34.64",
                        height: "10.54",
                        left: "295.61",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "334",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights",
                        width: "27.33",
                        height: "10.54",
                        left: "349.74",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "permissible",
                        width: "55.33",
                        height: "10.54",
                        left: "380.82",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "439.9",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law",
                        width: "17.33",
                        height: "10.54",
                        left: "470.97",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "491.3",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "recover",
                        width: "35.97",
                        height: "10.54",
                        left: "503.63",
                        top: "247.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "72",
                        top: "233.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "118.57",
                        top: "233.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "141.15",
                        top: "233.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "costs",
                        width: "24",
                        height: "10.54",
                        left: "158.39",
                        top: "233.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "187.64",
                        top: "233.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "expenses",
                        width: "43.32",
                        height: "10.54",
                        left: "209.47",
                        top: "233.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "incurred,",
                        width: "42.97",
                        height: "10.54",
                        left: "257.29",
                        top: "233.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "304.76",
                        top: "233.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reasonable",
                        width: "51.31",
                        height: "10.54",
                        left: "354.59",
                        top: "233.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "attorneys'",
                        width: "46.14",
                        height: "10.54",
                        left: "410.4",
                        top: "233.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees",
                        width: "19.32",
                        height: "10.54",
                        left: "461.04",
                        top: "233.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "484.86",
                        top: "233.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "related",
                        width: "32.64",
                        height: "10.54",
                        left: "506.69",
                        top: "233.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "costs,",
                        width: "27",
                        height: "10.54",
                        left: "72",
                        top: "219.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "105",
                        top: "219.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "120.33",
                        top: "219.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pursuit",
                        width: "33.33",
                        height: "10.54",
                        left: "142.33",
                        top: "219.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "181.66",
                        top: "219.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment.",
                        width: "44.32",
                        height: "10.54",
                        left: "197.66",
                        top: "219.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "247.23",
                        top: "219.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "explicitly",
                        width: "45.32",
                        height: "10.54",
                        left: "271.95",
                        top: "219.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "322.52",
                        top: "219.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "353.74",
                        top: "219.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "376.99",
                        top: "219.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "communication",
                        width: "74.65",
                        height: "10.54",
                        left: "394.23",
                        top: "219.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "474.13",
                        top: "219.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "relation",
                        width: "36.65",
                        height: "10.54",
                        left: "488.71",
                        top: "219.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "530.61",
                        top: "219.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "delinquent",
                        width: "50.65",
                        height: "10.54",
                        left: "72",
                        top: "205.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accounts",
                        width: "41.99",
                        height: "10.54",
                        left: "127.9",
                        top: "205.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "175.14",
                        top: "205.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "overdue",
                        width: "38.65",
                        height: "10.54",
                        left: "190.38",
                        top: "205.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payments",
                        width: "45.99",
                        height: "10.54",
                        left: "233.53",
                        top: "205.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "284.02",
                        top: "205.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "307.19",
                        top: "205.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made",
                        width: "25.98",
                        height: "10.54",
                        left: "323.02",
                        top: "205.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "353.5",
                        top: "205.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "electronic",
                        width: "47.3",
                        height: "10.54",
                        left: "370",
                        top: "205.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "mail",
                        width: "21.33",
                        height: "10.54",
                        left: "421.8",
                        top: "205.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "447.63",
                        top: "205.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "462.13",
                        top: "205.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "phone.",
                        width: "32.33",
                        height: "10.54",
                        left: "478.63",
                        top: "205.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Such",
                        width: "24",
                        height: "10.54",
                        left: "515.46",
                        top: "205.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "communication",
                        width: "74.65",
                        height: "10.54",
                        left: "72",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "151.15",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "176.31",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made",
                        width: "25.99",
                        height: "10.54",
                        left: "192.13",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "222.62",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "239.12",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "254.29",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "268.79",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "anyone",
                        width: "34.65",
                        height: "10.54",
                        left: "285.29",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "323.69",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "339.44",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "behalf,",
                        width: "32.98",
                        height: "10.54",
                        left: "359.19",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "395.92",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "but",
                        width: "15.33",
                        height: "10.54",
                        left: "445",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "464.08",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limited",
                        width: "34",
                        height: "10.54",
                        left: "483.16",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "520.91",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "533.99",
                        top: "192.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "third­party",
                        width: "51.32",
                        height: "10.54",
                        left: "72",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collections",
                        width: "51.98",
                        height: "10.54",
                        left: "126.32",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agent.",
                        width: "28.99",
                        height: "10.54",
                        left: "181.3",
                        top: "178.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may,",
                        width: "22.88",
                        height: "10.54",
                        left: "92.19",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "119.57",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "147.4",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "173.23",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time,",
                        width: "24.33",
                        height: "10.54",
                        left: "187.06",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "place",
                        width: "25.31",
                        height: "10.54",
                        left: "215.89",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "245.7",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "hold",
                        width: "21.33",
                        height: "10.54",
                        left: "255.53",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "281.36",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "297.86",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "319.69",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "333.43",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "359.18",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "methods",
                        width: "40.66",
                        height: "10.54",
                        left: "404.25",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "448.66",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "order",
                        width: "25.32",
                        height: "10.54",
                        left: "461.75",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "490.82",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prevent",
                        width: "35.98",
                        height: "10.54",
                        left: "503.9",
                        top: "149.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "failed",
                        width: "27.32",
                        height: "10.55",
                        left: "72",
                        top: "135.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payments",
                        width: "45.99",
                        height: "10.55",
                        left: "102.32",
                        top: "135.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.55",
                        left: "151.31",
                        top: "135.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.55",
                        left: "164.3",
                        top: "135.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "order",
                        width: "25.32",
                        height: "10.55",
                        left: "176.64",
                        top: "135.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "204.96",
                        top: "135.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reverse",
                        width: "34.64",
                        height: "10.55",
                        left: "217.29",
                        top: "135.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "254.93",
                        top: "135.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "booking.",
                        width: "42.33",
                        height: "10.55",
                        left: "279.93",
                        top: "135.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Stored",
                        width: "33.78",
                        height: "10.54",
                        left: "72",
                        top: "106.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payments",
                        width: "49.99",
                        height: "10.54",
                        left: "108.78",
                        top: "106.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "By",
                        width: "14",
                        height: "10.54",
                        left: "72",
                        top: "79.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providing",
                        width: "46.67",
                        height: "10.54",
                        left: "90.5",
                        top: "79.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "141.67",
                        top: "79.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "168.16",
                        top: "79.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "method",
                        width: "36",
                        height: "10.54",
                        left: "213.98",
                        top: "79.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "254.48",
                        top: "79.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "315.63",
                        top: "79.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "329.47",
                        top: "79.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics,",
                        width: "38.99",
                        height: "10.54",
                        left: "365.96",
                        top: "79.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "409.45",
                        top: "79.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "consent",
                        width: "36.66",
                        height: "10.54",
                        left: "431.95",
                        top: "79.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "473.11",
                        top: "79.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "486.19",
                        top: "79.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "storing",
                        width: "33.33",
                        height: "10.54",
                        left: "505.94",
                        top: "79.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,121",
                    "lineto,540,121",
                    "lineto,540,92",
                    "lineto,72,92",
                    "lineto,72,121",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,623.5", "lineto,233,623.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#1154cc",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,499.5", "lineto,201,499.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,108.5", "lineto,159,108.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "13",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "97.75",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "method",
                        width: "35.99",
                        height: "10.54",
                        left: "142.82",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "182.56",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(“",
                        width: "9.33",
                        height: "10.54",
                        left: "242.96",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Stored",
                        width: "33.78",
                        height: "10.54",
                        left: "252.29",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "45.32",
                        height: "10.54",
                        left: "289.82",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Method",
                        width: "39.99",
                        height: "10.54",
                        left: "338.89",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”).",
                        width: "12.33",
                        height: "10.54",
                        left: "378.88",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.54",
                        left: "394.96",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Stored",
                        width: "31.33",
                        height: "10.54",
                        left: "422.17",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "41.99",
                        height: "10.54",
                        left: "457.25",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Method",
                        width: "37.33",
                        height: "10.54",
                        left: "502.24",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "only",
                        width: "21.33",
                        height: "10.54",
                        left: "94.42",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "118.75",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "used,",
                        width: "24.99",
                        height: "10.54",
                        left: "133.08",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "stored,",
                        width: "32.33",
                        height: "10.54",
                        left: "161.07",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "196.4",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclosed",
                        width: "44.66",
                        height: "10.54",
                        left: "216.73",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "264.39",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accordance",
                        width: "53.95",
                        height: "10.54",
                        left: "276.72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "333.67",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "358.01",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "385.66",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "418.48",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "431.48",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "453.14",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "473.47",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRIVACY",
                        width: "47.11",
                        height: "10.54",
                        left: "492.46",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "POLICY",
                        width: "41.34",
                        height: "10.54",
                        left: "72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ",",
                        width: "3",
                        height: "10.54",
                        left: "113.34",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "119.34",
                        top: "678.6",
                        color: "#2e2f33",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "132.34",
                        top: "678.6",
                        color: "#2e2f33",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "145.34",
                        top: "678.6",
                        color: "#2e2f33",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agreed",
                        width: "31.97",
                        height: "10.54",
                        left: "194.99",
                        top: "678.6",
                        color: "#2e2f33",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "229.96",
                        top: "678.6",
                        color: "#2e2f33",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "242.3",
                        top: "678.6",
                        color: "#2e2f33",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you.",
                        width: "21",
                        height: "10.54",
                        left: "257.3",
                        top: "678.6",
                        color: "#2e2f33",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Additional",
                        width: "54.69",
                        height: "10.55",
                        left: "72",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "32.22",
                        height: "10.55",
                        left: "129.69",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.34",
                        height: "10.55",
                        left: "164.91",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Conditions",
                        width: "56.03",
                        height: "10.55",
                        left: "187.25",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Additional",
                        width: "51.33",
                        height: "10.54",
                        left: "72",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "127.08",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "157.49",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "conditions",
                        width: "50",
                        height: "10.54",
                        left: "178.56",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "232.31",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "19.33",
                        height: "10.54",
                        left: "256.72",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "apply",
                        width: "26.66",
                        height: "10.54",
                        left: "279.8",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "310.21",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "specific",
                        width: "37.31",
                        height: "10.54",
                        left: "323.3",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "portions,",
                        width: "42.33",
                        height: "10.54",
                        left: "364.36",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services,",
                        width: "41.65",
                        height: "10.54",
                        left: "410.44",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "455.84",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "features",
                        width: "37.97",
                        height: "10.54",
                        left: "469.59",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.9999999999999",
                        height: "10.54",
                        left: "511.31",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.06",
                        top: "623.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "72",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.32",
                        height: "10.54",
                        left: "122.36",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Subscriptions.",
                        width: "68.34",
                        height: "10.54",
                        left: "176.68",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "All",
                        width: "15.34",
                        height: "10.54",
                        left: "254.02",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "278.36",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "additional",
                        width: "47.99",
                        height: "10.54",
                        left: "309.35",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "365.59",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "400.5",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "conditions",
                        width: "49.99",
                        height: "10.54",
                        left: "426.08",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "484.32",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "hereby",
                        width: "32.65",
                        height: "10.54",
                        left: "507.22",
                        top: "609.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "incorporated",
                        width: "60.64",
                        height: "10.54",
                        left: "72",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "135.64",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "150.64",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reference",
                        width: "44.62",
                        height: "10.54",
                        left: "170.98",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "into",
                        width: "18.66",
                        height: "10.54",
                        left: "218.6",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "240.26",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "267.92",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "300.74",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use.",
                        width: "21.67",
                        height: "10.54",
                        left: "313.73",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Linking",
                        width: "40.69",
                        height: "10.54",
                        left: "72",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "115.69",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "128.69",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "40.66",
                        height: "10.54",
                        left: "147.69",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "191.35",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Social",
                        width: "30.67",
                        height: "10.54",
                        left: "213.7",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Media",
                        width: "32.66",
                        height: "10.54",
                        left: "247.37",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Features",
                        width: "44.43",
                        height: "10.54",
                        left: "283.03",
                        top: "567.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.67",
                        height: "10.54",
                        left: "94.46",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "link",
                        width: "18.66",
                        height: "10.54",
                        left: "118.13",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "139.79",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "152.13",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "homepage,",
                        width: "52.32",
                        height: "10.54",
                        left: "171.12",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided",
                        width: "42.65",
                        height: "10.54",
                        left: "226.44",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "272.09",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "293.09",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "so",
                        width: "10.67",
                        height: "10.54",
                        left: "308.09",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "321.76",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "334.1",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "way",
                        width: "19.99",
                        height: "10.54",
                        left: "342.42",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "365.41",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "386.41",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fair",
                        width: "16.65",
                        height: "10.54",
                        left: "397.41",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "417.06",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "legal",
                        width: "23.32",
                        height: "10.54",
                        left: "437.39",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "463.71",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "does",
                        width: "21.99",
                        height: "10.54",
                        left: "484.04",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "509.03",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "damage",
                        width: "37.31",
                        height: "10.54",
                        left: "72",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "112.31",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reputation",
                        width: "48.65",
                        height: "10.54",
                        left: "131.31",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "182.96",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "take",
                        width: "19.98",
                        height: "10.54",
                        left: "195.96",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advantage",
                        width: "48.64",
                        height: "10.54",
                        left: "218.94",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "270.58",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it,",
                        width: "9.66",
                        height: "10.54",
                        left: "283.58",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "but",
                        width: "15.34",
                        height: "10.54",
                        left: "296.24",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "314.58",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.34",
                        height: "10.54",
                        left: "335.58",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "361.92",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "establish",
                        width: "41.99",
                        height: "10.54",
                        left: "380.25",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "425.24",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "link",
                        width: "18.67",
                        height: "10.54",
                        left: "433.57",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "455.24",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "467.57",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "492.57",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "way",
                        width: "20",
                        height: "10.54",
                        left: "500.89",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "523.89",
                        top: "526.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "72",
                        top: "512.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "suggest",
                        width: "36",
                        height: "10.54",
                        left: "84.33",
                        top: "512.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "123.33",
                        top: "512.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "form",
                        width: "23.33",
                        height: "10.54",
                        left: "143.66",
                        top: "512.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "169.99",
                        top: "512.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "association,",
                        width: "56.32",
                        height: "10.54",
                        left: "182.98",
                        top: "512.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "approval,",
                        width: "44.99",
                        height: "10.54",
                        left: "242.3",
                        top: "512.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "290.29",
                        top: "512.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "endorsement",
                        width: "61.31",
                        height: "10.54",
                        left: "303.28",
                        top: "512.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "367.59",
                        top: "512.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "382.59",
                        top: "512.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "part,",
                        width: "21.66",
                        height: "10.54",
                        left: "401.59",
                        top: "512.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.66",
                        height: "10.54",
                        left: "426.25",
                        top: "512.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "465.91",
                        top: "512.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "express",
                        width: "35.99",
                        height: "10.54",
                        left: "484.91",
                        top: "512.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "written",
                        width: "33.99",
                        height: "10.55",
                        left: "72",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "consent.",
                        width: "39.66",
                        height: "10.55",
                        left: "108.99",
                        top: "498.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "This",
                        width: "21.33",
                        height: "10.54",
                        left: "72",
                        top: "470.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "96.33",
                        top: "470.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "137.69",
                        top: "470.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "161.35",
                        top: "470.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "201.01",
                        top: "470.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "social",
                        width: "27.99",
                        height: "10.54",
                        left: "236.65",
                        top: "470.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "media",
                        width: "29.32",
                        height: "10.54",
                        left: "267.64",
                        top: "470.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "features",
                        width: "37.97",
                        height: "10.54",
                        left: "299.96",
                        top: "470.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "340.93",
                        top: "470.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enable",
                        width: "31.31",
                        height: "10.54",
                        left: "361.93",
                        top: "470.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "396.24",
                        top: "470.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to:",
                        width: "12.67",
                        height: "10.54",
                        left: "417.24",
                        top: "470.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "443.29",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Link",
                        width: "22.66",
                        height: "10.54",
                        left: "108",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "133.66",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "159.99",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "own",
                        width: "20.66",
                        height: "10.54",
                        left: "184.99",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "208.65",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "221.65",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third­party",
                        width: "51.32",
                        height: "10.54",
                        left: "257.29",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "websites",
                        width: "41.32",
                        height: "10.54",
                        left: "311.61",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "355.93",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "368.27",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "403.91",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "442.23",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "457.23",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "477.57",
                        top: "442.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "429.49",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Send",
                        width: "24",
                        height: "10.54",
                        left: "108",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "emails",
                        width: "31.32",
                        height: "10.54",
                        left: "135",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "169.32",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "182.32",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "communications",
                        width: "79.31",
                        height: "10.54",
                        left: "209.98",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "292.29",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "316.63",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content,",
                        width: "38.32",
                        height: "10.54",
                        left: "352.27",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "393.59",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "links",
                        width: "23.34",
                        height: "10.54",
                        left: "406.59",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "432.93",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "445.26",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content,",
                        width: "38.32",
                        height: "10.54",
                        left: "480.9",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "522.22",
                        top: "429.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "108",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "128.34",
                        top: "415.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "401.9",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Cause",
                        width: "29.33",
                        height: "10.54",
                        left: "108",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limited",
                        width: "33.99",
                        height: "10.54",
                        left: "140.33",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "portions",
                        width: "39.34",
                        height: "10.54",
                        left: "177.32",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "219.66",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "232.65",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "270.97",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "285.97",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "306.31",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "347.67",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "360",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "displayed",
                        width: "45.99",
                        height: "10.54",
                        left: "374.33",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "423.32",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "appear",
                        width: "31.98",
                        height: "10.54",
                        left: "436.31",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "471.29",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "483.62",
                        top: "401.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "displayed",
                        width: "45.99",
                        height: "10.54",
                        left: "108",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "156.99",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "171.99",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "own",
                        width: "20.66",
                        height: "10.54",
                        left: "196.99",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "220.65",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "233.65",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third­party",
                        width: "51.32",
                        height: "10.54",
                        left: "269.29",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "websites.",
                        width: "44.32",
                        height: "10.54",
                        left: "323.61",
                        top: "387.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.67",
                        height: "10.54",
                        left: "94.46",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "118.13",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "137.12",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "features",
                        width: "37.97",
                        height: "10.54",
                        left: "164.78",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "solely",
                        width: "28.67",
                        height: "10.54",
                        left: "205.75",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "237.42",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "they",
                        width: "20.66",
                        height: "10.54",
                        left: "250.41",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "274.07",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided",
                        width: "42.66",
                        height: "10.54",
                        left: "291.72",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "337.38",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us,",
                        width: "13.67",
                        height: "10.54",
                        left: "352.38",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "solely",
                        width: "28.66",
                        height: "10.54",
                        left: "369.05",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "400.71",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respect",
                        width: "33.98",
                        height: "10.54",
                        left: "425.04",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "462.02",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "474.36",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "492.02",
                        top: "359.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "they",
                        width: "20.66",
                        height: "10.54",
                        left: "72",
                        top: "346.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "95.66",
                        top: "346.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "displayed",
                        width: "45.99",
                        height: "10.54",
                        left: "113.31",
                        top: "346.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with,",
                        width: "24.33",
                        height: "10.54",
                        left: "162.3",
                        top: "346.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "189.63",
                        top: "346.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "209.96",
                        top: "346.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "259.61",
                        top: "346.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accordance",
                        width: "53.95",
                        height: "10.54",
                        left: "271.95",
                        top: "346.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "328.9",
                        top: "346.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "353.23",
                        top: "346.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "additional",
                        width: "47.99",
                        height: "10.54",
                        left: "373.56",
                        top: "346.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "424.55",
                        top: "346.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "454.21",
                        top: "346.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "conditions",
                        width: "50",
                        height: "10.54",
                        left: "474.53",
                        top: "346.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.55",
                        left: "72",
                        top: "332.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.55",
                        left: "88.99",
                        top: "332.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.55",
                        left: "128.65",
                        top: "332.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respect",
                        width: "33.98",
                        height: "10.55",
                        left: "152.98",
                        top: "332.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "189.96",
                        top: "332.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.55",
                        left: "202.29",
                        top: "332.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "features.",
                        width: "40.98",
                        height: "10.55",
                        left: "227.29",
                        top: "332.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "90.69",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disable",
                        width: "33.99",
                        height: "10.54",
                        left: "114.35",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "151.34",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "166.34",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "179.33",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "social",
                        width: "27.99",
                        height: "10.54",
                        left: "199.66",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "media",
                        width: "29.32",
                        height: "10.54",
                        left: "230.65",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "features",
                        width: "37.97",
                        height: "10.54",
                        left: "262.97",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "303.94",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "324.27",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "links",
                        width: "23.33",
                        height: "10.54",
                        left: "344.6",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "370.93",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "382.59",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "402.92",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.67",
                        height: "10.54",
                        left: "427.25",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notice",
                        width: "29.32",
                        height: "10.54",
                        left: "466.92",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "499.24",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "511.57",
                        top: "304.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "discretion.",
                        width: "50.32",
                        height: "10.54",
                        left: "72",
                        top: "290.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "262.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.98",
                        height: "10.54",
                        left: "95.96",
                        top: "262.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "126.44",
                        top: "262.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cooperate",
                        width: "46.64",
                        height: "10.54",
                        left: "140.27",
                        top: "262.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "191.41",
                        top: "262.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "217.24",
                        top: "262.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "232.41",
                        top: "262.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "causing",
                        width: "36.65",
                        height: "10.54",
                        left: "245.5",
                        top: "262.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "285.9",
                        top: "262.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unauthorized",
                        width: "62.64",
                        height: "10.54",
                        left: "306.98",
                        top: "262.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "framing",
                        width: "37.99",
                        height: "10.54",
                        left: "373.37",
                        top: "262.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "415.11",
                        top: "262.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "linking",
                        width: "34.01",
                        height: "10.54",
                        left: "428.85",
                        top: "262.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "immediately",
                        width: "59.98",
                        height: "10.54",
                        left: "466.61",
                        top: "262.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.3299999999999",
                        height: "10.54",
                        left: "530.34",
                        top: "262.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "stop.",
                        width: "23",
                        height: "10.54",
                        left: "72",
                        top: "249.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.7",
                        height: "10.54",
                        left: "98",
                        top: "249.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reserve",
                        width: "34.64",
                        height: "10.54",
                        left: "116.7",
                        top: "249.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "154.34",
                        top: "249.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.66",
                        height: "10.54",
                        left: "172",
                        top: "249.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "197.66",
                        top: "249.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "withdraw",
                        width: "45.33",
                        height: "10.54",
                        left: "209.99",
                        top: "249.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "linking",
                        width: "34",
                        height: "10.54",
                        left: "258.32",
                        top: "249.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "permission",
                        width: "52.66",
                        height: "10.54",
                        left: "295.32",
                        top: "249.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.67",
                        height: "10.54",
                        left: "350.98",
                        top: "249.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notice.",
                        width: "32.32",
                        height: "10.54",
                        left: "390.65",
                        top: "249.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Third",
                        width: "30.01",
                        height: "10.54",
                        left: "72",
                        top: "221.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Party",
                        width: "28.65",
                        height: "10.54",
                        left: "105.01",
                        top: "221.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Sites",
                        width: "24",
                        height: "10.54",
                        left: "136.66",
                        top: "221.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "163.66",
                        top: "221.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Services",
                        width: "41.98",
                        height: "10.54",
                        left: "186.01",
                        top: "221.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "72",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "83.74",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "102.15",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contains",
                        width: "39.99",
                        height: "10.54",
                        left: "144.26",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "links",
                        width: "23.34",
                        height: "10.54",
                        left: "187.25",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "213.59",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "225.92",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sites",
                        width: "21.33",
                        height: "10.54",
                        left: "253.58",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "277.91",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "resources",
                        width: "45.31",
                        height: "10.54",
                        left: "298.24",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided",
                        width: "42.65",
                        height: "10.54",
                        left: "346.55",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "392.2",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "407.2",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties,",
                        width: "34.98",
                        height: "10.54",
                        left: "432.87",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "470.85",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "links",
                        width: "23.34",
                        height: "10.54",
                        left: "498.51",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "524.85",
                        top: "193.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "provided",
                        width: "42.66",
                        height: "10.54",
                        left: "72",
                        top: "179.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "119.16",
                        top: "179.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "137.65",
                        top: "179.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "convenience",
                        width: "59.97",
                        height: "10.54",
                        left: "164.14",
                        top: "179.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "only.",
                        width: "23.55",
                        height: "10.54",
                        left: "228.61",
                        top: "179.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "This",
                        width: "21.34",
                        height: "10.54",
                        left: "255.91",
                        top: "179.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "includes",
                        width: "39.99",
                        height: "10.54",
                        left: "281",
                        top: "179.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "links",
                        width: "23.34",
                        height: "10.54",
                        left: "324.74",
                        top: "179.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contained",
                        width: "46.64",
                        height: "10.54",
                        left: "351.83",
                        top: "179.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "402.22",
                        top: "179.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertisements,",
                        width: "74.97",
                        height: "10.54",
                        left: "415.31",
                        top: "179.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "494.03",
                        top: "179.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "banner",
                        width: "32.65",
                        height: "10.54",
                        left: "72",
                        top: "165.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertisements",
                        width: "71.98",
                        height: "10.54",
                        left: "109.15",
                        top: "165.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "185.63",
                        top: "165.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sponsored",
                        width: "48.66",
                        height: "10.54",
                        left: "206.7",
                        top: "165.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "links.",
                        width: "26.34",
                        height: "10.54",
                        left: "259.11",
                        top: "165.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "289.2",
                        top: "165.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "308.64",
                        top: "165.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "335.04",
                        top: "165.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "control",
                        width: "34",
                        height: "10.54",
                        left: "350.79",
                        top: "165.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "over",
                        width: "21.32",
                        height: "10.54",
                        left: "388.54",
                        top: "165.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "413.61",
                        top: "165.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contents",
                        width: "39.99",
                        height: "10.54",
                        left: "432.02",
                        top: "165.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "475.76",
                        top: "165.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "those",
                        width: "25.33",
                        height: "10.54",
                        left: "489.5",
                        top: "165.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sites",
                        width: "21.34",
                        height: "10.54",
                        left: "518.58",
                        top: "165.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "resources",
                        width: "45.31",
                        height: "10.54",
                        left: "85.75",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "134.81",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accept",
                        width: "30.64",
                        height: "10.54",
                        left: "155.88",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "190.27",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsibility",
                        width: "65.33",
                        height: "10.54",
                        left: "206.02",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.54",
                        left: "275.1",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "them",
                        width: "23.99",
                        height: "10.54",
                        left: "292.85",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "320.59",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "333.59",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "350.58",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "loss",
                        width: "18.68",
                        height: "10.54",
                        left: "370.9",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "392.58",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "damage",
                        width: "37.32",
                        height: "10.54",
                        left: "405.57",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "445.89",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "466.88",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arise",
                        width: "22.65",
                        height: "10.54",
                        left: "490.54",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "516.19",
                        top: "152.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "72",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "97.75",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "117.49",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "them.",
                        width: "26.99",
                        height: "10.54",
                        left: "131.24",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "And",
                        width: "20.67",
                        height: "10.54",
                        left: "161.98",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "186.4",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "204.14",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "222.54",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsible",
                        width: "54.66",
                        height: "10.54",
                        left: "241.62",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "300.03",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "317.77",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "products",
                        width: "41.33",
                        height: "10.54",
                        left: "336.18",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "381.26",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.65",
                        height: "10.54",
                        left: "395",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offered",
                        width: "34.43",
                        height: "10.54",
                        left: "437.4",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "475.58",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "491.33",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "517.07",
                        top: "138.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "parties.",
                        width: "34.99",
                        height: "10.54",
                        left: "72",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "110.74",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "122.48",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "decide",
                        width: "31.31",
                        height: "10.54",
                        left: "144.23",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "179.29",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "192.38",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "226.77",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "247.85",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "261.59",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third­party",
                        width: "51.32",
                        height: "10.54",
                        left: "280",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "websites",
                        width: "41.32",
                        height: "10.54",
                        left: "335.07",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "linked",
                        width: "30",
                        height: "10.54",
                        left: "380.14",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "413.89",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "426.97",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "448.06",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "493.17",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "514.17",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "so",
                        width: "10.67",
                        height: "10.54",
                        left: "529.17",
                        top: "124.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "entirely",
                        width: "36.65",
                        height: "10.54",
                        left: "72",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "111.65",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "123.31",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "own",
                        width: "20.66",
                        height: "10.54",
                        left: "148.31",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "risk",
                        width: "18",
                        height: "10.54",
                        left: "171.97",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "192.97",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "subject",
                        width: "33.99",
                        height: "10.54",
                        left: "213.3",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "250.29",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "262.62",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "280.28",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "309.94",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "conditions",
                        width: "50",
                        height: "10.54",
                        left: "330.27",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "383.27",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "396.26",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "415.26",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "432.25",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "websites.",
                        width: "44.32",
                        height: "10.54",
                        left: "457.25",
                        top: "110.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals,",
                        width: "40.32",
                        height: "10.54",
                        left: "94.41",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "goods,",
                        width: "31.67",
                        height: "10.54",
                        left: "138.48",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services,",
                        width: "41.65",
                        height: "10.54",
                        left: "173.9",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "219.3",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activities",
                        width: "43.31",
                        height: "10.54",
                        left: "240.38",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offered",
                        width: "34.43",
                        height: "10.54",
                        left: "287.44",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "325.62",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "341.37",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "31.99",
                        height: "10.54",
                        left: "367.78",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.66",
                        height: "10.54",
                        left: "403.52",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "427.93",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "447.68",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertise",
                        width: "43.31",
                        height: "10.54",
                        left: "460.68",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "506.99",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "521.99",
                        top: "82.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,665",
                    "lineto,540,665",
                    "lineto,540,636",
                    "lineto,72,636",
                    "lineto,72,665",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,637",
                    "lineto,540,637",
                    "lineto,540,622",
                    "lineto,72,622",
                    "lineto,72,637",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,623",
                    "lineto,540,623",
                    "lineto,540,608",
                    "lineto,72,608",
                    "lineto,72,623",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,609",
                    "lineto,540,609",
                    "lineto,540,580",
                    "lineto,72,580",
                    "lineto,72,609",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,581",
                    "lineto,540,581",
                    "lineto,540,552",
                    "lineto,72,552",
                    "lineto,72,581",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,554",
                    "lineto,540,554",
                    "lineto,540,539",
                    "lineto,72,539",
                    "lineto,72,554",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,540",
                    "lineto,540,540",
                    "lineto,540,525",
                    "lineto,72,525",
                    "lineto,72,540",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,526",
                    "lineto,540,526",
                    "lineto,540,511",
                    "lineto,72,511",
                    "lineto,72,526",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,512",
                    "lineto,540,512",
                    "lineto,540,483",
                    "lineto,72,483",
                    "lineto,72,512",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,484",
                    "lineto,540,484",
                    "lineto,540,469",
                    "lineto,72,469",
                    "lineto,72,484",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,457",
                    "lineto,540,457",
                    "lineto,540,442",
                    "lineto,90,442",
                    "lineto,90,457",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,443",
                    "lineto,540,443",
                    "lineto,540,428",
                    "lineto,90,428",
                    "lineto,90,443",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,429",
                    "lineto,540,429",
                    "lineto,540,414",
                    "lineto,90,414",
                    "lineto,90,429",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,415",
                    "lineto,540,415",
                    "lineto,540,400",
                    "lineto,90,400",
                    "lineto,90,415",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,90,401",
                    "lineto,540,401",
                    "lineto,540,386",
                    "lineto,90,386",
                    "lineto,90,401",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,374",
                    "lineto,540,374",
                    "lineto,540,359",
                    "lineto,72,359",
                    "lineto,72,374",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,360",
                    "lineto,540,360",
                    "lineto,540,345",
                    "lineto,72,345",
                    "lineto,72,360",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,346",
                    "lineto,540,346",
                    "lineto,540,317",
                    "lineto,72,317",
                    "lineto,72,346",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,318",
                    "lineto,540,318",
                    "lineto,540,303",
                    "lineto,72,303",
                    "lineto,72,318",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,304",
                    "lineto,540,304",
                    "lineto,540,275",
                    "lineto,72,275",
                    "lineto,72,304",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,277",
                    "lineto,540,277",
                    "lineto,540,262",
                    "lineto,72,262",
                    "lineto,72,277",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,263",
                    "lineto,540,263",
                    "lineto,540,234",
                    "lineto,72,234",
                    "lineto,72,263",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,235",
                    "lineto,540,235",
                    "lineto,540,206",
                    "lineto,72,206",
                    "lineto,72,235",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,207",
                    "lineto,540,207",
                    "lineto,540,192",
                    "lineto,72,192",
                    "lineto,72,207",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,193",
                    "lineto,540,193",
                    "lineto,540,178",
                    "lineto,72,178",
                    "lineto,72,193",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,180",
                    "lineto,540,180",
                    "lineto,540,165",
                    "lineto,72,165",
                    "lineto,72,180",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,166",
                    "lineto,540,166",
                    "lineto,540,151",
                    "lineto,72,151",
                    "lineto,72,166",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,152",
                    "lineto,540,152",
                    "lineto,540,137",
                    "lineto,72,137",
                    "lineto,72,152",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,138",
                    "lineto,540,138",
                    "lineto,540,123",
                    "lineto,72,123",
                    "lineto,72,138",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,124",
                    "lineto,540,124",
                    "lineto,540,95",
                    "lineto,72,95",
                    "lineto,72,124",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,652.5", "lineto,243,652.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,568.5", "lineto,327,568.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,222.5", "lineto,228,222.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "14",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and/or",
                        width: "30.65",
                        height: "10.54",
                        left: "114.11",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "whose",
                        width: "30.66",
                        height: "10.54",
                        left: "148.51",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "websites",
                        width: "41.33",
                        height: "10.54",
                        left: "182.92",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "227.25",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "linked",
                        width: "29.99",
                        height: "10.54",
                        left: "244.9",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "277.89",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "304.22",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "324.56",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "365.91",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "389.57",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "deemed",
                        width: "37.31",
                        height: "10.54",
                        left: "403.9",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "444.21",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "illegal",
                        width: "29.99",
                        height: "10.54",
                        left: "458.54",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activity",
                        width: "35.98",
                        height: "10.54",
                        left: "491.53",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "530.51",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "72",
                        top: "691.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "jurisdictions",
                        width: "59.33",
                        height: "10.54",
                        left: "109.14",
                        top: "691.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "172.97",
                        top: "691.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "194.8",
                        top: "691.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "void",
                        width: "21.33",
                        height: "10.54",
                        left: "213.2",
                        top: "691.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "26",
                        height: "10.54",
                        left: "238.28",
                        top: "691.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prohibited.",
                        width: "52.32",
                        height: "10.54",
                        left: "268.03",
                        top: "691.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "324.1",
                        top: "691.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "347.31",
                        top: "691.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "specifically",
                        width: "55.31",
                        height: "10.54",
                        left: "365.71",
                        top: "691.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "warned",
                        width: "35.31",
                        height: "10.54",
                        left: "424.77",
                        top: "691.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "463.83",
                        top: "691.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "485.58",
                        top: "691.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "should",
                        width: "32",
                        height: "10.54",
                        left: "507.33",
                        top: "691.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "make",
                        width: "25.99",
                        height: "10.54",
                        left: "72",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "103.24",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "own",
                        width: "20.67",
                        height: "10.54",
                        left: "130.48",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inquiry",
                        width: "34.66",
                        height: "10.54",
                        left: "156.4",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "into",
                        width: "18.67",
                        height: "10.54",
                        left: "196.31",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "220.23",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "legality",
                        width: "35.99",
                        height: "10.54",
                        left: "240.14",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "281.38",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchasing",
                        width: "52.65",
                        height: "10.54",
                        left: "296.63",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "354.53",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "selling",
                        width: "32",
                        height: "10.54",
                        left: "369.77",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "406.27",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals",
                        width: "37.32",
                        height: "10.54",
                        left: "428.1",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "469.92",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "484.42",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "items",
                        width: "26",
                        height: "10.54",
                        left: "513.57",
                        top: "676.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "72",
                        top: "661.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "113.08",
                        top: "661.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "131.49",
                        top: "661.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "173.6",
                        top: "661.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "participating",
                        width: "60.65",
                        height: "10.54",
                        left: "187.34",
                        top: "661.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "251.74",
                        top: "661.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "264.82",
                        top: "661.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "285.9",
                        top: "661.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "299.64",
                        top: "661.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third­party",
                        width: "51.32",
                        height: "10.54",
                        left: "328.05",
                        top: "661.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "goods,",
                        width: "31.67",
                        height: "10.54",
                        left: "382.37",
                        top: "661.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services,",
                        width: "41.64",
                        height: "10.54",
                        left: "417.04",
                        top: "661.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "461.68",
                        top: "661.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activities.",
                        width: "46.32",
                        height: "10.54",
                        left: "474.68",
                        top: "661.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "524",
                        top: "661.53",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "assume",
                        width: "35.33",
                        height: "10.54",
                        left: "72",
                        top: "646.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "114.08",
                        top: "646.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsibility",
                        width: "65.33",
                        height: "10.54",
                        left: "132.83",
                        top: "646.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "204.16",
                        top: "646.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "224.15",
                        top: "646.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "participation",
                        width: "60.64",
                        height: "10.54",
                        left: "252.15",
                        top: "646.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "318.79",
                        top: "646.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "334.13",
                        top: "646.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "357.45",
                        top: "646.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "373.45",
                        top: "646.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third­party",
                        width: "51.32",
                        height: "10.54",
                        left: "404.1",
                        top: "646.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals,",
                        width: "40.32",
                        height: "10.54",
                        left: "461.42",
                        top: "646.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "goods,",
                        width: "31.67",
                        height: "10.54",
                        left: "507.74",
                        top: "646.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "services,",
                        width: "41.65",
                        height: "10.55",
                        left: "72",
                        top: "631.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.55",
                        left: "117.4",
                        top: "631.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activities",
                        width: "43.32",
                        height: "10.55",
                        left: "131.14",
                        top: "631.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "178.21",
                        top: "631.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.99",
                        height: "10.55",
                        left: "199.29",
                        top: "631.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.55",
                        left: "229.03",
                        top: "631.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "representation",
                        width: "67.96",
                        height: "10.55",
                        left: "244.78",
                        top: "631.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.55",
                        left: "316.49",
                        top: "631.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respect",
                        width: "33.98",
                        height: "10.55",
                        left: "341.58",
                        top: "631.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to,",
                        width: "12.33",
                        height: "10.55",
                        left: "379.31",
                        top: "631.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "395.39",
                        top: "631.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "endorsement",
                        width: "61.31",
                        height: "10.55",
                        left: "409.14",
                        top: "631.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of,",
                        width: "13",
                        height: "10.55",
                        left: "474.2",
                        top: "631.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.55",
                        left: "490.95",
                        top: "631.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.55",
                        left: "516.69",
                        top: "631.74",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "parties",
                        width: "31.99",
                        height: "10.54",
                        left: "72",
                        top: "616.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "109.24",
                        top: "616.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "their",
                        width: "21.99",
                        height: "10.54",
                        left: "124.48",
                        top: "616.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respective",
                        width: "48.64",
                        height: "10.54",
                        left: "151.72",
                        top: "616.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "goods,",
                        width: "31.67",
                        height: "10.54",
                        left: "204.86",
                        top: "616.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services,",
                        width: "41.65",
                        height: "10.54",
                        left: "241.03",
                        top: "616.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "287.18",
                        top: "616.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activities,",
                        width: "46.31",
                        height: "10.54",
                        left: "301.68",
                        top: "616.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "352.49",
                        top: "616.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "402.32",
                        top: "616.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "representation",
                        width: "67.97",
                        height: "10.54",
                        left: "424.15",
                        top: "616.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "496.62",
                        top: "616.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "511.11",
                        top: "616.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.95",
                        top: "616.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "legality",
                        width: "35.99",
                        height: "10.54",
                        left: "72",
                        top: "601.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "113.24",
                        top: "601.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "128.48",
                        top: "601.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "goods,",
                        width: "31.67",
                        height: "10.54",
                        left: "155.73",
                        top: "601.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services,",
                        width: "41.65",
                        height: "10.54",
                        left: "192.65",
                        top: "601.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "239.55",
                        top: "601.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activities",
                        width: "43.32",
                        height: "10.54",
                        left: "254.79",
                        top: "601.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "303.36",
                        top: "601.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "317.95",
                        top: "601.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "jurisdiction.",
                        width: "57.67",
                        height: "10.54",
                        left: "340.52",
                        top: "601.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.54",
                        left: "408.69",
                        top: "601.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "more",
                        width: "24.65",
                        height: "10.54",
                        left: "430.61",
                        top: "601.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "59.66",
                        height: "10.54",
                        left: "460.51",
                        top: "601.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "see",
                        width: "15.32",
                        height: "10.54",
                        left: "524.67",
                        top: "601.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Disclaimer",
                        width: "52.65",
                        height: "10.54",
                        left: "72",
                        top: "587.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "127.65",
                        top: "587.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Warranties,",
                        width: "54.68",
                        height: "10.54",
                        left: "140.64",
                        top: "587.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below.",
                        width: "31.54",
                        height: "10.54",
                        left: "198.32",
                        top: "587.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Geographic",
                        width: "59.99",
                        height: "10.54",
                        left: "72",
                        top: "558.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Restrictions",
                        width: "61.32",
                        height: "10.54",
                        left: "134.99",
                        top: "558.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "owner",
                        width: "29.98",
                        height: "10.54",
                        left: "95.16",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "129.64",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "144.14",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "163.3",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "206.16",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "based",
                        width: "27.32",
                        height: "10.54",
                        left: "218.66",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "250.48",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "264.32",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "State",
                        width: "23.99",
                        height: "10.54",
                        left: "283.48",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "311.97",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Texas",
                        width: "27.81",
                        height: "10.54",
                        left: "326.47",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "358.78",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "371.87",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "United",
                        width: "32.66",
                        height: "10.54",
                        left: "390.28",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "States.",
                        width: "31.66",
                        height: "10.54",
                        left: "426.69",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "462.1",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "481.54",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "521.95",
                        top: "530.38",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "72",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "116.36",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "136.35",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "only",
                        width: "21.34",
                        height: "10.54",
                        left: "158.34",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "185.68",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "persons",
                        width: "36.66",
                        height: "10.54",
                        left: "203.68",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "located",
                        width: "34.65",
                        height: "10.54",
                        left: "246.34",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "286.24",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "300.82",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "United",
                        width: "32.66",
                        height: "10.54",
                        left: "320.73",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "States.",
                        width: "31.66",
                        height: "10.54",
                        left: "358.64",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.7",
                        height: "10.54",
                        left: "395.55",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.98",
                        height: "10.54",
                        left: "416.5",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "447.73",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claims",
                        width: "31.33",
                        height: "10.54",
                        left: "464.98",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "501.56",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.8",
                        top: "516.58",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "72",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "114.11",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "127.85",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "148.93",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "11.34",
                        height: "10.54",
                        left: "162.67",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "177.76",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "216.83",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accessible",
                        width: "48.63",
                        height: "10.54",
                        left: "228.59",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "280.97",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "appropriate",
                        width: "54.64",
                        height: "10.54",
                        left: "294.72",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "outside",
                        width: "34.66",
                        height: "10.54",
                        left: "353.11",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "391.52",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "405.27",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "United",
                        width: "32.66",
                        height: "10.54",
                        left: "422.93",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "States.",
                        width: "31.66",
                        height: "10.54",
                        left: "458.59",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Access",
                        width: "33.99",
                        height: "10.54",
                        left: "493.25",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "530.24",
                        top: "502.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "72",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.55",
                        left: "90.41",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "132.52",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.55",
                        left: "156.93",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.55",
                        left: "176.01",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "legal",
                        width: "23.32",
                        height: "10.55",
                        left: "190.34",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.55",
                        left: "216.66",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.55",
                        left: "231.66",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "persons",
                        width: "36.66",
                        height: "10.55",
                        left: "267.3",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "306.96",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.55",
                        left: "319.96",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.55",
                        left: "332.29",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "countries.",
                        width: "46.99",
                        height: "10.55",
                        left: "367.93",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.55",
                        left: "417.92",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "428.91",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.65",
                        height: "10.55",
                        left: "449.91",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "483.56",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.55",
                        left: "501.22",
                        top: "488.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "72",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "outside",
                        width: "34.66",
                        height: "10.54",
                        left: "102.08",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "143.49",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "United",
                        width: "32.66",
                        height: "10.54",
                        left: "164.9",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "States,",
                        width: "31.66",
                        height: "10.54",
                        left: "203.56",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "241.22",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "265.22",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "so",
                        width: "10.67",
                        height: "10.54",
                        left: "283.22",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "299.89",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "317.89",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "own",
                        width: "20.67",
                        height: "10.54",
                        left: "345.89",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "initiative",
                        width: "42.65",
                        height: "10.54",
                        left: "372.56",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "421.21",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "444.54",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsible",
                        width: "54.65",
                        height: "10.54",
                        left: "465.19",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.54",
                        left: "525.84",
                        top: "475.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "compliance",
                        width: "55.31",
                        height: "10.54",
                        left: "72",
                        top: "461.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "130.31",
                        top: "461.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "local",
                        width: "23.32",
                        height: "10.54",
                        left: "154.64",
                        top: "461.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "laws.",
                        width: "25",
                        height: "10.54",
                        left: "180.96",
                        top: "461.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Disclaimer",
                        width: "55.31",
                        height: "10.54",
                        left: "72",
                        top: "433.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "130.31",
                        top: "433.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Warranties",
                        width: "57.99",
                        height: "10.54",
                        left: "143.31",
                        top: "433.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "405.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "understand",
                        width: "52.66",
                        height: "10.54",
                        left: "97.46",
                        top: "405.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "156.12",
                        top: "405.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "179.36",
                        top: "405.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "36",
                        height: "10.54",
                        left: "216.6",
                        top: "405.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "does",
                        width: "21.99",
                        height: "10.54",
                        left: "257.85",
                        top: "405.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "285.09",
                        top: "405.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "manufacture,",
                        width: "62.96",
                        height: "10.54",
                        left: "305.68",
                        top: "405.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "breed,",
                        width: "29.65",
                        height: "10.54",
                        left: "373.89",
                        top: "405.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inspect,",
                        width: "36.99",
                        height: "10.54",
                        left: "408.79",
                        top: "405.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "care",
                        width: "19.98",
                        height: "10.54",
                        left: "451.03",
                        top: "405.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for,",
                        width: "16.51",
                        height: "10.54",
                        left: "476.26",
                        top: "405.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "store,",
                        width: "26.32",
                        height: "10.54",
                        left: "498.02",
                        top: "405.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.59",
                        top: "405.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "handle",
                        width: "31.99",
                        height: "10.54",
                        left: "72",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "107.74",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals",
                        width: "37.33",
                        height: "10.54",
                        left: "128.06",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "168.39",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "181.38",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "items",
                        width: "26",
                        height: "10.54",
                        left: "209.04",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sold",
                        width: "20",
                        height: "10.54",
                        left: "238.04",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "261.04",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "301.37",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "319.03",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "366.39",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.65",
                        height: "10.54",
                        left: "385.08",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "424.73",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "venue",
                        width: "28.66",
                        height: "10.54",
                        left: "442.39",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "474.05",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "491.04",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sales",
                        width: "23.32",
                        height: "10.54",
                        left: "516.04",
                        top: "391.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "only;",
                        width: "24.67",
                        height: "10.54",
                        left: "72",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "101.92",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals",
                        width: "37.32",
                        height: "10.54",
                        left: "121.83",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "164.4",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "186.98",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "items",
                        width: "26",
                        height: "10.54",
                        left: "216.88",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "248.13",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "listed",
                        width: "26",
                        height: "10.54",
                        left: "268.03",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "299.28",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sold",
                        width: "20",
                        height: "10.54",
                        left: "321.86",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "directly",
                        width: "36.65",
                        height: "10.54",
                        left: "347.11",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "389.01",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "independent",
                        width: "58.65",
                        height: "10.54",
                        left: "406.26",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Sellers,",
                        width: "35.66",
                        height: "10.54",
                        left: "470.16",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "so",
                        width: "10.67",
                        height: "10.54",
                        left: "510.32",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "525.49",
                        top: "378.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "cannot",
                        width: "31.99",
                        height: "10.54",
                        left: "72",
                        top: "364.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "109.24",
                        top: "364.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "131.81",
                        top: "364.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "149.06",
                        top: "364.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.98",
                        height: "10.54",
                        left: "169.65",
                        top: "364.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "200.88",
                        top: "364.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "guarantees",
                        width: "51.3",
                        height: "10.54",
                        left: "222.71",
                        top: "364.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "278.51",
                        top: "364.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "warranties",
                        width: "49.97",
                        height: "10.54",
                        left: "293.01",
                        top: "364.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "347.48",
                        top: "364.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "their",
                        width: "21.99",
                        height: "10.54",
                        left: "378.64",
                        top: "364.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "quality,",
                        width: "35.55",
                        height: "10.54",
                        left: "405.13",
                        top: "364.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "safety,",
                        width: "30.88",
                        height: "10.54",
                        left: "445.18",
                        top: "364.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "authenticity,",
                        width: "58.87",
                        height: "10.54",
                        left: "480.56",
                        top: "364.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "health,",
                        width: "32.32",
                        height: "10.54",
                        left: "72",
                        top: "350.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "110.32",
                        top: "350.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "legality,",
                        width: "38.21",
                        height: "10.54",
                        left: "125.57",
                        top: "350.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "nor",
                        width: "15.99",
                        height: "10.54",
                        left: "169.03",
                        top: "350.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "190.27",
                        top: "350.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "207.52",
                        top: "350.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.99",
                        height: "10.54",
                        left: "226.76",
                        top: "350.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "258",
                        top: "350.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "warranty,",
                        width: "44.86",
                        height: "10.54",
                        left: "280.58",
                        top: "350.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "representation,",
                        width: "70.97",
                        height: "10.54",
                        left: "330.69",
                        top: "350.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "406.91",
                        top: "350.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "guarantee",
                        width: "46.63",
                        height: "10.54",
                        left: "422.16",
                        top: "350.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "regarding",
                        width: "45.98",
                        height: "10.54",
                        left: "474.04",
                        top: "350.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.27",
                        top: "350.59",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.55",
                        left: "72",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "111.82",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.55",
                        left: "126.32",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Seller",
                        width: "27.99",
                        height: "10.55",
                        left: "148.14",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "listings,",
                        width: "37.68",
                        height: "10.55",
                        left: "180.63",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "222.81",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ability",
                        width: "30.66",
                        height: "10.55",
                        left: "241.97",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "277.13",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Sellers",
                        width: "32.66",
                        height: "10.55",
                        left: "290.88",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "327.29",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sell",
                        width: "16.67",
                        height: "10.55",
                        left: "340.37",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals",
                        width: "37.32",
                        height: "10.55",
                        left: "360.79",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "401.86",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.55",
                        left: "415.61",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "items,",
                        width: "29",
                        height: "10.55",
                        left: "444.01",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "476.76",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ability",
                        width: "30.66",
                        height: "10.55",
                        left: "495.17",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "529.58",
                        top: "336.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Purchasers",
                        width: "51.98",
                        height: "10.54",
                        left: "72",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "129.98",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pay",
                        width: "17.32",
                        height: "10.54",
                        left: "145.32",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.54",
                        left: "168.64",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals",
                        width: "37.32",
                        height: "10.54",
                        left: "188.64",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "231.96",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "247.96",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "items,",
                        width: "29",
                        height: "10.54",
                        left: "278.61",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "313.61",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "329.61",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "353.6",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Purchaser",
                        width: "47.31",
                        height: "10.54",
                        left: "376.93",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "429.49",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Seller",
                        width: "27.99",
                        height: "10.54",
                        left: "444.74",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "477.98",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "actually",
                        width: "37.98",
                        height: "10.54",
                        left: "501.9",
                        top: "323",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "complete",
                        width: "43.98",
                        height: "10.54",
                        left: "72",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "119.73",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transaction",
                        width: "52.64",
                        height: "10.54",
                        left: "128.81",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "185.2",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "return",
                        width: "28.65",
                        height: "10.54",
                        left: "198.95",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "231.35",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchased",
                        width: "48.64",
                        height: "10.54",
                        left: "240.43",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animal",
                        width: "32.66",
                        height: "10.54",
                        left: "292.82",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "329.23",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "342.97",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "item.",
                        width: "24.33",
                        height: "10.54",
                        left: "370.63",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Any",
                        width: "20.66",
                        height: "10.54",
                        left: "400.96",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "legal",
                        width: "23.32",
                        height: "10.54",
                        left: "424.62",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claim",
                        width: "26.66",
                        height: "10.54",
                        left: "450.94",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "related",
                        width: "32.64",
                        height: "10.54",
                        left: "480.6",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "516.24",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "528.57",
                        top: "309.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "animal",
                        width: "32.65",
                        height: "10.54",
                        left: "72",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "109.15",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "item",
                        width: "21.33",
                        height: "10.54",
                        left: "123.65",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "149.48",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchase",
                        width: "42.64",
                        height: "10.54",
                        left: "171.98",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.34",
                        height: "10.54",
                        left: "219.12",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "246.96",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "brought",
                        width: "37.33",
                        height: "10.54",
                        left: "262.79",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "directly",
                        width: "36.65",
                        height: "10.54",
                        left: "304.62",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "against",
                        width: "33.99",
                        height: "10.54",
                        left: "345.02",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "382.76",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Seller",
                        width: "27.99",
                        height: "10.54",
                        left: "401.17",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "432.91",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "446.65",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animal",
                        width: "32.66",
                        height: "10.54",
                        left: "465.06",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "501.47",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "item,",
                        width: "24.33",
                        height: "10.54",
                        left: "515.21",
                        top: "295.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "96.08",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claim",
                        width: "26.66",
                        height: "10.54",
                        left: "119.4",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "related",
                        width: "32.64",
                        height: "10.54",
                        left: "152.06",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "190.7",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "206.03",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animal",
                        width: "32.65",
                        height: "10.54",
                        left: "223.36",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "262.01",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "item",
                        width: "21.33",
                        height: "10.54",
                        left: "278.01",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "305.34",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sell",
                        width: "16.66",
                        height: "10.54",
                        left: "329.34",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.34",
                        height: "10.54",
                        left: "352",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "381.34",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "brought",
                        width: "37.33",
                        height: "10.54",
                        left: "398.67",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "directly",
                        width: "36.65",
                        height: "10.54",
                        left: "442",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "against",
                        width: "33.99",
                        height: "10.54",
                        left: "484.65",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.64",
                        top: "281.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Purchaser",
                        width: "47.31",
                        height: "10.54",
                        left: "72",
                        top: "267.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "125.31",
                        top: "267.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "141.31",
                        top: "267.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animal",
                        width: "32.66",
                        height: "10.54",
                        left: "161.97",
                        top: "267.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "200.63",
                        top: "267.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "item.",
                        width: "24.33",
                        height: "10.54",
                        left: "216.62",
                        top: "267.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Without",
                        width: "38.85",
                        height: "10.54",
                        left: "246.95",
                        top: "267.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limiting",
                        width: "38",
                        height: "10.54",
                        left: "291.05",
                        top: "267.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "334.3",
                        top: "267.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "General",
                        width: "37.98",
                        height: "10.54",
                        left: "354.21",
                        top: "267.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Warranty",
                        width: "44.34",
                        height: "10.54",
                        left: "397.44",
                        top: "267.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Disclaimer,",
                        width: "55.65",
                        height: "10.54",
                        left: "447.03",
                        top: "267.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below,",
                        width: "31.54",
                        height: "10.54",
                        left: "507.93",
                        top: "267.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "NEITHER",
                        width: "55.35",
                        height: "10.54",
                        left: "72",
                        top: "254",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.66",
                        height: "10.54",
                        left: "131.85",
                        top: "254",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXOTICS",
                        width: "54.02",
                        height: "10.54",
                        left: "183.01",
                        top: "254",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NOR",
                        width: "26.67",
                        height: "10.54",
                        left: "241.53",
                        top: "254",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "25.99",
                        height: "10.54",
                        left: "272.7",
                        top: "254",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PERSON",
                        width: "48.68",
                        height: "10.54",
                        left: "303.19",
                        top: "254",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ASSOCIATED",
                        width: "77.13",
                        height: "10.54",
                        left: "356.37",
                        top: "254",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITH",
                        width: "34.01",
                        height: "10.54",
                        left: "438",
                        top: "254",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "US",
                        width: "15.34",
                        height: "10.54",
                        left: "476.51",
                        top: "254",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MAKES",
                        width: "44",
                        height: "10.54",
                        left: "495.6",
                        top: "254",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "72",
                        top: "240.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "GUARANTEE,",
                        width: "79.67",
                        height: "10.54",
                        left: "101.75",
                        top: "240.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "REPRESENTATION,",
                        width: "112.58",
                        height: "10.54",
                        left: "185.17",
                        top: "240.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "301.5",
                        top: "240.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WARRANTY",
                        width: "70.67",
                        height: "10.54",
                        left: "323.25",
                        top: "240.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "397.67",
                        top: "240.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "418.08",
                        top: "240.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "KIND",
                        width: "31.34",
                        height: "10.54",
                        left: "447.08",
                        top: "240.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(EXPRESS",
                        width: "58.01",
                        height: "10.54",
                        left: "481.42",
                        top: "240.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OR",
                        width: "18",
                        height: "10.55",
                        left: "72",
                        top: "226.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IMPLIED)",
                        width: "56.67",
                        height: "10.55",
                        left: "97.5",
                        top: "226.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITH",
                        width: "34",
                        height: "10.55",
                        left: "161.67",
                        top: "226.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "RESPECT",
                        width: "55.35",
                        height: "10.55",
                        left: "203.17",
                        top: "226.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.55",
                        left: "266.02",
                        top: "226.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.55",
                        left: "290.64",
                        top: "226.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANIMALS",
                        width: "56.67",
                        height: "10.55",
                        left: "323.39",
                        top: "226.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.55",
                        left: "386.81",
                        top: "226.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHER",
                        width: "43.34",
                        height: "10.55",
                        left: "411.56",
                        top: "226.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ITEMS",
                        width: "38.68",
                        height: "10.55",
                        left: "461.65",
                        top: "226.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SOLD",
                        width: "32.68",
                        height: "10.55",
                        left: "507.08",
                        top: "226.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "THROUGH",
                        width: "62.67",
                        height: "10.54",
                        left: "72",
                        top: "212.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "157.92",
                        top: "212.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE",
                        width: "55.36",
                        height: "10.54",
                        left: "206.51",
                        top: "212.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ",",
                        width: "2.84",
                        height: "9.7",
                        left: "261.87",
                        top: "212.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "11",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "INCLUDING",
                        width: "70.01",
                        height: "10.54",
                        left: "287.44",
                        top: "212.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "380.7",
                        top: "212.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WARRANTY",
                        width: "70.67",
                        height: "10.54",
                        left: "429.95",
                        top: "212.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "523.12",
                        top: "212.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "MERCHANTABILITY,",
                        width: "123.02",
                        height: "10.54",
                        left: "72",
                        top: "198.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TITLE,",
                        width: "39.69",
                        height: "10.54",
                        left: "201.77",
                        top: "198.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "248.21",
                        top: "198.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FITNESS",
                        width: "50.02",
                        height: "10.54",
                        left: "272.96",
                        top: "198.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FOR",
                        width: "25.33",
                        height: "10.54",
                        left: "328.98",
                        top: "198.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.67",
                        height: "10.54",
                        left: "360.31",
                        top: "198.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PARTICULAR",
                        width: "78.69",
                        height: "10.54",
                        left: "374.98",
                        top: "198.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PURPOSE",
                        width: "56",
                        height: "10.54",
                        left: "459.67",
                        top: "198.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "521.67",
                        top: "198.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "WARRANTY",
                        width: "70.67",
                        height: "10.54",
                        left: "72",
                        top: "184.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IMPLIED",
                        width: "52.67",
                        height: "10.54",
                        left: "146.42",
                        top: "184.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "202.84",
                        top: "184.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.67",
                        height: "10.54",
                        left: "223.26",
                        top: "184.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "COURSE",
                        width: "50.01",
                        height: "10.54",
                        left: "234.93",
                        top: "184.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "287.94",
                        top: "184.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PERFORMANCE,",
                        width: "97.66",
                        height: "10.54",
                        left: "307.6",
                        top: "184.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "COURSE",
                        width: "50.01",
                        height: "10.54",
                        left: "408.26",
                        top: "184.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "461.27",
                        top: "184.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DEALING,",
                        width: "59.01",
                        height: "10.54",
                        left: "480.93",
                        top: "184.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OR",
                        width: "18",
                        height: "10.55",
                        left: "72",
                        top: "171.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USAGE",
                        width: "41.34",
                        height: "10.55",
                        left: "95.25",
                        top: "171.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.67",
                        height: "10.55",
                        left: "141.84",
                        top: "171.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TRADE.",
                        width: "45",
                        height: "10.55",
                        left: "163.76",
                        top: "171.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOU",
                        width: "26.67",
                        height: "10.55",
                        left: "218.51",
                        top: "171.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "RELEASE",
                        width: "56.02",
                        height: "10.55",
                        left: "249.68",
                        top: "171.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.67",
                        height: "10.55",
                        left: "310.2",
                        top: "171.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXOTICS",
                        width: "54.01",
                        height: "10.55",
                        left: "361.37",
                        top: "171.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FROM",
                        width: "36.66",
                        height: "10.55",
                        left: "419.88",
                        top: "171.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.55",
                        left: "461.04",
                        top: "171.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLAIMS",
                        width: "48",
                        height: "10.55",
                        left: "491.54",
                        top: "171.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "RELATED",
                        width: "57.12",
                        height: "10.54",
                        left: "72",
                        top: "157.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "139.62",
                        top: "157.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ITEMS",
                        width: "38.68",
                        height: "10.54",
                        left: "167.24",
                        top: "157.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SOLD",
                        width: "32.68",
                        height: "10.54",
                        left: "216.42",
                        top: "157.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "259.6",
                        top: "157.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PURCHASED",
                        width: "74.67",
                        height: "10.54",
                        left: "288.1",
                        top: "157.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THROUGH",
                        width: "62.67",
                        height: "10.54",
                        left: "372.52",
                        top: "157.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OUR",
                        width: "26.67",
                        height: "10.54",
                        left: "444.94",
                        top: "157.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE,",
                        width: "58.36",
                        height: "10.54",
                        left: "481.36",
                        top: "157.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "INCLUDING",
                        width: "70.01",
                        height: "10.54",
                        left: "72",
                        top: "143.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FOR",
                        width: "25.33",
                        height: "10.54",
                        left: "147.26",
                        top: "143.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DEFECTIVE",
                        width: "70.01",
                        height: "10.54",
                        left: "177.84",
                        top: "143.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ITEMS,",
                        width: "41.68",
                        height: "10.54",
                        left: "252.35",
                        top: "143.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MISREPRESENTATIONS",
                        width: "138.92",
                        height: "10.54",
                        left: "298.53",
                        top: "143.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "441.95",
                        top: "143.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SELLERS",
                        width: "54.03",
                        height: "10.54",
                        left: "463.12",
                        top: "143.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "521.65",
                        top: "143.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "PURCHASERS,",
                        width: "84.35",
                        height: "10.54",
                        left: "72",
                        top: "129.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "162.35",
                        top: "129.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ITEMS",
                        width: "38.67",
                        height: "10.54",
                        left: "186.35",
                        top: "129.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THAT",
                        width: "33.12",
                        height: "10.54",
                        left: "231.02",
                        top: "129.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CAUSED",
                        width: "49.34",
                        height: "10.54",
                        left: "269.39",
                        top: "129.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PHYSICAL",
                        width: "62.01",
                        height: "10.54",
                        left: "323.98",
                        top: "129.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INJURY",
                        width: "44.91",
                        height: "10.54",
                        left: "391.24",
                        top: "129.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(LIKE",
                        width: "34.01",
                        height: "10.54",
                        left: "441.4",
                        top: "129.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRODUCT",
                        width: "59.33",
                        height: "10.54",
                        left: "480.66",
                        top: "129.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "LIABILITY",
                        width: "63.36",
                        height: "10.54",
                        left: "72",
                        top: "115.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLAIMS).",
                        width: "55",
                        height: "10.54",
                        left: "138.36",
                        top: "115.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "88.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "96.66",
                        top: "88.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "159.31",
                        top: "88.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "present",
                        width: "34.66",
                        height: "10.54",
                        left: "179.3",
                        top: "88.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "219.21",
                        top: "88.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "236.46",
                        top: "88.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "251.7",
                        top: "88.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "294.28",
                        top: "88.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "314.19",
                        top: "88.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "357.8",
                        top: "88.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made",
                        width: "25.99",
                        height: "10.54",
                        left: "371.05",
                        top: "88.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.3",
                        height: "10.54",
                        left: "402.29",
                        top: "88.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "solely",
                        width: "28.67",
                        height: "10.54",
                        left: "450.84",
                        top: "88.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "484.76",
                        top: "88.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "general",
                        width: "35.31",
                        height: "10.54",
                        left: "504",
                        top: "88.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "informational",
                        width: "65.31",
                        height: "10.54",
                        left: "72",
                        top: "74.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes.",
                        width: "45.67",
                        height: "10.54",
                        left: "141.06",
                        top: "74.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Any",
                        width: "20.66",
                        height: "10.54",
                        left: "190.48",
                        top: "74.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reliance",
                        width: "37.97",
                        height: "10.54",
                        left: "214.89",
                        top: "74.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "256.61",
                        top: "74.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "place",
                        width: "25.31",
                        height: "10.54",
                        left: "278.36",
                        top: "74.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "307.42",
                        top: "74.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "323.17",
                        top: "74.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "348.92",
                        top: "74.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "409.32",
                        top: "74.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "strictly",
                        width: "33.33",
                        height: "10.54",
                        left: "421.08",
                        top: "74.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "458.16",
                        top: "74.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "469.82",
                        top: "74.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "own",
                        width: "20.67",
                        height: "10.54",
                        left: "494.81",
                        top: "74.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "risk.",
                        width: "21",
                        height: "10.54",
                        left: "518.48",
                        top: "74.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,578",
                    "lineto,540,578",
                    "lineto,540,543",
                    "lineto,72,543",
                    "lineto,72,578",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,544",
                    "lineto,540,544",
                    "lineto,540,529",
                    "lineto,72,529",
                    "lineto,72,544",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,530",
                    "lineto,540,530",
                    "lineto,540,515",
                    "lineto,72,515",
                    "lineto,72,530",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,517",
                    "lineto,540,517",
                    "lineto,540,502",
                    "lineto,72,502",
                    "lineto,72,517",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,503",
                    "lineto,540,503",
                    "lineto,540,488",
                    "lineto,72,488",
                    "lineto,72,503",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,489",
                    "lineto,540,489",
                    "lineto,540,474",
                    "lineto,72,474",
                    "lineto,72,489",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,475",
                    "lineto,540,475",
                    "lineto,540,446",
                    "lineto,72,446",
                    "lineto,72,475",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,447",
                    "lineto,540,447",
                    "lineto,540,418",
                    "lineto,72,418",
                    "lineto,72,447",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,588.5", "lineto,192,588.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,559.5", "lineto,196,559.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,434.5", "lineto,201,434.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,354,269.5", "lineto,500,269.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "15",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.67",
                        height: "10.54",
                        left: "96.71",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "19.33",
                        height: "10.54",
                        left: "122.63",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "come",
                        width: "25.98",
                        height: "10.54",
                        left: "147.21",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "across",
                        width: "29.99",
                        height: "10.54",
                        left: "178.44",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials",
                        width: "43.98",
                        height: "10.54",
                        left: "213.68",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "262.91",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "285.4",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "find",
                        width: "19.33",
                        height: "10.54",
                        left: "307.9",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offensive",
                        width: "44.44",
                        height: "10.54",
                        left: "331.73",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "380.67",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inappropriate",
                        width: "63.97",
                        height: "10.54",
                        left: "395.16",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "while",
                        width: "26.66",
                        height: "10.54",
                        left: "463.63",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "using",
                        width: "26.01",
                        height: "10.54",
                        left: "494.79",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.3",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Without",
                        width: "38.84",
                        height: "10.54",
                        left: "120.11",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limiting",
                        width: "38.01",
                        height: "10.54",
                        left: "164.95",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "208.96",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "General",
                        width: "37.97",
                        height: "10.54",
                        left: "229.62",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Warranty",
                        width: "44.35",
                        height: "10.54",
                        left: "273.59",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Disclaimer,",
                        width: "55.64",
                        height: "10.54",
                        left: "323.94",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below,",
                        width: "31.55",
                        height: "10.54",
                        left: "385.58",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "423.13",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "443.12",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "461.12",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "warrant",
                        width: "36.64",
                        height: "10.54",
                        left: "482.46",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.1",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "accuracy,",
                        width: "44.85",
                        height: "10.54",
                        left: "72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "completeness,",
                        width: "67.64",
                        height: "10.54",
                        left: "122.85",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "legality,",
                        width: "38.21",
                        height: "10.54",
                        left: "196.49",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "usefulness,",
                        width: "53",
                        height: "10.54",
                        left: "240.7",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "299.7",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "decency",
                        width: "39.31",
                        height: "10.54",
                        left: "315.69",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "361",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "376.99",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "399.57",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "presented",
                        width: "45.98",
                        height: "10.54",
                        left: "461.47",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "512.7",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.95",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.55",
                        left: "72",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "113.08",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.55",
                        left: "131.49",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.55",
                        left: "176.6",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclaim",
                        width: "40.66",
                        height: "10.55",
                        left: "196.04",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.55",
                        left: "240.45",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liability",
                        width: "37.33",
                        height: "10.55",
                        left: "256.19",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "296.52",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsibility",
                        width: "65.33",
                        height: "10.55",
                        left: "316.85",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arising",
                        width: "32.66",
                        height: "10.55",
                        left: "385.18",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.32",
                        height: "10.55",
                        left: "420.84",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.55",
                        left: "447.16",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reliance",
                        width: "37.97",
                        height: "10.55",
                        left: "467.49",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "placed",
                        width: "31.31",
                        height: "10.55",
                        left: "508.46",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "72",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "90",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "117.25",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "179.15",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "196.4",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "219.65",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "234.9",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "257.47",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "visitor",
                        width: "30.67",
                        height: "10.54",
                        left: "287.38",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "323.3",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "337.88",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "357.79",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "404.4",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "419.64",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "anyone",
                        width: "34.65",
                        height: "10.54",
                        left: "436.89",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.67",
                        height: "10.54",
                        left: "476.79",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "502.71",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "528.62",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "informed",
                        width: "43.99",
                        height: "10.54",
                        left: "72",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "118.99",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "131.98",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "152.31",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "11.34",
                        height: "10.54",
                        left: "165.3",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contents.",
                        width: "42.99",
                        height: "10.54",
                        left: "179.64",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "This",
                        width: "21.33",
                        height: "10.54",
                        left: "72",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "98.58",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "142.19",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "include",
                        width: "35.32",
                        height: "10.54",
                        left: "168.1",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "208.67",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided",
                        width: "42.66",
                        height: "10.54",
                        left: "249.24",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "297.15",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "314.4",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties,",
                        width: "34.99",
                        height: "10.54",
                        left: "342.31",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "381.8",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials",
                        width: "43.98",
                        height: "10.54",
                        left: "431.63",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided",
                        width: "42.65",
                        height: "10.54",
                        left: "480.11",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "527.26",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "72",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users.",
                        width: "27.66",
                        height: "10.54",
                        left: "101.91",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "All",
                        width: "15.33",
                        height: "10.54",
                        left: "134.82",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "statements",
                        width: "50.66",
                        height: "10.54",
                        left: "155.4",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and/or",
                        width: "30.65",
                        height: "10.54",
                        left: "211.31",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "opinions",
                        width: "41.34",
                        height: "10.54",
                        left: "247.21",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "expressed",
                        width: "47.32",
                        height: "10.54",
                        left: "293.8",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "346.37",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "360.95",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials,",
                        width: "46.98",
                        height: "10.54",
                        left: "390.86",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "443.09",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "465.66",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "articles",
                        width: "34.64",
                        height: "10.54",
                        left: "482.91",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "522.05",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "responses",
                        width: "46.66",
                        height: "10.54",
                        left: "72",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "123.16",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "questions",
                        width: "45.34",
                        height: "10.54",
                        left: "136.99",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "186.83",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "208.65",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content,",
                        width: "38.32",
                        height: "10.54",
                        left: "237.81",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "280.63",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "than",
                        width: "20.66",
                        height: "10.54",
                        left: "309.79",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "334.95",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "354.11",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided",
                        width: "42.65",
                        height: "10.54",
                        left: "393.93",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "441.08",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us,",
                        width: "13.67",
                        height: "10.54",
                        left: "456.83",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "474.25",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "solely",
                        width: "28.66",
                        height: "10.54",
                        left: "492.65",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.06",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "opinions",
                        width: "41.34",
                        height: "10.54",
                        left: "72",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "117.09",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "138.16",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsibility",
                        width: "65.34",
                        height: "10.54",
                        left: "155.82",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "224.16",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "237.15",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "person",
                        width: "31.99",
                        height: "10.54",
                        left: "254.81",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "289.8",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "entity",
                        width: "27.33",
                        height: "10.54",
                        left: "302.8",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providing",
                        width: "46.66",
                        height: "10.54",
                        left: "333.13",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "those",
                        width: "25.33",
                        height: "10.54",
                        left: "382.79",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials.",
                        width: "46.98",
                        height: "10.54",
                        left: "411.12",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "These",
                        width: "28.66",
                        height: "10.54",
                        left: "464.1",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials",
                        width: "43.98",
                        height: "10.54",
                        left: "495.76",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "do",
                        width: "12",
                        height: "10.55",
                        left: "72",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.55",
                        left: "87.75",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "necessarily",
                        width: "53.31",
                        height: "10.55",
                        left: "106.83",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reflect",
                        width: "30.64",
                        height: "10.55",
                        left: "163.89",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "198.28",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "opinion",
                        width: "36.67",
                        height: "10.55",
                        left: "216.69",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "257.11",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.55",
                        left: "270.86",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics.",
                        width: "38.99",
                        height: "10.55",
                        left: "306.6",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.55",
                        left: "349.34",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.55",
                        left: "368.78",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.55",
                        left: "387.18",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsible,",
                        width: "57.65",
                        height: "10.55",
                        left: "406.27",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "466.92",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liable",
                        width: "26.65",
                        height: "10.55",
                        left: "479.92",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.55",
                        left: "509.57",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "521.91",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "85",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "105.32",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "party,",
                        width: "26.87",
                        height: "10.54",
                        left: "130.99",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.54",
                        left: "160.86",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "177.86",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "195.52",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "233.84",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accuracy",
                        width: "42.63",
                        height: "10.54",
                        left: "246.83",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "292.46",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "305.46",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials",
                        width: "43.98",
                        height: "10.54",
                        left: "325.78",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided",
                        width: "42.66",
                        height: "10.54",
                        left: "372.76",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "418.42",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "433.42",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "453.74",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties.",
                        width: "34.98",
                        height: "10.54",
                        left: "479.41",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Please",
                        width: "30.66",
                        height: "10.55",
                        left: "72",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.55",
                        left: "106.41",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advised",
                        width: "36.66",
                        height: "10.55",
                        left: "121.48",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.55",
                        left: "161.89",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "183.63",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "can",
                        width: "16.66",
                        height: "10.55",
                        left: "205.38",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.55",
                        left: "225.79",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "244.78",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.55",
                        left: "262.44",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "303.8",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interact",
                        width: "35.98",
                        height: "10.55",
                        left: "316.13",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.55",
                        left: "355.11",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.55",
                        left: "379.44",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "individuals,",
                        width: "56.33",
                        height: "10.55",
                        left: "407.1",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "either",
                        width: "27.32",
                        height: "10.55",
                        left: "466.43",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "online",
                        width: "29.99",
                        height: "10.55",
                        left: "496.75",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "529.74",
                        top: "516.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "72",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "person.",
                        width: "35",
                        height: "10.54",
                        left: "86.58",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "However,",
                        width: "46.5",
                        height: "10.54",
                        left: "126.83",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "177.83",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "understand",
                        width: "52.65",
                        height: "10.54",
                        left: "200.33",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "257.48",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "279.97",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "298.46",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "314.96",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "screen",
                        width: "30.64",
                        height: "10.54",
                        left: "334.8",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.67",
                        height: "10.54",
                        left: "369.94",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "399.11",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "413.6",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "432.76",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "475.62",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "than",
                        width: "20.66",
                        height: "10.54",
                        left: "504.77",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "529.93",
                        top: "503.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "meet",
                        width: "23.32",
                        height: "10.54",
                        left: "72",
                        top: "489.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "99.82",
                        top: "489.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "compliance",
                        width: "55.31",
                        height: "10.54",
                        left: "136.96",
                        top: "489.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "196.77",
                        top: "489.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "legal",
                        width: "23.32",
                        height: "10.54",
                        left: "218.6",
                        top: "489.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "obligations,",
                        width: "56.33",
                        height: "10.54",
                        left: "246.42",
                        top: "489.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "307.25",
                        top: "489.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "329.07",
                        top: "489.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "release",
                        width: "33.31",
                        height: "10.54",
                        left: "351.57",
                        top: "489.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "389.38",
                        top: "489.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "404.55",
                        top: "489.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "432.38",
                        top: "489.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liability",
                        width: "37.33",
                        height: "10.54",
                        left: "448.87",
                        top: "489.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "relating",
                        width: "36.65",
                        height: "10.54",
                        left: "489.95",
                        top: "489.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.3299999999999",
                        height: "10.54",
                        left: "530.35",
                        top: "489.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "72",
                        top: "475.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interactions",
                        width: "55.98",
                        height: "10.54",
                        left: "97.75",
                        top: "475.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "157.48",
                        top: "475.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "182.56",
                        top: "475.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users.",
                        width: "27.66",
                        height: "10.54",
                        left: "210.97",
                        top: "475.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Please",
                        width: "30.66",
                        height: "10.54",
                        left: "242.38",
                        top: "475.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "276.79",
                        top: "475.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "careful",
                        width: "33.31",
                        height: "10.54",
                        left: "291.86",
                        top: "475.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "328.92",
                        top: "475.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "exercise",
                        width: "39.31",
                        height: "10.54",
                        left: "349.99",
                        top: "475.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "caution",
                        width: "35.32",
                        height: "10.54",
                        left: "393.05",
                        top: "475.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "432.12",
                        top: "475.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "good",
                        width: "24",
                        height: "10.54",
                        left: "453.19",
                        top: "475.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "judgment",
                        width: "45.33",
                        height: "10.54",
                        left: "480.94",
                        top: "475.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "530.02",
                        top: "475.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "72",
                        top: "461.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interactions",
                        width: "55.98",
                        height: "10.54",
                        left: "86.99",
                        top: "461.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "145.97",
                        top: "461.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "others,",
                        width: "32.32",
                        height: "10.54",
                        left: "170.31",
                        top: "461.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "especially",
                        width: "47.98",
                        height: "10.54",
                        left: "205.63",
                        top: "461.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "256.61",
                        top: "461.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "266.94",
                        top: "461.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "287.94",
                        top: "461.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "meeting",
                        width: "38.65",
                        height: "10.54",
                        left: "305.59",
                        top: "461.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "someone",
                        width: "42.66",
                        height: "10.54",
                        left: "347.24",
                        top: "461.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "392.9",
                        top: "461.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "person.",
                        width: "35",
                        height: "10.54",
                        left: "405.23",
                        top: "461.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "433.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "understand",
                        width: "52.66",
                        height: "10.54",
                        left: "98.96",
                        top: "433.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "159.12",
                        top: "433.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "184.61",
                        top: "433.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cannot",
                        width: "31.99",
                        height: "10.54",
                        left: "206.1",
                        top: "433.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "244.84",
                        top: "433.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "268.92",
                        top: "433.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "287.67",
                        top: "433.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "guarantee",
                        width: "46.63",
                        height: "10.54",
                        left: "309.75",
                        top: "433.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "363.13",
                        top: "433.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "warrant",
                        width: "36.65",
                        height: "10.54",
                        left: "379.88",
                        top: "433.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "423.28",
                        top: "433.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "files",
                        width: "20.66",
                        height: "10.54",
                        left: "448.02",
                        top: "433.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.31",
                        height: "10.54",
                        left: "475.43",
                        top: "433.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "525.49",
                        top: "433.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "downloading",
                        width: "62.66",
                        height: "10.54",
                        left: "72",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "139.16",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "166.99",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "internet",
                        width: "36.65",
                        height: "10.54",
                        left: "186.15",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "227.3",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "241.79",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "260.95",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "303.81",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "326.23",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "free",
                        width: "18.65",
                        height: "10.54",
                        left: "341.3",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "363.7",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "viruses",
                        width: "34",
                        height: "10.54",
                        left: "377.44",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "415.19",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "428.94",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "destructive",
                        width: "52.65",
                        height: "10.54",
                        left: "457.34",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "code.",
                        width: "25.65",
                        height: "10.54",
                        left: "513.74",
                        top: "420.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "406.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "97.46",
                        top: "406.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsible",
                        width: "54.66",
                        height: "10.54",
                        left: "118.11",
                        top: "406.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "178.77",
                        top: "406.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "implementing",
                        width: "66.66",
                        height: "10.54",
                        left: "198.76",
                        top: "406.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sufficient",
                        width: "45.1",
                        height: "10.54",
                        left: "271.42",
                        top: "406.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "procedures",
                        width: "52.64",
                        height: "10.54",
                        left: "322.52",
                        top: "406.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "381.16",
                        top: "406.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "checkpoints",
                        width: "57.32",
                        height: "10.54",
                        left: "403.73",
                        top: "406.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "466.3",
                        top: "406.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "satisfy",
                        width: "31.33",
                        height: "10.54",
                        left: "480.88",
                        top: "406.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "517.46",
                        top: "406.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "requirements",
                        width: "62.64",
                        height: "10.54",
                        left: "72",
                        top: "392.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "138.39",
                        top: "392.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "anti­virus",
                        width: "46",
                        height: "10.54",
                        left: "156.13",
                        top: "392.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "protection",
                        width: "48.65",
                        height: "10.54",
                        left: "205.88",
                        top: "392.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "258.28",
                        top: "392.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accuracy",
                        width: "42.63",
                        height: "10.54",
                        left: "278.6",
                        top: "392.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "324.23",
                        top: "392.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "19.99",
                        height: "10.54",
                        left: "337.22",
                        top: "392.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "input",
                        width: "24.67",
                        height: "10.54",
                        left: "360.21",
                        top: "392.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "387.88",
                        top: "392.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "output,",
                        width: "33.66",
                        height: "10.54",
                        left: "408.21",
                        top: "392.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "444.87",
                        top: "392.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "465.2",
                        top: "392.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "maintaining",
                        width: "57.32",
                        height: "10.54",
                        left: "482.19",
                        top: "392.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.55",
                        left: "72",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "means",
                        width: "30.65",
                        height: "10.55",
                        left: "83.33",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "external",
                        width: "38.65",
                        height: "10.55",
                        left: "119.98",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "164.63",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.55",
                        left: "179.96",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "site",
                        width: "16.66",
                        height: "10.55",
                        left: "201.96",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.55",
                        left: "224.62",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.55",
                        left: "244.61",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reconstruction",
                        width: "68.64",
                        height: "10.55",
                        left: "267.94",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "342.58",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.55",
                        left: "358.58",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "lost",
                        width: "17.34",
                        height: "10.55",
                        left: "381.15",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data.",
                        width: "22.99",
                        height: "10.55",
                        left: "403.74",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.55",
                        left: "431.98",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.55",
                        left: "454.35",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FULLEST",
                        width: "54.69",
                        height: "10.55",
                        left: "484.94",
                        top: "378.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "EXTENT",
                        width: "49.35",
                        height: "10.54",
                        left: "72",
                        top: "364.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PROVIDED",
                        width: "64",
                        height: "10.54",
                        left: "127.35",
                        top: "364.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "197.35",
                        top: "364.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LAW,",
                        width: "29.24",
                        height: "10.54",
                        left: "220.02",
                        top: "364.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WE",
                        width: "20",
                        height: "10.54",
                        left: "255.26",
                        top: "364.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WILL",
                        width: "32.68",
                        height: "10.54",
                        left: "281.26",
                        top: "364.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NOT",
                        width: "26",
                        height: "10.54",
                        left: "319.94",
                        top: "364.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BE",
                        width: "16.01",
                        height: "10.54",
                        left: "351.94",
                        top: "364.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LIABLE",
                        width: "45.35",
                        height: "10.54",
                        left: "373.2",
                        top: "364.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FOR",
                        width: "25.33",
                        height: "10.54",
                        left: "423.8",
                        top: "364.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "454.38",
                        top: "364.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LOSS",
                        width: "30.69",
                        height: "10.54",
                        left: "485.63",
                        top: "364.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "521.57",
                        top: "364.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "DAMAGE",
                        width: "54.66",
                        height: "10.54",
                        left: "72",
                        top: "351.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CAUSED",
                        width: "49.34",
                        height: "10.54",
                        left: "130.41",
                        top: "351.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "183.5",
                        top: "351.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.67",
                        height: "10.54",
                        left: "203.92",
                        top: "351.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DISTRIBUTED",
                        width: "82.69",
                        height: "10.54",
                        left: "216.34",
                        top: "351.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DENIAL­OF­SERVICE",
                        width: "124.26",
                        height: "10.54",
                        left: "302.03",
                        top: "351.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ATTACK,",
                        width: "52.56",
                        height: "10.54",
                        left: "429.29",
                        top: "351.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "VIRUSES,",
                        width: "55.02",
                        height: "10.54",
                        left: "484.85",
                        top: "351.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "72",
                        top: "337.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHER",
                        width: "43.34",
                        height: "10.54",
                        left: "97.5",
                        top: "337.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TECHNOLOGICALLY",
                        width: "124.26",
                        height: "10.54",
                        left: "148.34",
                        top: "337.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "HARMFUL",
                        width: "61.99",
                        height: "10.54",
                        left: "279.35",
                        top: "337.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MATERIAL",
                        width: "65.11",
                        height: "10.54",
                        left: "348.09",
                        top: "337.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THAT",
                        width: "33.12",
                        height: "10.54",
                        left: "419.95",
                        top: "337.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MAY",
                        width: "27.56",
                        height: "10.54",
                        left: "459.82",
                        top: "337.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INFECT",
                        width: "45.34",
                        height: "10.54",
                        left: "494.13",
                        top: "337.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "YOUR",
                        width: "35.33",
                        height: "10.54",
                        left: "72",
                        top: "323.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "COMPUTER",
                        width: "70",
                        height: "10.54",
                        left: "114.08",
                        top: "323.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EQUIPMENT,",
                        width: "76.11",
                        height: "10.54",
                        left: "190.83",
                        top: "323.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "COMPUTER",
                        width: "70",
                        height: "10.54",
                        left: "273.69",
                        top: "323.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PROGRAMS,",
                        width: "72.99",
                        height: "10.54",
                        left: "350.44",
                        top: "323.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DATA,",
                        width: "35.22",
                        height: "10.54",
                        left: "430.18",
                        top: "323.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "472.15",
                        top: "323.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHER",
                        width: "43.35",
                        height: "10.54",
                        left: "496.15",
                        top: "323.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "PROPRIETARY",
                        width: "86.69",
                        height: "10.54",
                        left: "72",
                        top: "309.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MATERIAL",
                        width: "65.11",
                        height: "10.54",
                        left: "166.19",
                        top: "309.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DUE",
                        width: "25.34",
                        height: "10.54",
                        left: "238.8",
                        top: "309.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "271.64",
                        top: "309.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOUR",
                        width: "35.33",
                        height: "10.54",
                        left: "296.26",
                        top: "309.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USE",
                        width: "23.35",
                        height: "10.54",
                        left: "339.09",
                        top: "309.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "369.94",
                        top: "309.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "394.1",
                        top: "309.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE",
                        width: "55.36",
                        height: "10.54",
                        left: "426.94",
                        top: "309.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "489.05",
                        top: "309.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "513.8",
                        top: "309.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "SERVICES",
                        width: "59.6",
                        height: "10.54",
                        left: "72",
                        top: "295.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "139.85",
                        top: "295.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ITEMS",
                        width: "38.68",
                        height: "10.54",
                        left: "166.1",
                        top: "295.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OBTAINED",
                        width: "63.12",
                        height: "10.54",
                        left: "213.03",
                        top: "295.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THROUGH",
                        width: "62.67",
                        height: "10.54",
                        left: "284.4",
                        top: "295.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.35",
                        height: "10.54",
                        left: "355.32",
                        top: "295.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE",
                        width: "55.36",
                        height: "10.54",
                        left: "388.92",
                        top: "295.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "452.53",
                        top: "295.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "478.78",
                        top: "295.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOUR",
                        width: "35.33",
                        height: "10.54",
                        left: "504.15",
                        top: "295.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "DOWNLOADING",
                        width: "96.01",
                        height: "10.54",
                        left: "72",
                        top: "282.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "174.76",
                        top: "282.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "198.17",
                        top: "282.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MATERIAL",
                        width: "65.11",
                        height: "10.54",
                        left: "230.92",
                        top: "282.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "POSTED",
                        width: "48.01",
                        height: "10.54",
                        left: "302.78",
                        top: "282.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ON",
                        width: "18",
                        height: "10.54",
                        left: "357.54",
                        top: "282.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IT,",
                        width: "14.79",
                        height: "10.54",
                        left: "382.29",
                        top: "282.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "403.83",
                        top: "282.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ON",
                        width: "18",
                        height: "10.54",
                        left: "428.58",
                        top: "282.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "452.58",
                        top: "282.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE",
                        width: "55.36",
                        height: "10.54",
                        left: "484.58",
                        top: "282.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "LINKED",
                        width: "47.34",
                        height: "10.54",
                        left: "72",
                        top: "268.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "122.34",
                        top: "268.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IT.",
                        width: "14.79",
                        height: "10.54",
                        left: "142.46",
                        top: "268.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "GENERAL",
                        width: "59.34",
                        height: "10.54",
                        left: "198.99",
                        top: "240.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WARRANTY",
                        width: "70.67",
                        height: "10.54",
                        left: "261.33",
                        top: "240.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DISCLAIMER",
                        width: "78.01",
                        height: "10.54",
                        left: "335",
                        top: "240.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "72",
                        top: "212.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE",
                        width: "55.36",
                        height: "10.54",
                        left: "101.09",
                        top: "212.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "26",
                        height: "10.54",
                        left: "160.2",
                        top: "212.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "189.95",
                        top: "212.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONTENT,",
                        width: "61.45",
                        height: "10.54",
                        left: "219.7",
                        top: "212.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MATERIALS,",
                        width: "74.79",
                        height: "10.54",
                        left: "284.9",
                        top: "212.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INFORMATION,",
                        width: "91.44",
                        height: "10.54",
                        left: "363.44",
                        top: "212.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "458.63",
                        top: "212.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SERVICES",
                        width: "59.6",
                        height: "10.54",
                        left: "480.38",
                        top: "212.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OBTAINED,",
                        width: "66.12",
                        height: "10.54",
                        left: "72",
                        top: "198.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACCESSED,",
                        width: "67.02",
                        height: "10.54",
                        left: "144.12",
                        top: "198.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "216.39",
                        top: "198.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MADE",
                        width: "36.66",
                        height: "10.54",
                        left: "239.64",
                        top: "198.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AVAILABLE",
                        width: "68.26",
                        height: "10.54",
                        left: "281.55",
                        top: "198.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THROUGH",
                        width: "62.67",
                        height: "10.54",
                        left: "355.06",
                        top: "198.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "422.98",
                        top: "198.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE",
                        width: "55.36",
                        height: "10.54",
                        left: "453.57",
                        top: "198.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ARE",
                        width: "25.34",
                        height: "10.54",
                        left: "514.18",
                        top: "198.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "PROVIDED",
                        width: "64",
                        height: "10.54",
                        left: "72",
                        top: "185.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ON",
                        width: "18",
                        height: "10.54",
                        left: "144.25",
                        top: "185.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AN",
                        width: "17.33",
                        height: "10.54",
                        left: "170.5",
                        top: "185.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"AS',
                        width: "22.01",
                        height: "10.54",
                        left: "196.08",
                        top: "185.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: 'IS"',
                        width: "18",
                        height: "10.54",
                        left: "226.34",
                        top: "185.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "26",
                        height: "10.54",
                        left: "252.59",
                        top: "185.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"AS',
                        width: "22",
                        height: "10.54",
                        left: "286.84",
                        top: "185.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: 'AVAILABLE"',
                        width: "74.92",
                        height: "10.54",
                        left: "317.09",
                        top: "185.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BASIS,",
                        width: "37.69",
                        height: "10.54",
                        left: "400.26",
                        top: "185.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITHOUT",
                        width: "60.01",
                        height: "10.54",
                        left: "446.2",
                        top: "185.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "513.71",
                        top: "185.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "WARRANTIES",
                        width: "81.35",
                        height: "10.54",
                        left: "72",
                        top: "171.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.67",
                        height: "10.54",
                        left: "157.1",
                        top: "171.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "25.99",
                        height: "10.54",
                        left: "177.52",
                        top: "171.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "KIND,",
                        width: "34.34",
                        height: "10.54",
                        left: "207.26",
                        top: "171.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EITHER",
                        width: "46.68",
                        height: "10.54",
                        left: "245.35",
                        top: "171.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXPRESS",
                        width: "54.02",
                        height: "10.54",
                        left: "295.78",
                        top: "171.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "353.55",
                        top: "171.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IMPLIED.",
                        width: "55.67",
                        height: "10.54",
                        left: "375.3",
                        top: "171.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NEITHER",
                        width: "55.35",
                        height: "10.54",
                        left: "434.72",
                        top: "171.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.66",
                        height: "10.54",
                        left: "493.07",
                        top: "171.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "EXOCTICS",
                        width: "62.68",
                        height: "10.54",
                        left: "72",
                        top: "157.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NOR",
                        width: "26.67",
                        height: "10.54",
                        left: "138.43",
                        top: "157.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "168.85",
                        top: "157.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PERSON",
                        width: "48.67",
                        height: "10.54",
                        left: "198.6",
                        top: "157.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ASSOCIATED",
                        width: "77.13",
                        height: "10.54",
                        left: "251.02",
                        top: "157.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITH",
                        width: "34.01",
                        height: "10.54",
                        left: "331.9",
                        top: "157.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "US,",
                        width: "18.34",
                        height: "10.54",
                        left: "369.66",
                        top: "157.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INCLUDING",
                        width: "70.01",
                        height: "10.54",
                        left: "391",
                        top: "157.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "464.01",
                        top: "157.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "493.01",
                        top: "157.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OUR",
                        width: "26.67",
                        height: "10.54",
                        left: "512.67",
                        top: "157.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "LICENSORS,",
                        width: "72.36",
                        height: "10.54",
                        left: "72",
                        top: "143.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MAKES",
                        width: "44",
                        height: "10.54",
                        left: "150.36",
                        top: "143.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "199.61",
                        top: "143.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WARRANTY",
                        width: "70.67",
                        height: "10.54",
                        left: "230.86",
                        top: "143.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "306.78",
                        top: "143.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "REPRESENTATION",
                        width: "109.58",
                        height: "10.54",
                        left: "330.03",
                        top: "143.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITH",
                        width: "34",
                        height: "10.54",
                        left: "444.86",
                        top: "143.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "RESPECT",
                        width: "55.35",
                        height: "10.54",
                        left: "484.11",
                        top: "143.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.55",
                        left: "72",
                        top: "129.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.55",
                        left: "97.37",
                        top: "129.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "COMPLETENESS,",
                        width: "101.69",
                        height: "10.55",
                        left: "130.96",
                        top: "129.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SECURITY,",
                        width: "63.92",
                        height: "10.55",
                        left: "240.9",
                        top: "129.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "RELIABILITY,",
                        width: "81.92",
                        height: "10.55",
                        left: "313.07",
                        top: "129.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "QUALITY,",
                        width: "57.91",
                        height: "10.55",
                        left: "403.24",
                        top: "129.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACCURACY,",
                        width: "71.23",
                        height: "10.55",
                        left: "468.65",
                        top: "129.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "LEGALITY,",
                        width: "65.25",
                        height: "10.54",
                        left: "72",
                        top: "116.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USEFULNESS,",
                        width: "80.36",
                        height: "10.54",
                        left: "147",
                        top: "116.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SAFETY,",
                        width: "49.24",
                        height: "10.54",
                        left: "237.11",
                        top: "116.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DECENCY,",
                        width: "61.24",
                        height: "10.54",
                        left: "296.1",
                        top: "116.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "366.34",
                        top: "116.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AVAILABILITY",
                        width: "86.26",
                        height: "10.54",
                        left: "393.34",
                        top: "116.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.67",
                        height: "10.54",
                        left: "488.6",
                        top: "116.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "514.27",
                        top: "116.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "WEBSITE",
                        width: "55.36",
                        height: "10.54",
                        left: "72",
                        top: "102.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "136.36",
                        top: "102.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "163.36",
                        top: "102.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INFORMATION,",
                        width: "91.44",
                        height: "10.54",
                        left: "198.36",
                        top: "102.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MATERIALS,",
                        width: "74.79",
                        height: "10.54",
                        left: "298.8",
                        top: "102.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONTENT,",
                        width: "61.45",
                        height: "10.54",
                        left: "382.59",
                        top: "102.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "453.04",
                        top: "102.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SERVICES",
                        width: "59.6",
                        height: "10.54",
                        left: "480.04",
                        top: "102.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "PROVIDED",
                        width: "64",
                        height: "10.54",
                        left: "72",
                        top: "88.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "147.25",
                        top: "88.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MADE",
                        width: "36.66",
                        height: "10.54",
                        left: "175.75",
                        top: "88.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AVAILABLE",
                        width: "68.26",
                        height: "10.54",
                        left: "222.91",
                        top: "88.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THROUGH",
                        width: "62.67",
                        height: "10.54",
                        left: "301.67",
                        top: "88.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "374.84",
                        top: "88.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE.",
                        width: "58.36",
                        height: "10.54",
                        left: "410.68",
                        top: "88.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITHOUT",
                        width: "60.01",
                        height: "10.54",
                        left: "479.54",
                        top: "88.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "LIMITING",
                        width: "59.34",
                        height: "10.54",
                        left: "72",
                        top: "74.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.35",
                        height: "10.54",
                        left: "141.84",
                        top: "74.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FOREGOING,",
                        width: "77.67",
                        height: "10.54",
                        left: "177.69",
                        top: "74.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NEITHER",
                        width: "55.35",
                        height: "10.54",
                        left: "265.86",
                        top: "74.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.66",
                        height: "10.54",
                        left: "330.96",
                        top: "74.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXOTICS",
                        width: "54.02",
                        height: "10.54",
                        left: "387.37",
                        top: "74.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NOR",
                        width: "26.66",
                        height: "10.54",
                        left: "451.14",
                        top: "74.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANYONE",
                        width: "52",
                        height: "10.54",
                        left: "487.55",
                        top: "74.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,462",
                    "lineto,540,462",
                    "lineto,540,433",
                    "lineto,72,433",
                    "lineto,72,462",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,434",
                    "lineto,540,434",
                    "lineto,540,419",
                    "lineto,72,419",
                    "lineto,72,434",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,420",
                    "lineto,540,420",
                    "lineto,540,405",
                    "lineto,72,405",
                    "lineto,72,420",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,406",
                    "lineto,540,406",
                    "lineto,540,391",
                    "lineto,72,391",
                    "lineto,72,406",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,392",
                    "lineto,540,392",
                    "lineto,540,377",
                    "lineto,72,377",
                    "lineto,72,392",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,379",
                    "lineto,540,379",
                    "lineto,540,364",
                    "lineto,72,364",
                    "lineto,72,379",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,365",
                    "lineto,540,365",
                    "lineto,540,350",
                    "lineto,72,350",
                    "lineto,72,365",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,351",
                    "lineto,540,351",
                    "lineto,540,336",
                    "lineto,72,336",
                    "lineto,72,351",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,337",
                    "lineto,540,337",
                    "lineto,540,322",
                    "lineto,72,322",
                    "lineto,72,337",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,323",
                    "lineto,540,323",
                    "lineto,540,308",
                    "lineto,72,308",
                    "lineto,72,323",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,310",
                    "lineto,540,310",
                    "lineto,540,295",
                    "lineto,72,295",
                    "lineto,72,310",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,296",
                    "lineto,540,296",
                    "lineto,540,281",
                    "lineto,72,281",
                    "lineto,72,296",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,282",
                    "lineto,540,282",
                    "lineto,540,253",
                    "lineto,72,253",
                    "lineto,72,282",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,254",
                    "lineto,540,254",
                    "lineto,540,225",
                    "lineto,72,225",
                    "lineto,72,254",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,230,693.5", "lineto,377,693.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,199,241.5", "lineto,413,241.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "16",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "ASSOCIATED",
                        width: "77.13",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITH",
                        width: "34.01",
                        height: "10.54",
                        left: "154.38",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "US",
                        width: "15.34",
                        height: "10.54",
                        left: "193.64",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "REPRESENTS",
                        width: "78.69",
                        height: "10.54",
                        left: "214.23",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "298.17",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WARRANTS",
                        width: "68.68",
                        height: "10.54",
                        left: "321.42",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THAT",
                        width: "33.12",
                        height: "10.54",
                        left: "394.6",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "432.22",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE",
                        width: "55.36",
                        height: "10.54",
                        left: "462.06",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "521.92",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONTENT,",
                        width: "61.45",
                        height: "10.54",
                        left: "109.25",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MATERIALS,",
                        width: "74.79",
                        height: "10.54",
                        left: "181.95",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INFORMATION,",
                        width: "91.44",
                        height: "10.54",
                        left: "267.99",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRODUCTS,",
                        width: "69.01",
                        height: "10.54",
                        left: "370.68",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "450.94",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SERVICES",
                        width: "59.6",
                        height: "10.54",
                        left: "480.19",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OBTAINED,",
                        width: "66.12",
                        height: "10.54",
                        left: "72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACCESSED,",
                        width: "67.02",
                        height: "10.54",
                        left: "142.62",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "214.14",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MADE",
                        width: "36.66",
                        height: "10.54",
                        left: "236.64",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AVAILABLE",
                        width: "68.26",
                        height: "10.54",
                        left: "277.8",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THROUGH",
                        width: "62.67",
                        height: "10.54",
                        left: "350.56",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "417.73",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE",
                        width: "55.36",
                        height: "10.54",
                        left: "447.57",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WILL",
                        width: "32.68",
                        height: "10.54",
                        left: "506.68",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "BE",
                        width: "16.01",
                        height: "10.55",
                        left: "72",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACCURATE,",
                        width: "70.11",
                        height: "10.55",
                        left: "101.51",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "RELIABLE,",
                        width: "65.02",
                        height: "10.55",
                        left: "185.12",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ERROR­FREE,",
                        width: "82.34",
                        height: "10.55",
                        left: "262.89",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "UNINTERRUPTED,",
                        width: "107.68",
                        height: "10.55",
                        left: "357.98",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FREE",
                        width: "32",
                        height: "10.55",
                        left: "478.41",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.55",
                        left: "523.16",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "DEFECTS,",
                        width: "58.35",
                        height: "10.54",
                        left: "72",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THAT",
                        width: "33.11",
                        height: "10.54",
                        left: "137.85",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DEFECTS",
                        width: "55.35",
                        height: "10.54",
                        left: "174.71",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WILL",
                        width: "32.68",
                        height: "10.54",
                        left: "233.06",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BE",
                        width: "16.01",
                        height: "10.54",
                        left: "268.74",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CORRECTED,",
                        width: "79.67",
                        height: "10.54",
                        left: "287.75",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THAT",
                        width: "33.12",
                        height: "10.54",
                        left: "370.42",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "406.54",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE",
                        width: "55.36",
                        height: "10.54",
                        left: "434.88",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "493.24",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "514.24",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "SERVER",
                        width: "48.26",
                        height: "10.54",
                        left: "72",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THAT",
                        width: "33.12",
                        height: "10.54",
                        left: "127.76",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MAKES",
                        width: "44",
                        height: "10.54",
                        left: "168.38",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IT",
                        width: "12.67",
                        height: "10.54",
                        left: "219.88",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AVAILABLE",
                        width: "68.26",
                        height: "10.54",
                        left: "240.05",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ARE",
                        width: "25.33",
                        height: "10.54",
                        left: "315.81",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FREE",
                        width: "32.01",
                        height: "10.54",
                        left: "348.64",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "388.15",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "VIRUSES",
                        width: "52.02",
                        height: "10.54",
                        left: "412.31",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "471.83",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHER",
                        width: "43.34",
                        height: "10.54",
                        left: "496.58",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "HARMFUL",
                        width: "61.99",
                        height: "10.54",
                        left: "72",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "COMPONENTS,",
                        width: "89.01",
                        height: "10.54",
                        left: "142.99",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "241",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THAT",
                        width: "33.11",
                        height: "10.54",
                        left: "268",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.35",
                        height: "10.54",
                        left: "310.11",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE",
                        width: "55.35",
                        height: "10.54",
                        left: "344.46",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "26",
                        height: "10.54",
                        left: "408.81",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "443.81",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONTENT,",
                        width: "61.45",
                        height: "10.54",
                        left: "478.06",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "MATERIALS,",
                        width: "74.79",
                        height: "10.54",
                        left: "72",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INFORMATION,",
                        width: "91.44",
                        height: "10.54",
                        left: "154.29",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRODUCTS,",
                        width: "69.01",
                        height: "10.54",
                        left: "253.23",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SERVICES,",
                        width: "62.6",
                        height: "10.54",
                        left: "328.99",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "398.34",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "GOODS",
                        width: "43.34",
                        height: "10.54",
                        left: "423.09",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OBTAINED,",
                        width: "66.12",
                        height: "10.54",
                        left: "473.18",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "ACCESSED,",
                        width: "67.02",
                        height: "10.54",
                        left: "72",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "145.77",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MADE",
                        width: "36.66",
                        height: "10.54",
                        left: "170.52",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AVAILABLE",
                        width: "68.26",
                        height: "10.54",
                        left: "213.93",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "288.19",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OBTAINED",
                        width: "63.12",
                        height: "10.54",
                        left: "312.19",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THROUGH",
                        width: "62.67",
                        height: "10.54",
                        left: "381.31",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "449.98",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE,",
                        width: "58.36",
                        height: "10.54",
                        left: "481.32",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "INCLUDING,",
                        width: "73.01",
                        height: "10.54",
                        left: "72",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BUT",
                        width: "24.67",
                        height: "10.54",
                        left: "151.01",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NOT",
                        width: "26.01",
                        height: "10.54",
                        left: "181.68",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LIMITED",
                        width: "53.34",
                        height: "10.54",
                        left: "213.69",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO,",
                        width: "20.12",
                        height: "10.54",
                        left: "273.03",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OUR",
                        width: "26.67",
                        height: "10.54",
                        left: "299.15",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SERVICES",
                        width: "59.6",
                        height: "10.54",
                        left: "331.82",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.33",
                        height: "10.54",
                        left: "397.42",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONNECTION",
                        width: "82.68",
                        height: "10.54",
                        left: "416.75",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITH",
                        width: "34.01",
                        height: "10.54",
                        left: "505.43",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "FACILITATING",
                        width: "86.68",
                        height: "10.54",
                        left: "72",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MARKETPLACE",
                        width: "94.67",
                        height: "10.54",
                        left: "163.93",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PAYMENTS",
                        width: "65.34",
                        height: "10.54",
                        left: "263.85",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(see",
                        width: "19.32",
                        height: "10.54",
                        left: "334.44",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Market",
                        width: "34.65",
                        height: "10.54",
                        left: "359.01",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Place",
                        width: "25.99",
                        height: "10.54",
                        left: "398.91",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payments,",
                        width: "49.66",
                        height: "10.54",
                        left: "430.15",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "above)",
                        width: "32.65",
                        height: "10.54",
                        left: "484.31",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "521.46",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "ADJUDICATING",
                        width: "92.45",
                        height: "10.55",
                        left: "72",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.55",
                        left: "173.45",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NOT",
                        width: "26",
                        height: "10.55",
                        left: "200.45",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ADJUDICATING",
                        width: "92.45",
                        height: "10.55",
                        left: "235.45",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PAYMENT",
                        width: "58.67",
                        height: "10.55",
                        left: "336.15",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DISPUTES",
                        width: "58.69",
                        height: "10.55",
                        left: "403.07",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(see",
                        width: "19.32",
                        height: "10.55",
                        left: "470.01",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "41.99",
                        height: "10.55",
                        left: "497.58",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Disputes,",
                        width: "45",
                        height: "10.54",
                        left: "72",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "above)",
                        width: "32.65",
                        height: "10.54",
                        left: "121.5",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ",",
                        width: "3",
                        height: "10.54",
                        left: "154.15",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "WILL",
                        width: "32.68",
                        height: "10.54",
                        left: "161.65",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHERWISE",
                        width: "74.26",
                        height: "10.54",
                        left: "198.08",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MEET",
                        width: "35.34",
                        height: "10.54",
                        left: "276.09",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOUR",
                        width: "35.33",
                        height: "10.54",
                        left: "315.18",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NEEDS",
                        width: "40.02",
                        height: "10.54",
                        left: "354.26",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "398.03",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXPECTATIONS.",
                        width: "95.9",
                        height: "10.54",
                        left: "419.78",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "523.18",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "YOUR",
                        width: "35.33",
                        height: "10.54",
                        left: "72",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AGREEMENT",
                        width: "78.68",
                        height: "10.54",
                        left: "112.58",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "196.51",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THESE",
                        width: "40.02",
                        height: "10.54",
                        left: "218.88",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TERMS",
                        width: "42.67",
                        height: "10.54",
                        left: "264.15",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "312.07",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USE,",
                        width: "26.35",
                        height: "10.54",
                        left: "333.23",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOU",
                        width: "26.66",
                        height: "10.54",
                        left: "364.08",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ARE",
                        width: "25.34",
                        height: "10.54",
                        left: "395.24",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DISCLAIMING",
                        width: "84.01",
                        height: "10.54",
                        left: "425.08",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "513.59",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "RELIANCE",
                        width: "63.35",
                        height: "10.54",
                        left: "72",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ON",
                        width: "18",
                        height: "10.54",
                        left: "138.35",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SUCH",
                        width: "33.34",
                        height: "10.54",
                        left: "159.35",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "25.99",
                        height: "10.54",
                        left: "195.69",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SUCH",
                        width: "33.34",
                        height: "10.54",
                        left: "224.68",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WARRANTIES.",
                        width: "84.36",
                        height: "10.54",
                        left: "261.02",
                        top: "513.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "72",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "93.62",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FULLEST",
                        width: "54.69",
                        height: "10.54",
                        left: "123.46",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXTENT",
                        width: "49.35",
                        height: "10.54",
                        left: "182.65",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PROVIDED",
                        width: "64",
                        height: "10.54",
                        left: "236.5",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "304.25",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LAW,",
                        width: "29.24",
                        height: "10.54",
                        left: "324.67",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.66",
                        height: "10.54",
                        left: "357.66",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXOTICS,",
                        width: "57.02",
                        height: "10.54",
                        left: "408.07",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ON",
                        width: "18",
                        height: "10.54",
                        left: "468.84",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BEHALF",
                        width: "49.34",
                        height: "10.54",
                        left: "490.59",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "72",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ITSELF",
                        width: "42.69",
                        height: "10.54",
                        left: "94.66",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "26",
                        height: "10.54",
                        left: "143.35",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ALL",
                        width: "24.67",
                        height: "10.54",
                        left: "175.35",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PERSONS",
                        width: "55.35",
                        height: "10.54",
                        left: "206.02",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ASSOCIATED",
                        width: "77.13",
                        height: "10.54",
                        left: "267.37",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITH",
                        width: "34.01",
                        height: "10.54",
                        left: "350.5",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "US,",
                        width: "18.34",
                        height: "10.54",
                        left: "390.51",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "HEREBY",
                        width: "50.68",
                        height: "10.54",
                        left: "414.85",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DISCLAIMS",
                        width: "68.01",
                        height: "10.54",
                        left: "471.53",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "ALL",
                        width: "24.67",
                        height: "10.54",
                        left: "72",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WARRANTIES",
                        width: "81.36",
                        height: "10.54",
                        left: "108.67",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "202.03",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "230.69",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "KIND,",
                        width: "34.33",
                        height: "10.54",
                        left: "268.69",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WHETHER",
                        width: "63.35",
                        height: "10.54",
                        left: "315.02",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXPRESS",
                        width: "54.02",
                        height: "10.54",
                        left: "389.62",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "454.89",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IMPLIED,",
                        width: "55.67",
                        height: "10.54",
                        left: "484.14",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "STATUTORY,",
                        width: "74.16",
                        height: "10.54",
                        left: "72",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "156.66",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHERWISE,",
                        width: "77.27",
                        height: "10.54",
                        left: "185.16",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INCLUDING",
                        width: "70.01",
                        height: "10.54",
                        left: "272.93",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BUT",
                        width: "24.67",
                        height: "10.54",
                        left: "353.44",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NOT",
                        width: "26.01",
                        height: "10.54",
                        left: "387.86",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LIMITED",
                        width: "53.34",
                        height: "10.54",
                        left: "423.62",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "486.71",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "513.58",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "WARRANTIES",
                        width: "81.35",
                        height: "10.54",
                        left: "72",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.67",
                        height: "10.54",
                        left: "159.35",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MERCHANTABILITY,",
                        width: "123.02",
                        height: "10.54",
                        left: "182.02",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NON­INFRINGEMENT,",
                        width: "128.78",
                        height: "10.54",
                        left: "310.29",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TITLE,",
                        width: "39.68",
                        height: "10.54",
                        left: "444.32",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FITNESS",
                        width: "50.02",
                        height: "10.54",
                        left: "489.25",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "FOR",
                        width: "25.33",
                        height: "10.55",
                        left: "72",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PARTICULAR",
                        width: "78.69",
                        height: "10.55",
                        left: "102.58",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PURPOSE",
                        width: "56.01",
                        height: "10.55",
                        left: "186.52",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "25.99",
                        height: "10.55",
                        left: "247.78",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.55",
                        left: "279.02",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WARRANTIES",
                        width: "81.35",
                        height: "10.55",
                        left: "310.27",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THAT",
                        width: "33.12",
                        height: "10.55",
                        left: "396.87",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MAY",
                        width: "27.56",
                        height: "10.55",
                        left: "434.49",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BE",
                        width: "16",
                        height: "10.55",
                        left: "466.55",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IMPLIED",
                        width: "52.67",
                        height: "10.55",
                        left: "487.05",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "72",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.67",
                        height: "10.54",
                        left: "96.92",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "COURSE",
                        width: "50.01",
                        height: "10.54",
                        left: "113.84",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "171.35",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PERFORMANCE,",
                        width: "97.66",
                        height: "10.54",
                        left: "195.51",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "COURSE",
                        width: "50.01",
                        height: "10.54",
                        left: "300.67",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "358.18",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DEALING,",
                        width: "59.01",
                        height: "10.54",
                        left: "382.34",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "448.85",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USAGE",
                        width: "41.35",
                        height: "10.54",
                        left: "474.35",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "523.2",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "TRADE.",
                        width: "45.01",
                        height: "10.54",
                        left: "72",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "126.76",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOUR",
                        width: "35.33",
                        height: "10.54",
                        left: "153.18",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AGREEMENT",
                        width: "78.67",
                        height: "10.54",
                        left: "198.26",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "286.68",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THESE",
                        width: "40.02",
                        height: "10.54",
                        left: "313.55",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TERMS",
                        width: "42.68",
                        height: "10.54",
                        left: "363.32",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "415.75",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USE,",
                        width: "26.34",
                        height: "10.54",
                        left: "442.16",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOU",
                        width: "26.67",
                        height: "10.54",
                        left: "478.25",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ARE",
                        width: "25.34",
                        height: "10.54",
                        left: "513.92",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "DISCLAIMING",
                        width: "84.01",
                        height: "10.54",
                        left: "72",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "159.01",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "RELIANCE",
                        width: "63.35",
                        height: "10.54",
                        left: "188.01",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ON",
                        width: "18",
                        height: "10.54",
                        left: "254.36",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SUCH",
                        width: "33.34",
                        height: "10.54",
                        left: "275.36",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "25.99",
                        height: "10.54",
                        left: "311.7",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SUCH",
                        width: "33.34",
                        height: "10.54",
                        left: "340.69",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WARRANTIES.",
                        width: "84.35",
                        height: "10.54",
                        left: "377.03",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Waiver",
                        width: "37.32",
                        height: "10.54",
                        left: "72",
                        top: "351.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "112.32",
                        top: "351.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Liability",
                        width: "44.01",
                        height: "10.54",
                        left: "125.32",
                        top: "351.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "172.33",
                        top: "351.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Limitation",
                        width: "54.67",
                        height: "10.54",
                        left: "194.68",
                        top: "351.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12.67",
                        height: "10.54",
                        left: "252.35",
                        top: "351.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Damages",
                        width: "46.66",
                        height: "10.54",
                        left: "268.02",
                        top: "351.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "expressly",
                        width: "45.33",
                        height: "10.54",
                        left: "95.21",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "waive",
                        width: "28.65",
                        height: "10.54",
                        left: "143.54",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "175.19",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "release",
                        width: "33.3",
                        height: "10.54",
                        left: "195.52",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "231.82",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "252.15",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "272.47",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claims",
                        width: "31.32",
                        height: "10.54",
                        left: "287.47",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "321.79",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "causes",
                        width: "31.32",
                        height: "10.54",
                        left: "342.12",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "376.44",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "action,",
                        width: "32.32",
                        height: "10.54",
                        left: "389.43",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "now",
                        width: "20.67",
                        height: "10.54",
                        left: "424.75",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "known",
                        width: "32.66",
                        height: "10.54",
                        left: "448.42",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "484.08",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "hereafter",
                        width: "42.63",
                        height: "10.54",
                        left: "497.08",
                        top: "325.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "known,",
                        width: "35.67",
                        height: "10.54",
                        left: "72",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "against",
                        width: "33.99",
                        height: "10.54",
                        left: "112.92",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "152.16",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "35.99",
                        height: "10.54",
                        left: "189.4",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "230.64",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "11.34",
                        height: "10.54",
                        left: "253.22",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "subsidiaries",
                        width: "56.66",
                        height: "10.54",
                        left: "269.81",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "331.72",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "affiliates,",
                        width: "44.76",
                        height: "10.54",
                        left: "354.29",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "404.3",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "11.34",
                        height: "10.54",
                        left: "426.88",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "442.72",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "their",
                        width: "21.99",
                        height: "10.54",
                        left: "464.54",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respective",
                        width: "48.64",
                        height: "10.54",
                        left: "491.03",
                        top: "311.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "licensors,",
                        width: "45.66",
                        height: "10.54",
                        left: "72",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "employees,",
                        width: "54.31",
                        height: "10.54",
                        left: "121.41",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contractors,",
                        width: "56.31",
                        height: "10.54",
                        left: "179.47",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agents,",
                        width: "33.66",
                        height: "10.54",
                        left: "239.53",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "officers,",
                        width: "39.43",
                        height: "10.54",
                        left: "276.94",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "successors,",
                        width: "53.65",
                        height: "10.54",
                        left: "320.12",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "377.52",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "assigns",
                        width: "34.67",
                        height: "10.54",
                        left: "397.85",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(collectively,",
                        width: "62.19",
                        height: "10.54",
                        left: "435.52",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"',
                        width: "4.9",
                        height: "10.54",
                        left: "500.71",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "33.76",
                        height: "10.54",
                        left: "505.61",
                        top: "297.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Exotics",
                        width: "37.33",
                        height: "10.54",
                        left: "72",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Parties",
                        width: "35.98",
                        height: "10.54",
                        left: "116.83",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"),',
                        width: "11.9",
                        height: "10.54",
                        left: "152.81",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "172.21",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "191.71",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "236.52",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "254.02",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "283.51",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "307.01",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "323.75",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "345.16",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "390.27",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "407.02",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content,",
                        width: "38.32",
                        height: "10.54",
                        left: "431.09",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials,",
                        width: "46.98",
                        height: "10.54",
                        left: "476.16",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.89",
                        top: "283.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "72",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided",
                        width: "42.66",
                        height: "10.54",
                        left: "132.4",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "178.81",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made",
                        width: "25.98",
                        height: "10.54",
                        left: "192.56",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.31",
                        height: "10.54",
                        left: "222.29",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "269.35",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "310.43",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "328.84",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "370.95",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "animals",
                        width: "37.33",
                        height: "10.54",
                        left: "384.69",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "425.02",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "438.01",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "good,",
                        width: "27",
                        height: "10.54",
                        left: "465.67",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "products,",
                        width: "44.32",
                        height: "10.54",
                        left: "495.67",
                        top: "270.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.64",
                        height: "10.54",
                        left: "85.75",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "obtained",
                        width: "41.32",
                        height: "10.54",
                        left: "128.14",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "173.21",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchased",
                        width: "48.65",
                        height: "10.54",
                        left: "186.96",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "239.36",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "280.44",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.35",
                        height: "10.54",
                        left: "298.85",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WHETHER",
                        width: "63.35",
                        height: "10.54",
                        left: "343.95",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ARISING",
                        width: "51.34",
                        height: "10.54",
                        left: "411.05",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OUT",
                        width: "26.01",
                        height: "10.54",
                        left: "465.39",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "494.4",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "514.06",
                        top: "256.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "NEGLIGENCE",
                        width: "81.35",
                        height: "10.54",
                        left: "72",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.67",
                        height: "10.54",
                        left: "157.1",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.66",
                        height: "10.54",
                        left: "177.52",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXOTICS",
                        width: "54.02",
                        height: "10.54",
                        left: "227.93",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "285.7",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "306.7",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHER",
                        width: "43.34",
                        height: "10.54",
                        left: "335.7",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.66",
                        height: "10.54",
                        left: "382.04",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXOTICS",
                        width: "54.02",
                        height: "10.54",
                        left: "431.7",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PARTIES",
                        width: "50.7",
                        height: "10.54",
                        left: "488.72",
                        top: "242.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "72",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "93.75",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHER",
                        width: "43.34",
                        height: "10.54",
                        left: "123.5",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PERSON,",
                        width: "51.67",
                        height: "10.54",
                        left: "170.59",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "226.01",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "regardless",
                        width: "48.64",
                        height: "10.54",
                        left: "247.09",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "299.48",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "whether",
                        width: "38.65",
                        height: "10.54",
                        left: "313.23",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "355.63",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claims",
                        width: "31.32",
                        height: "10.54",
                        left: "380.63",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "414.95",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "based",
                        width: "27.32",
                        height: "10.54",
                        left: "432.6",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "462.92",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "tort,",
                        width: "19.67",
                        height: "10.54",
                        left: "475.25",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contract,",
                        width: "41.64",
                        height: "10.54",
                        left: "497.92",
                        top: "228.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "strict",
                        width: "23.99",
                        height: "10.54",
                        left: "72",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liability,",
                        width: "39.55",
                        height: "10.54",
                        left: "98.99",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "141.54",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise.",
                        width: "49.65",
                        height: "10.54",
                        left: "154.54",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "210.19",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "covenant",
                        width: "43.31",
                        height: "10.54",
                        left: "232.66",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "278.97",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "297.3",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.98",
                        height: "10.54",
                        left: "309.64",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "338.63",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bring",
                        width: "25.33",
                        height: "10.54",
                        left: "351.62",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "379.95",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "400.28",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claim",
                        width: "26.66",
                        height: "10.54",
                        left: "425.27",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "454.93",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cause",
                        width: "26.65",
                        height: "10.54",
                        left: "467.92",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "497.57",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "action",
                        width: "29.32",
                        height: "10.54",
                        left: "510.57",
                        top: "214.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "against",
                        width: "33.99",
                        height: "10.54",
                        left: "72",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "111.99",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "36",
                        height: "10.54",
                        left: "149.98",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "191.23",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "206.47",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "229.05",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "258.96",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "35.99",
                        height: "10.54",
                        left: "296.2",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Party,",
                        width: "27.55",
                        height: "10.54",
                        left: "337.44",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "370.24",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "forever",
                        width: "34.64",
                        height: "10.54",
                        left: "392.82",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "release",
                        width: "33.3",
                        height: "10.54",
                        left: "432.71",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "471.26",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discharge",
                        width: "45.76",
                        height: "10.54",
                        left: "493.84",
                        top: "201.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "72",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "36",
                        height: "10.54",
                        left: "106.99",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "145.99",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "12",
                        height: "10.54",
                        left: "166.31",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "181.31",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "32",
                        height: "10.54",
                        left: "208.96",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "35.99",
                        height: "10.54",
                        left: "243.96",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Parties",
                        width: "32.66",
                        height: "10.54",
                        left: "282.95",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "318.61",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liability",
                        width: "37.33",
                        height: "10.54",
                        left: "344.94",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "385.27",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "415.59",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claims",
                        width: "31.33",
                        height: "10.54",
                        left: "440.58",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "474.91",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "causes",
                        width: "31.32",
                        height: "10.54",
                        left: "495.23",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "529.55",
                        top: "187.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "action.",
                        width: "32.32",
                        height: "10.54",
                        left: "72",
                        top: "173.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "IN",
                        width: "13.34",
                        height: "10.55",
                        left: "72",
                        top: "147.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NO",
                        width: "18",
                        height: "10.55",
                        left: "92.09",
                        top: "147.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EVENT",
                        width: "41.34",
                        height: "10.55",
                        left: "116.84",
                        top: "147.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SHALL",
                        width: "40.68",
                        height: "10.55",
                        left: "164.93",
                        top: "147.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.67",
                        height: "10.55",
                        left: "212.36",
                        top: "147.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXOTIVS",
                        width: "54.01",
                        height: "10.55",
                        left: "265.78",
                        top: "147.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.55",
                        left: "326.54",
                        top: "147.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.55",
                        left: "351.29",
                        top: "147.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHER",
                        width: "43.34",
                        height: "10.55",
                        left: "383.29",
                        top: "147.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.67",
                        height: "10.55",
                        left: "432.63",
                        top: "147.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXOTICS",
                        width: "54.01",
                        height: "10.55",
                        left: "485.3",
                        top: "147.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "PARTY",
                        width: "40.02",
                        height: "10.54",
                        left: "72",
                        top: "133.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BE",
                        width: "16.01",
                        height: "10.54",
                        left: "115.77",
                        top: "133.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LIABLE",
                        width: "45.35",
                        height: "10.54",
                        left: "135.53",
                        top: "133.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "184.63",
                        top: "133.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOU",
                        width: "26.67",
                        height: "10.54",
                        left: "205.5",
                        top: "133.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "235.92",
                        top: "133.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "25.99",
                        height: "10.54",
                        left: "257.67",
                        top: "133.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THIRD",
                        width: "39.34",
                        height: "10.54",
                        left: "287.41",
                        top: "133.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PARTY",
                        width: "40.02",
                        height: "10.54",
                        left: "330.5",
                        top: "133.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FOR",
                        width: "25.33",
                        height: "10.54",
                        left: "374.27",
                        top: "133.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "402.6",
                        top: "133.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONSEQUENTIAL,",
                        width: "108.36",
                        height: "10.54",
                        left: "431.6",
                        top: "133.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "INDIRECT,",
                        width: "62.12",
                        height: "10.54",
                        left: "72",
                        top: "120.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INCIDENTAL,",
                        width: "78.79",
                        height: "10.54",
                        left: "142.37",
                        top: "120.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SPECIAL,",
                        width: "55.02",
                        height: "10.54",
                        left: "229.41",
                        top: "120.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXEMPLARY,",
                        width: "78.8",
                        height: "10.54",
                        left: "292.68",
                        top: "120.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PUNITIVE",
                        width: "58.68",
                        height: "10.54",
                        left: "378.98",
                        top: "120.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "445.16",
                        top: "120.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ENHANCED",
                        width: "68.67",
                        height: "10.54",
                        left: "470.66",
                        top: "120.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "DAMAGES,",
                        width: "64.34",
                        height: "10.54",
                        left: "72",
                        top: "106.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LOST",
                        width: "32.01",
                        height: "10.54",
                        left: "140.09",
                        top: "106.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PROFITS",
                        width: "52.01",
                        height: "10.54",
                        left: "175.85",
                        top: "106.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "231.61",
                        top: "106.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "REVENUES,",
                        width: "68.35",
                        height: "10.54",
                        left: "252.61",
                        top: "106.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "323.96",
                        top: "106.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DIMINUTION",
                        width: "77.34",
                        height: "10.54",
                        left: "344.96",
                        top: "106.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.33",
                        height: "10.54",
                        left: "425.3",
                        top: "106.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "VALUE,",
                        width: "43.46",
                        height: "10.54",
                        left: "441.63",
                        top: "106.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ARISING",
                        width: "51.35",
                        height: "10.54",
                        left: "488.09",
                        top: "106.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OUT",
                        width: "26",
                        height: "10.54",
                        left: "72",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF,",
                        width: "18.57",
                        height: "10.54",
                        left: "111.5",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "143.57",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "RELATING",
                        width: "63.12",
                        height: "10.54",
                        left: "175.07",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO,",
                        width: "20.12",
                        height: "10.54",
                        left: "251.69",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND/OR",
                        width: "47.33",
                        height: "10.54",
                        left: "285.31",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.34",
                        height: "10.54",
                        left: "346.14",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONNECTION",
                        width: "82.67",
                        height: "10.54",
                        left: "372.23",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITH",
                        width: "34.01",
                        height: "10.54",
                        left: "467.65",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "514.41",
                        top: "92.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "PERFORMANCE",
                        width: "94.66",
                        height: "10.54",
                        left: "72",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "174.16",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "198.32",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OBLIGATIONS",
                        width: "84.47",
                        height: "10.54",
                        left: "231.07",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "UNDER",
                        width: "42.67",
                        height: "10.54",
                        left: "322.29",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THESE",
                        width: "40.02",
                        height: "10.54",
                        left: "371.71",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TERMS",
                        width: "42.67",
                        height: "10.54",
                        left: "418.48",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "467.9",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USE",
                        width: "23.35",
                        height: "10.54",
                        left: "491.31",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "521.41",
                        top: "78.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,359,569.5", "lineto,477,569.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,498,555.5", "lineto,540,555.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,541.5", "lineto,114,541.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,352.5", "lineto,315,352.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "17",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "YOUR",
                        width: "35.33",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USE",
                        width: "23.35",
                        height: "10.54",
                        left: "114.83",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF,",
                        width: "18.56",
                        height: "10.54",
                        left: "145.68",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "171.74",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INABILITY",
                        width: "64.02",
                        height: "10.54",
                        left: "197.24",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "268.76",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USE,",
                        width: "26.34",
                        height: "10.54",
                        left: "293.38",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "326.47",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE",
                        width: "55.36",
                        height: "10.54",
                        left: "358.56",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "420.67",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "445.42",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONTENT,",
                        width: "61.46",
                        height: "10.54",
                        left: "478.17",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "INFORMATION,",
                        width: "91.44",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MATERIALS,",
                        width: "74.79",
                        height: "10.54",
                        left: "171.69",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRODUCTS,",
                        width: "69.01",
                        height: "10.54",
                        left: "253.98",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SERVICES,",
                        width: "62.6",
                        height: "10.54",
                        left: "330.49",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANIMALS,",
                        width: "59.67",
                        height: "10.54",
                        left: "400.59",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "GOODS,",
                        width: "46.34",
                        height: "10.54",
                        left: "467.76",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "521.6",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OTHER",
                        width: "43.34",
                        height: "10.54",
                        left: "72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ITEMS",
                        width: "38.68",
                        height: "10.54",
                        left: "128.09",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACCESSED,",
                        width: "67.02",
                        height: "10.54",
                        left: "178.77",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OBTAINED,",
                        width: "66.12",
                        height: "10.54",
                        left: "257.79",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PURCHASED,",
                        width: "77.67",
                        height: "10.54",
                        left: "335.91",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SOLD,",
                        width: "35.68",
                        height: "10.54",
                        left: "425.58",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "473.26",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MADE",
                        width: "36.66",
                        height: "10.54",
                        left: "503.26",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "AVAILABLE",
                        width: "68.26",
                        height: "10.55",
                        left: "72",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THROUGH",
                        width: "62.67",
                        height: "10.55",
                        left: "144.76",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.55",
                        left: "211.93",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE",
                        width: "55.36",
                        height: "10.55",
                        left: "241.77",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(INCLUDING",
                        width: "74",
                        height: "10.55",
                        left: "301.63",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FOR",
                        width: "25.33",
                        height: "10.55",
                        left: "379.38",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.55",
                        left: "408.46",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NEGLIGENT",
                        width: "72.69",
                        height: "10.55",
                        left: "438.21",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACT",
                        width: "25.33",
                        height: "10.55",
                        left: "514.65",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "72",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OMISSIONS),",
                        width: "75.02",
                        height: "10.54",
                        left: "98.25",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INCLUDING,",
                        width: "73.01",
                        height: "10.54",
                        left: "189.77",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BUT",
                        width: "24.67",
                        height: "10.54",
                        left: "271.03",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NOT",
                        width: "26",
                        height: "10.54",
                        left: "303.2",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LIMITED",
                        width: "53.35",
                        height: "10.54",
                        left: "336.7",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO,",
                        width: "20.12",
                        height: "10.54",
                        left: "397.55",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OUR",
                        width: "26.66",
                        height: "10.54",
                        left: "425.17",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SERVICES",
                        width: "59.61",
                        height: "10.54",
                        left: "459.33",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.33",
                        height: "10.54",
                        left: "526.44",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "CONNECTION",
                        width: "82.68",
                        height: "10.54",
                        left: "72",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITH",
                        width: "34",
                        height: "10.54",
                        left: "158.43",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FACILITATING",
                        width: "86.68",
                        height: "10.54",
                        left: "196.18",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MARKETPLACE",
                        width: "94.67",
                        height: "10.54",
                        left: "286.61",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PAYMENTS",
                        width: "65.35",
                        height: "10.54",
                        left: "385.03",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(see",
                        width: "19.31",
                        height: "10.54",
                        left: "453.38",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Market",
                        width: "34.66",
                        height: "10.54",
                        left: "475.69",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Place",
                        width: "25.98",
                        height: "10.54",
                        left: "513.35",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Payments,",
                        width: "49.66",
                        height: "10.54",
                        left: "72",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "above)",
                        width: "32.65",
                        height: "10.54",
                        left: "133.66",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "177.56",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ADJUDICATING",
                        width: "92.45",
                        height: "10.54",
                        left: "206.81",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "310.51",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NOT",
                        width: "26.01",
                        height: "10.54",
                        left: "339.76",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ADJUDICATING",
                        width: "92.44",
                        height: "10.54",
                        left: "377.02",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PAYMENT",
                        width: "58.67",
                        height: "10.54",
                        left: "480.71",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "DISPUTES",
                        width: "58.69",
                        height: "10.54",
                        left: "72",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(see",
                        width: "19.32",
                        height: "10.54",
                        left: "137.44",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "41.99",
                        height: "10.54",
                        left: "163.51",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Disputes,",
                        width: "45",
                        height: "10.54",
                        left: "211.5",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "above)",
                        width: "32.65",
                        height: "10.54",
                        left: "262.5",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ",",
                        width: "3",
                        height: "10.54",
                        left: "295.15",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "REGARDLESS",
                        width: "81.36",
                        height: "10.54",
                        left: "304.15",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "391.51",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(A)",
                        width: "16.66",
                        height: "10.54",
                        left: "414.17",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WHETHER",
                        width: "63.34",
                        height: "10.54",
                        left: "436.83",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SUCH",
                        width: "33.34",
                        height: "10.54",
                        left: "506.17",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "DAMAGES",
                        width: "61.34",
                        height: "10.54",
                        left: "72",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WERE",
                        width: "36.67",
                        height: "10.54",
                        left: "138.59",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FORESEEABLE,",
                        width: "91.69",
                        height: "10.54",
                        left: "180.51",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(B)",
                        width: "16",
                        height: "10.54",
                        left: "277.45",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WHETHER",
                        width: "63.34",
                        height: "10.54",
                        left: "298.7",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "367.29",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NOT",
                        width: "26.01",
                        height: "10.54",
                        left: "389.79",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WE",
                        width: "20",
                        height: "10.54",
                        left: "420.3",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WERE",
                        width: "36.68",
                        height: "10.54",
                        left: "444.8",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ADVISED",
                        width: "54.01",
                        height: "10.54",
                        left: "485.98",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "72",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.35",
                        height: "10.54",
                        left: "92.41",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "POSSIBILITY",
                        width: "76.7",
                        height: "10.54",
                        left: "121.51",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "201.96",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SUCH",
                        width: "33.34",
                        height: "10.54",
                        left: "222.37",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DAMAGES",
                        width: "61.33",
                        height: "10.54",
                        left: "259.46",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "26",
                        height: "10.54",
                        left: "324.54",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(C)",
                        width: "16.66",
                        height: "10.54",
                        left: "354.29",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "374.7",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LEGAL",
                        width: "42.01",
                        height: "10.54",
                        left: "403.04",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "448.05",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EQUITABLE",
                        width: "70.47",
                        height: "10.54",
                        left: "469.05",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "THEORY",
                        width: "51.59",
                        height: "10.54",
                        left: "72",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(CONTRACT,",
                        width: "74.77",
                        height: "10.54",
                        left: "130.34",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TORT",
                        width: "33.37",
                        height: "10.54",
                        left: "211.86",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "251.23",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHERWISE)",
                        width: "78.27",
                        height: "10.54",
                        left: "275.23",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "UPON",
                        width: "33.99",
                        height: "10.54",
                        left: "359.5",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WHICH",
                        width: "44.01",
                        height: "10.54",
                        left: "399.49",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "449.5",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLAIM",
                        width: "41.33",
                        height: "10.54",
                        left: "480.84",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IS",
                        width: "11.34",
                        height: "10.54",
                        left: "528.17",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "BASED.",
                        width: "43.01",
                        height: "10.55",
                        left: "72",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "72",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "96.62",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FULLEST",
                        width: "54.69",
                        height: "10.54",
                        left: "129.46",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXTENT",
                        width: "49.35",
                        height: "10.54",
                        left: "191.65",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PROVIDED",
                        width: "64",
                        height: "10.54",
                        left: "248.5",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "320",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LAW,",
                        width: "29.24",
                        height: "10.54",
                        left: "344.17",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.33",
                        height: "10.54",
                        left: "380.91",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NO",
                        width: "18",
                        height: "10.54",
                        left: "401.74",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EVENT",
                        width: "41.35",
                        height: "10.54",
                        left: "426.49",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WILL",
                        width: "32.67",
                        height: "10.54",
                        left: "474.59",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.35",
                        height: "10.54",
                        left: "514.01",
                        top: "528.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "COLLECTIVE",
                        width: "80.02",
                        height: "10.54",
                        left: "72",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LIABILITY",
                        width: "63.36",
                        height: "10.54",
                        left: "160.27",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "231.88",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.67",
                        height: "10.54",
                        left: "256.79",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXOTICS",
                        width: "54.01",
                        height: "10.54",
                        left: "311.71",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "26",
                        height: "10.54",
                        left: "373.97",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ALL",
                        width: "24.68",
                        height: "10.54",
                        left: "408.22",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHER",
                        width: "43.34",
                        height: "10.54",
                        left: "441.15",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.66",
                        height: "10.54",
                        left: "492.74",
                        top: "514.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "EXOTICS",
                        width: "54.02",
                        height: "10.54",
                        left: "72",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PARTIES",
                        width: "50.7",
                        height: "10.54",
                        left: "130.52",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "185.72",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "207.34",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PERSON",
                        width: "48.67",
                        height: "10.54",
                        left: "237.09",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(REGARDLESS",
                        width: "85.36",
                        height: "10.54",
                        left: "289.51",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "378.62",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "399.03",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FORM",
                        width: "36.66",
                        height: "10.54",
                        left: "428.12",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "468.53",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACTION,",
                        width: "51.01",
                        height: "10.54",
                        left: "488.94",
                        top: "501.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "WHETHER",
                        width: "63.35",
                        height: "10.54",
                        left: "72",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.33",
                        height: "10.54",
                        left: "139.1",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONTRACT,",
                        width: "70.78",
                        height: "10.54",
                        left: "156.18",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TORT,",
                        width: "35.48",
                        height: "10.54",
                        left: "230.71",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "269.94",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHERWISE)",
                        width: "78.27",
                        height: "10.54",
                        left: "291.69",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.33",
                        height: "10.54",
                        left: "373.71",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONNECTION",
                        width: "82.68",
                        height: "10.54",
                        left: "390.79",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITH",
                        width: "34",
                        height: "10.54",
                        left: "477.22",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.35",
                        height: "10.54",
                        left: "514.22",
                        top: "487.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "PERFORMANCE",
                        width: "94.66",
                        height: "10.54",
                        left: "72",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "174.16",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "198.32",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OBLIGATIONS",
                        width: "84.47",
                        height: "10.54",
                        left: "231.07",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "UNDER",
                        width: "42.67",
                        height: "10.54",
                        left: "322.29",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THESE",
                        width: "40.02",
                        height: "10.54",
                        left: "371.71",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TERMS",
                        width: "42.67",
                        height: "10.54",
                        left: "418.48",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "467.9",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USE",
                        width: "23.35",
                        height: "10.54",
                        left: "491.31",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "521.41",
                        top: "473.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "YOUR",
                        width: "35.33",
                        height: "10.54",
                        left: "72",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USE",
                        width: "23.35",
                        height: "10.54",
                        left: "114.83",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF,",
                        width: "18.56",
                        height: "10.54",
                        left: "145.68",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "171.74",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INABILITY",
                        width: "64.02",
                        height: "10.54",
                        left: "197.24",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "268.76",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USE,",
                        width: "26.34",
                        height: "10.54",
                        left: "293.38",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "326.47",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE",
                        width: "55.36",
                        height: "10.54",
                        left: "358.56",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "420.67",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "445.42",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONTENT,",
                        width: "61.46",
                        height: "10.54",
                        left: "478.17",
                        top: "459.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "INFORMATION,",
                        width: "91.44",
                        height: "10.54",
                        left: "72",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MATERIALS,",
                        width: "74.79",
                        height: "10.54",
                        left: "175.44",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRODUCTS,",
                        width: "69.01",
                        height: "10.54",
                        left: "262.23",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SERVICES,",
                        width: "62.6",
                        height: "10.54",
                        left: "343.24",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "417.09",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHER",
                        width: "43.34",
                        height: "10.54",
                        left: "446.34",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ITEMS",
                        width: "38.68",
                        height: "10.54",
                        left: "500.93",
                        top: "445.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OBTAINED",
                        width: "63.12",
                        height: "10.54",
                        left: "72",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THROUGH",
                        width: "62.67",
                        height: "10.54",
                        left: "139.62",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.35",
                        height: "10.54",
                        left: "206.04",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE,",
                        width: "58.36",
                        height: "10.54",
                        left: "235.14",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INCLUDING,",
                        width: "73",
                        height: "10.54",
                        left: "297.25",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BUT",
                        width: "24.68",
                        height: "10.54",
                        left: "374",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NOT",
                        width: "26",
                        height: "10.54",
                        left: "402.43",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LIMITED",
                        width: "53.35",
                        height: "10.54",
                        left: "432.18",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO,",
                        width: "20.12",
                        height: "10.54",
                        left: "489.28",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OUR",
                        width: "26.66",
                        height: "10.54",
                        left: "513.15",
                        top: "432.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "SERVICES",
                        width: "59.6",
                        height: "10.55",
                        left: "72",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.34",
                        height: "10.55",
                        left: "136.85",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONNECTION",
                        width: "82.67",
                        height: "10.55",
                        left: "155.44",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITH",
                        width: "34.01",
                        height: "10.55",
                        left: "243.36",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FACILITATING",
                        width: "86.68",
                        height: "10.55",
                        left: "282.62",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MARKETPLACE",
                        width: "94.67",
                        height: "10.55",
                        left: "374.55",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PAYMENTS",
                        width: "65.34",
                        height: "10.55",
                        left: "474.47",
                        top: "418.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "(see",
                        width: "19.32",
                        height: "10.54",
                        left: "72",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Market",
                        width: "34.65",
                        height: "10.54",
                        left: "98.07",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Place",
                        width: "25.99",
                        height: "10.54",
                        left: "139.47",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payments,",
                        width: "49.66",
                        height: "10.54",
                        left: "172.21",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "above)",
                        width: "32.65",
                        height: "10.54",
                        left: "228.62",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "268.02",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ADJUDICATING",
                        width: "92.45",
                        height: "10.54",
                        left: "292.77",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "391.22",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NOT",
                        width: "26",
                        height: "10.54",
                        left: "415.22",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ADJUDICATING",
                        width: "92.45",
                        height: "10.54",
                        left: "447.22",
                        top: "404.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "PAYMENT",
                        width: "58.67",
                        height: "10.54",
                        left: "72",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DISPUTES",
                        width: "58.69",
                        height: "10.54",
                        left: "135.17",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(see",
                        width: "19.32",
                        height: "10.54",
                        left: "198.36",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Payment",
                        width: "41.99",
                        height: "10.54",
                        left: "222.18",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Disputes,",
                        width: "45",
                        height: "10.54",
                        left: "268.67",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "above),",
                        width: "35.65",
                        height: "10.54",
                        left: "318.17",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXCEED",
                        width: "50.01",
                        height: "10.54",
                        left: "357.57",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "411.33",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "GREATER",
                        width: "58.45",
                        height: "10.54",
                        left: "440.42",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.67",
                        height: "10.54",
                        left: "502.62",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(A)",
                        width: "16.66",
                        height: "10.54",
                        left: "523.04",
                        top: "390.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "72",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TOTAL",
                        width: "40.91",
                        height: "10.54",
                        left: "101.84",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AMOUNT",
                        width: "54.66",
                        height: "10.54",
                        left: "147.25",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "206.41",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SUBSCRIPTION",
                        width: "90.03",
                        height: "10.54",
                        left: "227.57",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FEES",
                        width: "30.01",
                        height: "10.54",
                        left: "322.1",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "26",
                        height: "10.54",
                        left: "356.61",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHER",
                        width: "43.34",
                        height: "10.54",
                        left: "387.11",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PAYMENTS",
                        width: "65.34",
                        height: "10.54",
                        left: "434.2",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MADE",
                        width: "36.66",
                        height: "10.54",
                        left: "503.29",
                        top: "376.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "72",
                        top: "363.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOU",
                        width: "26.67",
                        height: "10.54",
                        left: "96.17",
                        top: "363.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "130.34",
                        top: "363.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.66",
                        height: "10.54",
                        left: "154.21",
                        top: "363.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXOTICS",
                        width: "54.02",
                        height: "10.54",
                        left: "207.62",
                        top: "363.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.33",
                        height: "10.54",
                        left: "268.39",
                        top: "363.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONNECTION",
                        width: "82.68",
                        height: "10.54",
                        left: "288.47",
                        top: "363.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITH",
                        width: "34.01",
                        height: "10.54",
                        left: "377.9",
                        top: "363.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOUR",
                        width: "35.33",
                        height: "10.54",
                        left: "418.66",
                        top: "363.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USE",
                        width: "23.34",
                        height: "10.54",
                        left: "460.74",
                        top: "363.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.67",
                        height: "10.54",
                        left: "490.83",
                        top: "363.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "514.25",
                        top: "363.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "WEBSITE",
                        width: "55.36",
                        height: "10.54",
                        left: "72",
                        top: "349.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.34",
                        height: "10.54",
                        left: "134.11",
                        top: "349.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "153.45",
                        top: "349.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TWELVE",
                        width: "51.58",
                        height: "10.54",
                        left: "184.79",
                        top: "349.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(12)",
                        width: "19.99",
                        height: "10.54",
                        left: "242.37",
                        top: "349.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MONTH",
                        width: "46.66",
                        height: "10.54",
                        left: "268.36",
                        top: "349.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PERIOD",
                        width: "46.67",
                        height: "10.54",
                        left: "321.02",
                        top: "349.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IMMEDIATELY",
                        width: "88.02",
                        height: "10.54",
                        left: "373.69",
                        top: "349.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRECEDING",
                        width: "72",
                        height: "10.54",
                        left: "467.71",
                        top: "349.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "72",
                        top: "335.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DATE",
                        width: "32.45",
                        height: "10.54",
                        left: "102.59",
                        top: "335.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.67",
                        height: "10.54",
                        left: "140.29",
                        top: "335.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "162.21",
                        top: "335.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FIRST",
                        width: "35.34",
                        height: "10.54",
                        left: "192.8",
                        top: "335.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INCIDENT",
                        width: "60.01",
                        height: "10.54",
                        left: "233.39",
                        top: "335.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "298.65",
                        top: "335.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SERIES",
                        width: "42.69",
                        height: "10.54",
                        left: "321.9",
                        top: "335.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.67",
                        height: "10.54",
                        left: "369.84",
                        top: "335.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONNECTED",
                        width: "76.67",
                        height: "10.54",
                        left: "391.76",
                        top: "335.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INCIDENTS",
                        width: "66.69",
                        height: "10.54",
                        left: "472.93",
                        top: "335.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "GIVING",
                        width: "45.34",
                        height: "10.54",
                        left: "72",
                        top: "321.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "RISE",
                        width: "28.01",
                        height: "10.54",
                        left: "121.84",
                        top: "321.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "154.35",
                        top: "321.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.67",
                        height: "10.54",
                        left: "175.97",
                        top: "321.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLAIM",
                        width: "41.33",
                        height: "10.54",
                        left: "189.14",
                        top: "321.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MADE",
                        width: "36.66",
                        height: "10.54",
                        left: "234.22",
                        top: "321.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "274.63",
                        top: "321.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOU",
                        width: "26.67",
                        height: "10.54",
                        left: "295.05",
                        top: "321.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AGAINST",
                        width: "54.68",
                        height: "10.54",
                        left: "325.47",
                        top: "321.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.66",
                        height: "10.54",
                        left: "383.9",
                        top: "321.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXOTICS",
                        width: "54.02",
                        height: "10.54",
                        left: "434.31",
                        top: "321.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "492.08",
                        top: "321.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "513.83",
                        top: "321.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OTHER",
                        width: "43.34",
                        height: "10.54",
                        left: "72",
                        top: "307.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.67",
                        height: "10.54",
                        left: "118.34",
                        top: "307.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXOTICS",
                        width: "54.01",
                        height: "10.54",
                        left: "168.01",
                        top: "307.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PARTY,",
                        width: "41.92",
                        height: "10.54",
                        left: "225.02",
                        top: "307.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "26",
                        height: "10.54",
                        left: "269.94",
                        top: "307.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(B)",
                        width: "16",
                        height: "10.54",
                        left: "298.94",
                        top: "307.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USD",
                        width: "24",
                        height: "10.54",
                        left: "317.94",
                        top: "307.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "$500.00.",
                        width: "42",
                        height: "10.54",
                        left: "344.94",
                        top: "307.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Indemnification",
                        width: "82.01",
                        height: "10.54",
                        left: "72",
                        top: "280.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "252.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.98",
                        height: "10.54",
                        left: "95.96",
                        top: "252.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "126.44",
                        top: "252.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "defend,",
                        width: "35.65",
                        height: "10.54",
                        left: "140.27",
                        top: "252.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "indemnify,",
                        width: "51.55",
                        height: "10.54",
                        left: "179.67",
                        top: "252.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "234.97",
                        top: "252.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "hold",
                        width: "21.34",
                        height: "10.54",
                        left: "256.04",
                        top: "252.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "harmless",
                        width: "42.65",
                        height: "10.54",
                        left: "281.13",
                        top: "252.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "32",
                        height: "10.54",
                        left: "327.53",
                        top: "252.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "35.99",
                        height: "10.54",
                        left: "363.28",
                        top: "252.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "403.02",
                        top: "252.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "424.1",
                        top: "252.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "439.84",
                        top: "252.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "468.25",
                        top: "252.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "35.99",
                        height: "10.54",
                        left: "503.99",
                        top: "252.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Parties",
                        width: "32.66",
                        height: "10.54",
                        left: "72",
                        top: "238.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "109.16",
                        top: "238.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "136.99",
                        top: "238.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "against",
                        width: "33.99",
                        height: "10.54",
                        left: "158.81",
                        top: "238.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "197.3",
                        top: "238.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "219.13",
                        top: "238.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "240.96",
                        top: "238.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claims,",
                        width: "34.32",
                        height: "10.54",
                        left: "257.45",
                        top: "238.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liabilities,",
                        width: "47.66",
                        height: "10.54",
                        left: "296.27",
                        top: "238.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "damages,",
                        width: "44.99",
                        height: "10.54",
                        left: "347.68",
                        top: "238.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "judgments,",
                        width: "52.99",
                        height: "10.54",
                        left: "396.42",
                        top: "238.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "penalties,",
                        width: "45.65",
                        height: "10.54",
                        left: "453.16",
                        top: "238.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "awards,",
                        width: "36.99",
                        height: "10.54",
                        left: "502.56",
                        top: "238.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "losses,",
                        width: "31.67",
                        height: "10.54",
                        left: "72",
                        top: "224.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "costs,",
                        width: "27",
                        height: "10.54",
                        left: "108.17",
                        top: "224.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "expenses,",
                        width: "46.32",
                        height: "10.54",
                        left: "139.67",
                        top: "224.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "190.49",
                        top: "224.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees,",
                        width: "22.32",
                        height: "10.54",
                        left: "204.98",
                        top: "224.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "231.8",
                        top: "224.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reasonable",
                        width: "51.31",
                        height: "10.54",
                        left: "281.63",
                        top: "224.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "attorneys’",
                        width: "47.98",
                        height: "10.54",
                        left: "337.44",
                        top: "224.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees",
                        width: "19.32",
                        height: "10.54",
                        left: "389.92",
                        top: "224.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "412.99",
                        top: "224.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "434.06",
                        top: "224.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "costs",
                        width: "24",
                        height: "10.54",
                        left: "452.47",
                        top: "224.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "480.22",
                        top: "224.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enforcing",
                        width: "45.98",
                        height: "10.54",
                        left: "493.97",
                        top: "224.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.66",
                        height: "10.54",
                        left: "93.83",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "120.99",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "indemnification",
                        width: "75.98",
                        height: "10.54",
                        left: "134.82",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "215.3",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "247.13",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "276.28",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "310.6",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use,",
                        width: "21.66",
                        height: "10.54",
                        left: "325.1",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "incurred",
                        width: "39.98",
                        height: "10.54",
                        left: "351.26",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "395.74",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "412.24",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "431.4",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "35.99",
                        height: "10.54",
                        left: "467.89",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "508.38",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "522.13",
                        top: "210.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "72",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "101.16",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "35.99",
                        height: "10.54",
                        left: "137.65",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Party",
                        width: "25.33",
                        height: "10.54",
                        left: "178.14",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arising",
                        width: "32.66",
                        height: "10.54",
                        left: "207.97",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "out",
                        width: "15.34",
                        height: "10.54",
                        left: "245.13",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "264.97",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "278.71",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "relating",
                        width: "36.65",
                        height: "10.54",
                        left: "292.46",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "332.86",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "345.94",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "violation",
                        width: "42.66",
                        height: "10.54",
                        left: "371.69",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "418.1",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "431.85",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "460.25",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "493.82",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "507.57",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.98",
                        top: "197.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "72",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "97.75",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "117.49",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "131.24",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "149.65",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "191",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "204",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transactions",
                        width: "57.31",
                        height: "10.54",
                        left: "224.33",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made",
                        width: "25.99",
                        height: "10.54",
                        left: "284.64",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "313.63",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "328.63",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "349.63",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "375.96",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "388.95",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "429.28",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "446.94",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including,",
                        width: "48.33",
                        height: "10.54",
                        left: "491.3",
                        top: "183.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "but",
                        width: "15.33",
                        height: "10.54",
                        left: "72",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "91.08",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limited",
                        width: "33.99",
                        height: "10.54",
                        left: "110.17",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to,",
                        width: "12.34",
                        height: "10.54",
                        left: "147.16",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "162.5",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "187.49",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contributions,",
                        width: "69.01",
                        height: "10.54",
                        left: "213.15",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sales",
                        width: "23.32",
                        height: "10.54",
                        left: "285.16",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "311.48",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchases",
                        width: "47.31",
                        height: "10.54",
                        left: "324.48",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "374.79",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "415.13",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.35",
                        height: "10.54",
                        left: "432.79",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "477.14",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "490.14",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "510.46",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "529.46",
                        top: "169.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "72",
                        top: "155.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website’s",
                        width: "46.36",
                        height: "10.55",
                        left: "90.41",
                        top: "155.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content,",
                        width: "38.32",
                        height: "10.55",
                        left: "140.52",
                        top: "155.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services,",
                        width: "41.65",
                        height: "10.55",
                        left: "182.59",
                        top: "155.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "59.65",
                        height: "10.55",
                        left: "227.99",
                        top: "155.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials,",
                        width: "46.98",
                        height: "10.55",
                        left: "291.39",
                        top: "155.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "products,",
                        width: "44.33",
                        height: "10.55",
                        left: "342.12",
                        top: "155.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "390.2",
                        top: "155.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.64",
                        height: "10.55",
                        left: "403.95",
                        top: "155.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "obtained,",
                        width: "44.32",
                        height: "10.55",
                        left: "446.34",
                        top: "155.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accessed,",
                        width: "44.97",
                        height: "10.55",
                        left: "494.41",
                        top: "155.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "purchased,",
                        width: "51.64",
                        height: "10.54",
                        left: "72",
                        top: "141.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "129.64",
                        top: "141.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sold",
                        width: "20",
                        height: "10.54",
                        left: "145.64",
                        top: "141.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "170.89",
                        top: "141.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "213.47",
                        top: "141.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "228.72",
                        top: "141.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "257.3",
                        top: "141.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.35",
                        height: "10.54",
                        left: "277.21",
                        top: "141.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.34",
                        height: "10.54",
                        left: "323.81",
                        top: "141.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EACH",
                        width: "34.67",
                        height: "10.54",
                        left: "342.4",
                        top: "141.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CASE",
                        width: "32.01",
                        height: "10.54",
                        left: "382.32",
                        top: "141.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WHETHER",
                        width: "63.34",
                        height: "10.54",
                        left: "419.58",
                        top: "141.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ARISING",
                        width: "51.35",
                        height: "10.54",
                        left: "488.17",
                        top: "141.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OUT",
                        width: "26",
                        height: "10.54",
                        left: "72",
                        top: "128.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.67",
                        height: "10.54",
                        left: "104.75",
                        top: "128.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "128.17",
                        top: "128.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NEGLIGENCE",
                        width: "81.35",
                        height: "10.54",
                        left: "160.26",
                        top: "128.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.67",
                        height: "10.54",
                        left: "248.36",
                        top: "128.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.66",
                        height: "10.54",
                        left: "271.78",
                        top: "128.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXOTICS",
                        width: "54.02",
                        height: "10.54",
                        left: "325.19",
                        top: "128.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "385.96",
                        top: "128.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "410.71",
                        top: "128.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHER",
                        width: "43.34",
                        height: "10.54",
                        left: "443.46",
                        top: "128.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.66",
                        height: "10.54",
                        left: "492.8",
                        top: "128.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "EXOTICS",
                        width: "54.02",
                        height: "10.54",
                        left: "72",
                        top: "114.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PARTIES",
                        width: "50.7",
                        height: "10.54",
                        left: "129.02",
                        top: "114.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "182.72",
                        top: "114.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "203.72",
                        top: "114.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHER",
                        width: "43.34",
                        height: "10.54",
                        left: "232.72",
                        top: "114.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PERSON",
                        width: "48.67",
                        height: "10.54",
                        left: "279.06",
                        top: "114.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "327.73",
                        top: "114.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Governing",
                        width: "54.67",
                        height: "10.54",
                        left: "72",
                        top: "86.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Law",
                        width: "22.67",
                        height: "10.54",
                        left: "129.67",
                        top: "86.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "155.34",
                        top: "86.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Jurisdiction",
                        width: "61.34",
                        height: "10.54",
                        left: "177.69",
                        top: "86.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,296",
                    "lineto,540,296",
                    "lineto,540,265",
                    "lineto,72,265",
                    "lineto,72,296",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,266",
                    "lineto,540,266",
                    "lineto,540,251",
                    "lineto,72,251",
                    "lineto,72,266",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,252",
                    "lineto,540,252",
                    "lineto,540,237",
                    "lineto,72,237",
                    "lineto,72,252",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,238",
                    "lineto,540,238",
                    "lineto,540,223",
                    "lineto,72,223",
                    "lineto,72,238",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,225",
                    "lineto,540,225",
                    "lineto,540,210",
                    "lineto,72,210",
                    "lineto,72,225",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,211",
                    "lineto,540,211",
                    "lineto,540,196",
                    "lineto,72,196",
                    "lineto,72,211",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,197",
                    "lineto,540,197",
                    "lineto,540,182",
                    "lineto,72,182",
                    "lineto,72,197",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,183",
                    "lineto,540,183",
                    "lineto,540,168",
                    "lineto,72,168",
                    "lineto,72,183",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,169",
                    "lineto,540,169",
                    "lineto,540,154",
                    "lineto,72,154",
                    "lineto,72,169",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,156",
                    "lineto,540,156",
                    "lineto,540,141",
                    "lineto,72,141",
                    "lineto,72,156",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,142",
                    "lineto,540,142",
                    "lineto,540,127",
                    "lineto,72,127",
                    "lineto,72,142",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,128",
                    "lineto,540,128",
                    "lineto,540,99",
                    "lineto,72,99",
                    "lineto,72,128",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,100",
                    "lineto,540,100",
                    "lineto,540,71",
                    "lineto,72,71",
                    "lineto,72,100",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,306,666.5", "lineto,540,666.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,652.5", "lineto,166,652.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,476,638.5", "lineto,539,638.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,624.5", "lineto,119,624.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,164,610.5", "lineto,254,610.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,98,405.5", "lineto,219,405.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,222,391.5", "lineto,311,391.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,281.5", "lineto,154,281.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,87.5", "lineto,239,87.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "18",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "All",
                        width: "15.33",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "matters",
                        width: "35.32",
                        height: "10.54",
                        left: "92.58",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "relating",
                        width: "36.65",
                        height: "10.54",
                        left: "133.15",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "175.05",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "189.64",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "209.55",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "253.15",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "275.73",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "305.64",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "340.71",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "355.2",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "378.36",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "400.19",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "dispute",
                        width: "34.66",
                        height: "10.54",
                        left: "422.02",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "461.18",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claim",
                        width: "26.65",
                        height: "10.54",
                        left: "475.68",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arising",
                        width: "32.66",
                        height: "10.54",
                        left: "506.83",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "therefrom",
                        width: "47.31",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "123.06",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "related",
                        width: "32.65",
                        height: "10.54",
                        left: "136.8",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "thereto",
                        width: "33.31",
                        height: "10.54",
                        left: "173.2",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(in",
                        width: "13.33",
                        height: "10.54",
                        left: "210.26",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "each",
                        width: "21.98",
                        height: "10.54",
                        left: "227.34",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "case,",
                        width: "23.65",
                        height: "10.54",
                        left: "253.07",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "279.72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "non­contractual",
                        width: "75.3",
                        height: "10.54",
                        left: "328.05",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disputes",
                        width: "39.33",
                        height: "10.54",
                        left: "406.35",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "448.68",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claims),",
                        width: "38.32",
                        height: "10.54",
                        left: "461.68",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shall",
                        width: "22.66",
                        height: "10.54",
                        left: "503",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "528.66",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "governed",
                        width: "44.65",
                        height: "10.54",
                        left: "72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "121.15",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "137.65",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "construed",
                        width: "46.66",
                        height: "10.54",
                        left: "159.47",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "210.63",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accordance",
                        width: "53.95",
                        height: "10.54",
                        left: "224.46",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "282.91",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "308.75",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "internal",
                        width: "36.65",
                        height: "10.54",
                        left: "327.91",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "laws",
                        width: "21.99",
                        height: "10.54",
                        left: "369.06",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "395.55",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "410.05",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "State",
                        width: "24",
                        height: "10.54",
                        left: "429.21",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "457.71",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Texas",
                        width: "27.82",
                        height: "10.54",
                        left: "471.45",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.66",
                        height: "10.54",
                        left: "503.02",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "giving",
                        width: "30.67",
                        height: "10.55",
                        left: "72",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "effect",
                        width: "27.09",
                        height: "10.55",
                        left: "106.42",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "137.26",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.55",
                        left: "150.34",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "choice",
                        width: "31.31",
                        height: "10.55",
                        left: "171.42",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.55",
                        left: "206.48",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "conflict",
                        width: "36.65",
                        height: "10.55",
                        left: "220.22",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.55",
                        left: "260.63",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law",
                        width: "17.33",
                        height: "10.55",
                        left: "274.37",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provision",
                        width: "45.33",
                        height: "10.55",
                        left: "295.45",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "344.53",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rule",
                        width: "18.65",
                        height: "10.55",
                        left: "358.28",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(whether",
                        width: "42.65",
                        height: "10.55",
                        left: "380.68",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.55",
                        left: "427.08",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "440.82",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "State",
                        width: "24",
                        height: "10.55",
                        left: "458.48",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.55",
                        left: "485.48",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Texas",
                        width: "27.82",
                        height: "10.55",
                        left: "498.47",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "529.29",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "92.33",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "jurisdiction).",
                        width: "61.66",
                        height: "10.54",
                        left: "119.98",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Any",
                        width: "20.67",
                        height: "10.54",
                        left: "72",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arbitration",
                        width: "49.98",
                        height: "10.54",
                        left: "97.92",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arising",
                        width: "32.66",
                        height: "10.54",
                        left: "153.15",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "out",
                        width: "15.33",
                        height: "10.54",
                        left: "190.31",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of,",
                        width: "13",
                        height: "10.54",
                        left: "210.14",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "227.64",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "related",
                        width: "32.65",
                        height: "10.54",
                        left: "242.13",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to,",
                        width: "12.33",
                        height: "10.54",
                        left: "279.28",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "296.11",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.81",
                        height: "10.54",
                        left: "325.27",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "359.58",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use,",
                        width: "21.66",
                        height: "10.54",
                        left: "374.08",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "400.24",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "419.4",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "465.26",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "479.75",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content,",
                        width: "38.32",
                        height: "10.54",
                        left: "501.58",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "materials,",
                        width: "46.98",
                        height: "10.54",
                        left: "72",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "59.65",
                        height: "10.54",
                        left: "125.73",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "products,",
                        width: "44.33",
                        height: "10.54",
                        left: "192.13",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services,",
                        width: "41.65",
                        height: "10.54",
                        left: "243.21",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "291.61",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "goods",
                        width: "28.67",
                        height: "10.54",
                        left: "308.36",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "obtained,",
                        width: "44.32",
                        height: "10.54",
                        left: "343.78",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accessed,",
                        width: "44.97",
                        height: "10.54",
                        left: "394.85",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "446.57",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made",
                        width: "25.99",
                        height: "10.54",
                        left: "463.31",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.31",
                        height: "10.54",
                        left: "496.05",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "72",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "116.83",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "138.99",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shall",
                        width: "22.66",
                        height: "10.54",
                        left: "184.85",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "215.01",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "instituted",
                        width: "44.66",
                        height: "10.54",
                        left: "233.84",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and,",
                        width: "20.33",
                        height: "10.54",
                        left: "285.25",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "312.33",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arbitration",
                        width: "49.98",
                        height: "10.54",
                        left: "331.07",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "proceedings",
                        width: "57.98",
                        height: "10.54",
                        left: "387.8",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shall",
                        width: "22.67",
                        height: "10.54",
                        left: "452.53",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "481.95",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "held,",
                        width: "23.66",
                        height: "10.54",
                        left: "500.02",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "530.43",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "accordance",
                        width: "53.95",
                        height: "10.54",
                        left: "72",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "131.2",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "157.79",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provisions",
                        width: "50",
                        height: "10.54",
                        left: "176.95",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below",
                        width: "29.33",
                        height: "10.54",
                        left: "231.45",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(see",
                        width: "19.32",
                        height: "10.54",
                        left: "265.28",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Arbitration),",
                        width: "60.31",
                        height: "10.54",
                        left: "289.1",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "exclusively",
                        width: "54.65",
                        height: "10.54",
                        left: "353.91",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "413.06",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Denton",
                        width: "35.32",
                        height: "10.54",
                        left: "426.9",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "County,",
                        width: "37.56",
                        height: "10.54",
                        left: "466.72",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Texas.",
                        width: "30.82",
                        height: "10.54",
                        left: "508.78",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "72",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "89.74",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "extent",
                        width: "29.32",
                        height: "10.54",
                        left: "109.65",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "144.22",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "action",
                        width: "29.32",
                        height: "10.54",
                        left: "166.8",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "201.37",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "proceeding",
                        width: "53.3",
                        height: "10.54",
                        left: "216.62",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "involves",
                        width: "40.67",
                        height: "10.54",
                        left: "275.17",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.3200000000001",
                        height: "10.54",
                        left: "321.09",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claim,",
                        width: "29.66",
                        height: "10.54",
                        left: "331.66",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "dispute,",
                        width: "37.66",
                        height: "10.54",
                        left: "366.57",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "409.48",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "issue",
                        width: "24",
                        height: "10.54",
                        left: "424.73",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arising",
                        width: "32.66",
                        height: "10.54",
                        left: "453.98",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "out",
                        width: "15.33",
                        height: "10.54",
                        left: "491.89",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of,",
                        width: "13",
                        height: "10.54",
                        left: "512.47",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "529.97",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "related",
                        width: "32.64",
                        height: "10.54",
                        left: "72",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to,",
                        width: "12.34",
                        height: "10.54",
                        left: "109.89",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "127.48",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "157.38",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "192.45",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use,",
                        width: "21.66",
                        height: "10.54",
                        left: "207.7",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "234.61",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "254.52",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "300.38",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "314.87",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content,",
                        width: "38.32",
                        height: "10.54",
                        left: "336.7",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials,",
                        width: "46.98",
                        height: "10.54",
                        left: "379.52",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "59.65",
                        height: "10.54",
                        left: "431",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "products,",
                        width: "44.33",
                        height: "10.54",
                        left: "495.15",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "services,",
                        width: "41.65",
                        height: "10.55",
                        left: "72",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.55",
                        left: "120.4",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "goods",
                        width: "28.67",
                        height: "10.55",
                        left: "137.14",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "obtained,",
                        width: "44.32",
                        height: "10.55",
                        left: "172.56",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accessed,",
                        width: "44.98",
                        height: "10.55",
                        left: "223.63",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.55",
                        left: "275.36",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made",
                        width: "25.99",
                        height: "10.55",
                        left: "292.1",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.3",
                        height: "10.55",
                        left: "324.84",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.55",
                        left: "374.89",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "418.22",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.55",
                        left: "438.88",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.55",
                        left: "486.24",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.55",
                        left: "510.23",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.55",
                        left: "524.24",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "arbitrable",
                        width: "45.97",
                        height: "10.54",
                        left: "72",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pursuant",
                        width: "41.33",
                        height: "10.54",
                        left: "122.47",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "168.3",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applicable",
                        width: "49.31",
                        height: "10.54",
                        left: "182.13",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law,",
                        width: "19.55",
                        height: "10.54",
                        left: "235.94",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "259.99",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "action",
                        width: "29.32",
                        height: "10.54",
                        left: "285.73",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "318.8",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "proceeding",
                        width: "53.31",
                        height: "10.54",
                        left: "332.55",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shall",
                        width: "22.66",
                        height: "10.54",
                        left: "389.61",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "416.02",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "brought",
                        width: "37.33",
                        height: "10.54",
                        left: "431.1",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "exclusively",
                        width: "54.65",
                        height: "10.54",
                        left: "472.18",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.3299999999999",
                        height: "10.54",
                        left: "530.58",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "72",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "courts",
                        width: "29.33",
                        height: "10.54",
                        left: "90.41",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "123.49",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "137.23",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "State",
                        width: "24",
                        height: "10.54",
                        left: "155.64",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "183.39",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Texas",
                        width: "27.82",
                        height: "10.54",
                        left: "197.13",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "228.7",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "241.69",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "federal",
                        width: "33.31",
                        height: "10.54",
                        left: "259.35",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "courts",
                        width: "29.32",
                        height: "10.54",
                        left: "295.66",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "327.98",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "340.98",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "United",
                        width: "32.66",
                        height: "10.54",
                        left: "358.64",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "States,",
                        width: "31.66",
                        height: "10.54",
                        left: "394.3",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "428.96",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "each",
                        width: "21.98",
                        height: "10.54",
                        left: "441.3",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "case,",
                        width: "23.65",
                        height: "10.54",
                        left: "466.28",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "located",
                        width: "34.64",
                        height: "10.54",
                        left: "492.93",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.3399999999999",
                        height: "10.54",
                        left: "530.57",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Denton",
                        width: "35.33",
                        height: "10.54",
                        left: "72",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "County,",
                        width: "37.55",
                        height: "10.54",
                        left: "113.33",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Texas.",
                        width: "30.82",
                        height: "10.54",
                        left: "156.13",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Notwithstanding",
                        width: "80",
                        height: "10.54",
                        left: "192.2",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "277.45",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "foregoing,",
                        width: "49.65",
                        height: "10.54",
                        left: "297.36",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "352.26",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "retain",
                        width: "27.32",
                        height: "10.54",
                        left: "371.5",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "404.07",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.66",
                        height: "10.54",
                        left: "423.98",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "451.89",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bring",
                        width: "25.33",
                        height: "10.54",
                        left: "466.48",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "497.06",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "suit,",
                        width: "20.34",
                        height: "10.54",
                        left: "519.63",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "action,",
                        width: "32.32",
                        height: "10.54",
                        left: "72",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "108.07",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "proceeding",
                        width: "53.31",
                        height: "10.54",
                        left: "121.82",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "against",
                        width: "33.99",
                        height: "10.54",
                        left: "178.88",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "216.62",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "238.37",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "breach",
                        width: "31.97",
                        height: "10.54",
                        left: "256.11",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "291.83",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "305.58",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "333.98",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "367.55",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "381.3",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "403.71",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "416.79",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "country",
                        width: "36.66",
                        height: "10.54",
                        left: "441.79",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "481.45",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "residence",
                        width: "45.31",
                        height: "10.54",
                        left: "494.44",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "86.5",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "108.32",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "relevant",
                        width: "38.64",
                        height: "10.54",
                        left: "137.48",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "country.",
                        width: "38.88",
                        height: "10.54",
                        left: "180.62",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "224",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "waive",
                        width: "28.66",
                        height: "10.54",
                        left: "247.96",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "281.12",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "302.94",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "324.77",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "objections",
                        width: "49.33",
                        height: "10.54",
                        left: "341.26",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "395.09",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "408.92",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "exercise",
                        width: "39.3",
                        height: "10.54",
                        left: "428.08",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "471.13",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "jurisdiction",
                        width: "54.66",
                        height: "10.54",
                        left: "484.88",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "over",
                        width: "21.32",
                        height: "10.54",
                        left: "72",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "96.32",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "117.32",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "132.32",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "courts",
                        width: "29.32",
                        height: "10.54",
                        left: "157.32",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "189.64",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "209.97",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "venue",
                        width: "28.66",
                        height: "10.54",
                        left: "222.3",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "253.96",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "266.29",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "courts.",
                        width: "32.32",
                        height: "10.54",
                        left: "291.29",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Arbitration",
                        width: "59.33",
                        height: "10.54",
                        left: "72",
                        top: "431.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "YOU",
                        width: "26.67",
                        height: "10.54",
                        left: "72",
                        top: "406.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "25.99",
                        height: "10.54",
                        left: "105.42",
                        top: "406.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.67",
                        height: "10.54",
                        left: "138.16",
                        top: "406.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXOTICS",
                        width: "54.02",
                        height: "10.54",
                        left: "191.58",
                        top: "406.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ARE",
                        width: "25.33",
                        height: "10.54",
                        left: "252.35",
                        top: "406.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AGREEING",
                        width: "65.35",
                        height: "10.54",
                        left: "284.43",
                        top: "406.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "355.78",
                        top: "406.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "GIVE",
                        width: "30.67",
                        height: "10.54",
                        left: "378.9",
                        top: "406.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "UP",
                        width: "16",
                        height: "10.54",
                        left: "415.57",
                        top: "406.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "25.99",
                        height: "10.54",
                        left: "437.57",
                        top: "406.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "RIGHTS",
                        width: "46.69",
                        height: "10.54",
                        left: "469.56",
                        top: "406.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "522.25",
                        top: "406.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "LITIGATE",
                        width: "58.46",
                        height: "10.55",
                        left: "72",
                        top: "392.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLAIMS",
                        width: "48.01",
                        height: "10.55",
                        left: "134.96",
                        top: "392.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.34",
                        height: "10.55",
                        left: "187.47",
                        top: "392.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.66",
                        height: "10.55",
                        left: "204.56",
                        top: "392.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "COURT",
                        width: "42.92",
                        height: "10.55",
                        left: "216.97",
                        top: "392.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.55",
                        left: "263.64",
                        top: "392.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BEFORE",
                        width: "49.34",
                        height: "10.55",
                        left: "285.39",
                        top: "392.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.66",
                        height: "10.55",
                        left: "338.48",
                        top: "392.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "JURY,",
                        width: "33.48",
                        height: "10.55",
                        left: "350.89",
                        top: "392.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.55",
                        left: "388.12",
                        top: "392.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.55",
                        left: "409.87",
                        top: "392.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PARTICIPATE",
                        width: "79.58",
                        height: "10.55",
                        left: "430.74",
                        top: "392.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.34",
                        height: "10.55",
                        left: "514.07",
                        top: "392.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.6600000000001",
                        height: "10.55",
                        left: "531.16",
                        top: "392.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "CLASS",
                        width: "38.68",
                        height: "10.54",
                        left: "72",
                        top: "378.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACTION",
                        width: "48.01",
                        height: "10.54",
                        left: "115.93",
                        top: "378.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "169.19",
                        top: "378.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "REPRESENTATIVE",
                        width: "108.25",
                        height: "10.54",
                        left: "192.44",
                        top: "378.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACTION",
                        width: "48",
                        height: "10.54",
                        left: "305.94",
                        top: "378.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITH",
                        width: "34.01",
                        height: "10.54",
                        left: "359.19",
                        top: "378.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "RESPECT",
                        width: "55.35",
                        height: "10.54",
                        left: "398.45",
                        top: "378.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "459.05",
                        top: "378.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.66",
                        height: "10.54",
                        left: "481.42",
                        top: "378.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLAIM.",
                        width: "44.34",
                        height: "10.54",
                        left: "495.33",
                        top: "378.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OTHER",
                        width: "43.34",
                        height: "10.54",
                        left: "72",
                        top: "364.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "RIGHTS",
                        width: "46.68",
                        height: "10.54",
                        left: "119.09",
                        top: "364.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THAT",
                        width: "33.12",
                        height: "10.54",
                        left: "169.52",
                        top: "364.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOU",
                        width: "26.67",
                        height: "10.54",
                        left: "206.39",
                        top: "364.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WOULD",
                        width: "46.67",
                        height: "10.54",
                        left: "236.81",
                        top: "364.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "HAVE",
                        width: "33.12",
                        height: "10.54",
                        left: "287.23",
                        top: "364.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IF",
                        width: "12",
                        height: "10.54",
                        left: "324.1",
                        top: "364.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOU",
                        width: "26.67",
                        height: "10.54",
                        left: "339.85",
                        top: "364.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WENT",
                        width: "36.67",
                        height: "10.54",
                        left: "370.27",
                        top: "364.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "410.69",
                        top: "364.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "COURT",
                        width: "42.91",
                        height: "10.54",
                        left: "430.81",
                        top: "364.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MAY",
                        width: "27.56",
                        height: "10.54",
                        left: "476.72",
                        top: "364.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ALSO",
                        width: "32.68",
                        height: "10.54",
                        left: "507.28",
                        top: "364.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "BE",
                        width: "16.01",
                        height: "10.54",
                        left: "72",
                        top: "350.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "UNAVAILABLE",
                        width: "85.59",
                        height: "10.54",
                        left: "91.01",
                        top: "350.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "179.6",
                        top: "350.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MAY",
                        width: "27.55",
                        height: "10.54",
                        left: "200.6",
                        top: "350.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BE",
                        width: "16.01",
                        height: "10.54",
                        left: "231.15",
                        top: "350.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LIMITED",
                        width: "53.34",
                        height: "10.54",
                        left: "250.16",
                        top: "350.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.34",
                        height: "10.54",
                        left: "306.5",
                        top: "350.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ARBITRATION.",
                        width: "88.12",
                        height: "10.54",
                        left: "322.84",
                        top: "350.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "72",
                        top: "325.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLAIM,",
                        width: "44.33",
                        height: "10.54",
                        left: "101.75",
                        top: "325.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "DISPUTE",
                        width: "52.01",
                        height: "10.54",
                        left: "149.83",
                        top: "325.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "205.59",
                        top: "325.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONTROVERSY",
                        width: "93.35",
                        height: "10.54",
                        left: "227.34",
                        top: "325.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(WHETHER",
                        width: "67.34",
                        height: "10.54",
                        left: "324.44",
                        top: "325.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.34",
                        height: "10.54",
                        left: "394.78",
                        top: "325.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONTRACT,",
                        width: "70.78",
                        height: "10.54",
                        left: "411.12",
                        top: "325.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TORT",
                        width: "33.37",
                        height: "10.54",
                        left: "484.9",
                        top: "325.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "521.27",
                        top: "325.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OTHERWISE,",
                        width: "77.27",
                        height: "10.54",
                        left: "72",
                        top: "311.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WHETHER",
                        width: "63.34",
                        height: "10.54",
                        left: "163.52",
                        top: "311.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRE­EXISTING,",
                        width: "89.69",
                        height: "10.54",
                        left: "241.11",
                        top: "311.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRESENT",
                        width: "55.34",
                        height: "10.54",
                        left: "345.05",
                        top: "311.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "414.64",
                        top: "311.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FUTURE,",
                        width: "52.34",
                        height: "10.54",
                        left: "446.89",
                        top: "311.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "26",
                        height: "10.54",
                        left: "513.48",
                        top: "311.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "INCLUDING",
                        width: "70.01",
                        height: "10.54",
                        left: "72",
                        top: "297.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "STATUTORY,",
                        width: "74.16",
                        height: "10.54",
                        left: "158.51",
                        top: "297.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONSUMER",
                        width: "70",
                        height: "10.54",
                        left: "249.17",
                        top: "297.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PROTECTION,",
                        width: "83.68",
                        height: "10.54",
                        left: "335.67",
                        top: "297.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "COMMON",
                        width: "58.65",
                        height: "10.54",
                        left: "435.85",
                        top: "297.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LAW,",
                        width: "29.24",
                        height: "10.54",
                        left: "510.25",
                        top: "297.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "INTENTIONAL",
                        width: "85.35",
                        height: "10.54",
                        left: "72",
                        top: "283.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TORT,",
                        width: "35.48",
                        height: "10.54",
                        left: "162.6",
                        top: "283.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INJUNCTIVE",
                        width: "74.68",
                        height: "10.54",
                        left: "203.33",
                        top: "283.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "26",
                        height: "10.54",
                        left: "283.26",
                        top: "283.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EQUITABLE",
                        width: "70.46",
                        height: "10.54",
                        left: "314.51",
                        top: "283.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLAIMS)",
                        width: "52",
                        height: "10.54",
                        left: "390.22",
                        top: "283.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BETWEEN",
                        width: "60.69",
                        height: "10.54",
                        left: "447.47",
                        top: "283.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOU",
                        width: "26.67",
                        height: "10.54",
                        left: "512.66",
                        top: "283.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "AND",
                        width: "26",
                        height: "10.54",
                        left: "72",
                        top: "269.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "US",
                        width: "15.34",
                        height: "10.54",
                        left: "101.75",
                        top: "269.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ARISING",
                        width: "51.34",
                        height: "10.54",
                        left: "120.09",
                        top: "269.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FROM",
                        width: "36.66",
                        height: "10.54",
                        left: "174.43",
                        top: "269.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "214.09",
                        top: "269.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "RELATING",
                        width: "63.12",
                        height: "10.54",
                        left: "235.09",
                        top: "269.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.34",
                        height: "10.54",
                        left: "301.21",
                        top: "269.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "317.55",
                        top: "269.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WAY",
                        width: "26.9",
                        height: "10.54",
                        left: "346.55",
                        top: "269.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THESE",
                        width: "40.02",
                        height: "10.54",
                        left: "376.45",
                        top: "269.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TERMS",
                        width: "42.67",
                        height: "10.54",
                        left: "419.47",
                        top: "269.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "465.14",
                        top: "269.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USE,",
                        width: "26.35",
                        height: "10.54",
                        left: "484.8",
                        top: "269.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "514.15",
                        top: "269.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "WEBSITE,",
                        width: "58.36",
                        height: "10.54",
                        left: "72",
                        top: "256.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "142.36",
                        top: "256.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "171.61",
                        top: "256.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONTENT,",
                        width: "61.45",
                        height: "10.54",
                        left: "208.86",
                        top: "256.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MATERIALS,",
                        width: "74.79",
                        height: "10.54",
                        left: "281.56",
                        top: "256.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "INFORMATION,",
                        width: "91.44",
                        height: "10.54",
                        left: "367.6",
                        top: "256.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRODUCTS,",
                        width: "69.01",
                        height: "10.54",
                        left: "470.29",
                        top: "256.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "SERVICES,",
                        width: "62.6",
                        height: "10.54",
                        left: "72",
                        top: "242.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "138.35",
                        top: "242.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "GOODS",
                        width: "43.34",
                        height: "10.54",
                        left: "159.35",
                        top: "242.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OBTAINED,",
                        width: "66.13",
                        height: "10.54",
                        left: "205.69",
                        top: "242.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACCESSED,",
                        width: "67.02",
                        height: "10.54",
                        left: "274.82",
                        top: "242.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "344.84",
                        top: "242.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MADE",
                        width: "36.66",
                        height: "10.54",
                        left: "365.84",
                        top: "242.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AVAILABLE",
                        width: "68.25",
                        height: "10.54",
                        left: "405.5",
                        top: "242.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THROUGH",
                        width: "62.68",
                        height: "10.54",
                        left: "476.75",
                        top: "242.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "72",
                        top: "228.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE,",
                        width: "58.36",
                        height: "10.54",
                        left: "101.84",
                        top: "228.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WILL",
                        width: "32.68",
                        height: "10.54",
                        left: "164.7",
                        top: "228.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BE",
                        width: "16.01",
                        height: "10.54",
                        left: "201.88",
                        top: "228.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "RESOLVED",
                        width: "64.91",
                        height: "10.54",
                        left: "222.39",
                        top: "228.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "EXCLUSIVELY",
                        width: "85.59",
                        height: "10.54",
                        left: "291.05",
                        top: "228.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AND",
                        width: "26",
                        height: "10.54",
                        left: "380.39",
                        top: "228.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "FINALLY",
                        width: "52.9",
                        height: "10.54",
                        left: "410.14",
                        top: "228.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "466.79",
                        top: "228.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BINDING",
                        width: "52.68",
                        height: "10.54",
                        left: "487.21",
                        top: "228.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "ARBITRATION.",
                        width: "88.13",
                        height: "10.54",
                        left: "72",
                        top: "214.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "144",
                        top: "188.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arbitration",
                        width: "49.98",
                        height: "10.54",
                        left: "168.66",
                        top: "188.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "224.64",
                        top: "188.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "249.3",
                        top: "188.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "administered",
                        width: "61.98",
                        height: "10.54",
                        left: "266.63",
                        top: "188.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "334.61",
                        top: "188.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "351.86",
                        top: "188.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "American",
                        width: "47.31",
                        height: "10.54",
                        left: "371.77",
                        top: "188.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Arbitration",
                        width: "53.32",
                        height: "10.54",
                        left: "424.33",
                        top: "188.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Association",
                        width: "56.66",
                        height: "10.54",
                        left: "482.9",
                        top: "188.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: '("',
                        width: "8.89",
                        height: "10.54",
                        left: "108",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "AAA",
                        width: "26",
                        height: "10.54",
                        left: "116.89",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '")',
                        width: "8.9",
                        height: "10.54",
                        left: "142.89",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "155.54",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accordance",
                        width: "53.95",
                        height: "10.54",
                        left: "168.62",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "226.32",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "251.41",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Consumer",
                        width: "49.33",
                        height: "10.54",
                        left: "269.82",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Arbitration",
                        width: "53.32",
                        height: "10.54",
                        left: "322.9",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Rules",
                        width: "27.33",
                        height: "10.54",
                        left: "379.97",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(the",
                        width: "18.66",
                        height: "10.54",
                        left: "410.3",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"',
                        width: "4.9",
                        height: "10.54",
                        left: "431.96",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "AAA",
                        width: "26",
                        height: "10.54",
                        left: "436.86",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Rules",
                        width: "28.67",
                        height: "10.54",
                        left: "465.86",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '")',
                        width: "8.89",
                        height: "10.54",
                        left: "494.53",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "then",
                        width: "20.66",
                        height: "10.54",
                        left: "506.42",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.3299999999999",
                        height: "10.54",
                        left: "530.08",
                        top: "175.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "effect,",
                        width: "30.09",
                        height: "10.54",
                        left: "108",
                        top: "161.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "except",
                        width: "31.31",
                        height: "10.54",
                        left: "144.09",
                        top: "161.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "181.4",
                        top: "161.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "modified",
                        width: "43.32",
                        height: "10.54",
                        left: "197.4",
                        top: "161.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "246.72",
                        top: "161.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "264.72",
                        top: "161.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "295.38",
                        top: "161.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "331.2",
                        top: "161.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "347.19",
                        top: "161.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(The",
                        width: "22.66",
                        height: "10.54",
                        left: "371.1",
                        top: "161.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AAA",
                        width: "25.99",
                        height: "10.54",
                        left: "399.01",
                        top: "161.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Rules",
                        width: "27.34",
                        height: "10.54",
                        left: "430.25",
                        top: "161.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "462.84",
                        top: "161.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.3",
                        height: "10.54",
                        left: "482.74",
                        top: "161.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.6600000000001",
                        height: "10.54",
                        left: "531.29",
                        top: "161.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "www.adr.org/arb_med",
                        width: "106.97",
                        height: "10.54",
                        left: "108",
                        top: "147.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "223.97",
                        top: "147.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "242.97",
                        top: "147.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "calling",
                        width: "32.66",
                        height: "10.54",
                        left: "263.97",
                        top: "147.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "305.63",
                        top: "147.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AAA",
                        width: "25.99",
                        height: "10.54",
                        left: "329.29",
                        top: "147.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "364.28",
                        top: "147.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "1­800­778­7879.)",
                        width: "84.99",
                        height: "10.54",
                        left: "381.94",
                        top: "147.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "The",
                        width: "18.65",
                        height: "10.54",
                        left: "475.93",
                        top: "147.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Federal",
                        width: "35.99",
                        height: "10.54",
                        left: "503.58",
                        top: "147.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Arbitration",
                        width: "53.32",
                        height: "10.55",
                        left: "108",
                        top: "133.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Act",
                        width: "17.33",
                        height: "10.55",
                        left: "164.32",
                        top: "133.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.55",
                        left: "184.65",
                        top: "133.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "govern",
                        width: "33.33",
                        height: "10.55",
                        left: "206.31",
                        top: "133.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "242.64",
                        top: "133.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interpretation",
                        width: "64.64",
                        height: "10.55",
                        left: "260.3",
                        top: "133.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.55",
                        left: "327.94",
                        top: "133.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enforcement",
                        width: "59.97",
                        height: "10.55",
                        left: "348.26",
                        top: "133.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.55",
                        left: "411.23",
                        top: "133.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.55",
                        left: "424.22",
                        top: "133.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "section.",
                        width: "36.99",
                        height: "10.55",
                        left: "444.56",
                        top: "133.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "144",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arbitrator",
                        width: "44.64",
                        height: "10.54",
                        left: "168.66",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "219.3",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "243.97",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "exclusive",
                        width: "45.32",
                        height: "10.54",
                        left: "272.62",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "authority",
                        width: "43.32",
                        height: "10.54",
                        left: "323.19",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "371.76",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "resolve",
                        width: "34.66",
                        height: "10.54",
                        left: "386.34",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "426.25",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "dispute",
                        width: "34.67",
                        height: "10.54",
                        left: "448.82",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "relating",
                        width: "36.65",
                        height: "10.54",
                        left: "488.74",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "530.64",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "arbitrability",
                        width: "56.65",
                        height: "10.54",
                        left: "108",
                        top: "94.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and/or",
                        width: "30.65",
                        height: "10.54",
                        left: "175.9",
                        top: "94.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enforceability",
                        width: "66.64",
                        height: "10.54",
                        left: "217.8",
                        top: "94.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "295.69",
                        top: "94.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "316.93",
                        top: "94.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arbitration",
                        width: "49.98",
                        height: "10.54",
                        left: "345.52",
                        top: "94.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provision,",
                        width: "48.34",
                        height: "10.54",
                        left: "406.75",
                        top: "94.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.32",
                        height: "10.54",
                        left: "466.34",
                        top: "94.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "522.16",
                        top: "94.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "unconscionability",
                        width: "85.32",
                        height: "10.54",
                        left: "108",
                        top: "80.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "challenge",
                        width: "45.97",
                        height: "10.54",
                        left: "197.82",
                        top: "80.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "248.29",
                        top: "80.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "262.79",
                        top: "80.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "284.61",
                        top: "80.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "challenge",
                        width: "45.97",
                        height: "10.54",
                        left: "313.77",
                        top: "80.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "364.24",
                        top: "80.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "386.74",
                        top: "80.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arbitration",
                        width: "49.98",
                        height: "10.54",
                        left: "405.9",
                        top: "80.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provision",
                        width: "45.33",
                        height: "10.54",
                        left: "460.38",
                        top: "80.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "510.21",
                        top: "80.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.71",
                        top: "80.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,720",
                    "lineto,540,720",
                    "lineto,540,705",
                    "lineto,72,705",
                    "lineto,72,720",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,706",
                    "lineto,540,706",
                    "lineto,540,691",
                    "lineto,72,691",
                    "lineto,72,706",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,692",
                    "lineto,540,692",
                    "lineto,540,677",
                    "lineto,72,677",
                    "lineto,72,692",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,679",
                    "lineto,540,679",
                    "lineto,540,664",
                    "lineto,72,664",
                    "lineto,72,679",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,665",
                    "lineto,540,665",
                    "lineto,540,636",
                    "lineto,72,636",
                    "lineto,72,665",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,433.5", "lineto,131,433.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "19",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "agreement",
                        width: "49.97",
                        height: "10.54",
                        left: "108",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "162.47",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "void,",
                        width: "24.34",
                        height: "10.54",
                        left: "174.97",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "voidable,",
                        width: "44.32",
                        height: "10.54",
                        left: "203.81",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "252.63",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.66",
                        height: "10.54",
                        left: "267.12",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "invalid.",
                        width: "36.32",
                        height: "10.54",
                        left: "318.28",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "359.1",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arbitrator",
                        width: "44.64",
                        height: "10.54",
                        left: "382.26",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "431.4",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "454.57",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "empowered",
                        width: "55.97",
                        height: "10.54",
                        left: "470.4",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "530.12",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "grant",
                        width: "24.66",
                        height: "10.54",
                        left: "108",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "whatever",
                        width: "43.97",
                        height: "10.54",
                        left: "135.66",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "relief",
                        width: "25.31",
                        height: "10.54",
                        left: "182.63",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "would",
                        width: "30",
                        height: "10.54",
                        left: "210.94",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "243.94",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.31",
                        height: "10.54",
                        left: "258.27",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "304.58",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "court",
                        width: "24.66",
                        height: "10.54",
                        left: "316.91",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "344.57",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law",
                        width: "17.32",
                        height: "10.54",
                        left: "374.89",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "395.21",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "408.21",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "equity.",
                        width: "32.22",
                        height: "10.54",
                        left: "420.54",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Any",
                        width: "20.67",
                        height: "10.54",
                        left: "455.76",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "award",
                        width: "29.31",
                        height: "10.54",
                        left: "479.43",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "511.74",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.74",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "arbitrator(s)",
                        width: "57.3",
                        height: "10.54",
                        left: "108",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "170.55",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "194.47",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "final",
                        width: "21.99",
                        height: "10.54",
                        left: "211.05",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "238.29",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "binding",
                        width: "36.66",
                        height: "10.54",
                        left: "260.12",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "301.28",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "each",
                        width: "21.98",
                        height: "10.54",
                        left: "317.78",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "344.26",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "358.76",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties,",
                        width: "34.98",
                        height: "10.54",
                        left: "377.92",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "417.4",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "439.23",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "464.39",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "entered",
                        width: "35.31",
                        height: "10.54",
                        left: "480.22",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "520.03",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "534.52",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "judgment",
                        width: "45.33",
                        height: "10.55",
                        left: "108",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.55",
                        left: "156.33",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.55",
                        left: "168.66",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "court",
                        width: "24.65",
                        height: "10.55",
                        left: "188.99",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "216.64",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "competent",
                        width: "49.98",
                        height: "10.55",
                        left: "229.64",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "jurisdiction.",
                        width: "57.66",
                        height: "10.55",
                        left: "282.62",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.98",
                        height: "10.54",
                        left: "95.96",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "126.44",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "140.27",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arbitration",
                        width: "49.98",
                        height: "10.54",
                        left: "156.1",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "210.58",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "227.08",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "individual",
                        width: "48.66",
                        height: "10.54",
                        left: "242.91",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "basis.",
                        width: "27",
                        height: "10.54",
                        left: "296.07",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "In",
                        width: "9.99",
                        height: "10.54",
                        left: "327.57",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "341.31",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "dispute,",
                        width: "37.66",
                        height: "10.54",
                        left: "362.39",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NEITHER",
                        width: "55.35",
                        height: "10.54",
                        left: "403.8",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOU",
                        width: "26.67",
                        height: "10.54",
                        left: "462.9",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MOORE",
                        width: "46.66",
                        height: "10.54",
                        left: "493.32",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "EXOTICS",
                        width: "54.02",
                        height: "10.54",
                        left: "72",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WILL",
                        width: "32.68",
                        height: "10.54",
                        left: "133.52",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BE",
                        width: "16",
                        height: "10.54",
                        left: "173.7",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ENTITLED",
                        width: "62.02",
                        height: "10.54",
                        left: "196.45",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.13",
                        height: "10.54",
                        left: "265.22",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "JOIN",
                        width: "28.67",
                        height: "10.54",
                        left: "289.1",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "324.52",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONSOLIDATE",
                        width: "87.79",
                        height: "10.54",
                        left: "349.27",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLAIMS",
                        width: "48.01",
                        height: "10.54",
                        left: "443.81",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BY",
                        width: "16.67",
                        height: "10.54",
                        left: "498.57",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "521.99",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "AGAINST",
                        width: "54.68",
                        height: "10.54",
                        left: "72",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHER",
                        width: "43.34",
                        height: "10.54",
                        left: "130.43",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CUSTOMERS",
                        width: "75.8",
                        height: "10.54",
                        left: "177.52",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.33",
                        height: "10.54",
                        left: "256.32",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "COURT",
                        width: "42.92",
                        height: "10.54",
                        left: "272.65",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "318.57",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.33",
                        height: "10.54",
                        left: "339.57",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ARBITRATION",
                        width: "85.13",
                        height: "10.54",
                        left: "355.9",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "444.03",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHERWISE",
                        width: "74.27",
                        height: "10.54",
                        left: "465.03",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "PARTICIPATE",
                        width: "79.58",
                        height: "10.54",
                        left: "72",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.34",
                        height: "10.54",
                        left: "156.08",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "173.92",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLAIM",
                        width: "41.33",
                        height: "10.54",
                        left: "204.42",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AS",
                        width: "15.34",
                        height: "10.54",
                        left: "250.25",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.66",
                        height: "10.54",
                        left: "270.09",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLASS",
                        width: "38.69",
                        height: "10.54",
                        left: "283.25",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "REPRESENTATIVE,",
                        width: "111.24",
                        height: "10.54",
                        left: "326.44",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLASS",
                        width: "38.69",
                        height: "10.54",
                        left: "442.18",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MEMBER",
                        width: "55.33",
                        height: "10.54",
                        left: "484.62",
                        top: "595.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "72",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IN",
                        width: "13.34",
                        height: "10.54",
                        left: "95.25",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.66",
                        height: "10.54",
                        left: "113.84",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRIVATE",
                        width: "51.57",
                        height: "10.54",
                        left: "127.75",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ATTORNEY",
                        width: "66.9",
                        height: "10.54",
                        left: "184.57",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "GENERAL",
                        width: "59.35",
                        height: "10.54",
                        left: "256.72",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CAPACITY",
                        width: "62.44",
                        height: "10.54",
                        left: "321.32",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "383.76",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "392.01",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arbitral",
                        width: "34.64",
                        height: "10.54",
                        left: "415.92",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "tribunal",
                        width: "37.33",
                        height: "10.54",
                        left: "455.81",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "498.39",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "524.3",
                        top: "581.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "consolidate",
                        width: "54.65",
                        height: "10.54",
                        left: "72",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "more",
                        width: "24.66",
                        height: "10.54",
                        left: "131.9",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "than",
                        width: "20.66",
                        height: "10.54",
                        left: "161.06",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "one",
                        width: "17.32",
                        height: "10.54",
                        left: "186.22",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "person's",
                        width: "38.83",
                        height: "10.54",
                        left: "208.04",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claims",
                        width: "31.32",
                        height: "10.54",
                        left: "251.37",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "287.19",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "309.02",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "334.18",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "354.01",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "preside",
                        width: "34.66",
                        height: "10.54",
                        left: "405.16",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "over",
                        width: "21.32",
                        height: "10.54",
                        left: "444.32",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "470.14",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "form",
                        width: "23.33",
                        height: "10.54",
                        left: "491.96",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "519.79",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.3200000000001",
                        height: "10.54",
                        left: "534.29",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "representative",
                        width: "67.29",
                        height: "10.54",
                        left: "72",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "147.54",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "class",
                        width: "23.33",
                        height: "10.54",
                        left: "165.79",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "proceeding.",
                        width: "56.31",
                        height: "10.54",
                        left: "197.37",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "The",
                        width: "18.65",
                        height: "10.54",
                        left: "261.93",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arbitral",
                        width: "34.65",
                        height: "10.54",
                        left: "288.83",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "tribunal",
                        width: "37.32",
                        height: "10.54",
                        left: "331.73",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "has",
                        width: "16",
                        height: "10.54",
                        left: "377.3",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "401.55",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "power",
                        width: "29.99",
                        height: "10.54",
                        left: "421.8",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "460.04",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "consider",
                        width: "40.65",
                        height: "10.54",
                        left: "476.87",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.02",
                        top: "554.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "enforceability",
                        width: "66.63",
                        height: "10.55",
                        left: "72",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "143.13",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.55",
                        left: "157.63",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "class",
                        width: "23.32",
                        height: "10.55",
                        left: "179.47",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arbitration",
                        width: "49.98",
                        height: "10.55",
                        left: "207.29",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "waiver",
                        width: "32.65",
                        height: "10.55",
                        left: "261.77",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "298.92",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.55",
                        left: "320.75",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "challenge",
                        width: "45.98",
                        height: "10.55",
                        left: "342.57",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "393.05",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "406.88",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "class",
                        width: "23.33",
                        height: "10.55",
                        left: "426.04",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "arbitration",
                        width: "49.98",
                        height: "10.55",
                        left: "453.12",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "waiver",
                        width: "32.65",
                        height: "10.55",
                        left: "506.85",
                        top: "540.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "72",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "only",
                        width: "21.33",
                        height: "10.54",
                        left: "95.66",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "119.99",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "raised",
                        width: "28.65",
                        height: "10.54",
                        left: "134.32",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "165.97",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "178.31",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "court",
                        width: "24.66",
                        height: "10.54",
                        left: "186.63",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "214.29",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "competent",
                        width: "49.98",
                        height: "10.54",
                        left: "227.29",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "jurisdiction.",
                        width: "57.66",
                        height: "10.54",
                        left: "280.27",
                        top: "526.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Limitation",
                        width: "54.67",
                        height: "10.54",
                        left: "72",
                        top: "498.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12.67",
                        height: "10.54",
                        left: "129.67",
                        top: "498.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Time",
                        width: "26.45",
                        height: "10.54",
                        left: "145.34",
                        top: "498.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.99",
                        height: "10.54",
                        left: "174.79",
                        top: "498.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "File",
                        width: "19.33",
                        height: "10.54",
                        left: "187.78",
                        top: "498.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Claims",
                        width: "36",
                        height: "10.54",
                        left: "210.11",
                        top: "498.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "ANY",
                        width: "26",
                        height: "10.54",
                        left: "72",
                        top: "473.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CAUSE",
                        width: "40.67",
                        height: "10.54",
                        left: "104.75",
                        top: "473.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.67",
                        height: "10.54",
                        left: "152.17",
                        top: "473.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACTION",
                        width: "48",
                        height: "10.54",
                        left: "175.59",
                        top: "473.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "230.34",
                        top: "473.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLAIM",
                        width: "41.34",
                        height: "10.54",
                        left: "255.09",
                        top: "473.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YOU",
                        width: "26.66",
                        height: "10.54",
                        left: "303.18",
                        top: "473.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MAY",
                        width: "27.56",
                        height: "10.54",
                        left: "336.59",
                        top: "473.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "HAVE",
                        width: "33.12",
                        height: "10.54",
                        left: "370.15",
                        top: "473.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ARISING",
                        width: "51.35",
                        height: "10.54",
                        left: "409.27",
                        top: "473.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OUT",
                        width: "26",
                        height: "10.54",
                        left: "466.62",
                        top: "473.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.67",
                        height: "10.54",
                        left: "498.62",
                        top: "473.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "521.29",
                        top: "473.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "RELATING",
                        width: "63.12",
                        height: "10.54",
                        left: "72",
                        top: "459.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TO",
                        width: "17.12",
                        height: "10.54",
                        left: "140.37",
                        top: "459.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THESE",
                        width: "40.02",
                        height: "10.54",
                        left: "162.74",
                        top: "459.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "TERMS",
                        width: "42.68",
                        height: "10.54",
                        left: "208.01",
                        top: "459.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "255.94",
                        top: "459.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "USE",
                        width: "23.35",
                        height: "10.54",
                        left: "277.85",
                        top: "459.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "306.45",
                        top: "459.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "329.7",
                        top: "459.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WEBSITE,",
                        width: "58.36",
                        height: "10.54",
                        left: "360.29",
                        top: "459.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "423.9",
                        top: "459.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ANY",
                        width: "25.99",
                        height: "10.54",
                        left: "447.15",
                        top: "459.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CONTENT,",
                        width: "61.46",
                        height: "10.54",
                        left: "478.39",
                        top: "459.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "INFORMATION,",
                        width: "91.44",
                        height: "10.54",
                        left: "72",
                        top: "445.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MATERIALS,",
                        width: "74.79",
                        height: "10.54",
                        left: "170.94",
                        top: "445.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRODUCTS,",
                        width: "69.01",
                        height: "10.54",
                        left: "253.23",
                        top: "445.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SERVICES,",
                        width: "62.6",
                        height: "10.54",
                        left: "328.99",
                        top: "445.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "398.34",
                        top: "445.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "GOODS",
                        width: "43.34",
                        height: "10.54",
                        left: "423.09",
                        top: "445.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OBTAINED,",
                        width: "66.12",
                        height: "10.54",
                        left: "473.18",
                        top: "445.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "ACCESSESED,",
                        width: "81.7",
                        height: "10.54",
                        left: "72",
                        top: "431.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PURCHASED,",
                        width: "77.67",
                        height: "10.54",
                        left: "162.7",
                        top: "431.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SOLD,",
                        width: "35.68",
                        height: "10.54",
                        left: "249.37",
                        top: "431.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "294.05",
                        top: "431.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MADE",
                        width: "36.66",
                        height: "10.54",
                        left: "321.05",
                        top: "431.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AVAILABLE",
                        width: "68.25",
                        height: "10.54",
                        left: "366.71",
                        top: "431.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THROUGH",
                        width: "62.68",
                        height: "10.54",
                        left: "443.21",
                        top: "431.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "514.14",
                        top: "431.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "WEBSITE,",
                        width: "58.36",
                        height: "10.54",
                        left: "72",
                        top: "417.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "MUST",
                        width: "34.67",
                        height: "10.54",
                        left: "135.61",
                        top: "417.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BE",
                        width: "16.01",
                        height: "10.54",
                        left: "175.53",
                        top: "417.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "COMMENCED",
                        width: "82.66",
                        height: "10.54",
                        left: "196.79",
                        top: "417.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "WITHIN",
                        width: "47.34",
                        height: "10.54",
                        left: "284.7",
                        top: "417.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ONE",
                        width: "26",
                        height: "10.54",
                        left: "336.54",
                        top: "417.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(1)",
                        width: "14",
                        height: "10.54",
                        left: "367.04",
                        top: "417.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "YEAR",
                        width: "34",
                        height: "10.54",
                        left: "385.54",
                        top: "417.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "AFTER",
                        width: "40.67",
                        height: "10.54",
                        left: "424.04",
                        top: "417.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "THE",
                        width: "25.34",
                        height: "10.54",
                        left: "469.21",
                        top: "417.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CAUSE",
                        width: "40.67",
                        height: "10.54",
                        left: "499.05",
                        top: "417.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "72",
                        top: "404.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACTION",
                        width: "48.01",
                        height: "10.54",
                        left: "94.66",
                        top: "404.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACCRUES;",
                        width: "62",
                        height: "10.54",
                        left: "148.67",
                        top: "404.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OTHERWISE,",
                        width: "77.27",
                        height: "10.54",
                        left: "215.92",
                        top: "404.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "SUCH",
                        width: "33.34",
                        height: "10.54",
                        left: "298.44",
                        top: "404.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CAUSE",
                        width: "40.68",
                        height: "10.54",
                        left: "337.03",
                        top: "404.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OF",
                        width: "16.66",
                        height: "10.54",
                        left: "382.96",
                        top: "404.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ACTION",
                        width: "48.01",
                        height: "10.54",
                        left: "404.87",
                        top: "404.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "OR",
                        width: "18",
                        height: "10.54",
                        left: "458.13",
                        top: "404.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CLAIM",
                        width: "41.33",
                        height: "10.54",
                        left: "481.38",
                        top: "404.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IS",
                        width: "11.34",
                        height: "10.54",
                        left: "527.96",
                        top: "404.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "PERMANENTLY",
                        width: "92.9",
                        height: "10.55",
                        left: "72",
                        top: "390.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "BARRED.",
                        width: "53.67",
                        height: "10.55",
                        left: "167.9",
                        top: "390.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Waiver",
                        width: "37.32",
                        height: "10.54",
                        left: "72",
                        top: "362.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "112.32",
                        top: "362.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Severability",
                        width: "61.33",
                        height: "10.54",
                        left: "134.67",
                        top: "362.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "No",
                        width: "14.67",
                        height: "10.54",
                        left: "72",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "waiver",
                        width: "32.64",
                        height: "10.54",
                        left: "91.17",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "128.31",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "32",
                        height: "10.54",
                        left: "144.81",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "35.99",
                        height: "10.54",
                        left: "181.31",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "221.8",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "236.3",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "term",
                        width: "21.99",
                        height: "10.54",
                        left: "258.12",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "284.61",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "condition",
                        width: "45.33",
                        height: "10.54",
                        left: "299.11",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "set",
                        width: "13.33",
                        height: "10.54",
                        left: "348.94",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "out",
                        width: "15.33",
                        height: "10.54",
                        left: "366.77",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "386.6",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "400.44",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "429.59",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "463.91",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "478.41",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shall",
                        width: "22.66",
                        height: "10.54",
                        left: "501.57",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "527.98",
                        top: "334.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "deemed",
                        width: "37.31",
                        height: "10.54",
                        left: "72",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "113.06",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "further",
                        width: "32.65",
                        height: "10.54",
                        left: "122.14",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "158.54",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "continuing",
                        width: "51.33",
                        height: "10.54",
                        left: "172.28",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "waiver",
                        width: "32.65",
                        height: "10.54",
                        left: "226.61",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "262.26",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "275.26",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "term",
                        width: "21.99",
                        height: "10.54",
                        left: "300.25",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "325.24",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "condition",
                        width: "45.33",
                        height: "10.54",
                        left: "338.24",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "386.57",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "399.56",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "waiver",
                        width: "32.65",
                        height: "10.54",
                        left: "407.89",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "443.54",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "456.53",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "476.86",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "term",
                        width: "21.99",
                        height: "10.54",
                        left: "504.52",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "529.51",
                        top: "320.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "condition,",
                        width: "48.33",
                        height: "10.54",
                        left: "72",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "124.83",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "146.65",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "failure",
                        width: "31.31",
                        height: "10.54",
                        left: "168.48",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "204.29",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "218.79",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "36",
                        height: "10.54",
                        left: "254.53",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "294.28",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "assert",
                        width: "27.32",
                        height: "10.54",
                        left: "307.36",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "338.43",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.66",
                        height: "10.54",
                        left: "347.51",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "373.92",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provision",
                        width: "45.33",
                        height: "10.54",
                        left: "387.67",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "436.75",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "467.82",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "496.23",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "529.8",
                        top: "307.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "293.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shall",
                        width: "22.67",
                        height: "10.54",
                        left: "93.66",
                        top: "293.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "119.33",
                        top: "293.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "constitute",
                        width: "46.66",
                        height: "10.54",
                        left: "137.66",
                        top: "293.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "187.32",
                        top: "293.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "waiver",
                        width: "32.65",
                        height: "10.54",
                        left: "195.64",
                        top: "293.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "231.29",
                        top: "293.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "244.29",
                        top: "293.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.66",
                        height: "10.54",
                        left: "269.29",
                        top: "293.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "294.95",
                        top: "293.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provision.",
                        width: "48.33",
                        height: "10.54",
                        left: "307.95",
                        top: "293.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "72",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "85.99",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provision",
                        width: "45.33",
                        height: "10.54",
                        left: "109.32",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "160.65",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "176.65",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "207.3",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "243.12",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "259.12",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "283.78",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "held",
                        width: "20.66",
                        height: "10.54",
                        left: "297.79",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "324.45",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "342.45",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "court",
                        width: "24.66",
                        height: "10.54",
                        left: "353.77",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "384.43",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "400.42",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "tribunal",
                        width: "37.32",
                        height: "10.54",
                        left: "431.08",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "474.4",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "competent",
                        width: "49.98",
                        height: "10.54",
                        left: "489.65",
                        top: "265.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "jurisdiction",
                        width: "54.66",
                        height: "10.54",
                        left: "72",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "132.66",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "148",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "invalid,",
                        width: "36.33",
                        height: "10.54",
                        left: "165.32",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "illegal,",
                        width: "32.99",
                        height: "10.54",
                        left: "207.65",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "246.64",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unenforceable",
                        width: "67.96",
                        height: "10.54",
                        left: "262.63",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "336.59",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "356.58",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reason,",
                        width: "34.32",
                        height: "10.54",
                        left: "379.91",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "420.23",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provision",
                        width: "45.34",
                        height: "10.54",
                        left: "448.22",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shall",
                        width: "22.66",
                        height: "10.54",
                        left: "499.56",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "528.22",
                        top: "251.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "eliminated",
                        width: "50.65",
                        height: "10.54",
                        left: "72",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "126.4",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "limited",
                        width: "34",
                        height: "10.54",
                        left: "140.14",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "177.89",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "190.97",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "minimum",
                        width: "46.67",
                        height: "10.54",
                        left: "209.38",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "extent",
                        width: "29.33",
                        height: "10.54",
                        left: "259.8",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "292.88",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "318.62",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "340.37",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "remaining",
                        width: "48.65",
                        height: "10.54",
                        left: "358.78",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provisions",
                        width: "50",
                        height: "10.54",
                        left: "411.18",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "464.93",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "478.68",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.81",
                        height: "10.54",
                        left: "497.09",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "529.9",
                        top: "237.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "93.66",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "continue",
                        width: "41.32",
                        height: "10.54",
                        left: "115.33",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "159.65",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "full",
                        width: "16.67",
                        height: "10.54",
                        left: "171.98",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "force",
                        width: "24.64",
                        height: "10.54",
                        left: "191.65",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "219.29",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "effect.",
                        width: "30.09",
                        height: "10.54",
                        left: "239.62",
                        top: "224.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Relationship",
                        width: "64.68",
                        height: "10.54",
                        left: "72",
                        top: "196.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "139.68",
                        top: "196.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Parties",
                        width: "35.98",
                        height: "10.54",
                        left: "152.68",
                        top: "196.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "188.66",
                        top: "196.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "These",
                        width: "28.65",
                        height: "10.54",
                        left: "72",
                        top: "168.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "107.4",
                        top: "168.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "143.97",
                        top: "168.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "160.72",
                        top: "168.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "185.38",
                        top: "168.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "203.38",
                        top: "168.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "create",
                        width: "28.64",
                        height: "10.54",
                        left: "224.71",
                        top: "168.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "259.35",
                        top: "168.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agency,",
                        width: "36.2",
                        height: "10.54",
                        left: "282.67",
                        top: "168.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "partnership,",
                        width: "56.99",
                        height: "10.54",
                        left: "324.87",
                        top: "168.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "joint",
                        width: "22",
                        height: "10.54",
                        left: "387.86",
                        top: "168.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "venture,",
                        width: "38.98",
                        height: "10.54",
                        left: "415.86",
                        top: "168.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "employment,",
                        width: "62.99",
                        height: "10.54",
                        left: "460.84",
                        top: "168.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "529.83",
                        top: "168.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "franchisee",
                        width: "49.3",
                        height: "10.54",
                        left: "72",
                        top: "154.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "relationship",
                        width: "56.66",
                        height: "10.54",
                        left: "124.3",
                        top: "154.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "between",
                        width: "39.97",
                        height: "10.54",
                        left: "183.96",
                        top: "154.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "226.93",
                        top: "154.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "247.93",
                        top: "154.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "268.26",
                        top: "154.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics.",
                        width: "39",
                        height: "10.54",
                        left: "303.25",
                        top: "154.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Entire",
                        width: "32.44",
                        height: "10.54",
                        left: "72",
                        top: "126.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Agreement",
                        width: "56.42",
                        height: "10.54",
                        left: "107.44",
                        top: "126.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "99.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.81",
                        height: "10.54",
                        left: "94.41",
                        top: "99.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "127.97",
                        top: "99.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "141.72",
                        top: "99.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(including",
                        width: "49.33",
                        height: "10.54",
                        left: "164.13",
                        top: "99.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "216.46",
                        top: "99.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "PRIVACY",
                        width: "47.11",
                        height: "10.54",
                        left: "235.45",
                        top: "99.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "POLICY",
                        width: "41.34",
                        height: "10.54",
                        left: "285.56",
                        top: "99.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "329.9",
                        top: "99.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "350.23",
                        top: "99.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "370.55",
                        top: "99.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "additional",
                        width: "47.99",
                        height: "10.54",
                        left: "398.21",
                        top: "99.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "449.2",
                        top: "99.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "incorporated",
                        width: "60.64",
                        height: "10.54",
                        left: "478.86",
                        top: "99.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "72",
                        top: "85.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reference)",
                        width: "48.62",
                        height: "10.54",
                        left: "87.75",
                        top: "85.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "constitute",
                        width: "46.65",
                        height: "10.54",
                        left: "140.12",
                        top: "85.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "190.52",
                        top: "85.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "208.93",
                        top: "85.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "232.01",
                        top: "85.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "entire",
                        width: "27.32",
                        height: "10.54",
                        left: "252.34",
                        top: "85.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agreement",
                        width: "49.97",
                        height: "10.54",
                        left: "282.66",
                        top: "85.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "between",
                        width: "39.97",
                        height: "10.54",
                        left: "335.63",
                        top: "85.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "378.6",
                        top: "85.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "399.6",
                        top: "85.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "419.93",
                        top: "85.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "36",
                        height: "10.54",
                        left: "454.92",
                        top: "85.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "regarding",
                        width: "45.97",
                        height: "10.54",
                        left: "493.92",
                        top: "85.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,651",
                    "lineto,540,651",
                    "lineto,540,636",
                    "lineto,72,636",
                    "lineto,72,651",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,637",
                    "lineto,540,637",
                    "lineto,540,622",
                    "lineto,72,622",
                    "lineto,72,637",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,623",
                    "lineto,540,623",
                    "lineto,540,608",
                    "lineto,72,608",
                    "lineto,72,623",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,609",
                    "lineto,540,609",
                    "lineto,540,594",
                    "lineto,72,594",
                    "lineto,72,609",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,596",
                    "lineto,540,596",
                    "lineto,540,581",
                    "lineto,72,581",
                    "lineto,72,596",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,582",
                    "lineto,540,582",
                    "lineto,540,567",
                    "lineto,72,567",
                    "lineto,72,582",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,568",
                    "lineto,540,568",
                    "lineto,540,553",
                    "lineto,72,553",
                    "lineto,72,568",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,554",
                    "lineto,540,554",
                    "lineto,540,539",
                    "lineto,72,539",
                    "lineto,72,554",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,540",
                    "lineto,540,540",
                    "lineto,540,511",
                    "lineto,72,511",
                    "lineto,72,540",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,378",
                    "lineto,540,378",
                    "lineto,540,347",
                    "lineto,72,347",
                    "lineto,72,378",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,348",
                    "lineto,540,348",
                    "lineto,540,333",
                    "lineto,72,333",
                    "lineto,72,348",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,335",
                    "lineto,540,335",
                    "lineto,540,320",
                    "lineto,72,320",
                    "lineto,72,335",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,321",
                    "lineto,540,321",
                    "lineto,540,306",
                    "lineto,72,306",
                    "lineto,72,321",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,307",
                    "lineto,540,307",
                    "lineto,540,278",
                    "lineto,72,278",
                    "lineto,72,307",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,279",
                    "lineto,540,279",
                    "lineto,540,264",
                    "lineto,72,264",
                    "lineto,72,279",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,265",
                    "lineto,540,265",
                    "lineto,540,250",
                    "lineto,72,250",
                    "lineto,72,265",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,252",
                    "lineto,540,252",
                    "lineto,540,237",
                    "lineto,72,237",
                    "lineto,72,252",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,238",
                    "lineto,540,238",
                    "lineto,540,209",
                    "lineto,72,209",
                    "lineto,72,238",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,210",
                    "lineto,540,210",
                    "lineto,540,181",
                    "lineto,72,181",
                    "lineto,72,210",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,182",
                    "lineto,540,182",
                    "lineto,540,167",
                    "lineto,72,167",
                    "lineto,72,182",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,168",
                    "lineto,540,168",
                    "lineto,540,139",
                    "lineto,72,139",
                    "lineto,72,168",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,141",
                    "lineto,540,141",
                    "lineto,540,112",
                    "lineto,72,112",
                    "lineto,72,141",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,113",
                    "lineto,540,113",
                    "lineto,540,98",
                    "lineto,72,98",
                    "lineto,72,113",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,99",
                    "lineto,540,99",
                    "lineto,540,84",
                    "lineto,72,84",
                    "lineto,72,99",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,500.5", "lineto,246,500.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,363.5", "lineto,196,363.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,197.5", "lineto,189,197.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,128.5", "lineto,164,128.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "20",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "95.66",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "143.02",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "supersede",
                        width: "47.32",
                        height: "10.54",
                        left: "169.34",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "225.66",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prior",
                        width: "23.33",
                        height: "10.54",
                        left: "246.65",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "278.98",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contemporaneous",
                        width: "84.64",
                        height: "10.54",
                        left: "305.3",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "understandings,",
                        width: "75.66",
                        height: "10.54",
                        left: "398.19",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agreements,",
                        width: "57.64",
                        height: "10.54",
                        left: "482.1",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "representations,",
                        width: "75.64",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "150.64",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "warranties,",
                        width: "52.98",
                        height: "10.54",
                        left: "170.96",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "both",
                        width: "21.33",
                        height: "10.54",
                        left: "226.94",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "written",
                        width: "33.99",
                        height: "10.54",
                        left: "251.27",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "288.26",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "oral,",
                        width: "21.66",
                        height: "10.54",
                        left: "308.59",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "regarding",
                        width: "45.97",
                        height: "10.54",
                        left: "333.25",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "382.22",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "399.88",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Your",
                        width: "25.34",
                        height: "10.54",
                        left: "72",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Comments",
                        width: "55.32",
                        height: "10.54",
                        left: "100.34",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "158.66",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Concerns",
                        width: "48.66",
                        height: "10.54",
                        left: "181.01",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "This",
                        width: "21.33",
                        height: "10.55",
                        left: "72",
                        top: "636.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.55",
                        left: "96.33",
                        top: "636.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.55",
                        left: "137.69",
                        top: "636.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "operated",
                        width: "41.31",
                        height: "10.55",
                        left: "148.69",
                        top: "636.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.55",
                        left: "193",
                        top: "636.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: " Moore M&ore LLC. PO box 122416 Arlington, Tx 76012",
                        width: "31.99",
                        height: "10.55",
                        left: "208",
                        top: "636.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "All",
                        width: "15.33",
                        height: "10.54",
                        left: "72",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notices",
                        width: "33.99",
                        height: "10.54",
                        left: "91.08",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "128.82",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copyright",
                        width: "45.99",
                        height: "10.54",
                        left: "141.82",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "infringement",
                        width: "61.98",
                        height: "10.54",
                        left: "190.81",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "claims",
                        width: "31.33",
                        height: "10.54",
                        left: "255.79",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "should",
                        width: "32",
                        height: "10.54",
                        left: "290.12",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "325.12",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sent",
                        width: "19.33",
                        height: "10.54",
                        left: "339.45",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "361.78",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "374.11",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Copyright",
                        width: "48.67",
                        height: "10.54",
                        left: "391.77",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Agent",
                        width: "29.32",
                        height: "10.54",
                        left: "443.44",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "designated",
                        width: "51.32",
                        height: "10.54",
                        left: "475.76",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.3299999999999",
                        height: "10.54",
                        left: "530.08",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "72",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Copyright",
                        width: "48.67",
                        height: "10.54",
                        left: "91.16",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Infringement",
                        width: "62.64",
                        height: "10.54",
                        left: "144.33",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "section",
                        width: "33.99",
                        height: "10.54",
                        left: "211.47",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "249.96",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "264.46",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "292.86",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "326.43",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use,",
                        width: "21.66",
                        height: "10.54",
                        left: "340.18",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "365.59",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "378.67",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "manner",
                        width: "35.99",
                        height: "10.54",
                        left: "397.08",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "436.82",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "457.89",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "473.64",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "means",
                        width: "30.66",
                        height: "10.54",
                        left: "492.05",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "set",
                        width: "13.33",
                        height: "10.54",
                        left: "526.46",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "forth",
                        width: "23.33",
                        height: "10.54",
                        left: "72",
                        top: "581.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "98.33",
                        top: "581.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "110.66",
                        top: "581.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.81",
                        height: "10.54",
                        left: "138.32",
                        top: "581.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "171.13",
                        top: "581.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use.",
                        width: "21.66",
                        height: "10.54",
                        left: "184.13",
                        top: "581.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "All",
                        width: "15.33",
                        height: "10.54",
                        left: "72",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "91.08",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "feedback,",
                        width: "46.3",
                        height: "10.54",
                        left: "119.49",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "comments,",
                        width: "52.33",
                        height: "10.54",
                        left: "169.54",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "requests",
                        width: "39.32",
                        height: "10.54",
                        left: "224.87",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "267.19",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "technical",
                        width: "43.31",
                        height: "10.54",
                        left: "284.18",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "support,",
                        width: "39",
                        height: "10.54",
                        left: "330.49",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "372.49",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "392.81",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "communications",
                        width: "79.32",
                        height: "10.54",
                        left: "420.47",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "relating",
                        width: "36.65",
                        height: "10.54",
                        left: "502.79",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "72",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "84.33",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "101.99",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "should",
                        width: "32",
                        height: "10.54",
                        left: "143.35",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "178.35",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "directed",
                        width: "38.64",
                        height: "10.54",
                        left: "192.68",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to:",
                        width: "12.67",
                        height: "10.54",
                        left: "234.32",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "admin@mooreexotics.com.",
                        width: "132.35",
                        height: "10.54",
                        left: "249.99",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,720",
                    "lineto,540,720",
                    "lineto,540,705",
                    "lineto,72,705",
                    "lineto,72,720",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,706",
                    "lineto,540,706",
                    "lineto,540,677",
                    "lineto,72,677",
                    "lineto,72,706",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,678",
                    "lineto,540,678",
                    "lineto,540,649",
                    "lineto,72,649",
                    "lineto,72,678",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,651",
                    "lineto,540,651",
                    "lineto,540,622",
                    "lineto,72,622",
                    "lineto,72,651",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,623",
                    "lineto,540,623",
                    "lineto,540,608",
                    "lineto,72,608",
                    "lineto,72,623",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,609",
                    "lineto,540,609",
                    "lineto,540,594",
                    "lineto,72,594",
                    "lineto,72,609",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,595",
                    "lineto,540,595",
                    "lineto,540,566",
                    "lineto,72,566",
                    "lineto,72,595",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,567",
                    "lineto,540,567",
                    "lineto,540,552",
                    "lineto,72,552",
                    "lineto,72,567",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,554",
                    "lineto,540,554",
                    "lineto,540,525",
                    "lineto,72,525",
                    "lineto,72,554",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,665.5", "lineto,230,665.5"],
                },
              ],
            },
          },
        ],
      },
    ],
  },
];
