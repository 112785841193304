import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  Menu,
  Person,
  PersonOutlineOutlined,
  SettingsOutlined,
  ExitToApp,
} from "@mui/icons-material";
import catName from "../../pages/categories/ComingSoonCategories";
import Snackbar from "@mui/material/Snackbar";
import { useLocation } from "react-router-dom";
import serviceIcon from "./ServiceIcon";
import PetsIcon from "@mui/icons-material/Pets";
import StoreIcon from "@mui/icons-material/Store";
import Zoom from "@mui/material/Zoom";
import catIcons from "./CategoryIcons";
import {
  Stack,
  Paper,
  Popper,
  Typography,
  Grow,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Hidden,
  Grid,
  Button,
  Toolbar,
  ListItemIcon,
  AppBar,
  Alert,
  Box,
  IconButton,
  ListItemText,
  Avatar,
  TextField,
  InputAdornment,
  Badge,
  useMediaQuery,
  List,
  ListItem,
} from "@mui/material";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { navItems } from "./BuyerNavItemsData";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BuyerDashboardDrawer from "./BuyerDrawer";
import * as Actions from "../../redux/actions";
import config from "../../config";
import ConstructionIcon from "@mui/icons-material/Construction";
import SearchIcon from "@mui/icons-material/Search";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import { useNavigate } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { motion } from "framer-motion";

const NavBar = (props) => {
  const classes = useStyles();
  const {
    user,
    logoutUser,
    removeSeller,
    loadServices,
    removeListings,
    conversations,
    jwtToken,
    categories,
  } = props;
  const data = [
    { id: "education", value: "Education" },
    { id: "classes", value: "Classes" },
    { id: "listings", value: "Listings" },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentNavItems, setCurrentNavItems] = useState("");
  const { window } = props;
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [selectedNavItem, setSelectedNavItem] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [focusedItem, setFocusedItem] = useState(-1);
  const popperRef = useRef(null);
  const searchRef = useRef(null);
  const [selected, setSelected] = useState({
    id: null,
    name: "",
  });
  const [hoveredCategory, setHoveredCategory] = useState({
    id: null,
    name: "",
  });
  const [query, setQuery] = useState("");
  const boxRef = useRef(null);

  const isMediumScreen = useMediaQuery(
    "(max-width: 1442px) and (min-width: 1200px)"
  );

  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [logoutSnackbarStates, setLogoutSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const location = useLocation();

  const paths = [
    "/",
    "/classes",
    "/services",
    "/shop",
    "/media",
    "/neighbour",
    "/listings",
    "/about",
    "/contact",
    "/pricing",
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (paths.includes(location.pathname) && queryParams.size === 0) {
      navigate(setSelectedNavItem(location.pathname));
    }
  }, [location.pathname]);

  const handleMouseEnter = (event, name) => {
    setIsOpen(true);
    setCurrentNavItems(name);
    setAnchorEl2(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
    setCurrentNavItems("");
    setHoveredCategory({
      id: null,
      name: "",
    });
    setAnchorEl2(null);
  };

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(query);

  useEffect(() => {
    loadServices();
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/users/edit`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;
      }
      if (registerRequest.status === 200) {
        setUserProfile(registerRequest.data.data);
      }
    };
    if (location.pathname == "/") {
      // fetchUser();
    }
  }, [jwtToken, location.pathname]);

  let profilePopupOpen =
    anchorEl &&
    anchorEl?.innerHTML?.indexOf("</span></span></span>") !== -1 &&
    Boolean(anchorEl);

  const id = profilePopupOpen ? "simple-popper" : undefined;

  const handleLoggingOut = () => {
    logoutUser();
    removeSeller();
    removeListings();
    setLogoutSnackbarStates({
      open: true,
      message: "You have logged out successfully",
      severity: "success",
    });
  };

  const getUnreadCount = useMemo(() => {
    return conversations.reduce((acc, cur) => {
      acc += cur.unreadCount;
      return acc;
    }, 0);
  }, [conversations]);

  const handleToggleProfile = (event) => {
    event.preventDefault();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleDrawerToggle = (e) => {
    e.preventDefault();
    setMobileOpen(!mobileOpen);
  };

  const handleChat = () => {
    navigate("/conversations");
  };

  const handleSearch = () => {
    setShowSearch(!showSearch);
    if (searchRef.current) {
      searchRef.current.focus();
    }
    if (showSearch) {
      setQuery("");
      setAnchorEl3(null);
    }
  };

  const handleCloseCategoryAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };
  const handleLogoutAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLogoutSnackbarStates({ ...snackbarStates, open: false });
  };
  useEffect(() => {
    if (props.error !== "") {
      setSnackbarStates({
        open: true,
        message: props.error,
        severity: "warning",
      });
    } else {
      setSnackbarStates({
        open: false,
        message: "",
        severity: "",
      });
    }
  }, [props.error]);

  const handleCategoryClick = (data, name) => {
    if (name === "Categories") {
      setSelected({
        id: data.id,
        name: "Categories",
      });
      navigate("/classes/" + data.id + "/breeds");
    } else {
      setSelected({
        id: data.id,
        name: "Services",
      });
      navigate("/services/" + data.id);
    }
    setAnchorEl(null);
  };

  const handleAllCategoryClick = (data, name) => {
    navigate("/classes");
    setSelected({
      id: -1,
      name: "Categories",
    });
  };

  const handleAllServicesClick = (data, name) => {
    setSelected({
      id: -1,
      name: "Services",
    });
    navigate("/services");
  };

  const handleArrowDownClick = () => {
    if (boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  };

  const handleNavigatePath = (item) => {
    setSelectedNavItem(item.path);
    setSelected({
      id: null,
      name: "",
    });
    navigate(item.path);
  };

  const handleSearchInputChange = (e) => {
    const userInput = e.target.value.toLowerCase();
    setQuery(userInput);
    setAnchorEl3(e.currentTarget);
    setFocusedItem(-1);
  };

  const handleListItemClick = (option) => {
    let url;
    if (option.id === "education") {
      url = `/education?search=${query}`;
    } else if (option.id === "classes") {
      const foundCategory = categories.find((category) => {
        return category.name.toLowerCase() === query.toLowerCase();
      });

      if (foundCategory) {
        url = `/classes/${foundCategory.id}/breeds`;
      } else {
        setSnackbarStates({
          open: true,
          message: "No Class Found",
          severity: "warning",
        });
      }

      setTimeout(() => {
        setSnackbarStates({
          open: false,
        });
      }, 7000);
    } else if (option.id === "listings") {
      url = `/listings?search=${query}`;
    }
    setQuery("");
    setAnchorEl3(null);
    setShowSearch(false);
    navigate(url);
  };

  useEffect(() => {
    if (open2) {
      let checkTimeout = setTimeout(() => {
        if (!hoveredCategory.id) {
          handleMouseLeave();
        }
      }, 2000);
      return () => {
        clearTimeout(checkTimeout);
      };
    }
  }, [open2, hoveredCategory]);

  const handleMenuMouseEnter = (data) => {
    setHoveredCategory({
      id: data.id,
      name: data.name,
    });
  };
  const handleNavigateToListing = (event, category, id) => {
    event.stopPropagation();
    if (category === "classes") {
      if (id === -1) {
        navigate("/listings");
      } else {
        navigate("/listings/classes/" + id);
      }
    } else if (category === "services") {
      if (id === -1) {
        navigate("/services/listings");
      } else {
        navigate("/services/listings/classes/" + id);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown" && focusedItem < data.length - 1) {
      setFocusedItem(focusedItem + 1);
    } else if (e.key === "ArrowUp" && focusedItem > 0) {
      setFocusedItem(focusedItem - 1);
    } else if (e.key === "Enter") {
      if (focusedItem === -1) {
        handleListItemClick({
          id: "education",
          value: query,
        });
      } else {
        handleListItemClick(data[focusedItem]);
      }
    }
  };

  return (
    <>
      <Popper
        open={open2}
        anchorEl={anchorEl2}
        placement="bottom-start"
        transition
        style={{ zIndex: 9999 }}
        onMouseLeave={handleMouseLeave}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              style={{ position: "relative" }}
              onMouseLeave={handleMouseLeave}
            >
              <Box
                ref={boxRef}
                style={{
                  maxHeight: "40vh",
                  overflow: "auto",
                  maxWidth: "100vh",
                  boxShadow: "0px 0px 20px rgba(87, 87, 87, 0.15)",
                  backgroundColor: "white",
                }}
              >
                {currentNavItems === "Classes" && (
                  <Box style={{ display: "flex", flexWrap: "wrap" }}>
                    <MenuItem
                      onMouseEnter={() =>
                        handleMenuMouseEnter({ id: -1, name: "classes" })
                      }
                      sx={{
                        fontSize: "18px",
                        flex: "1 0 33%",
                        backgroundColor:
                          selected.id === -1 &&
                          selected.name === "Categories" &&
                          "#FFC001",
                        "&:hover": {
                          backgroundColor: "#FFC001",
                        },
                      }}
                      onClick={handleAllCategoryClick}
                    >
                      <PetsIcon width={"32"} height={"32"} pr={2} />
                      <Typography component="span" ml={2}>
                        All Animals
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flex: 1,
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton
                          color="black"
                          onClick={(event) =>
                            handleNavigateToListing(event, "classes", -1)
                          }
                        >
                          <Zoom
                            in={
                              (-1 === hoveredCategory.id &&
                                hoveredCategory.name === "classes") ||
                              (selected.id === -1 &&
                                selected.name === "Categories")
                            }
                          >
                            <StoreIcon
                              width={"32"}
                              height={"32"}
                              style={{
                                transitionDelay:
                                  -1 === hoveredCategory.id ? "500ms" : "0ms",
                                color: "black",
                              }}
                            />
                          </Zoom>
                        </IconButton>
                      </Box>
                    </MenuItem>
                    {props.categories.map((filter, index) => (
                      <>
                        {filter.active && (
                          <MenuItem
                            key={filter.id}
                            value={filter.id}
                            onMouseEnter={() =>
                              handleMenuMouseEnter({
                                id: filter.id,
                                name: "classes",
                              })
                            }
                            sx={{
                              // fontSize: "18px",
                              flex: "1 0 33%",
                              backgroundColor:
                                filter.id === selected.id &&
                                selected.name === "Categories" &&
                                "#FFC001",
                              "&:hover": {
                                backgroundColor: "#FFC001",
                              },
                            }}
                            onClick={() =>
                              handleCategoryClick(filter, "Categories")
                            }
                          >
                            <img
                              width={"32"}
                              height={"32"}
                              alt="category icon"
                              src={
                                catIcons[index]
                                  ? `${catIcons[index]}`
                                  : "https://cdn-icons-png.flaticon.com/512/64/64431.png"
                              }
                            ></img>{" "}
                            <Typography component="span" ml={2}>
                              {filter.name}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flex: 1,
                                alignItems: "flex-end",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Zoom
                                in={
                                  (filter.id === hoveredCategory.id &&
                                    hoveredCategory.name === "classes") ||
                                  (selected.id === filter.id &&
                                    selected.name === "Categories")
                                }
                                style={{
                                  transitionDelay:
                                    (filter.id === hoveredCategory.id &&
                                      hoveredCategory.name === "classes") ||
                                    (selected.id === filter.id &&
                                      selected.name === "Categories")
                                      ? "500ms"
                                      : "0ms",
                                }}
                              >
                                <IconButton
                                  color="black"
                                  onClick={(event) =>
                                    handleNavigateToListing(
                                      event,
                                      "classes",
                                      filter.id
                                    )
                                  }
                                >
                                  <StoreIcon
                                    width={"32"}
                                    height={"32"}
                                    style={{ color: "black" }}
                                  />
                                </IconButton>
                              </Zoom>
                            </Box>
                            {/* )} */}
                          </MenuItem>
                        )}
                      </>
                    ))}
                    <MenuItem
                      sx={{
                        fontSize: "18px",
                        flex: "1 0 33%",
                      }}
                    ></MenuItem>
                    <MenuItem
                      sx={{
                        fontSize: "18px",
                        flex: "1 0 33%",
                      }}
                    ></MenuItem>
                  </Box>
                )}
                {currentNavItems === "Services" && (
                  <Box style={{ display: "flex", flexWrap: "wrap" }}>
                    <MenuItem
                      onClick={handleAllServicesClick}
                      onMouseEnter={() =>
                        handleMenuMouseEnter({
                          id: -1,
                          name: "services",
                        })
                      }
                      sx={{
                        fontSize: "18px",
                        flex: "1 0 33%",
                        backgroundColor:
                          selected.id === -1 &&
                          selected.name === "Services" &&
                          "#FFC001",
                        "&:hover": {
                          backgroundColor: "#FFC001",
                        },
                      }}
                    >
                      <img
                        width="32"
                        height="32"
                        alt="category icon"
                        src={
                          "https://stagingappdata.s3.amazonaws.com/ICONS/Group(1).png"
                        }
                      ></img>{" "}
                      <Typography component="span" ml={2}>
                        All Services
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flex: 1,
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton
                          onClick={(event) =>
                            handleNavigateToListing(event, "services", -1)
                          }
                        >
                          <Zoom
                            in={
                              (-1 === hoveredCategory.id &&
                                hoveredCategory.name === "services") ||
                              (selected.id === -1 &&
                                selected.name === "Services")
                            }
                          >
                            <StoreIcon
                              width={"32"}
                              height={"32"}
                              style={{
                                transitionDelay:
                                  -1 === hoveredCategory.id &&
                                  hoveredCategory.name === "Services"
                                    ? "500ms"
                                    : "0ms",
                                color: "black",
                              }}
                            />
                          </Zoom>
                        </IconButton>
                      </Box>
                    </MenuItem>
                    {props.services.map((filter, index) => (
                      <MenuItem
                        key={filter.id}
                        value={filter.id}
                        sx={{
                          fontSize: "18px",
                          flex: "1 0 33%",
                          backgroundColor:
                            filter.id === selected.id &&
                            selected.name === "Services" &&
                            "#FFC001",
                          "&:hover": {
                            backgroundColor: "#FFC001",
                          },
                        }}
                        onClick={() => handleCategoryClick(filter, "Services")}
                        onMouseEnter={() =>
                          handleMenuMouseEnter({
                            id: filter.id,
                            name: "services",
                          })
                        }
                      >
                        <img
                          width="32"
                          height="32"
                          alt="category icon"
                          src={`${serviceIcon[index]}`}
                        ></img>{" "}
                        <Typography component="span" ml={2}>
                          {filter.name}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flex: 1,
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Zoom
                            in={
                              (filter.id === hoveredCategory.id &&
                                hoveredCategory.name === "services") ||
                              (selected.id === filter.id &&
                                selected.name === "Services")
                            }
                          >
                            <IconButton
                              onClick={(event) =>
                                handleNavigateToListing(
                                  event,
                                  "services",
                                  filter.id
                                )
                              }
                            >
                              <StoreIcon
                                width={"32"}
                                height={"32"}
                                style={{
                                  transitionDelay:
                                    filter.id === hoveredCategory.id &&
                                    hoveredCategory.name === "services"
                                      ? "500ms"
                                      : "0ms",
                                  color: "black",
                                }}
                              />
                            </IconButton>
                          </Zoom>
                        </Box>
                      </MenuItem>
                    ))}
                    <MenuItem
                      sx={{
                        fontSize: "18px",
                        flex: "1 0 33%",
                      }}
                    ></MenuItem>
                  </Box>
                )}
              </Box>
              {currentNavItems === "Classes" && (
                <IconButton
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    backgroundColor: "black",
                    opacity: "0.5",
                  }}
                  onClick={handleArrowDownClick}
                >
                  <ArrowDownwardIcon
                    fontSize="medium"
                    style={{ color: "rgb(255, 192, 1)" }}
                  />
                </IconButton>
              )}
            </Box>
          </Fade>
        )}
      </Popper>

      <Snackbar
        open={snackbarStates.open}
        autoHideDuration={3000}
        onClose={handleCloseCategoryAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseCategoryAlert}
          variant="filled"
          sx={{ width: "100%", backgroundColor: "#FFA500" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={logoutSnackbarStates.open}
        autoHideDuration={3000}
        onClose={handleLogoutAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleLogoutAlert}
          variant="filled"
          sx={{ width: "100%" }}
          severity={logoutSnackbarStates.severity}
        >
          {logoutSnackbarStates.message}
        </Alert>
      </Snackbar>
      <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AppBar
          component="nav"
          position="sticky"
          style={{ background: "#ffff" }}
        >
          <Toolbar direction="row">
            <IconButton
              color="black"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                display: { xs: "flex", sm: "flex", md: "flex", lg: "none" },
              }}
            >
              <Menu sx={{ fontSize: "40px" }} />
            </IconButton>

            <Grid
              container
              direction="row"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "flex",
                },
              }}
            >
              <Grid
                item
                justifyContent="center"
                alignItems="center"
                xs={12}
                sm={12}
                md={12}
                lg={2}
                xl={2}
              >
                <Link to="/">
                  <img
                    style={{ width: 200 }}
                    alt="Exotics logo"
                    src="https://res.cloudinary.com/dwezij7u7/image/upload/v1675115795/exoticslogo_w2oi9z.png"
                  />
                </Link>
              </Grid>

              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={10}
                xl={10}
                justifyContent="center"
                alignItems="center"
                spacing={4}
                sx={{
                  mt: !showSearch ? 5.6 : 4,
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "inline",
                    xl: "inline",
                  },
                  textAlign: "center",
                }}
              >
                {!showSearch ? (
                  <>
                    {navItems.map((item) => (
                      <>
                        {item.name === "Classes" || item.name === "Services" ? (
                          <>
                            <Button
                              onClick={() => {
                                handleNavigatePath(item);
                              }}
                              disableRipple
                              className={
                                item.path !== selectedNavItem &&
                                classes.customButton
                              }
                              sx={{
                                borderTop: item.path === selectedNavItem && 2,
                                borderBottom:
                                  item.path === selectedNavItem && 2,
                                borderRadius:
                                  item.path === selectedNavItem && 0,
                                borderColor:
                                  item.path === selectedNavItem && "#FFC001",
                                textTransform: "none",
                                height: "35px",
                              }}
                              onMouseEnter={(event) =>
                                handleMouseEnter(event, item.name)
                              }
                              aria-owns={open ? "hover-menu" : undefined}
                              aria-haspopup="true"
                            >
                              <Typography
                                fontSize={isMediumScreen ? "12px" : "16px"}
                                color={
                                  item.path === selectedNavItem && "#FFC001"
                                }
                              >
                                {item.name}{" "}
                              </Typography>
                              <ArrowDropDownIcon
                                fontSize="small"
                                sx={{
                                  color:
                                    item.path === selectedNavItem && "#FFC001",
                                }}
                              />
                            </Button>
                          </>
                        ) : item.name === "Education" ? (
                          <Button
                            component={Link}
                            to={item.path}
                            target="_blank"
                            disableRipple
                            onMouseEnter={handleMouseLeave}
                            className={
                              item.path !== selectedNavItem &&
                              classes.customButton
                            }
                            sx={{
                              borderTop: item.path === selectedNavItem && 2,
                              borderBottom: item.path === selectedNavItem && 2,
                              borderRadius: item.path === selectedNavItem && 0,
                              borderColor:
                                item.path === selectedNavItem && "#FFC001",
                              textTransform: "none",
                              height: "35px",
                            }}
                          >
                            <Typography
                              fontSize={isMediumScreen ? "12px" : "16px"}
                            >
                              {item.name}
                            </Typography>
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              handleNavigatePath(item);
                            }}
                            onMouseEnter={handleMouseLeave}
                            disableRipple
                            className={
                              item.path !== selectedNavItem &&
                              classes.customButton
                            }
                            sx={{
                              borderTop: item.path === selectedNavItem && 2,
                              borderBottom: item.path === selectedNavItem && 2,
                              borderRadius: item.path === selectedNavItem && 0,
                              borderColor:
                                item.path === selectedNavItem && "#FFC001",
                              textTransform: "none",
                              height: "35px",
                            }}
                          >
                            <Typography
                              fontSize={isMediumScreen ? "12px" : "16px"}
                              color={item.path === selectedNavItem && "#FFC001"}
                            >
                              <Typography
                                fontSize={isMediumScreen ? "12px" : "16px"}
                              >
                                {item.name}
                              </Typography>
                            </Typography>
                          </Button>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      color: "white",
                      display: "flex",
                    }}
                  >
                    <Grow in={true}>
                      <TextField
                        type="text"
                        label="Search..."
                        variant="outlined"
                        fullWidth
                        ref={searchRef}
                        value={query}
                        onChange={handleSearchInputChange}
                        onKeyDown={(e) => handleKeyDown(e)}
                      />
                    </Grow>
                    <Popper
                      open={open3}
                      anchorEl={anchorEl3}
                      placement="bottom-start"
                      style={{ zIndex: 9999, width: "70%" }}
                      ref={popperRef}
                    >
                      <Paper>
                        <List>
                          {data.map((option, index) => (
                            <ListItem
                              key={index}
                              onClick={() => handleListItemClick(option)}
                              sx={{
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "#f5f5f5",
                                },
                                backgroundColor:
                                  focusedItem === index
                                    ? "#ffc001"
                                    : "transparent", // Highlight the focused item
                              }}
                            >
                              <ListItemText
                                primary={
                                  option.value + ' matching "' + query + '"'
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Paper>
                    </Popper>
                  </Box>
                )}
              </Grid>
            </Grid>

            {!showSearch ? (
              <Box
                sx={{
                  margin: "auto",
                  display: {
                    xs: "flex",
                    sm: "flex",
                    md: "flex",
                    lg: "none",
                    xl: "none",
                  },
                }}
              >
                <Link to="/">
                  <img
                    style={{ width: 150 }}
                    alt="Exotics logo"
                    src="https://res.cloudinary.com/dwezij7u7/image/upload/v1675115795/exoticslogo_w2oi9z.png"
                  />
                </Link>
              </Box>
            ) : (
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  color: "white",
                  display: {
                    xs: "flex",
                    sm: "flex",
                    md: "flex",
                    lg: "none",
                    xl: "none",
                  },
                }}
              >
                <TextField
                  type="text"
                  label="Search..."
                  variant="outlined"
                  ref={searchRef}
                  fullWidth
                  value={query}
                  onChange={handleSearchInputChange}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
                <Popper
                  open={open3}
                  anchorEl={anchorEl3}
                  placement="bottom-start"
                  style={{ zIndex: 9999, width: "70%" }}
                  ref={popperRef}
                >
                  <Paper>
                    <List>
                      {data.map((option, index) => (
                        <ListItem
                          key={index}
                          onClick={() => handleListItemClick(option)}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "#f5f5f5", // Light grey background on hover
                            },
                            backgroundColor:
                              focusedItem === index ? "#ffc001" : "transparent", // Highlight the focused item
                          }}
                        >
                          <ListItemText
                            primary={option.value + ' matching "' + query + '"'}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                </Popper>
              </Box>
            )}
            <Box
              sx={{
                ml: 1,
                mb: { xs: 0.8, sm: 0.8, md: 0.8, lg: 0 },
                mt: { lg: 3.2 },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                color={window?.innerWidth > 959 ? "transparent" : "white"}
                justIcon={window?.innerWidth > 959}
                simple={!(window?.innerWidth > 959)}
                sx={{ mr: 1 }}
                onClick={handleSearch}
              >
                {!showSearch ? (
                  <SearchIcon
                    sx={{
                      fontSize: { xs: "30px", md: "35px" },
                      color: "rgb(255, 192, 1)",
                    }}
                  />
                ) : (
                  <CloseIcon
                    sx={{
                      fontSize: { xs: "30px", md: "35px" },
                      color: "rgb(255, 192, 1)",
                    }}
                  />
                )}
              </IconButton>
              {user && user.email ? (
                <>
                  <IconButton
                    color={window?.innerWidth > 959 ? "transparent" : "white"}
                    justIcon={window?.innerWidth > 959}
                    simple={!(window?.innerWidth > 959)}
                    sx={{ mt: 0.5, mr: 1 }}
                    onClick={handleChat}
                  >
                    <Badge badgeContent={getUnreadCount} color="secondary">
                      <ForumRoundedIcon
                        sx={{
                          fontSize: "38px",
                          mt: 0.5,
                          color: "rgb(255, 192, 1)",
                        }}
                      />
                    </Badge>
                  </IconButton>
                  <IconButton
                    component="label"
                    edge="end"
                    disabled={profilePopupOpen}
                    color={window?.innerWidth > 959 ? "transparent" : "white"}
                    justIcon={window?.innerWidth > 959}
                    simple={!(window?.innerWidth > 959)}
                    aria-label="Person"
                    aria-describedby={id}
                    onClick={handleToggleProfile}
                    sx={{ mt: 0.5 }}
                  >
                    {userProfile?.user_profile?.url ? (
                      <Avatar
                        alt="Profile Avatar"
                        src={userProfile.user_profile.url}
                      />
                    ) : (
                      <Avatar alt="User Profile Avatar" src={user.avatar_url} />
                      // <Person />
                    )}
                    <Hidden mdUp implementation="css">
                      <p className={classes.linkText}></p>
                    </Hidden>
                  </IconButton>

                  <Popper
                    id={id}
                    open={anchorEl?.innerHTML && profilePopupOpen}
                    anchorEl={anchorEl}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleToggleProfile}>
                            <MenuList role="menu">
                              <Link
                                to={`users/${user.id}`}
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}
                              >
                                <MenuItem
                                  divider
                                  className={classes.dropdownItem}
                                >
                                  <ListItemIcon>
                                    <PersonOutlineOutlined fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText>Profile</ListItemText>
                                </MenuItem>
                              </Link>
                              <Link
                                to={`settings`}
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}
                              >
                                <MenuItem
                                  divider
                                  className={classes.dropdownItem}
                                >
                                  <ListItemIcon>
                                    <SettingsOutlined fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText>Settings</ListItemText>
                                </MenuItem>
                              </Link>
                              {props.seller &&
                              props.seller.approval_status === "approved" ? (
                                <Link
                                  to={`seller/dashboard`}
                                  style={{
                                    textDecoration: "none",
                                    color: "#000",
                                  }}
                                >
                                  <MenuItem
                                    divider
                                    className={classes.dropdownItem}
                                  >
                                    <ListItemIcon>
                                      <ArrowBackIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                      Seller Dashboard
                                    </ListItemText>
                                  </MenuItem>
                                </Link>
                              ) : (
                                <Link
                                  to={
                                    user && user.is_subscribed
                                      ? "/seller/register"
                                      : "/pricing"
                                  }
                                  style={{
                                    textDecoration: "none",
                                    color: "#000",
                                  }}
                                >
                                  <MenuItem
                                    divider
                                    className={classes.dropdownItem}
                                    sx={{ marginTop: 4 }}
                                  >
                                    <ListItemIcon>
                                      <ExitToApp fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Seller Register</ListItemText>
                                  </MenuItem>
                                </Link>
                              )}
                              <Link
                                to="/"
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}
                              >
                                <MenuItem
                                  divider
                                  onClick={handleLoggingOut}
                                  className={classes.dropdownItem}
                                  sx={{ marginTop: 4 }}
                                >
                                  <ListItemIcon>
                                    <ExitToApp fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText>Logout</ListItemText>
                                </MenuItem>
                              </Link>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              ) : (
                <Box
                  sx={{
                    ml: 1,
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "none",
                      lg: "flex",
                      xl: "flex",
                    },
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    component={Link}
                    disableRipple
                    sx={{
                      width: "94px",
                      height: "30px",
                      borderColor: "rgb(255, 192, 1)",
                      fontColor: "rgb(255, 192, 1) !important",
                    }}
                    variant="outlined"
                    to="/auth/login"
                  >
                    Login
                  </Button>
                  <Button
                    component={Link}
                    to="/auth/signup"
                    disableRipple
                    sx={{
                      color: "#fff",
                      ml: 1,
                      width: "94px",
                      height: "30px",
                    }}
                    variant="contained"
                  >
                    Sign Up
                  </Button>
                </Box>
              )}
            </Box>
          </Toolbar>
        </AppBar>

        <BuyerDashboardDrawer
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
        />
      </Stack>
    </>
  );
};

const useStyles = makeStyles({
  customButton: {
    color: "black",
    background: "transparent",
    transition: "background 0.3s ease, color 0.3s ease",
    textTransform: "none",
    "&:hover": {
      color: "#FFC001",
      background: "transparent",
      boxShadow: "none",
      "&:before, &:after": {
        width: "100%",
        transition: "width 0.8s ease",
      },
    },
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      color: "#FFC001",
      top: 0,
      right: 0,
      height: "2px",
      width: 0,
      background: "#FFC001",
      boxShadow:
        "-1px -1px 5px 0px #fff, 7px 7px 20px 0px rgba(0, 0, 0, 0.3), 4px 4px 5px 0px rgba(0, 0, 0, 0.2)",
      transition: "width 0.4s ease",
    },
    "&:after": {
      right: "inherit",
      top: "inherit",
      color: "#FFC001",
      left: 0,
      bottom: 1,
    },
    "&:hover": {
      color: "#FFC001",
      background: "transparent",
      boxShadow: "none",
      "&:before, &:after": {
        width: "100%",
        transition: "width 0.8s ease",
      },
    },
  },
  activeNavbarButton: {
    borderTop: 2,
    borderBottom: 2,
    borderRadius: 0,
    borderColor: "#FFC001",
  },
  flexGrow: {
    flex: "1",
  },
  links: {
    fontSize: "16px !important",
    color: "black",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "rgb(255, 192, 1) !important",
      color: "white",
    },
  },
  buttonLink: {
    display: "flex",
    marginLeft: "30px",
    width: "auto",
  },
  dropdownItem: {
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    WebkitTransition: "all 150ms linear",
    MozTransition: "all 150ms linear",
    OTransition: "all 150ms linear",
    MsTransition: "all 150ms linear",
    transition: "all 150ms linear",
    fontWeight: "400",
    lineHeight: "1.42857143",
    color: "black",
    whiteSpace: "nowrap",
    height: "unset",
    "&:hover": {
      backgroundColor: "white",
      color: "rgb(255, 192, 1)",
    },
  },
});

const mapStateToProps = ({ Auth, Seller, Messages, Categories, Services }) => ({
  user: Auth.user,
  jwtToken: Auth.jwtToken,
  seller: Seller.seller,
  error: Seller.error,
  categories: Categories.classes,
  services: Services.services,
  conversations: Messages.conversations,
  logoutSnackbar: Auth.showLogoutBar,
});

const mapDispatchToProps = (dispatch) => ({
  loadServices: () => dispatch(Actions.thunkLoadServices()),
  logoutUser: () => dispatch(Actions.thunkLogout()),
  removeSeller: () => dispatch(Actions.removeSellerContent()),
  removeListings: () => dispatch(Actions.removeListingsContent()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
