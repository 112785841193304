import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

const SellerPolicies = ({ openPolicy, policyContent, setOpenPolicies }) => {
  const [open, setOpen] = useState(false);

  const dummyPolicies = [
    "1. Morph Market is not responsible for setting and communicating the return and refund policies.",
    "2. Actual appearance of animal may slightly vary, from the attached images.",
    " 3. Any request or demand that violates the law or is against legal and ethical standards will not be met or entertained. ",
  ];

  useEffect(() => {
    setOpen(openPolicy);
  }, [openPolicy]);
  const handleClose = () => {
    setOpen(false);
    setOpenPolicies(false);
  };
  return (
    <Dialog open={open} onClose={handleClose} style={{ zIndex: 200 }}>
      <DialogTitle>
        <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
          Seller Policies
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {!policyContent &&
            dummyPolicies.map((list) => <Typography>{list}</Typography>)}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default SellerPolicies;
