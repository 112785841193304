import React, { useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

const DropDown = ({
  title,
  selected,
  handleSelected,
  data,
  disabled,
  required,
}) => {
  const handleChange = (event) => {
    const { value } = event.target;
    handleSelected(value);
  };

  return (
    <FormControl size="small" sx={{ width: "100%", mb: 2 }}>
      <InputLabel id="checkbox-label">{title}</InputLabel>
      <Select
        displayEmpty
        labelId="checkbox-label"
        input={<OutlinedInput label={title} />}
        value={selected}
        size="small"
        onChange={handleChange}
        defaultValue=""
        disabled={disabled}
        required={required}
      >
        {data &&
          data.map((list) => (
            <MenuItem key={list.id} value={list.id} sx={{ fontSize: "14px" }}>
              {list.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default DropDown;
