import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import CustomModal from "../listings/Modal";
import Paper from "@mui/material/Paper";
import { Avatar, Box, Button, Snackbar, Typography } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import MuiAlert from "@mui/material/Alert";
import { connect } from "react-redux";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    color: "inherit",
  },
})(MuiTableCell);

const TableRow = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    cursor: "pointer",
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#aaaaaa" },
  },
})(MuiTableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 8px 8px 8px",
    margin: "10px 10px 10px 10px",
    overflow: "auto",
    borderRadius: "10px",
    height: "60vh",
  },
  table: {
    padding: 0,
    margin: 0,
    borderCollapse: "separate",
    borderSpacing: "0px 9px",
  },
  hover: {
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#eeeeee" },
  },
}));

const ListingTableHeadings = ["", "Name"];

const BlockList = ({ data, jwtToken, fetchBlockedUser, handleUnblockUser }) => {
  const classes = useStyles();
  const [openUnBlockModal, setOpenUnBlockModal] = useState(false);
  const [confirmProp, setConfirmProp] = useState(false);
  const [openSucessAlert, setOpenSucessAlert] = useState(false);
  const [userId, setUserId] = useState(null);

  const handleCloseUnBlockModal = (event) => {
    setOpenUnBlockModal(!openUnBlockModal);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSucessAlert(false);
  };
  useEffect(() => {
    if (confirmProp && userId) {
      handleUnblockUser(userId);
    }

    return () => {
      setConfirmProp(false);
    };
  }, [confirmProp, jwtToken, userId]);

  const handleUnBlock = (data) => {
    setUserId(data.id);
    setOpenUnBlockModal(true);
  };

  return (
    <>
      <Snackbar
        open={openSucessAlert}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <MuiAlert onClose={handleClose} severity="success">
            User Blocked Successfully!
          </MuiAlert>
        </div>
      </Snackbar>
      <CustomModal
        title="Unblock Alert"
        content={
          "Are you sure you want to un-block this user? This will restore all the conversations, related listings and neighbour stores."
        }
        openModal={openUnBlockModal}
        closeModal={handleCloseUnBlockModal}
        actionTitle={"Unblock"}
        setConfirmProp={setConfirmProp}
        actionColor={"red"}
      />
      <Paper className={classes.root} elevation={2}>
        {data && data.length > 0 ? (
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                {ListingTableHeadings.map((item) => (
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: { md: "14px", xl: "16px" },
                      fontWeight: "bold",
                    }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((data) => (
                <TableRow
                  key={data.id}
                  className={data.is_featured ? classes.featuredRrow : ""}
                >
                  <TableCell align="left" style={{ width: 0, padding: "5px" }}>
                    <Avatar
                      width={50}
                      height={50}
                      alt={data.full_name}
                      src={data.avatar_url}
                    />
                  </TableCell>
                  <TableCell align="left">{data.full_name}</TableCell>
                  <TableCell align="right" justifyContent="flex-end">
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        marginTop: "25px !important",
                        marginBottom: "8px !important",
                        height: "40px",
                        color: "white",
                        fontSize: "15px !important",
                        fontWeight: "900 !important",
                        textTransform: "none !important",
                      }}
                      onClick={() => handleUnBlock(data)}
                    >
                      Unblock
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            sx={{ height: "80vh" }}
            justifyContent="center"
            flexDirection="column"
          >
            <Typography variant="p" sx={{ fontSize: "14px" }}>
              You didn't block any user.
            </Typography>
          </Box>
        )}
      </Paper>
    </>
  );
};

const mapStateToProps = ({ Auth }) => ({
  jwtToken: Auth.jwtToken,
  user: Auth.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BlockList);
