import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Dialog, IconButton } from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ShareIcon from "@mui/icons-material/Share";
import FlagIcon from "@mui/icons-material/Flag";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactImageMagnify from "react-image-magnify";
import "swiper/swiper.min.css";
import "./ListImage.css";
import ClearIcon from "@mui/icons-material/Clear";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { grey } from "@mui/material/colors";
const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "transparent",
    },
  },
  iconStyles: {
    fontSize: "12px",
  },
  hover: {
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#aaaaaa" },
  },
}));

const ListImage = ({ listData }) => {
  const classes = useStyles;
  const [selectedMedia, setSelectedMedia] = useState();
  const [media, setMedia] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);

  const handleZoomIn = (event) => {
    event?.stopPropagation();
    setIsZoomed(true);
  };

  const handleZoomOut = (event) => {
    event?.stopPropagation();
    setIsZoomed(false);
  };
  const handleLeftArrow = (event) => {
    event?.stopPropagation();
    let index = 0;
    if (media.length > 1) {
      index = media.findIndex((e) => e.url === "" + selectedMedia);
    }

    if (index > 0) {
      setSelectedMedia(media[index - 1].url);
    } else {
      setSelectedMedia(media[media.length - 1].url);
    }
  };

  const handleRightArrow = (event) => {
    event?.stopPropagation();
    let index = 0;
    if (media.length > 1) {
      index = media.findIndex((e) => e.url === "" + selectedMedia);
    }

    if (index !== media.length - 1) {
      setSelectedMedia(media[index + 1].url);
    } else {
      setSelectedMedia(media[0].url);
    }
  };

  const handleOpenImageModal = (e, item) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    setOpenModal(false);
    setIsZoomed(false);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 37) {
      handleLeftArrow();
    } else if (event.keyCode === 39) {
      handleRightArrow();
    }
  };

  function handleImageClick(event) {
    event.stopPropagation();
  }

  useEffect(() => {
    if (listData) {
      setSelectedMedia(listData[0]?.url);
      const mediaCopy = listData.filter((media) => media.url !== null);
      setMedia(mediaCopy);
    }
  }, [listData]);

  const handleImageSelect = (e) => {
    setSelectedMedia(e.target.src);
  };

  const iconLinks = [
    {
      name: "Like",
      icon: <ThumbUpAltIcon className={classes.iconStyles} />,
      src: "#",
    },
    {
      name: "Save",
      icon: <BookmarkIcon className={classes.iconStyles} />,
      src: "#",
    },
    {
      name: "Share",
      icon: <ShareIcon className={classes.iconStyles} />,
      src: "#",
    },
    {
      name: "Report",
      icon: <FlagIcon className={classes.iconStyles} />,
      src: "#",
    },
  ];

  if (!listData) {
    return;
  }

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={handleCloseModal}
        onKeyDown={handleKeyDown}
        fullScreen
        justifyContent={"center"}
        alignItems={"center"}
        PaperProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(8px)",
            boxShadow: "none",
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              padding: "15px",
            }}
          >
            <IconButton
              color="primary"
              aria-label="Delete"
              variant="outlined"
              onClick={handleCloseModal}
            >
              <ClearIcon fontSize="large" />
            </IconButton>
            {!isZoomed ? (
              <IconButton
                color="primary"
                aria-label="Delete"
                variant="outlined"
                onClick={handleZoomIn}
              >
                <ZoomInIcon fontSize="large" />
              </IconButton>
            ) : (
              <IconButton
                color="primary"
                aria-label="Delete"
                variant="outlined"
                onClick={handleZoomOut}
              >
                <ZoomOutIcon fontSize="large" />
              </IconButton>
            )}
          </Box>
          {media.length > 1 && (
            <>
              <IconButton
                color="primary"
                aria-label="arrowRight"
                variant="outlined"
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: { sm: "1%", xs: "0%" },
                }}
                onClick={handleRightArrow}
              >
                <EastIcon
                  sx={{
                    fontSize: {
                      lg: "30px",
                      md: "24px",
                      sm: "20px",
                      xs: "16px",
                    },
                  }}
                />
              </IconButton>

              <IconButton
                color="primary"
                aria-label="arrowRight"
                variant="outlined"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: { sm: "1%", xs: "0%" },
                }}
                onClick={handleLeftArrow}
              >
                <WestIcon
                  sx={{
                    fontSize: {
                      lg: "30px",
                      md: "24px",
                      sm: "20px",
                      xs: "16px",
                    },
                  }}
                />
              </IconButton>
            </>
          )}
        </Box>
        <Box
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "90%",
            height: "90%",
            display: "flex",
            margin: "auto",
          }}
        >
          <Box
            display="flex"
            sx={{
              padding: "20px",
            }}
          >
            {!isZoomed ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  marginLeft: "1%",
                  marginRight: "1%",
                  objectFit: "contain",
                  transition: "0.5s ease",
                  backfaceVisibility: "hidden",
                }}
                src={`${selectedMedia}`}
                srcSet={`${selectedMedia}`}
                alt="listing"
                loading="lazy"
                onClick={handleImageClick}
              />
            ) : (
              <ReactImageMagnify
                {...{
                  isEnlargedImagePortalEnabledForTouch: true,
                  shouldHideHintAfterFirstActivation: false,
                  hintTextTouch: "Long-Touch to Zoom",
                  enlargedImagePosition: "over",
                  isHintEnabled: true,
                  smallImage: {
                    alt: `${selectedMedia}`,
                    isFluidWidth: true,
                    src: `${selectedMedia}`,
                  },
                  largeImage: {
                    src: `${selectedMedia}`,
                    width: 2000,
                    height: 2000,
                  },
                  enlargedImageContainerStyle: {
                    zIndex: "1500",
                  },
                  enlargedImageContainerDimensions: {
                    width: "100%",
                    height: "100%",
                  },
                }}
              />
            )}
          </Box>
        </Box>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          margin: "5px",
          borderRadius: "10px",
        }}
        onClick={handleOpenImageModal}
      >
        <Box>
          {selectedMedia?.includes(".mp4") ||
          selectedMedia?.includes(".mkv") ||
          selectedMedia?.includes(".mov") ||
          isZoomed ? (
            <video
              src={selectedMedia}
              style={{
                width: "100%",
                height: "300px",
                marginBottom: "5px",
                border: "2px solid grey",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              controls
              key={selectedMedia}
            >
              Your browser doesn't support HTML5 video tag.
            </video>
          ) : (
            <img
              src={selectedMedia}
              alt="listing"
              style={{
                width: "100%",
                maxHeight: "900px",
                marginBottom: "5px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            />
          )}
        </Box>
      </Box>
      <Grid
        container
        direction="row"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {media?.length > 1 && (
          <Swiper
            style={{
              "--swiper-navigation-color": "rgb(255, 192, 1)",
              "--swiper-pagination-color": "rgb(255, 192, 1)",
              width: "100%",
            }}
            loop={true}
            navigation={true}
            spaceBetween={40}
            modules={[Navigation, Scrollbar]}
            breakpoints={{
              320: { slidesPerView: 1 },
              480: {
                slidesPerView: media.length <= 2 ? media.length : 2,
              },
              600: {
                slidesPerView: media.length <= 3 ? media.length : 3,
              },
              900: {
                slidesPerView: media.length <= 4 ? media.length : 4,
              },
              1200: {
                slidesPerView: media.length <= 4 ? media.length : 4,
              },
            }}
          >
            {media.map((image, index) => {
              return (
                <SwiperSlide spaceBetween={10}>
                  <Grid
                    item
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                  >
                    {image.url?.includes(".mp4") ||
                    image.url?.includes(".mkv") ||
                    image.url?.includes(".mov") ? (
                      <video
                        src={image.url}
                        onClick={(e) => handleImageSelect(e)}
                        style={{
                          width: "80px",
                          height: "80px",
                          marginBottom: "5px",
                          border: "2px solid grey",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        key={image.url}
                      >
                        Your browser doesn't support HTML5 video tag.
                      </video>
                    ) : (
                      <img
                        className="image"
                        style={{
                          height: "80px",
                          width: "80px",
                        }}
                        alt={image.url}
                        src={image.url}
                        key={image.url}
                        onClick={(e) => handleImageSelect(e)}
                      />
                    )}
                  </Grid>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </Grid>
    </>
  );
};

export default ListImage;
