import React, { Fragment } from "react";
import { Typography, Card, CardContent, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  headerStyle: {
    fontStyle: "normal",
    color: "#000",
    fontWeight: "900 !important",
    marginBottom: "15px !important",
  },
});

const EmailVerification = ({ type }) => {
  const classes = useStyles();

  const firstPara =
    "We have sent an e-mail to you for verification. It's necessary that we verify a working email address because this is how you will receive responses back from sellers.";
  const secondPara =
    "If you do not receive it within several minutes, it may have been blocked by your spam filter (check spam and promotional folders) or you may have typed in the wrong email address.";

  const htmlBreakTag = (
    <Fragment>
      {firstPara}
      <br></br>
      <br></br>
      {secondPara}
    </Fragment>
  );

  return (
    <Stack
      style={{ backgroundColor: "#ebecf0" }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Card
        xl={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ padding: "30px", margin: "60px", width: { md: "30%" } }}
      >
        <CardContent>
          <Typography
            variant="h4"
            align="center"
            className={classes.headerStyle}
          >
            {type === "signup" ? "Verification" : "Password Reset"}
          </Typography>

          <Typography>
            {type === "signup"
              ? htmlBreakTag
              : "We have sent you an e-mail. Please contact us if you do not receive it within a few minutes."}
          </Typography>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default EmailVerification;
