import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  TextField,
  Typography,
} from "@mui/material";

import CustomizedSteppers from "./Stepper";

const CustomModal = ({
  title,
  openModal,
  closeModal,
  content,
  actionTitle,
  actionTitle2,
  actionColor,
  actionColor2,
  setConfirmProp,
  setConfirmProp2,
  handleInputChange,
  disputeMessage,
  showTextBox,
}) => {
  const handleConfirmProp = () => {
    setConfirmProp(true);
    closeModal();
  };

  const handleConfirmProp2 = () => {
    setConfirmProp2(true);
    closeModal();
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "30px",

            width: { xs: "90%", sm: "50%", md: "35%" },
            maxHeight: {
              md: "60%",
              xs: "80%",
              sm: "60%",
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography textAlign={"center"}>{title}</Typography>
        </DialogTitle>

        <DialogContent>
          <CustomizedSteppers activeStep={1} />
          <DialogContentText id="alert-dialog-description">
            <p>
              {content?.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
          </DialogContentText>

          {showTextBox && (
            <>
              <Box mt={2}>
                <TextField
                  placeholder={"Please write the reason here..."}
                  value={disputeMessage}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  rows={6}
                />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions display="flex" flex={1}>
          <Box width="100%" justifyContent={"space-between"} display={"flex"}>
            <Button sx={{ color: "black" }} onClick={closeModal} pl={4}>
              Cancel
            </Button>
            <Button
              sx={{ color: actionColor }}
              autoFocus
              onClick={handleConfirmProp}
              disabled={disputeMessage ? false : true}
              pr={4}
            >
              {actionTitle}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomModal;
