import * as Types from "../types/Auth.types";
import axios from "axios";
import config from "../../config";

export const setUser = (payload) => ({
  type: Types.SET_USER,
  payload,
});

const setUserBillingPortal = (payload) => ({
  type: Types.SET_USER_BILLING_PORTAL,
  payload,
});

const logoutUser = () => ({
  type: Types.LOGOUT,
});

const setResponseErrors = (payload) => ({
  type: Types.SET_ERROR,
  payload,
});

const resetResponseErrors = () => ({
  type: Types.RESET_ERROR,
});

export const resetToken = () => ({
  type: Types.RESET_TOKEN,
});

export const setUserIsSubscribed = () => ({
  type: Types.SET_USER_IS_SUBSCRIBED,
});

export const thunkSignUp =
  ({ name, email, password }) =>
  async (dispatch) => {
    let registerRequest;
    dispatch(resetResponseErrors());
    try {
      registerRequest = await axios.post(`${config.SERVER_URL}/api/users`, {
        user: {
          full_name: name,
          email: email,
          password: password,
        },
      });
      if (registerRequest.status === 200) {
        dispatch(resetResponseErrors());
        return registerRequest;
      }
    } catch ({ response }) {
      registerRequest = response;
      if (registerRequest.status === 422) {
        dispatch(
          setResponseErrors({
            responseError:
              registerRequest.data.errors &&
              Object.keys(registerRequest.data.errors) +
                " " +
                Object.values(registerRequest.data.errors),
          })
        );
      }
    }
  };

export const thunkLogin =
  ({ email, password }) =>
  async (dispatch) => {
    let registerRequest;
    dispatch(resetResponseErrors());

    try {
      registerRequest = await axios.post(
        `${config.SERVER_URL}/api/users/login`,
        {
          user: {
            email: email,
            password: password,
          },
        }
      );
      dispatch(resetResponseErrors());
      const { data: registerRequestData } = registerRequest;
      if (registerRequestData.logged_in) {
        dispatch(resetResponseErrors());
        dispatch(
          setUser({
            user: registerRequestData.data,
            token: registerRequest.headers.authorization.split(" ")[1],
          })
        );
      }
    } catch ({ response }) {
      registerRequest = response;

      if (registerRequest.status === 422) {
        dispatch(
          setResponseErrors({
            responseError:
              registerRequest.data.errors &&
              Object.keys(registerRequest.data.errors) +
                " " +
                Object.values(registerRequest.data.errors),
          })
        );
      }
    }
  };

export const thunkResponseGoogle = (googleResponse) => async (dispatch) => {
  let registerRequest;
  try {
    registerRequest = await axios.post(
      `${config.SERVER_URL}/api/v1/auth/google`,
      {
        provider: "google_oauth2",
        ...googleResponse,
      },
      {
        headers: {
          "Content-Type": "application/json",
          access_token: `${googleResponse.access_token}`,
        },
      }
    );
    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 200 && registerRequestData) {
      dispatch(resetResponseErrors());
      dispatch(
        setUser({
          user: registerRequestData.data,
          token: registerRequest.headers.authorization.split(" ")[1],
        })
      );
    }
    if (registerRequest.status === 422) {
      dispatch(
        setResponseErrors({
          responseError:
            registerRequest.data.errors &&
            Object.keys(registerRequest.data.errors) +
              " " +
              Object.values(registerRequest.data.errors),
        })
      );
    }
  } catch ({ response }) {
    registerRequest = response;

    dispatch(
      setResponseErrors({
        responseError: "Unable to sign in for now. Please try again later.",
      })
    );
  }
};
export const thunkResponseApple = (AppleResponse) => async (dispatch) => {
  let registerRequest;
  try {
    registerRequest = await axios.post(
      `${config.SERVER_URL}/api/v1/auth/apple`,
      {
        provider: "apple",
      },
      {
        headers: {
          "Content-Type": "application/json",
          access_token: `${AppleResponse.access_token}`,
        },
      }
    );
    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 200 && registerRequestData) {
      dispatch(resetResponseErrors());
      dispatch(
        setUser({
          user: registerRequestData.data,
          token: registerRequest.headers.authorization.split(" ")[1],
        })
      );
    }
  } catch ({ response }) {
    registerRequest = response;

    dispatch(
      setResponseErrors({
        responseError: "Unable to sign in for now. Please try again later.",
      })
    );
  }
};
export const thunkResponseFacebook = (facebookResponse) => async (dispatch) => {
  let registerRequest;
  try {
    registerRequest = await axios.post(
      `${config.SERVER_URL}/api/v1/auth/facebook`,
      { provider: "facebook", ...facebookResponse },
      {
        headers: {
          "Content-Type": "application/json",
          access_token: `${facebookResponse.access_token}`,
        },
      }
    );
    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 200 && registerRequestData) {
      dispatch(resetResponseErrors());
      dispatch(
        setUser({
          user: registerRequestData.data,
          token: registerRequest.headers.authorization.split(" ")[1],
        })
      );
    }
  } catch ({ response }) {
    registerRequest = response;
    dispatch(
      setResponseErrors({
        responseError: "Unable to sign in for now. Please try again later.",
      })
    );
    // if (registerRequest.status === 422) {
    //   dispatch(
    //     setResponseErrors({
    //       responseError:
    //         registerRequest.data.errors &&
    //         Object.keys(registerRequest.data.errors) +
    //           " " +
    //           Object.values(registerRequest.data.errors).split(" ")[1],
    //     })
    //   );
    // }
  }
};

export const thunkLogout = () => async (dispatch) => {
  let registerRequest;

  const localStrToken = localStorage.getItem("access_token");
  let localStorageToken;
  if (localStrToken !== "undefined") {
    localStorageToken = typeof window !== "undefined" ? localStrToken : null;
  }

  try {
    registerRequest = await axios.delete(
      `${config.SERVER_URL}/api/users/sign_out`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorageToken}`,
        },
      }
    );
    if (registerRequest.status === 200) {
      dispatch(logoutUser());
      return registerRequest;
    }
  } catch ({ response }) {
    registerRequest = response;
    if (registerRequest) {
      if (registerRequest.status === 401) {
        dispatch(resetToken());
      }
    }
  }
};

export const thunkGetUserBillingPortal =
  ({ userId }) =>
  async (dispatch) => {
    const localStrToken = localStorage.getItem("access_token");
    let localStorageToken;
    if (localStrToken !== "undefined") {
      localStorageToken = typeof window !== "undefined" ? localStrToken : null;
    }

    let registerRequest;
    const data = {
      user_id: userId,
    };

    try {
      registerRequest = await axios.post(
        `${config.SERVER_URL}/billings`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorageToken}`,
          },
        }
      );

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 200) {
        dispatch(
          setUserBillingPortal({ billingPortalUrl: registerRequestData.url })
        );
      }
    } catch ({ response }) {
      registerRequest = response;
    }
  };
