import {
  Alert,
  Box,
  Button,
  Grid,
  Slide,
  Snackbar,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BlockList from "../../../components/seller/settings/BlockList";
import CustomModal from "../../../components/seller/listings/Modal";
import config from "../../../config";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";

const Dashboard = ({ classes, seller, jwtToken, user }) => {
  const [loading, setLoading] = useState(false);

  const [blockedUsers, setBlockedUsers] = useState([]);
  const [paginationStates, setPaginationStates] = useState({
    page: 0,
    rowsPerPage: 10,
    total_records: 0,
  });
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const fetchBlockedUser = async () => {
    setLoading(true);
    let registerRequest;
    try {
      registerRequest = await axios.get(`${config.SERVER_URL}/api/v1/blocks`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 200) {
        console.log(registerRequestData);
        setBlockedUsers(registerRequestData);
        setLoading(false);
      }
    } catch ({ response }) {}
  };

  const handleUnblockUser = async (id) => {
    let registerRequest;
    try {
      registerRequest = await axios.patch(
        `${config.SERVER_URL}/api/v1/blocks/${id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 200) {
        fetchBlockedUser();
      }
    } catch ({ response }) {
      console.log(response);
    }
  };
  useEffect(() => {
    fetchBlockedUser();
  }, [jwtToken]);

  return (
    <>
      {loading ? (
        <Box sx={{ position: "relative", flex: 1 }}>
          <CircularProgress
            size={44}
            sx={{ top: "50%", left: "50%", position: "absolute" }}
          />
        </Box>
      ) : (
        <Grid xs={12}>
          <Box
            px="5px"
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "5px",
              border: "none",
              backgroundColor: "white",
              m: 1,
            }}
          >
            <Typography
              sx={{
                paddingTop: "5px",
                fontWeight: "bold",
              }}
            >
              Block List
            </Typography>
          </Box>
          <Box sx={{ height: "100%", color: "green" }}>
            {blockedUsers && (
              <BlockList
                data={blockedUsers}
                fetchBlockedUser={fetchBlockedUser}
                handleUnblockUser={handleUnblockUser}
              />
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="bottom"
          >
            <TablePagination
              component="div"
              count={paginationStates.total_records}
              page={paginationStates.page}
              rowsPerPage={paginationStates.rowsPerPage}
            />
          </Box>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = ({ Categories, Auth, Seller }) => ({
  classes: Categories.classes,
  user: Auth.user,
  seller: Seller.seller,
  jwtToken: Auth.jwtToken,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
