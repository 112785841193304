import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Box,
  Stack,
  Divider,
  IconButton,
  CircularProgress,
  imageListItemClasses,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import {
  Facebook,
  Instagram,
  LocationOn,
  Person2,
  PhoneEnabled,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import PaymentIcon from "@mui/icons-material/Payment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import CreateOrEditSellerProfile from "./CreateOrEditSellerProfile";
import * as Actions from "../../../redux/actions/";
import ListCard from "../../../components/listing/ListCard";

const SellerProfile = ({
  jwtToken,
  seller,
  listings,
  loadListings,
  classes,
  store,
  getStore,
}) => {
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState(null);
  useEffect(() => {
    setLoading(true);
    if (seller.store) {
      getStore({ storeId: seller.store.id });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (store) {
      setResponseData({
        brandName: store.brand_name,
        brandLogo: store.brand_logo && store.brand_logo,
        ownerName: store.owner_name,
        location: store.location,
        phoneNumber: store.phone_number,
        paymentMethods: store.payment_methods,
        deliveryOptions: store.delivery_option,
        profileColor: store.profile_color,
        socialMedia: {
          facebook: store.facebook_url,
          instagram: store.instagram_url,
          twitter: store.twitter_url,
          youtube: store.youtube_url,
        },
        about: store.about,
        policy: store.policy,
      });
    }
  }, [store]);

  const getSlicedArray = (array, count) => {
    return array.slice(0, count);
  };

  const slicedListings = getSlicedArray(listings, 6);

  useEffect(() => {
    loadListings({ sellerId: seller.id });
  }, []);

  if (!(seller.store || store)) {
    return <CreateOrEditSellerProfile />;
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack
      style={{
        backgroundColor: responseData && responseData.profileColor,
        height: "100%",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alginItem="center"
      >
        <Grid item display="flex">
          <Box
            xl={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              paddingTop: 2,
              paddingBottom: 2,
              margin: "auto",
              width: { xs: "90%", sm: "90%", md: "80%" },
            }}
          >
            <Typography variant="h4" align="left">
              Seller Profile
            </Typography>
            <Typography
              align="left"
              sx={{ fontSize: { xs: "14px", md: "16px" } }}
            >
              Your Profile will only be visible to sellers that you choose to
              interact with by sending inquiries. It will save you time by
              providing information necessary to complete sales.
            </Typography>
            {responseData && (
              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      paddingY: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "auto",
                    }}
                  >
                    <Typography
                      variant="h5"
                      align="left"
                      sx={{ fontWeight: "600" }}
                    >
                      {responseData.brandName}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        component={Link}
                        to={
                          "/seller/profile/edit/" +
                          (seller.store ? `${seller.store.id}` : `${store.id}`)
                        }
                        size="large"
                        sx={{
                          textAlign: "center",
                          marginTop: "10px",
                          border: "1px solid #bbbbbb",
                          textTransform: "none",
                          backgroundColor: "rgb(255, 192, 1) !important",
                          color: "white !important",
                          "&:hover": {
                            backgroundColor: "rgb(255, 192, 1)",
                          },
                        }}
                        paddingX={2}
                        variant="contained"
                      >
                        <EditIcon
                          fontSize=""
                          sx={{
                            marginRight: 1,
                          }}
                        ></EditIcon>
                        Edit
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
            {responseData && (
              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={4} sm={4} md={3} lg={3}>
                  {responseData.brandLogo && responseData.brandLogo.url && (
                    <img
                      alt="qr-code"
                      src={responseData.brandLogo.url}
                      style={{
                        width: "90%",
                      }}
                      onError={(e) => {
                        e.target.src = "fallback-image-url"; // Provide a fallback image URL
                        e.target.alt = "Fallback Image Alt Text"; // Provide a fallback alt text
                      }}
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={9}>
                  <Box
                    sx={{
                      paddingY: 3,
                      margin: "auto",
                    }}
                  >
                    <Grid container direction="row">
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            gap: "20px",
                            mb: 2,
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Person2
                              fontSize="small"
                              sx={{ pr: 0.5, color: "grey" }}
                            />
                            <Typography variant="p">
                              {responseData.ownerName}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <LocationOn
                              fontSize="small"
                              sx={{ pr: 0.5, color: "grey" }}
                            />
                            <Typography variant="p">
                              {responseData.location}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <PhoneEnabled
                              fontSize="small"
                              sx={{ pr: 0.5, color: "grey" }}
                            />
                            <Typography variant="p">
                              {responseData.phoneNumber}
                            </Typography>
                          </Box>
                          {responseData.paymentMethods && (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <PaymentIcon
                                fontSize="small"
                                sx={{ pr: 0.5, color: "grey" }}
                              />
                              <Typography variant="p">
                                {responseData.paymentMethods.join(", ")}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            gap: "20px",
                          }}
                        >
                          {responseData.deliveryOptions &&
                            responseData.deliveryOptions.includes(
                              "local_pickup"
                            ) && (
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <DirectionsCarIcon
                                  fontSize="small"
                                  sx={{ pr: 0.5, color: "grey" }}
                                />
                                <Typography variant="p">
                                  Local Location
                                </Typography>
                              </Box>
                            )}
                          {responseData.deliveryOptions &&
                            responseData.deliveryOptions.includes(
                              "regional_pickup"
                            ) && (
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <LocalShippingIcon
                                  fontSize="small"
                                  sx={{ pr: 0.5, color: "grey" }}
                                />
                                <Typography variant="p">
                                  Regional Location
                                </Typography>
                              </Box>
                            )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box paddingY={3}>
                          {responseData.socialMedia.twitter && (
                            <Link
                              to={responseData.socialMedia.twitter}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ border: "1px solid grey", mr: 1 }}
                              >
                                <Twitter />
                              </IconButton>
                            </Link>
                          )}
                          {responseData.socialMedia.youtube && (
                            <Link
                              to={responseData.socialMedia.youtube}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ border: "1px solid grey", mr: 1 }}
                              >
                                <YouTube />
                              </IconButton>
                            </Link>
                          )}
                          {responseData.socialMedia.facebook && (
                            <Link
                              to={responseData.socialMedia.facebook}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ border: "1px solid grey", mr: 1 }}
                              >
                                <Facebook />
                              </IconButton>
                            </Link>
                          )}
                          {responseData.socialMedia.instagram && (
                            <Link
                              to={responseData.socialMedia.instagram}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ border: "1px solid grey", mr: 1 }}
                              >
                                <Instagram />
                              </IconButton>
                            </Link>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Divider />

                  <Grid container direction="row" sx={{ mt: 2, mb: 2 }}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        align="left"
                        sx={{ fontWeight: "600" }}
                      >
                        Ratings
                      </Typography>

                      <Box paddingY={2}>
                        <Typography variant="p" align="left">
                          No ratings yet.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Divider />

                  <Grid container direction="row" sx={{ mt: 2, mb: 2 }}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        align="left"
                        sx={{ fontWeight: "600" }}
                      >
                        About
                      </Typography>

                      <Box paddingY={2}>
                        <Typography
                          variant="p"
                          align="left"
                          textAlign={"justify"}
                        >
                          {responseData.about}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Divider />

                  <Grid container direction="row" sx={{ mt: 2, mb: 2 }}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        align="left"
                        sx={{ fontWeight: "600" }}
                      >
                        Store Policy
                      </Typography>

                      <Box paddingY={2}>
                        <Typography
                          variant="p"
                          align="left"
                          textAlign={"justify"}
                        >
                          {responseData.policy}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Divider />

                  <Grid container direction="row" sx={{ mt: 2, mb: 2 }}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        align="left"
                        sx={{ fontWeight: "600" }}
                      >
                        Listings
                      </Typography>

                      <Box paddingY={2}>
                        <Grid
                          sx={{
                            width: "100%",
                            backgroundColor: "transparent",
                            mt: 2,
                            display: "grid",
                            gap: { lg: 4, md: 3, sm: 2, xs: 2 },
                            gridTemplateColumns: {
                              xs: "repeat(2, minmax(0, 1fr))",
                              sm: "repeat(3, minmax(0, 1fr))",
                              md: "repeat(3, minmax(0, 1fr))",
                              lg: "repeat(3, minmax(0, 1fr))",
                            },
                            [`& .${imageListItemClasses.root}`]: {
                              display: "flex",
                              flexDirection: "column",
                            },
                          }}
                        >
                          <ListCard
                            listData={slicedListings}
                            classes={classes}
                          />
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

const mapStateToProps = ({ Auth, Seller, Store, Listings, Categories }) => ({
  jwtToken: Auth.jwtToken,
  seller: Seller.seller,
  store: Store.store,
  listings: Listings.listings,
  loading: Listings.loading,
  totalListing: Listings.totalListing,
  classes: Categories.classes,
});

const mapDispatchToProps = (dispatch) => ({
  loadListings: (sellerId) => dispatch(Actions.thunkLoadListing(sellerId)),
  getStore: (storeId) => dispatch(Actions.thunkGetStore(storeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SellerProfile);
