export const Privacy = [
  {
    pages: [
      {
        width: "612",
        height: "792",
        num: "1",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Last",
                        width: "20.66",
                        height: "10.54",
                        left: "72",
                        top: "669.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "modified:",
                        width: "46.66",
                        height: "10.54",
                        left: "95.66",
                        top: "669.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "24th",
                        width: "21.33",
                        height: "10.54",
                        left: "145.32",
                        top: "669.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "August",
                        width: "34.67",
                        height: "10.54",
                        left: "169.65",
                        top: "669.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "2023",
                        width: "24",
                        height: "10.54",
                        left: "207.32",
                        top: "669.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Introduction",
                        width: "79.08",
                        height: "14.06",
                        left: "72",
                        top: "636.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "72",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "M&ore,",
                        width: "38.33",
                        height: "10.54",
                        left: "109.99",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "LLC ,",
                        width: "22.66",
                        height: "10.54",
                        left: "153.57",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(“",
                        width: "9.32",
                        height: "10.54",
                        left: "181.48",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "33.77",
                        height: "10.54",
                        left: "190.8",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics",
                        width: "37.33",
                        height: "10.54",
                        left: "229.82",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”,",
                        width: "8.3200000000001",
                        height: "10.54",
                        left: "267.15",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "“",
                        width: "5.33",
                        height: "10.54",
                        left: "280.72",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "286.05",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”,",
                        width: "8.33",
                        height: "10.54",
                        left: "304.05",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "“",
                        width: "5.32",
                        height: "10.54",
                        left: "317.63",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "322.95",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”,",
                        width: "8.33",
                        height: "10.54",
                        left: "336.94",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "350.52",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "“",
                        width: "5.32",
                        height: "10.54",
                        left: "365.77",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "11.35",
                        height: "10.54",
                        left: "371.09",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”)",
                        width: "9.32",
                        height: "10.54",
                        left: "382.44",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "respects",
                        width: "38.65",
                        height: "10.54",
                        left: "397.01",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "440.91",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "privacy",
                        width: "35.98",
                        height: "10.54",
                        left: "468.15",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "509.38",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "531.96",
                        top: "609.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "committed",
                        width: "51.32",
                        height: "10.54",
                        left: "72",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "126.32",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "protecting",
                        width: "48.65",
                        height: "10.54",
                        left: "138.66",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "6.66",
                        height: "10.54",
                        left: "190.31",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "199.97",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "240.3",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "compliance",
                        width: "55.31",
                        height: "10.54",
                        left: "259.3",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "317.61",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "341.94",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.66",
                        height: "10.54",
                        left: "362.28",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy.",
                        width: "32.88",
                        height: "10.54",
                        left: "401.94",
                        top: "595.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "This",
                        width: "21.33",
                        height: "10.54",
                        left: "72",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.66",
                        height: "10.54",
                        left: "97.08",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "30.67",
                        height: "10.54",
                        left: "137.49",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "describes",
                        width: "44.65",
                        height: "10.54",
                        left: "171.91",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "220.31",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "types",
                        width: "25.33",
                        height: "10.54",
                        left: "238.72",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "267.8",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "281.54",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "341.2",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "358.19",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "381.85",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "416.83",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "443.16",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "464.16",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "477.15",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "498.15",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "519.15",
                        top: "567.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "72",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "112.41",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "141.4",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "visit",
                        width: "20.67",
                        height: "10.54",
                        left: "162.4",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "186.07",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "website",
                        width: "36.66",
                        height: "10.54",
                        left: "203.73",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "https://mooreexotics.com/policy",
                        width: "154.97",
                        height: "10.54",
                        left: "243.39",
                        top: "553.61",
                        color: "#1154cc",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                        link: "https://mooreexotics.com/policy",
                      },
                      {
                        text: "(this",
                        width: "21.34",
                        height: "10.54",
                        left: "407.36",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "431.7",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "444.69",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "“",
                        width: "5.32",
                        height: "10.54",
                        left: "463.69",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "40.67",
                        height: "10.54",
                        left: "469.01",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "”)",
                        width: "9.32",
                        height: "10.54",
                        left: "509.68",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "522",
                        top: "553.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "72",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "practices",
                        width: "42.63",
                        height: "10.54",
                        left: "91",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.54",
                        left: "136.63",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collecting,",
                        width: "50.31",
                        height: "10.54",
                        left: "153.63",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "using,",
                        width: "29.01",
                        height: "10.54",
                        left: "206.94",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "maintaining,",
                        width: "60.32",
                        height: "10.54",
                        left: "238.95",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "protecting,",
                        width: "51.65",
                        height: "10.54",
                        left: "302.27",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "356.92",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclosing",
                        width: "48.67",
                        height: "10.54",
                        left: "377.24",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "428.91",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information.",
                        width: "59.65",
                        height: "10.54",
                        left: "449.91",
                        top: "539.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "This",
                        width: "21.33",
                        height: "10.54",
                        left: "72",
                        top: "512.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.66",
                        height: "10.54",
                        left: "96.33",
                        top: "512.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "30.67",
                        height: "10.54",
                        left: "135.99",
                        top: "512.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applies",
                        width: "33.99",
                        height: "10.54",
                        left: "169.66",
                        top: "512.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "206.65",
                        top: "512.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "218.98",
                        top: "512.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "278.64",
                        top: "512.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect:",
                        width: "35.31",
                        height: "10.54",
                        left: "295.63",
                        top: "512.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.14",
                        left: "90",
                        top: "484.21",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "108",
                        top: "484.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "123",
                        top: "484.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website;",
                        width: "41.69",
                        height: "10.54",
                        left: "143.34",
                        top: "484.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.13",
                        left: "90",
                        top: "470.36",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "108",
                        top: "470.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.66",
                        height: "10.54",
                        left: "120.33",
                        top: "470.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "149.99",
                        top: "470.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "170.31",
                        top: "470.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "electronic",
                        width: "47.3",
                        height: "10.54",
                        left: "197.97",
                        top: "470.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "messages",
                        width: "45.33",
                        height: "10.54",
                        left: "248.27",
                        top: "470.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "between",
                        width: "39.97",
                        height: "10.54",
                        left: "296.6",
                        top: "470.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "339.57",
                        top: "470.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "360.57",
                        top: "470.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "380.9",
                        top: "470.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website;",
                        width: "41.69",
                        height: "10.54",
                        left: "399.9",
                        top: "470.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.13",
                        left: "90",
                        top: "456.51",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "108",
                        top: "456.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "some",
                        width: "25.33",
                        height: "10.54",
                        left: "120.33",
                        top: "456.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cases,",
                        width: "28.32",
                        height: "10.54",
                        left: "148.66",
                        top: "456.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "179.98",
                        top: "456.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "206.31",
                        top: "456.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties,",
                        width: "34.99",
                        height: "10.54",
                        left: "231.97",
                        top: "456.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "269.96",
                        top: "456.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "294.96",
                        top: "456.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "307.95",
                        top: "456.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "business",
                        width: "40.67",
                        height: "10.54",
                        left: "326.95",
                        top: "456.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "partners",
                        width: "38.65",
                        height: "10.54",
                        left: "370.62",
                        top: "456.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "412.27",
                        top: "456.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "33.98",
                        height: "10.54",
                        left: "432.59",
                        top: "456.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providers;",
                        width: "48.66",
                        height: "10.54",
                        left: "469.57",
                        top: "456.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.13",
                        left: "90",
                        top: "442.66",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "108",
                        top: "442.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "139.99",
                        top: "442.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interact",
                        width: "35.98",
                        height: "10.54",
                        left: "163.99",
                        top: "442.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "205.22",
                        top: "442.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "231.8",
                        top: "442.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertising",
                        width: "53.32",
                        height: "10.54",
                        left: "253.05",
                        top: "442.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "311.62",
                        top: "442.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third­party",
                        width: "51.32",
                        height: "10.54",
                        left: "328.87",
                        top: "442.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "websites",
                        width: "41.32",
                        height: "10.54",
                        left: "385.44",
                        top: "442.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "432.01",
                        top: "442.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services,",
                        width: "41.65",
                        height: "10.54",
                        left: "454.59",
                        top: "442.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "501.49",
                        top: "442.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "those",
                        width: "25.33",
                        height: "10.54",
                        left: "514.07",
                        top: "442.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "applications",
                        width: "57.98",
                        height: "10.54",
                        left: "108",
                        top: "428.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "168.98",
                        top: "428.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertising",
                        width: "53.32",
                        height: "10.54",
                        left: "181.98",
                        top: "428.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "include",
                        width: "35.32",
                        height: "10.54",
                        left: "238.3",
                        top: "428.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "links",
                        width: "23.34",
                        height: "10.54",
                        left: "276.62",
                        top: "428.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "302.96",
                        top: "428.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "315.29",
                        top: "428.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.66",
                        height: "10.54",
                        left: "335.63",
                        top: "428.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy;",
                        width: "34",
                        height: "10.54",
                        left: "375.29",
                        top: "428.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "412.29",
                        top: "428.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.13",
                        left: "90",
                        top: "415.01",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "automatically",
                        width: "65.31",
                        height: "10.54",
                        left: "108",
                        top: "414.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "176.31",
                        top: "414.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "216.64",
                        top: "414.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "234.3",
                        top: "414.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "253.29",
                        top: "414.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "tracking",
                        width: "39.32",
                        height: "10.54",
                        left: "266.29",
                        top: "414.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "technologies.",
                        width: "63.65",
                        height: "10.54",
                        left: "308.61",
                        top: "414.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "It",
                        width: "7.33",
                        height: "10.54",
                        left: "72",
                        top: "387.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "does",
                        width: "22",
                        height: "10.54",
                        left: "82.33",
                        top: "387.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "107.33",
                        top: "387.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "apply",
                        width: "26.66",
                        height: "10.54",
                        left: "125.66",
                        top: "387.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "155.32",
                        top: "387.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "167.65",
                        top: "387.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collected",
                        width: "43.31",
                        height: "10.54",
                        left: "227.31",
                        top: "387.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by:",
                        width: "15.33",
                        height: "10.54",
                        left: "273.62",
                        top: "387.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "359.83",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "108",
                        top: "359.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offline",
                        width: "31.77",
                        height: "10.54",
                        left: "123.92",
                        top: "359.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "160.94",
                        top: "359.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "176.19",
                        top: "359.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "218.77",
                        top: "359.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "240.59",
                        top: "359.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "means,",
                        width: "33.65",
                        height: "10.54",
                        left: "269.75",
                        top: "359.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "307.9",
                        top: "359.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "357.73",
                        top: "359.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "374.23",
                        top: "359.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "396.06",
                        top: "359.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "website",
                        width: "36.66",
                        height: "10.54",
                        left: "425.21",
                        top: "359.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "operated",
                        width: "41.31",
                        height: "10.54",
                        left: "466.37",
                        top: "359.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "512.18",
                        top: "359.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "528.68",
                        top: "359.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "(including",
                        width: "49.32",
                        height: "10.54",
                        left: "108",
                        top: "345.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "160.32",
                        top: "345.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "affiliates",
                        width: "41.76",
                        height: "10.54",
                        left: "179.32",
                        top: "345.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "224.08",
                        top: "345.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "subsidiaries);",
                        width: "63.99",
                        height: "10.54",
                        left: "244.41",
                        top: "345.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "311.4",
                        top: "345.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "332.24",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "108",
                        top: "331.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third­party",
                        width: "51.31",
                        height: "10.54",
                        left: "128.33",
                        top: "331.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertising",
                        width: "53.32",
                        height: "10.54",
                        left: "182.64",
                        top: "331.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "238.96",
                        top: "331.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "259.96",
                        top: "331.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "link",
                        width: "18.67",
                        height: "10.54",
                        left: "283.62",
                        top: "331.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "305.29",
                        top: "331.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "317.62",
                        top: "331.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "330.62",
                        top: "331.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accessible",
                        width: "48.64",
                        height: "10.54",
                        left: "344.94",
                        top: "331.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "396.58",
                        top: "331.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "422.91",
                        top: "331.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "435.9",
                        top: "331.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "450.9",
                        top: "331.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "468.56",
                        top: "331.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Please",
                        width: "30.66",
                        height: "10.54",
                        left: "72",
                        top: "303.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "read",
                        width: "20.64",
                        height: "10.54",
                        left: "106.41",
                        top: "303.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "130.8",
                        top: "303.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.66",
                        height: "10.54",
                        left: "151.89",
                        top: "303.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "30.67",
                        height: "10.54",
                        left: "192.3",
                        top: "303.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "carefully",
                        width: "42.64",
                        height: "10.54",
                        left: "226.72",
                        top: "303.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "273.11",
                        top: "303.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "understand",
                        width: "52.65",
                        height: "10.54",
                        left: "286.19",
                        top: "303.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "342.59",
                        top: "303.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "policies",
                        width: "37.32",
                        height: "10.54",
                        left: "362.34",
                        top: "303.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "402.66",
                        top: "303.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "practices",
                        width: "42.64",
                        height: "10.54",
                        left: "422.99",
                        top: "303.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "regarding",
                        width: "45.98",
                        height: "10.54",
                        left: "468.63",
                        top: "303.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "517.61",
                        top: "303.96",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "72",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "133.15",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "how",
                        width: "20.67",
                        height: "10.54",
                        left: "154.98",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "180.15",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "198.64",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "treat",
                        width: "21.31",
                        height: "10.54",
                        left: "221.81",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it.",
                        width: "9.67",
                        height: "10.54",
                        left: "247.62",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "261.79",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "274.28",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "296.78",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "312.53",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.54",
                        left: "331.62",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "361.34",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "386.43",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "policies",
                        width: "37.33",
                        height: "10.54",
                        left: "406.17",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "447.25",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "practices,",
                        width: "45.64",
                        height: "10.54",
                        left: "468.32",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "517.71",
                        top: "290.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "choice",
                        width: "31.31",
                        height: "10.54",
                        left: "72",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "107.06",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "118.82",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discontinue",
                        width: "55.32",
                        height: "10.54",
                        left: "131.9",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "190.97",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "210.72",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "223.72",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "242.71",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "By",
                        width: "14",
                        height: "10.54",
                        left: "287.07",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accessing",
                        width: "45.98",
                        height: "10.54",
                        left: "304.07",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "353.05",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "using",
                        width: "26",
                        height: "10.54",
                        left: "366.05",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "395.05",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.35",
                        height: "10.54",
                        left: "415.39",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "459.74",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agree",
                        width: "25.98",
                        height: "10.54",
                        left: "480.74",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.3299999999999",
                        height: "10.54",
                        left: "509.72",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "522.05",
                        top: "276.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Privacy",
                        width: "36.66",
                        height: "10.55",
                        left: "72",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy.",
                        width: "32.88",
                        height: "10.55",
                        left: "113.16",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "This",
                        width: "21.34",
                        height: "10.55",
                        left: "150.54",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.66",
                        height: "10.55",
                        left: "176.38",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "30.66",
                        height: "10.55",
                        left: "217.54",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "252.7",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "change",
                        width: "33.98",
                        height: "10.55",
                        left: "277.86",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.55",
                        left: "316.34",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.55",
                        left: "344.17",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "369.25",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time.",
                        width: "24.33",
                        height: "10.55",
                        left: "382.33",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.55",
                        left: "410.41",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "continued",
                        width: "47.32",
                        height: "10.55",
                        left: "437.62",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.55",
                        left: "488.69",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.55",
                        left: "508.44",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.55",
                        left: "522.18",
                        top: "262.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "72",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "after",
                        width: "21.97",
                        height: "10.54",
                        left: "114.86",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "141.33",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.98",
                        height: "10.54",
                        left: "159.83",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "changes",
                        width: "38.65",
                        height: "10.54",
                        left: "190.31",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "233.46",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "deemed",
                        width: "37.32",
                        height: "10.54",
                        left: "245.96",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "287.78",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "301.61",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "acceptance",
                        width: "52.61",
                        height: "10.54",
                        left: "317.44",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "374.55",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "those",
                        width: "25.33",
                        height: "10.54",
                        left: "389.05",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "changes,",
                        width: "41.65",
                        height: "10.54",
                        left: "418.13",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "so",
                        width: "10.67",
                        height: "10.54",
                        left: "463.53",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "please",
                        width: "29.98",
                        height: "10.54",
                        left: "477.95",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "check",
                        width: "27.98",
                        height: "10.54",
                        left: "511.68",
                        top: "248.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "72",
                        top: "234.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.65",
                        height: "10.54",
                        left: "92.34",
                        top: "234.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "30.67",
                        height: "10.54",
                        left: "131.99",
                        top: "234.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "periodically",
                        width: "57.31",
                        height: "10.54",
                        left: "165.66",
                        top: "234.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "225.97",
                        top: "234.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "updates.",
                        width: "39.66",
                        height: "10.54",
                        left: "242.96",
                        top: "234.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Individuals",
                        width: "71.98",
                        height: "14.05",
                        left: "72",
                        top: "202.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Under",
                        width: "39.98",
                        height: "14.05",
                        left: "147.97",
                        top: "202.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "19.54",
                        height: "14.05",
                        left: "191.95",
                        top: "202.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Age",
                        width: "26.65",
                        height: "14.05",
                        left: "215.49",
                        top: "202.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "13.32",
                        height: "14.05",
                        left: "246.14",
                        top: "202.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "18",
                        width: "16",
                        height: "14.05",
                        left: "263.46",
                        top: "202.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Our",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "95.16",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "138.02",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "150.52",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "intended",
                        width: "41.32",
                        height: "10.54",
                        left: "170.36",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "216.18",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "individuals",
                        width: "53.33",
                        height: "10.54",
                        left: "234.67",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "292.5",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "18",
                        width: "12",
                        height: "10.54",
                        left: "324.32",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "years",
                        width: "25.32",
                        height: "10.54",
                        left: "340.82",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "370.64",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "age.",
                        width: "19.65",
                        height: "10.54",
                        left: "385.14",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "No",
                        width: "14.67",
                        height: "10.54",
                        left: "412.29",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "one",
                        width: "17.32",
                        height: "10.54",
                        left: "430.71",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "451.78",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "age",
                        width: "16.66",
                        height: "10.54",
                        left: "482.85",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "18",
                        width: "12",
                        height: "10.54",
                        left: "503.26",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "519.01",
                        top: "174.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "72",
                        top: "160.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "113.16",
                        top: "160.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "134.98",
                        top: "160.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "180.13",
                        top: "160.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "241.29",
                        top: "160.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "254.37",
                        top: "160.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "268.12",
                        top: "160.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "283.87",
                        top: "160.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "304.96",
                        top: "160.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "353.81",
                        top: "160.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "373.25",
                        top: "160.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "389",
                        top: "160.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "knowingly",
                        width: "51.33",
                        height: "10.54",
                        left: "408.09",
                        top: "160.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "463.17",
                        top: "160.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "498.9",
                        top: "160.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "72",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "136.15",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "individuals",
                        width: "53.33",
                        height: "10.54",
                        left: "166.23",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "226.31",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "18.",
                        width: "15",
                        height: "10.54",
                        left: "260.38",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "8",
                        height: "10.54",
                        left: "282.13",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "296.88",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "321.63",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "343.03",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "18,",
                        width: "15",
                        height: "10.54",
                        left: "377.1",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "398.85",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "417.6",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "439.68",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "462.43",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "479.17",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "522.58",
                        top: "147.17",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "72",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "133.15",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "149.65",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "170.74",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "212.85",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "226.59",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "242.34",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "256.09",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "297.17",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "318.25",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "11.34",
                        height: "10.54",
                        left: "331.99",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "features,",
                        width: "40.97",
                        height: "10.54",
                        left: "347.08",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "register",
                        width: "35.99",
                        height: "10.54",
                        left: "391.8",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "431.54",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.33",
                        height: "10.54",
                        left: "447.29",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.35",
                        height: "10.54",
                        left: "468.38",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.99",
                        height: "10.54",
                        left: "513.48",
                        top: "133.37",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.55",
                        left: "72",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchases",
                        width: "47.31",
                        height: "10.55",
                        left: "93.08",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.55",
                        left: "144.14",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.55",
                        left: "185.22",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.35",
                        height: "10.55",
                        left: "206.31",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.55",
                        left: "251.41",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.55",
                        left: "270.41",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.55",
                        left: "290.74",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "303.73",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interactive",
                        width: "50.64",
                        height: "10.55",
                        left: "321.39",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "375.03",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "public",
                        width: "29.99",
                        height: "10.55",
                        left: "388.03",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "comment",
                        width: "44.65",
                        height: "10.55",
                        left: "421.02",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "features",
                        width: "37.98",
                        height: "10.55",
                        left: "468.67",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.55",
                        left: "509.65",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.55",
                        left: "522.64",
                        top: "119.57",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "72",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "119.36",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "135.35",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "178.01",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "200.58",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "262.49",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "yourself",
                        width: "39.32",
                        height: "10.54",
                        left: "294.4",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "338.97",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us.",
                        width: "13.67",
                        height: "10.54",
                        left: "353.55",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "8",
                        height: "10.54",
                        left: "372.47",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "385.72",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "learn",
                        width: "23.98",
                        height: "10.54",
                        left: "404.96",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "434.19",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.66",
                        height: "10.54",
                        left: "453.43",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collected",
                        width: "43.3",
                        height: "10.54",
                        left: "481.34",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.89",
                        top: "105.78",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "received",
                        width: "40.63",
                        height: "10.54",
                        left: "72",
                        top: "91.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.66",
                        height: "10.54",
                        left: "119.38",
                        top: "91.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "166.04",
                        top: "91.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "228.69",
                        top: "91.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.32",
                        height: "10.54",
                        left: "258.02",
                        top: "91.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "individual",
                        width: "48.67",
                        height: "10.54",
                        left: "275.34",
                        top: "91.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "330.01",
                        top: "91.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "18",
                        width: "12",
                        height: "10.54",
                        left: "363.33",
                        top: "91.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.67",
                        height: "10.54",
                        left: "381.33",
                        top: "91.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "verification",
                        width: "55.3",
                        height: "10.54",
                        left: "424",
                        top: "91.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "485.3",
                        top: "91.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parental",
                        width: "38.64",
                        height: "10.54",
                        left: "501.3",
                        top: "91.98",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "consent,",
                        width: "39.66",
                        height: "10.54",
                        left: "72",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "115.41",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "133.15",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "delete",
                        width: "28.64",
                        height: "10.54",
                        left: "155.57",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "187.96",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information.",
                        width: "59.65",
                        height: "10.54",
                        left: "209.71",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "273.11",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "284.85",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "believe",
                        width: "34.65",
                        height: "10.54",
                        left: "306.6",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "345",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "might",
                        width: "28",
                        height: "10.54",
                        left: "362.74",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.66",
                        height: "10.54",
                        left: "394.49",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "420.9",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "441.97",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.32",
                        height: "10.54",
                        left: "502.38",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.45",
                        top: "78.18",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,638.5", "lineto,151,638.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#1154cc",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,243,554.5", "lineto,398,554.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,204.5", "lineto,279,204.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "2",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "101.66",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "individual",
                        width: "48.66",
                        height: "10.54",
                        left: "115.99",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "167.65",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "18,",
                        width: "15",
                        height: "10.54",
                        left: "197.97",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "please",
                        width: "29.98",
                        height: "10.54",
                        left: "215.97",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contact",
                        width: "34.65",
                        height: "10.54",
                        left: "248.95",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "286.6",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "using",
                        width: "26",
                        height: "10.54",
                        left: "300.27",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "329.27",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contact",
                        width: "34.65",
                        height: "10.54",
                        left: "346.93",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "384.58",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided",
                        width: "42.66",
                        height: "10.54",
                        left: "444.23",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below.",
                        width: "31.55",
                        height: "10.54",
                        left: "489.89",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Information",
                        width: "76.41",
                        height: "14.06",
                        left: "72",
                        top: "673.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "20.92",
                        height: "14.06",
                        left: "152.4",
                        top: "673.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Collect",
                        width: "46.2",
                        height: "14.06",
                        left: "177.32",
                        top: "673.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "About",
                        width: "39.99",
                        height: "14.06",
                        left: "227.52",
                        top: "673.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "25.94",
                        height: "14.06",
                        left: "271.51",
                        top: "673.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "23.1",
                        height: "14.06",
                        left: "301.45",
                        top: "673.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "How",
                        width: "31.1",
                        height: "14.06",
                        left: "328.55",
                        top: "673.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "20.92",
                        height: "14.06",
                        left: "363.65",
                        top: "673.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Collect",
                        width: "46.2",
                        height: "14.06",
                        left: "388.56",
                        top: "673.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "It",
                        width: "9.77",
                        height: "14.06",
                        left: "438.76",
                        top: "673.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "646",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "94.44",
                        top: "646",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "several",
                        width: "33.98",
                        height: "10.54",
                        left: "133.17",
                        top: "646",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "types",
                        width: "25.33",
                        height: "10.54",
                        left: "173.9",
                        top: "646",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "205.98",
                        top: "646",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "222.73",
                        top: "646",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "286.13",
                        top: "646",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "316.21",
                        top: "646",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "340.28",
                        top: "646",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.67",
                        height: "10.54",
                        left: "373.69",
                        top: "646",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "405.11",
                        top: "646",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "421.85",
                        top: "646",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.35",
                        height: "10.54",
                        left: "445.94",
                        top: "646",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "494.04",
                        top: "646",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information:",
                        width: "59.99",
                        height: "10.54",
                        left: "72",
                        top: "632.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.13",
                        left: "90",
                        top: "604.41",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "108",
                        top: "604.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "29.33",
                        height: "10.54",
                        left: "123.75",
                        top: "604.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "156.83",
                        top: "604.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "178.58",
                        top: "604.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "202.99",
                        top: "604.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personally",
                        width: "49.99",
                        height: "10.54",
                        left: "218.06",
                        top: "604.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identified,",
                        width: "48.98",
                        height: "10.54",
                        left: "271.05",
                        top: "604.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "323.03",
                        top: "604.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "348.03",
                        top: "604.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "name,",
                        width: "28.98",
                        height: "10.54",
                        left: "361.03",
                        top: "604.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "postal",
                        width: "28.67",
                        height: "10.54",
                        left: "393.01",
                        top: "604.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address,",
                        width: "38.98",
                        height: "10.54",
                        left: "424.68",
                        top: "604.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "e­mail",
                        width: "30.65",
                        height: "10.54",
                        left: "466.66",
                        top: "604.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address,",
                        width: "38.99",
                        height: "10.54",
                        left: "500.31",
                        top: "604.35",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "telephone",
                        width: "46.65",
                        height: "10.55",
                        left: "108",
                        top: "590.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "number,",
                        width: "39.17",
                        height: "10.55",
                        left: "160.65",
                        top: "590.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "205.82",
                        top: "590.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.55",
                        left: "221.82",
                        top: "590.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.55",
                        left: "244.39",
                        top: "590.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identifier",
                        width: "43.98",
                        height: "10.55",
                        left: "274.3",
                        top: "590.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.55",
                        left: "323.53",
                        top: "590.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "29.33",
                        height: "10.55",
                        left: "340.78",
                        top: "590.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "375.36",
                        top: "590.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "398.61",
                        top: "590.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.55",
                        left: "424.52",
                        top: "590.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contacted",
                        width: "45.98",
                        height: "10.55",
                        left: "441.09",
                        top: "590.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offline",
                        width: "31.77",
                        height: "10.55",
                        left: "492.32",
                        top: "590.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.55",
                        left: "529.34",
                        top: "590.55",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "online",
                        width: "29.99",
                        height: "10.54",
                        left: "108",
                        top: "576.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '("',
                        width: "8.9",
                        height: "10.54",
                        left: "140.99",
                        top: "576.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "44",
                        height: "10.54",
                        left: "149.89",
                        top: "576.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "196.89",
                        top: "576.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '");',
                        width: "12.23",
                        height: "10.54",
                        left: "258.22",
                        top: "576.76",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.13",
                        left: "90",
                        top: "562.96",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.55",
                        left: "108",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.55",
                        left: "130.49",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.55",
                        left: "142.25",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "172.66",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "but",
                        width: "15.33",
                        height: "10.55",
                        left: "194.41",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "individually",
                        width: "58",
                        height: "10.55",
                        left: "213.49",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "does",
                        width: "21.99",
                        height: "10.55",
                        left: "275.24",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.55",
                        left: "300.98",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identify",
                        width: "37.32",
                        height: "10.55",
                        left: "320.07",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you,",
                        width: "21",
                        height: "10.55",
                        left: "361.14",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.55",
                        left: "385.89",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.55",
                        left: "411.64",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "425.38",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "response",
                        width: "41.99",
                        height: "10.55",
                        left: "451.13",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "496.87",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "online",
                        width: "30",
                        height: "10.55",
                        left: "509.95",
                        top: "562.9",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "surveys;",
                        width: "40",
                        height: "10.54",
                        left: "108",
                        top: "549.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and/or",
                        width: "30.65",
                        height: "10.54",
                        left: "151",
                        top: "549.11",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.13",
                        left: "90",
                        top: "535.31",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "108",
                        top: "535.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "139.16",
                        top: "535.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "internet",
                        width: "36.65",
                        height: "10.54",
                        left: "165.66",
                        top: "535.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "connection,",
                        width: "55.64",
                        height: "10.54",
                        left: "206.81",
                        top: "535.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "266.95",
                        top: "535.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "equipment",
                        width: "50.66",
                        height: "10.54",
                        left: "286.11",
                        top: "535.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "341.27",
                        top: "535.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "363.02",
                        top: "535.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "382.76",
                        top: "535.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "395.85",
                        top: "535.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "430.24",
                        top: "535.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "451.33",
                        top: "535.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "496.44",
                        top: "535.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "517.51",
                        top: "535.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "usage",
                        width: "27.32",
                        height: "10.54",
                        left: "108",
                        top: "521.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "details.",
                        width: "34.33",
                        height: "10.54",
                        left: "138.32",
                        top: "521.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "493.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "90.69",
                        top: "493.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "125.67",
                        top: "493.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information:",
                        width: "59.99",
                        height: "10.54",
                        left: "146.01",
                        top: "493.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "466.33",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "directly",
                        width: "36.65",
                        height: "10.54",
                        left: "108",
                        top: "465.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "147.65",
                        top: "465.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "173.98",
                        top: "465.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "194.98",
                        top: "465.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "223.97",
                        top: "465.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "244.97",
                        top: "465.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "6.66",
                        height: "10.54",
                        left: "284.63",
                        top: "465.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "294.29",
                        top: "465.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us;",
                        width: "14",
                        height: "10.54",
                        left: "306.63",
                        top: "465.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "452.54",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "automatically",
                        width: "65.31",
                        height: "10.54",
                        left: "108",
                        top: "452.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "177.81",
                        top: "452.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "192.3",
                        top: "452.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "navigate",
                        width: "40.65",
                        height: "10.54",
                        left: "214.8",
                        top: "452.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "259.2",
                        top: "452.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "300.28",
                        top: "452.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "site.",
                        width: "19.67",
                        height: "10.54",
                        left: "318.69",
                        top: "452.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "57.31",
                        height: "10.54",
                        left: "342.11",
                        top: "452.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collected",
                        width: "43.31",
                        height: "10.54",
                        left: "403.17",
                        top: "452.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "automatically",
                        width: "65.31",
                        height: "10.54",
                        left: "450.23",
                        top: "452.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "519.29",
                        top: "452.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "include",
                        width: "35.32",
                        height: "10.54",
                        left: "108",
                        top: "438.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "usage",
                        width: "27.32",
                        height: "10.54",
                        left: "150.07",
                        top: "438.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "details,",
                        width: "34.33",
                        height: "10.54",
                        left: "183.39",
                        top: "438.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IP",
                        width: "10.67",
                        height: "10.54",
                        left: "223.72",
                        top: "438.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "addresses,",
                        width: "48.98",
                        height: "10.54",
                        left: "240.39",
                        top: "438.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "295.37",
                        top: "438.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "318.7",
                        top: "438.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collected",
                        width: "43.31",
                        height: "10.54",
                        left: "381.35",
                        top: "438.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "430.66",
                        top: "438.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cookies,",
                        width: "39.65",
                        height: "10.54",
                        left: "473.99",
                        top: "438.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "web",
                        width: "20",
                        height: "10.54",
                        left: "519.64",
                        top: "438.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "beacons,",
                        width: "41.65",
                        height: "10.54",
                        left: "108",
                        top: "424.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "152.65",
                        top: "424.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "172.97",
                        top: "424.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "tracking",
                        width: "39.32",
                        height: "10.54",
                        left: "200.63",
                        top: "424.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "technologies;",
                        width: "63.98",
                        height: "10.54",
                        left: "242.95",
                        top: "424.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "309.93",
                        top: "424.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "411.14",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "108",
                        top: "410.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "136.58",
                        top: "410.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties,",
                        width: "34.99",
                        height: "10.54",
                        left: "164.49",
                        top: "410.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "204.73",
                        top: "410.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "example,",
                        width: "43.65",
                        height: "10.54",
                        left: "223.22",
                        top: "410.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "271.37",
                        top: "410.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "business",
                        width: "40.67",
                        height: "10.54",
                        left: "291.86",
                        top: "410.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "partners",
                        width: "38.65",
                        height: "10.54",
                        left: "337.03",
                        top: "410.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "380.18",
                        top: "410.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "33.97",
                        height: "10.54",
                        left: "402.01",
                        top: "410.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providers,",
                        width: "48.33",
                        height: "10.54",
                        left: "440.48",
                        top: "410.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "493.31",
                        top: "410.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "515.13",
                        top: "410.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "users",
                        width: "24.66",
                        height: "10.54",
                        left: "108",
                        top: "396.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "135.66",
                        top: "396.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "148.66",
                        top: "396.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "166.32",
                        top: "396.86",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Information",
                        width: "60.69",
                        height: "10.55",
                        left: "72",
                        top: "369.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "18.67",
                        height: "10.55",
                        left: "135.69",
                        top: "369.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Provide",
                        width: "37.99",
                        height: "10.55",
                        left: "157.36",
                        top: "369.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "198.35",
                        top: "369.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Us",
                        width: "13.34",
                        height: "10.55",
                        left: "210.68",
                        top: "369.06",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "341.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "93.66",
                        top: "341.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "153.31",
                        top: "341.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "170.3",
                        top: "341.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "205.28",
                        top: "341.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "220.28",
                        top: "341.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "233.28",
                        top: "341.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "273.61",
                        top: "341.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "292.61",
                        top: "341.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "333.96",
                        top: "341.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "include:",
                        width: "38.66",
                        height: "10.54",
                        left: "357.62",
                        top: "341.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "313.94",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "57.32",
                        height: "10.54",
                        left: "108",
                        top: "313.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "173.57",
                        top: "313.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "199.81",
                        top: "313.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "226.06",
                        top: "313.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "270.22",
                        top: "313.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "filling",
                        width: "29.33",
                        height: "10.54",
                        left: "289.72",
                        top: "313.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "326.55",
                        top: "313.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "forms",
                        width: "28",
                        height: "10.54",
                        left: "343.38",
                        top: "313.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "378.88",
                        top: "313.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "398.38",
                        top: "313.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "421.88",
                        top: "313.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "This",
                        width: "21.33",
                        height: "10.54",
                        left: "470.73",
                        top: "313.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "includes",
                        width: "39.99",
                        height: "10.54",
                        left: "499.56",
                        top: "313.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "108",
                        top: "299.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided",
                        width: "42.66",
                        height: "10.54",
                        left: "168.4",
                        top: "299.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "214.81",
                        top: "299.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "227.22",
                        top: "299.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "245.63",
                        top: "299.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "269.96",
                        top: "299.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "registering",
                        width: "51.31",
                        height: "10.54",
                        left: "282.96",
                        top: "299.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "337.27",
                        top: "299.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "354.26",
                        top: "299.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "368.59",
                        top: "299.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "408.9",
                        top: "299.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "421.9",
                        top: "299.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "subscription",
                        width: "58.67",
                        height: "10.54",
                        left: "440.89",
                        top: "299.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service,",
                        width: "36.98",
                        height: "10.54",
                        left: "502.56",
                        top: "299.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "posting",
                        width: "35.34",
                        height: "10.54",
                        left: "108",
                        top: "285.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material,",
                        width: "42.31",
                        height: "10.54",
                        left: "147.09",
                        top: "285.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providing",
                        width: "46.66",
                        height: "10.54",
                        left: "193.15",
                        top: "285.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "243.56",
                        top: "285.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "59.66",
                        height: "10.54",
                        left: "288.63",
                        top: "285.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "352.04",
                        top: "285.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "requesting",
                        width: "49.99",
                        height: "10.54",
                        left: "365.78",
                        top: "285.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "further",
                        width: "32.65",
                        height: "10.54",
                        left: "419.52",
                        top: "285.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services.",
                        width: "41.65",
                        height: "10.54",
                        left: "455.92",
                        top: "285.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "500.57",
                        top: "285.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "519.26",
                        top: "285.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "also",
                        width: "19.33",
                        height: "10.54",
                        left: "108",
                        top: "272.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ask",
                        width: "16",
                        height: "10.54",
                        left: "133.33",
                        top: "272.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "155.33",
                        top: "272.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "179.33",
                        top: "272.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "199.32",
                        top: "272.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "261.97",
                        top: "272.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "293.96",
                        top: "272.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "choose",
                        width: "33.33",
                        height: "10.54",
                        left: "317.21",
                        top: "272.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "355.79",
                        top: "272.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "participate",
                        width: "50.64",
                        height: "10.54",
                        left: "370.37",
                        top: "272.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "426.26",
                        top: "272.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "surveys,",
                        width: "39.66",
                        height: "10.54",
                        left: "440.84",
                        top: "272.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contests",
                        width: "38.66",
                        height: "10.54",
                        left: "485.75",
                        top: "272.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.66",
                        top: "272.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "promotions",
                        width: "54.67",
                        height: "10.54",
                        left: "108",
                        top: "258.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sponsored",
                        width: "48.66",
                        height: "10.54",
                        left: "165.67",
                        top: "258.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "217.33",
                        top: "258.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us,",
                        width: "13.67",
                        height: "10.54",
                        left: "232.33",
                        top: "258.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "249",
                        top: "258.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "269.33",
                        top: "258.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "298.32",
                        top: "258.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "report",
                        width: "28.65",
                        height: "10.54",
                        left: "319.32",
                        top: "258.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "350.97",
                        top: "258.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "problem",
                        width: "39.99",
                        height: "10.54",
                        left: "359.3",
                        top: "258.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "402.29",
                        top: "258.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "426.62",
                        top: "258.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "445.62",
                        top: "258.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "244.95",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Records",
                        width: "39.32",
                        height: "10.54",
                        left: "108",
                        top: "244.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "154.07",
                        top: "244.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copies",
                        width: "30.65",
                        height: "10.54",
                        left: "178.15",
                        top: "244.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "215.55",
                        top: "244.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "232.3",
                        top: "244.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "communications",
                        width: "79.32",
                        height: "10.54",
                        left: "260.3",
                        top: "244.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(including",
                        width: "49.32",
                        height: "10.54",
                        left: "345.62",
                        top: "244.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.65",
                        height: "10.54",
                        left: "400.94",
                        top: "244.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "addresses)",
                        width: "49.98",
                        height: "10.54",
                        left: "433.59",
                        top: "244.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "26",
                        height: "10.54",
                        left: "489.57",
                        top: "244.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "521.57",
                        top: "244.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "communicate",
                        width: "64.64",
                        height: "10.54",
                        left: "108",
                        top: "230.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "175.64",
                        top: "230.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "199.97",
                        top: "230.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "213.64",
                        top: "230.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "226.64",
                        top: "230.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "250.97",
                        top: "230.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "271.3",
                        top: "230.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "19.99",
                        height: "10.54",
                        left: "298.96",
                        top: "230.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "321.95",
                        top: "230.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "362.28",
                        top: "230.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "379.94",
                        top: "230.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "217.35",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Details",
                        width: "33.99",
                        height: "10.54",
                        left: "108",
                        top: "216.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "146.49",
                        top: "216.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transactions",
                        width: "57.31",
                        height: "10.54",
                        left: "160.99",
                        top: "216.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "222.8",
                        top: "216.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "carry",
                        width: "24.65",
                        height: "10.54",
                        left: "245.3",
                        top: "216.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "out",
                        width: "15.33",
                        height: "10.54",
                        left: "273.7",
                        top: "216.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "292.78",
                        top: "216.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "333.86",
                        top: "216.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "353.61",
                        top: "216.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "395.71",
                        top: "216.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "416.79",
                        top: "216.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "430.54",
                        top: "216.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fulfillment",
                        width: "51.32",
                        height: "10.54",
                        left: "448.95",
                        top: "216.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "504.02",
                        top: "216.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "517.76",
                        top: "216.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "orders.",
                        width: "32.99",
                        height: "10.54",
                        left: "108",
                        top: "203.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "146.24",
                        top: "203.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "170.95",
                        top: "203.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "196.86",
                        top: "203.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "required",
                        width: "39.98",
                        height: "10.54",
                        left: "213.44",
                        top: "203.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "258.67",
                        top: "203.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "273.25",
                        top: "203.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "financial",
                        width: "41.97",
                        height: "10.54",
                        left: "315.16",
                        top: "203.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "362.38",
                        top: "203.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "before",
                        width: "30.64",
                        height: "10.54",
                        left: "423.54",
                        top: "203.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "placing",
                        width: "35.32",
                        height: "10.54",
                        left: "458.68",
                        top: "203.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "498.5",
                        top: "203.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "order",
                        width: "25.32",
                        height: "10.54",
                        left: "514.33",
                        top: "203.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "108",
                        top: "189.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "148.33",
                        top: "189.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "167.33",
                        top: "189.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "175.96",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.54",
                        left: "108",
                        top: "175.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "search",
                        width: "30.65",
                        height: "10.54",
                        left: "134.46",
                        top: "175.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "queries",
                        width: "34.65",
                        height: "10.54",
                        left: "168.11",
                        top: "175.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "205.76",
                        top: "175.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "220.76",
                        top: "175.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "238.42",
                        top: "175.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "147.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "19.33",
                        height: "10.54",
                        left: "95.96",
                        top: "147.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.67",
                        height: "10.54",
                        left: "119.79",
                        top: "147.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.65",
                        height: "10.54",
                        left: "144.96",
                        top: "147.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "185.36",
                        top: "147.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "245.77",
                        top: "147.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "258.85",
                        top: "147.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "published,",
                        width: "49.66",
                        height: "10.54",
                        left: "273.93",
                        top: "147.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transmitted,",
                        width: "56.99",
                        height: "10.54",
                        left: "327.34",
                        top: "147.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "388.08",
                        top: "147.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "displayed",
                        width: "45.99",
                        height: "10.54",
                        left: "401.82",
                        top: "147.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "451.56",
                        top: "147.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "public",
                        width: "30",
                        height: "10.54",
                        left: "467.31",
                        top: "147.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "areas",
                        width: "24.64",
                        height: "10.54",
                        left: "501.06",
                        top: "147.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "529.45",
                        top: "147.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "72",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "90.41",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(such",
                        width: "25.99",
                        height: "10.54",
                        left: "132.52",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "162.26",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "176",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "19.99",
                        height: "10.54",
                        left: "201.75",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "profile",
                        width: "31.99",
                        height: "10.54",
                        left: "225.49",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "261.23",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "274.97",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "review",
                        width: "32.65",
                        height: "10.54",
                        left: "294.72",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "boards,",
                        width: "34.99",
                        height: "10.54",
                        left: "331.12",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "forums,",
                        width: "37",
                        height: "10.54",
                        left: "369.86",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "blogs,",
                        width: "29",
                        height: "10.54",
                        left: "409.86",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "441.86",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "454.86",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "postings)",
                        width: "44.01",
                        height: "10.54",
                        left: "482.51",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "529.52",
                        top: "133.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "transmitted",
                        width: "53.99",
                        height: "10.54",
                        left: "72",
                        top: "120.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "130.49",
                        top: "120.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "143.57",
                        top: "120.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.66",
                        height: "10.54",
                        left: "171.98",
                        top: "120.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "200.39",
                        top: "120.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "214.14",
                        top: "120.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "232.55",
                        top: "120.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "274.65",
                        top: "120.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "288.4",
                        top: "120.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "31.99",
                        height: "10.54",
                        left: "314.81",
                        top: "120.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "350.55",
                        top: "120.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "391.63",
                        top: "120.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "410.04",
                        top: "120.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Such",
                        width: "24",
                        height: "10.54",
                        left: "455.14",
                        top: "120.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "482.89",
                        top: "120.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "72",
                        top: "106.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "include",
                        width: "35.32",
                        height: "10.54",
                        left: "96.41",
                        top: "106.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "profile",
                        width: "31.99",
                        height: "10.54",
                        left: "135.48",
                        top: "106.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "171.22",
                        top: "106.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "231.62",
                        top: "106.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pictures,",
                        width: "40.98",
                        height: "10.54",
                        left: "252.7",
                        top: "106.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sales",
                        width: "23.33",
                        height: "10.54",
                        left: "297.43",
                        top: "106.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "323.76",
                        top: "106.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "marketing",
                        width: "48.65",
                        height: "10.54",
                        left: "336.76",
                        top: "106.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "materials,",
                        width: "46.98",
                        height: "10.54",
                        left: "388.41",
                        top: "106.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "listing",
                        width: "30",
                        height: "10.54",
                        left: "438.39",
                        top: "106.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "photos,",
                        width: "35.01",
                        height: "10.54",
                        left: "471.39",
                        top: "106.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "listing",
                        width: "30",
                        height: "10.54",
                        left: "509.4",
                        top: "106.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "descriptions,",
                        width: "60.99",
                        height: "10.55",
                        left: "72",
                        top: "92.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reviews,",
                        width: "40.32",
                        height: "10.55",
                        left: "144.24",
                        top: "92.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "comments,",
                        width: "52.32",
                        height: "10.55",
                        left: "195.81",
                        top: "92.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "suggestions,",
                        width: "59.01",
                        height: "10.55",
                        left: "259.38",
                        top: "92.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.55",
                        left: "329.64",
                        top: "92.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contributions",
                        width: "63.33",
                        height: "10.55",
                        left: "350.88",
                        top: "92.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "424.71",
                        top: "92.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discussion",
                        width: "50.01",
                        height: "10.55",
                        left: "444.54",
                        top: "92.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "threads",
                        width: "34.65",
                        height: "10.55",
                        left: "505.05",
                        top: "92.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "(collectively,",
                        width: "62.19",
                        height: "10.54",
                        left: "72",
                        top: "78.69",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"',
                        width: "4.9",
                        height: "10.54",
                        left: "138.69",
                        top: "78.69",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "23.99",
                        height: "10.54",
                        left: "143.59",
                        top: "78.69",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "41.33",
                        height: "10.54",
                        left: "172.08",
                        top: "78.69",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '").',
                        width: "11.89",
                        height: "10.54",
                        left: "213.41",
                        top: "78.69",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.47",
                        height: "10.54",
                        left: "229.05",
                        top: "78.69",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.65",
                        height: "10.54",
                        left: "256.27",
                        top: "78.69",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "282.67",
                        top: "78.69",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "324.42",
                        top: "78.69",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "posted",
                        width: "31.33",
                        height: "10.54",
                        left: "336.18",
                        top: "78.69",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "371.26",
                        top: "78.69",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transmitted,",
                        width: "56.99",
                        height: "10.54",
                        left: "385",
                        top: "78.69",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "445.74",
                        top: "78.69",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "made",
                        width: "25.98",
                        height: "10.54",
                        left: "466.82",
                        top: "78.69",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.31",
                        height: "10.54",
                        left: "496.55",
                        top: "78.69",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,675.5", "lineto,449,675.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "3",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "others,",
                        width: "32.33",
                        height: "10.54",
                        left: "85.08",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "121.16",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "133.57",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "own",
                        width: "20.66",
                        height: "10.54",
                        left: "159.32",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "risk,",
                        width: "21",
                        height: "10.54",
                        left: "183.73",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "so",
                        width: "10.67",
                        height: "10.54",
                        left: "208.48",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "please",
                        width: "29.98",
                        height: "10.54",
                        left: "222.9",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "256.63",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "careful",
                        width: "33.31",
                        height: "10.54",
                        left: "271.71",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "308.02",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsible",
                        width: "54.66",
                        height: "10.54",
                        left: "328.34",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "whenever",
                        width: "46.64",
                        height: "10.54",
                        left: "386",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "435.64",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "456.64",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "online.",
                        width: "32.99",
                        height: "10.54",
                        left: "474.29",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "510.28",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "521.27",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "choose",
                        width: "33.32",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "109.07",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "post",
                        width: "20",
                        height: "10.54",
                        left: "122.16",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "145.91",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "172.32",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "214.07",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "229.82",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "243.56",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "284.64",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "303.05",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "348.16",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "373.9",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "387.65",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user­to­user",
                        width: "57.07",
                        height: "10.54",
                        left: "428.73",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "messaging",
                        width: "50.66",
                        height: "10.54",
                        left: "488.8",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "88.75",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "132.83",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "19.99",
                        height: "10.54",
                        left: "161.57",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "profile",
                        width: "31.99",
                        height: "10.54",
                        left: "188.31",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "227.05",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "243.8",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "review",
                        width: "32.65",
                        height: "10.54",
                        left: "266.54",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "boards,",
                        width: "34.99",
                        height: "10.54",
                        left: "305.94",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "forums,",
                        width: "37",
                        height: "10.54",
                        left: "347.68",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "blogs,",
                        width: "29",
                        height: "10.54",
                        left: "390.68",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "425.68",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "441.68",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "postings,",
                        width: "43",
                        height: "10.54",
                        left: "472.34",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "521.34",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information:",
                        width: "59.99",
                        height: "10.55",
                        left: "72",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(a)",
                        width: "13.32",
                        height: "10.55",
                        left: "136.49",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "154.31",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.55",
                        left: "179.47",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "195.29",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "209.79",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "become",
                        width: "37.31",
                        height: "10.55",
                        left: "234.95",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "publicly",
                        width: "39.33",
                        height: "10.55",
                        left: "276.76",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available;",
                        width: "46.64",
                        height: "10.55",
                        left: "320.59",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(b)",
                        width: "13.99",
                        height: "10.55",
                        left: "371.73",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "390.22",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.55",
                        left: "415.38",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collected",
                        width: "43.31",
                        height: "10.55",
                        left: "431.21",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.55",
                        left: "479.02",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "used",
                        width: "22",
                        height: "10.55",
                        left: "500.84",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.55",
                        left: "527.34",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "72",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "31.99",
                        height: "10.54",
                        left: "99.16",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "135.65",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "161.48",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.67",
                        height: "10.54",
                        left: "175.98",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "216.4",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "knowledge;",
                        width: "55.99",
                        height: "10.54",
                        left: "236.14",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "295.88",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(c)",
                        width: "13.32",
                        height: "10.54",
                        left: "316.96",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "334.03",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "358.44",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "used",
                        width: "22",
                        height: "10.54",
                        left: "373.51",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "399.26",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "412.34",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "manner",
                        width: "35.98",
                        height: "10.54",
                        left: "421.42",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "461.15",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "482.89",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "violate",
                        width: "32.66",
                        height: "10.54",
                        left: "507.3",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "72",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.65",
                        height: "10.54",
                        left: "93.84",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy,",
                        width: "32.89",
                        height: "10.54",
                        left: "134.99",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "172.38",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law,",
                        width: "19.55",
                        height: "10.54",
                        left: "191.54",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "215.59",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "230.09",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "256.58",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "privacy.",
                        width: "38.21",
                        height: "10.54",
                        left: "301.73",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "348.94",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cannot",
                        width: "31.99",
                        height: "10.54",
                        left: "369.13",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "control",
                        width: "33.99",
                        height: "10.54",
                        left: "405.62",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "444.11",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "actions",
                        width: "33.99",
                        height: "10.54",
                        left: "463.27",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "501.01",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "514.75",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "users",
                        width: "24.66",
                        height: "10.54",
                        left: "72",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "101.16",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "115.66",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "134.82",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.67",
                        height: "10.54",
                        left: "177.67",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "202.09",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "view,",
                        width: "25.55",
                        height: "10.54",
                        left: "226.5",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use,",
                        width: "18.99",
                        height: "10.54",
                        left: "255.8",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclose,",
                        width: "41.66",
                        height: "10.54",
                        left: "278.54",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "323.95",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respond",
                        width: "37.99",
                        height: "10.54",
                        left: "337.7",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "379.44",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "392.53",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "418.27",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content.",
                        width: "41",
                        height: "10.54",
                        left: "444.68",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Therefore,",
                        width: "50.29",
                        height: "10.54",
                        left: "489.43",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "72",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cannot",
                        width: "31.99",
                        height: "10.54",
                        left: "91.24",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "128.48",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "151.05",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "168.3",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "guarantee",
                        width: "46.63",
                        height: "10.54",
                        left: "188.89",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "240.77",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "264.02",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "291.26",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "319.17",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "362.42",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "385.59",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "405.42",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "viewed",
                        width: "34.65",
                        height: "10.54",
                        left: "421.25",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "460.4",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unauthorized",
                        width: "62.64",
                        height: "10.54",
                        left: "476.9",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "persons.",
                        width: "39.66",
                        height: "10.54",
                        left: "72",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Information",
                        width: "60.69",
                        height: "10.54",
                        left: "72",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "24",
                        height: "10.54",
                        left: "135.69",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Affiliates,",
                        width: "48.11",
                        height: "10.54",
                        left: "162.69",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Social",
                        width: "30.66",
                        height: "10.54",
                        left: "213.8",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Networking",
                        width: "58.01",
                        height: "10.54",
                        left: "247.46",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Sites,",
                        width: "26.34",
                        height: "10.54",
                        left: "308.47",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "18.67",
                        height: "10.54",
                        left: "337.81",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Other",
                        width: "28.67",
                        height: "10.54",
                        left: "359.48",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Non­Affiliated",
                        width: "71.78",
                        height: "10.54",
                        left: "391.15",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Third­Parties",
                        width: "66.67",
                        height: "10.54",
                        left: "465.93",
                        top: "568.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "539.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "92.19",
                        top: "539.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "117.35",
                        top: "539.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "153.83",
                        top: "539.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "214.24",
                        top: "539.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "244.65",
                        top: "539.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "266.4",
                        top: "539.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "others",
                        width: "29.33",
                        height: "10.54",
                        left: "280.14",
                        top: "539.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "313.22",
                        top: "539.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "354.3",
                        top: "539.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "affiliates",
                        width: "41.76",
                        height: "10.54",
                        left: "374.04",
                        top: "539.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "419.55",
                        top: "539.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "433.3",
                        top: "539.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "non­affiliated",
                        width: "65.09",
                        height: "10.54",
                        left: "474.38",
                        top: "539.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.55",
                        left: "72",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties.",
                        width: "34.99",
                        height: "10.55",
                        left: "99.16",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.55",
                        left: "138.65",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "example,",
                        width: "43.65",
                        height: "10.55",
                        left: "159.82",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "207.97",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "230.47",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.55",
                        left: "255.63",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "able",
                        width: "19.99",
                        height: "10.55",
                        left: "271.45",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "295.19",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.65",
                        height: "10.55",
                        left: "308.27",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "342.67",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.55",
                        left: "361.08",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.55",
                        left: "403.18",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.55",
                        left: "444.26",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "social",
                        width: "27.99",
                        height: "10.55",
                        left: "453.34",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "networking",
                        width: "54.66",
                        height: "10.55",
                        left: "485.08",
                        top: "525.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "account,",
                        width: "40.31",
                        height: "10.54",
                        left: "72",
                        top: "511.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "116.06",
                        top: "511.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "141.81",
                        top: "511.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Facebook.",
                        width: "49.66",
                        height: "10.54",
                        left: "155.55",
                        top: "511.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "208.96",
                        top: "511.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "220.7",
                        top: "511.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "242.45",
                        top: "511.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "276.84",
                        top: "511.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "295.25",
                        top: "511.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "337.36",
                        top: "511.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "378.44",
                        top: "511.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Facebook",
                        width: "46.65",
                        height: "10.54",
                        left: "404.19",
                        top: "511.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account,",
                        width: "40.31",
                        height: "10.54",
                        left: "454.59",
                        top: "511.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "497.9",
                        top: "511.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "518.9",
                        top: "511.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "allow",
                        width: "26.66",
                        height: "10.54",
                        left: "72",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "103.16",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "117.58",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.66",
                        height: "10.54",
                        left: "130.66",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "157.07",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "191.46",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.65",
                        height: "10.54",
                        left: "204.54",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "240.94",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "301.34",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "314.43",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Facebook",
                        width: "46.65",
                        height: "10.54",
                        left: "340.17",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "profile.",
                        width: "34.99",
                        height: "10.54",
                        left: "390.57",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "This",
                        width: "21.33",
                        height: "10.54",
                        left: "429.31",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "454.39",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "include",
                        width: "35.33",
                        height: "10.54",
                        left: "478.8",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "517.88",
                        top: "497.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "name,",
                        width: "28.99",
                        height: "10.54",
                        left: "72",
                        top: "484.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "profile",
                        width: "31.98",
                        height: "10.54",
                        left: "105.49",
                        top: "484.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "picture,",
                        width: "36.32",
                        height: "10.54",
                        left: "141.97",
                        top: "484.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "gender,",
                        width: "35.17",
                        height: "10.54",
                        left: "182.79",
                        top: "484.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "networks,",
                        width: "46.99",
                        height: "10.54",
                        left: "222.46",
                        top: "484.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "19.99",
                        height: "10.54",
                        left: "273.95",
                        top: "484.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IDs,",
                        width: "20.33",
                        height: "10.54",
                        left: "298.44",
                        top: "484.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "list",
                        width: "14.68",
                        height: "10.54",
                        left: "323.27",
                        top: "484.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "342.45",
                        top: "484.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "friends,",
                        width: "36.32",
                        height: "10.54",
                        left: "356.94",
                        top: "484.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "location,",
                        width: "41.66",
                        height: "10.54",
                        left: "397.76",
                        top: "484.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "date",
                        width: "19.98",
                        height: "10.54",
                        left: "443.92",
                        top: "484.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "468.4",
                        top: "484.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "birth,",
                        width: "25.66",
                        height: "10.54",
                        left: "482.9",
                        top: "484.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.66",
                        height: "10.54",
                        left: "513.06",
                        top: "484.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "address,",
                        width: "38.99",
                        height: "10.54",
                        left: "72",
                        top: "470.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "photos,",
                        width: "35",
                        height: "10.54",
                        left: "114.74",
                        top: "470.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "videos,",
                        width: "34.33",
                        height: "10.54",
                        left: "153.49",
                        top: "470.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "people",
                        width: "31.99",
                        height: "10.54",
                        left: "191.57",
                        top: "470.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "227.31",
                        top: "470.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "follow",
                        width: "31.33",
                        height: "10.54",
                        left: "249.06",
                        top: "470.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and/or",
                        width: "30.65",
                        height: "10.54",
                        left: "284.14",
                        top: "470.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.67",
                        height: "10.54",
                        left: "318.54",
                        top: "470.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "follow",
                        width: "31.33",
                        height: "10.54",
                        left: "342.96",
                        top: "470.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you,",
                        width: "21",
                        height: "10.54",
                        left: "378.04",
                        top: "470.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and/or",
                        width: "30.66",
                        height: "10.54",
                        left: "402.79",
                        top: "470.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "437.2",
                        top: "470.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "posts",
                        width: "24.68",
                        height: "10.54",
                        left: "462.94",
                        top: "470.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "491.37",
                        top: "470.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"likes."',
                        width: "35.46",
                        height: "10.54",
                        left: "504.36",
                        top: "470.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Social",
                        width: "29.99",
                        height: "10.54",
                        left: "72",
                        top: "456.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "networking",
                        width: "54.66",
                        height: "10.54",
                        left: "110.24",
                        top: "456.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sites,",
                        width: "24.33",
                        height: "10.54",
                        left: "173.15",
                        top: "456.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "205.73",
                        top: "456.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "235.98",
                        top: "456.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Facebook,",
                        width: "49.65",
                        height: "10.54",
                        left: "254.23",
                        top: "456.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "312.13",
                        top: "456.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "their",
                        width: "21.99",
                        height: "10.54",
                        left: "343.03",
                        top: "456.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "own",
                        width: "20.67",
                        height: "10.54",
                        left: "373.27",
                        top: "456.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "policies",
                        width: "37.32",
                        height: "10.54",
                        left: "402.19",
                        top: "456.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "447.01",
                        top: "456.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "handling",
                        width: "42",
                        height: "10.54",
                        left: "468.5",
                        top: "456.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "518",
                        top: "456.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information.",
                        width: "59.65",
                        height: "10.54",
                        left: "72",
                        top: "442.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.54",
                        left: "138.4",
                        top: "442.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "161.82",
                        top: "442.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "description",
                        width: "53.32",
                        height: "10.54",
                        left: "173.9",
                        top: "442.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "233.97",
                        top: "442.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "how",
                        width: "20.66",
                        height: "10.54",
                        left: "250.72",
                        top: "442.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "277.38",
                        top: "442.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sites",
                        width: "21.33",
                        height: "10.54",
                        left: "308.04",
                        top: "442.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "335.37",
                        top: "442.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "362.03",
                        top: "442.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "384.03",
                        top: "442.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclose",
                        width: "38.66",
                        height: "10.54",
                        left: "407.36",
                        top: "442.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "452.02",
                        top: "442.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "59.66",
                        height: "10.54",
                        left: "480.01",
                        top: "442.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "72",
                        top: "428.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "121.08",
                        top: "428.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "142.15",
                        top: "428.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "202.56",
                        top: "428.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.98",
                        height: "10.54",
                        left: "224.31",
                        top: "428.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "public,",
                        width: "33",
                        height: "10.54",
                        left: "254.04",
                        top: "428.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "please",
                        width: "29.98",
                        height: "10.54",
                        left: "290.79",
                        top: "428.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "consult",
                        width: "34.67",
                        height: "10.54",
                        left: "323.77",
                        top: "428.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "those",
                        width: "25.33",
                        height: "10.54",
                        left: "361.44",
                        top: "428.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sites'",
                        width: "23.49",
                        height: "10.54",
                        left: "389.77",
                        top: "428.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "privacy",
                        width: "35.98",
                        height: "10.54",
                        left: "416.26",
                        top: "428.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "policies.",
                        width: "40.33",
                        height: "10.54",
                        left: "455.24",
                        top: "428.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "498.57",
                        top: "428.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "517.26",
                        top: "428.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "no",
                        width: "12",
                        height: "10.54",
                        left: "72",
                        top: "415.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "control",
                        width: "33.99",
                        height: "10.54",
                        left: "89.25",
                        top: "415.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "over",
                        width: "21.32",
                        height: "10.54",
                        left: "128.49",
                        top: "415.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "how",
                        width: "20.67",
                        height: "10.54",
                        left: "155.06",
                        top: "415.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "180.23",
                        top: "415.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third­party",
                        width: "51.32",
                        height: "10.54",
                        left: "202.05",
                        top: "415.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "site",
                        width: "16.67",
                        height: "10.54",
                        left: "257.87",
                        top: "415.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "uses",
                        width: "20.66",
                        height: "10.54",
                        left: "279.04",
                        top: "415.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "304.2",
                        top: "415.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discloses",
                        width: "43.33",
                        height: "10.54",
                        left: "318.7",
                        top: "415.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "366.53",
                        top: "415.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "385.69",
                        top: "415.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "430.84",
                        top: "415.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "6.67",
                        height: "10.54",
                        left: "491.99",
                        top: "415.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collects",
                        width: "36.65",
                        height: "10.54",
                        left: "503.16",
                        top: "415.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.55",
                        left: "72",
                        top: "401.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you.",
                        width: "21",
                        height: "10.55",
                        left: "101.66",
                        top: "401.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "372.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "95.19",
                        top: "372.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "122.6",
                        top: "372.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "161.33",
                        top: "372.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "224.74",
                        top: "372.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "258.15",
                        top: "372.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "282.9",
                        top: "372.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "others",
                        width: "29.33",
                        height: "10.54",
                        left: "299.64",
                        top: "372.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "335.72",
                        top: "372.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "non­affiliated",
                        width: "65.08",
                        height: "10.54",
                        left: "379.8",
                        top: "372.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "451.63",
                        top: "372.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties.",
                        width: "34.99",
                        height: "10.54",
                        left: "481.05",
                        top: "372.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.54",
                        left: "522.79",
                        top: "372.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "example,",
                        width: "43.65",
                        height: "10.54",
                        left: "72",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "120.9",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "135.48",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "extent",
                        width: "29.32",
                        height: "10.54",
                        left: "155.39",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "permitted",
                        width: "45.99",
                        height: "10.54",
                        left: "189.96",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "241.2",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law,",
                        width: "19.54",
                        height: "10.54",
                        left: "258.45",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "283.24",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may,",
                        width: "22.89",
                        height: "10.54",
                        left: "302.48",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "330.62",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "345.2",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "366.45",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion,",
                        width: "50.32",
                        height: "10.54",
                        left: "391.03",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ask",
                        width: "15.99",
                        height: "10.54",
                        left: "446.6",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "467.09",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "485.58",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "507.41",
                        top: "358.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "supplemental",
                        width: "63.98",
                        height: "10.54",
                        left: "72",
                        top: "344.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "141.23",
                        top: "344.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "203.14",
                        top: "344.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "235.05",
                        top: "344.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "258.3",
                        top: "344.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "286.13",
                        top: "344.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties,",
                        width: "34.99",
                        height: "10.54",
                        left: "313.29",
                        top: "344.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "352.78",
                        top: "344.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "379.27",
                        top: "344.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "393.77",
                        top: "344.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "454.92",
                        top: "344.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "486.08",
                        top: "344.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "credit",
                        width: "27.31",
                        height: "10.54",
                        left: "512.58",
                        top: "344.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "72",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "99.08",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "credit",
                        width: "27.32",
                        height: "10.54",
                        left: "108.15",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bureau,",
                        width: "35.65",
                        height: "10.54",
                        left: "139.22",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "178.62",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "192.36",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "252.77",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "verify",
                        width: "28.65",
                        height: "10.54",
                        left: "265.85",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "298.25",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identity",
                        width: "36.66",
                        height: "10.54",
                        left: "324",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "364.41",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "trustworthiness,",
                        width: "76.33",
                        height: "10.54",
                        left: "377.41",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "456.74",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.54",
                        left: "469.73",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "486.73",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fraud",
                        width: "25.32",
                        height: "10.54",
                        left: "514.38",
                        top: "331.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "317.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "safety",
                        width: "28.65",
                        height: "10.54",
                        left: "85.75",
                        top: "317.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "protection",
                        width: "48.65",
                        height: "10.54",
                        left: "117.4",
                        top: "317.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes.",
                        width: "45.66",
                        height: "10.54",
                        left: "169.05",
                        top: "317.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "217.71",
                        top: "317.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "236.4",
                        top: "317.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "combine",
                        width: "41.32",
                        height: "10.54",
                        left: "260.06",
                        top: "317.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "304.38",
                        top: "317.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "364.04",
                        top: "317.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "385.03",
                        top: "317.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "402.02",
                        top: "317.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "437",
                        top: "317.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "463.33",
                        top: "317.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "484.33",
                        top: "317.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.66",
                        top: "317.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "72",
                        top: "303.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "114.86",
                        top: "303.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "140.69",
                        top: "303.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "201.84",
                        top: "303.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "224.34",
                        top: "303.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "obtain",
                        width: "29.99",
                        height: "10.54",
                        left: "242.83",
                        top: "303.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "277.32",
                        top: "303.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "305.15",
                        top: "303.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "331.65",
                        top: "303.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "31.99",
                        height: "10.54",
                        left: "358.81",
                        top: "303.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "395.3",
                        top: "303.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "416.37",
                        top: "303.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "derived",
                        width: "35.98",
                        height: "10.54",
                        left: "476.78",
                        top: "303.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "516.51",
                        top: "303.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "289.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "92.33",
                        top: "289.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "products",
                        width: "41.33",
                        height: "10.54",
                        left: "119.98",
                        top: "289.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "164.31",
                        top: "289.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.65",
                        height: "10.54",
                        left: "177.3",
                        top: "289.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "218.95",
                        top: "289.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide.",
                        width: "39.65",
                        height: "10.54",
                        left: "235.95",
                        top: "289.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Through",
                        width: "41.33",
                        height: "10.54",
                        left: "72",
                        top: "260.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "non­affiliated",
                        width: "65.08",
                        height: "10.54",
                        left: "119.33",
                        top: "260.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "190.41",
                        top: "260.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties,",
                        width: "34.98",
                        height: "10.54",
                        left: "219.08",
                        top: "260.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "260.06",
                        top: "260.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "280.05",
                        top: "260.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "19.33",
                        height: "10.54",
                        left: "306.71",
                        top: "260.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.99",
                        height: "10.54",
                        left: "332.04",
                        top: "260.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "370.03",
                        top: "260.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "432.68",
                        top: "260.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "demonstrates",
                        width: "63.32",
                        height: "10.54",
                        left: "456.67",
                        top: "260.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "525.24",
                        top: "260.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "occurrence",
                        width: "52.62",
                        height: "10.54",
                        left: "72",
                        top: "247.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "128.37",
                        top: "247.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "142.12",
                        top: "247.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "off­Website",
                        width: "56.12",
                        height: "10.54",
                        left: "157.2",
                        top: "247.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "communication",
                        width: "74.65",
                        height: "10.54",
                        left: "217.07",
                        top: "247.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "between",
                        width: "39.98",
                        height: "10.54",
                        left: "295.47",
                        top: "247.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.66",
                        height: "10.54",
                        left: "339.2",
                        top: "247.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "367.61",
                        top: "247.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "order",
                        width: "25.31",
                        height: "10.54",
                        left: "379.95",
                        top: "247.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "408.26",
                        top: "247.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ensure",
                        width: "31.32",
                        height: "10.54",
                        left: "420.6",
                        top: "247.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accurate",
                        width: "39.96",
                        height: "10.54",
                        left: "454.92",
                        top: "247.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "charging",
                        width: "41.76",
                        height: "10.54",
                        left: "497.88",
                        top: "247.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "233.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "93.08",
                        top: "233.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "138.15",
                        top: "233.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amounts",
                        width: "40.67",
                        height: "10.54",
                        left: "151.89",
                        top: "233.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "196.31",
                        top: "233.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transactions",
                        width: "57.32",
                        height: "10.54",
                        left: "214.05",
                        top: "233.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "between",
                        width: "39.97",
                        height: "10.54",
                        left: "275.12",
                        top: "233.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.67",
                        height: "10.54",
                        left: "318.84",
                        top: "233.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "347.26",
                        top: "233.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "388.34",
                        top: "233.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.35",
                        height: "10.54",
                        left: "406.75",
                        top: "233.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "451.85",
                        top: "233.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "472.93",
                        top: "233.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enforce",
                        width: "35.97",
                        height: "10.54",
                        left: "486.01",
                        top: "233.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.98",
                        top: "233.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "72",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "106.32",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use.",
                        width: "21.67",
                        height: "10.54",
                        left: "120.81",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "146.98",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "159.47",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "send",
                        width: "21.99",
                        height: "10.54",
                        left: "181.97",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "text",
                        width: "18",
                        height: "10.54",
                        left: "208.46",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "messages",
                        width: "45.32",
                        height: "10.54",
                        left: "230.96",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "280.78",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "another",
                        width: "35.98",
                        height: "10.54",
                        left: "306.62",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "19.99",
                        height: "10.54",
                        left: "347.1",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "370.84",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "384.59",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "403",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "using",
                        width: "26.01",
                        height: "10.54",
                        left: "445.1",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "474.86",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "telephone",
                        width: "46.64",
                        height: "10.54",
                        left: "493.27",
                        top: "219.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "number",
                        width: "36.66",
                        height: "10.54",
                        left: "72",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "112.41",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "130.15",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "19.99",
                        height: "10.54",
                        left: "151.89",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "available",
                        width: "43.31",
                        height: "10.54",
                        left: "175.63",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "222.69",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "238.44",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "256.1",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "300.46",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "317.45",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "341.11",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "360.11",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third­party",
                        width: "51.32",
                        height: "10.54",
                        left: "368.43",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "33.98",
                        height: "10.54",
                        left: "422.75",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provider",
                        width: "40.65",
                        height: "10.54",
                        left: "459.73",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "503.38",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "track",
                        width: "23.99",
                        height: "10.54",
                        left: "515.71",
                        top: "205.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "72",
                        top: "191.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "text",
                        width: "17.99",
                        height: "10.54",
                        left: "101.91",
                        top: "191.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "messages.",
                        width: "48.32",
                        height: "10.54",
                        left: "125.15",
                        top: "191.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "177.97",
                        top: "191.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "track",
                        width: "23.99",
                        height: "10.54",
                        left: "198.16",
                        top: "191.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "226.65",
                        top: "191.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "text",
                        width: "18",
                        height: "10.54",
                        left: "255.8",
                        top: "191.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "messages",
                        width: "45.32",
                        height: "10.54",
                        left: "278.3",
                        top: "191.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "328.12",
                        top: "191.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fraud",
                        width: "25.32",
                        height: "10.54",
                        left: "346.61",
                        top: "191.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prevention,",
                        width: "54.32",
                        height: "10.54",
                        left: "376.43",
                        top: "191.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "435.25",
                        top: "191.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ensure",
                        width: "31.32",
                        height: "10.54",
                        left: "449.08",
                        top: "191.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "appropriate",
                        width: "54.64",
                        height: "10.54",
                        left: "484.9",
                        top: "191.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "charging",
                        width: "41.77",
                        height: "10.54",
                        left: "72",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "118.27",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amounts",
                        width: "40.67",
                        height: "10.54",
                        left: "132.76",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "177.93",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transactions",
                        width: "57.31",
                        height: "10.54",
                        left: "219.01",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "280.07",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "295.82",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "314.23",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "359.34",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enforce",
                        width: "35.97",
                        height: "10.54",
                        left: "372.42",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "412.14",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "431.89",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "465.46",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use,",
                        width: "21.67",
                        height: "10.54",
                        left: "479.2",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "504.62",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "525.69",
                        top: "178.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "quality",
                        width: "33.33",
                        height: "10.54",
                        left: "72",
                        top: "164.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "109.83",
                        top: "164.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "training",
                        width: "37.33",
                        height: "10.54",
                        left: "131.65",
                        top: "164.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes.",
                        width: "45.66",
                        height: "10.54",
                        left: "173.48",
                        top: "164.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "As",
                        width: "13.34",
                        height: "10.54",
                        left: "223.64",
                        top: "164.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "part",
                        width: "18.65",
                        height: "10.54",
                        left: "241.48",
                        top: "164.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "264.63",
                        top: "164.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "279.13",
                        top: "164.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "process,",
                        width: "38.99",
                        height: "10.54",
                        left: "300.97",
                        top: "164.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "344.46",
                        top: "164.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "362.95",
                        top: "164.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "384.77",
                        top: "164.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third­party",
                        width: "51.32",
                        height: "10.54",
                        left: "405.27",
                        top: "164.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "33.97",
                        height: "10.54",
                        left: "461.09",
                        top: "164.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provider",
                        width: "40.66",
                        height: "10.54",
                        left: "498.81",
                        top: "164.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "72",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "receive",
                        width: "34.63",
                        height: "10.54",
                        left: "96.41",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "134.79",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "real",
                        width: "17.98",
                        height: "10.54",
                        left: "147.88",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "169.61",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "194.69",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "store",
                        width: "23.32",
                        height: "10.54",
                        left: "215.77",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "19.99",
                        height: "10.54",
                        left: "242.84",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "266.58",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "296.99",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "text",
                        width: "18",
                        height: "10.54",
                        left: "322.73",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "message,",
                        width: "43.65",
                        height: "10.54",
                        left: "344.48",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "391.88",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "440.96",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "date",
                        width: "19.99",
                        height: "10.54",
                        left: "459.37",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "483.11",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "504.18",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "529.26",
                        top: "150.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "72",
                        top: "136.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "text",
                        width: "17.99",
                        height: "10.55",
                        left: "89.66",
                        top: "136.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "message,",
                        width: "43.66",
                        height: "10.55",
                        left: "110.65",
                        top: "136.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.55",
                        left: "157.31",
                        top: "136.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "phone",
                        width: "29.33",
                        height: "10.55",
                        left: "182.3",
                        top: "136.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "number,",
                        width: "39.17",
                        height: "10.55",
                        left: "214.63",
                        top: "136.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "256.8",
                        top: "136.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "277.13",
                        top: "136.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.55",
                        left: "294.79",
                        top: "136.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "333.11",
                        top: "136.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "346.11",
                        top: "136.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "text",
                        width: "17.99",
                        height: "10.55",
                        left: "363.77",
                        top: "136.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "message.",
                        width: "43.65",
                        height: "10.55",
                        left: "384.76",
                        top: "136.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Information",
                        width: "60.69",
                        height: "10.54",
                        left: "72",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.1",
                        height: "10.54",
                        left: "135.69",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Collect",
                        width: "34.66",
                        height: "10.54",
                        left: "153.79",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Through",
                        width: "44.02",
                        height: "10.54",
                        left: "191.45",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Automatic",
                        width: "51.34",
                        height: "10.54",
                        left: "238.47",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Data",
                        width: "24",
                        height: "10.54",
                        left: "292.81",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Collection",
                        width: "50.67",
                        height: "10.54",
                        left: "319.81",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Technologies;",
                        width: "68.89",
                        height: "10.54",
                        left: "373.48",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Cookie",
                        width: "34.66",
                        height: "10.54",
                        left: "445.37",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "30.65",
                        height: "10.54",
                        left: "483.03",
                        top: "107.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "4",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "As",
                        width: "13.34",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "89.84",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "navigate",
                        width: "40.64",
                        height: "10.54",
                        left: "112.34",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "157.48",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "199.31",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interact",
                        width: "35.98",
                        height: "10.54",
                        left: "221.14",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "261.62",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "287.45",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.35",
                        height: "10.54",
                        left: "307.95",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "353.8",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "372.29",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "397.45",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "automatic",
                        width: "47.31",
                        height: "10.54",
                        left: "417.2",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "19.99",
                        height: "10.54",
                        left: "468.26",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collection",
                        width: "47.31",
                        height: "10.54",
                        left: "492",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "technologies",
                        width: "60.65",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "136.4",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "148.73",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.65",
                        height: "10.54",
                        left: "183.71",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "219.36",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "279.01",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "308.67",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "equipment,",
                        width: "53.65",
                        height: "10.54",
                        left: "333.67",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "browsing",
                        width: "44.67",
                        height: "10.54",
                        left: "390.32",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "actions,",
                        width: "36.99",
                        height: "10.54",
                        left: "437.99",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "477.98",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "patterns,",
                        width: "40.99",
                        height: "10.54",
                        left: "498.3",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "including:",
                        width: "48.66",
                        height: "10.54",
                        left: "72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "651.28",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Details",
                        width: "33.99",
                        height: "10.55",
                        left: "108",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "145.74",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.55",
                        left: "159.49",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "visits",
                        width: "25.34",
                        height: "10.55",
                        left: "185.23",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.55",
                        left: "214.32",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.55",
                        left: "227.41",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.55",
                        left: "247.15",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.55",
                        left: "292.26",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "traffic",
                        width: "29.09",
                        height: "10.55",
                        left: "341.34",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data,",
                        width: "22.99",
                        height: "10.55",
                        left: "374.18",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "location",
                        width: "38.65",
                        height: "10.55",
                        left: "400.92",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data,",
                        width: "22.99",
                        height: "10.55",
                        left: "442.57",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "logs,",
                        width: "23",
                        height: "10.55",
                        left: "468.56",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "494.56",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.55",
                        left: "514.89",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "communication",
                        width: "74.65",
                        height: "10.54",
                        left: "108",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "19.98",
                        height: "10.54",
                        left: "185.65",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "208.63",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "228.96",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "resources",
                        width: "45.31",
                        height: "10.54",
                        left: "246.62",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "294.93",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "315.93",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "336.93",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "370.57",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "390.9",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "409.89",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "424.89",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "442.55",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.2800000000001",
                        left: "90",
                        top: "623.68",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "57.32",
                        height: "10.54",
                        left: "108",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "171.32",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "203.98",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "computer",
                        width: "45.32",
                        height: "10.54",
                        left: "231.22",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "281.79",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "internet",
                        width: "36.65",
                        height: "10.54",
                        left: "304.37",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "connection,",
                        width: "55.64",
                        height: "10.54",
                        left: "346.27",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "407.16",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "457.74",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IP",
                        width: "10.67",
                        height: "10.54",
                        left: "484.99",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address,",
                        width: "38.98",
                        height: "10.54",
                        left: "500.91",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "operating",
                        width: "45.32",
                        height: "10.54",
                        left: "108",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "system,",
                        width: "36.33",
                        height: "10.54",
                        left: "156.32",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "195.65",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "browser",
                        width: "38.65",
                        height: "10.54",
                        left: "215.98",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "type.",
                        width: "23.66",
                        height: "10.54",
                        left: "257.63",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "95.16",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "156.31",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "174.8",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "automatically",
                        width: "65.31",
                        height: "10.54",
                        left: "211.28",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "does",
                        width: "22",
                        height: "10.54",
                        left: "281.09",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "307.59",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "include",
                        width: "35.32",
                        height: "10.54",
                        left: "327.42",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "367.24",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information.",
                        width: "59.66",
                        height: "10.54",
                        left: "412.39",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "It",
                        width: "7.33",
                        height: "10.54",
                        left: "475.8",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "helps",
                        width: "25.33",
                        height: "10.54",
                        left: "486.88",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "515.96",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "530.38",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "improve",
                        width: "39.99",
                        height: "10.54",
                        left: "72",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "115.74",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "134.74",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "176.09",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "196.42",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "deliver",
                        width: "33.32",
                        height: "10.54",
                        left: "208.75",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "245.07",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "better",
                        width: "27.32",
                        height: "10.54",
                        left: "253.39",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "283.71",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "more",
                        width: "24.65",
                        height: "10.54",
                        left: "304.04",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personalized",
                        width: "60.64",
                        height: "10.54",
                        left: "331.69",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service,",
                        width: "36.98",
                        height: "10.54",
                        left: "395.33",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "435.31",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "483.64",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enabling",
                        width: "41.32",
                        height: "10.54",
                        left: "498.64",
                        top: "567.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "72",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to:",
                        width: "12.67",
                        height: "10.54",
                        left: "85.67",
                        top: "554.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.14",
                        left: "90",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Estimate",
                        width: "41.99",
                        height: "10.54",
                        left: "108",
                        top: "526.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "152.99",
                        top: "526.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "audience",
                        width: "42.64",
                        height: "10.54",
                        left: "171.98",
                        top: "526.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "size",
                        width: "18.66",
                        height: "10.54",
                        left: "217.62",
                        top: "526.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "239.28",
                        top: "526.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "usage",
                        width: "27.32",
                        height: "10.54",
                        left: "259.61",
                        top: "526.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "patterns.",
                        width: "40.98",
                        height: "10.54",
                        left: "289.93",
                        top: "526.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.13",
                        left: "90",
                        top: "512.36",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Store",
                        width: "25.33",
                        height: "10.54",
                        left: "108",
                        top: "512.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "141.58",
                        top: "512.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "206.48",
                        top: "512.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "241.39",
                        top: "512.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "preferences,",
                        width: "58.29",
                        height: "10.54",
                        left: "271.64",
                        top: "512.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "allowing",
                        width: "41.99",
                        height: "10.54",
                        left: "337.43",
                        top: "512.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "386.92",
                        top: "512.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "405.09",
                        top: "512.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "customize",
                        width: "48.65",
                        height: "10.54",
                        left: "421.93",
                        top: "512.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "478.08",
                        top: "512.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "501.57",
                        top: "512.31",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "according",
                        width: "47.31",
                        height: "10.54",
                        left: "108",
                        top: "498.51",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "158.31",
                        top: "498.51",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "170.64",
                        top: "498.51",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "individual",
                        width: "48.66",
                        height: "10.54",
                        left: "195.64",
                        top: "498.51",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interests.",
                        width: "42.99",
                        height: "10.54",
                        left: "247.3",
                        top: "498.51",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.13",
                        left: "90",
                        top: "484.71",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Speed",
                        width: "29.33",
                        height: "10.54",
                        left: "108",
                        top: "484.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "up",
                        width: "12",
                        height: "10.54",
                        left: "140.33",
                        top: "484.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "155.33",
                        top: "484.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "searches.",
                        width: "43.64",
                        height: "10.54",
                        left: "180.32",
                        top: "484.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.13",
                        left: "90",
                        top: "470.86",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Recognize",
                        width: "50.64",
                        height: "10.54",
                        left: "108",
                        top: "470.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "161.64",
                        top: "470.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "182.64",
                        top: "470.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "211.63",
                        top: "470.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "return",
                        width: "28.66",
                        height: "10.54",
                        left: "232.63",
                        top: "470.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "264.29",
                        top: "470.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "276.62",
                        top: "470.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "295.62",
                        top: "470.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "443.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "technologies",
                        width: "60.65",
                        height: "10.54",
                        left: "93.66",
                        top: "443.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "157.31",
                        top: "443.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "174.3",
                        top: "443.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.54",
                        left: "193.29",
                        top: "443.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.33",
                        height: "10.54",
                        left: "210.29",
                        top: "443.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "automatic",
                        width: "47.31",
                        height: "10.54",
                        left: "230.63",
                        top: "443.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "19.99",
                        height: "10.54",
                        left: "280.94",
                        top: "443.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collection",
                        width: "47.31",
                        height: "10.54",
                        left: "303.93",
                        top: "443.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "354.24",
                        top: "443.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "include:",
                        width: "38.65",
                        height: "10.54",
                        left: "377.9",
                        top: "443.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "415.69",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Cookies",
                        width: "40.67",
                        height: "10.54",
                        left: "108",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(or",
                        width: "15.32",
                        height: "10.54",
                        left: "152.42",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "browser",
                        width: "41.77",
                        height: "10.54",
                        left: "171.49",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cookies)",
                        width: "41.33",
                        height: "10.54",
                        left: "217.01",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "258.34",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "A",
                        width: "8.67",
                        height: "10.54",
                        left: "265.09",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cookie",
                        width: "31.98",
                        height: "10.54",
                        left: "277.51",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "313.24",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "325",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "small",
                        width: "26",
                        height: "10.54",
                        left: "334.07",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "file",
                        width: "15.99",
                        height: "10.54",
                        left: "363.82",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "placed",
                        width: "31.31",
                        height: "10.54",
                        left: "383.56",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "418.62",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "434.37",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "hard",
                        width: "21.33",
                        height: "10.54",
                        left: "452.78",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "drive",
                        width: "24.65",
                        height: "10.54",
                        left: "477.11",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "504.76",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "517.76",
                        top: "415.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "computer.",
                        width: "47.65",
                        height: "10.54",
                        left: "108",
                        top: "401.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "159.4",
                        top: "401.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "182.62",
                        top: "401.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "refuse",
                        width: "29.31",
                        height: "10.54",
                        left: "207.03",
                        top: "401.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "240.09",
                        top: "401.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accept",
                        width: "30.64",
                        height: "10.54",
                        left: "252.43",
                        top: "401.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "browser",
                        width: "38.65",
                        height: "10.54",
                        left: "286.07",
                        top: "401.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cookies",
                        width: "36.66",
                        height: "10.54",
                        left: "327.72",
                        top: "401.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "367.38",
                        top: "401.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activating",
                        width: "47.31",
                        height: "10.54",
                        left: "382.38",
                        top: "401.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "432.69",
                        top: "401.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "appropriate",
                        width: "54.64",
                        height: "10.54",
                        left: "450.35",
                        top: "401.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "setting",
                        width: "32",
                        height: "10.54",
                        left: "507.99",
                        top: "401.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "108",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "123.75",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "browser.",
                        width: "40.99",
                        height: "10.54",
                        left: "149.5",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "However,",
                        width: "46.5",
                        height: "10.54",
                        left: "194.24",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "244.49",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "255.57",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "select",
                        width: "27.31",
                        height: "10.54",
                        left: "277.32",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "308.38",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "setting",
                        width: "32",
                        height: "10.54",
                        left: "329.47",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "365.22",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "386.97",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "411.38",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unable",
                        width: "31.98",
                        height: "10.54",
                        left: "425.71",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "460.69",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "473.03",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "506.67",
                        top: "387.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "parts",
                        width: "23.33",
                        height: "10.54",
                        left: "108",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "135.08",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "148.82",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "168.57",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Unless",
                        width: "32.67",
                        height: "10.54",
                        left: "213.67",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "250.09",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "271.84",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "adjusted",
                        width: "39.99",
                        height: "10.54",
                        left: "298.24",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "341.98",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "browser",
                        width: "38.65",
                        height: "10.54",
                        left: "367.73",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "setting",
                        width: "32",
                        height: "10.54",
                        left: "409.38",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "so",
                        width: "10.67",
                        height: "10.54",
                        left: "444.38",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "458.05",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "6.67",
                        height: "10.54",
                        left: "479.04",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "488.71",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "refuse",
                        width: "29.32",
                        height: "10.54",
                        left: "510.38",
                        top: "373.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "cookies,",
                        width: "39.66",
                        height: "10.54",
                        left: "108",
                        top: "360.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "150.66",
                        top: "360.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "system",
                        width: "33.34",
                        height: "10.54",
                        left: "169.65",
                        top: "360.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "205.99",
                        top: "360.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "issue",
                        width: "24",
                        height: "10.54",
                        left: "227.65",
                        top: "360.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cookies",
                        width: "36.66",
                        height: "10.54",
                        left: "254.65",
                        top: "360.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "294.31",
                        top: "360.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "323.3",
                        top: "360.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "direct",
                        width: "27.32",
                        height: "10.54",
                        left: "344.3",
                        top: "360.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "374.62",
                        top: "360.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "browser",
                        width: "38.65",
                        height: "10.54",
                        left: "399.62",
                        top: "360.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "441.27",
                        top: "360.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "453.6",
                        top: "360.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "472.6",
                        top: "360.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "346.69",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Flash",
                        width: "28.01",
                        height: "10.55",
                        left: "108",
                        top: "346.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Cookies",
                        width: "40.67",
                        height: "10.55",
                        left: "141.26",
                        top: "346.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.55",
                        left: "181.93",
                        top: "346.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Certain",
                        width: "35.32",
                        height: "10.55",
                        left: "189.43",
                        top: "346.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "features",
                        width: "37.97",
                        height: "10.55",
                        left: "229.25",
                        top: "346.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "271.72",
                        top: "346.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.55",
                        left: "286.22",
                        top: "346.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.55",
                        left: "306.71",
                        top: "346.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "349.57",
                        top: "346.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.55",
                        left: "374.73",
                        top: "346.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "local",
                        width: "23.32",
                        height: "10.55",
                        left: "395.23",
                        top: "346.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "stored",
                        width: "29.32",
                        height: "10.55",
                        left: "423.05",
                        top: "346.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "objects",
                        width: "33.99",
                        height: "10.55",
                        left: "456.87",
                        top: "346.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(or",
                        width: "14",
                        height: "10.55",
                        left: "495.36",
                        top: "346.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Flash",
                        width: "26",
                        height: "10.55",
                        left: "513.86",
                        top: "346.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "cookies)",
                        width: "40.65",
                        height: "10.54",
                        left: "108",
                        top: "332.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "152.4",
                        top: "332.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "165.49",
                        top: "332.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "201.22",
                        top: "332.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "store",
                        width: "23.33",
                        height: "10.54",
                        left: "222.29",
                        top: "332.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "249.37",
                        top: "332.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "309.77",
                        top: "332.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "340.18",
                        top: "332.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "preferences",
                        width: "55.29",
                        height: "10.54",
                        left: "365.93",
                        top: "332.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "424.22",
                        top: "332.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "navigation",
                        width: "50.66",
                        height: "10.54",
                        left: "444.54",
                        top: "332.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to,",
                        width: "12.33",
                        height: "10.54",
                        left: "498.2",
                        top: "332.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from,",
                        width: "26.33",
                        height: "10.54",
                        left: "513.53",
                        top: "332.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "108",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "129.83",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "146.33",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "166.07",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Flash",
                        width: "26",
                        height: "10.54",
                        left: "211.18",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cookies",
                        width: "36.66",
                        height: "10.54",
                        left: "240.93",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "281.34",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "299.74",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "managed",
                        width: "43.31",
                        height: "10.54",
                        left: "318.82",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "365.88",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "381.63",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "same",
                        width: "24.66",
                        height: "10.54",
                        left: "400.04",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "browser",
                        width: "38.65",
                        height: "10.54",
                        left: "428.45",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "settings",
                        width: "36.67",
                        height: "10.54",
                        left: "470.85",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "511.27",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "525.02",
                        top: "318.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "used",
                        width: "22",
                        height: "10.54",
                        left: "108",
                        top: "304.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "135.25",
                        top: "304.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "browser",
                        width: "38.65",
                        height: "10.54",
                        left: "154.49",
                        top: "304.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cookies.",
                        width: "39.66",
                        height: "10.54",
                        left: "198.39",
                        top: "304.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.54",
                        left: "243.3",
                        top: "304.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "265.22",
                        top: "304.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "327.12",
                        top: "304.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "managing",
                        width: "47.32",
                        height: "10.54",
                        left: "359.03",
                        top: "304.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "411.6",
                        top: "304.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "privacy",
                        width: "35.98",
                        height: "10.54",
                        left: "438.85",
                        top: "304.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "480.08",
                        top: "304.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "security",
                        width: "37.98",
                        height: "10.54",
                        left: "501.91",
                        top: "304.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "settings",
                        width: "36.67",
                        height: "10.54",
                        left: "108",
                        top: "291.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "152.92",
                        top: "291.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Flash",
                        width: "26",
                        height: "10.54",
                        left: "175.16",
                        top: "291.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cookies,",
                        width: "39.66",
                        height: "10.54",
                        left: "209.41",
                        top: "291.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "see",
                        width: "15.32",
                        height: "10.54",
                        left: "257.32",
                        top: "291.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Choices",
                        width: "38.66",
                        height: "10.54",
                        left: "275.64",
                        top: "291.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "About",
                        width: "30",
                        height: "10.54",
                        left: "322.55",
                        top: "291.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "How",
                        width: "23.33",
                        height: "10.54",
                        left: "360.8",
                        top: "291.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.7",
                        height: "10.54",
                        left: "392.38",
                        top: "291.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "416.33",
                        top: "291.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "442.49",
                        top: "291.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Disclose",
                        width: "41.33",
                        height: "10.54",
                        left: "467.31",
                        top: "291.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.54",
                        left: "516.14",
                        top: "291.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Information,",
                        width: "60.32",
                        height: "10.54",
                        left: "108",
                        top: "277.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below.",
                        width: "31.54",
                        height: "10.54",
                        left: "171.32",
                        top: "277.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "263.9",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Web",
                        width: "23.34",
                        height: "10.54",
                        left: "108",
                        top: "263.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Beacons",
                        width: "42",
                        height: "10.54",
                        left: "135.84",
                        top: "263.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "177.84",
                        top: "263.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Pages",
                        width: "27.99",
                        height: "10.54",
                        left: "185.34",
                        top: "263.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "217.83",
                        top: "263.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "232.33",
                        top: "263.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "252.83",
                        top: "263.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "295.68",
                        top: "263.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "317.51",
                        top: "263.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "emails",
                        width: "31.33",
                        height: "10.54",
                        left: "338",
                        top: "263.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "373.83",
                        top: "263.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contain",
                        width: "35.32",
                        height: "10.54",
                        left: "398.99",
                        top: "263.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "small",
                        width: "26",
                        height: "10.54",
                        left: "438.06",
                        top: "263.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "electronic",
                        width: "47.3",
                        height: "10.54",
                        left: "467.81",
                        top: "263.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "files",
                        width: "20.66",
                        height: "10.54",
                        left: "518.86",
                        top: "263.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "known",
                        width: "32.67",
                        height: "10.54",
                        left: "108",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "143.67",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "web",
                        width: "19.99",
                        height: "10.54",
                        left: "156.66",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "beacons",
                        width: "38.65",
                        height: "10.54",
                        left: "179.65",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(also",
                        width: "23.33",
                        height: "10.54",
                        left: "221.3",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "referred",
                        width: "37.96",
                        height: "10.54",
                        left: "247.63",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "288.59",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.54",
                        left: "300.93",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "clear",
                        width: "23.31",
                        height: "10.54",
                        left: "313.92",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "gifs,",
                        width: "21",
                        height: "10.54",
                        left: "340.23",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pixel",
                        width: "23.99",
                        height: "10.54",
                        left: "364.23",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "tags,",
                        width: "22.33",
                        height: "10.54",
                        left: "391.22",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "416.55",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "single­pixel",
                        width: "56.66",
                        height: "10.54",
                        left: "436.88",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "gifs)",
                        width: "21.99",
                        height: "10.54",
                        left: "496.54",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "521.53",
                        top: "249.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "permit",
                        width: "31.32",
                        height: "10.55",
                        left: "108",
                        top: "235.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "143.07",
                        top: "235.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Company,",
                        width: "48.89",
                        height: "10.55",
                        left: "161.48",
                        top: "235.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.55",
                        left: "214.12",
                        top: "235.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "example,",
                        width: "43.65",
                        height: "10.55",
                        left: "231.86",
                        top: "235.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "279.26",
                        top: "235.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "count",
                        width: "26.66",
                        height: "10.55",
                        left: "291.59",
                        top: "235.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.66",
                        height: "10.55",
                        left: "321.25",
                        top: "235.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.67",
                        height: "10.55",
                        left: "348.91",
                        top: "235.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.55",
                        left: "372.58",
                        top: "235.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "visited",
                        width: "32",
                        height: "10.55",
                        left: "398.23",
                        top: "235.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "those",
                        width: "25.33",
                        height: "10.55",
                        left: "433.23",
                        top: "235.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pages",
                        width: "27.32",
                        height: "10.55",
                        left: "461.56",
                        top: "235.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "491.88",
                        top: "235.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "opened",
                        width: "34.65",
                        height: "10.55",
                        left: "504.88",
                        top: "235.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "108",
                        top: "222.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.65",
                        height: "10.54",
                        left: "123.08",
                        top: "222.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "153.48",
                        top: "222.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "174.56",
                        top: "222.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "192.3",
                        top: "222.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "related",
                        width: "32.64",
                        height: "10.54",
                        left: "220.71",
                        top: "222.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "website",
                        width: "36.65",
                        height: "10.54",
                        left: "257.1",
                        top: "222.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "statistics",
                        width: "41.34",
                        height: "10.54",
                        left: "297.5",
                        top: "222.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(for",
                        width: "17.98",
                        height: "10.54",
                        left: "342.59",
                        top: "222.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "example,",
                        width: "43.65",
                        height: "10.54",
                        left: "364.32",
                        top: "222.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "recording",
                        width: "45.98",
                        height: "10.54",
                        left: "410.97",
                        top: "222.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "459.95",
                        top: "222.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "popularity",
                        width: "49.32",
                        height: "10.54",
                        left: "477.61",
                        top: "222.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "529.93",
                        top: "222.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "108",
                        top: "208.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "website",
                        width: "36.66",
                        height: "10.54",
                        left: "143.64",
                        top: "208.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "183.3",
                        top: "208.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "221.62",
                        top: "208.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "verifying",
                        width: "43.98",
                        height: "10.54",
                        left: "241.95",
                        top: "208.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "system",
                        width: "33.34",
                        height: "10.54",
                        left: "288.93",
                        top: "208.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "325.27",
                        top: "208.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "server",
                        width: "29.32",
                        height: "10.54",
                        left: "345.59",
                        top: "208.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "integrity).",
                        width: "47.65",
                        height: "10.54",
                        left: "377.91",
                        top: "208.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Third­Party",
                        width: "74.63",
                        height: "14.05",
                        left: "72",
                        top: "175.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "76.4",
                        height: "14.05",
                        left: "150.63",
                        top: "175.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Collection",
                        width: "66.64",
                        height: "14.05",
                        left: "231.03",
                        top: "175.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Some",
                        width: "27.33",
                        height: "10.54",
                        left: "72",
                        top: "148.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "105.33",
                        top: "148.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "146.65",
                        top: "148.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applications,",
                        width: "60.98",
                        height: "10.54",
                        left: "162.65",
                        top: "148.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "229.63",
                        top: "148.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertisements,",
                        width: "74.98",
                        height: "10.54",
                        left: "280.96",
                        top: "148.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "361.94",
                        top: "148.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "379.94",
                        top: "148.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "399.85",
                        top: "148.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.64",
                        height: "10.54",
                        left: "443.46",
                        top: "148.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "served",
                        width: "31.32",
                        height: "10.54",
                        left: "463.35",
                        top: "148.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "499.92",
                        top: "148.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "517.17",
                        top: "148.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "parties,",
                        width: "34.99",
                        height: "10.54",
                        left: "72",
                        top: "134.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.32",
                        height: "10.54",
                        left: "114.49",
                        top: "134.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertisers,",
                        width: "54.98",
                        height: "10.54",
                        left: "166.56",
                        top: "134.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ad",
                        width: "11.33",
                        height: "10.54",
                        left: "228.29",
                        top: "134.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "networks",
                        width: "43.99",
                        height: "10.54",
                        left: "246.37",
                        top: "134.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "297.11",
                        top: "134.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "servers,",
                        width: "36.98",
                        height: "10.54",
                        left: "321.19",
                        top: "134.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "35.32",
                        height: "10.54",
                        left: "364.92",
                        top: "134.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providers,",
                        width: "48.32",
                        height: "10.54",
                        left: "406.99",
                        top: "134.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "462.06",
                        top: "134.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "application",
                        width: "53.32",
                        height: "10.54",
                        left: "486.14",
                        top: "134.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "providers,",
                        width: "48.32",
                        height: "10.54",
                        left: "72",
                        top: "120.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.67",
                        height: "10.54",
                        left: "124.07",
                        top: "120.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "148.49",
                        top: "120.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "tracking",
                        width: "39.32",
                        height: "10.54",
                        left: "168.23",
                        top: "120.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "technologies",
                        width: "60.65",
                        height: "10.54",
                        left: "211.3",
                        top: "120.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "275.7",
                        top: "120.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "288.79",
                        top: "120.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "324.52",
                        top: "120.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "384.92",
                        top: "120.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "415.33",
                        top: "120.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "437.08",
                        top: "120.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "466.82",
                        top: "120.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.65",
                        height: "10.54",
                        left: "488.57",
                        top: "120.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "522.22",
                        top: "120.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "72",
                        top: "106.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "92.5",
                        top: "106.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "112.99",
                        top: "106.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "155.85",
                        top: "106.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "visit",
                        width: "20.68",
                        height: "10.54",
                        left: "170.34",
                        top: "106.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third­party",
                        width: "51.31",
                        height: "10.54",
                        left: "195.52",
                        top: "106.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sites.",
                        width: "24.34",
                        height: "10.54",
                        left: "251.33",
                        top: "106.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "The",
                        width: "18.65",
                        height: "10.54",
                        left: "280.17",
                        top: "106.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "303.32",
                        top: "106.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "they",
                        width: "20.66",
                        height: "10.54",
                        left: "364.48",
                        top: "106.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "389.64",
                        top: "106.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "426.12",
                        top: "106.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "450.53",
                        top: "106.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "associated",
                        width: "49.32",
                        height: "10.54",
                        left: "465.6",
                        top: "106.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "518.67",
                        top: "106.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "72",
                        top: "92.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "97",
                        top: "92.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "140.65",
                        top: "92.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "200.3",
                        top: "92.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "they",
                        width: "20.66",
                        height: "10.54",
                        left: "213.3",
                        top: "92.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "236.96",
                        top: "92.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "260.62",
                        top: "92.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "59.65",
                        height: "10.54",
                        left: "295.6",
                        top: "92.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "358.25",
                        top: "92.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "406.58",
                        top: "92.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "59.66",
                        height: "10.54",
                        left: "450.23",
                        top: "92.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "512.89",
                        top: "92.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "72",
                        top: "79.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "online",
                        width: "29.99",
                        height: "10.54",
                        left: "99.25",
                        top: "79.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activities",
                        width: "43.32",
                        height: "10.54",
                        left: "134.49",
                        top: "79.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "over",
                        width: "21.32",
                        height: "10.54",
                        left: "183.06",
                        top: "79.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "21.33",
                        height: "10.54",
                        left: "209.63",
                        top: "79.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "235.46",
                        top: "79.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "across",
                        width: "29.98",
                        height: "10.54",
                        left: "257.29",
                        top: "79.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "different",
                        width: "41.1",
                        height: "10.54",
                        left: "291.77",
                        top: "79.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "websites",
                        width: "41.32",
                        height: "10.54",
                        left: "337.37",
                        top: "79.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "383.19",
                        top: "79.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "405.02",
                        top: "79.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "online",
                        width: "30",
                        height: "10.54",
                        left: "434.17",
                        top: "79.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services.",
                        width: "41.65",
                        height: "10.54",
                        left: "468.67",
                        top: "79.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "They",
                        width: "24.65",
                        height: "10.54",
                        left: "514.82",
                        top: "79.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,276,292.5", "lineto,540,292.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,108,278.5", "lineto,165,278.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,177.5", "lineto,298,177.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "5",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "97.91",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.33",
                        height: "10.54",
                        left: "119.16",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "141.74",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "203.65",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "218.23",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "260.14",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "282.64",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interest­based",
                        width: "66.64",
                        height: "10.54",
                        left: "308.47",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(behavioral)",
                        width: "58.64",
                        height: "10.54",
                        left: "379.61",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertising",
                        width: "53.32",
                        height: "10.54",
                        left: "442.75",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "500.57",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "515.06",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "targeted",
                        width: "38.43",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content.",
                        width: "38.32",
                        height: "10.54",
                        left: "113.43",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "93.69",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "111.69",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "control",
                        width: "33.99",
                        height: "10.54",
                        left: "133.03",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.65",
                        height: "10.54",
                        left: "173.02",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "203.67",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties'",
                        width: "34.14",
                        height: "10.54",
                        left: "232.34",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "tracking",
                        width: "39.32",
                        height: "10.54",
                        left: "272.48",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "technologies",
                        width: "60.65",
                        height: "10.54",
                        left: "317.05",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "382.95",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "how",
                        width: "20.66",
                        height: "10.54",
                        left: "398.2",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "they",
                        width: "20.66",
                        height: "10.54",
                        left: "424.11",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "450.02",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "475.93",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "used.",
                        width: "25",
                        height: "10.54",
                        left: "492.51",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.54",
                        left: "522.76",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.55",
                        left: "72",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.55",
                        left: "133.15",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "how",
                        width: "20.67",
                        height: "10.55",
                        left: "164.31",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "189.48",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "can",
                        width: "16.65",
                        height: "10.55",
                        left: "211.98",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "opt",
                        width: "15.34",
                        height: "10.55",
                        left: "233.13",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "out",
                        width: "15.33",
                        height: "10.55",
                        left: "252.97",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "272.8",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "receiving",
                        width: "44.64",
                        height: "10.55",
                        left: "286.55",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "targeted",
                        width: "38.43",
                        height: "10.55",
                        left: "334.94",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertising",
                        width: "53.32",
                        height: "10.55",
                        left: "377.12",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.32",
                        height: "10.55",
                        left: "434.19",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "many",
                        width: "26.66",
                        height: "10.55",
                        left: "461.26",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providers,",
                        width: "48.32",
                        height: "10.55",
                        left: "491.67",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "see",
                        width: "15.32",
                        height: "10.54",
                        left: "72",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Choices",
                        width: "38.66",
                        height: "10.54",
                        left: "90.32",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "About",
                        width: "30",
                        height: "10.54",
                        left: "135.73",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "How",
                        width: "23.33",
                        height: "10.54",
                        left: "172.48",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.7",
                        height: "10.54",
                        left: "202.56",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "225.01",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "250.42",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Disclose",
                        width: "41.33",
                        height: "10.54",
                        left: "274.49",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.54",
                        left: "322.57",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information,",
                        width: "60.32",
                        height: "10.54",
                        left: "352.03",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below.",
                        width: "31.54",
                        height: "10.54",
                        left: "418.35",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "8",
                        height: "10.54",
                        left: "455.89",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "469.89",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "493.89",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "522.54",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "questions",
                        width: "45.33",
                        height: "10.54",
                        left: "72",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "121.83",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "152.99",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "162.82",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "party’s",
                        width: "32.66",
                        height: "10.54",
                        left: "189.98",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "practices,",
                        width: "45.64",
                        height: "10.54",
                        left: "227.14",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "277.28",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "should",
                        width: "32.01",
                        height: "10.54",
                        left: "299.03",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "read",
                        width: "20.65",
                        height: "10.54",
                        left: "334.79",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "359.19",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "377.6",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "party’s",
                        width: "32.66",
                        height: "10.54",
                        left: "404.01",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "privacy",
                        width: "35.98",
                        height: "10.54",
                        left: "440.42",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "statement",
                        width: "45.99",
                        height: "10.54",
                        left: "480.15",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "529.89",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "contact",
                        width: "34.65",
                        height: "10.54",
                        left: "72",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "109.65",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "127.31",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "party",
                        width: "24.66",
                        height: "10.54",
                        left: "152.97",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "directly.",
                        width: "38.87",
                        height: "10.54",
                        left: "180.63",
                        top: "609.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "How",
                        width: "31.1",
                        height: "14.05",
                        left: "72",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "20.92",
                        height: "14.05",
                        left: "107.1",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "24.87",
                        height: "14.05",
                        left: "132.02",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "31.28",
                        height: "14.05",
                        left: "160.89",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "76.41",
                        height: "14.05",
                        left: "196.16",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "92.19",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "112.69",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "173.09",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "194.84",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "212.58",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "248.31",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "278.72",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "300.47",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "314.21",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "335.96",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "357.71",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "398.12",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us,",
                        width: "13.67",
                        height: "10.54",
                        left: "411.2",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "428.62",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "477.7",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.66",
                        height: "10.54",
                        left: "498.77",
                        top: "549.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information:",
                        width: "59.99",
                        height: "10.54",
                        left: "72",
                        top: "535.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "508.09",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "present",
                        width: "34.65",
                        height: "10.54",
                        left: "125.74",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "165.64",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "186.89",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "230.5",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "11.34",
                        height: "10.54",
                        left: "253.07",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contents",
                        width: "39.99",
                        height: "10.54",
                        left: "269.66",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "314.9",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "329.48",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "352.73",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "375.31",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enable",
                        width: "31.32",
                        height: "10.54",
                        left: "389.14",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "424.96",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "447.46",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "461.29",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "481.79",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.65",
                        height: "10.54",
                        left: "500.95",
                        top: "507.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "available",
                        width: "43.31",
                        height: "10.55",
                        left: "108",
                        top: "493.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.55",
                        left: "156.56",
                        top: "493.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "198.39",
                        top: "493.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.35",
                        height: "10.55",
                        left: "217.55",
                        top: "493.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.55",
                        left: "263.4",
                        top: "493.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "registering",
                        width: "51.32",
                        height: "10.55",
                        left: "313.23",
                        top: "493.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "369.05",
                        top: "493.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.55",
                        left: "391.55",
                        top: "493.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.55",
                        left: "410.04",
                        top: "493.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.64",
                        height: "10.55",
                        left: "430.54",
                        top: "493.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "473.68",
                        top: "493.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "verifying",
                        width: "43.99",
                        height: "10.55",
                        left: "495.51",
                        top: "493.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "108",
                        top: "480.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identity",
                        width: "36.66",
                        height: "10.54",
                        left: "133",
                        top: "480.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "172.66",
                        top: "480.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "authority",
                        width: "43.33",
                        height: "10.54",
                        left: "192.98",
                        top: "480.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "239.31",
                        top: "480.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "251.64",
                        top: "480.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "270.64",
                        top: "480.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services.",
                        width: "41.65",
                        height: "10.54",
                        left: "289.63",
                        top: "480.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "466.69",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "466.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "123.49",
                        top: "466.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "163.15",
                        top: "466.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "184.15",
                        top: "466.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "59.66",
                        height: "10.54",
                        left: "208.48",
                        top: "466.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "products,",
                        width: "44.32",
                        height: "10.54",
                        left: "271.14",
                        top: "466.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "318.46",
                        top: "466.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.65",
                        height: "10.54",
                        left: "331.46",
                        top: "466.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "373.11",
                        top: "466.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "394.1",
                        top: "466.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "request",
                        width: "34.65",
                        height: "10.54",
                        left: "415.1",
                        top: "466.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "452.75",
                        top: "466.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us.",
                        width: "13.67",
                        height: "10.54",
                        left: "479.08",
                        top: "466.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "452.89",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.54",
                        left: "108",
                        top: "452.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "internal",
                        width: "36.65",
                        height: "10.54",
                        left: "127.67",
                        top: "452.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "record­keeping",
                        width: "72.63",
                        height: "10.54",
                        left: "167.32",
                        top: "452.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes.",
                        width: "45.66",
                        height: "10.54",
                        left: "242.95",
                        top: "452.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "439.1",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.54",
                        left: "108",
                        top: "438.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "customer",
                        width: "43.99",
                        height: "10.54",
                        left: "127.67",
                        top: "438.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "support",
                        width: "36",
                        height: "10.54",
                        left: "174.66",
                        top: "438.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "213.66",
                        top: "438.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "233.98",
                        top: "438.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respond",
                        width: "37.99",
                        height: "10.54",
                        left: "246.32",
                        top: "438.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "287.31",
                        top: "438.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "299.64",
                        top: "438.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inquiries.",
                        width: "44.99",
                        height: "10.54",
                        left: "324.64",
                        top: "438.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "425.3",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "424.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "manage",
                        width: "37.31",
                        height: "10.54",
                        left: "127.99",
                        top: "424.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "172.8",
                        top: "424.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "197.63",
                        top: "424.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notices",
                        width: "33.99",
                        height: "10.54",
                        left: "241.79",
                        top: "424.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "282.53",
                        top: "424.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "315.94",
                        top: "424.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.32",
                        height: "10.54",
                        left: "344.68",
                        top: "424.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and/or",
                        width: "30.65",
                        height: "10.54",
                        left: "388.75",
                        top: "424.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "subscription,",
                        width: "61.67",
                        height: "10.54",
                        left: "426.15",
                        top: "424.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.32",
                        height: "10.54",
                        left: "494.57",
                        top: "424.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "expiration",
                        width: "48.65",
                        height: "10.54",
                        left: "108",
                        top: "411.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "159.65",
                        top: "411.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "renewal",
                        width: "37.97",
                        height: "10.54",
                        left: "179.98",
                        top: "411.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notices.",
                        width: "36.99",
                        height: "10.54",
                        left: "220.95",
                        top: "411.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "397.7",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "397.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fulfill",
                        width: "27.33",
                        height: "10.54",
                        left: "123.49",
                        top: "397.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "153.82",
                        top: "397.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "174.15",
                        top: "397.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purpose",
                        width: "37.99",
                        height: "10.54",
                        left: "201.8",
                        top: "397.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.54",
                        left: "242.79",
                        top: "397.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "29.32",
                        height: "10.54",
                        left: "259.79",
                        top: "397.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "292.11",
                        top: "397.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "313.11",
                        top: "397.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it.",
                        width: "9.67",
                        height: "10.54",
                        left: "352.77",
                        top: "397.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "383.9",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notify",
                        width: "28.67",
                        height: "10.54",
                        left: "125.74",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "159.66",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "182.91",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "changes",
                        width: "38.64",
                        height: "10.54",
                        left: "214.82",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "258.71",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "273.3",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "294.54",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "338.15",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "353.4",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "products",
                        width: "41.33",
                        height: "10.54",
                        left: "375.97",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "422.55",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.65",
                        height: "10.54",
                        left: "437.79",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "481.69",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offer",
                        width: "23.09",
                        height: "10.54",
                        left: "500.94",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.28",
                        top: "383.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "108",
                        top: "369.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "147.66",
                        top: "369.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it.",
                        width: "9.66",
                        height: "10.54",
                        left: "187.99",
                        top: "369.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "356.3",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "355.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "track",
                        width: "23.98",
                        height: "10.54",
                        left: "123.49",
                        top: "355.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees",
                        width: "19.32",
                        height: "10.54",
                        left: "150.47",
                        top: "355.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "172.79",
                        top: "355.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "process",
                        width: "35.99",
                        height: "10.54",
                        left: "193.12",
                        top: "355.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "billing",
                        width: "31.33",
                        height: "10.54",
                        left: "232.11",
                        top: "355.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "266.44",
                        top: "355.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "286.77",
                        top: "355.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "331.09",
                        top: "355.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sharing",
                        width: "35.32",
                        height: "10.54",
                        left: "379.42",
                        top: "355.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "417.74",
                        top: "355.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third­party",
                        width: "51.31",
                        height: "10.54",
                        left: "442.08",
                        top: "355.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "496.39",
                        top: "355.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "gateways",
                        width: "44.65",
                        height: "10.54",
                        left: "108",
                        top: "342.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "155.65",
                        top: "342.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.32",
                        height: "10.54",
                        left: "175.97",
                        top: "342.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "33.98",
                        height: "10.54",
                        left: "220.29",
                        top: "342.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providers",
                        width: "45.33",
                        height: "10.54",
                        left: "257.27",
                        top: "342.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "305.6",
                        top: "342.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "connection",
                        width: "52.65",
                        height: "10.54",
                        left: "317.93",
                        top: "342.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "373.58",
                        top: "342.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "397.91",
                        top: "342.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "415.57",
                        top: "342.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "328.71",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "328.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "improve",
                        width: "39.99",
                        height: "10.54",
                        left: "123.49",
                        top: "328.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "166.48",
                        top: "328.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "maintain",
                        width: "41.99",
                        height: "10.54",
                        left: "186.81",
                        top: "328.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "231.8",
                        top: "328.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "249.46",
                        top: "328.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "290.81",
                        top: "328.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "311.14",
                        top: "328.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "product",
                        width: "36.66",
                        height: "10.54",
                        left: "328.13",
                        top: "328.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "development",
                        width: "61.98",
                        height: "10.54",
                        left: "367.79",
                        top: "328.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "314.91",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "314.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "resolve",
                        width: "34.65",
                        height: "10.54",
                        left: "124.99",
                        top: "314.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disputes,",
                        width: "42.34",
                        height: "10.54",
                        left: "164.14",
                        top: "314.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "210.98",
                        top: "314.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "protect",
                        width: "33.32",
                        height: "10.54",
                        left: "224.81",
                        top: "314.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ourselves",
                        width: "45.32",
                        height: "10.54",
                        left: "262.63",
                        top: "314.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "312.45",
                        top: "314.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "334.28",
                        top: "314.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.67",
                        height: "10.54",
                        left: "363.43",
                        top: "314.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "392.6",
                        top: "314.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "406.34",
                        top: "314.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "424.75",
                        top: "314.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "469.86",
                        top: "314.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "490.93",
                        top: "314.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enforce",
                        width: "35.97",
                        height: "10.54",
                        left: "504.02",
                        top: "314.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "108",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "legal",
                        width: "23.32",
                        height: "10.54",
                        left: "128.33",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "154.65",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "184.31",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "govern",
                        width: "33.32",
                        height: "10.54",
                        left: "205.3",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "241.62",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "266.62",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "285.62",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "298.61",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "316.27",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "287.31",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.54",
                        left: "108",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "127.67",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "research",
                        width: "39.96",
                        height: "10.54",
                        left: "155.33",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "198.29",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "analytical",
                        width: "46.64",
                        height: "10.54",
                        left: "218.62",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes.",
                        width: "45.66",
                        height: "10.54",
                        left: "268.26",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "273.51",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "review,",
                        width: "34.87",
                        height: "10.54",
                        left: "123.49",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "scan,",
                        width: "24.32",
                        height: "10.54",
                        left: "161.36",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "188.68",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "analyze",
                        width: "36.64",
                        height: "10.54",
                        left: "201.68",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "241.32",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "communications",
                        width: "79.32",
                        height: "10.54",
                        left: "266.31",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "348.63",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "372.97",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Users",
                        width: "27.33",
                        height: "10.54",
                        left: "400.62",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "exchanged",
                        width: "51.31",
                        height: "10.54",
                        left: "430.95",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "via",
                        width: "14.66",
                        height: "10.54",
                        left: "485.26",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "502.92",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.55",
                        left: "108",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.55",
                        left: "149.36",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.55",
                        left: "162.35",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.55",
                        left: "175.35",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "described",
                        width: "45.98",
                        height: "10.55",
                        left: "225",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.55",
                        left: "273.98",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.55",
                        left: "286.31",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.66",
                        height: "10.55",
                        left: "306.65",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "30.66",
                        height: "10.55",
                        left: "346.31",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.55",
                        left: "379.97",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fraud",
                        width: "25.32",
                        height: "10.55",
                        left: "396.97",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prevention,",
                        width: "54.31",
                        height: "10.55",
                        left: "425.29",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "risk",
                        width: "18",
                        height: "10.55",
                        left: "482.6",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "assessment,",
                        width: "56.33",
                        height: "10.54",
                        left: "108",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "regulatory",
                        width: "49.31",
                        height: "10.54",
                        left: "167.33",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "compliance,",
                        width: "58.31",
                        height: "10.54",
                        left: "219.64",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "investigation,",
                        width: "64.99",
                        height: "10.54",
                        left: "280.95",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "product",
                        width: "36.65",
                        height: "10.54",
                        left: "348.94",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "development,",
                        width: "64.98",
                        height: "10.54",
                        left: "388.59",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "research,",
                        width: "42.97",
                        height: "10.54",
                        left: "456.57",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "502.54",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "user",
                        width: "19.99",
                        height: "10.54",
                        left: "108",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "support",
                        width: "36",
                        height: "10.54",
                        left: "130.99",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes.",
                        width: "45.66",
                        height: "10.54",
                        left: "169.99",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "218.31",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "allow",
                        width: "26.66",
                        height: "10.54",
                        left: "123.49",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "153.15",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "174.15",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "participate",
                        width: "50.63",
                        height: "10.54",
                        left: "186.49",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "240.12",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interactive",
                        width: "50.63",
                        height: "10.54",
                        left: "252.46",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "features",
                        width: "37.98",
                        height: "10.54",
                        left: "306.09",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "347.07",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "362.07",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "381.06",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "204.52",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "In",
                        width: "10",
                        height: "10.54",
                        left: "108",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "121",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "141.32",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "way",
                        width: "19.99",
                        height: "10.54",
                        left: "168.98",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "191.97",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "208.96",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "describe",
                        width: "39.98",
                        height: "10.54",
                        left: "232.62",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "275.6",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "304.59",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "325.59",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "365.25",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information.",
                        width: "59.65",
                        height: "10.54",
                        left: "382.91",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "190.72",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.54",
                        left: "108",
                        top: "190.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "127.67",
                        top: "190.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "148",
                        top: "190.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purpose",
                        width: "37.99",
                        height: "10.54",
                        left: "175.65",
                        top: "190.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "216.64",
                        top: "190.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "240.98",
                        top: "190.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "consent.",
                        width: "39.66",
                        height: "10.54",
                        left: "265.97",
                        top: "190.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "92.19",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "display",
                        width: "34.67",
                        height: "10.54",
                        left: "117.35",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "156.52",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "related",
                        width: "32.64",
                        height: "10.54",
                        left: "217.67",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "254.81",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "268.65",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "business,",
                        width: "43.67",
                        height: "10.54",
                        left: "295.14",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "343.31",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "393.14",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "name",
                        width: "25.98",
                        height: "10.54",
                        left: "419.64",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "450.12",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "464.62",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "business’s",
                        width: "48.67",
                        height: "10.54",
                        left: "491.12",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "name,",
                        width: "28.99",
                        height: "10.54",
                        left: "72",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "publicly",
                        width: "39.32",
                        height: "10.54",
                        left: "106.99",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "152.31",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "170.31",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "profile,",
                        width: "34.99",
                        height: "10.54",
                        left: "198.31",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "except",
                        width: "31.31",
                        height: "10.54",
                        left: "239.3",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "276.61",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "291.94",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "extent",
                        width: "29.32",
                        height: "10.54",
                        left: "312.6",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "347.92",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "tell",
                        width: "15.32",
                        height: "10.54",
                        left: "367.92",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "389.24",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "413.24",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "433.24",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "457.15",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "display",
                        width: "34.66",
                        height: "10.54",
                        left: "477.74",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "22",
                        height: "10.54",
                        left: "517.65",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information.",
                        width: "59.65",
                        height: "10.54",
                        left: "72",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "8",
                        height: "10.54",
                        left: "136.15",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "148.65",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "167.81",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "228.96",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "251.46",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "291.87",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "upon",
                        width: "24",
                        height: "10.54",
                        left: "306.29",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "signing",
                        width: "35.33",
                        height: "10.54",
                        left: "334.04",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "up",
                        width: "12",
                        height: "10.54",
                        left: "373.13",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "388.88",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.32",
                        height: "10.54",
                        left: "406.62",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.32",
                        height: "10.54",
                        left: "421.69",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "differs",
                        width: "31.1",
                        height: "10.54",
                        left: "462.76",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.32",
                        height: "10.54",
                        left: "497.61",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.68",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.55",
                        left: "72",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "132.4",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.55",
                        left: "154.15",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.55",
                        left: "194.56",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.55",
                        left: "208.98",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "part",
                        width: "18.65",
                        height: "10.55",
                        left: "222.73",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "245.13",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "258.88",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "verification",
                        width: "55.31",
                        height: "10.55",
                        left: "277.29",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "19.98",
                        height: "10.55",
                        left: "336.35",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.55",
                        left: "360.08",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect,",
                        width: "34.98",
                        height: "10.55",
                        left: "377.07",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.55",
                        left: "415.05",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.55",
                        left: "432.05",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.55",
                        left: "457.7",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion",
                        width: "47.32",
                        height: "10.55",
                        left: "480.03",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.3299999999999",
                        height: "10.55",
                        left: "530.35",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "determining",
                        width: "57.98",
                        height: "10.54",
                        left: "72",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "29.33",
                        height: "10.54",
                        left: "135.23",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "169.81",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "231.71",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "255.63",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "displayed",
                        width: "45.99",
                        height: "10.54",
                        left: "272.21",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "publicly",
                        width: "39.33",
                        height: "10.54",
                        left: "323.45",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "368.03",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "385.28",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "profile.",
                        width: "34.99",
                        height: "10.54",
                        left: "412.52",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "452.76",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "472.95",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "combine",
                        width: "41.32",
                        height: "10.54",
                        left: "498.11",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "72",
                        top: "93.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "132.4",
                        top: "93.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "154.15",
                        top: "93.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "171.89",
                        top: "93.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "207.62",
                        top: "93.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "234.7",
                        top: "93.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "256.45",
                        top: "93.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "297.53",
                        top: "93.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "315.94",
                        top: "93.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "358.04",
                        top: "93.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "383.13",
                        top: "93.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "443.53",
                        top: "93.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "465.28",
                        top: "93.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "obtain",
                        width: "29.99",
                        height: "10.54",
                        left: "483.02",
                        top: "93.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "516.01",
                        top: "93.45",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "affiliated",
                        width: "43.09",
                        height: "10.54",
                        left: "72",
                        top: "79.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "120.34",
                        top: "79.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "non­affiliated",
                        width: "65.08",
                        height: "10.54",
                        left: "142.92",
                        top: "79.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "213.25",
                        top: "79.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties,",
                        width: "34.98",
                        height: "10.54",
                        left: "241.17",
                        top: "79.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "281.4",
                        top: "79.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "303.23",
                        top: "79.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "derived",
                        width: "35.99",
                        height: "10.54",
                        left: "364.38",
                        top: "79.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.32",
                        height: "10.54",
                        left: "404.87",
                        top: "79.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "432.69",
                        top: "79.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "454.52",
                        top: "79.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "products",
                        width: "41.33",
                        height: "10.54",
                        left: "483.67",
                        top: "79.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "529.5",
                        top: "79.65",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,90,638.5", "lineto,409,638.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,578.5", "lineto,273,578.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "6",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "services",
                        width: "38.65",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "113.65",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide.",
                        width: "39.66",
                        height: "10.54",
                        left: "130.64",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "92.19",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "19.33",
                        height: "10.54",
                        left: "117.35",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "141.18",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "161.68",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "188.17",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "249.33",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contact",
                        width: "34.65",
                        height: "10.54",
                        left: "263.16",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "302.31",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "324.81",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "355.97",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "own",
                        width: "20.67",
                        height: "10.54",
                        left: "376.46",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "401.63",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "423.46",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties'",
                        width: "34.15",
                        height: "10.54",
                        left: "450.62",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "goods",
                        width: "28.67",
                        height: "10.54",
                        left: "489.27",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "522.44",
                        top: "678.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "services",
                        width: "38.65",
                        height: "10.54",
                        left: "72",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "114.4",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "136.14",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "160.55",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "175.63",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interest",
                        width: "35.32",
                        height: "10.54",
                        left: "188.63",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "226.95",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you.",
                        width: "21",
                        height: "10.54",
                        left: "239.28",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "263.28",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "274.27",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "295.27",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "310.27",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "want",
                        width: "23.32",
                        height: "10.54",
                        left: "328.61",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "354.93",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "368.6",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "380.94",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "399.93",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "424.93",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "484.58",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.33",
                        height: "10.54",
                        left: "496.92",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "way,",
                        width: "22.22",
                        height: "10.54",
                        left: "517.25",
                        top: "664.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "please",
                        width: "29.98",
                        height: "10.55",
                        left: "72",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "[check",
                        width: "31.98",
                        height: "10.55",
                        left: "109.48",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "148.21",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "relevant",
                        width: "38.64",
                        height: "10.55",
                        left: "169.62",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "box",
                        width: "18",
                        height: "10.55",
                        left: "215.01",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "located",
                        width: "34.65",
                        height: "10.55",
                        left: "239.76",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.55",
                        left: "281.16",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "299.91",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "form",
                        width: "23.32",
                        height: "10.55",
                        left: "321.32",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.55",
                        left: "351.39",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "29.33",
                        height: "10.55",
                        left: "370.14",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.55",
                        left: "406.22",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.55",
                        left: "426.96",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "465.69",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "19.98",
                        height: "10.55",
                        left: "494.44",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(the",
                        width: "18.66",
                        height: "10.55",
                        left: "521.17",
                        top: "650.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "registration",
                        width: "54.65",
                        height: "10.54",
                        left: "72",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "form)/adjust",
                        width: "59.32",
                        height: "10.54",
                        left: "131.15",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "194.97",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "19.99",
                        height: "10.54",
                        left: "221.47",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "preferences",
                        width: "55.29",
                        height: "10.54",
                        left: "245.96",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "305.75",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "319.58",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "346.08",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "profile.]",
                        width: "38.98",
                        height: "10.54",
                        left: "387.89",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.54",
                        left: "431.37",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "more",
                        width: "24.66",
                        height: "10.54",
                        left: "451.79",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "59.65",
                        height: "10.54",
                        left: "480.2",
                        top: "637.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "see",
                        width: "15.32",
                        height: "10.54",
                        left: "72",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Choices",
                        width: "38.66",
                        height: "10.54",
                        left: "90.32",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "About",
                        width: "30",
                        height: "10.54",
                        left: "131.98",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "How",
                        width: "23.33",
                        height: "10.54",
                        left: "164.98",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.7",
                        height: "10.54",
                        left: "191.31",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "210.01",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "231.67",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Disclose",
                        width: "41.33",
                        height: "10.54",
                        left: "251.99",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.54",
                        left: "296.32",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information,",
                        width: "60.32",
                        height: "10.54",
                        left: "322.78",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below.",
                        width: "31.54",
                        height: "10.54",
                        left: "386.1",
                        top: "623.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "In",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "addition,",
                        width: "42.32",
                        height: "10.54",
                        left: "87.25",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "134.82",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "154.73",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "216.64",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "235.13",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "automatically",
                        width: "65.31",
                        height: "10.54",
                        left: "271.61",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "helps",
                        width: "25.33",
                        height: "10.54",
                        left: "341.42",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "371.25",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "386.42",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "improve",
                        width: "39.99",
                        height: "10.54",
                        left: "400.25",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "444.74",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.65",
                        height: "10.54",
                        left: "465.24",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "508.39",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.3399999999999",
                        height: "10.54",
                        left: "530.21",
                        top: "595.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "deliver",
                        width: "33.32",
                        height: "10.54",
                        left: "72",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "108.32",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "better",
                        width: "27.32",
                        height: "10.54",
                        left: "116.64",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "146.96",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "more",
                        width: "24.65",
                        height: "10.54",
                        left: "167.29",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personalized",
                        width: "60.64",
                        height: "10.54",
                        left: "194.94",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service,",
                        width: "36.98",
                        height: "10.54",
                        left: "258.58",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "298.56",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "346.89",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enabling",
                        width: "41.32",
                        height: "10.54",
                        left: "361.89",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "406.21",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to:",
                        width: "12.66",
                        height: "10.54",
                        left: "419.88",
                        top: "581.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "554.29",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Estimate",
                        width: "41.99",
                        height: "10.54",
                        left: "108",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "152.99",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "audience",
                        width: "42.64",
                        height: "10.54",
                        left: "171.98",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "size",
                        width: "18.66",
                        height: "10.54",
                        left: "217.62",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "239.28",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "usage",
                        width: "27.32",
                        height: "10.54",
                        left: "259.61",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "patterns.",
                        width: "40.98",
                        height: "10.54",
                        left: "289.93",
                        top: "553.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "540.49",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Store",
                        width: "25.33",
                        height: "10.54",
                        left: "108",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "141.58",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "206.48",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "241.39",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "preferences,",
                        width: "58.29",
                        height: "10.54",
                        left: "270.89",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "allowing",
                        width: "41.99",
                        height: "10.54",
                        left: "336.68",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "386.17",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "404.34",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "customize",
                        width: "48.65",
                        height: "10.54",
                        left: "421.18",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "477.33",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.65",
                        height: "10.54",
                        left: "500.82",
                        top: "540.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "according",
                        width: "47.31",
                        height: "10.54",
                        left: "108",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "158.31",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "170.64",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "individual",
                        width: "48.66",
                        height: "10.54",
                        left: "195.64",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interests.",
                        width: "42.99",
                        height: "10.54",
                        left: "247.3",
                        top: "526.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "512.89",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Speed",
                        width: "29.33",
                        height: "10.55",
                        left: "108",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "up",
                        width: "12",
                        height: "10.55",
                        left: "140.33",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.55",
                        left: "155.33",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "searches.",
                        width: "43.64",
                        height: "10.55",
                        left: "180.32",
                        top: "512.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "499.09",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Recognize",
                        width: "50.64",
                        height: "10.54",
                        left: "108",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "161.64",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "182.64",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "211.63",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "232.63",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "251.63",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services.",
                        width: "41.65",
                        height: "10.54",
                        left: "270.63",
                        top: "498.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "91.44",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "115.85",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "135.6",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "154.01",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "214.41",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.66",
                        height: "10.54",
                        left: "232.15",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collected",
                        width: "43.3",
                        height: "10.54",
                        left: "258.56",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "305.61",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "332.69",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "354.44",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enable",
                        width: "31.32",
                        height: "10.54",
                        left: "367.52",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "402.59",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "417.01",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "display",
                        width: "34.66",
                        height: "10.54",
                        left: "430.09",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertisements",
                        width: "71.98",
                        height: "10.54",
                        left: "467.75",
                        top: "470.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "72",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "85.83",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertisers'",
                        width: "54.14",
                        height: "10.54",
                        left: "106.33",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "target",
                        width: "27.1",
                        height: "10.54",
                        left: "164.97",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "audiences.",
                        width: "50.31",
                        height: "10.54",
                        left: "196.57",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Even",
                        width: "24.66",
                        height: "10.54",
                        left: "251.38",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "though",
                        width: "33.33",
                        height: "10.54",
                        left: "280.54",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "317.62",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "335.36",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "351.11",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclose",
                        width: "38.66",
                        height: "10.54",
                        left: "370.2",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "412.61",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "438.35",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "482.75",
                        top: "457.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "72",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "93.49",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes",
                        width: "42.66",
                        height: "10.54",
                        left: "125.65",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.67",
                        height: "10.54",
                        left: "175.81",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "219.98",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "consent,",
                        width: "39.66",
                        height: "10.54",
                        left: "249.47",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "296.63",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "311.46",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "click",
                        width: "23.32",
                        height: "10.54",
                        left: "336.96",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "367.78",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "387.28",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "404.03",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interact",
                        width: "35.98",
                        height: "10.54",
                        left: "457.43",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "500.16",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "528.24",
                        top: "443.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "advertisement,",
                        width: "70.31",
                        height: "10.54",
                        left: "72",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "145.31",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertiser",
                        width: "47.31",
                        height: "10.54",
                        left: "162.97",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "213.28",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "assume",
                        width: "35.32",
                        height: "10.54",
                        left: "236.94",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "275.26",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "296.26",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "meet",
                        width: "23.32",
                        height: "10.54",
                        left: "317.26",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "11.33",
                        height: "10.54",
                        left: "343.58",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "target",
                        width: "27.1",
                        height: "10.54",
                        left: "357.91",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "criteria.",
                        width: "36.98",
                        height: "10.54",
                        left: "388.01",
                        top: "429.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Disclosure",
                        width: "68.41",
                        height: "14.06",
                        left: "72",
                        top: "397.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "13.33",
                        height: "14.06",
                        left: "144.41",
                        top: "397.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "31.27",
                        height: "14.06",
                        left: "161.74",
                        top: "397.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "76.4",
                        height: "14.06",
                        left: "197.01",
                        top: "397.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "369.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "91.44",
                        top: "369.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclose",
                        width: "38.66",
                        height: "10.54",
                        left: "115.85",
                        top: "369.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "aggregated",
                        width: "52.64",
                        height: "10.54",
                        left: "158.26",
                        top: "369.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "214.65",
                        top: "369.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "275.05",
                        top: "369.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "305.46",
                        top: "369.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users,",
                        width: "27.66",
                        height: "10.54",
                        left: "325.21",
                        top: "369.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "356.62",
                        top: "369.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "377.7",
                        top: "369.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "438.1",
                        top: "369.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "does",
                        width: "22",
                        height: "10.54",
                        left: "459.09",
                        top: "369.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "484.09",
                        top: "369.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identify",
                        width: "37.33",
                        height: "10.54",
                        left: "502.42",
                        top: "369.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "355.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "individual,",
                        width: "51.66",
                        height: "10.54",
                        left: "94.58",
                        top: "355.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.67",
                        height: "10.54",
                        left: "151.49",
                        top: "355.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "restriction.",
                        width: "51.65",
                        height: "10.54",
                        left: "192.66",
                        top: "355.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "In",
                        width: "9.99",
                        height: "10.54",
                        left: "248.81",
                        top: "355.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "addition,",
                        width: "42.33",
                        height: "10.54",
                        left: "263.3",
                        top: "355.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "310.13",
                        top: "355.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "328.62",
                        top: "355.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclose",
                        width: "38.66",
                        height: "10.54",
                        left: "353.78",
                        top: "355.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.66",
                        height: "10.54",
                        left: "396.94",
                        top: "355.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "442.1",
                        top: "355.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "503.25",
                        top: "355.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "525.74",
                        top: "355.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "72",
                        top: "341.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "106.98",
                        top: "341.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "119.98",
                        top: "341.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.65",
                        height: "10.54",
                        left: "140.98",
                        top: "341.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "180.63",
                        top: "341.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "described",
                        width: "45.98",
                        height: "10.54",
                        left: "193.63",
                        top: "341.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "242.61",
                        top: "341.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "254.94",
                        top: "341.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.66",
                        height: "10.54",
                        left: "275.28",
                        top: "341.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy:",
                        width: "34",
                        height: "10.54",
                        left: "314.94",
                        top: "341.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "314.3",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "313.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "123.49",
                        top: "313.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "subsidiaries,",
                        width: "59.66",
                        height: "10.54",
                        left: "142.49",
                        top: "313.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "affiliates,",
                        width: "44.76",
                        height: "10.54",
                        left: "205.15",
                        top: "313.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "252.91",
                        top: "313.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "business",
                        width: "40.67",
                        height: "10.54",
                        left: "273.23",
                        top: "313.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "partners.",
                        width: "41.65",
                        height: "10.54",
                        left: "316.9",
                        top: "313.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "300.5",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "300.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contractors,",
                        width: "56.31",
                        height: "10.54",
                        left: "124.99",
                        top: "300.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "33.98",
                        height: "10.54",
                        left: "185.8",
                        top: "300.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providers,",
                        width: "48.32",
                        height: "10.54",
                        left: "224.28",
                        top: "300.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "277.1",
                        top: "300.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "298.18",
                        top: "300.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "326.58",
                        top: "300.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "31.98",
                        height: "10.54",
                        left: "353",
                        top: "300.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "388.73",
                        top: "300.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "406.48",
                        top: "300.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "426.22",
                        top: "300.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "support",
                        width: "36",
                        height: "10.54",
                        left: "439.31",
                        top: "300.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "479.06",
                        top: "300.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "business",
                        width: "40.67",
                        height: "10.54",
                        left: "498.8",
                        top: "300.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "operations.",
                        width: "52.99",
                        height: "10.54",
                        left: "108",
                        top: "286.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "272.91",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "272.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "127.24",
                        top: "272.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "buyer",
                        width: "27.32",
                        height: "10.54",
                        left: "139.32",
                        top: "272.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "173.39",
                        top: "272.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "190.14",
                        top: "272.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "successor",
                        width: "45.99",
                        height: "10.54",
                        left: "221.54",
                        top: "272.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "274.28",
                        top: "272.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "290.36",
                        top: "272.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "event",
                        width: "25.99",
                        height: "10.54",
                        left: "311.77",
                        top: "272.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "344.51",
                        top: "272.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "361.25",
                        top: "272.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "merger,",
                        width: "36.28",
                        height: "10.54",
                        left: "373.33",
                        top: "272.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "divestiture,",
                        width: "53.66",
                        height: "10.54",
                        left: "415.61",
                        top: "272.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "restructuring,",
                        width: "64.31",
                        height: "10.54",
                        left: "475.27",
                        top: "272.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "reorganization,",
                        width: "72.08",
                        height: "10.54",
                        left: "108",
                        top: "258.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "dissolution,",
                        width: "55.68",
                        height: "10.54",
                        left: "185.33",
                        top: "258.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "246.26",
                        top: "258.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "261.5",
                        top: "258.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sale",
                        width: "18.66",
                        height: "10.54",
                        left: "291.41",
                        top: "258.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "315.32",
                        top: "258.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transfer",
                        width: "36.65",
                        height: "10.54",
                        left: "330.56",
                        top: "258.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "372.46",
                        top: "258.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "some",
                        width: "25.33",
                        height: "10.54",
                        left: "387.7",
                        top: "258.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "417.53",
                        top: "258.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.54",
                        left: "432.03",
                        top: "258.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "448.52",
                        top: "258.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore",
                        width: "31.99",
                        height: "10.54",
                        left: "463.02",
                        top: "258.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Exotics’",
                        width: "39.99",
                        height: "10.54",
                        left: "499.51",
                        top: "258.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "assets,",
                        width: "31",
                        height: "10.54",
                        left: "108",
                        top: "244.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "whether",
                        width: "38.64",
                        height: "10.54",
                        left: "145",
                        top: "244.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "189.64",
                        top: "244.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "205.64",
                        top: "244.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "going",
                        width: "27.33",
                        height: "10.54",
                        left: "216.97",
                        top: "244.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "concern",
                        width: "37.98",
                        height: "10.54",
                        left: "250.3",
                        top: "244.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "294.28",
                        top: "244.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "310.27",
                        top: "244.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "part",
                        width: "18.65",
                        height: "10.54",
                        left: "326.27",
                        top: "244.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "350.92",
                        top: "244.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bankruptcy,",
                        width: "56.2",
                        height: "10.54",
                        left: "366.92",
                        top: "244.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "liquidation,",
                        width: "55",
                        height: "10.54",
                        left: "429.12",
                        top: "244.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "490.12",
                        top: "244.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "similar",
                        width: "33.32",
                        height: "10.54",
                        left: "506.12",
                        top: "244.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "proceeding,",
                        width: "56.31",
                        height: "10.54",
                        left: "108",
                        top: "231.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "168.81",
                        top: "231.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "29.33",
                        height: "10.54",
                        left: "181.89",
                        top: "231.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "214.97",
                        top: "231.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "259.37",
                        top: "231.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "held",
                        width: "20.66",
                        height: "10.54",
                        left: "319.78",
                        top: "231.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "344.19",
                        top: "231.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "359.94",
                        top: "231.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "374.36",
                        top: "231.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "404.77",
                        top: "231.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "424.51",
                        top: "231.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users",
                        width: "24.66",
                        height: "10.54",
                        left: "466.62",
                        top: "231.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "495.03",
                        top: "231.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "among",
                        width: "32.66",
                        height: "10.54",
                        left: "506.78",
                        top: "231.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "108",
                        top: "217.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "assets",
                        width: "28",
                        height: "10.55",
                        left: "125.66",
                        top: "217.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transferred.",
                        width: "54.96",
                        height: "10.55",
                        left: "156.66",
                        top: "217.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "203.91",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "108",
                        top: "203.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "128.19",
                        top: "203.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "153.35",
                        top: "203.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.64",
                        height: "10.54",
                        left: "194.51",
                        top: "203.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "228.9",
                        top: "203.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "241.99",
                        top: "203.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "267.73",
                        top: "203.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "328.14",
                        top: "203.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "select",
                        width: "27.32",
                        height: "10.54",
                        left: "341.22",
                        top: "203.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "372.29",
                        top: "203.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "31.99",
                        height: "10.54",
                        left: "398.7",
                        top: "203.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.66",
                        height: "10.54",
                        left: "434.44",
                        top: "203.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "perform",
                        width: "38.65",
                        height: "10.54",
                        left: "458.85",
                        top: "203.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.65",
                        height: "10.54",
                        left: "501.25",
                        top: "203.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "108",
                        top: "189.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "124.5",
                        top: "189.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "behalf.",
                        width: "32.98",
                        height: "10.54",
                        left: "145",
                        top: "189.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "These",
                        width: "28.65",
                        height: "10.54",
                        left: "182.48",
                        top: "189.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "215.63",
                        top: "189.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "31.99",
                        height: "10.54",
                        left: "242.04",
                        top: "189.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "277.78",
                        top: "189.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "318.19",
                        top: "189.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "variety",
                        width: "33.32",
                        height: "10.54",
                        left: "327.26",
                        top: "189.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "364.33",
                        top: "189.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.64",
                        height: "10.54",
                        left: "378.08",
                        top: "189.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "420.47",
                        top: "189.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us,",
                        width: "13.67",
                        height: "10.54",
                        left: "433.56",
                        top: "189.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including,",
                        width: "48.33",
                        height: "10.54",
                        left: "450.98",
                        top: "189.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "without",
                        width: "36.66",
                        height: "10.54",
                        left: "503.06",
                        top: "189.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "limitation,",
                        width: "49.66",
                        height: "10.54",
                        left: "108",
                        top: "175.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "billing,",
                        width: "34.34",
                        height: "10.54",
                        left: "165.91",
                        top: "175.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sales,",
                        width: "26.33",
                        height: "10.54",
                        left: "208.5",
                        top: "175.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "marketing,",
                        width: "51.65",
                        height: "10.54",
                        left: "243.08",
                        top: "175.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertising,",
                        width: "56.32",
                        height: "10.54",
                        left: "302.98",
                        top: "175.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "market",
                        width: "33.31",
                        height: "10.54",
                        left: "366.8",
                        top: "175.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "research,",
                        width: "42.97",
                        height: "10.54",
                        left: "407.61",
                        top: "175.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fulfillment,",
                        width: "54.32",
                        height: "10.54",
                        left: "458.08",
                        top: "175.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "19.99",
                        height: "10.54",
                        left: "519.9",
                        top: "175.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "storage,",
                        width: "37.65",
                        height: "10.54",
                        left: "108",
                        top: "162.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "analysis",
                        width: "38.66",
                        height: "10.54",
                        left: "150.9",
                        top: "162.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "194.81",
                        top: "162.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "processing,",
                        width: "54.32",
                        height: "10.54",
                        left: "217.39",
                        top: "162.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identity",
                        width: "36.66",
                        height: "10.54",
                        left: "276.96",
                        top: "162.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "verification,",
                        width: "58.31",
                        height: "10.54",
                        left: "318.87",
                        top: "162.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fraud",
                        width: "25.32",
                        height: "10.54",
                        left: "381.68",
                        top: "162.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "411.5",
                        top: "162.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "safety",
                        width: "28.66",
                        height: "10.54",
                        left: "433.32",
                        top: "162.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "protection,",
                        width: "51.65",
                        height: "10.54",
                        left: "466.48",
                        top: "162.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "522.63",
                        top: "162.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "legal",
                        width: "23.32",
                        height: "10.54",
                        left: "108",
                        top: "148.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services.",
                        width: "41.65",
                        height: "10.54",
                        left: "134.32",
                        top: "148.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "134.92",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "124.99",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "31.98",
                        height: "10.54",
                        left: "152.16",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "188.64",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "market",
                        width: "33.31",
                        height: "10.54",
                        left: "202.48",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "their",
                        width: "21.99",
                        height: "10.54",
                        left: "240.29",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "products",
                        width: "41.33",
                        height: "10.54",
                        left: "266.03",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "311.11",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "38.64",
                        height: "10.54",
                        left: "324.86",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "367.25",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "380.34",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "402.09",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "413.17",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "434.92",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "461.32",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "opted",
                        width: "26.66",
                        height: "10.54",
                        left: "480.4",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "out",
                        width: "15.34",
                        height: "10.54",
                        left: "510.81",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "529.9",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "108",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclosures.",
                        width: "56.32",
                        height: "10.54",
                        left: "136.41",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.54",
                        left: "196.48",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "more",
                        width: "24.66",
                        height: "10.54",
                        left: "216.9",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "59.65",
                        height: "10.54",
                        left: "245.31",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Choices",
                        width: "38.66",
                        height: "10.54",
                        left: "308.71",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "About",
                        width: "30",
                        height: "10.54",
                        left: "351.12",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "How",
                        width: "23.34",
                        height: "10.54",
                        left: "384.87",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "411.21",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "429.9",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "451.56",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Disclose",
                        width: "41.33",
                        height: "10.54",
                        left: "471.88",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.54",
                        left: "516.21",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Information,",
                        width: "60.32",
                        height: "10.54",
                        left: "108",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below.",
                        width: "31.54",
                        height: "10.54",
                        left: "171.32",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "93.52",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.55",
                        left: "108",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "131.94",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclose",
                        width: "38.66",
                        height: "10.55",
                        left: "160.85",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "207.76",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.55",
                        left: "238.01",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.55",
                        left: "302.91",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "whomever",
                        width: "50.64",
                        height: "10.55",
                        left: "320.5",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "necessary",
                        width: "46.65",
                        height: "10.55",
                        left: "379.39",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.55",
                        left: "434.29",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.55",
                        left: "468.53",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "believe",
                        width: "34.65",
                        height: "10.55",
                        left: "490.77",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "6.6600000000001",
                        height: "10.55",
                        left: "532.92",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "appropriate",
                        width: "54.64",
                        height: "10.54",
                        left: "108",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "167.89",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "investigate,",
                        width: "54.99",
                        height: "10.54",
                        left: "182.47",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prevent,",
                        width: "38.98",
                        height: "10.54",
                        left: "242.71",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "286.94",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "take",
                        width: "19.98",
                        height: "10.54",
                        left: "302.19",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "action",
                        width: "29.32",
                        height: "10.54",
                        left: "327.42",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "regarding",
                        width: "45.98",
                        height: "10.54",
                        left: "361.99",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "possible",
                        width: "39.33",
                        height: "10.54",
                        left: "413.22",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "illegal",
                        width: "29.99",
                        height: "10.54",
                        left: "457.8",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activities,",
                        width: "46.32",
                        height: "10.54",
                        left: "493.04",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,90,624.5", "lineto,380,624.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,398.5", "lineto,273,398.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,309,121.5", "lineto,540,121.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,108,108.5", "lineto,165,108.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "7",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "suspected",
                        width: "46.65",
                        height: "10.54",
                        left: "108",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fraud,",
                        width: "28.32",
                        height: "10.54",
                        left: "157.65",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "situations",
                        width: "46",
                        height: "10.54",
                        left: "188.97",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "involving",
                        width: "46",
                        height: "10.54",
                        left: "237.97",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "potential",
                        width: "41.99",
                        height: "10.54",
                        left: "286.97",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "threats",
                        width: "31.99",
                        height: "10.54",
                        left: "331.96",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "366.95",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "379.28",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "physical",
                        width: "39.99",
                        height: "10.54",
                        left: "396.94",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "safety",
                        width: "28.66",
                        height: "10.54",
                        left: "439.93",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "471.59",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "484.58",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "person,",
                        width: "34.99",
                        height: "10.54",
                        left: "504.91",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "violations",
                        width: "47.33",
                        height: "10.54",
                        left: "108",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "160.58",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "175.08",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "194.24",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "228.56",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "243.05",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "266.21",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "280.71",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "302.54",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "legal",
                        width: "23.32",
                        height: "10.54",
                        left: "331.69",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "26.66",
                        height: "10.54",
                        left: "359.51",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "governing",
                        width: "48.66",
                        height: "10.54",
                        left: "390.67",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "443.83",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "464.33",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "478.82",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "497.98",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and/or",
                        width: "30.66",
                        height: "10.54",
                        left: "108",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "141.66",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "protect",
                        width: "33.32",
                        height: "10.54",
                        left: "153.99",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "190.31",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights",
                        width: "27.34",
                        height: "10.54",
                        left: "209.3",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "239.64",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "property",
                        width: "40.66",
                        height: "10.54",
                        left: "259.96",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "303.62",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "323.94",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights",
                        width: "27.34",
                        height: "10.54",
                        left: "341.6",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "371.94",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "property",
                        width: "40.65",
                        height: "10.54",
                        left: "392.26",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "435.91",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "448.91",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users.",
                        width: "27.66",
                        height: "10.54",
                        left: "476.57",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "665.28",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.55",
                        left: "108",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "enforce",
                        width: "35.97",
                        height: "10.55",
                        left: "125.74",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "166.96",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "apply",
                        width: "26.66",
                        height: "10.55",
                        left: "181.46",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.55",
                        left: "212.62",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.81",
                        height: "10.55",
                        left: "233.12",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "267.43",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.55",
                        left: "281.93",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "305.09",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.55",
                        left: "326.92",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "agreements,",
                        width: "57.64",
                        height: "10.55",
                        left: "356.07",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.55",
                        left: "418.21",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.55",
                        left: "468.04",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "billing",
                        width: "31.34",
                        height: "10.55",
                        left: "486.53",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "522.37",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "collection",
                        width: "47.31",
                        height: "10.54",
                        left: "108",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes.",
                        width: "45.67",
                        height: "10.54",
                        left: "158.31",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.2800000000001",
                        left: "90",
                        top: "637.68",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "108",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fulfill",
                        width: "27.33",
                        height: "10.54",
                        left: "123.49",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "153.82",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purpose",
                        width: "37.99",
                        height: "10.54",
                        left: "171.48",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "212.47",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "29.33",
                        height: "10.54",
                        left: "229.46",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "261.79",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "282.79",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it.",
                        width: "9.67",
                        height: "10.54",
                        left: "322.45",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "623.88",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.54",
                        left: "108",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "127.67",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "148",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purpose",
                        width: "37.99",
                        height: "10.54",
                        left: "175.65",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclosed",
                        width: "44.66",
                        height: "10.54",
                        left: "216.64",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "264.3",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "279.3",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "26",
                        height: "10.54",
                        left: "292.97",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "321.97",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.65",
                        height: "10.54",
                        left: "342.97",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "382.62",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information.",
                        width: "59.66",
                        height: "10.54",
                        left: "400.28",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "610.09",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "With",
                        width: "23.51",
                        height: "10.54",
                        left: "108",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "134.51",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "consent.",
                        width: "39.66",
                        height: "10.54",
                        left: "159.51",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Your",
                        width: "31.27",
                        height: "14.06",
                        left: "72",
                        top: "577.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Choices",
                        width: "51.54",
                        height: "14.06",
                        left: "107.27",
                        top: "577.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "About",
                        width: "39.99",
                        height: "14.06",
                        left: "162.81",
                        top: "577.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Our",
                        width: "24.88",
                        height: "14.06",
                        left: "206.79",
                        top: "577.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "24.88",
                        height: "14.06",
                        left: "235.67",
                        top: "577.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "13.32",
                        height: "14.06",
                        left: "264.55",
                        top: "577.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "31.27",
                        height: "14.06",
                        left: "281.87",
                        top: "577.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "76.41",
                        height: "14.06",
                        left: "317.14",
                        top: "577.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "strive",
                        width: "26.66",
                        height: "10.54",
                        left: "91.44",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "121.85",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.65",
                        height: "10.54",
                        left: "134.94",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "175.34",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "197.09",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "choices",
                        width: "35.98",
                        height: "10.54",
                        left: "222.18",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "regarding",
                        width: "45.98",
                        height: "10.54",
                        left: "261.91",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "311.64",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "330.05",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "374.45",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "434.1",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "455.1",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "494.76",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us.",
                        width: "13.67",
                        height: "10.54",
                        left: "507.09",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.7",
                        height: "10.54",
                        left: "523.76",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "72",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "created",
                        width: "34.64",
                        height: "10.54",
                        left: "97.65",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "mechanisms",
                        width: "59.32",
                        height: "10.54",
                        left: "135.29",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "197.61",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.66",
                        height: "10.54",
                        left: "209.94",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "249.6",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "270.6",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "294.93",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "following",
                        width: "46.67",
                        height: "10.54",
                        left: "312.59",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "control",
                        width: "33.99",
                        height: "10.54",
                        left: "362.26",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "over",
                        width: "21.32",
                        height: "10.54",
                        left: "399.25",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "423.57",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information:",
                        width: "59.99",
                        height: "10.54",
                        left: "448.56",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "90",
                        top: "508.29",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Tracking",
                        width: "46.45",
                        height: "10.55",
                        left: "108",
                        top: "507.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Technologies",
                        width: "65.56",
                        height: "10.55",
                        left: "158.2",
                        top: "507.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.55",
                        left: "227.51",
                        top: "507.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Advertising",
                        width: "60",
                        height: "10.55",
                        left: "250.61",
                        top: "507.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.55",
                        left: "310.61",
                        top: "507.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.55",
                        left: "316.61",
                        top: "507.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "can",
                        width: "16.65",
                        height: "10.55",
                        left: "339.08",
                        top: "507.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "set",
                        width: "13.33",
                        height: "10.55",
                        left: "358.73",
                        top: "507.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.55",
                        left: "375.06",
                        top: "507.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "browser",
                        width: "38.66",
                        height: "10.55",
                        left: "400.05",
                        top: "507.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "441.71",
                        top: "507.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "refuse",
                        width: "29.32",
                        height: "10.55",
                        left: "454.04",
                        top: "507.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "all",
                        width: "11.99",
                        height: "10.55",
                        left: "486.36",
                        top: "507.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.55",
                        left: "501.35",
                        top: "507.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "some",
                        width: "25.33",
                        height: "10.55",
                        left: "514.35",
                        top: "507.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "browser",
                        width: "38.65",
                        height: "10.54",
                        left: "108",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cookies,",
                        width: "39.66",
                        height: "10.54",
                        left: "151.9",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "196.81",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "212.06",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "alert",
                        width: "21.32",
                        height: "10.54",
                        left: "226.64",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "253.21",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "25.99",
                        height: "10.54",
                        left: "276.46",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cookies",
                        width: "36.66",
                        height: "10.54",
                        left: "307.7",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.64",
                        height: "10.54",
                        left: "349.61",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "being",
                        width: "26.66",
                        height: "10.54",
                        left: "369.5",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sent.",
                        width: "22.33",
                        height: "10.54",
                        left: "401.41",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "To",
                        width: "12.5",
                        height: "10.54",
                        left: "428.99",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "learn",
                        width: "23.98",
                        height: "10.54",
                        left: "446.74",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "how",
                        width: "20.66",
                        height: "10.54",
                        left: "475.22",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "500.38",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "can",
                        width: "16.66",
                        height: "10.54",
                        left: "522.88",
                        top: "494.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "manage",
                        width: "37.31",
                        height: "10.54",
                        left: "108",
                        top: "480.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "152.06",
                        top: "480.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Flash",
                        width: "26",
                        height: "10.54",
                        left: "180.81",
                        top: "480.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cookie",
                        width: "31.99",
                        height: "10.54",
                        left: "213.56",
                        top: "480.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "settings,",
                        width: "39.67",
                        height: "10.54",
                        left: "252.3",
                        top: "480.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "visit",
                        width: "20.67",
                        height: "10.54",
                        left: "298.72",
                        top: "480.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "326.14",
                        top: "480.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Flash",
                        width: "26",
                        height: "10.54",
                        left: "346.8",
                        top: "480.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "player",
                        width: "29.99",
                        height: "10.54",
                        left: "378.8",
                        top: "480.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "settings",
                        width: "36.66",
                        height: "10.54",
                        left: "414.79",
                        top: "480.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "page",
                        width: "22.66",
                        height: "10.54",
                        left: "457.45",
                        top: "480.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "486.11",
                        top: "480.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Adobe's",
                        width: "38.82",
                        height: "10.54",
                        left: "501.11",
                        top: "480.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                        link: "http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "website.",
                        width: "39.66",
                        height: "10.54",
                        left: "108",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "156.66",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "169.15",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disable",
                        width: "33.99",
                        height: "10.54",
                        left: "191.65",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "230.14",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "refuse",
                        width: "29.32",
                        height: "10.54",
                        left: "244.63",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cookies,",
                        width: "39.66",
                        height: "10.54",
                        left: "278.45",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "please",
                        width: "29.98",
                        height: "10.54",
                        left: "322.61",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "note",
                        width: "20.66",
                        height: "10.54",
                        left: "357.09",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "382.25",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "some",
                        width: "25.33",
                        height: "10.54",
                        left: "404.74",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parts",
                        width: "23.33",
                        height: "10.54",
                        left: "434.57",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "462.4",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "476.89",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "site",
                        width: "16.67",
                        height: "10.54",
                        left: "498.73",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "519.15",
                        top: "466.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "then",
                        width: "20.66",
                        height: "10.54",
                        left: "108",
                        top: "452.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "131.66",
                        top: "452.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inaccessible",
                        width: "57.97",
                        height: "10.54",
                        left: "145.99",
                        top: "452.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "206.96",
                        top: "452.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "219.96",
                        top: "452.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "function",
                        width: "39.99",
                        height: "10.54",
                        left: "238.29",
                        top: "452.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "properly.",
                        width: "42.87",
                        height: "10.54",
                        left: "281.28",
                        top: "452.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "439.3",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Disclosure",
                        width: "53.11",
                        height: "10.54",
                        left: "108",
                        top: "438.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "164.86",
                        top: "438.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "25.33",
                        height: "10.54",
                        left: "178.61",
                        top: "438.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "62.67",
                        height: "10.54",
                        left: "207.69",
                        top: "438.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "15.32",
                        height: "10.54",
                        left: "274.11",
                        top: "438.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Third­Party",
                        width: "62.66",
                        height: "10.54",
                        left: "293.18",
                        top: "438.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Advertising",
                        width: "60",
                        height: "10.54",
                        left: "358.84",
                        top: "438.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "418.84",
                        top: "438.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "424.84",
                        top: "438.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "435.83",
                        top: "438.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "456.83",
                        top: "438.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "471.83",
                        top: "438.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "want",
                        width: "23.32",
                        height: "10.54",
                        left: "490.17",
                        top: "438.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "516.49",
                        top: "438.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "530.16",
                        top: "438.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "share",
                        width: "25.32",
                        height: "10.54",
                        left: "108",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "142.32",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.66",
                        height: "10.54",
                        left: "173.31",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "222.22",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "287.12",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unaffiliated",
                        width: "55.08",
                        height: "10.54",
                        left: "316.71",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "380.04",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "non­agent",
                        width: "47.98",
                        height: "10.54",
                        left: "398.29",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "454.52",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "31.98",
                        height: "10.54",
                        left: "485.44",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "14",
                        height: "10.54",
                        left: "525.67",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "promotional",
                        width: "58.66",
                        height: "10.54",
                        left: "108",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes,",
                        width: "45.66",
                        height: "10.54",
                        left: "170.41",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "219.82",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "can",
                        width: "16.65",
                        height: "10.54",
                        left: "241.57",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "opt­out",
                        width: "34.67",
                        height: "10.54",
                        left: "261.97",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "checking",
                        width: "43.31",
                        height: "10.54",
                        left: "300.39",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "347.45",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "relevant",
                        width: "38.64",
                        height: "10.54",
                        left: "365.86",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "box",
                        width: "18",
                        height: "10.54",
                        left: "408.25",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "located",
                        width: "34.65",
                        height: "10.54",
                        left: "430",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "468.4",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "483.4",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "form",
                        width: "23.32",
                        height: "10.54",
                        left: "501.06",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "527.38",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "which",
                        width: "29.33",
                        height: "10.54",
                        left: "108",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "143.33",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "163.32",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "201.3",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "19.99",
                        height: "10.54",
                        left: "229.29",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(the",
                        width: "18.66",
                        height: "10.54",
                        left: "255.28",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "registration",
                        width: "54.65",
                        height: "10.54",
                        left: "279.94",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "form).",
                        width: "30.32",
                        height: "10.54",
                        left: "340.59",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "376.91",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "can",
                        width: "16.65",
                        height: "10.54",
                        left: "402.38",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "19.33",
                        height: "10.54",
                        left: "424.28",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "always",
                        width: "33.32",
                        height: "10.54",
                        left: "448.86",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "opt­out",
                        width: "34.66",
                        height: "10.54",
                        left: "487.43",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "527.34",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "[logging",
                        width: "40.66",
                        height: "10.54",
                        left: "108",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "into",
                        width: "18.67",
                        height: "10.54",
                        left: "152.41",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "174.83",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "193.24",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "235.35",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "adjusting",
                        width: "44",
                        height: "10.54",
                        left: "256.42",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "304.17",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "19.99",
                        height: "10.54",
                        left: "329.92",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "preferences",
                        width: "55.29",
                        height: "10.54",
                        left: "352.91",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "411.2",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "423.53",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "448.53",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "profile][,][",
                        width: "50.97",
                        height: "10.54",
                        left: "488.84",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "checking",
                        width: "43.31",
                        height: "10.54",
                        left: "108",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "155.06",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unchecking",
                        width: "55.31",
                        height: "10.54",
                        left: "168.81",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "227.12",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "relevant",
                        width: "38.64",
                        height: "10.54",
                        left: "244.78",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "boxes]",
                        width: "32",
                        height: "10.54",
                        left: "286.42",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "[or",
                        width: "13.99",
                        height: "10.54",
                        left: "321.42",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by]",
                        width: "15.99",
                        height: "10.54",
                        left: "338.41",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sending",
                        width: "37.33",
                        height: "10.54",
                        left: "357.4",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "397.73",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.54",
                        left: "411.4",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.65",
                        height: "10.54",
                        left: "425.73",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "455.38",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "479.72",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "request",
                        width: "34.66",
                        height: "10.54",
                        left: "504.71",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "108",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "admin@mooreexotics.com",
                        width: "129.36",
                        height: "10.54",
                        left: "120.33",
                        top: "356.03",
                        color: "#1154cc",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                        link: "mailto:admin@mooreexotics.com",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "252.69",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "neutral",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "90",
                        top: "342.71",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Promotional",
                        width: "63.77",
                        height: "10.54",
                        left: "108",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Offers",
                        width: "32.65",
                        height: "10.54",
                        left: "175.52",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "25.1",
                        height: "10.54",
                        left: "211.92",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "240.77",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Company",
                        width: "50.01",
                        height: "10.54",
                        left: "260.52",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "310.53",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "317.28",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "329.02",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "350.77",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "366.52",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "wish",
                        width: "22.67",
                        height: "10.54",
                        left: "385.61",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "411.28",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "423.61",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "449.26",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.65",
                        height: "10.54",
                        left: "474.26",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address",
                        width: "35.99",
                        height: "10.54",
                        left: "503.91",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "used",
                        width: "22",
                        height: "10.54",
                        left: "108",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "133.75",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "149.5",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Company",
                        width: "46.66",
                        height: "10.54",
                        left: "167.91",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "218.32",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promote",
                        width: "39.99",
                        height: "10.54",
                        left: "231.4",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "275.14",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "own",
                        width: "20.67",
                        height: "10.54",
                        left: "294.89",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "319.31",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.67",
                        height: "10.54",
                        left: "333.05",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties'",
                        width: "34.15",
                        height: "10.54",
                        left: "359.47",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "products",
                        width: "41.32",
                        height: "10.54",
                        left: "397.37",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "442.44",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services,",
                        width: "41.65",
                        height: "10.54",
                        left: "456.19",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "501.59",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "can",
                        width: "16.65",
                        height: "10.54",
                        left: "523.34",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "opt­out",
                        width: "34.66",
                        height: "10.54",
                        left: "108",
                        top: "314.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "146.41",
                        top: "314.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "checking",
                        width: "43.32",
                        height: "10.54",
                        left: "162.16",
                        top: "314.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "209.23",
                        top: "314.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "relevant",
                        width: "38.64",
                        height: "10.54",
                        left: "227.64",
                        top: "314.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "box(s)",
                        width: "30.66",
                        height: "10.54",
                        left: "270.03",
                        top: "314.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "within",
                        width: "30.67",
                        height: "10.54",
                        left: "304.44",
                        top: "314.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.54",
                        left: "338.86",
                        top: "314.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users’",
                        width: "28.65",
                        height: "10.54",
                        left: "347.94",
                        top: "314.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "profile",
                        width: "31.99",
                        height: "10.54",
                        left: "380.34",
                        top: "314.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "settings",
                        width: "36.67",
                        height: "10.54",
                        left: "415.33",
                        top: "314.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "455",
                        top: "314.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "467.99",
                        top: "314.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "clicking",
                        width: "38.66",
                        height: "10.54",
                        left: "482.99",
                        top: "314.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "524.65",
                        top: "314.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "“unsubscribe”",
                        width: "67.97",
                        height: "10.54",
                        left: "108",
                        top: "300.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "link",
                        width: "18.67",
                        height: "10.54",
                        left: "178.97",
                        top: "300.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "included",
                        width: "41.32",
                        height: "10.54",
                        left: "200.64",
                        top: "300.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "244.96",
                        top: "300.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "256.62",
                        top: "300.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bottom",
                        width: "34.01",
                        height: "10.54",
                        left: "274.28",
                        top: "300.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "311.29",
                        top: "300.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "most",
                        width: "23.34",
                        height: "10.54",
                        left: "324.28",
                        top: "300.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "emails",
                        width: "31.32",
                        height: "10.54",
                        left: "350.62",
                        top: "300.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "384.94",
                        top: "300.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "send;",
                        width: "25.33",
                        height: "10.54",
                        left: "401.94",
                        top: "300.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided,",
                        width: "45.65",
                        height: "10.54",
                        left: "430.27",
                        top: "300.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "however,",
                        width: "43.84",
                        height: "10.54",
                        left: "478.92",
                        top: "300.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "525.76",
                        top: "300.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "reserve",
                        width: "34.64",
                        height: "10.54",
                        left: "108",
                        top: "287.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "153.14",
                        top: "287.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.66",
                        height: "10.54",
                        left: "177.55",
                        top: "287.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "209.96",
                        top: "287.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "send",
                        width: "21.99",
                        height: "10.54",
                        left: "229.05",
                        top: "287.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "260.79",
                        top: "287.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transactional",
                        width: "61.31",
                        height: "10.54",
                        left: "288.54",
                        top: "287.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "emails",
                        width: "31.33",
                        height: "10.54",
                        left: "359.6",
                        top: "287.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "21.99",
                        height: "10.54",
                        left: "400.68",
                        top: "287.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.54",
                        left: "432.42",
                        top: "287.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "customer",
                        width: "43.98",
                        height: "10.54",
                        left: "452.17",
                        top: "287.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "33.98",
                        height: "10.54",
                        left: "505.9",
                        top: "287.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "communications.",
                        width: "82.32",
                        height: "10.55",
                        left: "108",
                        top: "273.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "92.94",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "110.19",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "control",
                        width: "33.99",
                        height: "10.54",
                        left: "130.78",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "170.02",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties'",
                        width: "34.15",
                        height: "10.54",
                        left: "197.93",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collection",
                        width: "47.31",
                        height: "10.54",
                        left: "237.33",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "289.89",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "15.99",
                        height: "10.54",
                        left: "305.14",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "326.38",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "341.63",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "368.13",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "429.28",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "serve",
                        width: "25.31",
                        height: "10.54",
                        left: "443.12",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interest­based",
                        width: "66.64",
                        height: "10.54",
                        left: "472.93",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "advertising.",
                        width: "56.32",
                        height: "10.54",
                        left: "72",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "However,",
                        width: "46.5",
                        height: "10.54",
                        left: "132.07",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "these",
                        width: "24.66",
                        height: "10.54",
                        left: "182.32",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "22.66",
                        height: "10.54",
                        left: "210.73",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "31.99",
                        height: "10.54",
                        left: "237.14",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "272.88",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "36.65",
                        height: "10.54",
                        left: "297.29",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "337.69",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.34",
                        height: "10.54",
                        left: "359.44",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ways",
                        width: "24.66",
                        height: "10.54",
                        left: "384.53",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "412.94",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "choose",
                        width: "33.33",
                        height: "10.54",
                        left: "425.27",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "461.6",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "479.93",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.66",
                        height: "10.54",
                        left: "492.26",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "517.92",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "72",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collected",
                        width: "43.31",
                        height: "10.54",
                        left: "134.65",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "183.96",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "used",
                        width: "21.99",
                        height: "10.54",
                        left: "199.96",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "227.95",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.33",
                        height: "10.54",
                        left: "243.29",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "way.",
                        width: "22.21",
                        height: "10.54",
                        left: "266.63",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "294.84",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "can",
                        width: "16.66",
                        height: "10.54",
                        left: "320.3",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "opt",
                        width: "15.33",
                        height: "10.54",
                        left: "342.96",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "out",
                        width: "15.33",
                        height: "10.54",
                        left: "364.29",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "385.62",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "receiving",
                        width: "44.64",
                        height: "10.54",
                        left: "401.62",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "targeted",
                        width: "38.43",
                        height: "10.54",
                        left: "451.51",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ads",
                        width: "15.99",
                        height: "10.54",
                        left: "495.19",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "516.43",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "members",
                        width: "43.99",
                        height: "10.54",
                        left: "72",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "118.99",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "131.98",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Network",
                        width: "41.99",
                        height: "10.54",
                        left: "149.64",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Advertising",
                        width: "56.66",
                        height: "10.54",
                        left: "194.63",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Initiative",
                        width: "43.32",
                        height: "10.54",
                        left: "254.29",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '("',
                        width: "8.89",
                        height: "10.54",
                        left: "300.61",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "NAI",
                        width: "22.01",
                        height: "10.54",
                        left: "309.5",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '")',
                        width: "8.89",
                        height: "10.54",
                        left: "331.51",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "343.4",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "358.4",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "NAI's",
                        width: "28.16",
                        height: "10.54",
                        left: "376.06",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                        link: "http://optout.networkadvertising.org/?c=1",
                      },
                      {
                        text: "website.",
                        width: "39.66",
                        height: "10.54",
                        left: "407.22",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "California",
                        width: "48.65",
                        height: "10.54",
                        left: "72",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "residents",
                        width: "42.66",
                        height: "10.54",
                        left: "125.9",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "173.81",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "199.72",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "additional",
                        width: "47.99",
                        height: "10.54",
                        left: "227.62",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "280.86",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "326.76",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights",
                        width: "27.34",
                        height: "10.54",
                        left: "388.66",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "421.25",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "choices.",
                        width: "38.99",
                        height: "10.54",
                        left: "443.82",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Please",
                        width: "30.65",
                        height: "10.54",
                        left: "488.06",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "see",
                        width: "15.33",
                        height: "10.54",
                        left: "523.96",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Your",
                        width: "23.46",
                        height: "10.54",
                        left: "72",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "California",
                        width: "48.65",
                        height: "10.54",
                        left: "98.46",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.66",
                        height: "10.54",
                        left: "150.11",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Rights,",
                        width: "34.34",
                        height: "10.54",
                        left: "189.77",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below,",
                        width: "31.55",
                        height: "10.54",
                        left: "227.11",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "261.66",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "more",
                        width: "24.65",
                        height: "10.54",
                        left: "278.65",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information.",
                        width: "59.66",
                        height: "10.54",
                        left: "306.3",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Nevada",
                        width: "36.64",
                        height: "10.54",
                        left: "72",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "residents",
                        width: "42.66",
                        height: "10.54",
                        left: "113.14",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "20.67",
                        height: "10.54",
                        left: "160.3",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "wish",
                        width: "22.67",
                        height: "10.54",
                        left: "185.47",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "212.64",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "exercise",
                        width: "39.31",
                        height: "10.54",
                        left: "226.47",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "their",
                        width: "21.99",
                        height: "10.54",
                        left: "270.28",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sale",
                        width: "18.65",
                        height: "10.54",
                        left: "296.02",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "opt­out",
                        width: "34.67",
                        height: "10.54",
                        left: "318.42",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights",
                        width: "27.33",
                        height: "10.54",
                        left: "356.84",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "under",
                        width: "27.32",
                        height: "10.54",
                        left: "387.92",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Nevada",
                        width: "36.65",
                        height: "10.54",
                        left: "418.99",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Revised",
                        width: "38.66",
                        height: "10.54",
                        left: "459.39",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Statutes",
                        width: "37.99",
                        height: "10.54",
                        left: "501.8",
                        top: "134.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Chapter",
                        width: "37.99",
                        height: "10.55",
                        left: "72",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "603A",
                        width: "26.66",
                        height: "10.55",
                        left: "112.99",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "142.65",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "submit",
                        width: "32.67",
                        height: "10.55",
                        left: "166.31",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.33",
                        height: "10.55",
                        left: "201.98",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "request",
                        width: "34.65",
                        height: "10.55",
                        left: "210.31",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.55",
                        left: "247.96",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.33",
                        height: "10.55",
                        left: "260.3",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "designated",
                        width: "51.32",
                        height: "10.55",
                        left: "280.63",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.66",
                        height: "10.55",
                        left: "334.95",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address:",
                        width: "39.32",
                        height: "10.55",
                        left: "367.61",
                        top: "121.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "admin@mooreexotics.com",
                        width: "129.35",
                        height: "10.55",
                        left: "409.93",
                        top: "121.04",
                        color: "#1154cc",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                        link: "mailto:admin@mooreexotics.com",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "However,",
                        width: "46.5",
                        height: "10.54",
                        left: "72",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "please",
                        width: "29.98",
                        height: "10.54",
                        left: "121.5",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "know",
                        width: "26.67",
                        height: "10.54",
                        left: "154.48",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "184.15",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "201.14",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "216.14",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "currently",
                        width: "43.32",
                        height: "10.54",
                        left: "234.47",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sell",
                        width: "16.66",
                        height: "10.54",
                        left: "280.79",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "19.99",
                        height: "10.54",
                        left: "300.45",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "triggering",
                        width: "47.32",
                        height: "10.54",
                        left: "323.44",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "373.76",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "statute's",
                        width: "38.16",
                        height: "10.54",
                        left: "394.75",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "opt­out",
                        width: "34.66",
                        height: "10.54",
                        left: "435.91",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "requirements.",
                        width: "65.64",
                        height: "10.54",
                        left: "473.57",
                        top: "107.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Accessing",
                        width: "65.74",
                        height: "14.05",
                        left: "72",
                        top: "82.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "23.1",
                        height: "14.05",
                        left: "141.74",
                        top: "82.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Correcting",
                        width: "68.4",
                        height: "14.05",
                        left: "168.84",
                        top: "82.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "31.27",
                        height: "14.05",
                        left: "241.24",
                        top: "82.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "76.41",
                        height: "14.05",
                        left: "276.51",
                        top: "82.85",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,190",
                    "lineto,540,190",
                    "lineto,540,175",
                    "lineto,72,175",
                    "lineto,72,190",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,176",
                    "lineto,540,176",
                    "lineto,540,161",
                    "lineto,72,161",
                    "lineto,72,176",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,162",
                    "lineto,540,162",
                    "lineto,540,147",
                    "lineto,72,147",
                    "lineto,72,162",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,149",
                    "lineto,540,149",
                    "lineto,540,134",
                    "lineto,72,134",
                    "lineto,72,149",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,135",
                    "lineto,540,135",
                    "lineto,540,120",
                    "lineto,72,120",
                    "lineto,72,135",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,121",
                    "lineto,540,121",
                    "lineto,540,106",
                    "lineto,72,106",
                    "lineto,72,121",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,578.5", "lineto,394,578.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,501,481.5", "lineto,540,481.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,108,467.5", "lineto,145,467.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#1154cc",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,120,357.5", "lineto,250,357.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,253,357.5", "lineto,256,357.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,376,205.5", "lineto,444,205.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,163.5", "lineto,221,163.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#1154cc",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,410,122.5", "lineto,539,122.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,84.5", "lineto,353,84.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "8",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.67",
                        height: "10.54",
                        left: "95.21",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "review,",
                        width: "34.86",
                        height: "10.54",
                        left: "119.63",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "correct,",
                        width: "36.31",
                        height: "10.54",
                        left: "158.24",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "198.3",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "delete",
                        width: "28.64",
                        height: "10.54",
                        left: "219.38",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "251.77",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "288.16",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "348.57",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "378.98",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "400.73",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "logging",
                        width: "36.67",
                        height: "10.54",
                        left: "416.48",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "456.9",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "469.98",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "483.06",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "501.47",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "navigating",
                        width: "50.65",
                        height: "10.54",
                        left: "94.58",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "150.48",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "165.06",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Account",
                        width: "40.65",
                        height: "10.54",
                        left: "192.31",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "page",
                        width: "22.66",
                        height: "10.54",
                        left: "238.21",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "266.12",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: '"Your',
                        width: "28.36",
                        height: "10.54",
                        left: "280.7",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: 'Dashboard."',
                        width: "59.88",
                        height: "10.54",
                        left: "314.31",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "379.44",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "must",
                        width: "23.33",
                        height: "10.54",
                        left: "404.16",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "promptly",
                        width: "44",
                        height: "10.54",
                        left: "432.74",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "update",
                        width: "31.99",
                        height: "10.54",
                        left: "481.24",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "517.73",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "113.06",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "if",
                        width: "7.33",
                        height: "10.54",
                        left: "173.47",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "6.66",
                        height: "10.54",
                        left: "184.55",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "changes",
                        width: "38.65",
                        height: "10.54",
                        left: "194.96",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "236.61",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "249.61",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "inaccurate.",
                        width: "52.3",
                        height: "10.54",
                        left: "260.61",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Upon",
                        width: "26.66",
                        height: "10.54",
                        left: "315.91",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "345.57",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "request,",
                        width: "37.65",
                        height: "10.54",
                        left: "370.57",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "411.22",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.54",
                        left: "428.21",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "close",
                        width: "24.66",
                        height: "10.54",
                        left: "449.88",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "477.54",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "502.54",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "72",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "remove",
                        width: "35.98",
                        height: "10.55",
                        left: "93.83",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.55",
                        left: "134.31",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "profile",
                        width: "31.99",
                        height: "10.55",
                        left: "160.8",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.55",
                        left: "197.29",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.32",
                        height: "10.55",
                        left: "258.45",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "view",
                        width: "23.33",
                        height: "10.55",
                        left: "285.52",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "9.99",
                        height: "10.55",
                        left: "312.6",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "soon",
                        width: "22.67",
                        height: "10.55",
                        left: "326.34",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10",
                        height: "10.55",
                        left: "352.76",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reasonably",
                        width: "51.98",
                        height: "10.55",
                        left: "366.51",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "possible.",
                        width: "42.33",
                        height: "10.55",
                        left: "422.24",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.55",
                        left: "468.32",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.55",
                        left: "487.76",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "retain",
                        width: "27.32",
                        height: "10.55",
                        left: "512.17",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "72",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "132.4",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "closed",
                        width: "30.66",
                        height: "10.54",
                        left: "159.48",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accounts",
                        width: "41.98",
                        height: "10.54",
                        left: "193.14",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "238.12",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "order",
                        width: "25.32",
                        height: "10.54",
                        left: "250.45",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "278.77",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "comply",
                        width: "35.99",
                        height: "10.54",
                        left: "291.11",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "330.1",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "354.43",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law,",
                        width: "19.55",
                        height: "10.54",
                        left: "372.09",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prevent",
                        width: "35.98",
                        height: "10.54",
                        left: "394.64",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fraud,",
                        width: "28.32",
                        height: "10.54",
                        left: "433.62",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "31.98",
                        height: "10.54",
                        left: "464.94",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "499.92",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fees",
                        width: "19.32",
                        height: "10.54",
                        left: "520.25",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "owed,",
                        width: "28.99",
                        height: "10.54",
                        left: "72",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "resolve",
                        width: "34.65",
                        height: "10.54",
                        left: "105.49",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disputes,",
                        width: "42.34",
                        height: "10.54",
                        left: "144.64",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "troubleshoot",
                        width: "59.99",
                        height: "10.54",
                        left: "191.48",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "problems,",
                        width: "47.66",
                        height: "10.54",
                        left: "255.97",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "assist",
                        width: "26.01",
                        height: "10.54",
                        left: "307.38",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "337.14",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "362.22",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "investigations",
                        width: "66.66",
                        height: "10.54",
                        left: "383.3",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "453.71",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.32",
                        height: "10.54",
                        left: "467.46",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "488.53",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user,",
                        width: "22.51",
                        height: "10.54",
                        left: "516.94",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "enforce",
                        width: "35.97",
                        height: "10.54",
                        left: "72",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "113.22",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "134.47",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "169.54",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use,",
                        width: "21.66",
                        height: "10.54",
                        left: "184.78",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and/or",
                        width: "30.66",
                        height: "10.54",
                        left: "211.69",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "246.85",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "265.34",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.65",
                        height: "10.54",
                        left: "287.17",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes",
                        width: "42.67",
                        height: "10.54",
                        left: "316.32",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "otherwise",
                        width: "46.65",
                        height: "10.54",
                        left: "363.49",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "permitted",
                        width: "45.98",
                        height: "10.54",
                        left: "414.64",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "465.12",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law",
                        width: "17.33",
                        height: "10.54",
                        left: "481.62",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "17.99",
                        height: "10.54",
                        left: "503.45",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "525.94",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "deem",
                        width: "25.99",
                        height: "10.54",
                        left: "72",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "necessary",
                        width: "46.64",
                        height: "10.54",
                        left: "102.49",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "153.63",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "167.46",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sole",
                        width: "19.33",
                        height: "10.54",
                        left: "187.21",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion.",
                        width: "50.32",
                        height: "10.54",
                        left: "210.29",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.46",
                        height: "10.54",
                        left: "264.36",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "should",
                        width: "32.01",
                        height: "10.54",
                        left: "287.57",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "understand,",
                        width: "55.65",
                        height: "10.54",
                        left: "323.33",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "however,",
                        width: "43.83",
                        height: "10.54",
                        left: "382.73",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.54",
                        left: "430.31",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "once",
                        width: "22.65",
                        height: "10.54",
                        left: "452.06",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "478.46",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transmit",
                        width: "39.33",
                        height: "10.54",
                        left: "500.21",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "72",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "38",
                        height: "10.54",
                        left: "99.16",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "141.66",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "183.49",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.35",
                        height: "10.54",
                        left: "202.65",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "248.5",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "271",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.34",
                        height: "10.54",
                        left: "296.16",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.32",
                        height: "10.54",
                        left: "316",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "able",
                        width: "19.99",
                        height: "10.54",
                        left: "331.82",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "355.56",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "change",
                        width: "33.98",
                        height: "10.54",
                        left: "368.64",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "406.37",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "remove",
                        width: "35.98",
                        height: "10.54",
                        left: "420.12",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it.",
                        width: "9.67",
                        height: "10.54",
                        left: "459.85",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "16.67",
                        height: "10.54",
                        left: "477.02",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "instance,",
                        width: "42.31",
                        height: "10.54",
                        left: "497.44",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "copies",
                        width: "30.66",
                        height: "10.54",
                        left: "72",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "107.16",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "121.65",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "147.4",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "37.99",
                        height: "10.54",
                        left: "173.81",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "215.55",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "remain",
                        width: "33.32",
                        height: "10.54",
                        left: "239.96",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "viewable",
                        width: "43.31",
                        height: "10.54",
                        left: "277.03",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.34",
                        height: "10.54",
                        left: "324.09",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cached",
                        width: "33.3",
                        height: "10.54",
                        left: "337.18",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "374.23",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "archived",
                        width: "41.31",
                        height: "10.54",
                        left: "395.31",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pages",
                        width: "27.32",
                        height: "10.54",
                        left: "440.37",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "471.44",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "might",
                        width: "28",
                        height: "10.54",
                        left: "485.19",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "516.94",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "been",
                        width: "22.65",
                        height: "10.54",
                        left: "72",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "copied",
                        width: "31.99",
                        height: "10.54",
                        left: "99.15",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "135.64",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "stored",
                        width: "29.33",
                        height: "10.54",
                        left: "150.13",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "183.96",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "24.66",
                        height: "10.54",
                        left: "200.46",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users.",
                        width: "27.66",
                        height: "10.54",
                        left: "229.62",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Once",
                        width: "25.32",
                        height: "10.54",
                        left: "261.03",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "290.1",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "307.84",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "deactivated",
                        width: "54.64",
                        height: "10.54",
                        left: "334.24",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "9.99",
                        height: "10.54",
                        left: "392.63",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "removed",
                        width: "41.98",
                        height: "10.54",
                        left: "406.37",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "452.1",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account,",
                        width: "40.31",
                        height: "10.54",
                        left: "477.85",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "521.91",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "agree",
                        width: "25.97",
                        height: "10.55",
                        left: "72",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "18",
                        height: "10.55",
                        left: "102.47",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.55",
                        left: "124.97",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.67",
                        height: "10.55",
                        left: "143.46",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.55",
                        left: "166.63",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.55",
                        left: "186.46",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsible",
                        width: "54.66",
                        height: "10.55",
                        left: "202.29",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "261.45",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "275.28",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.55",
                        left: "297.78",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "retaining",
                        width: "42.65",
                        height: "10.55",
                        left: "316.27",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.55",
                        left: "363.42",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "related",
                        width: "32.64",
                        height: "10.55",
                        left: "423.83",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "460.22",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.55",
                        left: "473.3",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account.",
                        width: "40.31",
                        height: "10.55",
                        left: "499.05",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Proper",
                        width: "31.99",
                        height: "10.54",
                        left: "72",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.65",
                        height: "10.54",
                        left: "108.49",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "142.89",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16",
                        height: "10.54",
                        left: "163.96",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "183.71",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "197.46",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provided",
                        width: "42.66",
                        height: "10.54",
                        left: "257.86",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "304.27",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "320.02",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.35",
                        height: "10.54",
                        left: "338.43",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "45.33",
                        height: "10.54",
                        left: "383.53",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "22.66",
                        height: "10.54",
                        left: "432.61",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contributions,",
                        width: "69",
                        height: "10.54",
                        left: "459.02",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "531.77",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "governed",
                        width: "44.65",
                        height: "10.54",
                        left: "72",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "119.65",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "134.65",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "153.43",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "186.25",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.66",
                        height: "10.54",
                        left: "199.24",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(located",
                        width: "38.65",
                        height: "10.54",
                        left: "220.9",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "262.55",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "https://mooreexotics.com/conditions",
                        width: "174.98",
                        height: "10.54",
                        left: "274.21",
                        top: "526.82",
                        color: "#1154cc",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                        link: "https://mooreexotics.com/conditions",
                      },
                      {
                        text: ").",
                        width: "6.99",
                        height: "10.54",
                        left: "452.19",
                        top: "526.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Your",
                        width: "31.27",
                        height: "14.05",
                        left: "72",
                        top: "494.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "California",
                        width: "64.86",
                        height: "14.05",
                        left: "107.27",
                        top: "494.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "48.87",
                        height: "14.05",
                        left: "176.12",
                        top: "494.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Rights",
                        width: "41.79",
                        height: "14.05",
                        left: "228.98",
                        top: "494.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "If",
                        width: "8.67",
                        height: "10.54",
                        left: "72",
                        top: "466.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18.67",
                        height: "10.54",
                        left: "85.92",
                        top: "466.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "16.44",
                        height: "10.54",
                        left: "109.84",
                        top: "466.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "6",
                        height: "10.54",
                        left: "131.53",
                        top: "466.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "California",
                        width: "52.66",
                        height: "10.54",
                        left: "142.78",
                        top: "466.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "resident,",
                        width: "44.11",
                        height: "10.54",
                        left: "200.69",
                        top: "466.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "California",
                        width: "52.66",
                        height: "10.54",
                        left: "250.05",
                        top: "466.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law",
                        width: "18",
                        height: "10.54",
                        left: "307.96",
                        top: "466.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "22",
                        height: "10.54",
                        left: "331.21",
                        top: "466.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "39.12",
                        height: "10.54",
                        left: "358.46",
                        top: "466.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18.67",
                        height: "10.54",
                        left: "402.83",
                        top: "466.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "22.67",
                        height: "10.54",
                        left: "426",
                        top: "466.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "additional",
                        width: "52.02",
                        height: "10.54",
                        left: "453.17",
                        top: "466.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "rights",
                        width: "30",
                        height: "10.54",
                        left: "509.69",
                        top: "466.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "regarding",
                        width: "50.44",
                        height: "10.54",
                        left: "72",
                        top: "452.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "125.44",
                        top: "452.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16.67",
                        height: "10.54",
                        left: "146.44",
                        top: "452.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "166.11",
                        top: "452.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "24",
                        height: "10.54",
                        left: "179.11",
                        top: "452.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "44",
                        height: "10.54",
                        left: "206.11",
                        top: "452.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information.",
                        width: "64.33",
                        height: "10.54",
                        left: "253.11",
                        top: "452.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "California",
                        width: "52.66",
                        height: "10.54",
                        left: "72",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Online",
                        width: "34.68",
                        height: "10.54",
                        left: "132.91",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "39.32",
                        height: "10.54",
                        left: "175.84",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Protection",
                        width: "53.09",
                        height: "10.54",
                        left: "223.41",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Act,",
                        width: "20.99",
                        height: "10.54",
                        left: "284.75",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Cal.",
                        width: "21",
                        height: "10.54",
                        left: "313.24",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Bus.",
                        width: "22.34",
                        height: "10.54",
                        left: "341.74",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "&",
                        width: "10",
                        height: "10.54",
                        left: "371.58",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Prof.",
                        width: "25.44",
                        height: "10.54",
                        left: "389.08",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Code",
                        width: "26.66",
                        height: "10.54",
                        left: "422.02",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "§§",
                        width: "12",
                        height: "10.54",
                        left: "456.18",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "22575­22579",
                        width: "64",
                        height: "10.54",
                        left: "475.68",
                        top: "425.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "(“CalOPPA”).",
                        width: "72.76",
                        height: "10.54",
                        left: "72",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CalOPPA",
                        width: "49.77",
                        height: "10.54",
                        left: "147.76",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "requires",
                        width: "42.22",
                        height: "10.54",
                        left: "201.28",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "commercial",
                        width: "59.75",
                        height: "10.54",
                        left: "246.5",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "websites",
                        width: "42.66",
                        height: "10.54",
                        left: "309.25",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "354.91",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "online",
                        width: "31.34",
                        height: "10.54",
                        left: "377.26",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "39.98",
                        height: "10.54",
                        left: "411.6",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "454.58",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "post",
                        width: "21.34",
                        height: "10.54",
                        left: "467.58",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "6",
                        height: "10.54",
                        left: "491.92",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "privacy",
                        width: "38.66",
                        height: "10.54",
                        left: "500.92",
                        top: "411.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "policy.",
                        width: "33.01",
                        height: "10.54",
                        left: "72",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Among",
                        width: "37.33",
                        height: "10.54",
                        left: "109.51",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "27.32",
                        height: "10.54",
                        left: "151.34",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "things,",
                        width: "34.35",
                        height: "10.54",
                        left: "183.16",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "222.01",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "law",
                        width: "18",
                        height: "10.54",
                        left: "242.51",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "requires",
                        width: "42.22",
                        height: "10.54",
                        left: "265.01",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "businesses",
                        width: "52.69",
                        height: "10.54",
                        left: "311.73",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "20.66",
                        height: "10.54",
                        left: "368.92",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "operate",
                        width: "38.65",
                        height: "10.54",
                        left: "394.08",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "websites",
                        width: "42.66",
                        height: "10.54",
                        left: "436.48",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "20.67",
                        height: "10.54",
                        left: "482.89",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "32.64",
                        height: "10.54",
                        left: "507.31",
                        top: "397.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "personally",
                        width: "53.34",
                        height: "10.54",
                        left: "72",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identifiable",
                        width: "58",
                        height: "10.54",
                        left: "129.84",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "192.34",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "25.1",
                        height: "10.54",
                        left: "258.17",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "California",
                        width: "52.67",
                        height: "10.54",
                        left: "287.77",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "consumers",
                        width: "54.66",
                        height: "10.54",
                        left: "344.94",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to:",
                        width: "13.99",
                        height: "10.54",
                        left: "404.1",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(a)",
                        width: "13.99",
                        height: "10.54",
                        left: "422.59",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "post",
                        width: "21.34",
                        height: "10.54",
                        left: "441.08",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "6",
                        height: "10.54",
                        left: "466.92",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "conspicuous",
                        width: "62.02",
                        height: "10.54",
                        left: "477.42",
                        top: "383.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "privacy",
                        width: "38.66",
                        height: "10.54",
                        left: "72",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "policy",
                        width: "30.67",
                        height: "10.54",
                        left: "114.41",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12.67",
                        height: "10.54",
                        left: "148.83",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "its",
                        width: "12",
                        height: "10.54",
                        left: "165.25",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "website",
                        width: "37.99",
                        height: "10.54",
                        left: "180.25",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "stating",
                        width: "34.67",
                        height: "10.54",
                        left: "221.24",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "exactly",
                        width: "35.99",
                        height: "10.54",
                        left: "258.91",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "297.9",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "316.89",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "being",
                        width: "28.01",
                        height: "10.54",
                        left: "381.22",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collected;",
                        width: "48.64",
                        height: "10.54",
                        left: "412.23",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(b)",
                        width: "14.67",
                        height: "10.54",
                        left: "463.87",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identify",
                        width: "39.33",
                        height: "10.54",
                        left: "481.54",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "523.87",
                        top: "369.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "third",
                        width: "26",
                        height: "10.54",
                        left: "72",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parties",
                        width: "35.33",
                        height: "10.54",
                        left: "104.75",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "22.67",
                        height: "10.54",
                        left: "146.83",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "whom",
                        width: "31.34",
                        height: "10.54",
                        left: "176.25",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "23.34",
                        height: "10.54",
                        left: "214.34",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "244.43",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "22",
                        height: "10.54",
                        left: "312.51",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "12",
                        height: "10.54",
                        left: "341.26",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shared;",
                        width: "38.45",
                        height: "10.54",
                        left: "360.01",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.34",
                        height: "10.54",
                        left: "405.21",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(c)",
                        width: "13.32",
                        height: "10.54",
                        left: "431.3",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "comply",
                        width: "37.33",
                        height: "10.54",
                        left: "451.37",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "22.67",
                        height: "10.54",
                        left: "494.7",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "523.37",
                        top: "356.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "business’s",
                        width: "50.91",
                        height: "10.54",
                        left: "72",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "written",
                        width: "37.32",
                        height: "10.54",
                        left: "128.91",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "privacy",
                        width: "38.66",
                        height: "10.54",
                        left: "172.23",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "policy.",
                        width: "33.01",
                        height: "10.54",
                        left: "216.89",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "This",
                        width: "22.68",
                        height: "10.54",
                        left: "261.9",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "39.31",
                        height: "10.54",
                        left: "290.58",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "31.33",
                        height: "10.54",
                        left: "335.89",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "373.22",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "intended",
                        width: "44.68",
                        height: "10.54",
                        left: "387.22",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "437.9",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "comply",
                        width: "37.33",
                        height: "10.54",
                        left: "453.15",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "22.67",
                        height: "10.54",
                        left: "495.73",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "523.65",
                        top: "342.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "requirements",
                        width: "68.21",
                        height: "10.54",
                        left: "72",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "143.21",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "CalOPPA.",
                        width: "52.77",
                        height: "10.54",
                        left: "156.21",
                        top: "328.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "As",
                        width: "13.34",
                        height: "10.54",
                        left: "72",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "used",
                        width: "23.34",
                        height: "10.54",
                        left: "89.09",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "herein,",
                        width: "35.44",
                        height: "10.54",
                        left: "116.18",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "“personally",
                        width: "59.34",
                        height: "10.54",
                        left: "154.62",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identifiable",
                        width: "58",
                        height: "10.54",
                        left: "216.96",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information”",
                        width: "67.33",
                        height: "10.54",
                        left: "277.96",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "has",
                        width: "17.35",
                        height: "10.54",
                        left: "348.29",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "368.64",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "meaning",
                        width: "44.01",
                        height: "10.54",
                        left: "387.63",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "set",
                        width: "13.99",
                        height: "10.54",
                        left: "434.64",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "forth",
                        width: "25.99",
                        height: "10.54",
                        left: "451.63",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10.01",
                        height: "10.54",
                        left: "480.62",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Cal.",
                        width: "21",
                        height: "10.54",
                        left: "493.63",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Bus.",
                        width: "22.35",
                        height: "10.54",
                        left: "517.63",
                        top: "300.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "&",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Prof.",
                        width: "25.43",
                        height: "10.54",
                        left: "86.5",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Code",
                        width: "26.67",
                        height: "10.54",
                        left: "116.43",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "§",
                        width: "6",
                        height: "10.54",
                        left: "147.6",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "22577(a),",
                        width: "46.99",
                        height: "10.54",
                        left: "158.1",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which,",
                        width: "33.67",
                        height: "10.54",
                        left: "209.59",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10.67",
                        height: "10.54",
                        left: "247.76",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "262.93",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "277.43",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "date",
                        width: "21.99",
                        height: "10.54",
                        left: "297.93",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "323.67",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "18.67",
                        height: "10.54",
                        left: "337.42",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "39.32",
                        height: "10.54",
                        left: "359.84",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy,",
                        width: "33.66",
                        height: "10.54",
                        left: "402.91",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "defines",
                        width: "36",
                        height: "10.54",
                        left: "440.32",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "“personally",
                        width: "59.34",
                        height: "10.54",
                        left: "480.07",
                        top: "286.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "identifiable",
                        width: "58",
                        height: "10.54",
                        left: "72",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information”",
                        width: "67.33",
                        height: "10.54",
                        left: "137.5",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10.67",
                        height: "10.54",
                        left: "212.33",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "individually",
                        width: "61.37",
                        height: "10.54",
                        left: "230.5",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identifiable",
                        width: "58",
                        height: "10.54",
                        left: "299.37",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "364.12",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "29.34",
                        height: "10.54",
                        left: "432.2",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "12.68",
                        height: "10.54",
                        left: "468.29",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "individual",
                        width: "52.03",
                        height: "10.54",
                        left: "487.72",
                        top: "273.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "consumer",
                        width: "49.99",
                        height: "10.55",
                        left: "72",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collected",
                        width: "44.64",
                        height: "10.55",
                        left: "127.99",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "online",
                        width: "31.35",
                        height: "10.55",
                        left: "178.63",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12.67",
                        height: "10.55",
                        left: "215.23",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.55",
                        left: "233.15",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "operator",
                        width: "44.64",
                        height: "10.55",
                        left: "254.4",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "25.11",
                        height: "10.55",
                        left: "304.29",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "20.66",
                        height: "10.55",
                        left: "334.65",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "individual",
                        width: "52.03",
                        height: "10.55",
                        left: "360.56",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.55",
                        left: "417.84",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "maintained",
                        width: "58.01",
                        height: "10.55",
                        left: "442.44",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12.67",
                        height: "10.55",
                        left: "505.7",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.55",
                        left: "523.62",
                        top: "259.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "operator",
                        width: "44.65",
                        height: "10.54",
                        left: "72",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10.01",
                        height: "10.54",
                        left: "120.4",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "12.67",
                        height: "10.54",
                        left: "134.16",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accessible",
                        width: "49.99",
                        height: "10.54",
                        left: "150.58",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "form,",
                        width: "28.31",
                        height: "10.54",
                        left: "204.32",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "48.03",
                        height: "10.54",
                        left: "236.38",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "18.67",
                        height: "10.54",
                        left: "288.16",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "310.58",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "323.58",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "following:",
                        width: "51.34",
                        height: "10.54",
                        left: "342.57",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(1)",
                        width: "13.99",
                        height: "10.54",
                        left: "396.91",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "first",
                        width: "21.32",
                        height: "10.54",
                        left: "413.9",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "438.22",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "last",
                        width: "18",
                        height: "10.54",
                        left: "460.57",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "name;",
                        width: "31.99",
                        height: "10.54",
                        left: "481.57",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(2)",
                        width: "13.99",
                        height: "10.54",
                        left: "516.56",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "6",
                        height: "10.54",
                        left: "533.55",
                        top: "245.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "home",
                        width: "28",
                        height: "10.54",
                        left: "72",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.32",
                        height: "10.54",
                        left: "104.5",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "27.32",
                        height: "10.54",
                        left: "120.32",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "physical",
                        width: "42.02",
                        height: "10.54",
                        left: "152.14",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address,",
                        width: "42.12",
                        height: "10.54",
                        left: "198.66",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "48.02",
                        height: "10.54",
                        left: "244.53",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "street",
                        width: "28.43",
                        height: "10.54",
                        left: "296.3",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "name",
                        width: "27.99",
                        height: "10.54",
                        left: "328.48",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "360.22",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "name",
                        width: "28",
                        height: "10.54",
                        left: "383.32",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "415.07",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "6",
                        height: "10.54",
                        left: "428.81",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "city",
                        width: "18.66",
                        height: "10.54",
                        left: "438.56",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.32",
                        height: "10.54",
                        left: "460.97",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "town;",
                        width: "29.34",
                        height: "10.54",
                        left: "476.04",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(3)",
                        width: "13.99",
                        height: "10.54",
                        left: "509.13",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "12.67",
                        height: "10.54",
                        left: "526.87",
                        top: "231.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "e­mail",
                        width: "31.99",
                        height: "10.54",
                        left: "72",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address;",
                        width: "43.12",
                        height: "10.54",
                        left: "109.24",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(4)",
                        width: "13.99",
                        height: "10.54",
                        left: "157.61",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "6",
                        height: "10.54",
                        left: "174.6",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "telephone",
                        width: "49.33",
                        height: "10.54",
                        left: "185.85",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "number;",
                        width: "44.66",
                        height: "10.54",
                        left: "240.43",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(5)",
                        width: "14",
                        height: "10.54",
                        left: "290.34",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "6",
                        height: "10.54",
                        left: "307.34",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "social",
                        width: "28.66",
                        height: "10.54",
                        left: "318.59",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "security",
                        width: "40.65",
                        height: "10.54",
                        left: "352.5",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "number;",
                        width: "44.67",
                        height: "10.54",
                        left: "397.65",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "446.82",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(6)",
                        width: "13.99",
                        height: "10.54",
                        left: "470.67",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "18.67",
                        height: "10.54",
                        left: "489.16",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "27.32",
                        height: "10.54",
                        left: "512.33",
                        top: "217.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "identifier",
                        width: "47.32",
                        height: "10.54",
                        left: "72",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "20.67",
                        height: "10.54",
                        left: "122.32",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "permits",
                        width: "39.32",
                        height: "10.54",
                        left: "145.99",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "188.31",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "physical",
                        width: "42.02",
                        height: "10.54",
                        left: "207.3",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.32",
                        height: "10.54",
                        left: "252.32",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "online",
                        width: "31.34",
                        height: "10.54",
                        left: "266.64",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contacting",
                        width: "53.33",
                        height: "10.54",
                        left: "300.98",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "357.31",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "6",
                        height: "10.54",
                        left: "370.31",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "specific",
                        width: "37.98",
                        height: "10.54",
                        left: "379.31",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "individual.",
                        width: "55.03",
                        height: "10.54",
                        left: "420.29",
                        top: "204.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Personally",
                        width: "53.99",
                        height: "10.54",
                        left: "72",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Identifiable",
                        width: "59.34",
                        height: "10.54",
                        left: "129.74",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "62.67",
                        height: "10.54",
                        left: "192.08",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "16.66",
                        height: "10.54",
                        left: "257.75",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Collect.",
                        width: "38.98",
                        height: "10.54",
                        left: "277.41",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "As",
                        width: "13.34",
                        height: "10.54",
                        left: "322.39",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "further",
                        width: "37.32",
                        height: "10.54",
                        left: "338.73",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "described",
                        width: "49.33",
                        height: "10.54",
                        left: "379.05",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10.01",
                        height: "10.54",
                        left: "431.38",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "18.67",
                        height: "10.54",
                        left: "444.39",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "39.32",
                        height: "10.54",
                        left: "466.06",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "31.32",
                        height: "10.54",
                        left: "508.38",
                        top: "176.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "above,",
                        width: "33",
                        height: "10.54",
                        left: "72",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10.01",
                        height: "10.54",
                        left: "108.75",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "122.51",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "preceding",
                        width: "50.45",
                        height: "10.54",
                        left: "142.25",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "twelve",
                        width: "32.65",
                        height: "10.54",
                        left: "196.45",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "months,",
                        width: "41.01",
                        height: "10.54",
                        left: "232.85",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "277.61",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.32",
                        height: "10.54",
                        left: "295.35",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "310.42",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "35.31",
                        height: "10.54",
                        left: "332.17",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providers",
                        width: "49.12",
                        height: "10.54",
                        left: "371.23",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "424.1",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "21.99",
                        height: "10.54",
                        left: "441.84",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "24",
                        height: "10.54",
                        left: "467.58",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collected",
                        width: "44.65",
                        height: "10.54",
                        left: "495.33",
                        top: "162.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "72",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "following",
                        width: "47.33",
                        height: "10.54",
                        left: "91",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "classes",
                        width: "51.31",
                        height: "10.54",
                        left: "141.33",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "195.64",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personally",
                        width: "53.34",
                        height: "10.54",
                        left: "208.63",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identifiable",
                        width: "58",
                        height: "10.54",
                        left: "264.97",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information:",
                        width: "65.33",
                        height: "10.54",
                        left: "325.97",
                        top: "148.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.14",
                        left: "90",
                        top: "120.84",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Name",
                        width: "29.33",
                        height: "10.54",
                        left: "108",
                        top: "120.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "18.67",
                        height: "10.54",
                        left: "141.83",
                        top: "120.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Contact",
                        width: "38.67",
                        height: "10.54",
                        left: "165",
                        top: "120.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "60.69",
                        height: "10.54",
                        left: "208.17",
                        top: "120.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "268.86",
                        top: "120.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "This",
                        width: "22.68",
                        height: "10.54",
                        left: "280.86",
                        top: "120.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "22",
                        height: "10.54",
                        left: "308.04",
                        top: "120.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "include",
                        width: "37.34",
                        height: "10.54",
                        left: "334.54",
                        top: "120.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "24",
                        height: "10.54",
                        left: "376.38",
                        top: "120.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "first",
                        width: "21.32",
                        height: "10.54",
                        left: "404.13",
                        top: "120.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "429.2",
                        top: "120.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "last",
                        width: "18",
                        height: "10.54",
                        left: "452.3",
                        top: "120.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "name",
                        width: "27.99",
                        height: "10.54",
                        left: "474.05",
                        top: "120.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and/or",
                        width: "34.01",
                        height: "10.54",
                        left: "505.79",
                        top: "120.79",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "alias;",
                        width: "27.33",
                        height: "10.54",
                        left: "108",
                        top: "106.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "24",
                        height: "10.54",
                        left: "139.08",
                        top: "106.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "home",
                        width: "28",
                        height: "10.54",
                        left: "166.08",
                        top: "106.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "197.08",
                        top: "106.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "27.32",
                        height: "10.54",
                        left: "211.41",
                        top: "106.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "physical",
                        width: "42.01",
                        height: "10.54",
                        left: "241.73",
                        top: "106.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address,",
                        width: "42.12",
                        height: "10.54",
                        left: "286.74",
                        top: "106.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "48.03",
                        height: "10.54",
                        left: "331.86",
                        top: "106.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "street",
                        width: "28.42",
                        height: "10.54",
                        left: "382.89",
                        top: "106.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "name",
                        width: "28",
                        height: "10.54",
                        left: "414.31",
                        top: "106.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.34",
                        height: "10.54",
                        left: "445.31",
                        top: "106.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "name",
                        width: "28",
                        height: "10.54",
                        left: "467.65",
                        top: "106.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "498.65",
                        top: "106.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "6",
                        height: "10.54",
                        left: "511.65",
                        top: "106.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "city",
                        width: "18.65",
                        height: "10.54",
                        left: "520.65",
                        top: "106.99",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "108",
                        top: "93.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "town,",
                        width: "28.33",
                        height: "10.54",
                        left: "124.58",
                        top: "93.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "158.16",
                        top: "93.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "27.32",
                        height: "10.54",
                        left: "174.74",
                        top: "93.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "postal",
                        width: "30.67",
                        height: "10.54",
                        left: "206.56",
                        top: "93.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address;",
                        width: "43.12",
                        height: "10.54",
                        left: "241.73",
                        top: "93.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "online",
                        width: "31.35",
                        height: "10.54",
                        left: "289.35",
                        top: "93.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identifiers,",
                        width: "54.99",
                        height: "10.54",
                        left: "325.2",
                        top: "93.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "48.02",
                        height: "10.54",
                        left: "384.69",
                        top: "93.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "usernames,",
                        width: "57.66",
                        height: "10.54",
                        left: "437.21",
                        top: "93.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "40",
                        height: "10.54",
                        left: "499.37",
                        top: "93.19",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "names,",
                        width: "35.67",
                        height: "10.55",
                        left: "108",
                        top: "79.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.34",
                        height: "10.55",
                        left: "149.67",
                        top: "79.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "27.99",
                        height: "10.55",
                        left: "175.01",
                        top: "79.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "addresses;",
                        width: "53.12",
                        height: "10.55",
                        left: "208.25",
                        top: "79.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.55",
                        left: "266.62",
                        top: "79.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "telephone",
                        width: "49.33",
                        height: "10.55",
                        left: "291.22",
                        top: "79.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "numbers.",
                        width: "48.34",
                        height: "10.55",
                        left: "345.8",
                        top: "79.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "20.01",
                        height: "10.55",
                        left: "399.39",
                        top: "79.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "might",
                        width: "30",
                        height: "10.55",
                        left: "424.65",
                        top: "79.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "39.11",
                        height: "10.55",
                        left: "459.9",
                        top: "79.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "11.35",
                        height: "10.55",
                        left: "504.26",
                        top: "79.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "18.67",
                        height: "10.55",
                        left: "520.86",
                        top: "79.39",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#1154cc",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,274,528.5", "lineto,449,528.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,496.5", "lineto,271,496.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,426.5", "lineto,540,426.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,412.5", "lineto,142,412.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,177.5", "lineto,313,177.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "9",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "information,",
                        width: "64.33",
                        height: "10.54",
                        left: "108",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "whether",
                        width: "41.99",
                        height: "10.54",
                        left: "177.58",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "41.13",
                        height: "10.54",
                        left: "224.82",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "24",
                        height: "10.54",
                        left: "271.2",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16.67",
                        height: "10.54",
                        left: "299.7",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "320.87",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "335.36",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services,",
                        width: "42.98",
                        height: "10.54",
                        left: "357.86",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "registering",
                        width: "55.1",
                        height: "10.54",
                        left: "405.34",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "12.67",
                        height: "10.54",
                        left: "464.94",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "40",
                        height: "10.54",
                        left: "482.11",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12.67",
                        height: "10.54",
                        left: "526.61",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "108",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "43.66",
                        height: "10.54",
                        left: "129.75",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12.68",
                        height: "10.54",
                        left: "177.16",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "completing",
                        width: "56.66",
                        height: "10.54",
                        left: "193.59",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "6",
                        height: "10.54",
                        left: "254",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "form",
                        width: "25.32",
                        height: "10.54",
                        left: "263.75",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12.67",
                        height: "10.54",
                        left: "292.82",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "309.24",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "43.66",
                        height: "10.54",
                        left: "330.24",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "41.13",
                        height: "10.54",
                        left: "376.9",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "12.68",
                        height: "10.54",
                        left: "421.03",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interaction",
                        width: "55.98",
                        height: "10.54",
                        left: "436.71",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "22.67",
                        height: "10.54",
                        left: "495.69",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "521.36",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "support",
                        width: "40.01",
                        height: "10.54",
                        left: "108",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "team,",
                        width: "28.32",
                        height: "10.54",
                        left: "151.01",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "182.33",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10.01",
                        height: "10.54",
                        left: "196.66",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "response",
                        width: "44.45",
                        height: "10.54",
                        left: "209.67",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.99",
                        height: "10.54",
                        left: "257.12",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "one",
                        width: "18",
                        height: "10.54",
                        left: "270.11",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "291.11",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "304.11",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "surveys.",
                        width: "41.66",
                        height: "10.54",
                        left: "325.11",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.14",
                        left: "90",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Financial",
                        width: "48.68",
                        height: "10.54",
                        left: "108",
                        top: "664.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "60.69",
                        height: "10.54",
                        left: "163.43",
                        top: "664.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(such",
                        width: "27.34",
                        height: "10.54",
                        left: "230.87",
                        top: "664.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10.67",
                        height: "10.54",
                        left: "264.96",
                        top: "664.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "payment",
                        width: "41.99",
                        height: "10.54",
                        left: "282.38",
                        top: "664.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information)",
                        width: "63.34",
                        height: "10.54",
                        left: "331.12",
                        top: "664.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "394.46",
                        top: "664.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "This",
                        width: "22.69",
                        height: "10.54",
                        left: "410.96",
                        top: "664.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "21.99",
                        height: "10.54",
                        left: "440.4",
                        top: "664.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "include",
                        width: "37.34",
                        height: "10.54",
                        left: "469.14",
                        top: "664.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "bank",
                        width: "26.03",
                        height: "10.54",
                        left: "513.23",
                        top: "664.75",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "account",
                        width: "40",
                        height: "10.54",
                        left: "108",
                        top: "650.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "number,",
                        width: "42.56",
                        height: "10.54",
                        left: "156.25",
                        top: "650.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "credit",
                        width: "29.77",
                        height: "10.54",
                        left: "207.06",
                        top: "650.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "card",
                        width: "23.33",
                        height: "10.54",
                        left: "245.08",
                        top: "650.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "number,",
                        width: "42.56",
                        height: "10.54",
                        left: "276.66",
                        top: "650.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "debit",
                        width: "26.01",
                        height: "10.54",
                        left: "326.72",
                        top: "650.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "card",
                        width: "23.32",
                        height: "10.54",
                        left: "360.23",
                        top: "650.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "number,",
                        width: "42.57",
                        height: "10.54",
                        left: "391.05",
                        top: "650.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "441.12",
                        top: "650.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "27.32",
                        height: "10.54",
                        left: "459.95",
                        top: "650.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "financial",
                        width: "44.67",
                        height: "10.54",
                        left: "494.77",
                        top: "650.95",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information.",
                        width: "64.33",
                        height: "10.55",
                        left: "108",
                        top: "637.15",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.13",
                        left: "90",
                        top: "623.36",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Usage",
                        width: "30.66",
                        height: "10.54",
                        left: "108",
                        top: "623.3",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "60.69",
                        height: "10.54",
                        left: "143.16",
                        top: "623.3",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "203.85",
                        top: "623.3",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "16.66",
                        height: "10.54",
                        left: "214.35",
                        top: "623.3",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "32.64",
                        height: "10.54",
                        left: "234.76",
                        top: "623.3",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "usage",
                        width: "28.67",
                        height: "10.54",
                        left: "271.15",
                        top: "623.3",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "303.57",
                        top: "623.3",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "29.35",
                        height: "10.54",
                        left: "368.65",
                        top: "623.3",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18.67",
                        height: "10.54",
                        left: "401.75",
                        top: "623.3",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "whenever",
                        width: "49.32",
                        height: "10.54",
                        left: "424.17",
                        top: "623.3",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18.67",
                        height: "10.54",
                        left: "477.24",
                        top: "623.3",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interact",
                        width: "39.98",
                        height: "10.54",
                        left: "499.66",
                        top: "623.3",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "with",
                        width: "22.67",
                        height: "10.54",
                        left: "108",
                        top: "609.5",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "135.92",
                        top: "609.5",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "40.66",
                        height: "10.54",
                        left: "159.17",
                        top: "609.5",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "205.08",
                        top: "609.5",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services.",
                        width: "42.98",
                        height: "10.54",
                        left: "228.93",
                        top: "609.5",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "This",
                        width: "22.68",
                        height: "10.54",
                        left: "276.41",
                        top: "609.5",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "includes",
                        width: "42.01",
                        height: "10.54",
                        left: "303.59",
                        top: "609.5",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "30.68",
                        height: "10.54",
                        left: "350.1",
                        top: "609.5",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "webpages",
                        width: "49.33",
                        height: "10.54",
                        left: "385.28",
                        top: "609.5",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18.68",
                        height: "10.54",
                        left: "439.11",
                        top: "609.5",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "visit,",
                        width: "24.33",
                        height: "10.54",
                        left: "462.29",
                        top: "609.5",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "what",
                        width: "25.34",
                        height: "10.54",
                        left: "491.12",
                        top: "609.5",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18.67",
                        height: "10.54",
                        left: "520.96",
                        top: "609.5",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "click",
                        width: "23.99",
                        height: "10.54",
                        left: "108",
                        top: "595.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on,",
                        width: "15.68",
                        height: "10.54",
                        left: "136.49",
                        top: "595.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "when",
                        width: "27.34",
                        height: "10.54",
                        left: "156.67",
                        top: "595.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18.67",
                        height: "10.54",
                        left: "188.51",
                        top: "595.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "perform",
                        width: "42.65",
                        height: "10.54",
                        left: "210.93",
                        top: "595.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "those",
                        width: "26.66",
                        height: "10.54",
                        left: "257.33",
                        top: "595.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "actions,",
                        width: "39",
                        height: "10.54",
                        left: "287.74",
                        top: "595.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "what",
                        width: "25.34",
                        height: "10.54",
                        left: "330.49",
                        top: "595.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "language",
                        width: "46.01",
                        height: "10.54",
                        left: "359.58",
                        top: "595.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "preference",
                        width: "54.19",
                        height: "10.54",
                        left: "409.34",
                        top: "595.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18.67",
                        height: "10.54",
                        left: "467.28",
                        top: "595.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have,",
                        width: "27",
                        height: "10.54",
                        left: "489.7",
                        top: "595.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "520.45",
                        top: "595.71",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "so",
                        width: "10.67",
                        height: "10.54",
                        left: "108",
                        top: "581.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on.",
                        width: "15.67",
                        height: "10.54",
                        left: "121.67",
                        top: "581.91",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.13",
                        left: "90",
                        top: "568.11",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Device",
                        width: "33.3",
                        height: "10.55",
                        left: "108",
                        top: "568.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "18.68",
                        height: "10.55",
                        left: "145.8",
                        top: "568.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Browser",
                        width: "41.34",
                        height: "10.55",
                        left: "168.98",
                        top: "568.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Data",
                        width: "24",
                        height: "10.55",
                        left: "214.82",
                        top: "568.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.55",
                        left: "238.82",
                        top: "568.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "16.67",
                        height: "10.55",
                        left: "250.82",
                        top: "568.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "32.64",
                        height: "10.55",
                        left: "271.99",
                        top: "568.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.55",
                        left: "309.13",
                        top: "568.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "25.1",
                        height: "10.55",
                        left: "374.96",
                        top: "568.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.55",
                        left: "403.81",
                        top: "568.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "device",
                        width: "31.98",
                        height: "10.55",
                        left: "423.56",
                        top: "568.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.55",
                        left: "459.29",
                        top: "568.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "application",
                        width: "57.35",
                        height: "10.55",
                        left: "482.39",
                        top: "568.05",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "you",
                        width: "18.67",
                        height: "10.54",
                        left: "108",
                        top: "554.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16.67",
                        height: "10.54",
                        left: "130.42",
                        top: "554.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "150.84",
                        top: "554.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "31.32",
                        height: "10.54",
                        left: "164.59",
                        top: "554.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "199.66",
                        top: "554.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "43.66",
                        height: "10.54",
                        left: "221.41",
                        top: "554.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Device",
                        width: "33.98",
                        height: "10.54",
                        left: "271.82",
                        top: "554.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "22.67",
                        height: "10.54",
                        left: "308.8",
                        top: "554.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "mainly",
                        width: "35.34",
                        height: "10.54",
                        left: "334.47",
                        top: "554.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "means",
                        width: "32.66",
                        height: "10.54",
                        left: "372.81",
                        top: "554.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18.68",
                        height: "10.54",
                        left: "408.47",
                        top: "554.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IP",
                        width: "12",
                        height: "10.54",
                        left: "430.15",
                        top: "554.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address,",
                        width: "42.12",
                        height: "10.54",
                        left: "445.15",
                        top: "554.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "operating",
                        width: "49.33",
                        height: "10.54",
                        left: "490.27",
                        top: "554.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "system",
                        width: "34.66",
                        height: "10.54",
                        left: "108",
                        top: "540.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "version,",
                        width: "40.33",
                        height: "10.54",
                        left: "148.66",
                        top: "540.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "device",
                        width: "31.98",
                        height: "10.54",
                        left: "194.99",
                        top: "540.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "type,",
                        width: "25",
                        height: "10.54",
                        left: "232.97",
                        top: "540.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "system",
                        width: "34.66",
                        height: "10.54",
                        left: "263.97",
                        top: "540.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "304.63",
                        top: "540.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "performance",
                        width: "65.97",
                        height: "10.54",
                        left: "329.98",
                        top: "540.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "64.33",
                        height: "10.54",
                        left: "401.95",
                        top: "540.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "472.28",
                        top: "540.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "browser",
                        width: "41.77",
                        height: "10.54",
                        left: "497.63",
                        top: "540.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "type.",
                        width: "25",
                        height: "10.54",
                        left: "108",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "8.66",
                        height: "10.54",
                        left: "139",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18.68",
                        height: "10.54",
                        left: "150.66",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "16.43",
                        height: "10.54",
                        left: "172.34",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12.68",
                        height: "10.54",
                        left: "191.77",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "6",
                        height: "10.54",
                        left: "207.45",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "mobile",
                        width: "34.66",
                        height: "10.54",
                        left: "216.45",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "device",
                        width: "31.99",
                        height: "10.54",
                        left: "254.11",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "289.1",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "20",
                        height: "10.54",
                        left: "306.09",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "32.64",
                        height: "10.54",
                        left: "329.09",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "364.73",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "UUID",
                        width: "30.67",
                        height: "10.54",
                        left: "383.73",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "15.32",
                        height: "10.54",
                        left: "417.4",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "20.67",
                        height: "10.54",
                        left: "435.72",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "device.",
                        width: "34.98",
                        height: "10.54",
                        left: "459.39",
                        top: "526.66",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.13",
                        left: "90",
                        top: "512.86",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "60.69",
                        height: "10.54",
                        left: "108",
                        top: "512.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "24",
                        height: "10.54",
                        left: "174.69",
                        top: "512.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Page",
                        width: "24.65",
                        height: "10.54",
                        left: "204.69",
                        top: "512.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Tags",
                        width: "22.9",
                        height: "10.54",
                        left: "235.34",
                        top: "512.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "258.24",
                        top: "512.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "16.66",
                        height: "10.54",
                        left: "273.24",
                        top: "512.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16.67",
                        height: "10.54",
                        left: "295.9",
                        top: "512.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third­party",
                        width: "58",
                        height: "10.54",
                        left: "318.57",
                        top: "512.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "tracking",
                        width: "43.33",
                        height: "10.54",
                        left: "382.57",
                        top: "512.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "39.98",
                        height: "10.54",
                        left: "431.15",
                        top: "512.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "20.66",
                        height: "10.54",
                        left: "476.38",
                        top: "512.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "employ",
                        width: "37.33",
                        height: "10.54",
                        left: "502.29",
                        top: "512.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "cookies",
                        width: "37.33",
                        height: "10.54",
                        left: "108",
                        top: "499.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "149.83",
                        top: "499.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "page",
                        width: "24",
                        height: "10.54",
                        left: "172.93",
                        top: "499.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "tags",
                        width: "20.66",
                        height: "10.54",
                        left: "200.68",
                        top: "499.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(also",
                        width: "24",
                        height: "10.54",
                        left: "225.09",
                        top: "499.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "known",
                        width: "34.69",
                        height: "10.54",
                        left: "252.84",
                        top: "499.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10.67",
                        height: "10.54",
                        left: "291.28",
                        top: "499.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "web",
                        width: "20.67",
                        height: "10.54",
                        left: "305.7",
                        top: "499.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "beacons)",
                        width: "44.66",
                        height: "10.54",
                        left: "330.12",
                        top: "499.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "378.53",
                        top: "499.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "32.64",
                        height: "10.54",
                        left: "392.28",
                        top: "499.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "22.67",
                        height: "10.54",
                        left: "428.67",
                        top: "499.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "29.35",
                        height: "10.54",
                        left: "455.09",
                        top: "499.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "visitors",
                        width: "37.33",
                        height: "10.54",
                        left: "488.19",
                        top: "499.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.99",
                        height: "10.54",
                        left: "529.27",
                        top: "499.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "108",
                        top: "485.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "43.66",
                        height: "10.54",
                        left: "129.75",
                        top: "485.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "This",
                        width: "22.69",
                        height: "10.54",
                        left: "180.91",
                        top: "485.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "22.67",
                        height: "10.54",
                        left: "207.35",
                        top: "485.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "includes",
                        width: "42.01",
                        height: "10.54",
                        left: "233.02",
                        top: "485.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "usage",
                        width: "28.67",
                        height: "10.54",
                        left: "278.03",
                        top: "485.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.34",
                        height: "10.54",
                        left: "309.7",
                        top: "485.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "22",
                        height: "10.54",
                        left: "332.04",
                        top: "485.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "statistics.",
                        width: "46.99",
                        height: "10.54",
                        left: "357.04",
                        top: "485.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Emails",
                        width: "35.34",
                        height: "10.54",
                        left: "410.03",
                        top: "485.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sent",
                        width: "20.67",
                        height: "10.54",
                        left: "448.37",
                        top: "485.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12.67",
                        height: "10.54",
                        left: "472.04",
                        top: "485.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "11.34",
                        height: "10.54",
                        left: "487.71",
                        top: "485.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "include",
                        width: "37.35",
                        height: "10.54",
                        left: "502.05",
                        top: "485.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "page",
                        width: "24",
                        height: "10.54",
                        left: "108",
                        top: "471.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "tags",
                        width: "20.67",
                        height: "10.54",
                        left: "136.5",
                        top: "471.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "20.66",
                        height: "10.54",
                        left: "161.67",
                        top: "471.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "allow",
                        width: "27.34",
                        height: "10.54",
                        left: "186.83",
                        top: "471.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "11.34",
                        height: "10.54",
                        left: "218.67",
                        top: "471.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "234.51",
                        top: "471.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "32.64",
                        height: "10.54",
                        left: "249.01",
                        top: "471.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "286.15",
                        top: "471.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "29.34",
                        height: "10.54",
                        left: "351.98",
                        top: "471.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "who",
                        width: "21.34",
                        height: "10.54",
                        left: "385.82",
                        top: "471.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "opened",
                        width: "36.68",
                        height: "10.54",
                        left: "411.66",
                        top: "471.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "those",
                        width: "26.66",
                        height: "10.54",
                        left: "452.84",
                        top: "471.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "emails",
                        width: "32.66",
                        height: "10.54",
                        left: "484",
                        top: "471.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "520.41",
                        top: "471.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "clicked",
                        width: "35.99",
                        height: "10.54",
                        left: "108",
                        top: "457.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12.68",
                        height: "10.54",
                        left: "149.24",
                        top: "457.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "links",
                        width: "24.68",
                        height: "10.54",
                        left: "167.17",
                        top: "457.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "int",
                        width: "14.01",
                        height: "10.54",
                        left: "197.1",
                        top: "457.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "hem.",
                        width: "24.99",
                        height: "10.54",
                        left: "216.36",
                        top: "457.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "16.67",
                        height: "10.54",
                        left: "251.1",
                        top: "457.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provide",
                        width: "39.11",
                        height: "10.54",
                        left: "272.27",
                        top: "457.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "more",
                        width: "26.44",
                        height: "10.54",
                        left: "315.88",
                        top: "457.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "346.82",
                        top: "457.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12.67",
                        height: "10.54",
                        left: "412.65",
                        top: "457.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cookies",
                        width: "37.33",
                        height: "10.54",
                        left: "429.82",
                        top: "457.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "above,",
                        width: "33",
                        height: "10.54",
                        left: "471.65",
                        top: "457.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10.01",
                        height: "10.54",
                        left: "509.15",
                        top: "457.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "523.66",
                        top: "457.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "subsection",
                        width: "53.34",
                        height: "10.54",
                        left: "108",
                        top: "443.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "titled",
                        width: "26.66",
                        height: "10.54",
                        left: "170.34",
                        top: "443.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "60.69",
                        height: "10.54",
                        left: "206",
                        top: "443.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.1",
                        height: "10.54",
                        left: "275.69",
                        top: "443.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Collect",
                        width: "34.66",
                        height: "10.54",
                        left: "299.79",
                        top: "443.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Through",
                        width: "44.02",
                        height: "10.54",
                        left: "343.45",
                        top: "443.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Automatic",
                        width: "51.34",
                        height: "10.54",
                        left: "396.47",
                        top: "443.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Data",
                        width: "24",
                        height: "10.54",
                        left: "456.81",
                        top: "443.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Collection",
                        width: "50.67",
                        height: "10.54",
                        left: "489.06",
                        top: "443.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Technologies;",
                        width: "68.89",
                        height: "10.54",
                        left: "108",
                        top: "430.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Cookie",
                        width: "34.66",
                        height: "10.54",
                        left: "179.89",
                        top: "430.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy.",
                        width: "33.21",
                        height: "10.54",
                        left: "217.55",
                        top: "430.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.14",
                        left: "90",
                        top: "416.21",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Log",
                        width: "19.33",
                        height: "10.54",
                        left: "108",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Data",
                        width: "24",
                        height: "10.54",
                        left: "131.08",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "155.08",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Like",
                        width: "23.34",
                        height: "10.54",
                        left: "161.83",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "most",
                        width: "24.66",
                        height: "10.54",
                        left: "188.92",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "websites",
                        width: "42.66",
                        height: "10.54",
                        left: "217.33",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "today,",
                        width: "31.01",
                        height: "10.54",
                        left: "263.74",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "298.5",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "web",
                        width: "20.67",
                        height: "10.54",
                        left: "320.25",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "servers",
                        width: "36.64",
                        height: "10.54",
                        left: "344.67",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "keep",
                        width: "24",
                        height: "10.54",
                        left: "385.06",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "log",
                        width: "15.33",
                        height: "10.54",
                        left: "412.81",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "files",
                        width: "20.66",
                        height: "10.54",
                        left: "431.89",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "20.67",
                        height: "10.54",
                        left: "456.3",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "record",
                        width: "33.76",
                        height: "10.54",
                        left: "479.97",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "22.67",
                        height: "10.54",
                        left: "516.73",
                        top: "416.16",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "each",
                        width: "23.33",
                        height: "10.54",
                        left: "108",
                        top: "402.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "22.65",
                        height: "10.54",
                        left: "137.33",
                        top: "402.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "6",
                        height: "10.54",
                        left: "165.98",
                        top: "402.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "device",
                        width: "31.98",
                        height: "10.54",
                        left: "177.98",
                        top: "402.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accesses",
                        width: "41.32",
                        height: "10.54",
                        left: "215.96",
                        top: "402.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "those",
                        width: "26.67",
                        height: "10.54",
                        left: "263.28",
                        top: "402.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "servers.",
                        width: "39.64",
                        height: "10.54",
                        left: "295.95",
                        top: "402.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "The",
                        width: "20",
                        height: "10.54",
                        left: "346.09",
                        top: "402.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "log",
                        width: "15.34",
                        height: "10.54",
                        left: "371.34",
                        top: "402.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "files",
                        width: "20.66",
                        height: "10.54",
                        left: "391.93",
                        top: "402.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contain",
                        width: "38",
                        height: "10.54",
                        left: "417.84",
                        top: "402.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "data",
                        width: "22.67",
                        height: "10.54",
                        left: "461.09",
                        top: "402.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "29.35",
                        height: "10.54",
                        left: "489.01",
                        top: "402.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "523.61",
                        top: "402.36",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "nature",
                        width: "33.78",
                        height: "10.55",
                        left: "108",
                        top: "388.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "145.53",
                        top: "388.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "each",
                        width: "23.32",
                        height: "10.55",
                        left: "159.28",
                        top: "388.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access,",
                        width: "34.32",
                        height: "10.55",
                        left: "186.35",
                        top: "388.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "including",
                        width: "48.02",
                        height: "10.55",
                        left: "224.42",
                        top: "388.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "originating",
                        width: "56.68",
                        height: "10.55",
                        left: "275.44",
                        top: "388.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "IP",
                        width: "12",
                        height: "10.55",
                        left: "335.12",
                        top: "388.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "addresses,",
                        width: "52.11",
                        height: "10.55",
                        left: "350.12",
                        top: "388.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "internet",
                        width: "40.66",
                        height: "10.55",
                        left: "405.23",
                        top: "388.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "35.31",
                        height: "10.55",
                        left: "448.89",
                        top: "388.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providers,",
                        width: "52.11",
                        height: "10.55",
                        left: "487.2",
                        top: "388.56",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "108",
                        top: "374.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "files",
                        width: "20.66",
                        height: "10.54",
                        left: "127.75",
                        top: "374.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "viewed",
                        width: "35.32",
                        height: "10.54",
                        left: "152.16",
                        top: "374.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12.68",
                        height: "10.54",
                        left: "191.23",
                        top: "374.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "207.66",
                        top: "374.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "40.66",
                        height: "10.54",
                        left: "229.41",
                        top: "374.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(e.g.,",
                        width: "24.32",
                        height: "10.54",
                        left: "273.82",
                        top: "374.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "HTML",
                        width: "36.67",
                        height: "10.54",
                        left: "301.89",
                        top: "374.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "pages,",
                        width: "31.67",
                        height: "10.54",
                        left: "341.56",
                        top: "374.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "376.23",
                        top: "374.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "graphics),",
                        width: "51",
                        height: "10.54",
                        left: "398.58",
                        top: "374.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "operating",
                        width: "49.33",
                        height: "10.54",
                        left: "452.58",
                        top: "374.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "system",
                        width: "34.66",
                        height: "10.54",
                        left: "504.91",
                        top: "374.77",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "versions,",
                        width: "45",
                        height: "10.54",
                        left: "108",
                        top: "360.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "device",
                        width: "31.99",
                        height: "10.54",
                        left: "156",
                        top: "360.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "type,",
                        width: "24.99",
                        height: "10.54",
                        left: "190.99",
                        top: "360.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "218.98",
                        top: "360.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "timestamps.",
                        width: "61.66",
                        height: "10.54",
                        left: "241.33",
                        top: "360.97",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.13",
                        left: "90",
                        top: "347.17",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "User",
                        width: "23.33",
                        height: "10.54",
                        left: "108",
                        top: "347.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Content",
                        width: "39.35",
                        height: "10.54",
                        left: "134.33",
                        top: "347.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "173.68",
                        top: "347.12",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "7.25",
                        height: "11.13",
                        left: "90",
                        top: "333.32",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Other",
                        width: "28.67",
                        height: "10.54",
                        left: "108",
                        top: "333.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "60.69",
                        height: "10.54",
                        left: "145.67",
                        top: "333.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "206.36",
                        top: "333.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "16.66",
                        height: "10.54",
                        left: "218.36",
                        top: "333.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "20",
                        height: "10.54",
                        left: "243.27",
                        top: "333.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "32.65",
                        height: "10.54",
                        left: "271.52",
                        top: "333.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "27.32",
                        height: "10.54",
                        left: "312.42",
                        top: "333.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "347.99",
                        top: "333.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "constituting",
                        width: "60.67",
                        height: "10.54",
                        left: "417.57",
                        top: "333.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personally",
                        width: "53.34",
                        height: "10.54",
                        left: "486.49",
                        top: "333.26",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "identifiable",
                        width: "58",
                        height: "10.55",
                        left: "108",
                        top: "319.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.55",
                        left: "169.75",
                        top: "319.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "20.67",
                        height: "10.55",
                        left: "234.83",
                        top: "319.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.55",
                        left: "259.25",
                        top: "319.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "described",
                        width: "49.33",
                        height: "10.55",
                        left: "271",
                        top: "319.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10.01",
                        height: "10.55",
                        left: "324.08",
                        top: "319.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "62.67",
                        height: "10.55",
                        left: "337.84",
                        top: "319.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "16.66",
                        height: "10.55",
                        left: "403.51",
                        top: "319.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Collect",
                        width: "35.98",
                        height: "10.55",
                        left: "423.17",
                        top: "319.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "About",
                        width: "32.01",
                        height: "10.55",
                        left: "462.15",
                        top: "319.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "20.01",
                        height: "10.55",
                        left: "497.16",
                        top: "319.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.55",
                        left: "520.17",
                        top: "319.46",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "How",
                        width: "24",
                        height: "10.54",
                        left: "108",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "16.66",
                        height: "10.54",
                        left: "135.75",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "18.67",
                        height: "10.54",
                        left: "156.16",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "It,",
                        width: "11.66",
                        height: "10.54",
                        left: "178.58",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "above.",
                        width: "33",
                        height: "10.54",
                        left: "193.99",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "16.67",
                        height: "10.54",
                        left: "234.49",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "32.64",
                        height: "10.54",
                        left: "254.91",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "23.34",
                        height: "10.54",
                        left: "291.3",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "27.32",
                        height: "10.54",
                        left: "318.39",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "349.46",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "414.54",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16.67",
                        height: "10.54",
                        left: "437.64",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "it",
                        width: "7.33",
                        height: "10.54",
                        left: "457.31",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10.01",
                        height: "10.54",
                        left: "467.64",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "480.65",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "manner",
                        width: "39.99",
                        height: "10.54",
                        left: "499.65",
                        top: "305.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "described",
                        width: "49.33",
                        height: "10.54",
                        left: "108",
                        top: "291.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10.01",
                        height: "10.54",
                        left: "160.33",
                        top: "291.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "18.67",
                        height: "10.54",
                        left: "173.34",
                        top: "291.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "39.32",
                        height: "10.54",
                        left: "195.01",
                        top: "291.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy.",
                        width: "33.66",
                        height: "10.54",
                        left: "237.33",
                        top: "291.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "16.66",
                        height: "10.54",
                        left: "72",
                        top: "264.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "32.65",
                        height: "10.54",
                        left: "95.41",
                        top: "264.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "134.81",
                        top: "264.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "classes",
                        width: "51.31",
                        height: "10.54",
                        left: "157.55",
                        top: "264.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "214.86",
                        top: "264.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "44.01",
                        height: "10.54",
                        left: "230.85",
                        top: "264.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "280.86",
                        top: "264.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identified",
                        width: "48.67",
                        height: "10.54",
                        left: "348.19",
                        top: "264.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "above",
                        width: "30",
                        height: "10.54",
                        left: "402.86",
                        top: "264.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "25.1",
                        height: "10.54",
                        left: "438.86",
                        top: "264.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "469.96",
                        top: "264.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "following",
                        width: "47.34",
                        height: "10.54",
                        left: "491.95",
                        top: "264.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "sources:",
                        width: "41.77",
                        height: "10.54",
                        left: "72",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(1)",
                        width: "13.99",
                        height: "10.54",
                        left: "118.27",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "directly",
                        width: "39.1",
                        height: "10.54",
                        left: "136.76",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "25.1",
                        height: "10.54",
                        left: "180.36",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you;",
                        width: "22.67",
                        height: "10.54",
                        left: "209.96",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(2)",
                        width: "14",
                        height: "10.54",
                        left: "237.13",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "41.12",
                        height: "10.54",
                        left: "255.63",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "24",
                        height: "10.54",
                        left: "301.25",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16.67",
                        height: "10.54",
                        left: "329.75",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "350.92",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "364.67",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website;",
                        width: "44.66",
                        height: "10.54",
                        left: "384.42",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(3)",
                        width: "13.99",
                        height: "10.54",
                        left: "432.83",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "affiliates;",
                        width: "47.98",
                        height: "10.54",
                        left: "450.57",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "502.3",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(4)",
                        width: "13.99",
                        height: "10.54",
                        left: "525.4",
                        top: "250.27",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "third­parties",
                        width: "65.33",
                        height: "10.54",
                        left: "72",
                        top: "236.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "23.34",
                        height: "10.54",
                        left: "140.33",
                        top: "236.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10.67",
                        height: "10.54",
                        left: "166.67",
                        top: "236.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "other",
                        width: "27.32",
                        height: "10.54",
                        left: "180.34",
                        top: "236.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Users",
                        width: "28.66",
                        height: "10.54",
                        left: "210.66",
                        top: "236.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "242.32",
                        top: "236.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "35.31",
                        height: "10.54",
                        left: "256.65",
                        top: "236.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providers.",
                        width: "52.11",
                        height: "10.54",
                        left: "294.96",
                        top: "236.47",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "16.66",
                        height: "10.54",
                        left: "72",
                        top: "208.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "93.16",
                        top: "208.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "108.99",
                        top: "208.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "35.31",
                        height: "10.54",
                        left: "130.74",
                        top: "208.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "providers",
                        width: "49.11",
                        height: "10.54",
                        left: "169.8",
                        top: "208.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "22",
                        height: "10.54",
                        left: "222.66",
                        top: "208.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "collect",
                        width: "32.64",
                        height: "10.54",
                        left: "248.41",
                        top: "208.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "284.8",
                        top: "208.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "classes",
                        width: "51.3",
                        height: "10.54",
                        left: "304.55",
                        top: "208.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "359.6",
                        top: "208.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "373.35",
                        top: "208.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identified",
                        width: "48.67",
                        height: "10.54",
                        left: "438.43",
                        top: "208.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "above",
                        width: "30",
                        height: "10.54",
                        left: "490.85",
                        top: "208.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "15.32",
                        height: "10.54",
                        left: "524.6",
                        top: "208.67",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "72",
                        top: "194.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "following",
                        width: "47.33",
                        height: "10.54",
                        left: "93.25",
                        top: "194.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "business",
                        width: "42.7",
                        height: "10.54",
                        left: "145.83",
                        top: "194.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.32",
                        height: "10.54",
                        left: "193.78",
                        top: "194.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "commercial",
                        width: "59.75",
                        height: "10.54",
                        left: "210.35",
                        top: "194.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes",
                        width: "46.01",
                        height: "10.54",
                        left: "274.6",
                        top: "194.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(as",
                        width: "14.67",
                        height: "10.54",
                        left: "325.11",
                        top: "194.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "those",
                        width: "26.67",
                        height: "10.54",
                        left: "344.28",
                        top: "194.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "29.31",
                        height: "10.54",
                        left: "375.45",
                        top: "194.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "16.44",
                        height: "10.54",
                        left: "409.26",
                        top: "194.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "defined",
                        width: "38",
                        height: "10.54",
                        left: "430.2",
                        top: "194.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10.01",
                        height: "10.54",
                        left: "472.7",
                        top: "194.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applicable",
                        width: "52.67",
                        height: "10.54",
                        left: "487.21",
                        top: "194.87",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "law):",
                        width: "25.99",
                        height: "10.55",
                        left: "72",
                        top: "181.07",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "108",
                        top: "153.75",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Our",
                        width: "21.33",
                        height: "10.54",
                        left: "126",
                        top: "153.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "150.33",
                        top: "153.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "164.66",
                        top: "153.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "35.31",
                        height: "10.54",
                        left: "185.66",
                        top: "153.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "provider’s",
                        width: "52.88",
                        height: "10.54",
                        left: "223.97",
                        top: "153.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "operational",
                        width: "58.66",
                        height: "10.54",
                        left: "279.85",
                        top: "153.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes;",
                        width: "50.01",
                        height: "10.54",
                        left: "341.51",
                        top: "153.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "108",
                        top: "139.95",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Auditing",
                        width: "45.35",
                        height: "10.54",
                        left: "126",
                        top: "139.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "22",
                        height: "10.54",
                        left: "174.35",
                        top: "139.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interactions",
                        width: "60.65",
                        height: "10.54",
                        left: "199.35",
                        top: "139.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12.68",
                        height: "10.54",
                        left: "263",
                        top: "139.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "278.68",
                        top: "139.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "site",
                        width: "17.32",
                        height: "10.54",
                        left: "299.68",
                        top: "139.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(e.g.,",
                        width: "24.33",
                        height: "10.54",
                        left: "320",
                        top: "139.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "measuring",
                        width: "54",
                        height: "10.54",
                        left: "347.33",
                        top: "139.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ad",
                        width: "12.67",
                        height: "10.54",
                        left: "404.33",
                        top: "139.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "impressions);",
                        width: "68.45",
                        height: "10.54",
                        left: "420",
                        top: "139.48",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "108",
                        top: "126.15",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Detecting,",
                        width: "51.64",
                        height: "10.54",
                        left: "126",
                        top: "125.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "protecting",
                        width: "52.44",
                        height: "10.54",
                        left: "182.14",
                        top: "125.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "against,",
                        width: "39.67",
                        height: "10.54",
                        left: "239.08",
                        top: "125.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "283.25",
                        top: "125.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "prosecuting",
                        width: "59.78",
                        height: "10.54",
                        left: "307.1",
                        top: "125.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "security",
                        width: "40.66",
                        height: "10.54",
                        left: "371.38",
                        top: "125.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "incidents,",
                        width: "49",
                        height: "10.54",
                        left: "416.54",
                        top: "125.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fraudulent",
                        width: "54.68",
                        height: "10.54",
                        left: "470.04",
                        top: "125.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.32",
                        height: "10.54",
                        left: "528.47",
                        top: "125.68",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "illegal",
                        width: "30.66",
                        height: "10.54",
                        left: "126",
                        top: "111.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activity,",
                        width: "40.33",
                        height: "10.54",
                        left: "159.66",
                        top: "111.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.34",
                        height: "10.54",
                        left: "202.99",
                        top: "111.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activity",
                        width: "37.99",
                        height: "10.54",
                        left: "225.33",
                        top: "111.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "20.67",
                        height: "10.54",
                        left: "266.32",
                        top: "111.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "violates",
                        width: "38.66",
                        height: "10.54",
                        left: "289.99",
                        top: "111.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "18.67",
                        height: "10.54",
                        left: "331.65",
                        top: "111.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "terms",
                        width: "29.31",
                        height: "10.54",
                        left: "353.32",
                        top: "111.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "385.63",
                        top: "111.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "policies;",
                        width: "42",
                        height: "10.54",
                        left: "399.96",
                        top: "111.88",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.27",
                        left: "108",
                        top: "98.56",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Bug",
                        width: "20.68",
                        height: "10.54",
                        left: "126",
                        top: "98.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "detection,",
                        width: "49.65",
                        height: "10.54",
                        left: "150.43",
                        top: "98.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "error",
                        width: "27.09",
                        height: "10.54",
                        left: "203.83",
                        top: "98.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reporting,",
                        width: "51.44",
                        height: "10.54",
                        left: "234.67",
                        top: "98.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "289.86",
                        top: "98.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "activities",
                        width: "45.31",
                        height: "10.54",
                        left: "312.96",
                        top: "98.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "362.02",
                        top: "98.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "maintain",
                        width: "46.01",
                        height: "10.54",
                        left: "375.02",
                        top: "98.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "424.03",
                        top: "98.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "quality",
                        width: "36.01",
                        height: "10.54",
                        left: "443.02",
                        top: "98.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "482.03",
                        top: "98.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "safety",
                        width: "29.99",
                        height: "10.54",
                        left: "496.36",
                        top: "98.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "529.35",
                        top: "98.08",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "126",
                        top: "84.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services;",
                        width: "43.97",
                        height: "10.54",
                        left: "147",
                        top: "84.28",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,338,320.5", "lineto,540,320.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,108,306.5", "lineto,187,306.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "10",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.2800000000001",
                        left: "108",
                        top: "706.68",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Short­term,",
                        width: "60.31",
                        height: "10.54",
                        left: "126",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transient",
                        width: "46",
                        height: "10.54",
                        left: "191.56",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use,",
                        width: "19.67",
                        height: "10.54",
                        left: "242.81",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "23.34",
                        height: "10.54",
                        left: "267.73",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10.67",
                        height: "10.54",
                        left: "296.32",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "customizing",
                        width: "61.33",
                        height: "10.54",
                        left: "312.24",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "content",
                        width: "37.99",
                        height: "10.54",
                        left: "378.82",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "20.67",
                        height: "10.54",
                        left: "422.06",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "447.23",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "465.72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "481.55",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service",
                        width: "35.31",
                        height: "10.54",
                        left: "504.05",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "providers",
                        width: "49.11",
                        height: "10.54",
                        left: "126",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "display",
                        width: "36.69",
                        height: "10.54",
                        left: "178.11",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12.67",
                        height: "10.54",
                        left: "217.8",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services;",
                        width: "43.98",
                        height: "10.54",
                        left: "233.47",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "108",
                        top: "679.08",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Providing",
                        width: "50.46",
                        height: "10.54",
                        left: "126",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "39.97",
                        height: "10.54",
                        left: "182.46",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(e.g.,",
                        width: "24.33",
                        height: "10.54",
                        left: "227.68",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "39.99",
                        height: "10.54",
                        left: "257.26",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "servicing",
                        width: "45.99",
                        height: "10.54",
                        left: "302.5",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "353.74",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "maintenance,",
                        width: "68.33",
                        height: "10.54",
                        left: "378.34",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "order",
                        width: "28.65",
                        height: "10.54",
                        left: "451.92",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "processing",
                        width: "53.78",
                        height: "10.54",
                        left: "485.82",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.55",
                        left: "126",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "fulfillment,",
                        width: "56.99",
                        height: "10.55",
                        left: "155.85",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "customer",
                        width: "47.32",
                        height: "10.55",
                        left: "223.34",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "service,",
                        width: "38.3",
                        height: "10.55",
                        left: "281.16",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertising",
                        width: "57.34",
                        height: "10.55",
                        left: "329.21",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.55",
                        left: "396.3",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "marketing,",
                        width: "56.32",
                        height: "10.55",
                        left: "425.4",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "analytics,",
                        width: "48.34",
                        height: "10.55",
                        left: "491.47",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "communication",
                        width: "79.33",
                        height: "10.54",
                        left: "126",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "29.34",
                        height: "10.54",
                        left: "215.08",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "254.17",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services,",
                        width: "42.98",
                        height: "10.54",
                        left: "281.92",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "administering",
                        width: "71.34",
                        height: "10.54",
                        left: "334.65",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "surveys,",
                        width: "41.67",
                        height: "10.54",
                        left: "415.74",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contests,",
                        width: "43.65",
                        height: "10.54",
                        left: "467.16",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "520.56",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "promotions,",
                        width: "61.45",
                        height: "10.54",
                        left: "126",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "facilitating",
                        width: "55.33",
                        height: "10.54",
                        left: "190.45",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "communications",
                        width: "84",
                        height: "10.54",
                        left: "248.78",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "between",
                        width: "41.99",
                        height: "10.54",
                        left: "335.78",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users);",
                        width: "34.65",
                        height: "10.54",
                        left: "380.77",
                        top: "637.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "108",
                        top: "623.88",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Improving",
                        width: "54.46",
                        height: "10.54",
                        left: "126",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "184.21",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "existing",
                        width: "39.33",
                        height: "10.54",
                        left: "205.96",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "39.98",
                        height: "10.54",
                        left: "249.04",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "292.77",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "developing",
                        width: "55.34",
                        height: "10.54",
                        left: "315.12",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "new",
                        width: "20.66",
                        height: "10.54",
                        left: "373.46",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "services",
                        width: "39.97",
                        height: "10.54",
                        left: "397.13",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(e.g.,",
                        width: "24.33",
                        height: "10.54",
                        left: "440.1",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12.67",
                        height: "10.54",
                        left: "467.43",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "conducting",
                        width: "56.68",
                        height: "10.54",
                        left: "483.1",
                        top: "623.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "research",
                        width: "43.54",
                        height: "10.54",
                        left: "126",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "173.29",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "develop",
                        width: "39.33",
                        height: "10.54",
                        left: "187.04",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "new",
                        width: "20.67",
                        height: "10.54",
                        left: "229.37",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "products",
                        width: "45.12",
                        height: "10.54",
                        left: "253.04",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "301.16",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "features,",
                        width: "44.09",
                        height: "10.54",
                        left: "315.49",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "362.58",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "376.91",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "train",
                        width: "25.33",
                        height: "10.54",
                        left: "389.91",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "418.24",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "employees",
                        width: "52.65",
                        height: "10.54",
                        left: "439.24",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12.67",
                        height: "10.54",
                        left: "494.89",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "issues",
                        width: "29.35",
                        height: "10.54",
                        left: "510.56",
                        top: "609.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "that",
                        width: "20.67",
                        height: "10.54",
                        left: "126",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "149.67",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Users",
                        width: "28.65",
                        height: "10.54",
                        left: "170.67",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "need",
                        width: "24",
                        height: "10.54",
                        left: "202.32",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "229.32",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "12",
                        height: "10.54",
                        left: "242.32",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "resolved);",
                        width: "50.43",
                        height: "10.54",
                        left: "257.32",
                        top: "595.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "108",
                        top: "582.49",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Other",
                        width: "30.66",
                        height: "10.54",
                        left: "126",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "uses",
                        width: "21.34",
                        height: "10.54",
                        left: "164.91",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "that",
                        width: "20.66",
                        height: "10.54",
                        left: "194.5",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advance",
                        width: "42",
                        height: "10.54",
                        left: "223.41",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "273.66",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "commercial",
                        width: "59.75",
                        height: "10.54",
                        left: "299.91",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "367.91",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "economic",
                        width: "47.98",
                        height: "10.54",
                        left: "387.49",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "interests,",
                        width: "46.1",
                        height: "10.54",
                        left: "443.72",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "23.34",
                        height: "10.54",
                        left: "498.07",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10.67",
                        height: "10.54",
                        left: "528.91",
                        top: "582.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "third­party",
                        width: "58",
                        height: "10.54",
                        left: "126",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "advertising",
                        width: "57.33",
                        height: "10.54",
                        left: "187.75",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "248.83",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "communicating",
                        width: "79.33",
                        height: "10.54",
                        left: "271.93",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "22.67",
                        height: "10.54",
                        left: "355.01",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18.67",
                        height: "10.54",
                        left: "381.43",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "29.35",
                        height: "10.54",
                        left: "403.85",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "relevant",
                        width: "41.76",
                        height: "10.54",
                        left: "436.95",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "offers",
                        width: "29.32",
                        height: "10.54",
                        left: "482.46",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "25.1",
                        height: "10.54",
                        left: "514.78",
                        top: "568.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "third­party",
                        width: "58",
                        height: "10.55",
                        left: "126",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "partners;",
                        width: "47.98",
                        height: "10.55",
                        left: "187",
                        top: "554.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "●",
                        width: "6.04",
                        height: "9.28",
                        left: "108",
                        top: "541.09",
                        color: "#000000",
                        fontName: "Arial",
                        fontSize: "10",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "Other",
                        width: "30.66",
                        height: "10.54",
                        left: "126",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "uses",
                        width: "21.34",
                        height: "10.54",
                        left: "159.66",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "29.34",
                        height: "10.54",
                        left: "184",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "which",
                        width: "30.67",
                        height: "10.54",
                        left: "216.34",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "250.01",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notify",
                        width: "30",
                        height: "10.54",
                        left: "267.01",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you.",
                        width: "21.67",
                        height: "10.54",
                        left: "300.01",
                        top: "540.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "16.66",
                        height: "10.54",
                        left: "72",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "describe",
                        width: "42.66",
                        height: "10.54",
                        left: "92.41",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "138.82",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "160.57",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sharing",
                        width: "38.68",
                        height: "10.54",
                        left: "224.9",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "practices",
                        width: "45.98",
                        height: "10.54",
                        left: "266.58",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10",
                        height: "10.54",
                        left: "315.56",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "328.56",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "39.32",
                        height: "10.54",
                        left: "347.56",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy,",
                        width: "33.66",
                        height: "10.54",
                        left: "389.88",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "above.",
                        width: "33",
                        height: "10.54",
                        left: "426.54",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "In",
                        width: "11.34",
                        height: "10.54",
                        left: "462.54",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "476.88",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "previous",
                        width: "43.79",
                        height: "10.54",
                        left: "495.88",
                        top: "512.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "twelve",
                        width: "32.65",
                        height: "10.54",
                        left: "72",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "months,",
                        width: "41.01",
                        height: "10.54",
                        left: "109.15",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "154.66",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "22",
                        height: "10.54",
                        left: "173.15",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "24",
                        height: "10.54",
                        left: "199.65",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shared",
                        width: "34.45",
                        height: "10.54",
                        left: "227.4",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "35.98",
                        height: "10.54",
                        left: "265.6",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "classes",
                        width: "51.31",
                        height: "10.54",
                        left: "305.33",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "360.39",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "44.01",
                        height: "10.54",
                        left: "374.13",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "421.89",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "22.67",
                        height: "10.54",
                        left: "486.97",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third",
                        width: "26",
                        height: "10.54",
                        left: "513.39",
                        top: "499.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "parties",
                        width: "35.33",
                        height: "10.54",
                        left: "72",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "15.32",
                        height: "10.54",
                        left: "111.08",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "business",
                        width: "42.69",
                        height: "10.54",
                        left: "130.15",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purposes.",
                        width: "49.01",
                        height: "10.54",
                        left: "176.59",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "For",
                        width: "18.66",
                        height: "10.54",
                        left: "229.35",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "example,",
                        width: "45.66",
                        height: "10.54",
                        left: "251.76",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "301.17",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "21.99",
                        height: "10.54",
                        left: "318.91",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "share",
                        width: "27.78",
                        height: "10.54",
                        left: "344.65",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "24",
                        height: "10.54",
                        left: "376.18",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "44.01",
                        height: "10.54",
                        left: "403.93",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "451.69",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "22.67",
                        height: "10.54",
                        left: "516.77",
                        top: "485.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "other",
                        width: "27.32",
                        height: "10.54",
                        left: "72",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users,",
                        width: "29.67",
                        height: "10.54",
                        left: "103.07",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "23.34",
                        height: "10.54",
                        left: "136.49",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10.67",
                        height: "10.54",
                        left: "163.58",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10.01",
                        height: "10.54",
                        left: "178",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "connection",
                        width: "55.33",
                        height: "10.54",
                        left: "191.76",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "22.67",
                        height: "10.54",
                        left: "250.84",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transactions",
                        width: "62.67",
                        height: "10.54",
                        left: "277.26",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "22.67",
                        height: "10.54",
                        left: "343.68",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "6",
                        height: "10.54",
                        left: "369.35",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "third­party",
                        width: "57.99",
                        height: "10.54",
                        left: "378.35",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "22",
                        height: "10.54",
                        left: "439.34",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12.67",
                        height: "10.54",
                        left: "464.34",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "480.01",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "40.66",
                        height: "10.54",
                        left: "499.01",
                        top: "471.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "marketplace.",
                        width: "66.98",
                        height: "10.54",
                        left: "72",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "The",
                        width: "20",
                        height: "10.54",
                        left: "144.98",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "44.01",
                        height: "10.54",
                        left: "170.98",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "220.99",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "shared",
                        width: "34.45",
                        height: "10.54",
                        left: "288.32",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "22",
                        height: "10.54",
                        left: "328.77",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "include",
                        width: "37.34",
                        height: "10.54",
                        left: "356.77",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "399.36",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "following",
                        width: "47.34",
                        height: "10.54",
                        left: "420.6",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "classes",
                        width: "51.31",
                        height: "10.54",
                        left: "473.19",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "529.75",
                        top: "457.62",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "personal",
                        width: "44",
                        height: "10.54",
                        left: "72",
                        top: "443.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information:",
                        width: "65.33",
                        height: "10.54",
                        left: "122.75",
                        top: "443.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(1)",
                        width: "13.99",
                        height: "10.54",
                        left: "194.83",
                        top: "443.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identifiers;",
                        width: "55.99",
                        height: "10.54",
                        left: "214.82",
                        top: "443.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(2)",
                        width: "13.99",
                        height: "10.54",
                        left: "276.81",
                        top: "443.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "commercial",
                        width: "59.75",
                        height: "10.54",
                        left: "296.8",
                        top: "443.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "362.55",
                        top: "443.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(such",
                        width: "27.34",
                        height: "10.54",
                        left: "429.88",
                        top: "443.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10.67",
                        height: "10.54",
                        left: "463.22",
                        top: "443.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "479.89",
                        top: "443.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "type",
                        width: "22",
                        height: "10.54",
                        left: "501.88",
                        top: "443.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "529.88",
                        top: "443.82",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "animals",
                        width: "40.01",
                        height: "10.54",
                        left: "72",
                        top: "430.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.32",
                        height: "10.54",
                        left: "119.51",
                        top: "430.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "related",
                        width: "35.77",
                        height: "10.54",
                        left: "138.33",
                        top: "430.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "items",
                        width: "27.32",
                        height: "10.54",
                        left: "181.6",
                        top: "430.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18.68",
                        height: "10.54",
                        left: "216.42",
                        top: "430.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "16.43",
                        height: "10.54",
                        left: "242.6",
                        top: "430.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "seeking",
                        width: "38.01",
                        height: "10.54",
                        left: "266.53",
                        top: "430.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.99",
                        height: "10.54",
                        left: "312.04",
                        top: "430.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sell",
                        width: "16.67",
                        height: "10.54",
                        left: "329.53",
                        top: "430.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.32",
                        height: "10.54",
                        left: "353.7",
                        top: "430.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "purchase",
                        width: "46.45",
                        height: "10.54",
                        left: "372.52",
                        top: "430.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "41.13",
                        height: "10.54",
                        left: "426.47",
                        top: "430.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "475.1",
                        top: "430.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "40.66",
                        height: "10.54",
                        left: "498.6",
                        top: "430.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "marketplace);",
                        width: "71.97",
                        height: "10.55",
                        left: "72",
                        top: "416.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.55",
                        left: "147.72",
                        top: "416.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(3)",
                        width: "13.99",
                        height: "10.55",
                        left: "170.82",
                        top: "416.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "location",
                        width: "40.66",
                        height: "10.55",
                        left: "188.56",
                        top: "416.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.55",
                        left: "232.97",
                        top: "416.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(e.g.",
                        width: "21.33",
                        height: "10.55",
                        left: "298.05",
                        top: "416.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.99",
                        height: "10.55",
                        left: "323.13",
                        top: "416.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "describe",
                        width: "42.66",
                        height: "10.55",
                        left: "336.87",
                        top: "416.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "where",
                        width: "31.1",
                        height: "10.55",
                        left: "382.53",
                        top: "416.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18.67",
                        height: "10.55",
                        left: "416.63",
                        top: "416.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "16.44",
                        height: "10.55",
                        left: "438.3",
                        top: "416.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "seeking",
                        width: "38",
                        height: "10.55",
                        left: "457.74",
                        top: "416.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.55",
                        left: "498.74",
                        top: "416.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "28",
                        height: "10.55",
                        left: "511.74",
                        top: "416.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "purchases",
                        width: "51.12",
                        height: "10.54",
                        left: "72",
                        top: "402.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "126.12",
                        top: "402.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "sales",
                        width: "24",
                        height: "10.54",
                        left: "140.45",
                        top: "402.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "41.13",
                        height: "10.54",
                        left: "167.45",
                        top: "402.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "211.58",
                        top: "402.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "40.67",
                        height: "10.54",
                        left: "230.57",
                        top: "402.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "marketplace).",
                        width: "70.97",
                        height: "10.54",
                        left: "274.24",
                        top: "402.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Changing",
                        width: "50.02",
                        height: "10.54",
                        left: "72",
                        top: "374.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "25.34",
                        height: "10.54",
                        left: "127.27",
                        top: "374.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Personally",
                        width: "53.99",
                        height: "10.54",
                        left: "157.86",
                        top: "374.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Identifiable",
                        width: "59.34",
                        height: "10.54",
                        left: "217.1",
                        top: "374.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information.",
                        width: "65.67",
                        height: "10.54",
                        left: "281.69",
                        top: "374.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "20.01",
                        height: "10.54",
                        left: "352.61",
                        top: "374.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "16.43",
                        height: "10.54",
                        left: "377.87",
                        top: "374.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "able",
                        width: "21.33",
                        height: "10.54",
                        left: "399.55",
                        top: "374.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "426.13",
                        top: "374.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "review",
                        width: "33.76",
                        height: "10.54",
                        left: "441.38",
                        top: "374.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "479.64",
                        top: "374.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "change",
                        width: "36",
                        height: "10.54",
                        left: "503.49",
                        top: "374.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "your",
                        width: "24",
                        height: "10.54",
                        left: "72",
                        top: "360.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personally",
                        width: "53.34",
                        height: "10.54",
                        left: "104.25",
                        top: "360.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identifiable",
                        width: "58",
                        height: "10.54",
                        left: "165.84",
                        top: "360.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "61.33",
                        height: "10.54",
                        left: "231.34",
                        top: "360.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10.01",
                        height: "10.54",
                        left: "300.17",
                        top: "360.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "317.68",
                        top: "360.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "manner",
                        width: "40",
                        height: "10.54",
                        left: "341.17",
                        top: "360.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "described",
                        width: "49.33",
                        height: "10.54",
                        left: "388.67",
                        top: "360.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10.01",
                        height: "10.54",
                        left: "445.5",
                        top: "360.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Accessing",
                        width: "49.99",
                        height: "10.54",
                        left: "463.01",
                        top: "360.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "520.5",
                        top: "360.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Correcting",
                        width: "55.76",
                        height: "10.54",
                        left: "72",
                        top: "347.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "25.33",
                        height: "10.54",
                        left: "130.76",
                        top: "347.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information,",
                        width: "65.67",
                        height: "10.54",
                        left: "159.09",
                        top: "347.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "above.",
                        width: "33",
                        height: "10.54",
                        left: "227.76",
                        top: "347.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "California",
                        width: "52.66",
                        height: "10.54",
                        left: "72",
                        top: "319.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Do",
                        width: "14.67",
                        height: "10.54",
                        left: "129.16",
                        top: "319.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Not",
                        width: "18.66",
                        height: "10.54",
                        left: "148.33",
                        top: "319.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Track",
                        width: "30.44",
                        height: "10.54",
                        left: "171.49",
                        top: "319.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Disclosures.",
                        width: "60.78",
                        height: "10.54",
                        left: "206.43",
                        top: "319.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "The",
                        width: "20.01",
                        height: "10.54",
                        left: "269.99",
                        top: "319.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "40.66",
                        height: "10.54",
                        left: "294.5",
                        top: "319.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "currently",
                        width: "47.77",
                        height: "10.54",
                        left: "339.66",
                        top: "319.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "does",
                        width: "22.67",
                        height: "10.54",
                        left: "391.93",
                        top: "319.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "16.67",
                        height: "10.54",
                        left: "419.1",
                        top: "319.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "respond",
                        width: "41.12",
                        height: "10.54",
                        left: "440.27",
                        top: "319.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "485.89",
                        top: "319.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "“do",
                        width: "18.67",
                        height: "10.54",
                        left: "500.39",
                        top: "319.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "16.67",
                        height: "10.54",
                        left: "522.81",
                        top: "319.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "track”",
                        width: "33.32",
                        height: "10.54",
                        left: "72",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "signals",
                        width: "34.68",
                        height: "10.54",
                        left: "109.82",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "149",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "operates",
                        width: "43.32",
                        height: "10.54",
                        left: "172.85",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "as",
                        width: "10.67",
                        height: "10.54",
                        left: "220.67",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "described",
                        width: "49.33",
                        height: "10.54",
                        left: "235.84",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10.01",
                        height: "10.54",
                        left: "289.67",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "18.67",
                        height: "10.54",
                        left: "304.18",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "39.32",
                        height: "10.54",
                        left: "327.35",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "31.32",
                        height: "10.54",
                        left: "371.17",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "whether",
                        width: "41.99",
                        height: "10.54",
                        left: "406.99",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "11.33",
                        height: "10.54",
                        left: "453.48",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "16.67",
                        height: "10.54",
                        left: "469.31",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "6",
                        height: "10.54",
                        left: "490.48",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "“do",
                        width: "18.67",
                        height: "10.54",
                        left: "500.23",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "16.67",
                        height: "10.54",
                        left: "522.65",
                        top: "305.43",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "track",
                        width: "27.32",
                        height: "10.54",
                        left: "72",
                        top: "291.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "signal”",
                        width: "36.01",
                        height: "10.54",
                        left: "102.32",
                        top: "291.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "141.33",
                        top: "291.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "received.",
                        width: "45.42",
                        height: "10.54",
                        left: "152.34",
                        top: "291.63",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Changes",
                        width: "44.01",
                        height: "10.54",
                        left: "72",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "120.51",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "18.67",
                        height: "10.54",
                        left: "135.01",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "39.32",
                        height: "10.54",
                        left: "158.18",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "31.32",
                        height: "10.54",
                        left: "202",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "233.32",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "16.66",
                        height: "10.54",
                        left: "240.82",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reserve",
                        width: "37.09",
                        height: "10.54",
                        left: "261.98",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "303.57",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "25.33",
                        height: "10.54",
                        left: "324.06",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "353.89",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amend",
                        width: "34.67",
                        height: "10.54",
                        left: "368.39",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "18.67",
                        height: "10.54",
                        left: "407.56",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "39.32",
                        height: "10.54",
                        left: "429.98",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "31.33",
                        height: "10.54",
                        left: "473.05",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "9.99",
                        height: "10.54",
                        left: "508.13",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "521.87",
                        top: "263.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "discretion",
                        width: "50.44",
                        height: "10.54",
                        left: "72",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "19.35",
                        height: "10.54",
                        left: "127.69",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "10",
                        height: "10.54",
                        left: "152.29",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "18.67",
                        height: "10.54",
                        left: "167.54",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time",
                        width: "22.65",
                        height: "10.54",
                        left: "191.46",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "10.01",
                        height: "10.54",
                        left: "219.36",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accordance",
                        width: "57.98",
                        height: "10.54",
                        left: "233.87",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "22.67",
                        height: "10.54",
                        left: "296.35",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "16",
                        height: "10.54",
                        left: "323.52",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "section",
                        width: "35.32",
                        height: "10.54",
                        left: "344.02",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "titled",
                        width: "26.66",
                        height: "10.54",
                        left: "383.84",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Changes",
                        width: "44.01",
                        height: "10.54",
                        left: "415",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "10",
                        height: "10.54",
                        left: "463.51",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "478.01",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "39.31",
                        height: "10.54",
                        left: "500.51",
                        top: "250.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Policy,",
                        width: "34.32",
                        height: "10.54",
                        left: "72",
                        top: "236.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "below.",
                        width: "32.34",
                        height: "10.54",
                        left: "109.32",
                        top: "236.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Data",
                        width: "30.2",
                        height: "14.05",
                        left: "72",
                        top: "203.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Security",
                        width: "53.31",
                        height: "14.05",
                        left: "106.19",
                        top: "203.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "176.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "92.19",
                        top: "176.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "implemented",
                        width: "62.65",
                        height: "10.54",
                        left: "119.34",
                        top: "176.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "measures",
                        width: "44.65",
                        height: "10.54",
                        left: "186.49",
                        top: "176.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "designed",
                        width: "42.66",
                        height: "10.54",
                        left: "235.64",
                        top: "176.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "282.8",
                        top: "176.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "secure",
                        width: "30.65",
                        height: "10.54",
                        left: "296.63",
                        top: "176.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "331.78",
                        top: "176.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "358.27",
                        top: "176.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "403.42",
                        top: "176.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.32",
                        height: "10.54",
                        left: "463.83",
                        top: "176.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "accidental",
                        width: "48.64",
                        height: "10.54",
                        left: "490.9",
                        top: "176.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "loss",
                        width: "18.67",
                        height: "10.54",
                        left: "72",
                        top: "162.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "93.67",
                        top: "162.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "from",
                        width: "23.33",
                        height: "10.54",
                        left: "114",
                        top: "162.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "unauthorized",
                        width: "62.64",
                        height: "10.54",
                        left: "140.33",
                        top: "162.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access,",
                        width: "33.64",
                        height: "10.54",
                        left: "205.97",
                        top: "162.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use,",
                        width: "19",
                        height: "10.54",
                        left: "242.61",
                        top: "162.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "alteration,",
                        width: "48.31",
                        height: "10.54",
                        left: "264.61",
                        top: "162.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "315.92",
                        top: "162.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "disclosure.",
                        width: "51.65",
                        height: "10.54",
                        left: "336.25",
                        top: "162.24",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "safety",
                        width: "28.65",
                        height: "10.54",
                        left: "94.41",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "126.81",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "security",
                        width: "37.99",
                        height: "10.54",
                        left: "147.88",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "189.62",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "203.37",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "229.11",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "also",
                        width: "19.33",
                        height: "10.54",
                        left: "289.52",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "depends",
                        width: "39.32",
                        height: "10.54",
                        left: "312.6",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "355.67",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you.",
                        width: "21",
                        height: "10.54",
                        left: "371.42",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Where",
                        width: "31.97",
                        height: "10.54",
                        left: "396.17",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "431.89",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.54",
                        left: "449.64",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "given",
                        width: "26.66",
                        height: "10.54",
                        left: "475.29",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "504.95",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(or",
                        width: "13.99",
                        height: "10.54",
                        left: "525.95",
                        top: "134.44",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "where",
                        width: "29.31",
                        height: "10.54",
                        left: "72",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "108.06",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.66",
                        height: "10.54",
                        left: "132.81",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "chosen)",
                        width: "37.32",
                        height: "10.54",
                        left: "162.22",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "206.29",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "password",
                        width: "45.33",
                        height: "10.54",
                        left: "218.36",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "270.44",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "access",
                        width: "30.65",
                        height: "10.54",
                        left: "291.18",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "328.58",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "certain",
                        width: "32.64",
                        height: "10.54",
                        left: "344.66",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "parts",
                        width: "23.33",
                        height: "10.54",
                        left: "384.05",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "414.13",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "430.88",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website,",
                        width: "41.36",
                        height: "10.54",
                        left: "453.62",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "500.98",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "524.98",
                        top: "120.64",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "responsible",
                        width: "54.66",
                        height: "10.54",
                        left: "72",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "130.41",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "keeping",
                        width: "37.98",
                        height: "10.54",
                        left: "148.15",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "189.88",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "password",
                        width: "45.33",
                        height: "10.54",
                        left: "210.97",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "confidential.",
                        width: "60.31",
                        height: "10.54",
                        left: "260.05",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "324.11",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ask",
                        width: "16",
                        height: "10.54",
                        left: "343.55",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "363.3",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "385.05",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "404.13",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "share",
                        width: "25.32",
                        height: "10.54",
                        left: "416.47",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "444.79",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "password",
                        width: "45.33",
                        height: "10.54",
                        left: "469.78",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "with",
                        width: "21.33",
                        height: "10.54",
                        left: "518.11",
                        top: "106.84",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "anyone.",
                        width: "37.65",
                        height: "10.55",
                        left: "72",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.55",
                        left: "114.15",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "urge",
                        width: "21.11",
                        height: "10.55",
                        left: "134.34",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.55",
                        left: "159.95",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.55",
                        left: "181.7",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.55",
                        left: "194.78",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "careful",
                        width: "33.3",
                        height: "10.55",
                        left: "209.86",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.55",
                        left: "246.91",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "giving",
                        width: "30.67",
                        height: "10.55",
                        left: "277.32",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "out",
                        width: "15.34",
                        height: "10.55",
                        left: "311.74",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.55",
                        left: "330.83",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.55",
                        left: "391.23",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "public",
                        width: "30",
                        height: "10.55",
                        left: "404.31",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "areas",
                        width: "24.64",
                        height: "10.55",
                        left: "438.06",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "466.45",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.55",
                        left: "480.2",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.55",
                        left: "498.61",
                        top: "93.04",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "93.66",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "153.31",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "share",
                        width: "25.32",
                        height: "10.54",
                        left: "174.31",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "202.63",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "public",
                        width: "30",
                        height: "10.54",
                        left: "214.96",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "areas",
                        width: "24.64",
                        height: "10.54",
                        left: "247.96",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "may",
                        width: "20.66",
                        height: "10.54",
                        left: "275.6",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "be",
                        width: "11.33",
                        height: "10.54",
                        left: "299.26",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "viewed",
                        width: "34.65",
                        height: "10.54",
                        left: "313.59",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "351.24",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "366.24",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "user",
                        width: "19.99",
                        height: "10.54",
                        left: "386.57",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "409.56",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "422.55",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.36",
                        height: "10.54",
                        left: "440.21",
                        top: "79.25",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,375.5", "lineto,344,375.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,463,362.5", "lineto,540,362.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,348.5", "lineto,222,348.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,320.5", "lineto,264,320.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,265.5", "lineto,233,265.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,415,251.5", "lineto,540,251.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,237.5", "lineto,103,237.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,205.5", "lineto,160,205.5"],
                },
              ],
            },
          },
        ],
      },
      {
        width: "612",
        height: "792",
        num: "11",
        dir: "ltr",
        orient: "portrait",
        columns: [
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Unfortunately,",
                        width: "69.53",
                        height: "10.54",
                        left: "72",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "149.03",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transmission",
                        width: "60.67",
                        height: "10.54",
                        left: "171.19",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "239.36",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.54",
                        left: "256.86",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "via",
                        width: "14.66",
                        height: "10.54",
                        left: "321.01",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "343.17",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "internet",
                        width: "36.65",
                        height: "10.54",
                        left: "365.33",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "408.73",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.54",
                        left: "423.49",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "completely",
                        width: "53.31",
                        height: "10.54",
                        left: "445.57",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "secure.",
                        width: "33.65",
                        height: "10.54",
                        left: "505.63",
                        top: "706.2",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "Although",
                        width: "45.33",
                        height: "10.54",
                        left: "72",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "121.08",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "do",
                        width: "12",
                        height: "10.54",
                        left: "138.83",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "154.58",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "best",
                        width: "19.33",
                        height: "10.54",
                        left: "174.32",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "197.4",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "protect",
                        width: "33.31",
                        height: "10.54",
                        left: "210.49",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "247.55",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "273.3",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "59.66",
                        height: "10.54",
                        left: "317.7",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "381.11",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "cannot",
                        width: "31.98",
                        height: "10.54",
                        left: "398.85",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "guarantee",
                        width: "46.64",
                        height: "10.54",
                        left: "434.58",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "484.22",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "security",
                        width: "37.99",
                        height: "10.54",
                        left: "501.88",
                        top: "692.4",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.54",
                        left: "90.25",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "120.49",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information",
                        width: "56.66",
                        height: "10.54",
                        left: "169.39",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transmitted",
                        width: "53.99",
                        height: "10.54",
                        left: "234.3",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "295.79",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "312.62",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "336.12",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Any",
                        width: "20.67",
                        height: "10.54",
                        left: "384.97",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "transmission",
                        width: "60.67",
                        height: "10.54",
                        left: "413.14",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "481.31",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.66",
                        height: "10.54",
                        left: "498.8",
                        top: "678.6",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "information",
                        width: "56.65",
                        height: "10.55",
                        left: "72",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.55",
                        left: "132.4",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.55",
                        left: "144.16",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "21.99",
                        height: "10.55",
                        left: "156.57",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "own",
                        width: "20.67",
                        height: "10.55",
                        left: "181.56",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "risk.",
                        width: "21",
                        height: "10.55",
                        left: "205.23",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.55",
                        left: "229.23",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.55",
                        left: "247.92",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "not",
                        width: "15.33",
                        height: "10.55",
                        left: "265.57",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "responsible",
                        width: "54.66",
                        height: "10.55",
                        left: "283.9",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.55",
                        left: "341.56",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "circumvention",
                        width: "69.31",
                        height: "10.55",
                        left: "358.55",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.55",
                        left: "430.86",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.55",
                        left: "443.86",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "privacy",
                        width: "35.98",
                        height: "10.55",
                        left: "464.19",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "settings",
                        width: "36.67",
                        height: "10.55",
                        left: "503.17",
                        top: "664.8",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "security",
                        width: "37.98",
                        height: "10.54",
                        left: "85",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "measures",
                        width: "44.65",
                        height: "10.54",
                        left: "125.98",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contained",
                        width: "46.65",
                        height: "10.54",
                        left: "173.63",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "223.28",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "238.28",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website.",
                        width: "41.35",
                        height: "10.54",
                        left: "255.94",
                        top: "651.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Changes",
                        width: "55.09",
                        height: "14.05",
                        left: "72",
                        top: "618.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "12.44",
                        height: "14.05",
                        left: "131.09",
                        top: "618.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Our",
                        width: "24.88",
                        height: "14.05",
                        left: "147.53",
                        top: "618.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "48.87",
                        height: "14.05",
                        left: "176.4",
                        top: "618.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "40.88",
                        height: "14.05",
                        left: "229.27",
                        top: "618.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "We",
                        width: "15.69",
                        height: "10.54",
                        left: "72",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "reserve",
                        width: "34.64",
                        height: "10.54",
                        left: "92.19",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "131.33",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "right",
                        width: "22.67",
                        height: "10.54",
                        left: "150.49",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "177.66",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "amend",
                        width: "31.99",
                        height: "10.54",
                        left: "191.49",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.33",
                        height: "10.54",
                        left: "227.98",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.66",
                        height: "10.54",
                        left: "249.81",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "30.67",
                        height: "10.54",
                        left: "290.97",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "326.14",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "339.3",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "discretion",
                        width: "47.33",
                        height: "10.54",
                        left: "359.79",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.54",
                        left: "411.62",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "433.44",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "446.6",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "time.",
                        width: "24.33",
                        height: "10.54",
                        left: "468.43",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "It",
                        width: "7.33",
                        height: "10.54",
                        left: "500.26",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8",
                        height: "10.54",
                        left: "512.09",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "523.84",
                        top: "590.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "policy",
                        width: "29.99",
                        height: "10.54",
                        left: "72",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "107.24",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "post",
                        width: "20",
                        height: "10.54",
                        left: "121.08",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "145.58",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "changes",
                        width: "38.65",
                        height: "10.54",
                        left: "167.41",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "210.56",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.99",
                        height: "10.54",
                        left: "229.05",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "259.54",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "273.37",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.65",
                        height: "10.54",
                        left: "293.87",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "30.67",
                        height: "10.54",
                        left: "335.02",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "370.19",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "386.69",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "page.",
                        width: "25.65",
                        height: "10.54",
                        left: "408.53",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "If",
                        width: "7.99",
                        height: "10.54",
                        left: "438.68",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "451.17",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "make",
                        width: "25.99",
                        height: "10.54",
                        left: "469.66",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "material",
                        width: "39.31",
                        height: "10.54",
                        left: "500.15",
                        top: "577.01",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "changes",
                        width: "38.65",
                        height: "10.54",
                        left: "72",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "114.4",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "how",
                        width: "20.67",
                        height: "10.54",
                        left: "126.73",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "13.99",
                        height: "10.54",
                        left: "150.4",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "treat",
                        width: "21.32",
                        height: "10.54",
                        left: "167.39",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "191.71",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "users'",
                        width: "26.83",
                        height: "10.54",
                        left: "210.7",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "personal",
                        width: "40.65",
                        height: "10.54",
                        left: "240.53",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "information,",
                        width: "59.65",
                        height: "10.54",
                        left: "284.18",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.54",
                        left: "346.83",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "will",
                        width: "18.66",
                        height: "10.54",
                        left: "363.83",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notify",
                        width: "28.67",
                        height: "10.54",
                        left: "385.49",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you",
                        width: "18",
                        height: "10.54",
                        left: "417.16",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "by",
                        width: "12",
                        height: "10.54",
                        left: "438.16",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.65",
                        height: "10.54",
                        left: "453.16",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.34",
                        height: "10.54",
                        left: "482.81",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "495.15",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.65",
                        height: "10.54",
                        left: "512.81",
                        top: "563.21",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "address",
                        width: "35.99",
                        height: "10.54",
                        left: "72",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "specified",
                        width: "43.31",
                        height: "10.54",
                        left: "111.74",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "in",
                        width: "9.33",
                        height: "10.54",
                        left: "158.8",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "22",
                        height: "10.54",
                        left: "171.88",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "account",
                        width: "37.31",
                        height: "10.54",
                        left: "197.63",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "(if",
                        width: "11.33",
                        height: "10.54",
                        left: "238.69",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "applicable)",
                        width: "53.3",
                        height: "10.54",
                        left: "253.02",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "309.32",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "through",
                        width: "37.33",
                        height: "10.54",
                        left: "329.65",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "a",
                        width: "5.32",
                        height: "10.54",
                        left: "369.98",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "notice",
                        width: "29.33",
                        height: "10.54",
                        left: "378.3",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "on",
                        width: "12",
                        height: "10.54",
                        left: "410.63",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "425.63",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.35",
                        height: "10.54",
                        left: "443.29",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "home",
                        width: "26.66",
                        height: "10.54",
                        left: "484.64",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "page.",
                        width: "25.65",
                        height: "10.54",
                        left: "514.3",
                        top: "549.41",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "The",
                        width: "18.66",
                        height: "10.54",
                        left: "72",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "date",
                        width: "19.98",
                        height: "10.54",
                        left: "96.66",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "122.64",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.66",
                        height: "10.54",
                        left: "145.98",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "30.66",
                        height: "10.54",
                        left: "188.64",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "was",
                        width: "18.67",
                        height: "10.54",
                        left: "225.3",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "last",
                        width: "16.66",
                        height: "10.54",
                        left: "249.97",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "revised",
                        width: "34.65",
                        height: "10.54",
                        left: "272.63",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "is",
                        width: "8.01",
                        height: "10.54",
                        left: "313.28",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "identified",
                        width: "45.98",
                        height: "10.54",
                        left: "327.29",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at",
                        width: "8.66",
                        height: "10.54",
                        left: "379.27",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "393.93",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "top",
                        width: "15.34",
                        height: "10.54",
                        left: "413.84",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "434.43",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "14.66",
                        height: "10.54",
                        left: "449.67",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "page.",
                        width: "25.65",
                        height: "10.54",
                        left: "469.58",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "You",
                        width: "19.47",
                        height: "10.54",
                        left: "500.48",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "are",
                        width: "14.65",
                        height: "10.54",
                        left: "525.2",
                        top: "535.61",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "responsible",
                        width: "54.66",
                        height: "10.55",
                        left: "72",
                        top: "521.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.55",
                        left: "130.41",
                        top: "521.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ensuring",
                        width: "41.32",
                        height: "10.55",
                        left: "148.15",
                        top: "521.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "we",
                        width: "14",
                        height: "10.55",
                        left: "193.22",
                        top: "521.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "have",
                        width: "22.65",
                        height: "10.55",
                        left: "210.97",
                        top: "521.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "an",
                        width: "11.33",
                        height: "10.55",
                        left: "237.37",
                        top: "521.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "up­to­date",
                        width: "49.31",
                        height: "10.55",
                        left: "252.45",
                        top: "521.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "active",
                        width: "28.64",
                        height: "10.55",
                        left: "304.76",
                        top: "521.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.55",
                        left: "336.4",
                        top: "521.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "deliverable",
                        width: "53.3",
                        height: "10.55",
                        left: "356.73",
                        top: "521.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "email",
                        width: "26.66",
                        height: "10.55",
                        left: "413.03",
                        top: "521.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "address",
                        width: "35.99",
                        height: "10.55",
                        left: "442.69",
                        top: "521.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.55",
                        left: "481.68",
                        top: "521.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "you,",
                        width: "21",
                        height: "10.55",
                        left: "498.67",
                        top: "521.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.32",
                        height: "10.55",
                        left: "522.67",
                        top: "521.81",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "72",
                        top: "508.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "periodically",
                        width: "57.31",
                        height: "10.54",
                        left: "91.24",
                        top: "508.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "visiting",
                        width: "36.01",
                        height: "10.54",
                        left: "153.8",
                        top: "508.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "15.99",
                        height: "10.54",
                        left: "195.06",
                        top: "508.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "38.36",
                        height: "10.54",
                        left: "216.3",
                        top: "508.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "259.91",
                        top: "508.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.33",
                        height: "10.54",
                        left: "282.49",
                        top: "508.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.66",
                        height: "10.54",
                        left: "305.07",
                        top: "508.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "30.67",
                        height: "10.54",
                        left: "346.23",
                        top: "508.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "to",
                        width: "9.33",
                        height: "10.54",
                        left: "381.4",
                        top: "508.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "check",
                        width: "27.98",
                        height: "10.54",
                        left: "395.23",
                        top: "508.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "for",
                        width: "13.99",
                        height: "10.54",
                        left: "427.71",
                        top: "508.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "any",
                        width: "17.33",
                        height: "10.54",
                        left: "446.2",
                        top: "508.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "changes.",
                        width: "41.65",
                        height: "10.54",
                        left: "468.03",
                        top: "508.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Your",
                        width: "25.33",
                        height: "10.54",
                        left: "514.18",
                        top: "508.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "continued",
                        width: "50.68",
                        height: "10.54",
                        left: "72",
                        top: "494.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "use",
                        width: "16.67",
                        height: "10.54",
                        left: "127.18",
                        top: "494.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "147.6",
                        top: "494.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "18",
                        height: "10.54",
                        left: "161.34",
                        top: "494.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Website",
                        width: "40.67",
                        height: "10.54",
                        left: "183.09",
                        top: "494.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "following",
                        width: "47.34",
                        height: "10.54",
                        left: "227.51",
                        top: "494.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "the",
                        width: "15.99",
                        height: "10.54",
                        left: "278.6",
                        top: "494.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "posting",
                        width: "37.35",
                        height: "10.54",
                        left: "298.34",
                        top: "494.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "339.44",
                        top: "494.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "changes",
                        width: "40.67",
                        height: "10.54",
                        left: "353.19",
                        top: "494.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "constitutes",
                        width: "54.66",
                        height: "10.54",
                        left: "397.61",
                        top: "494.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "your",
                        width: "24",
                        height: "10.54",
                        left: "456.02",
                        top: "494.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "acceptance",
                        width: "55.97",
                        height: "10.54",
                        left: "483.77",
                        top: "494.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                  {
                    words: [
                      {
                        text: "of",
                        width: "10",
                        height: "10.54",
                        left: "72",
                        top: "480.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "such",
                        width: "23.34",
                        height: "10.54",
                        left: "85",
                        top: "480.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "changes",
                        width: "40.67",
                        height: "10.54",
                        left: "111.34",
                        top: "480.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "152.01",
                        top: "480.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Contact",
                        width: "49.75",
                        height: "14.05",
                        left: "72",
                        top: "448.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Information",
                        width: "76.41",
                        height: "14.05",
                        left: "125.75",
                        top: "448.02",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "72",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "ask",
                        width: "16",
                        height: "10.54",
                        left: "87.49",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "questions",
                        width: "45.33",
                        height: "10.54",
                        left: "106.49",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "or",
                        width: "10",
                        height: "10.54",
                        left: "154.82",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "comment",
                        width: "44.65",
                        height: "10.54",
                        left: "167.82",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "about",
                        width: "26.66",
                        height: "10.54",
                        left: "215.47",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "this",
                        width: "17.34",
                        height: "10.54",
                        left: "245.13",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Privacy",
                        width: "36.66",
                        height: "10.54",
                        left: "265.47",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Policy",
                        width: "30.66",
                        height: "10.54",
                        left: "305.13",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "and",
                        width: "17.33",
                        height: "10.54",
                        left: "338.79",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "359.12",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "privacy",
                        width: "35.98",
                        height: "10.54",
                        left: "378.12",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "practices,",
                        width: "45.64",
                        height: "10.54",
                        left: "417.1",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "contact",
                        width: "34.64",
                        height: "10.54",
                        left: "465.74",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "us",
                        width: "10.67",
                        height: "10.54",
                        left: "503.38",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "at:",
                        width: "12",
                        height: "10.54",
                        left: "517.05",
                        top: "420.22",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "By",
                        width: "14",
                        height: "10.54",
                        left: "72",
                        top: "392.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Mail:",
                        width: "26",
                        height: "10.54",
                        left: "89",
                        top: "392.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Moore M&ore LLC. PO box 122416 Arlington, Tx 76012",
                        width: "31.99",
                        height: "10.54",
                        left: "144",
                        top: "392.42",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
              {
                lines: [
                  {
                    words: [
                      {
                        text: "By",
                        width: "14",
                        height: "10.54",
                        left: "72",
                        top: "351.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Phone:  1 (844) 444-4112",
                        width: "33.34",
                        height: "10.54",
                        left: "89",
                        top: "351.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "By",
                        width: "14",
                        height: "10.54",
                        left: "72",
                        top: "337.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Email: ",
                        width: "32",
                        height: "10.54",
                        left: "89",
                        top: "337.23",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "admin@mooreexotics.com",
                        width: "129.36",
                        height: "10.54",
                        left: "120.33",
                        top: "356.03",
                        color: "#1154cc",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                        link: "mailto:admin@mooreexotics.com",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "Terms",
                        width: "39.75",
                        height: "14.05",
                        left: "72",
                        top: "304.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "13.32",
                        height: "14.05",
                        left: "115.75",
                        top: "304.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use",
                        width: "24.88",
                        height: "14.05",
                        left: "133.07",
                        top: "304.83",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "16",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            paragraphs: [
              {
                lines: [
                  {
                    words: [
                      {
                        text: "To",
                        width: "12.49",
                        height: "10.54",
                        left: "72",
                        top: "277.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "see",
                        width: "15.32",
                        height: "10.54",
                        left: "87.49",
                        top: "277.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "our",
                        width: "16",
                        height: "10.54",
                        left: "105.81",
                        top: "277.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Terms",
                        width: "29.82",
                        height: "10.54",
                        left: "124.81",
                        top: "277.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "of",
                        width: "9.99",
                        height: "10.54",
                        left: "157.63",
                        top: "277.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "Use,",
                        width: "21.66",
                        height: "10.54",
                        left: "170.63",
                        top: "277.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "please",
                        width: "29.98",
                        height: "10.54",
                        left: "195.29",
                        top: "277.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "visit",
                        width: "20.67",
                        height: "10.54",
                        left: "228.27",
                        top: "277.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "ltr",
                        angle: "0",
                      },
                      {
                        text: "https://mooreexotics.com/conditions",
                        width: "174.98",
                        height: "10.54",
                        left: "251.94",
                        top: "277.03",
                        color: "#1154cc",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "1",
                        dir: "ltr",
                        angle: "0",
                        link: "https://mooreexotics.com/conditions",
                      },
                      {
                        text: ".",
                        width: "3",
                        height: "10.54",
                        left: "429.92",
                        top: "277.03",
                        color: "#000000",
                        fontName: "Times New Roman",
                        fontSize: "12",
                        underline: "0",
                        dir: "neutral",
                        angle: "0",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        fills: [
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,0,792",
                    "lineto,612,792",
                    "lineto,612,-0",
                    "lineto,0,-0",
                    "lineto,0,792",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,605",
                    "lineto,540,605",
                    "lineto,540,590",
                    "lineto,72,590",
                    "lineto,72,605",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,591",
                    "lineto,540,591",
                    "lineto,540,576",
                    "lineto,72,576",
                    "lineto,72,591",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,577",
                    "lineto,540,577",
                    "lineto,540,562",
                    "lineto,72,562",
                    "lineto,72,577",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,563",
                    "lineto,540,563",
                    "lineto,540,548",
                    "lineto,72,548",
                    "lineto,72,563",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,549",
                    "lineto,540,549",
                    "lineto,540,534",
                    "lineto,72,534",
                    "lineto,72,549",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,536",
                    "lineto,540,536",
                    "lineto,540,521",
                    "lineto,72,521",
                    "lineto,72,536",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,522",
                    "lineto,540,522",
                    "lineto,540,507",
                    "lineto,72,507",
                    "lineto,72,522",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,508",
                    "lineto,540,508",
                    "lineto,540,493",
                    "lineto,72,493",
                    "lineto,72,508",
                  ],
                },
              ],
            },
          },
          {
            rule: "nzwn",
            color: "#ffffff",
            path: {
              subpaths: [
                {
                  closed: "true",
                  points: [
                    "moveto,72,494",
                    "lineto,540,494",
                    "lineto,540,465",
                    "lineto,72,465",
                    "lineto,72,494",
                  ],
                },
              ],
            },
          },
        ],
        strokes: [
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,620.5", "lineto,270,620.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,449.5", "lineto,202,449.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#1154cc",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,144,338.5", "lineto,273,338.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#000000",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,72,306.5", "lineto,158,306.5"],
                },
              ],
            },
          },
          {
            width: "1",
            cap: "0",
            join: "0",
            color: "#1154cc",
            phase: "0",
            dashValues: [],
            path: {
              subpaths: [
                {
                  closed: "false",
                  points: ["moveto,252,278.5", "lineto,427,278.5"],
                },
              ],
            },
          },
        ],
      },
    ],
  },
];
