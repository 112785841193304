import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Typography,
  Container,
  Stack,
  Alert,
  Box,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Snackbar from "@mui/material/Snackbar";

import {
  DocumentScannerOutlined,
  ErrorOutline,
  LocalShippingOutlined,
  PaymentOutlined,
  PrivacyTipOutlined,
  ReviewsOutlined,
  Storefront,
  SupportAgentOutlined,
} from "@mui/icons-material";
import HandymanIcon from "@mui/icons-material/Handyman";
import CampaignIcon from "@mui/icons-material/Campaign";
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  EffectCoverflow,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PricingCard from "../../components/pricing/PricingCard";
import FAQs from "../../components/pricing/FAQs";
import config from "../../config";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/scrollbar";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";

const Membership = ({ user }) => {
  const classes = useStyles();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [planType, setPlanType] = useState("month");
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });

  useEffect(() => {
    const getPlans = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(`${config.SERVER_URL}/api/v1/plans`);
        const { data: registerRequestData } = registerRequest;
        if (registerRequest.status === 200) {
          setLoading(false);

          const filteredPlans = registerRequestData.filter(
            (plan) =>
              plan.interval === "month" || plan.name === "Diamond Special"
          );

          setPlans(registerRequestData);
          setFilteredPlans(filteredPlans);
        }
      } catch ({ response }) {
        setLoading(false);

        registerRequest = response;
        setSnackbarStates({
          open: true,
          message: "Unable to load plans right now. Please try again later.",
          severity: "danger",
        });
      }
    };
    getPlans();
  }, []);

  const handleClosePlanAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };
  SwiperCore.use([Autoplay]);

  const handleShowYearly = (planId) => {
    setPlanType("year");
  };

  const handleShowMonthly = (planId) => {
    setPlanType("month");
  };

  useEffect(() => {
    if (planType === "month") {
      const filteredPlans = plans.filter(
        (plan) => plan.interval === "month" || plan.name === "Diamond Special"
      );
      setFilteredPlans(filteredPlans);
    } else {
      const filteredPlans = plans.filter(
        (plan) => plan.interval === "year" || plan.name === "Diamond Special"
      );
      setFilteredPlans(filteredPlans);
    }
  }, [planType, plans]);

  return (
    <>
      <Helmet>
        <meta
          property="og:image"
          content="https://prodappdata.s3.amazonaws.com/preview-images/pricing-preview.png"
        />
        <meta property="og:title" content="Pricing" />
        <meta property="og:description" content="Moore Exotics Pricing" />
      </Helmet>
      <Snackbar
        open={snackbarStates.open}
        autoHideDuration={3000}
        onClose={handleClosePlanAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleClosePlanAlert}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <Stack
        className={classes.membershipContainer}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ pt: { sm: 2, md: 4, lg: 6, xl: 8 } }}
      >
        <Typography
          sx={{
            fontSize: { xs: "28px", sm: "32px", md: "36px", lg: "44px" },
            fontWeight: 600,
          }}
        >
          Membership Plans
        </Typography>
        {loading ? (
          <Box
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Container
              fixed
              sx={{
                width: {
                  xs: "95%",
                  sm: "65%",
                  md: "95%",
                  lg: "80%",
                  xl: "70%",
                },
                marginTop: { xs: 2, sm: 2, md: 4 },
              }}
            >
              <Swiper
                style={{
                  "--swiper-navigation-color": "rgb(255, 192, 1)",
                  "--swiper-pagination-color": "rgb(255, 192, 1)",
                }}
                navigation={true}
                modules={[Navigation, Pagination, Scrollbar, EffectCoverflow]}
                effect={"coverflow"}
                grabCursor={true}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 0.5,
                  slideShadows: true,
                }}
                autoplay={false}
                loop={true}
                pagination={{
                  clickable: true,
                  dynamicBullets: true,
                }}
                slidesPerView={3}
                initialSlide={0}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    slideActiveClass: classes.singleSlide,
                  },
                  480: {
                    slidesPerView: 1,
                    slideActiveClass: classes.singleSlide,
                  },
                  600: {
                    slidesPerView: 1,
                    slideActiveClass: classes.singleSlide,
                  },
                  900: {
                    slidesPerView: 3,
                    slideNextClass: classes.activeSlide,
                  },
                }}
              >
                {filteredPlans.length &&
                  filteredPlans.map((plan, index) => {
                    return (
                      <SwiperSlide>
                        <PricingCard
                          plan={plan}
                          user={user}
                          planType={planType}
                          handleShowMonthly={() => handleShowMonthly(plan.id)}
                          handleShowYearly={() => handleShowYearly(plan.id)}
                        />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </Container>
            <Container
              fixed
              maxWidth={90}
              sx={{
                width: {
                  xs: "95%",
                  sm: "65%",
                  md: "95%",
                  lg: "80%",
                  xl: "70%",
                },
                marginTop: { xs: 10, sm: 12, md: 14, lg: 20 },
                marginBottom: 4,
              }}
            >
              <FAQs faqs={faqs} />
            </Container>
          </>
        )}
      </Stack>
    </>
  );
};

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  membershipContainer: {
    backgroundColor: "#ebecf0",
  },
  activeSlide: {
    border: "2px solid rgb(255, 192, 1)",
  },
  singleSlide: {
    border: "none",
  },
});

const faqs = [
  {
    icon: LocalShippingOutlined,
    title: "How do I promote my services on your site?",
    paragraph:
      "To promote yourself or your business on our site, simply create your account and select which membership plan works best for you.",
  },
  {
    icon: Storefront,
    title:
      "In regards to the purchase of animals, is it illegal to do so on your website?",
    paragraph:
      "- We comply with all rules and regulations that are enforced by state and federal laws. If you are interested in partnering up with a breeder, we make sure that all sellers adhere to the necessary procedures when selling and distributing animals. Please view your state’s current rules and regulations regarding the purchase of exotic animals.",
  },
  {
    icon: SupportAgentOutlined,
    title:
      "How do I contact customer service if I have any issues or concerns?",
    paragraph:
      "You can contact our customer service team by email, phone, or through our social media outlets. We are always happy to assist you with any questions or concerns you may have. ",
  },

  {
    icon: ErrorOutline,
    title: "What happens if there is an issue with the service I selected?",
    paragraph:
      "As a hub for all things animals and services for your animals, we do not collect any kind of payment in regards to pet services. If there is an issue with the service you selected, we kindly urge you to contact the business themselves. However, if for any reason the business raises any kind of concern, we would be happy to look into it further with you.",
  },

  {
    icon: PaymentOutlined,
    title: "How do I get a refund in regards to the service I selected?",
    paragraph:
      "We understand that some things do not go the way we want them to. We do not collect any kind of payment in regards to services for your pet. Please follow up with the business that provided the service to you, in regard to your refund.",
  },

  {
    icon: DocumentScannerOutlined,
    title: "What forms of payment do you take?",
    paragraph:
      "- We make finding and selecting services for your pets easy and convenient by offering a variety of payment options. Currently, we accept major debit and credit cards, and we're constantly working to expand our payment options to make buying pets even more seamless.",
  },
  {
    icon: ReviewsOutlined,
    title: "How do I know if a business on your site is a reputable business?",
    paragraph:
      "We take safety and credibility very seriously. We have a thorough evaluation process that includes verifying each business before they are allowed to sell through our marketplace. We carefully monitor each seller's activities on the site to ensure they comply with our strict standards. We have a zero-tolerance policy for poor and inhumane business practices, and we work hard to maintain a safe and trusted environment for all pet lovers. When using services through Moore Exotics, you can be rest assured that you are dealing with a vetted and reputable business that has passed our rigorous screening process.",
  },
  {
    icon: HandymanIcon,
    title:
      " Will any documentation be needed for my business when using your service?",
    paragraph:
      "Compliance with regulations and legal requirements is very important to us. We ensure that all necessary documentation and licensing are provided by each business, and we work closely with all businesses to make sure they meet all legal requirements in regards to their business practices. Additional documentation may be required depending on the service you decide to get. If you have any questions or concerns about documentation, our team is here to assist you at every step of the transaction process.",
  },
  {
    icon: CampaignIcon,
    title: " Do You want to advertise on our site ? ",
    paragraph:
      "If you want to display your brand on our site, you can send us an email at admin@moorexotics.com with ad images and other contractual details.",
  },
];

const mapStateToProps = ({ Auth }) => ({
  user: Auth.user,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Membership);
