import { Box, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import "./ListDetailsCard.css";
import { makeStyles } from "@mui/styles";

const AboutPolicy = ({ listData }) => {
  const [showMore, setShowMore] = useState(false);
  const handleShowMore = () => {
    setShowMore(false);
  };

  const handleShowLess = () => {
    setShowMore(true);
  };

  const classes = useStyles();
  const dummyPolicies = [
    "1. Morph Market is not responsible for setting and communicating the return and refund policies.",
    "2. Actual appearance of animal may slightly vary, from the attached images.",
    " 3. Any request or demand that violates the law or is against legal and ethical standards will not be met or entertained. ",
  ];
  return (
    <>
      <Typography
        variant="h4"
        sx={{
          fontSize: "24px",
          marginBottom: "4px",
          fontWeight: "bolder",
          justifyContent: "left",
          alignItems: "left",
        }}
      >
        Store Policy
      </Typography>
      <Box
        sx={{
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        {showMore ? (
          <>
            {" "}
            <Typography
              sx={{
                fontSize: { xs: "9px", sm: "14px", md: "16px" },
                fontFamily: "Roboto",
              }}
            >
              Terms of Sale:
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "9px", sm: "14px", md: "16px" },
                fontFamily: "Roboto",
              }}
            >
              {" "}
              By making a purchase, you are accepting these terms.
            </Typography>{" "}
            <Typography
              sx={{
                fontSize: { xs: "9px", sm: "14px", md: "16px" },
                fontFamily: "Roboto",
              }}
            >
              {!listData?.seller?.store?.policy
                ? dummyPolicies.map((list) => <Typography>{list}</Typography>)
                : listData?.seller.store.policy}
            </Typography>{" "}
          </>
        ) : (
          <>
            {" "}
            <Typography
              sx={{
                fontSize: { xs: "9px", sm: "14px", md: "16px" },
                fontFamily: "Roboto",
              }}
            >
              Terms of Sale:
            </Typography>{" "}
            <Typography
              sx={{
                fontSize: { xs: "9px", sm: "14px", md: "16px" },
                fontFamily: "Roboto",
              }}
            >
              By making a purchase, you are accepting these terms.
            </Typography>{" "}
          </>
        )}
      </Box>
      <Box>
        {showMore ? (
          <Button
            className={classes.capitalizedButton}
            onClick={handleShowMore}
            variant="text"
          >
            View Less
          </Button>
        ) : (
          <Button
            className={classes.capitalizedButton}
            onClick={handleShowLess}
            variant="text"
          >
            View More
          </Button>
        )}{" "}
      </Box>
    </>
  );
};
const useStyles = makeStyles({
  capitalizedButton: {
    textTransform: "capitalize",
    textAlign: "left",
    justifyContent: "flex-start",
    paddingLeft: 0,
    color: "#d89700",
    fontFamily: "Roboto",
    fontWeight: "bolder",
    fontSize: "16px",
  },
});
export default AboutPolicy;
