import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config";
import { Box, Button, Grid, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";
import SellerPolicies from "./SellerPolicies";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const InquireDialog = ({
  open,
  brandName,
  user,
  jwtToken,
  listData,
  setOpen,
  setOpenSucessAlert,
  page,
}) => {
  const [inquireMessage, setInquireMessage] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPolicies, setOpenPolicies] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const isSmallToMediumScreen = useMediaQuery("(max-width: 960px)");
  const is1000pxScreen = useMediaQuery("(max-width: 1000px)");

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleClose = () => {
    setInquireMessage("");
    setCheckboxChecked(false);
    setOpen(false);
  };

  const handleInputChange = (e) => {
    setInquireMessage(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    setCheckboxChecked(e.target.checked);
  };

  const viewSellerPolicies = () => {
    setOpenPolicies(true);
  };

  const handleSave = async () => {
    setLoading(true);
    let registerRequest;
    try {
      if (page !== "Services") {
        registerRequest = await axios.post(
          `${config.SERVER_URL}/conversations`,
          {
            conversation: {
              listing_id: listData.id,
              title: listData.name,
            },
            message: {
              body: inquireMessage,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } else {
        registerRequest = await axios.post(
          `${config.SERVER_URL}/conversations`,
          {
            conversation: {
              listing_service_id: listData.id,
              title: listData.name,
            },
            message: {
              body: inquireMessage,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      }
    } catch ({ response }) {
      registerRequest = response;
    }

    const { data: registerRequestData } = registerRequest;
    setLoading(false);
    if (registerRequest.status === 200) {
      setOpenSucessAlert(true);
    }
    handleClose();
  };

  return (
    <>
      <Box position="relative">
        <SellerPolicies
          openPolicy={openPolicies}
          policyContent={listData?.seller.store?.policy}
          setOpenPolicies={setOpenPolicies}
        />

        <Dialog
          open={openDialog}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          fullScreen
          sx={{
            zIndex: "100",
            "& .MuiDialog-paper": {
              width: { md: "60%", xs: "90%" },
              maxHeight: { md: "68%", xs: "60%", sm: "58%" },
            },
          }}
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={12}>
                <Box display={"flex"}>
                  <Box
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ flex: 1 }}
                  >
                    <Typography
                      variant="h5"
                      textAlign={"center"}
                      fontSize={{ xs: "16px", md: "20px" }}
                      sx={{ fontWeight: "bolder" }}
                    >
                      Inquire About {listData.name} from{" "}
                      {listData.seller.store?.brand_name}
                    </Typography>
                  </Box>
                  <Box alignItems={"flex-end"} justifyContent={"flex-end"}>
                    <Box>
                      <IconButton>
                        <ClearIcon onClick={handleClose} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Divider sx={{ marginBottom: "10px" }} />

              <Box mb={4}>
                <TextField
                  disabled={loading ? true : false}
                  placeholder={"Your specific question goes here..."}
                  value={inquireMessage}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  rows={isSmallToMediumScreen ? 4 : 10}
                />
              </Box>
              <Typography
                p={2}
                mb={2}
                borderRadius={1}
                sx={{
                  backgroundColor: "#DCA500",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: { sx: "14px", md: "18px" },
                }}
              >
                {brandName}'s reply rate is very responsive and usually takes 1
                day
              </Typography>

              <Divider sx={{ marginBottom: "10px", marginTop: "10px" }} />
              <Typography mt={2} mb={0} pb={0}>
                Moore Exotics is not responsible for problem you may encounter
                with the seller.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              p={2}
            >
              <Grid item xs={is1000pxScreen ? 12 : 9}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    />
                  }
                  // "I've read and agree to the seller's policies on the ad."
                  label={
                    <Box
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{ mt: 0.5 }}
                      fontSize={{ xs: "10px", md: "12px" }}
                    >
                      <Typography
                        variant="p"
                        fontSize={{ xs: "8px", md: "12px" }}
                        textAlign={"center"}
                      >
                        <Typography component="span" pr={1}>
                          I've read and agreed to the
                        </Typography>

                        <Button
                          varient="text"
                          sx={{
                            textTransform: "capitalize",
                            color: "#d89700",
                            fontSize: { xs: "14px", md: "16px" },
                            fontWeight: "bold",
                            padding: 0,
                            m: 0,
                          }}
                          onClick={viewSellerPolicies}
                        >
                          Seller Policies
                        </Button>
                        <Typography component={"span"} pl={1}>
                          on the ad.
                        </Typography>
                      </Typography>
                    </Box>
                  }
                  render={(props) => (
                    <>
                      {props}
                      <Button variant="contained">Button</Button>
                    </>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={is1000pxScreen ? 12 : 3}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button onClick={handleClose} sx={{ marginRight: "10px" }}>
                  Cancel
                </Button>
                {inquireMessage === "" || !checkboxChecked ? (
                  <Button
                    onClick={handleSave}
                    disabled
                    sx={{ background: "#DCA500", maxWidth: "150px" }}
                    variant="contained"
                    autoFocus
                    startIcon={<EmailIcon sx={{ color: "white" }} />}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Send
                    </Typography>
                  </Button>
                ) : (
                  <>
                    {!loading ? (
                      <Button
                        onClick={handleSave}
                        sx={{ background: "#DCA500", maxWidth: "150px" }}
                        variant="contained"
                        autoFocus
                        startIcon={<EmailIcon sx={{ color: "white" }} />}
                        disabled={loading ? true : false}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          Send
                        </Typography>
                      </Button>
                    ) : (
                      <>
                        <Box>
                          <CircularProgress size={22} />
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};
const useStyles = makeStyles({
  capitalizedButton: {
    color: "#d89700",
    fontFamily: "SharpSans",
    fontWeight: "bolder",
    fontSize: "16px",
  },
});

const mapStateToProps = ({ Auth }) => ({
  jwtToken: Auth.jwtToken,
  user: Auth.user,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(InquireDialog);
