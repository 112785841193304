export const breedsRaw = [
  {
    id: "1",
    title: "Cow",
    classes: "Mammals",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
    ],
  },
  {
    id: "2",
    title: "Birds",
    classes: "Birds",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834159/Moore%20Exotics%20Assets/filter%20category/Birds_k32jwk.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834159/Moore%20Exotics%20Assets/filter%20category/Birds_k32jwk.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834159/Moore%20Exotics%20Assets/filter%20category/Birds_k32jwk.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834159/Moore%20Exotics%20Assets/filter%20category/Birds_k32jwk.jpg",
    ],
  },
  {
    id: "3",
    title: "Fishes",
    classes: "Fishes",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834194/Moore%20Exotics%20Assets/filter%20category/Fishes_gcwayp.jpg",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834194/Moore%20Exotics%20Assets/filter%20category/Fishes_gcwayp.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834194/Moore%20Exotics%20Assets/filter%20category/Fishes_gcwayp.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834194/Moore%20Exotics%20Assets/filter%20category/Fishes_gcwayp.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834194/Moore%20Exotics%20Assets/filter%20category/Fishes_gcwayp.jpg",
    ],
  },
  {
    id: "4",
    title: "Reptiles",
    classes: "Reptiles",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
    ],
  },
  {
    id: "5",
    title: "Frogs",
    classes: "Amphibians",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
    ],
  },
  {
    id: "6",
    title: "Insects",
    classes: "Insects",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
    ],
  },
  {
    id: "7",
    title: "Plants",
    classes: "Plants",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
    ],
  },
  {
    id: "8",
    title: "Frogs",
    classes: "Amphibians",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    ],
  },
  {
    id: "9",
    title: "Cow",
    classes: "Mammals",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
    ],
  },
  {
    id: "10",
    title: "Birds",
    classes: "Birds",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834159/Moore%20Exotics%20Assets/filter%20category/Birds_k32jwk.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834159/Moore%20Exotics%20Assets/filter%20category/Birds_k32jwk.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834159/Moore%20Exotics%20Assets/filter%20category/Birds_k32jwk.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834159/Moore%20Exotics%20Assets/filter%20category/Birds_k32jwk.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834159/Moore%20Exotics%20Assets/filter%20category/Birds_k32jwk.jpg",
    ],
  },
  {
    id: "11",
    title: "Fishes",
    classes: "Fishs",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834194/Moore%20Exotics%20Assets/filter%20category/Fishes_gcwayp.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834194/Moore%20Exotics%20Assets/filter%20category/Fishes_gcwayp.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834194/Moore%20Exotics%20Assets/filter%20category/Fishes_gcwayp.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834194/Moore%20Exotics%20Assets/filter%20category/Fishes_gcwayp.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834194/Moore%20Exotics%20Assets/filter%20category/Fishes_gcwayp.jpg",
    ],
  },
  {
    id: "12",
    title: "Reptiles",
    classes: "Reptiles",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
    ],
  },
  {
    id: "13",
    title: "Frogs",
    classes: "Amphibians",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
    ],
  },
  {
    id: "14",
    title: "Insects",
    classes: "Insects",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
    ],
  },
  {
    id: "15",
    title: "Plants",
    classes: "Plants",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
    ],
  },
  {
    id: "16",
    title: "Cow",
    classes: "Mammals",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834111/Moore%20Exotics%20Assets/filter%20category/Mammals_jera2g.jpg",
    ],
  },
  {
    id: "17",
    title: "Birds",
    classes: "Birds",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834159/Moore%20Exotics%20Assets/filter%20category/Birds_k32jwk.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834159/Moore%20Exotics%20Assets/filter%20category/Birds_k32jwk.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834159/Moore%20Exotics%20Assets/filter%20category/Birds_k32jwk.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834159/Moore%20Exotics%20Assets/filter%20category/Birds_k32jwk.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834159/Moore%20Exotics%20Assets/filter%20category/Birds_k32jwk.jpg",
    ],
  },
  {
    id: "18",
    title: "Fishes",
    classes: "Fishes",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834194/Moore%20Exotics%20Assets/filter%20category/Fishes_gcwayp.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834194/Moore%20Exotics%20Assets/filter%20category/Fishes_gcwayp.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834194/Moore%20Exotics%20Assets/filter%20category/Fishes_gcwayp.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834194/Moore%20Exotics%20Assets/filter%20category/Fishes_gcwayp.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834194/Moore%20Exotics%20Assets/filter%20category/Fishes_gcwayp.jpg",
    ],
  },
  {
    id: "19",
    title: "Reptiles",
    classes: "Reptiles",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
    ],
  },
  {
    id: "20",
    title: "Frogs",
    classes: "Amphibians",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
    ],
  },
  {
    id: "21",
    title: "Insects",
    classes: "Insects",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
    ],
  },
  {
    id: "22",
    title: "Plants",
    classes: "Plants",
    image:
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
    price: "1,246,235",
    moreImages: [
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
      "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
    ],
  },
];
