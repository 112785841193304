import Login from "../pages/login/Login";
import Signup from "../pages/signup/Signup";
import EmailVerification from "../pages/signup/EmailVerification";
import EmailConfirmation from "../pages/signup/EmailConfirmation";
import ResetPassword from "../pages/login/ResetPassword";
import Pricing from "../pages/pricing/Pricing";
import Categories from "../pages/categories/Categories";
import Breeds from "../pages/categories/breeds/Breeds";
import SubBreeds from "../pages/categories/breeds/subBreeds/SubBreeds";
import AboutUs from "../pages/about/AboutUs";
import TermsAndConditions from "../pages/conditions/TermsAndCondition";
import HomePage from "../pages/home/Home";
import Genetics from "../pages/categories/genetics/Genetics";
import ProductListing from "../pages/listing/ProductListing";
import ContactUs from "../pages/contact/ContactUs";
import ListDetail from "../pages/listing/listdetails/ListDetail";
import UserProfile from "../pages/profile/UserProfile";
import ViewUserProfile from "../pages/profile/ViewUserProfile";
import Services from "../pages/services/Services";
import Media from "../pages/media/Media";
import SellerDocuments from "../pages/seller/documents/SellerDocuments";
import CheckoutForm from "../components/checkout/CheckoutForm";
import BuyerLayout from "../layouts/buyer/BuyerLayout";
import SellerLayout from "../layouts/seller/SellerLayout";
import SettingLayout from "../layouts/settings/SettingLayout";
import SellerDashboard from "../pages/seller/dashboard/Dashboard";
import RecentOrder from "../pages/seller/recent_orders/RecentOrder";
import Listing from "../pages/seller/listing/Listing";
import Reviews from "../pages/seller/reviews/Reviews";
import SellerProfile from "../pages/seller/profile/SellerProfile";
import { Navigate } from "react-router-dom";
import { ProtectedRoute, ProtectedAuthRoute } from "../utils/ProtectedRoute";
import CreateOrEditList from "../pages/seller/listing/CreateOrEditList";
import Stores from "../pages/stores/Stores";
import StoreDetails from "../pages/stores/storedetails/StoreDetails";
import CreateOrEditSellerProfile from "../pages/seller/profile/CreateOrEditSellerProfile";
import Inbox from "../components/inbox/inbox";
import Dashboard from "../pages/setting/dashboard/Dashboard";
import ServiceList from "../pages/services/ServiceList";
import CreateOrEditService from "../pages/seller/service/CreateOrEditService";
import ServicesPageListings from "../pages/services/ServicesPageListings";
import Service from "../pages/seller/service/Service";
import PrivacyComponent from "../pages/privacy/PrivacyComponent";
import Shop from "../pages/shop/Shop";
import ServicesListingDetailPage from "../components/serviceListings/ServicesListingDetailPage";
import Education from "../pages/education/Education";

export const routesConfig = [
  //Buyer Layout Routes
  {
    element: <BuyerLayout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/about",
        element: (
          <ProtectedRoute>
            <AboutUs />
          </ProtectedRoute>
        ),
      },
      {
        path: "/policy",
        element: (
          <ProtectedRoute>
            <PrivacyComponent />
          </ProtectedRoute>
        ),
      },
      {
        path: "/conditions",
        element: (
          <ProtectedRoute>
            <TermsAndConditions />
          </ProtectedRoute>
        ),
      },
      {
        path: "/contact",
        element: (
          <ProtectedRoute>
            <ContactUs />
          </ProtectedRoute>
        ),
      },
      {
        path: "/shop",
        element: <Shop />,
      },
      {
        path: "/education",
        element: <Education />,
      },
      {
        path: "/services",
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <Services />
              </ProtectedRoute>
            ),
          },
          {
            path: ":serviceId",
            element: <ServiceList />,
          },
        ],
      },
      {
        path: "/services/:serviceId/subService/:subServiceId/listings",
        children: [
          {
            index: true,
            element: <ServicesPageListings />,
          },
          {
            path: ":listId",
            element: <ServicesListingDetailPage />,
          },
        ],
      },

      {
        path: "/service/listings/seller/:sellerId",
        element: <ServicesPageListings />,
      },
      {
        path: "/services/listings/classes/:categoryId",
        element: <ServicesPageListings />,
      },

      {
        path: "/services/listings",
        element: <ServicesPageListings />,
      },

      {
        path: "/media",
        element: <Media />,
      },
      {
        path: "/pricing",
        element: <Pricing />,
      },
      {
        path: "/conversations",
        element: (
          <ProtectedRoute>
            <Inbox />
          </ProtectedRoute>
        ),
      },
      {
        path: "/seller/register",
        element: (
          <ProtectedRoute>
            <SellerDocuments />
          </ProtectedRoute>
        ),
      },
      {
        path: "seller/checkout/plan/:planId",
        element: (
          <ProtectedRoute>
            <CheckoutForm />
          </ProtectedRoute>
        ),
      },
      {
        path: "/users/:userId",
        element: (
          <ProtectedRoute>
            <ViewUserProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: "/users/:userId/edit",
        element: (
          <ProtectedRoute>
            <UserProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: "/auth",
        children: [
          {
            path: "confirmation/user/:userId",
            element: (
              <ProtectedAuthRoute>
                <ResetPassword />
              </ProtectedAuthRoute>
            ),
          },
          {
            path: "login",
            children: [
              {
                index: true,
                element: (
                  <ProtectedAuthRoute>
                    <Login />
                  </ProtectedAuthRoute>
                ),
              },
              {
                path: "reset-password/verification",
                element: (
                  <ProtectedAuthRoute>
                    <EmailVerification type={"login"} />
                  </ProtectedAuthRoute>
                ),
              },
            ],
          },
          {
            path: "signup",
            children: [
              {
                index: true,
                element: (
                  <ProtectedAuthRoute>
                    <Signup />
                  </ProtectedAuthRoute>
                ),
              },
              {
                path: "verification",
                element: (
                  <ProtectedAuthRoute>
                    <EmailVerification type={"signup"} />
                  </ProtectedAuthRoute>
                ),
              },
              {
                path: "confirmation",
                element: (
                  <ProtectedAuthRoute>
                    <EmailConfirmation />
                  </ProtectedAuthRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "/listings",
        children: [
          {
            index: true,
            element: <ProductListing />,
          },
          {
            path: "subbreed/:subBreedId",
            element: <ProductListing />,
          },
        ],
      },
      {
        path: "/listings",
        children: [
          {
            index: true,
            element: <ProductListing />,
          },
          {
            path: "breed/:breedId",
            element: <ProductListing />,
          },
          {
            path: "genetics/:geneticsId",
            element: <ProductListing />,
          },
          {
            path: "subbreed/:subBreedId",
            element: <ProductListing />,
          },
        ],
      },
      {
        path: "/listings",
        children: [
          {
            index: true,
            element: <ProductListing />,
          },
          {
            path: "classes/:categoryId",
            element: <ProductListing />,
          },
        ],
      },
      {
        path: "/listings",
        children: [
          {
            path: "seller/:sellerId",
            element: <ProductListing />,
          },
        ],
      },
      // {
      //   path: "/listings",
      //   children: [
      //     {
      //       path: "featured/:is_featured",
      //       element: <ProductListing />,
      //     },
      //   ],
      // },
      {
        path: "/listing",
        children: [
          {
            path: ":listId",
            element: <ListDetail />,
          },
        ],
      },
      {
        path: "/neighbour",
        children: [
          {
            index: true,
            element: <Stores />,
          },
          {
            path: ":storeId",
            element: <StoreDetails />,
          },
        ],
      },
      {
        path: "/classes",
        children: [
          {
            index: true,
            element: <Categories />,
          },
          {
            path: ":categoryId/breeds",
            children: [
              {
                index: true,
                element: <Breeds />,
              },
              {
                path: ":breedId/subbreed/:subBreedId/genetics",
                children: [
                  {
                    index: true,
                    element: <Genetics />,
                  },
                ],
              },
              {
                path: ":breedId/subbreeds",
                children: [{ index: true, element: <SubBreeds /> }],
              },
              {
                path: ":breedId/genetics",
                children: [
                  {
                    index: true,
                    element: <Genetics />,
                  },
                  {
                    path: ":geneticId/subbreeds",
                    children: [
                      {
                        index: true,
                        element: <SubBreeds />,
                      },
                    ],
                  },
                  {
                    path: "listing",
                    children: [
                      {
                        index: true,
                        element: <ProductListing />,
                      },
                      {
                        path: ":listId",
                        element: <ListDetail />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    element: <SettingLayout />,
    children: [
      {
        path: "/settings",

        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
    ],
  },

  {
    element: <SellerLayout />,
    children: [
      {
        path: "/seller",
        children: [
          {
            path: "dashboard",
            element: (
              <ProtectedRoute>
                <SellerDashboard />
              </ProtectedRoute>
            ),
          },
          {
            path: "recent_orders",
            element: (
              <ProtectedRoute>
                <RecentOrder />
              </ProtectedRoute>
            ),
          },
          {
            path: "reviews",
            element: (
              <ProtectedRoute>
                <Reviews />
              </ProtectedRoute>
            ),
          },
          {
            path: "listing",
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute>
                    <Listing />
                  </ProtectedRoute>
                ),
              },
              {
                path: "create",
                element: (
                  <ProtectedRoute>
                    <CreateOrEditList />
                  </ProtectedRoute>
                ),
              },
              {
                path: "edit/:id",
                element: (
                  <ProtectedRoute>
                    <CreateOrEditList />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: "service",
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute>
                    <Service />
                  </ProtectedRoute>
                ),
              },
              {
                path: "create",
                element: (
                  <ProtectedRoute>
                    <CreateOrEditService />
                  </ProtectedRoute>
                ),
              },
              {
                path: "edit/:serviceId",
                element: (
                  <ProtectedRoute>
                    <CreateOrEditService />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: "profile",
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute>
                    <SellerProfile />
                  </ProtectedRoute>
                ),
              },
              {
                path: "edit/:storeId",
                element: (
                  <ProtectedRoute>
                    <CreateOrEditSellerProfile />
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  //Random Route
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];
