import * as Types from "../types/index";

const preState = {
  store: null,
};

const StoreReducer = (state = preState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_STORE:
      return {
        ...state,
        store: payload,
      };

    default:
      return state;
  }
};

export default StoreReducer;
