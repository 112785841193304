import React, { useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { connect } from "react-redux";
import config from "../../config";

const ResetPassword = ({ jwtToken }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
    responseMessage: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "password" || name === "confirmPassword") validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "password":
          if (value.length === 0) {
            stateObj[name] = "";
          } else if (value.length < 6) {
            stateObj[name] = "Password should be strong.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and confirm password does not match.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (value.length === 0) {
            stateObj[name] = "";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and confirm password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword((show) => !show);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fields = ["password"];
    const formElements = e.target.elements;

    const formValues = fields
      .map((field) => ({
        [field]: formElements.namedItem(field).value,
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    if (params.get("token")) {
      try {
        registerRequest = await axios.put(
          `${config.SERVER_URL}/api/users/password`,
          {
            user: {
              ...formValues,
              reset_password_token: params.get("token"),
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;
        setError({
          ...error,
          responseMessage:
            registerRequest.data.errors &&
            Object.keys(registerRequest.data.errors) +
              " " +
              Object.values(registerRequest.data.errors),
        });
      }

      if (registerRequest.status === 204) {
        return navigate("/auth/login");
      }
    }
  };

  return (
    <Stack
      style={{ backgroundColor: "#ebecf0" }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Card
        xl={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ padding: "30px", margin: "60px", width: { md: "30%" } }}
      >
        <CardContent>
          {!params.get("token") ? (
            <>
              <Typography
                variant="h4"
                align="center"
                className={classes.headerStyle}
                sx={{ color: "#FF5733 !important" }}
              >
                INVALID LINK!
              </Typography>
              <Typography>
                This is an Invalid Link. User has already used this token for
                password reset.
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="h4"
                align="center"
                className={classes.headerStyle}
              >
                Reset your password
              </Typography>
              <Typography>Enter your new password below.</Typography>
              <Typography component={"p"} sx={{ mt: "15px" }}>
                Password
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  helperText={
                    error.password && (
                      <span style={{ color: "red" }}>{error.password}</span>
                    )
                  }
                  placeholder="Enter your password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  fullWidth
                  size="small"
                  value={input.password}
                  onChange={onInputChange}
                  onBlur={validateInput}
                  InputProps={{
                    classes: {
                      root: classes.textField,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required
                />

                <Typography component={"p"} sx={{ mt: "15px" }}>
                  Confirm Password
                </Typography>
                <TextField
                  helperText={
                    error.confirmPassword && (
                      <span style={{ color: "red" }}>
                        {error.confirmPassword}
                      </span>
                    )
                  }
                  placeholder="Confirm your password"
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  fullWidth
                  size="small"
                  value={input.confirmPassword}
                  onChange={onInputChange}
                  onBlur={validateInput}
                  InputProps={{
                    classes: {
                      root: classes.textField,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required
                />

                <Button
                  type="submit"
                  variant="contained"
                  className={classes.loginButton}
                  fullWidth
                >
                  Change Password
                </Button>
              </form>
            </>
          )}

          <Typography>
            Back to{" "}
            <Link
              to="/auth/login"
              className={classes.links}
              style={{
                color: "rgb(255, 192, 1)",
              }}
            >
              Log In
            </Link>
          </Typography>
        </CardContent>
      </Card>
    </Stack>
  );
};

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  headerStyle: {
    fontStyle: "normal",
    color: "#000",
    fontWeight: "900 !important",
    marginBottom: "15px !important",
  },
  loginButton: {
    marginTop: "25px !important",
    height: "40px",
    color: "#000",
    fontSize: "15px !important",
    fontWeight: "900 !important",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "rgb(255, 192, 1)",
      color: "white !important",
    },
  },
  links: {
    fontSize: "16px",
    color: "black",
    textTransform: "none",
    textDecoration: "none",
    "&:hover": {
      color: "rgb(255, 192, 1)",
    },
  },
});

const mapStateToProps = ({ Auth }) => ({
  jwtToken: Auth.jwtToken,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
