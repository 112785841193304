import { Grid } from "@mui/material";
import React from "react";
import RecentOrders from "../../../components/seller/recent_orders/RecentOrders";

const RecentOrder = () => {
  return (
    <Grid xs={12}>
      <RecentOrders />
    </Grid>
  );
};

export default RecentOrder;
