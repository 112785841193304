import React from "react";
import { Stack, Typography, Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SwiperCore, { Autoplay, Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

const CompanyCertificates = () => {
  const classes = useStyles();
  SwiperCore.use([Autoplay]);

  return (
    <Stack className={classes.mainContainer}>
      <Box className={classes.headerBox}>
        <Typography
          sx={{
            fontSize: {
              xs: "14px",
              sm: "16px",
              md: "18px",
              lg: "20px",
              xl: "22px",
            },
          }}
        >
          What we do
        </Typography>
      </Box>
      <Box className={classes.headerBox}>
        <Typography
          sx={{
            fontSize: {
              xs: "20px",
              sm: "36px",
              md: "44px",
              lg: "48px",
              xl: "54px",
            },
            fontWeight: "700",
          }}
        >
          Company Certifications
        </Typography>
      </Box>
      <Box
        className={classes.headerBox}
        sx={{
          padding: { lg: "10px 100px 10px 100px", xl: "10px 250px 10px 250px" },
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "12px",
              sm: "12px",
              md: "14px",
              lg: "16px",
              xl: "18px",
            },
            padding: {
              sm: "0px 100px 0px 100px",
            },
          }}
        >
          We want to be your go to place to find your next pet or find a service
          to help care for your best friend. Since our core values are rooted in
          promoting safe and ethical business practices, here are few things we
          can guarantee you our company can provide you.
        </Typography>
      </Box>
      <Grid
        container
        direction="row"
        spacing={4}
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          paddingTop: 5,
          paddingRight: "60px",
          paddingLeft: "60px",
        }}
      >
        {certificates &&
          certificates.map((cert) => {
            return (
              <Grid
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
                direction="column"
                md={4}
              >
                <Box>
                  <img
                    key={cert.image}
                    alt={cert.image}
                    src={
                      window.location.origin +
                      "/images/certificates/" +
                      cert.image
                    }
                    style={{ width: "75px" }}
                  ></img>
                </Box>
                <Typography
                  sx={{
                    fontSize: { xs: "18px", sm: "24px" },
                    color: "#fff",
                    fontWeight: "700",
                  }}
                >
                  {cert.title}
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    textAlign: "center",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "16px",
                      xl: "18px",
                    },
                    padding: {
                      sm: "0px 100px 0px 100px",
                      md: "0px 10px 0px 10px",
                      lg: "0px 20px 0px 20px",
                      xl: "0px 30px 0px 30px",
                    },
                  }}
                >
                  {cert.description}
                </Typography>
              </Grid>
            );
          })}
      </Grid>
      <Grid
        container
        alignContent="center"
        justifyContent="center"
        direction="row"
        sx={{
          display: { xs: "flex", sm: "flex", md: "none" },
          marginTop: 5,
        }}
      >
        <Swiper
          style={{
            "--swiper-navigation-color": "rgb(255, 192, 1)",
            "--swiper-pagination-color": "rgb(255, 192, 1)",
          }}
          navigation={true}
          modules={[Navigation, Scrollbar]}
          grabCursor={true}
          autoplay={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={1}
        >
          {certificates &&
            certificates.map((cert) => {
              return (
                <SwiperSlide>
                  <Grid
                    item
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    xs={12}
                    sm={12}
                    md={0}
                  >
                    <Box>
                      <img
                        key={cert.image}
                        alt={cert.image}
                        src={
                          window.location.origin +
                          "/images/certificates/" +
                          cert.image
                        }
                        style={{ width: "75px" }}
                      ></img>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "18px", sm: "24px" },
                        color: "#fff",
                        fontWeight: "700",
                      }}
                    >
                      {cert.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "14px",
                          lg: "16px",
                          xl: "18px",
                        },
                        padding: {
                          xs: "0px 100px 0px 100px",
                          sm: "0px 200px 0px 200px",
                          md: "0px 10px 0px 10px",
                          lg: "0px 20px 0px 20px",
                          xl: "0px 30px 0px 30px",
                        },
                      }}
                    >
                      {cert.description}
                    </Typography>
                  </Grid>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </Grid>
    </Stack>
  );
};

const certificates = [
  {
    title: "Transparency",
    description:
      "We require everyone who uses our site to be transparent regarding the safety and well being of the animals in their care. With the proper documentation, we only allow reputable businesses to provide their services on our platform.",
    image: "Transparency.png",
  },
  {
    title: "Customer Satisfaction",
    description:
      "Whether you are looking for your next pet, finding a trainer, or even a groomer, we are always here to help. Regarding all your questions and concerns, we are happy to answer them for you!",

    image: "CustomerSatisfaction.png",
  },
  {
    title: "Safety",
    description:
      "Not only is the safety of animals important, but the safety of everyone that uses our services is a high priority of ours as well. Through regulated business standards, we will make sure that you are also in great hands.",
    image: "Safety.png",
  },
  {
    title: "Ease",
    description:
      "Our service is easy to use and to navigate. From our instruction videos, to our open social media outlets, we will provide all the necessary tools to help you meet your next pet or your next client.",
    image: "Ease.png",
  },
  {
    title: "Community",
    description:
      "Join a vibrant neighbour of pet enthusiasts who understand the joy and responsibility of pet ownership. Share stories, seek advice, and connect with like-minded individuals who share your passion.",
    image: "Dependability.png",
  },
  {
    title: "Accessibility",
    description:
      "All our information about our company and the services that we promote are just a click away! We promise to provide you with the necessary information to care for your pets, how to proceed with a purchase, as well as who you are buying from.",
    image: "Accessibility.png",
  },
];

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  mainContainer: {
    backgroundColor: "#242124",
    width: "100%",
    paddingTop: "60px",
    paddingBottom: "60px",
  },
  headerBox: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default CompanyCertificates;
