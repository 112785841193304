import axios from "axios";
import * as Types from "../types/Listing.types";
import config from "../../config";

export const thunkLoadServiceListings =
  ({
    service_id,
    sub_service_id,
    seller_id,
    store_id,
    category_id,
    jwtToken,
    is_Featured,
    filtersData,
    pageNumber,
    rowsPerPage,
  }) =>
    async (dispatch) => {
      dispatch(setServiceListingsLoading());

      let registerRequest;
      let url;
      const headers = {
        "Content-Type": "application/json",
      };

      if (jwtToken) {
        headers["Authorization"] = `Bearer ${jwtToken}`;
      }
      if (service_id && sub_service_id) {
        url = `${config.SERVER_URL}/api/v1/listing_services?page=${pageNumber + 1
          }&per_page=${rowsPerPage}&service_id=${service_id}&sub_service_id=${sub_service_id}`;
      } else if (category_id) {
        url = `${config.SERVER_URL}/api/v1/listing_services?page=${pageNumber + 1
          }&per_page=${rowsPerPage}&category_id=${category_id}`;
      } else if (seller_id) {
        url = `${config.SERVER_URL}/api/v1/listing_services?page=${pageNumber + 1
          }&per_page=${rowsPerPage}&seller_id=${seller_id}`;
      } else if (store_id) {
        url = `${config.SERVER_URL}/api/v1/listing_services?page=${pageNumber + 1
          }&per_page=${rowsPerPage}&store_id=${store_id}`;
      } else if (is_Featured) {
        url = `${config.SERVER_URL}/api/v1/listing_services?page=${pageNumber + 1
          }&per_page=${rowsPerPage}&is_featured=${is_Featured}`;
      } else if (filtersData) {
        url = `${config.SERVER_URL}/api/v1/listing_services?page=${pageNumber + 1
          }&per_page=${rowsPerPage}`;
      } else {
        url = `${config.SERVER_URL}/api/v1/listing_services?page=${pageNumber + 1
          }&per_page=${rowsPerPage}`;
      }
      try {
        if (filtersData) {
          registerRequest = await axios.get(url, {
            headers,
            params: filtersData,
          });
        } else {
          registerRequest = await axios.get(url, {
            headers,
          });
        }

        const { data: registerRequestData } = registerRequest;
        if (registerRequest.status === 200) {
          dispatch(
            loadServiceListings({
              listings: registerRequestData.data,
              loading: false,
              totalListing: registerRequestData.total_listing
                ? registerRequestData.total_listing
                : registerRequestData.total_listings,
            })
          );
        }
      } catch (errorServices) {
        dispatch(
          errorLoadServiceListings({
            errorServices:
              "Unable to fetch services right now. Please try again later.",
          })
        );
      }
    };

export const thunkLoadListing =
  ({
    pageNumber,
    rowsPerPage,
    breedId,
    categoryId,
    sellerId,
    isFeatured,
    jwtToken,
    subbreedId,
    filtersData,
    geneticId,
    searchQuery
  }) =>
    async (dispatch) => {
      console.log("Search Query", searchQuery)
      dispatch(setLoading());

      let registerRequest;
      let url;
      const headers = {
        "Content-Type": "application/json",
      };

      if (jwtToken) {
        headers["Authorization"] = `Bearer ${jwtToken}`;
      }
      if (subbreedId) {
        url = `${config.SERVER_URL}/api/v1/all_listings?page=${pageNumber + 1
          }&per_page=${rowsPerPage}&sub_breed_id=${subbreedId}`;
      } else if (breedId) {
        url = `${config.SERVER_URL}/api/v1/all_listings?page=${pageNumber + 1
          }&per_page=${rowsPerPage}&breed_id=${breedId}`;
      } else if (categoryId) {
        url = `${config.SERVER_URL}/api/v1/all_listings?page=${pageNumber + 1
          }&per_page=${rowsPerPage}&category_id=${categoryId}`;
      } else if (sellerId) {
        url = `${config.SERVER_URL}/api/v1/seller/${sellerId}/animals?page=${pageNumber + 1
          }&per_page=${rowsPerPage}`;
      } else if (isFeatured) {
        url = `${config.SERVER_URL}/api/v1/all_listings?page=${pageNumber + 1
          }&per_page=${rowsPerPage}&is_featured=${isFeatured}`;
      } else if (geneticId) {
        url = `${config.SERVER_URL}/api/v1/all_listings?page=${pageNumber + 1
          }&per_page=${rowsPerPage}&genetic_id=${geneticId}`;
      } else if (searchQuery) {
        url = `${config.SERVER_URL}/api/v1/all_listings?page=${pageNumber + 1
          }&per_page=${rowsPerPage}&search=${searchQuery}`;
      } else if (filtersData) {
        url = `${config.SERVER_URL}/api/v1/all_listings?page=${pageNumber + 1
          }&per_page=${rowsPerPage}`;
      } else {
        url = `${config.SERVER_URL}/api/v1/all_listings?page=${pageNumber + 1
          }&per_page=${rowsPerPage}`;
      }

      try {
        if (filtersData) {
          registerRequest = await axios.get(url, {
            headers,
            params: filtersData,
          });
        } else {
          registerRequest = await axios.get(url, {
            headers,
          });
        }

        const { data: registerRequestData } = registerRequest;
        if (registerRequest.status === 200) {
          dispatch(
            loadListings({
              listings: registerRequestData.data,
              loading: false,
              totalListing: registerRequestData.total_listing
                ? registerRequestData.total_listing
                : registerRequestData.total_listings,
            })
          );
        }
      } catch (errorList) {
        dispatch(
          errorLoadListings({
            errorList:
              "Unable to fetch listings right now. Please try again later.",
          })
        );
      }
    };

export const removeListings = () => ({
  type: Types.REMOVE_LISTINGS,
});

export const removeListingsContent = () => async (dispatch) => {
  dispatch(removeListings());
};
const loadServiceListings = (payload) => ({
  type: Types.LOAD_SERVICE_LISTING,
  payload,
});
const setServiceListingsLoading = (payload) => ({
  type: Types.LOADING_SERVICE_LISTING,
  payload,
});
const loadListings = (payload) => ({
  type: Types.LOAD_LISTING,
  payload,
});
const errorLoadListings = (payload) => ({
  type: Types.ERROR_LOAD_LISTING,
  payload,
});
const errorLoadServiceListings = (payload) => ({
  type: Types.ERROR_LOAD_SERVICE_LISTING,
  payload,
});
const setLoading = () => ({
  type: Types.START_LOADING,
});
