import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import SellerDashboardNav from "../../components/seller/navbar/NavList";
import SellerDashboardDrawer from "../../components/seller/navbar/SellerDrawer";
import * as Actions from "../../redux/actions";

function SellerLayout({ getUserBillingPortal, user, seller }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      getUserBillingPortal(user.id);
    } else {
      navigate("/auth/login");
    }
  }, []);
  return (
    <>
      {seller && seller.approval_status === "approved" ? (
        <>
          <SellerDashboardDrawer />
          <Grid
            container
            md={12}
            sx={{
              backgroundColor: "#ebecf0",
              width: "100vw",
              overflowX: "hidden",
            }}
          >
            <Grid
              item
              md={2}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  position: "sticky",
                  zIndex: 5,
                },
              }}
            >
              <SellerDashboardNav />
            </Grid>
            <Grid item xs={12} md={10}>
              <Outlet />
            </Grid>
          </Grid>
        </>
      ) : (
        navigate("/seller/register")
      )}
    </>
  );
}

const mapStateToProps = ({ Auth, Seller }) => ({
  responseErrors: Auth.responseError,
  user: Auth.user,
  seller: Seller.seller,
});

const mapDispatchToProps = (dispatch) => ({
  getUserBillingPortal: (userId) =>
    dispatch(Actions.thunkGetUserBillingPortal(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SellerLayout);
