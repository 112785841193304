import CategoriesReducer from "./Categories.reducer";
import AuthReducer from "./Auth.reducer";
import SellerReducer from "./Seller.reducer";
import ListingsReducer from "./Listings.reducer";
import StoreReducer from "./Store.reducer";
import MessageReducer from "./Messages.reducer";
import ServicesReducer from "./Services.reducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  Categories: CategoriesReducer,
  Auth: AuthReducer,
  Seller: SellerReducer,
  Listings: ListingsReducer,
  Store: StoreReducer,
  Messages: MessageReducer,
  Services: ServicesReducer,
});

export default rootReducer;
