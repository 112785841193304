import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Box,
  Stack,
  Divider,
  IconButton,
  CircularProgress,
  imageListItemClasses,
  Avatar,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  Facebook,
  Instagram,
  LocationOn,
  Person2,
  PhoneEnabled,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import PaymentIcon from "@mui/icons-material/Payment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import CakeIcon from "@mui/icons-material/Cake";
import config from "../../config";

const ViewUserProfile = ({ jwtToken, seller }) => {
  const [loading, setLoading] = useState(false);
  const { userId } = useParams();
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/users/edit`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;
      }
      if (registerRequest.status === 200) {
        setUserProfile(registerRequest.data.data);
      }
    };
    fetchUser();
  }, [jwtToken]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack
      style={{
        backgroundColor: "#ebecf0",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alginItem="center"
      >
        <Grid item display="flex">
          <Box
            xl={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              paddingTop: 2,
              paddingBottom: 2,
              margin: "auto",
              width: { xs: "90%", sm: "90%", md: "80%" },
            }}
          >
            <Typography variant="h4" align="left">
              User Profile
            </Typography>
            <Typography
              align="left"
              sx={{ fontSize: { xs: "14px", md: "16px" } }}
            >
              Your Profile will only be visible to sellers that you choose to
              interact with by sending inquiries. It will save you time by
              providing information necessary to complete sales.
            </Typography>

            {userProfile && (
              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      paddingY: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "auto",
                    }}
                  >
                    <Typography
                      variant="h5"
                      align="left"
                      sx={{ fontWeight: "600" }}
                    >
                      {userProfile.brandName}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        component={Link}
                        to={!seller ? "/seller/register" : "/seller/profile"}
                        size="large"
                        sx={{
                          textAlign: "center",
                          marginTop: "10px",
                          border: "1px solid #bbbbbb",
                          textTransform: "none",
                          backgroundColor: "rgb(255, 192, 1) !important",
                          color: "white !important",
                          "&:hover": {
                            backgroundColor: "rgb(255, 192, 1)",
                          },
                        }}
                        paddingX={2}
                        variant="contained"
                      >
                        <KeyboardBackspaceIcon
                          fontSize="20px"
                          sx={{
                            marginRight: 1,
                          }}
                        ></KeyboardBackspaceIcon>
                        {!seller ? "Register As A Seller" : "Seller Dashboard"}
                      </Button>
                      <Button
                        component={Link}
                        to={`/users/${userId}/edit`}
                        size="large"
                        sx={{
                          textAlign: "center",
                          marginTop: "10px",
                          border: "1px solid #bbbbbb",
                          textTransform: "none",
                          backgroundColor: "rgb(255, 192, 1) !important",
                          color: "white !important",
                          "&:hover": {
                            backgroundColor: "rgb(255, 192, 1)",
                          },
                        }}
                        paddingX={2}
                        variant="contained"
                      >
                        <EditIcon
                          fontSize=""
                          sx={{
                            marginRight: 1,
                          }}
                        ></EditIcon>
                        Edit
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
            {userProfile && (
              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={4} sm={4} md={3} lg={3}>
                  {userProfile.user_profile.url && (
                    <img
                      alt="qr-code"
                      src={
                        userProfile.user_profile.url
                      }
                      style={{
                        width: "80%",
                        height: "60%",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={9}>
                  <Box
                    sx={{
                      paddingY: 3,
                      margin: "auto",
                    }}
                  >
                    <Grid container direction="row">
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            gap: "20px",
                            mb: 2,
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Person2
                              fontSize="small"
                              sx={{ pr: 0.5, color: "grey" }}
                            />
                            <Typography variant="p">
                              {userProfile.full_name}
                            </Typography>
                          </Box>

                          {userProfile.phone && (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <PhoneEnabled
                                fontSize="small"
                                sx={{ pr: 0.5, color: "grey" }}
                              />
                              <Typography variant="p">
                                {userProfile.phone}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            gap: "20px",
                          }}
                        >
                          {userProfile.date_of_birth && (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CakeIcon
                                fontSize="small"
                                sx={{ pr: 0.5, color: "grey" }}
                              />
                              <Typography variant="p">
                                {userProfile.date_of_birth}
                              </Typography>
                            </Box>
                          )}
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <EmailIcon
                              fontSize="small"
                              sx={{ pr: 0.5, color: "grey" }}
                            />
                            <Typography variant="p">
                              {userProfile.email}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box paddingY={3}>
                          {userProfile.twitter_url && (
                            <Link
                              to={userProfile.twitter_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ border: "1px solid grey", mr: 1 }}
                              >
                                <Twitter />
                              </IconButton>
                            </Link>
                          )}
                          {userProfile.facebook_url && (
                            <Link
                              to={userProfile.facebook_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ border: "1px solid grey", mr: 1 }}
                              >
                                <Facebook />
                              </IconButton>
                            </Link>
                          )}
                          {userProfile.youtube_url && (
                            <Link
                              to={userProfile.youtube_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ border: "1px solid grey", mr: 1 }}
                              >
                                <YouTube />
                              </IconButton>
                            </Link>
                          )}
                          {userProfile.instagram_url && (
                            <Link
                              to={userProfile.instagram_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ border: "1px solid grey", mr: 1 }}
                              >
                                <Instagram />
                              </IconButton>
                            </Link>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Divider />
                  {userProfile.about && (
                    <Grid container direction="row" sx={{ mt: 2, mb: 2 }}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          align="left"
                          sx={{ fontWeight: "600" }}
                        >
                          About
                        </Typography>

                        <Box paddingY={2}>
                          <Typography
                            variant="p"
                            align="left"
                            textAlign={"justify"}
                          >
                            {userProfile.about}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};
const mapStateToProps = ({ Auth, Seller }) => ({
  jwtToken: Auth.jwtToken,
  seller: Seller.seller,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ViewUserProfile);
