import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { motion } from "framer-motion";
import { Box } from "@mui/material";

export default function SimpleAccordion({ faqs }) {
  const [showAnimation, setShowAnimation] = React.useState(true);

  const handleShowAnimations = () => {
    if (showAnimation) {
      setShowAnimation(false);
    }
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: "28px", sm: "32px", md: "36px", lg: "44px" },
          fontWeight: 600,
          textAlign: "center",
          mb: 4,
        }}
      >
        FAQs
      </Typography>
      {faqs.length &&
        faqs.map((faq) => {
          return (
            <Accordion
              disableGutters
              sx={{
                backgroundColor: "#ebecf0",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      "&:hover": {
                        color: "rgb(255, 192, 1)",
                      },
                    }}
                  />
                }
                sx={{
                  "&:hover": {
                    color: "rgb(255, 192, 1)",
                  },
                }}
              >
                {faq.icon && (
                  <motion.div
                    initial={{ opacity: 0, x: showAnimation ? -100 : 0 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: showAnimation ? 1 : 0 }}
                    onViewportLeave={handleShowAnimations}
                  >
                    <faq.icon
                      sx={{
                        fontWeight: 900,
                        fontSize: "26px",
                        mr: 1,
                        mt: 0.7,
                      }}
                    />
                  </motion.div>
                )}
                <motion.div
                  initial={{ opacity: 0, x: showAnimation ? -100 : 0 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: showAnimation ? 1 : 0 }}
                  onViewportLeave={handleShowAnimations}
                >
                  <Typography variant="h6">{faq.title}</Typography>
                </motion.div>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="p">{faq.paragraph}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Box>
  );
}
