import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  useMediaQuery,
  CircularProgress,
  Avatar,
} from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../../config";
import { Box, Button, Grid, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import ClearIcon from "@mui/icons-material/Clear";
import Rating from "@mui/material/Rating";
import { makeStyles } from "@mui/styles";
// import SellerPolicies from "./SellerPolicies";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const SeeAllReviews = ({ data, openDialog, setOpenDialog }) => {
  useEffect(() => {
    setOpenDialog(openDialog);
  }, [openDialog]);

  const handleClose = () => {
    setOpenDialog(false);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const formattedDate = date.toLocaleDateString(undefined, options);
    return formattedDate;
  };

  return (
    <>
      <Box position="relative">
        <Dialog
          open={openDialog}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          fullScreen
          sx={{
            zIndex: "100",
            "& .MuiDialog-paper": {
              width: { md: "60%", xs: "90%" },
              maxHeight: { md: "68%", xs: "60%", sm: "58%" },
              borderRadius: "20px",
            },
          }}
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={12}>
                <Box display={"flex"}>
                  <Box
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ flex: 1 }}
                  >
                    <Typography
                      variant="h5"
                      textAlign={"center"}
                      fontSize={{ xs: "16px", md: "20px" }}
                      sx={{ fontWeight: "bolder" }}
                    >
                      Reviews ({data.length})
                    </Typography>
                  </Box>
                  <Box alignItems={"flex-end"} justifyContent={"flex-end"}>
                    <Box>
                      <IconButton>
                        <ClearIcon onClick={handleClose} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <>
              {data.map((data) => {
                return (
                  <>
                    <Divider sx={{ marginBottom: "10px", marginTop: "10px" }} />

                    <Box flex={1} display={"flex"} mt={2} pl={2}>
                      <Box display={"flex"}>
                        <Avatar alt="Remy Sharp" src={data.user.avatar_url} />
                      </Box>
                      <Box pl={2}>
                        <Typography fontWeight={"bold"}>
                          {data.user.full_name}
                        </Typography>
                        <Typography fontSize={"12px"} color={"grey"}>
                          {formatDate(data.created_at)} . {data.reviewable.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box pl={9}>
                      <Rating
                        name="simple-controlled"
                        value={data.rating}
                        sx={{ fontSize: "16px" }}
                        readOnly
                      />
                    </Box>
                    <Box pl={9}>
                      <Typography sx={{ wordBreak: "break-all" }}>
                        {" "}
                        {data.content}
                      </Typography>
                    </Box>
                  </>
                );
              })}
            </>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};
const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(SeeAllReviews);
