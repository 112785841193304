import {
  Alert,
  Box,
  Button,
  Grid,
  Slide,
  Snackbar,
  Typography,
  Select,
  MenuItem,
  TablePagination,
} from "@mui/material";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ListingsList from "../../../components/seller/listings/ListingsList";
import CustomModal from "../../../components/seller/listings/Modal";
import config from "../../../config";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  hover: {
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#eeeeee" },
  },
}));

const filters = [
  { id: "all", name: "All" },
  { id: "active_listing", name: "Active" },
  { id: "sold", name: "Sold" },
  { id: "pending", name: "Pending" },
  { id: "rejected", name: "Rejected" },
  { id: "nfs", name: "NFS" },
  { id: "featured", name: "Featured" },
  { id: "on_hold", name: "On Hold" },
];

const ListingTopBar = ({ classes, seller, jwtToken, user, store }) => {
  const styleclasses = useStyles();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openFeatureLimiExceededModal, setOpenFeatureLimiExceededModal] =
    useState(false);
  const [featureLimitMessage, setFeatureLimitMessage] = useState("");
  const [filter, setFilter] = useState(filters[0].id);
  const [listings, setListings] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmProp, setConfirmProp] = useState(false);
  const [confirmProp1, setConfirmProp1] = useState(false);
  const [confirmProp2, setConfirmProp2] = useState(false);
  const [confirmProp3, setConfirmProp3] = useState(false);
  const [confirmProp4, setConfirmProp4] = useState(false);
  const [confirmProp5, setConfirmProp5] = useState(false);
  const [confirmProp6, setConfirmProp6] = useState(false);
  const [confirmProp7, setConfirmProp7] = useState(false);
  const [confirmProp8, setConfirmProp8] = useState(false);
  const [confirmProp9, setConfirmProp9] = useState(false);
  const [confirmProp10, setConfirmProp10] = useState();
  const [confirmProp11, setConfirmProp11] = useState(false);
  const [onHoldModalText, setOnHoldModalText] = useState(false);
  const [onHoldDialogText, setOnHoldDialogText] = useState("");
  const [deleteListId, setDeleteListId] = useState();
  const [filteredList, setFilteredList] = useState([]);
  const [nfsList, setNFSList] = useState();
  const [featuredList, setFeaturedList] = useState();
  const [openFeaturedModal, setOpenFeaturedModal] = useState();
  const [openNfsModal, setOpenNfsModal] = useState();
  const [addNfsList, setAddNfsList] = useState();
  const [openAddNfsModal, setOpenAddNfsModal] = useState();
  const [openAddSoldModal, setAddSoldModal] = useState();
  const [openMarkAsActiveModal, setOpenMarkAsActiveModal] = useState();
  const [openRemoveSoldModal, setRemoveSoldModal] = useState();
  const [soldList, setSoldList] = useState();
  const [holdList, setHoldList] = useState();
  const [activeList, setAsActiveList] = useState();
  const [openHoldModal, setOpenHoldModal] = useState();
  const [paginationStates, setPaginationStates] = useState({
    page: 0,
    rowsPerPage: 10,
    total_records: 0,
  });
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };
  const setOnHoldModalTexts = (text) => {
    setOnHoldModalText(text);
  };

  const handleOpenDeleteModal = (id) => {
    if (id) {
      setDeleteListId(id);
      setOpenDeleteModal(true);
    }
  };

  const handleOpenNFSModal = (data) => {
    if (data) {
      setNFSList(data);
      setOpenNfsModal(true);
    }
  };

  const handleOpenAddToNFSModal = (data) => {
    if (data) {
      setAddNfsList(data);
      setOpenAddNfsModal(true);
    }
  };

  const handleOpenSoldModal = (data) => {
    if (data) {
      setSoldList(data);
      setAddSoldModal(true);
    }
  };
  const handleRemoveSoldModal = (data) => {
    if (data) {
      setSoldList(data);
      setRemoveSoldModal(true);
    }
  };

  const handleOpenFeaturedModal = (data) => {
    if (data) {
      setFeaturedList(data);
      setOpenFeaturedModal(true);
    }
  };
  const handleOpenMarkAsActiveModal = (data) => {
    if (data) {
      setAsActiveList(data);
      setOpenMarkAsActiveModal(true);
    }
  };
  const handleOpenHoldModal = (data) => {
    if (data) {
      setHoldList(data);
      setOpenHoldModal(true);
    }
  };

  const loadListings = async () => {
    let registerRequest;
    try {
      registerRequest = await axios.get(
        `${config.SERVER_URL}/api/v1/seller/${seller.id}/animals?per_page=${paginationStates.rowsPerPage
        }&page=${paginationStates.page + 1}&filter_status=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }

    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 200) {
      setFilteredList(registerRequestData.data);
      setListings(registerRequestData.data);
      setPaginationStates({
        ...paginationStates,
        total_records: registerRequestData.total_listings,
      });
    }
  };

  useEffect(() => {
    loadListings();
  }, [
    paginationStates.page,
    paginationStates.rowsPerPage,
    confirmProp,
    confirmProp1,
    confirmProp2,
    confirmProp7,
    filter,
  ]);

  const deleteListing = async (id) => {
    let registerRequest;
    try {
      registerRequest = await axios.delete(
        `${config.SERVER_URL}/api/v1/animals/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch ({ response }) {
      registerRequest = response;
      if (registerRequest.status === 422) {
        setSnackbarStates({
          open: true,
          message:
            Object.keys(registerRequest.data.errors) +
            " " +
            Object.values(registerRequest.data.errors),
          severity: "danger",
        });
      }
    }
    if (registerRequest.status === 200) {
      setSnackbarStates({
        open: true,
        message: "Listing deleted successfully.",
        severity: "success",
      });
      const copyListings = listings.filter((item) => item.id !== id);
      setListings(copyListings);
    }
    setConfirmProp(false);
    setConfirmProp1(false);
    setConfirmProp7(false);
    setDeleteListId(null);
  };

  useEffect(() => {
    if (confirmProp) {
      deleteListing(deleteListId);
    }
  }, [confirmProp]);

  useEffect(() => {
    if (confirmProp11) {
      navigate("/seller/dashboard");
    }
  }, [confirmProp11]);

  useEffect(() => {
    const setFeaturedListing = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.patch(
          `${config.SERVER_URL}/api/v1/animals/${featuredList.id}`,
          {
            animal: {
              is_featured: !featuredList.is_featured,
            },
            seller_id: featuredList.seller_id,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data; ",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;

        if (registerRequest.status === 422) {
          setOpenFeaturedModal(false);
          setConfirmProp3(false);
          setOpenFeatureLimiExceededModal(true);
          setFeatureLimitMessage(registerRequest.data.message);
        }
      }

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 201 || registerRequest.status === 200) {
        loadListings();
        setSnackbarStates({
          open: true,
          message: registerRequestData.message,
          severity: "success",
        });
      }
      setConfirmProp3(false);
      setFeaturedList(null);
      setOpenFeaturedModal(false);
    };

    if (confirmProp3) {
      setFeaturedListing();
    }
  }, [confirmProp3]);

  const handleMarkAsSold = async () => {
    let registerRequest;
    try {
      registerRequest = await axios.patch(
        `${config.SERVER_URL}/api/v1/animals/${soldList.id}`,
        {
          animal: {
            is_sold: !soldList.is_sold,
          },
          seller_id: soldList.seller_id,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data; ",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
    } catch ({ response }) {
      registerRequest = response;

      if (registerRequest.status === 422) {
        setOpen(true);
      }
    }

    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 201 || registerRequest.status === 200) {
      loadListings();
      setSnackbarStates({
        open: true,
        message: registerRequestData.message,
        severity: "success",
      });
    }
    setSoldList(null);
    setConfirmProp6(false);
    setConfirmProp8(false);
    setRemoveSoldModal(false);
  };

  useEffect(() => {
    if (confirmProp6) {
      handleMarkAsSold();
    }
  }, [confirmProp6]);
  useEffect(() => {
    if (confirmProp8) {
      handleMarkAsSold();
    }
  }, [confirmProp8]);

  useEffect(() => {
    const handleNfsListing = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.patch(
          `${config.SERVER_URL}/api/v1/animals/${addNfsList.id}`,
          {
            animal: {
              nfs_status: addNfsList.status === "nfs" ? false : true,
            },
            seller_id: addNfsList.seller_id,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data; ",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;

        if (registerRequest.status === 422) {
          setOpen(true);
        }
      }

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 201 || registerRequest.status === 200) {
        loadListings();

        setSnackbarStates({
          open: true,
          message: registerRequestData.message,
          severity: "success",
        });
      }
      setAddNfsList(null);
      setConfirmProp5(false);
    };

    if (confirmProp5) {
      handleNfsListing();
    }
  }, [confirmProp5]);

  useEffect(() => {
    const handleActiveListing = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.patch(
          `${config.SERVER_URL}/api/v1/animals/${activeList.id}`,
          {
            animal: {
              status: "active_listing",
            },
            seller_id: activeList.seller_id,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data; ",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;

        if (registerRequest.status === 400 || registerRequest.status === 422) {
          setOpen(true);
        }
      }

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 201 || registerRequest.status === 200) {
        loadListings();

        setSnackbarStates({
          open: true,
          message: "Listing has been successfully marked as active.",
          severity: "success",
        });
      }
      setAsActiveList(null);
      setConfirmProp10(false);
    };

    if (confirmProp10) {
      handleActiveListing();
    }
  }, [confirmProp10]);

  useEffect(() => {
    if (confirmProp1) {
      deleteListing(nfsList.id);
    }
  }, [confirmProp1]);

  useEffect(() => {
    if (confirmProp7) {
      deleteListing(soldList.id);
    }
  }, [confirmProp7]);

  useEffect(() => {
    if (confirmProp4) {
      window.location.href = user.billingPortalUrl;
    }
  }, [confirmProp4]);

  useEffect(() => {
    const updateListings = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.patch(
          `${config.SERVER_URL}/api/v1/animals/${nfsList.id}`,
          {
            animal: {
              nfs_status: nfsList.status === "nfs" ? false : true,
            },
            seller_id: nfsList.seller_id,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data; ",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;

        if (registerRequest.status === 422) {
          setOpen(true);
        }
      }

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 201 || registerRequest.status === 200) {
        loadListings();

        setSnackbarStates({
          open: true,
          message: registerRequestData.message,
          severity: "success",
        });
      }
      setConfirmProp2(false);
    };

    if (confirmProp2) {
      updateListings();
    }
  }, [confirmProp2, nfsList]);

  useEffect(() => {
    const holdListing = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.patch(
          `${config.SERVER_URL}/api/v1/animals/${holdList.id}`,
          {
            animal: {
              on_hold: !holdList.on_hold,
            },
            seller_id: holdList.seller_id,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data; ",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;

        if (registerRequest.status === 422) {
        }
      }

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 201 || registerRequest.status === 200) {
        loadListings();

        setSnackbarStates({
          open: true,
          message: registerRequestData.message,
          severity: "success",
        });
      }
      setConfirmProp9(false);
    };

    if (confirmProp9) {
      holdListing();
    }
  }, [confirmProp9]);

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleCloseNFSModal = () => setOpenNfsModal(false);
  const handleCloseFeaturedModal = () => setOpenFeaturedModal(false);
  const handleCloseAddtoNFSModal = () => setOpenAddNfsModal(false);
  const handleCloseSoldModal = () => setAddSoldModal(false);
  const handleCloseRemoveSoldModal = () => setRemoveSoldModal(false);
  const handleCloseFeatureLimitModal = () =>
    setOpenFeatureLimiExceededModal(false);
  const handleCloseHoldModal = () => setOpenHoldModal(false);
  const handleCloseAsActiveModal = () => setOpenMarkAsActiveModal(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };

  const onPageChange = (event, newPage) => {
    setPaginationStates({ ...paginationStates, page: newPage });

    const updatedListing = listings.slice(
      newPage * paginationStates.rowsPerPage,
      newPage * paginationStates.rowsPerPage + paginationStates.rowsPerPage
    );

    setFilteredList(updatedListing);
  };

  const onRowsPerPageChange = (event) => {
    const updatedRowsPerPage = parseInt(event.target.value, 10);
    setPaginationStates({
      ...paginationStates,
      rowsPerPage: updatedRowsPerPage,
      page: 0,
    });

    const updatedRows = listings.slice(
      0 * updatedRowsPerPage,
      0 * updatedRowsPerPage + updatedRowsPerPage
    );

    setFilteredList(updatedRows);
  };
  const handleCloseNoStoreModal = () => {
    navigate("/seller/profile");
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Limit exceeded!!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Upgrade your plan to set more listings to active.{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button
            // onClick={handleUpgradePlan}
            color="primary"
            component={Link}
            to={`${user.billingPortalUrl}`}
          >
            Upgrade
          </Button>
        </DialogActions>
      </Dialog>
      <CustomModal
        title="Need to Complete Seller Profile First"
        content={"Please Complete your seller profile to use Seller Dashboard"}
        openModal={!Boolean(store)}
        closeModal={handleCloseNoStoreModal}
        actionTitle={"Go to store"}
        setConfirmProp={setConfirmProp11}
        actionColor={"green"}
        isHideCancelButton={true}
      />
      <Grid xs={12}>
        <Snackbar
          transitionDuration={{ enter: 1000, exit: 2000 }}
          TransitionComponent={Slide}
          TransitionProps={{ enter: true, exit: false }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbarStates.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant="filled"
            severity={snackbarStates.severity}
            sx={{ width: "100%" }}
          >
            {snackbarStates.message}
          </Alert>
        </Snackbar>
        <CustomModal
          title="Delete Alert"
          content={
            "Are you sure you want to delete this listing? This action is irreversible."
          }
          openModal={openDeleteModal}
          closeModal={handleCloseDeleteModal}
          actionTitle={"Delete"}
          setConfirmProp={setConfirmProp}
          actionColor={"red"}
        />
        <CustomModal
          title="Remove from NFS "
          content={
            "Are you sure you want to remove from NFS? You can either delete this listing or set it to Active Listing. \n This listing will also be removed from Trophy Case if added."
          }
          openModal={openNfsModal}
          closeModal={handleCloseNFSModal}
          actionTitle={"Delete"}
          actionTitle2={"Set Active"}
          setConfirmProp={setConfirmProp1}
          setConfirmProp2={setConfirmProp2}
          actionColor={"red"}
          actionColor2={"green"}
        />
        <CustomModal
          title="Featured Alert"
          content={
            "Are you sure, you want to set this listing to featured, you will not be able to reverse this action and it will last for 24 hours."
          }
          openModal={openFeaturedModal}
          closeModal={handleCloseFeaturedModal}
          actionTitle={"Set Featured"}
          setConfirmProp={setConfirmProp3}
          actionColor={"green"}
        />
        <CustomModal
          title="NFS Alert"
          content={
            "Are you sure, you want to add the listing to NFS (Not for Sale Section). You would be able to display this listing in trophy case."
          }
          openModal={openAddNfsModal}
          closeModal={handleCloseAddtoNFSModal}
          actionTitle={"Yes"}
          setConfirmProp={setConfirmProp5}
          actionColor={"green"}
        />
        <CustomModal
          title="Sold Alert"
          content={
            "Are you sure, you want to mark the listing as Sold. If yes, then the listing would not be visible to buyers to inquire about."
          }
          openModal={openAddSoldModal}
          closeModal={handleCloseSoldModal}
          actionTitle={"Yes"}
          setConfirmProp={setConfirmProp6}
          actionColor={"green"}
        />
        <CustomModal
          title="Remove from sold "
          content={
            " Are you sure, you want to remove from Sold? You can either delete this listing or set it to Active Listing. \n This listing will also be removed from Trophy Case if added."
          }
          openModal={openRemoveSoldModal}
          closeModal={handleCloseRemoveSoldModal}
          actionTitle={"Delete"}
          actionTitle2={"Set Active"}
          setConfirmProp={setConfirmProp7}
          setConfirmProp2={setConfirmProp8}
          actionColor={"red"}
          actionColor2={"green"}
        />
        <CustomModal
          title="Featured Listing Limit Exceeded!"
          content={featureLimitMessage}
          openModal={openFeatureLimiExceededModal}
          closeModal={handleCloseFeatureLimitModal}
          actionTitle={"Update Plan"}
          actionColor={"primary"}
          setConfirmProp={setConfirmProp4}
        />
        <CustomModal
          title="Mark as Active Alert"
          content={"Are you sure you want to mark the listing as Active."}
          openModal={openMarkAsActiveModal}
          closeModal={handleCloseAsActiveModal}
          actionTitle={"Mark As Active"}
          setConfirmProp={setConfirmProp10}
          actionColor={"green"}
        />
        <CustomModal
          title="Hold Alert"
          content={onHoldDialogText}
          openModal={openHoldModal}
          closeModal={handleCloseHoldModal}
          actionTitle={onHoldModalText}
          setConfirmProp={setConfirmProp9}
          actionColor={"red"}
        />
        <Box
          display="flex"
          justifyContent="right"
          alignItems="center"
          sx={{ textAlign: "right", mt: 2, mr: 2 }}
        >
          <Button
            component={Link}
            to={"/seller/listing/create"}
            disableRipple
            sx={{
              color: "#fff",
            }}
            variant="contained"
          >
            Add New
          </Button>
        </Box>
        <Box
          px="5px"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "5px",
            border: "none",
            bgcolor: "white",
            m: 1,
          }}
        >
          <Typography sx={{ paddingTop: "5px", paddingLeft: "5px" }}>
            Listings
          </Typography>
          <Select
            value={filter}
            size="small"
            disableUnderline
            onChange={(e) => setFilter(e.target.value)}
            className={styleclasses.hover}
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
          >
            {filters.map((filter) => (
              <MenuItem
                key={filter.id}
                value={filter.id}
                sx={{ fontSize: "14px" }}
              >
                {filter.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{ height: "100vh" }}>
          <ListingsList
            handleOpenRemoveNFSModal={handleOpenNFSModal}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenFeaturedModal={handleOpenFeaturedModal}
            handleOpenMarkAsActiveModal={handleOpenMarkAsActiveModal}
            handleOpenAddToNFSModal={handleOpenAddToNFSModal}
            handleOpenAddToSoldModal={handleOpenSoldModal}
            handleRemoveSoldModal={handleRemoveSoldModal}
            handleOpenHoldModal={handleOpenHoldModal}
            setOnHoldModalText={setOnHoldModalTexts}
            setOnHoldDialogText={setOnHoldDialogText}
            data={filteredList}
            loadListings={loadListings}
            setListings={setListings}
            page={paginationStates.page}
            rowsPerPage={paginationStates.rowsPerPage}
            classes={classes}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="bottom"
        >
          <TablePagination
            component="div"
            count={paginationStates.total_records}
            page={paginationStates.page}
            onPageChange={onPageChange}
            rowsPerPage={paginationStates.rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Box>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ Categories, Auth, Seller, Store }) => ({
  classes: Categories.classes,
  user: Auth.user,
  seller: Seller.seller,
  jwtToken: Auth.jwtToken,
  store: Store.store,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListingTopBar);
