import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Box } from "@mui/material";
import { Helmet } from 'react-helmet-async';

const useStyles = makeStyles((theme) => ({
  centerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "30vh",
  },
  largeImage: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

const Shop = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <meta property="og:image" content='https://prodappdata.s3.amazonaws.com/preview-images/shop-preview.png' />
        <meta property="og:title" content="Shop" />
        <meta property="og:description" content="Moore Exotics Shop" />
      </Helmet>
      <Typography
        textAlign={"center"}
        mt={10}
        sx={{
          fontSize: {
            xs: "14px",
            sm: "16px",
            md: "18px",
            lg: "20px",
            xl: "30px",
          },
          fontWeight: "bold",
          color: "#FA8128",
        }}
      >
        Moore Exotics Exclusive Merch Shop
      </Typography>
      <Box className={classes.centerContainer}>
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfH8odbQoionZvkRpKWeXP24j9O3HRpLbapXnTqlS9MRxuGLO0B79GKoTqr2kmRBZQIHk&usqp=CAU"
          alt="Large Centered Image"
          className={classes.largeImage}
        />
      </Box>
    </>
  );
};

export default Shop;
