import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import { capitalizeFirstLetter } from "../../utils/Utils";
import Paper from "@mui/material/Paper";
import { Avatar, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import { makeStyles, withStyles } from "@mui/styles";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    color: "inherit",
  },
})(MuiTableCell);

const TableRow = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    cursor: "pointer",
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#aaaaaa" },
  },
})(MuiTableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 8px 8px 8px",
    margin: "10px 10px 10px 10px",
    overflow: "auto",
    borderRadius: "10px",
    height: "100%",
  },
  table: {
    padding: 0,
    margin: 0,
    borderCollapse: "separate",
    borderSpacing: "0px 9px",
    backgroundColor: "white",
  },
  hover: {
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#eeeeee" },
  },
  featuredRrow: {
    backgroundColor: "#FFF2CA",
  },
}));

const ListingTableHeadings = [
  "",
  "Name",
  "Category",
  "Year",
  "Price",
  "Status",
  "Store",
  "Gender",
];

const ListView = ({ classes, listData }) => {
  const styleclasses = useStyles();
  const navigate = useNavigate();
  const plans = ["Gold", "Diamond Special", "Diamond", "Platinum"];

  const findCategoryName = (id) => {
    const category = classes.filter((cat) => cat.id === parseInt(id))[0];
    if (category) {
      return category.name;
    } else {
      return "N/A";
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    return `${year}`;
  };
  const handleNavigateDetailPage = (data) => {
    navigate("/listing/" + data?.id);
  };
  const handleNavigateToStore = (event, id) => {
    event.stopPropagation();
    navigate("/neighbour/" + id);
  };
  return (
    <>
      {listData.length > 0 ? (
        <Paper className={styleclasses.root} elevation={2}>
          <Table stickyHeader className={styleclasses.table}>
            <TableHead>
              <TableRow>
                {ListingTableHeadings.map((item) => (
                  <TableCell
                    align="center"
                    sx={{
                      fontSize: { md: "14px", xl: "16px" },
                      fontWeight: "bold",
                    }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {listData.map((data) => (
                <TableRow
                  key={data.name}
                  onClick={() => handleNavigateDetailPage(data)}
                >
                  <TableCell align="left" style={{ width: 0, padding: "5px" }}>
                    <Avatar
                      width={50}
                      height={50}
                      alt={data.name}
                      src={data.media[0]?.url}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ width: "15%" }}>
                    <Typography noWrap>{data.name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    {findCategoryName(data.category_id)}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    {formatDate(data.created_at)}
                  </TableCell>
                  <TableCell align="center">{"$" + data.price}</TableCell>
                  {data.status.split("_")[0] === "nfs" ? (
                    <TableCell align="center">NFS</TableCell>
                  ) : (
                    <TableCell align="center">
                      {capitalizeFirstLetter(data.status.split("_")[0])}
                    </TableCell>
                  )}
                  <TableCell align="center">
                    {plans.includes(
                      data?.seller?.user?.subscriptions[0]?.plan?.name
                    ) && (
                      <Box
                        onClick={(event) =>
                          handleNavigateToStore(event, data?.seller?.store?.id)
                        }
                      >
                        {data?.seller?.store?.brand_name}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {data.sex === "male" ? (
                      <MaleIcon sx={{ color: "blue" }} />
                    ) : data.sex === "female" ? (
                      <FemaleIcon sx={{ color: "#AA336A" }} />
                    ) : (
                      <>
                        <MaleIcon sx={{ color: "blue" }} />
                        <FemaleIcon sx={{ color: "#AA336A" }} />
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      ) : (
        <Typography textAlign={"center"} sx={{ fontWeight: "bold" }}>
          {" "}
          No one has created such listings yet.
        </Typography>
      )}
    </>
  );
};

const mapStateToProps = ({ Auth }) => ({
  jwtToken: Auth.jwtToken,
  user: Auth.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default ListView;
