import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Paper,
  Typography,
  Divider,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config";
import axios from "axios";
import MailIcon from "@mui/icons-material/Mail";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import GridViewIcon from "@mui/icons-material/GridView";
import InquireDialog from "../listdetails/InquireDialog";
import { makeStyles, createStyles } from "@mui/styles";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ReviewModal from "./ReviewModal";
import { connect } from "react-redux";
import MuiAlert from "@mui/material/Alert";
import LoginIcon from "@mui/icons-material/Login";

const ServiceDetailCard = ({ listData, seller, jwtToken, user }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [openSucessAlert, setOpenSucessAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openReviewSucessAlert, setReviewOpenSucessAlert] = useState(false);
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const handleOpenReviewModal = () => {
    setOpenReviewDialog(true);
  };
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const responseData = {
    AnimalId: listData.id,
    name: listData.name,
    sellerId: listData.seller_id,
    subserviceName: listData.sub_service.name,
    serviceName: listData.service.name,
    price: listData.price,
    description: listData.description,
    shippingType: listData.seller?.store?.delivery_option,
    Shipping: listData?.shipping_details,
    img: listData.seller?.store?.brand_logo?.url,
    userId: listData.seller.user.id,
    storeId: listData?.seller?.store?.id,
    categoryId: listData?.service_id,
    brandName:
      listData.seller?.store?.brand_name && listData?.seller?.store?.brand_name,
    Location: listData?.seller.store?.location,
  };
  const navigate = useNavigate();

  const handleOpen = () => {
    if (conversationId !== null) {
      navigate("/conversations", {
        state: { conversationId: conversationId },
      });
    } else {
      setOpen(true);
    }
  };

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  useEffect(() => {
    const getConversation = async () => {
      setLoading(true);
      let registerRequest;
      try {
        registerRequest = await axios.post(
          `${config.SERVER_URL}/conversations/check_existing_conversation`,
          {
            conversation: {
              listing_service_id: responseData.AnimalId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;
      }

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 200) {
        if (registerRequestData.conversation_sid !== null) {
          setConversationId(registerRequestData.conversation_sid);
        }
      }
      setLoading(false);
    };

    getConversation();
  }, [responseData.AnimalId, jwtToken, openSucessAlert]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSucessAlert(false);
  };

  const handleCloseReviewAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };
  const handleNavigateToLogin = () => {
    navigate("/auth/login");
  };
  return (
    <>
      <Snackbar
        open={openSucessAlert}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert onClose={handleClose} severity="success">
            Message Sent Successfully!
          </Alert>
        </div>
      </Snackbar>
      <Snackbar
        open={snackbarStates.open}
        autoHideDuration={3000}
        onClose={handleCloseReviewAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert
            onClose={handleCloseReviewAlert}
            severity={snackbarStates.severity}
          >
            {snackbarStates.message}
          </Alert>
        </div>
      </Snackbar>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <InquireDialog
            page={"Services"}
            open={open}
            brandName={responseData.brandName}
            listData={listData}
            setOpen={setOpen}
            setOpenSucessAlert={setOpenSucessAlert}
          />
          <ReviewModal
            open={openReviewDialog}
            brandName={responseData.brandName}
            listData={listData}
            page="Services"
            setOpen={setOpenReviewDialog}
            setReviewOpenSucessAlert={setReviewOpenSucessAlert}
            setSnackbarStates={setSnackbarStates}
          />
          <Paper
            elevation={2}
            sx={{
              border: "1px solid #eeeeee",
              marginY: "5px",
              borderRadius: "10px",
            }}
          >
            <Card
              sx={{
                bgcolor: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingBottom: "30px",
              }}
            >
              <Typography
                sx={{
                  textAlign: "left",
                  marginLeft: "40px",
                  marginRight: "40px",
                }}
              >
                <Typography
                  variant="h1"
                  fontFamily="Roboto"
                  sx={{
                    marginTop: "40px",
                    marginBottom: "4px",
                    fontSize: "32px",
                    fontWeight: "bolder",
                  }}
                >
                  {responseData.name}
                </Typography>
                <Typography
                  variant="p"
                  fontFamily="Roboto"
                  sx={{
                    marginTop: "40px",
                    fontSize: "18px",
                    fontWeight: "400px",
                    letterSpacing: "0.01em",
                  }}
                >
                  {responseData.serviceName}
                </Typography>
                <br />
                <Typography
                  variant="p"
                  fontFamily="Roboto"
                  sx={{
                    marginTop: "40px",
                    fontSize: "14px",
                    fontWeight: "400px",
                    letterSpacing: "0.01em",
                  }}
                >
                  <em> {responseData.subserviceName}</em>
                </Typography>
                <Typography
                  variant="h1"
                  fontFamily="Roboto"
                  sx={{
                    marginTop: "20px",
                    marginRight: "40px",
                    marginBottom: "4px",
                    fontSize: "32px",
                    fontWeight: "bolder",
                  }}
                >
                  US ${responseData.price}
                </Typography>

                <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
                <Grid container sx={{ mb: "4px" }}>
                  <Typography
                    variant="p"
                    component={"p"}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: "Roboto",
                    }}
                  >
                    <em> Description:</em>
                  </Typography>
                  <Typography
                    variant="p"
                    component={"p"}
                    sx={{
                      fontSize: "18px",
                      pl: 0.5,
                      fontFamily: "Roboto",
                    }}
                  >
                    {responseData.description}
                  </Typography>
                </Grid>
                <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />

                <Grid container mb={4} pt={1}>
                  <Grid item xs={5} mr={2}>
                    <Link
                      to={"/neighbour/" + responseData.storeId}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <img
                        alt={responseData.img}
                        src={responseData.img}
                        key={responseData.img}
                        onClick={(e) => {}}
                        style={{
                          width: "90%",
                          height: "100%",
                          cursor: "pointer",
                          objectFit: "contain",
                        }}
                      />
                    </Link>
                  </Grid>

                  <Grid xs={6}>
                    <Link
                      to={"/neighbour/" + responseData.storeId}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#FFC001",
                          fontWeight: "bold",
                          fontFamily: "Roboto",
                          fontSize: "18px",
                        }}
                      >
                        {responseData.brandName}
                      </Typography>
                    </Link>
                    <Grid container sx={{ mt: 2 }}>
                      <Box display="flex" alignItems="flex-end">
                        <FmdGoodIcon
                          fontSize="small"
                          fontFamily="Roboto"
                          sx={{
                            marginLeft: "auto",
                            marginTop: "auto",
                            marginBottom: "auto",
                            marginRight: "8px",
                            color: "#D89700",
                          }}
                        />
                        <Typography
                          gutterTop
                          sx={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            fontFamily: "Roboto",
                          }}
                        >
                          {" "}
                          {responseData.Location}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid container sx={{ mt: 2 }}>
                      <Box display="flex" alignItems="flex-end">
                        <LocalShippingIcon
                          fontSize="small"
                          fontFamily="Roboto"
                          sx={{
                            marginLeft: "auto",
                            marginTop: "auto",
                            marginBottom: "auto",
                            marginRight: "8px",
                            color: "#D89700",
                          }}
                        />
                        <Typography
                          gutterTop
                          sx={{
                            fontWeight: "bold",
                            marginTop: "auto",
                            fontSize: "18px",
                            fontFamily: "Roboto",
                          }}
                        >
                          {responseData.shippingType === "regional_pickup"
                            ? "Regional Pickup"
                            : "Local Pickup"}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Typography>

              <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
                {!user && (
                  <Box
                    component={Button}
                    size="large"
                    variant="contained"
                    sx={{
                      textAlign: "center",
                      marginTop: "10px",
                      bgcolor: "#FFC001",
                      width: { xs: "70vw", md: "30vw" },
                      height: 40,
                      color: "#fff",
                      fontSize: 18,
                      fontWeight: 700,
                      fontFamily: "Roboto",
                      textTransform: "none !important",
                      transition: "0.3s all",
                    }}
                    onClick={handleNavigateToLogin}
                  >
                    <LoginIcon
                      fontSize="small"
                      sx={{
                        mr: 1,
                      }}
                    ></LoginIcon>
                    Login/Sign Up
                  </Box>
                )}
                {user && user.id !== responseData.userId && (
                  <Box
                    component={Button}
                    onClick={handleOpen}
                    size="large"
                    variant="contained"
                    sx={{
                      textAlign: "center",
                      marginTop: "10px",
                      bgcolor: "#FFC001",
                      width: { xs: "70vw", md: "30vw" },
                      height: 40,
                      color: "#fff",
                      fontSize: 18,
                      fontWeight: 700,
                      fontFamily: "Roboto",
                      textTransform: "none !important",
                      transition: "0.3s all",
                    }}
                  >
                    <MailIcon
                      fontSize="small"
                      sx={{
                        mr: 1,
                      }}
                    ></MailIcon>
                    {conversationId ? "Go to Conversation" : "Inquire to Buy"}
                  </Box>
                )}

                {conversationId && (
                  <Button
                    onClick={handleOpenReviewModal}
                    size="large"
                    variant="outlined"
                    sx={{
                      textAlign: "center",
                      fontSize: 18,
                      marginTop: "10px",
                      fontFamily: "Roboto",
                      border: "1px solid #FFC001",
                      color: "#FFC001",
                      width: { xs: "70vw", md: "30vw" },
                      transition: "0.3s all",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "rgb(255, 192, 1)",
                        color: "#000",
                      },
                      fontWeight: 600,
                    }}
                  >
                    <BorderColorIcon
                      fontSize="small"
                      sx={{
                        mr: 1,
                      }}
                    />
                    Review Seller
                  </Button>
                )}
                <Button
                  component={Link}
                  size="large"
                  variant="outlined"
                  sx={{
                    textAlign: "center",
                    marginTop: "10px",
                    border: "1px solid #FFC001",
                    fontSize: 18,
                    fontFamily: "Roboto",
                    color: "#FFC001",
                    width: { xs: "70vw", md: "30vw" },
                    transition: "0.3s all",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "rgb(255, 192, 1)",
                      color: "#000",
                    },
                    fontWeight: 600,
                  }}
                  to={"/services/listings/classes/" + responseData.categoryId}
                >
                  <GridViewIcon
                    fontSize="small"
                    sx={{
                      mr: 1,
                    }}
                  />
                  More Like this
                </Button>
                {/* {responseData.storeId && ( */}
                <Button
                  component={Link}
                  to={"/service/listings/seller/" + responseData.sellerId}
                  size="large"
                  variant="outlined"
                  sx={{
                    textAlign: "center",
                    fontSize: 18,
                    marginTop: "10px",
                    fontFamily: "Roboto",
                    border: "1px solid #FFC001",
                    color: "#FFC001",
                    width: { xs: "70vw", md: "30vw" },
                    transition: "0.3s all",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "rgb(255, 192, 1)",
                      color: "#000",
                    },
                    fontWeight: 600,
                  }}
                >
                  <GridViewIcon
                    fontSize="small"
                    sx={{
                      mr: 1,
                    }}
                  />
                  More from this Store
                </Button>
                {/* )} */}
              </Box>
            </Card>
          </Paper>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles({
  imageContainer: {
    position: "relative",
    width: "100%",
  },
  onHoldText: {
    position: "absolute",
    top: 140,
    right: "-7%",
    width: "100%",
    textAlign: "end",
    paddingRight: "25%",
    backgroundColor: "#FFD300",
    color: "#fff",
    padding: "10px",
    fontSize: "18px",
    transform: "translate(-0%, 100%) rotate(45deg)",
    transformOrigin: "top right",
  },
  soldOutStripeText: {
    position: "absolute",
    top: 140,
    right: "-7%",
    width: "100%",
    textAlign: "end",
    paddingRight: "25%",
    backgroundColor: "#EF0107",
    color: "#fff",
    padding: "10px",
    fontSize: "18px",
    transform: "translate(-0%, 100%) rotate(45deg)",
    transformOrigin: "top right",
  },
  nfsStripeText: {
    position: "absolute",
    top: 140,
    right: "-7%",
    width: "100%",
    textAlign: "end",
    paddingRight: "25%",
    backgroundColor: "black",
    color: "#fff",
    padding: "10px",
    fontSize: "18px",
    transform: "translate(-0%, 100%) rotate(45deg)",
    transformOrigin: "top right",
  },
});

const mapStateToProps = ({ Seller, Auth }) => ({
  seller: Seller.seller,
  jwtToken: Auth.jwtToken,
  user: Auth.user,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetailCard);
