import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import {
  ImageListItemBar,
  Stack,
  Typography,
  Paper,
  Button,
  Breadcrumbs,
  TextField,
  Alert,
  Card,
  InputAdornment,
  Box,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
import ListView from "./ListView";
import Popper from "@mui/material/Popper";
import ImageListItem, {
  imageListItemClasses,
} from "@mui/material/ImageListItem";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Search } from "@mui/icons-material";
import { connect } from "react-redux";
import catName from "./ComingSoonCategories";
import { Helmet } from "react-helmet-async";
import "./Categories.css";

const Categories = ({ categoriesRaw, loading, error }) => {
  const styleclasses = useStyles();
  const [categories, setCategories] = useState();
  const [view, setView] = useState("Grid");
  const [search, setSearch] = useState("");
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isLargeScreen = useMediaQuery("(min-width: 2500px)");
  const isLarge38pxScreen = useMediaQuery("(min-width: 3800px)");

  useEffect(() => {
    setCategories(categoriesRaw);
  }, [loading, categoriesRaw]);

  useEffect(() => {
    if (error != "") {
      setSnackbarStates({
        open: true,
        message: error,
        severity: "danger",
      });
    }
  }, [error]);
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const handleCloseCategoryAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };
  const handleGridView = () => {
    setView("Grid");
  };

  const handleListView = () => {
    setView("List");
  };
  const filteredCategories = categories?.filter((item) => {
    if (search === "") {
      return true;
    } else if (item.name.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    return false;
  });

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  return (
    <>
      {/* <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="top"
        onMouseLeave={handleMouseLeave}
      >
        <Box
          sx={{
            border: 1,
            p: 2,
            bgcolor: "background.paper",
          }}
        >
          <Typography>Name: Moore exotic</Typography>
          <Typography component={"span"}>
            <Typography component={"span"}>Link: </Typography>
            <Typography component={"span"}>
              <a
                href="https://example.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://example.com
              </a>
            </Typography>
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="https://img.freepik.com/free-vector/golden-bird-logo-design_1195-336.jpg"
              style={{ height: "60px", width: "60px" }}
            />
          </Box>
        </Box>
      </Popper> */}
      <Helmet>
        <meta
          property="og:image"
          content="https://prodappdata.s3.amazonaws.com/preview-images/classes-preview.png"
        />
        <meta property="og:title" content="Classes" />
        <meta property="og:description" content="Moore Exotics Classes" />
      </Helmet>
      <Snackbar
        open={snackbarStates.open}
        autoHideDuration={3000}
        onClose={handleCloseCategoryAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseCategoryAlert}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <Stack className={styleclasses.categoriesContainer}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/" className={styleclasses.breadcrumbs}>
            Home
          </Link>
          <Typography color="primary">Classes</Typography>
        </Breadcrumbs>
        <Typography
          variant="h3"
          component={"h3"}
          sx={{
            fontWeight: 900,
            textAlign: "center",
          }}
        >
          Classes
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ textAlign: "center" }} flexGrow={1}>
            <TextField
              placeholder="Search your classes"
              size="small"
              type="search"
              sx={{
                width: { xs: "100%", sm: "70%", md: "50%" },
                mt: 2,
                backgroundColor: "white",
                "& .MuiOutlinedInput-root": {
                  paddingRight: 0,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{
                      backgroundColor: "rgb(255, 192, 1)",
                      padding: "27.5px 14px",
                      borderTopLeftRadius: (theme) =>
                        theme.shape.borderRadius + "px",
                      borderBottomLeftRadius: (theme) =>
                        theme.shape.borderRadius + "px",
                    }}
                    position="end"
                  >
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearchChange}
            />
          </Box>
          {!isSmallScreen && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton aria-label="delete" onClick={handleGridView}>
                <GridViewIcon sx={{ color: view === "Grid" && "#FFC001" }} />
              </IconButton>{" "}
              <IconButton aria-label="delete" onClick={handleListView}>
                <ListIcon sx={{ color: view !== "Grid" && "#FFC001" }} />
              </IconButton>{" "}
            </Box>
          )}
        </Box>
        {isSmallScreen && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton aria-label="delete" onClick={handleGridView}>
              <GridViewIcon sx={{ color: view === "Grid" && "#FFC001" }} />
            </IconButton>{" "}
            <IconButton aria-label="delete" onClick={handleListView}>
              <ListIcon sx={{ color: view !== "Grid" && "#FFC001" }} />
            </IconButton>{" "}
          </Box>
        )}
        {loading ? (
          <Box
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {filteredCategories?.length > 0 ? (
              <>
                {view === "Grid" ? (
                  <Card
                    variant="outlined"
                    sx={{
                      p: 4,
                      mt: 1,
                      display: "grid",
                      gap: 0.5,
                      gridTemplateColumns: {
                        xs: "repeat(1, 1fr)",
                        sm: "repeat(2, 1fr)",
                        md: "repeat(3, 1fr)",
                        lg: "repeat(4, 1fr)",
                        xl: isLargeScreen
                          ? isLarge38pxScreen
                            ? "repeat(7, 1fr)"
                            : "repeat(6, 1fr)"
                          : "repeat(5, 1fr)",
                      },
                      [`& .${imageListItemClasses.root}`]: {
                        display: "flex",
                        flexDirection: "column",
                      },
                    }}
                  >
                    {categories &&
                      filteredCategories.map((item) => (
                        <Paper
                          elevation={24}
                          component={Link}
                          to={
                            item.active &&
                            `/classes/${item.id}/breeds`
                          }
                        >
                          <ImageListItem
                            key={item.id}
                            sx={{
                              height: "100% !important",
                              position: "relative",
                            }}
                          >
                            <div className="container">
                              <img
                                className="image"
                                style={{ width: "100%", height: "250px" }}
                                src={`${item.image}`}
                                srcSet={`${item.image}`}
                                alt={item.name}
                                loading="lazy"
                              />

                              {item.brand_promotions &&
                                item.brand_promotions.length > 0 && (
                                  <div className="circular-image">
                                    <img
                                      src={item.brand_promotions?.[0]?.image}
                                      alt={item.brand_promotions?.[0]?.name}
                                      onMouseEnter={handleMouseEnter}
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px",
                                        border: "2px solid white",
                                        zIndex: 100,
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                )}

                              {item.active ? (
                                <div className="middle">
                                  <div className="layOver"></div>
                                  <div className="button">
                                    <Button
                                      component={Link}
                                      to={`/classes/${item.id}/breeds`}
                                      size="large"
                                      variant="outlined"
                                      sx={{
                                        "&:hover": {
                                          backgroundColor: "rgb(255, 192, 1)",
                                          color: "#000",
                                        },
                                      }}
                                    >
                                      Go Now
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                <div className="middle">
                                  <div className="layOver"></div>
                                  <div className="button">
                                    <Button
                                      size="large"
                                      variant="outlined"
                                      sx={{
                                        "&:hover": {
                                          backgroundColor: "rgb(255, 192, 1)",
                                          color: "#000",
                                        },
                                      }}
                                    >
                                      Coming Soon
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </div>

                            <ImageListItemBar
                              title={item.name}
                              sx={{ textAlign: "center" }}
                            />
                          </ImageListItem>
                        </Paper>
                      ))}
                  </Card>
                ) : (
                  <ListView
                    categories={categories}
                    page={"Classes"}
                    search={search}
                  />
                )}
              </>
            ) : (
              <Typography
                sx={{ textAlign: "center", fontWeight: "bold" }}
                mt={2}
              >
                {" "}
                No class exists.
              </Typography>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

const useStyles = makeStyles({
  commingSoonText: {
    position: "absolute",
    top: 40,
    right: -10,
    textAlign: "end",
    paddingRight: "5%",
    borderRadius: "10%",
    backgroundColor: "#FFD300",
    color: "#fff",
    padding: "10px",
    transform: "translate(-0%, 100%) rotate(45deg)",
    transformOrigin: "top right",
  },
  flexGrow: {
    flex: "1",
  },
  categoriesContainer: {
    backgroundColor: "#ebecf0",
    padding: "24px 5px 24px 5px",
  },
  breadcrumbs: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      color: "rgb(255, 192, 1)",
      textDecoration: "underline",
    },
  },
});

const mapStateToProps = ({ Categories }) => ({
  categoriesRaw: Categories.classes,
  loading: Categories.loading,
  error: Categories.error,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
