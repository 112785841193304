import { AttachFile, Clear } from "@mui/icons-material";
import React from "react";
import { Box, Typography } from "@mui/material";
const SelectedMediaView = ({ media, onFileRemove }) => {
  return (
    <div className="drop-file-preview">
      <p>Selected File(s)</p>
      {console.log("media", media)}
      {media.map((item, index) => (
        <div key={index} className="drop-file-preview__item">
          <AttachFile sx={{ mr: 1 }} />

          {item.type?.startsWith("image/heic") ? (
            <img
              src={window.location.origin + "/images/heic-image.jpeg"}
              alt={item.name}
              style={{
                width: "50px",
                marginBottom: "5px",
                border: "2px solid grey",
                borderRadius: "10px",
              }}
            ></img>
          ) : item.type?.includes("pdf") ? (
            <Box>
              <Typography>{item.name}</Typography>
            </Box>
          ) : item.type?.startsWith("image/") ? (
            <img
              src={item.url ? item.url : URL.createObjectURL(item)}
              alt={item.url ? item.url : item.name}
              style={{
                width: "75px",
                height: "75px",
                marginBottom: "5px",
                border: "2px solid grey",
                borderRadius: "10px",
              }}
            ></img>
          ) : item.url?.includes(".mp4") ||
            item.url?.includes(".mkv") ||
            item.url?.includes(".mov") ? (
            <video
              src={item.url ? item.url : item}
              style={{
                width: "125px",
                marginBottom: "5px",
                border: "2px solid grey",
                borderRadius: "10px",
              }}
              controls
              key={item.name}
            >
              Your browser doesn't support HTML5 video tag.
            </video>
          ) : item.url?.includes(".jpg") ||
            item.url?.includes(".jpeg") ||
            item.url?.includes(".gif") ||
            item.url?.includes(".png") ||
            item.url?.includes(".webp") ||
            item.url?.includes(".heic") ? (
            <img
              src={item.url ? item.url : item}
              alt={item.url ? item.url : item.name}
              style={{
                width: "75px",
                height: "75px",
                marginBottom: "5px",
                border: "2px solid grey",
                borderRadius: "10px",
              }}
            ></img>
          ) : (
            ""
          )}
          {item.type?.startsWith("video/") ? (
            <video
              src={URL.createObjectURL(item)}
              style={{
                width: "125px",
                marginBottom: "5px",
                border: "2px solid grey",
                borderRadius: "10px",
              }}
              controls
              key={item.name}
            >
              Your browser doesn't support HTML5 video tag.
            </video>
          ) : item.name?.includes(".mkv") ? (
            <video
              src={URL.createObjectURL(item)}
              style={{
                width: "125px",
                marginBottom: "5px",
                border: "2px solid grey",
                borderRadius: "10px",
              }}
              controls
              key={item.name}
            >
              Your browser doesn't support HTML5 video tag.
            </video>
          ) : (
            ""
          )}
          <Clear
            onClick={() => onFileRemove(item)}
            sx={{
              color: "red",
              fontSize: "20px",
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default SelectedMediaView;
