import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useLayoutEffect,
} from "react";
import * as Actions from "../../redux/actions";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import config from "../../config";
import CustomModal from "../seller/listings/Modal";
import BlockIcon from "@mui/icons-material/Block";
import {
  Select,
  MenuItem,
  Button,
  Box,
  Grid,
  Divider,
  Typography,
  TextField,
  Paper,
  Hidden,
  Avatar,
  InputAdornment,
  CircularProgress,
  useMediaQuery,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/system";
import Message from "./message";
import { Search } from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import { format } from "date-fns";
import ReactQuill from "react-quill";
import { EventEmitter } from "fbemitter";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "react-quill/dist/quill.snow.css";
import SelectedMediaView from "../seller/listings/SelectedMediaView";

import { readAndCompressImage } from "browser-image-resizer";
import heic2any from "heic2any";

const StyledTextField = styled(TextField)`
& .MuiOutlinedInput-root {
  border: "white",
  height:'180px'
  paddingLeft: 0px
}`;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #dca500;
    color: #fff;
    font-size: 12px;
    min-width: 18px;
    height: 18px;
    borderradius: "10%";
  }
`;
const messagesPaginatorReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_PAGINATOR":
      return {
        ...state,
        [action.roomId]: {
          ...action.messagesPaginator,
          loaded: true,
        },
      };
    case "DELETE_PAGINATOR": {
      return {
        ...state,
        [action.roomId]: null,
      };
    }
    default:
      return state;
  }
};

const Chat = ({
  user,
  jwtToken,
  messagesByRoom,
  thunkInitChatClient,
  conversations,
  updateConversationCount,
  setConversations,
  loadConversation,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const location = useLocation();
  const stateData = location.state;
  const [textInput, setTextInput] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [disappearCoversation, setDisappearConversation] = useState(true);
  const [disappearNames, setDisappearNames] = useState(false);
  const [listingName, setListingName] = useState("");
  const [conversationId, setConversationId] = useState();
  const [retrieveConversationLoading, setRetrieveConversationLoading] =
    useState(false);
  const [conversation, setConversation] = useState();
  const [otherUser, setOtherUser] = useState();
  const [token, setToken] = useState(null);
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const [receiverOnlineStatus, setReceiverOnlineStatus] = useState(false);
  const [senderOnlineStatus, setSenderOnlineStatus] = useState(false);
  const [openMenuId, setOpenMenuId] = useState();
  const conversationRef = useRef(null);
  const isMediumScreen = useMediaQuery("(max-width: 960px)");
  const isMedium = useMediaQuery("(max-width: 900px)");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [confirmProp, setConfirmProp] = useState(false);
  const [confirmProp2, setConfirmProp2] = useState(false);
  const [hideConversation, setHideConversation] = useState();
  const [renderConversations, setRenderConversations] = useState([]);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [imageFilePreview, setImageFilePreview] = useState([]);
  const [fileAttachment, setFileAttachment] = useState([]);
  const [openSucessAlert, setOpenSucessAlert] = useState(false);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [isBlocked, setIsBlocked] = useState(false);
  const roomEmittersRef = useRef({});
  const reactQuillRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlockedUser = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/v1/blocks`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        const { data: registerRequestData } = registerRequest;
        if (registerRequest.status === 200) {
          setBlockedUsers(registerRequestData);
        }
      } catch ({ response }) {}
    };

    fetchBlockedUser();
  }, [conversations, jwtToken]);

  useEffect(() => {
    if (!loadConversation && !conversations.length === 0) {
      setLoading(false);
    } else if (!loadConversation && conversations.length === 0) {
      setLoading(false);
    }
  }, [conversations]);

  const getToken = useCallback(async () => {
    try {
      const registerRequest = await axios.post(
        `${config.SERVER_URL}/tokens`,
        {
          user_id: user.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      const { data: registerRequestData, status } = registerRequest;
      if (status === 200) {
        setToken(registerRequestData.token);
        return registerRequestData;
      }
    } catch (error) {
      console.error("Error while making the request:", error.message);
    }
  }, [user.id, jwtToken]);

  const handlePrevious = () => {
    setConversationId(-1);
    setDisappearConversation(true);
    setDisappearNames(false);
  };

  async function setTokens() {
    if (user && !client) {
      const response = await getToken();
      if (response) {
        const { token } = response;
        thunkInitChatClient({ token, setClient });
      }
    }
    return () => {
      if (client) {
        return client.shutdown();
      }
    };
  }

  useEffect(() => {
    setTokens();
  }, [client, dispatch]);

  useEffect(() => {
    if (stateData?.conversationId) {
      setRetrieveConversationLoading(true);
      retrieveConversation(stateData.conversationId);
    }
  }, [stateData?.conversationId, client, token]);

  useLayoutEffect(() => {
    const getAllConversationPages = async (
      items,
      chatConversationsPaginator
    ) => {
      if (chatConversationsPaginator.hasNextPage) {
        const nextChatConversationsPaginator =
          await chatConversationsPaginator.nextPage();
        const allItems = items.concat(nextChatConversationsPaginator.items);
        return getAllConversationPages(
          allItems,
          nextChatConversationsPaginator
        );
      }
      return items;
    };

    const refreshToken = async () => {
      const { token } = await getToken();
      const chatClient = await client.updateToken(token);
      setClient(chatClient);
    };

    if (client) {
      client.on("tokenAboutToExpire", async () => {
        await refreshToken();
      });
      client.on("tokenExpired", async () => {
        await refreshToken();
      });
    }
    return () => {
      Object.keys(roomEmittersRef.current).forEach((roomId) => {
        roomEmittersRef.current[roomId].removeAllListeners();
      });
    };
  }, [client, dispatch, getToken]);

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    if (searchTerm === "") {
      setSearchResults(renderConversations);
    } else {
      const filteredResults = renderConversations.filter((item) => {
        return item.conversation.friendlyName
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchResults(filteredResults);
    }
  };

  const fetchConversationBySid = async (convId) => {
    try {
      if (client && token) {
        const conversation = await client.getConversationBySid(convId);
        return conversation;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleInputChange = (e) => {
    const processedHtml = e
      .replace(/(^([ ]*<p><br><\/p>)*)|((<p><br><\/p>)*[ ]*$)/gi, "")
      .trim(" ");
    setTextInput(processedHtml);

    if (processedHtml) {
      if (!isTyping) {
        setIsTyping(true);
      }
    } else {
      if (isTyping) {
        setIsTyping(false);
      }
    }
  };

  useEffect(() => {}, [otherUser]);

  const retrieveConversation = async (conversationId) => {
    const conversation = await fetchConversationBySid(conversationId);
    if (isMediumScreen) {
      setDisappearConversation(false);
      setDisappearNames(true);
    }
    if (conversation) {
      setConversation(conversation);
      setConversationId(conversation.sid);
      setRetrieveConversationLoading(false);

      const updatedOtherUser = conversation.attributes.members.find(
        (member) => member.id !== user.id
      );
      setOtherUser(updatedOtherUser);
    }
  };

  const getOtherUserBlockedList = async (id) => {
    try {
      const { data } = await axios.get(
        `${config.SERVER_URL}/api/v1/blocks/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const handleConversationClick = async (conversation) => {
    setConversationLoading(true);
    setIsBlocked(false);
    await conversation.setAllMessagesRead();
    updateConversationCount({ conversation, unreadCount: 0 });

    if (isMediumScreen) {
      setDisappearConversation(false);
      setDisappearNames(true);
    }
    setConversationId(conversation.sid);
    setConversation(conversation);
    setConversationLoading(false);
    const updatedOtherUser = conversation.attributes.members.find(
      (member) => member.id !== user.id
    );

    const ohterUserBlockedList = await getOtherUserBlockedList(
      updatedOtherUser.id
    );

    if (ohterUserBlockedList) {
      const isBlocked =
        conversation.attributes.members.some((member) =>
          blockedUsers.some((blockedUser) => blockedUser.id === member.id)
        ) ||
        ohterUserBlockedList.some((member) =>
          ohterUserBlockedList.some(
            (blockedUser) => blockedUser.id === member.id
          )
        );
      if (isBlocked) {
        setIsBlocked(true);
      }
    }

    setOtherUser(updatedOtherUser);
  };

  const sendMessage = async (e) => {
    setSendMessageLoading(true);
    if (conversationId) {
      const conv = await fetchConversationBySid(conversationId);
      if (conv) {
        const messageAttributes = {
          id: user.id,
          email: user.email,
          name: user.full_name,
          avatar_url: user.avatar_url,
        };

        if (fileAttachment && fileAttachment.length > 0) {
          const formData = new FormData();
          fileAttachment.forEach((file, index) => {
            formData.append(`file_${index}`, file);
            formData.append(`Content-Type_${index}`, file.type);
          });

          const messageBuilder = conv
            .prepareMessage()
            .setBody(textInput ? textInput : "")
            .setAttributes(messageAttributes);

          for (let i = 0; i < fileAttachment.length; i++) {
            if (fileAttachment[i].type.includes("video")) {
              messageBuilder.addMedia({
                media: formData.get(`file_${i}`),
                contentType: formData.get(`Content-Type_${i}`),
                mediaType: "video",
              });
            } else {
              messageBuilder.addMedia({
                media: formData.get(`file_${i}`),
                contentType: formData.get(`Content-Type_${i}`),
              });
            }
          }
          await messageBuilder.build().send();
        } else {
          if (textInput !== "") {
            await conv.sendMessage(textInput, {
              author: user.id,
              ...messageAttributes,
            });
          }
        }
        updateConversationCount({ conversation: conv, unreadCount: 0 });
        await conv.setAllMessagesRead();
      }
    }
    setTextInput("");
    setImageFilePreview([]);
    setFileAttachment([]);
    setSendMessageLoading(false);
  };

  const getAvatarUrl = (conversation) => {
    const otherUser = conversation.attributes.members.find(
      (member) => member.id !== user.id
    );
    return otherUser.avatar_url;
  };

  useEffect(() => {
    scrollToBottom();
  }, [
    conversationId,
    conversation,
    conversationRef.current,
    conversations,
    stateData?.conversationId,
  ]);

  const scrollToBottom = () => {
    if (conversationRef.current) {
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  };

  const handleMenuClick = (event, conversation) => {
    event.stopPropagation();
    setOpenMenuId(conversation.sid);
    setIsMenuOpen(!isMenuOpen);
  };
  const handleRightMenuClick = (event) => {
    event.stopPropagation();
    setIsRightMenuOpen(!isRightMenuOpen);
  };

  const handleDeleteClick = (event, conversation) => {
    setHideConversation(conversation);
    event.stopPropagation();
    setOpenDeleteModal(true);
    setIsMenuOpen(false);
  };
  const handleBlockClick = (event, conversation) => {
    setHideConversation(conversation);
    event.stopPropagation();
    setOpenBlockModal(true);
    setIsRightMenuOpen(false);
  };
  const handleCloseClick = () => {
    setIsMenuOpen(false);
  };
  const handleCloseBlockClick = () => {
    setIsRightMenuOpen(false);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
    } else if (e.keyCode === 13) {
      e.preventDefault();
    } else if (e.keyCode === 16 && e.keyCode === 13) {
    }
  };

  const getFilteredConversations = useCallback(async () => {
    if (conversations.length > 0) {
      const filteredConversations = await Promise.all(
        conversations.map(async (conversation) => {
          const participants =
            await conversation.conversation.getParticipants();

          const receiver = participants.find(
            (participant) => participant.identity !== user.id
          );
          const sender = participants.find(
            (participant) => participant.identity === user.id
          );

          const setOnlineStatus = (user, setUserOnlineStatus) => {
            user.on("updated", (updatedUser) => {
              setUserOnlineStatus(updatedUser.user.isOnline);
            });
            setUserOnlineStatus(user.isOnline);
          };

          if (receiver) {
            const receiverUser = await receiver.getUser();
            setOnlineStatus(receiverUser, setReceiverOnlineStatus);
          }

          if (sender) {
            const senderUser = await sender.getUser();
            setOnlineStatus(senderUser, setSenderOnlineStatus);
          }
          const senderUser = participants.find(
            (participant) => participant.identity == user.id
          );
          return senderUser.attributes.hidden !== true;
        })
      );

      const filteredConversationsWithoutHidden = conversations.filter(
        (_, index) => filteredConversations[index]
      );

      setRenderConversations(filteredConversationsWithoutHidden);
      setSearchResults(filteredConversationsWithoutHidden);
      setLoading(false);
    }
  }, [conversations, user.id]);

  const updateParticipant = async (conversationSid, participantSid) => {
    try {
      let registerRequest;

      registerRequest = await axios.patch(
        `${config.SERVER_URL}/conversations/update_conversation_participant`,
        {
          participant: {
            conversation_id: conversationSid,
            participant_id: participantSid,
            status: true,
          },
        },
        {
          headers: {
            "Content-Type": "multipart/form-data; ",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      const { data: registerRequestData } = registerRequest;
      setConversations({ conversations });
      updateConversationCount({
        conversation: hideConversation,
        unreadCount: 0,
      });
      await hideConversation.setAllMessagesRead();
      setConversationId(null);
      setConversation(null);
      setHideConversation(null);
      getFilteredConversations();
    } catch (e) {
      console.log(e);
    }
  };

  const removeConversationParticipant = async (conversation) => {
    try {
      const participants = await conversation.getParticipants();
      participants.forEach(async (participant) => {
        if (participant.identity == user.id) {
          await updateParticipant(conversation.sid, participant.sid);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (confirmProp) {
      if (hideConversation) {
        removeConversationParticipant(hideConversation);
      }
    }

    return () => {
      setConfirmProp(false);
      setHideConversation(null);
    };
  }, [confirmProp]);

  useEffect(() => {
    const blockUser = async (id) => {
      let registerRequest;
      try {
        registerRequest = await axios.post(
          `${config.SERVER_URL}/api/v1/blocks`,
          {
            block: {
              id,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        setOpenSucessAlert(true);
      } catch ({ response }) {}

      console.log(registerRequest);
    };
    if (confirmProp2 && otherUser) {
      blockUser(otherUser.id);
      // removeConversationParticipant(hideConversation);
    }

    return () => {
      setConfirmProp2(false);
      setHideConversation(null);
    };
  }, [confirmProp2]);

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleCloseBlockModal = () => setOpenBlockModal(false);

  useEffect(() => {
    // setLoader2(true);
    getFilteredConversations();
  }, [conversations, getFilteredConversations, confirmProp]);

  const handleOnSelectFile = async (e) => {
    const files = Array.from(e.target.files);

    for (const file of files) {
      const fileSizeInMB = file.size / 1024 / 1024;

      if (fileSizeInMB > 150) {
      } else {
        let processedFile = null;

        if (file.type === "image/heic") {
          const convertedImage = await heic2any({
            blob: file,
            toType: "image/jpeg",
          });

          processedFile = await readAndCompressImage(convertedImage, {
            maxWidth: 800,
            maxHeight: 800,
            quality: 0.7,
            autoRotate: true,
            fileType: "jpeg",
          });
        } else if (file.type === "image/png" || file.type === "image/jpeg") {
          processedFile = await readAndCompressImage(file, {
            maxWidth: 800,
            maxHeight: 800,
            quality: 0.7,
            autoRotate: true,
            fileType: "jpeg",
          });
        } else {
          processedFile = file;
        }

        setImageFilePreview((prevState) => [
          ...prevState,
          processedFile || null,
        ]);
        setFileAttachment((prevState) => [...prevState, processedFile || file]);
      }
    }

    e.target.value = null;
  };

  const onMediaRemove = async (file) => {
    const fileIndex = fileAttachment.findIndex(
      (item) => item.name === file.name
    );
    if (fileIndex > -1) {
      setFileAttachment((prevState) => {
        const updatedFiles = [...prevState];
        updatedFiles.splice(fileIndex, 1);
        return updatedFiles;
      });
    }
    const imageIndex = imageFilePreview.findIndex(
      (item) => item.name === file.name
    );
    if (imageIndex > -1) {
      setImageFilePreview((prevState) => {
        const updatedPreview = [...prevState];
        updatedPreview.splice(imageIndex, 1);
        return updatedPreview;
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSucessAlert(false);
  };

  const handleListingClick = () => {
    if (conversation?.attributes?.list_data?.listable_id) {
      if (
        conversation?.attributes?.list_data?.listable_type === "ListingAnimal"
      ) {
        window.open(
          `/listing/${conversation.attributes.list_data.listable_id}`,
          "_blank"
        );
      } else {
        window.open(
          `/services/${conversation.attributes.list_data.listable.service_id}/subService/${conversation.attributes.list_data.listable.sub_service_id}/listings/${conversation.attributes.list_data.listable_id}`,
          "_blank"
        );
      }
    }
  };

  const handleNavigateToStore = () => {
    if (conversation?.attributes?.list_data?.listable?.seller?.store?.id) {
      window.open(
        `/neighbour/${conversation.attributes.list_data.listable.seller.store.id}`,
        "_blank"
      );
    }
  };

  useEffect(() => {}, [blockedUsers, conversations]);

  return (
    <>
      <Snackbar
        open={openSucessAlert}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert onClose={handleClose} severity="success">
            User Blocked Successfully!
          </Alert>
        </div>
      </Snackbar>
      <CustomModal
        title="Delete Alert"
        content={
          "Are you sure you want to delete this conversation? This action is irreversible."
        }
        openModal={openDeleteModal}
        closeModal={handleCloseDeleteModal}
        actionTitle={"Delete"}
        setConfirmProp={setConfirmProp}
        actionColor={"red"}
      />
      <CustomModal
        title="Block Alert"
        content={
          "Are you sure you want to block this user? This will remove all the conversations, related listings, related services, and neighbour stores."
        }
        openModal={openBlockModal}
        closeModal={handleCloseBlockModal}
        actionTitle={"Block"}
        setConfirmProp={setConfirmProp2}
        actionColor={"red"}
      />
      {loading ? (
        <Box sx={{ position: "relative", flex: 1 }}>
          <CircularProgress
            size={44}
            sx={{ top: "50%", left: "50%", position: "absolute" }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            width: "99.5%",
            height: "100%",
            minHeight: "100%",
            border: "1px solid #D0D0D0",
          }}
        >
          <Grid container sx={{ height: "100%", width: "100%" }}>
            <Hidden mdDown={disappearNames}>
              <Grid
                item
                md={3}
                xs={12}
                sx={{ borderRight: "1px solid #D0D0D0", paddingLeft: 0 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    overflowY: "auto",
                    paddingLeft: 0,
                    width: "100%",
                  }}
                >
                  <Box className={classes.root} sx={{ flex: 0.07 }}>
                    <TextField
                      variant="outlined"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={handleSearch}
                      sx={{ width: "100%", height: "100%" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              backgroundColor: "rgb(255, 192, 1)",
                              padding: "27.5px 14px",

                              borderTopLeftRadius: (theme) =>
                                theme.shape.borderRadius + "px",
                              borderBottomLeftRadius: (theme) =>
                                theme.shape.borderRadius + "px",
                            }}
                          >
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Box
                      flex={{ md: 0.93, xs: 0.9 }}
                      sx={{ overflowY: "scroll", height: "81vh" }}
                    >
                      {searchResults &&
                        searchResults.map(
                          ({ conversation, lastMessage, unreadCount }) => {
                            const isSelected =
                              conversation.sid === conversationId;

                            return (
                              <>
                                <Box
                                  key={conversation.sid}
                                  display="flex"
                                  p={1}
                                  position="relative"
                                  sx={{
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    fontSize: "16px",
                                    fontWeight:
                                      unreadCount > 0 ? "bold" : "normal",
                                    backgroundColor: isSelected
                                      ? "lightgrey"
                                      : "transparent",
                                  }}
                                  onClick={() =>
                                    handleConversationClick(conversation)
                                  }
                                >
                                  <Box
                                    sx={{
                                      flex: { md: 0.07, xs: 0.1, sm: 0.07 },
                                    }}
                                    justifyItems={"center"}
                                    alignItems={"center"}
                                    onClick={handleListingClick}
                                  >
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={getAvatarUrl(conversation)}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      flex: 1,
                                      overflowX: "wrap",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                    pl={1}
                                  >
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {conversation.friendlyName
                                        ? conversation.friendlyName
                                        : "Name"}

                                      {unreadCount > 0 && (
                                        <Box
                                          component={"span"}
                                          alignItems={"center"}
                                          justifyContent={"center"}
                                          ml={0.5}
                                          sx={{
                                            color: "#fff",

                                            borderRadius: "50%",
                                            background: "rgb(255, 192, 1)",
                                            paddingRight: "4px",
                                          }}
                                        >
                                          <Typography
                                            component={"span"}
                                            textAlign={"center"}
                                          >
                                            {" "}
                                            {unreadCount}{" "}
                                          </Typography>
                                        </Box>
                                      )}
                                    </Typography>
                                    {lastMessage && (
                                      <div
                                        style={{
                                          overflowX: "hidden",
                                          overflowY: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {lastMessage.body.replace(
                                          /<[^>]*>/g,
                                          ""
                                        )}
                                      </div>
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      sx={{ fontSize: "14px", color: "grey" }}
                                    >
                                      {lastMessage
                                        ? moment(
                                            lastMessage.dateCreated
                                          ).format("h:mm a")
                                        : ""}
                                    </Typography>
                                  </Box>

                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: "50%",
                                      marginTop: "1rem",
                                      right: "8px",
                                      transform: "translateY(-50%)",
                                      cursor: "pointer",
                                    }}
                                    onClick={(event) =>
                                      handleMenuClick(event, conversation)
                                    }
                                  >
                                    <MoreHorizIcon />
                                  </Box>
                                  {isMenuOpen &&
                                    conversation.sid === openMenuId && (
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          top: "50%",
                                          marginTop: "2.5rem",
                                          right: "8px",
                                          transform: "translateY(-50%)",
                                          backgroundColor: "#fff",
                                          boxShadow:
                                            "0px 2px 6px rgba(0, 0, 0, 0.16)",
                                          borderRadius: "4px",
                                          zIndex: 1,
                                        }}
                                      >
                                        <ListItem
                                          button
                                          className={classes.listItem}
                                          onClick={(event) =>
                                            handleDeleteClick(
                                              event,
                                              conversation
                                            )
                                          }
                                        >
                                          <ListItemIcon
                                            className={classes.listItemIcon}
                                          >
                                            <DeleteIcon />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={"Delete Conversation"}
                                          ></ListItemText>
                                        </ListItem>

                                        <ListItem
                                          button
                                          onClick={handleCloseClick}
                                          className={classes.listItem}
                                        >
                                          <ListItemIcon
                                            className={classes.listItemIcon}
                                          >
                                            <ClearIcon />
                                          </ListItemIcon>
                                          <ListItemText primary={"Close"} />
                                        </ListItem>
                                      </Box>
                                    )}
                                </Box>
                                <Divider sx={{ width: "100%" }} />
                              </>
                            );
                          }
                        )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Hidden>
            <Hidden mdDown={disappearCoversation}>
              {!conversationId && !stateData?.conversationId ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      width: "100%",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      {renderConversations.length > 0
                        ? "Select a conversation to view messages"
                        : "You have no conversations"}
                    </Typography>
                  </Box>
                </Box>
              ) : conversationLoading || retrieveConversationLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      mt: "30%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </Box>
              ) : (
                <Grid item xs={12} md={9} sx={{ height: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      width: "100%",
                      flex: 1,
                    }}
                  >
                    <Box sx={{ flex: 0.05 }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          border: "1px solid #D0D0D0",
                          py: 1,
                        }}
                      >
                        <Box
                          sx={{ flex: 0.5, display: "flex" }}
                          pt={0.5}
                          pl={{ md: 1, xs: 0 }}
                          mx={{ md: 1, xs: 0 }}
                        >
                          {isMedium && !disappearCoversation && (
                            <Box
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <IconButton
                                component="span"
                                sx={{ width: "100%" }}
                                onClick={handlePrevious}
                              >
                                <ArrowBackIosNewIcon
                                  sx={{
                                    fontSize: "20px",
                                    width: "100%",
                                  }}
                                />
                              </IconButton>
                            </Box>
                          )}
                          <Box
                            flex={{ md: 0.08, xs: 0.1, sm: 0.08, mr: 2 }}
                            onClick={handleNavigateToStore}
                            sx={{ cursor: "pointer" }}
                          >
                            <Avatar
                              alt="Remy Sharp"
                              src={otherUser && otherUser.avatar_url}
                            />
                          </Box>
                          <Box sx={{ mx: 1 }}>
                            {isMedium && !disappearCoversation && (
                              <Typography
                                fontSize={{ xs: "12px" }}
                                sx={{ fontWeight: "bold" }}
                              >
                                {listingName}
                              </Typography>
                            )}
                            <Typography
                              fontSize={{
                                xs: "12px",
                                md: "14px",
                                fontWeight: "bold",
                                "&:hover": {
                                  color: "rgb(255, 192, 1)",
                                },
                              }}
                              onClick={handleNavigateToStore}
                            >
                              {
                                conversation?.attributes?.list_data?.listable
                                  ?.seller?.store?.brand_name
                              }{" "}
                            </Typography>
                            <Typography
                              sx={{ color: "grey", fontSize: "12px" }}
                            >
                              {receiverOnlineStatus ? "Online" : "Offline"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          onClick={handleListingClick}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            "&:hover": {
                              color: "rgb(255, 192, 1)",
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              cursor: "pointer",

                              fontWeight: "bold",
                              maxWidth: {
                                xs: isMediumScreen ? "100px" : "250px",
                              },
                            }}
                            noWrap
                          >
                            {
                              conversation?.attributes?.list_data?.listable
                                ?.name
                            }
                          </Typography>
                        </Box>
                        <Box
                          sx={{ flex: 0.5 }}
                          mr={1}
                          display={"flex"}
                          justifyContent={"flex-end"}
                        >
                          <Box
                            sx={{
                              p: 1,
                            }}
                            onClick={(event) => handleRightMenuClick(event)}
                          >
                            <MoreVertIcon />
                          </Box>
                          {isRightMenuOpen && (
                            <Box
                              sx={{
                                position: "absolute",
                                marginTop: "2.5rem",
                                right: "8px",
                                transform: "translateY(-50%)",
                                backgroundColor: "#fff",
                                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.16)",
                                borderRadius: "4px",
                                zIndex: 1,
                              }}
                            >
                              <ListItem
                                button
                                className={classes.listItem}
                                onClick={(event) =>
                                  handleBlockClick(event, conversation)
                                }
                              >
                                <ListItemIcon className={classes.listItemIcon}>
                                  <BlockIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={"Block User"}
                                ></ListItemText>
                              </ListItem>
                              <ListItem
                                button
                                onClick={handleCloseBlockClick}
                                className={classes.listItem}
                              >
                                <ListItemIcon className={classes.listItemIcon}>
                                  <ClearIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Close"} />
                              </ListItem>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      pt={0.5}
                      pl={2}
                      pr={2}
                      pb={2}
                      sx={{ flex: 0.85, overflowY: "auto" }}
                      ref={conversationRef}
                    >
                      {conversation &&
                        messagesByRoom[conversation.uniqueName] &&
                        otherUser &&
                        messagesByRoom[conversation.uniqueName].map(
                          (message) => {
                            return (
                              <Message
                                // key={message.index}
                                childMessage={message}
                                receiverOnlineStatus={receiverOnlineStatus}
                                otherUser={otherUser}
                              />
                            );
                          }
                        )}
                    </Box>
                    {isBlocked ? (
                      <Box
                        flex={0.1}
                        border={"1px solid #D0D0D0"}
                        flexDirection={"row"}
                        display={"inline-flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Typography
                          variant="body5"
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          Person is unavailable
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <Box
                          flex={0.05}
                          border={"1px"}
                          flexDirection={"row"}
                          display={"inline-flex"}
                        >
                          <Box
                            flex={1}
                            flexDirection={"row"}
                            alignItems={"flex-end"}
                            display={"inline-flex"}
                            className={classes.qlEditor}
                          >
                            <ReactQuill
                              ref={reactQuillRef}
                              value={textInput}
                              onKeyDown={(e) => handleKeyDown(e)}
                              onChange={(value) => {
                                if (!isBlocked) {
                                  handleInputChange(value);
                                }
                              }}
                              disabled={isBlocked}
                              modules={{
                                toolbar: [
                                  ["bold", "italic", "underline", "strike"],
                                  [{ list: "ordered" }, { list: "bullet" }],
                                ],
                              }}
                              style={{
                                width: "100%",
                                fontSize: "40px",
                                maxWidth: "100%",
                              }}
                            />
                          </Box>
                        </Box>
                        <Box
                          flex={0.05}
                          flexDirection={"row"}
                          display={"inline-flex"}
                        >
                          {imageFilePreview && imageFilePreview.length > 0 && (
                            <Box sx={{ position: "relative", p: 2 }}>
                              <SelectedMediaView
                                media={imageFilePreview}
                                onFileRemove={onMediaRemove}
                              />
                            </Box>
                          )}

                          <Box
                            flex={1}
                            flexDirection={"row"}
                            alignItems={"flex-end"}
                            justifyContent={"flex-end"}
                            display={"inline-flex"}
                          >
                            <Box>
                              <input
                                id="file-upload"
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleOnSelectFile}
                                accept="image/jpeg, image/jpg, image/png, image/webp, image/heic, .pdf, .mp4, .mov"
                                multiple
                                disabled={sendMessageLoading || isBlocked}
                              />
                              <label htmlFor="file-upload">
                                <StyledBadge
                                  badgeContent={selectedFiles.length}
                                >
                                  <IconButton component="span">
                                    <AttachFileIcon
                                      sx={{
                                        fontSize: "26px",
                                        width: "25px",
                                        color: "rgb(255, 192, 1)",
                                      }}
                                    />
                                  </IconButton>
                                </StyledBadge>
                              </label>
                            </Box>
                            <Box>
                              {sendMessageLoading ? (
                                <CircularProgress size={24} />
                              ) : (
                                <IconButton
                                  component="span"
                                  sx={{
                                    color:
                                      (textInput && textInput.length > 0) ||
                                      fileAttachment.length > 0
                                        ? "grey"
                                        : "rgb(255, 192, 1)",
                                  }}
                                  onClick={sendMessage}
                                  disabled={
                                    (textInput && textInput.length > 0) ||
                                    fileAttachment.length > 0 ||
                                    !isBlocked
                                      ? false
                                      : true
                                  }
                                >
                                  <SendIcon
                                    sx={{
                                      fontSize: "26px",
                                      width: "25px",
                                      color:
                                        (textInput && textInput.length > 0) ||
                                        fileAttachment.length > 0
                                          ? "rgb(255, 192, 1)"
                                          : "grey",
                                    }}
                                  />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </Grid>
              )}
            </Hidden>
          </Grid>
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingTop: theme.spacing(0.5), // Adjust the top padding as needed
    paddingBottom: theme.spacing(0.5), // Adjust the bottom padding as needed
  },
  listItemIcon: {
    minWidth: theme.spacing(4), // Adjust the width of the icon as needed
  },
  textField: {
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottom: "none",
    },
    "& .MuiInputBase-input": {
      borderTop: "1px solid #D0D0D0",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "none",
      padding: "10px",
      marginBottom: "8px",
      flexGrow: 1,
    },
  },
  root: {
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart":
      {
        paddingLeft: 0,
      },
  },
  quill: {
    "& .ql-editor": {
      fontSize: "40px",
      borderBottom: "none",
    },
  },
  qlEditor: {
    "& .ql-editor": {
      fontSize: "18px !important",
      borderBottom: "none",
    },
    "& .ql-container.ql-snow": {
      border: "none",
      wordBreak: "break-word",
    },
  },
}));

const mapStateToProps = ({ Auth, Messages }) => ({
  user: Auth.user,
  jwtToken: Auth.jwtToken,
  messagesByRoom: Messages.messagesByRoom,
  conversations: Messages.conversations,
  unreadCount: Messages.unreadCount,
  loadConversation: Messages.loadConversation,
});

const mapDispatchToProps = (dispatch) => ({
  thunkInitChatClient: ({ token, setClient }) =>
    dispatch(Actions.thunkInitChatClient({ token, setClient })),
  updateConversationCount: ({ conversation, unreadCount }) =>
    dispatch(Actions.updateConversationCount({ conversation, unreadCount })),
  setConversations: ({ conversations }) =>
    dispatch(Actions.setConversations({ conversations })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
