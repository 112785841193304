import React, { useState } from "react";
import { Grid, styled, Typography, Button, useMediaQuery } from "@mui/material";
import { Container, Box } from "@mui/system";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const PetTrading = () => {
  const isMediumScreen = useMediaQuery("(max-width: 960px)");
  const [showBuyertext, setShowBuyerText] = useState(false);
  const [showSellertext, setShowSellerText] = useState(false);
  const [showAnimation, setShowAnimation] = useState(true);

  const text =
    " The safety and well-being of our pets are our top priority. When you buy a pet from our marketplace, you can be rest assured that you are getting a healthy and well-cared for animal. Our sellers are carefully vetted to ensure that they meet our high standards for animal welfare and care. We also offer a wide variety of resources and information to help you make an informed decision about your pet purchase. From breed profiles to care tips, we are here to support you every step of the way.";

  const text2 =
    " We are proud to partner with reputable breeders and sellers who are committed to the highest standards of animal welfare and care. When you sell your pets on our marketplace, you can be confident that you are reaching a global audience of dedicated pet lovers.Our platform offers a range of tools and features to help you grow your business and connect with buyers from around the world. From detailed pet listings to secure payment processing, we make it easy to sell your pets online.";
  const handleSeeMore = () => {
    setShowBuyerText(!showBuyertext);
  };
  const handleShowAnimations = () => {
    if (showAnimation) {
      setShowAnimation(false);
    }
  };
  const handleSeeMoreSeller = () => {
    setShowSellerText(!showSellertext);
  };
  return (
    <>
      <BannerTypo>
        <Container
          fixed
          maxWidth={false}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Box component={"span"}>
            <Typography
              className="BanTypo"
              component={"h1"}
              sx={{
                fontSize: { xs: "20px", sm: "36px", md: "44px", lg: "50px" },
              }}
            >
              The #1 Exotic Pet&nbsp;
              <Link
                to="/listings"
                style={{
                  color: "black",
                  textDecorationColor: "black",
                }}
              >
                <Typography
                  className="BanTypo"
                  component={"span"}
                  sx={{
                    color: "black",
                    fontSize: {
                      xs: "20px",
                      sm: "36px",
                      md: "44px",
                      lg: "50px",
                    },
                  }}
                >
                  Marketplace
                </Typography>
              </Link>
            </Typography>
          </Box>
        </Container>
      </BannerTypo>
      <PetWrap>
        <Container fixed>
          <BuySellerGrid
            container
            spacing={{ xs: 2, md: 3, lg: 3 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            <Grid
              item
              lg={5.5}
              md={5.5}
              xs={12}
              sm={12}
              className="ChildColumns"
            >
              <Box className="BoxContent">
                <motion.Grid
                  initial={{ opacity: 0, x: showAnimation ? -100 : 0 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: showAnimation ? 1 : 0 }}
                  onViewportLeave={handleShowAnimations}
                >
                  <Box
                    className="ImgBox"
                    sx={{
                      height: "477px",
                      backgroundImage: `url('https://res.cloudinary.com/dwezij7u7/image/upload/v1674834586/Moore%20Exotics%20Assets/pet%20trade/buyersanimals_rwhiqt.png')`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      borderRadius: "20px",
                    }}
                  ></Box>
                </motion.Grid>
                <motion.Grid
                  initial={{ opacity: 0, x: showAnimation ? -100 : 0 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: showAnimation ? 1 : 0 }}
                  onViewportLeave={handleShowAnimations}
                >
                  <Typography
                    className="CardTypo"
                    component="h2"
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "40px",
                      lineHeight: "53px",
                      marginTop: "30px",
                    }}
                  >
                    For Buyers
                  </Typography>
                </motion.Grid>
                <motion.Grid
                  initial={{ opacity: 0, x: showAnimation ? -100 : 0 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: showAnimation ? 1.2 : 0 }}
                  onViewportLeave={handleShowAnimations}
                >
                  <Typography
                    className="SellerDescrip"
                    component="p"
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                      marginTop: "10px",
                    }}
                  >
                    <>
                      {isMediumScreen && !showBuyertext
                        ? text.substring(0, 80)
                        : text}
                      {isMediumScreen && !showBuyertext && "..."}
                      {isMediumScreen && (
                        <Button varient="text" onClick={() => handleSeeMore()}>
                          {!showBuyertext ? "See More" : "See Less"}
                        </Button>
                      )}
                    </>
                  </Typography>
                </motion.Grid>
                <motion.Grid
                  initial={{ opacity: 0, x: showAnimation ? -100 : 0 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: showAnimation ? 1.5 : 0 }}
                  onViewportLeave={handleShowAnimations}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LoadMoreBtn component={Link} to={"listings"}>
                      Looking for a New Pet
                    </LoadMoreBtn>
                  </Grid>
                </motion.Grid>
              </Box>
            </Grid>
            <Grid
              className="ChildColumns"
              item
              lg={5.5}
              md={5.5}
              xs={12}
              sm={12}
            >
              <Box className="BoxContent">
                <motion.Grid
                  initial={{ opacity: 0, x: showAnimation ? 100 : 0 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: showAnimation ? 1 : 0 }}
                  onViewportLeave={handleShowAnimations}
                >
                  <Box
                    className="ImgBox"
                    sx={{
                      height: "477px",
                      backgroundImage: `url('https://res.cloudinary.com/dwezij7u7/image/upload/v1674834653/Moore%20Exotics%20Assets/pet%20trade/forsellerImg_gd0un7.png')`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  ></Box>
                </motion.Grid>
                <motion.Box
                  initial={{ opacity: 0, x: showAnimation ? 100 : 0 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: showAnimation ? 1 : 0 }}
                  onViewportLeave={handleShowAnimations}
                >
                  <Typography
                    className="CardTypo"
                    component="h2"
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "40px",
                      lineHeight: "53px",
                      marginTop: "30px",
                    }}
                  >
                    For Sellers
                  </Typography>
                </motion.Box>
                <motion.Grid
                  initial={{ opacity: 0, x: showAnimation ? 100 : 0 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: showAnimation ? 1.2 : 0 }}
                  onViewportLeave={handleShowAnimations}
                >
                  <Typography
                    className="SellerDescrip"
                    component="p"
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                      marginTop: "10px",
                      color: "#000000",
                    }}
                  >
                    {isMediumScreen && !showSellertext
                      ? text2.substring(0, 80)
                      : text2}
                    {isMediumScreen && !showSellertext && "..."}
                    {isMediumScreen && (
                      <Button
                        varient="text"
                        onClick={() => handleSeeMoreSeller()}
                      >
                        {!showSellertext ? "See More" : "See Less"}
                      </Button>
                    )}
                  </Typography>
                </motion.Grid>
                <motion.Grid
                  initial={{ opacity: 0, x: showAnimation ? 100 : 0 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: showAnimation ? 1.5 : 0 }}
                  onViewportLeave={handleShowAnimations}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LoadMoreBtn component={Link} to={"pricing"}>
                      Want to Sell Pets
                    </LoadMoreBtn>
                  </Grid>
                </motion.Grid>
              </Box>
            </Grid>
          </BuySellerGrid>
        </Container>
      </PetWrap>
      <Box style={{ display: "none" }} mt="30px" textAlign="center">
        <Container fixed maxWidth={false}>
          <LoadMoreBtn>Load More</LoadMoreBtn>
        </Container>
      </Box>
    </>
  );
};

const BuySellerGrid = styled(Grid)`
  margin-top: 50px;
  justify-content: center;
  .CardTypo {
    text-align: center;
  }
  & .BoxContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media screen and (max-width: 1536px) {
    .ChildColumns .ImgBox {
      height: 350px !important;
    }
  }
  @media screen and (max-width: 1200px) {
    .ChildColumns .ImgBox {
      height: 300px !important;
    }
  }
  @media screen and (max-width: 1250px) {
    .ChildColumns .CardTypo {
      font-size: 34px;
      line-height: 40px;
    }
  }
  @media screen and (max-width: 769px) {
    .CardTypo {
      font-family: "SharpSans";
      font-style: normal;
      font-weight: 700;
      font-size: 20px !important;
      line-height: 26px !important;
      text-align: center;
    }
    .SellerDescrip {
      font-family: "SharpSans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      color: #000000;
    }
    margin: auto;
    width: 100%;
  }
`;

const BannerTypo = styled(Box)`
  & .BanTypo {
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    line-height: 66px;
    font-style: normal;
    margin-top: 120px;
  }
  @media screen and (max-width: 1201px) {
    .BanTypo {
      font-size: 34px;
      line-height: 48px;
    }
  }
  @media screen and (max-width: 601px) {
    .BanTypo {
      margin-top: 50px;
      font-weight: 700px;
      font-size: 25px;
      line-height: 30px;
    }
  }
`;
const PetWrap = styled(Box)`
  & .ChildColumns {
    display: flex;
    align-items: center;

    background: #efefef;
    justify-content: center;
    margin: auto;
    margin-top: 45px;
    padding: 20px;
    border-radius: 10px;
    padding-bottom: 50px;
  }
  @media screen and (min-width: 1100px) {
    .ChildColumns {
      width: 45%;
      max-width: 45%;
    }
  }
  @media screen and (min-width: 601px) {
    margin-left: 45px;
    margin-right: 45px;
  }
`;
const LoadMoreBtn = styled(Button)`
  margin-top: 50px !important;
  background-color: #ffc001;
  font-style: normal;
  color: #000000;
  font-weight: 500;
  font-size: 18px !important;
  padding: 10px,
  line-height: 24px;
  text-transform: none;
  height: 48px;
  min-width: 250px !important;
  width: 250px;
  margin: auto;
  &:hover {
    background-color: #ffc001;
  }
  &:focus {
    background-color: #ffc001;
  }
`;

export default PetTrading;
