import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import StoreIcon from "@mui/icons-material/Store";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

const ListCard = ({ listData, classes, view }) => {
  const plans = ["Gold", "Diamond Special", "Diamond", "Platinum"];
  const navigate = useNavigate();
  const styleclasses = useStyles();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    return `${year}`;
  };

  const getCategoryName = (id) => {
    let category;
    if (classes.length > 0) {
      category = classes.filter((cat) => cat.id === id);
    }
    return category[0].name;
  };

  const handleNavigateToStore = (event, id) => {
    event.stopPropagation();
    navigate("/neighbour/" + id);
  };
  const handleNavigateToListing = (id) => {
    navigate("/listing/" + id);
  };

  return (
    <>
      {listData.map((item) => (
        <Box
          sx={{
            margin: { md: "2px" },
          }}
          onClick={() => handleNavigateToListing(item?.id)}
        >
          <Card
            sx={{
              border: "0.2px solid #eeeeee",
              borderRadius: "10px",
              cursor: "pointer",
              transition: "0.3s all",
              "&:hover": {
                color: "rgb(255, 192, 1)",
                backgroundColor: "#eeeeee",
              },
            }}
          >
            <div className={styleclasses.imageContainer}>
              <CardMedia
                sx={{
                  height: 180,
                  backgroundSize: "contain",
                  pointerEvents: "none",
                }}
                image={item?.media[0]?.url}
                title={item?.media[0]?.url}
              />
              {item?.on_hold && (
                <div className={item.on_hold && styleclasses.onHoldText}>
                  on Hold
                </div>
              )}
              {(item?.status === "sold" || item?.status === "nfs") && (
                <div
                  className={
                    (item.status === "sold" &&
                      styleclasses.soldOutStripeText) ||
                    (item.status === "nfs" && styleclasses.nfsStripeText)
                  }
                >
                  {item.status.toUpperCase()}
                </div>
              )}
            </div>
            <CardContent sx={{ height: view === "listing tab" ? 95 : 80 }}>
              <Grid xs={12}>
                <Typography variant="h6" noWrap fontWeight="bold">
                  {item.name}
                </Typography>
              </Grid>
              <Grid
                xs={12}
                display={"flex"}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    variant="p"
                    noWrap
                    sx={{ color: "grey", width: "100%" }}
                  >
                    {getCategoryName(item.category_id)}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  // alignItems: "center",
                }}
              >
                {plans.includes(
                  item?.seller?.user?.subscriptions[0]?.plan?.name
                ) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                    xs={12}
                  >
                    <Box>
                      <StoreIcon />
                    </Box>
                    <Box
                      onClick={(event) =>
                        handleNavigateToStore(event, item?.seller?.store?.id)
                      }
                      sx={{
                        maxWidth: "90%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography noWrap textAlign={"center"}>
                        {item?.seller?.store?.brand_name}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item xs="auto">
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "grey",
                      fontFamily: "SharpSans",
                    }}
                  >
                    {formatDate(item.created_at)}
                  </Typography>
                </Grid>
                <Grid item xs fontSize="medium">
                  {item.sex === "male" ? (
                    <MaleIcon sx={{ color: "blue" }} />
                  ) : item.sex === "female" ? (
                    <FemaleIcon sx={{ color: "#AA336A" }} />
                  ) : (
                    <>
                      <MaleIcon sx={{ color: "blue" }} />
                      <FemaleIcon sx={{ color: "#AA336A" }} />
                    </>
                  )}
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: { xs: "22px", md: "24px", lg: "27px" },
                      fontFamily: "SharpSans",
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                  >
                    ${item.price}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* </Link> */}
        </Box>
      ))}
    </>
  );
};
const useStyles = makeStyles({
  imageContainer: {
    position: "relative",
    width: "100%",
  },
  onHoldText: {
    position: "absolute",
    top: 30,
    right: -25,
    width: "100px",
    textAlign: "center",
    backgroundColor: "#FFD300",
    color: "#fff",
    padding: "5px",
    fontSize: "14px",
    transform: "translate(-0%, 100%) rotate(45deg)",
    transformOrigin: "top right",
  },
  soldOutStripeText: {
    position: "absolute",
    top: 30,
    right: -25,
    width: "100px",
    textAlign: "center",
    backgroundColor: "#EF0107",
    color: "#fff",
    padding: "5px",
    fontSize: "14px",
    transform: "translate(-0%, 100%) rotate(45deg)",
    transformOrigin: "top right",
  },
  nfsStripeText: {
    position: "absolute",
    top: 30,
    right: -25,
    width: "100px",
    textAlign: "center",
    backgroundColor: "black",
    color: "#fff",
    padding: "5px",
    fontSize: "14px",
    transform: "translate(-0%, 100%) rotate(45deg)",
    transformOrigin: "top right",
  },
});
export default ListCard;
