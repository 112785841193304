import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Box, MenuItem, Select, Typography } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { tableDummyData } from "../navbar/NavItemsData";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    color: "inherit",
  },
})(MuiTableCell);

const TableRow = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    cursor: "pointer",
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#aaaaaa" },
  },
})(MuiTableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 8px 8px 8px",
    margin: "10px 10px 10px 10px",
    overflow: "auto",
    borderRadius: "10px",
    height: "95vh",
  },
  table: {
    padding: 0,
    margin: 0,
    borderCollapse: "separate",
    borderSpacing: "0px 9px",
    backgroundColor: "white",
  },
  hover: {
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#eeeeee" },
  },
}));

const RecentOrders = () => {
  const filters = [
    "Last 1 day",
    "Last 7 days",
    "Last 15 days",
    "Last 1 month",
    "Last 3 months",
    "Last 6 months",
    "Last 1 year",
  ];
  const SoldItemTableHeadings = ["", "Animals", "Type", "Date", "Price"];
  const classes = useStyles();
  const [filter, setFilter] = useState(filters[1]);

  return (
    <Paper className={classes.root} elevation={2}>
      <Box
        px="5px"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "5px",
          border: "none",
        }}
      >
        <Typography sx={{ paddingTop: "5px", paddingLeft: "5px" }}>
          Recently Sold Items
        </Typography>
        <Select
          value={filter}
          size="small"
          disableUnderline
          onChange={(e) => setFilter(e.target.value)}
          className={classes.hover}
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
          }}
        >
          {filters.map((filter) => (
            <MenuItem key={filter} value={filter} sx={{ fontSize: "14px" }}>
              {filter}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow>
            {SoldItemTableHeadings.map((item) => (
              <TableCell
                align="center"
                sx={{
                  fontSize: { md: "14px", xl: "16px" },
                  fontWeight: "bold",
                }}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableDummyData.map((data) => (
            <TableRow key={data.animal}>
              <TableCell align="left" style={{ width: 0, padding: "5px" }}>
                <Avatar
                  width={40}
                  height={40}
                  alt={data.animal}
                  src={data.image}
                />
              </TableCell>
              <TableCell align="center" style={{ width: 0, padding: 0 }}>
                {data.animal}
              </TableCell>
              <TableCell align="center">{data.type}</TableCell>
              <TableCell align="center">{data.date}</TableCell>
              <TableCell align="center">{data.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default RecentOrders;
