import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PetAboutUs from "../../components/home/PetAboutUs";
import CompanyCertificates from "../../components/about/CompanyCertificates";
import Crew from "../../components/about/Crew";
import KnowUs from "../../components/about/knowUs";
import { Helmet } from 'react-helmet-async';

const AboutUs = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <meta property="og:image" content='https://prodappdata.s3.amazonaws.com/preview-images/about-preview.png' />
        <meta property="og:title" content="About Us" />
        <meta property="og:description" content="Moore Exotics About Us" />
      </Helmet>

      <Stack>
        <Box
          className={classes.headerBox}
          sx={{ width: "100%", height: "200px" }}
        >
          <Typography variant={"h3"} sx={{ fontWeight: "600" }}>
            About Us
          </Typography>
        </Box>
        <Box justifyContent={"center"} alignItems="center" display="flex">
          <PetAboutUs
            description={
              "We are Moore Exotics. We are passionate about providing a platform that connects loving pet owners with reputable breeders and other small businesses, who prioritize ethical business practices and the safety and well-being of their animals."
            }
            loadMoreText={loadMoreText}
          />
        </Box>
        <Box justifyContent={"center"} alignItems="center" display="flex">
          <CompanyCertificates />
        </Box>
        <Box>
          <KnowUs />
        </Box>

        <Box justifyContent={"center"} alignItems="center" display="flex">
          <Crew />
        </Box>
      </Stack>
    </>
  );
};

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  headerBox: {
    backgroundColor: "#000",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const loadMoreText = [
  "We are Moore Exotics. We're not just about transactions, we're about creating bonds that last a lifetime. We are passionate about providing a platform that connects loving pet owners with reputable businesses who can provide a multitude of services, who prioritize ethical business practices and the safety and well-being of their animals.",
  <>
    <br></br>
    <br></br>
  </>,
  "We work hard to ensure that everyone using our platform adheres to strict ethical standards. We carefully evaluate all businesses to ensure that they are committed to providing all animals with proper care, safety, and love. We also prioritize the safety and well-being of our customers. We require all of our users to follow strict safety protocols when handling animals, and we provide our customers with comprehensive information on how to properly care for their pets.",
  <>
    <br></br>
    <br></br>
  </>,
  "At Moore Exotics, we are committed to promoting safe practices and the responsible ownership of pets, especially exotic pets. Our team is made up of dedicated animal lovers who understand the importance of responsible pet ownership and the impact it has on the lives of pets and their owners. We believe that every pet deserves to be treated with love and most importantly respect.",
  <>
    <br></br>
    <br></br>
  </>,
  "We are dedicated to helping our customers through many ways, from finding the perfect pet, to finding ways on improving the lives of their pets. Thank you for choosing us for your animal needs. We look forward to helping you find your new furry, scaly, or feathered friend!",
];

export default AboutUs;
