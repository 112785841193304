export const config = {
  SERVER_URL:
    process.env.REACT_APP_NODE_ENV === "development"
      ? "http://localhost:3000"
      : process.env.REACT_APP_SERVER_URL,
  BUCKET_URL:
    process.env.NODE_ENV === "production" ? "prodappdata" : "stagingappdata",
};

console.log("SERVER_URL:", config.SERVER_URL);

export default config;
