import axios from "axios";
import * as Types from "../types/Services.types";
import config from "../../config";

export const thunkLoadServices = () => async (dispatch) => {
  dispatch(setLoading());
  let registerRequest;
  try {
    registerRequest = await axios.get(`${config.SERVER_URL}/api/v1/services`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 200) {
      const sortedData = registerRequestData.data.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      dispatch(
        loadServices({ services: sortedData, loading: false, error: "" })
      );
    }
  } catch ({ response }) {
    dispatch(
      loadServices({
        services: [],
        loading: false,
        error: "Unable to fetch services right now. Please try again later.",
      })
    );

    registerRequest = response;
  }
};

export const thunkLoadSubServices = (serviceId) => async (dispatch) => {
  dispatch(setLoading());
  let registerRequest;
  try {
    registerRequest = await axios.get(
      `${config.SERVER_URL}/api/v1/services/${serviceId}/sub_services`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 200) {
      const sortedData = registerRequestData.data.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      dispatch(loadSubServices({ subServices: sortedData, loading: false }));
    }
  } catch ({ response }) {
    registerRequest = response;
  }
};

const loadServices = (payload) => ({
  type: Types.LOAD_SERVICES,
  payload,
});

const loadSubServices = (payload) => ({
  type: Types.LOAD_SUB_SERVICES,
  payload,
});

const setLoading = () => ({
  type: Types.SET_SERVICE_LOADING,
});
