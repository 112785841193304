import * as Types from "../types";

const preState = {
  classes: [],
  breeds: [],
  subBreeds: [],
  error: "",
  genetics: [],
  subBreedGenetics: [],
  loading: false,
};

const CategoriesReducer = (state = preState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.LOAD_CATEGORIES:
      return {
        ...state,
        classes: payload.categories,
        loading: payload.loading,
      };
    case Types.ERROR_LOADING_CATEGORIES:
      return {
        ...state,
        error: payload.error,
      };

    case Types.LOAD_BREEDS:
      return {
        ...state,
        breeds: payload.breeds,
        loading: payload.loading,
      };
    case Types.LOAD_GENETICS:
      return {
        ...state,
        genetics: payload.genetics,
        loading: payload.loading,
      };
    case Types.LOAD_GENETICS_SUB_BREEDS:
      return {
        ...state,
        subBreedGenetics: payload.subBreedGenetics,
        loading: payload.loading,
      };
    case Types.LOAD_SUB_BREEDS:
      return {
        ...state,
        subBreeds: payload.subBreeds,
        loading: payload.loading,
      };
    case Types.SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export default CategoriesReducer;
