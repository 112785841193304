import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  Stack,
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  ListItemIcon,
  ListItem,
  Link,
  Alert,
  Slide,
  Snackbar,
  CircularProgress,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import { TagsInput } from "react-tag-input-component";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DropFileInput from "../../../components/dropFileInput/DropFileInput";
import { connect } from "react-redux";
import config from "../../../config";
import SelectedMediaView from "../../../components/seller/listings/SelectedMediaView";
import * as Actions from "../../../redux/actions/";

const initialState = {
  brand_name: "",
  owner_name: "",
  location: "",
  about: "",
  policy: "",
  facebook_url: "",
  instagram_url: "",
  twitter_url: "",
  youtube_url: "",
  store_cover: "",
  currency: "USD",
  away_message: "",
  min_price: "",
  max_price: "",
};

const youtubeUrlRegex = /^(https?:\/\/)?((www\.)?(youtube\.com|youtu\.be)).+$/;
const twitterUrlRegex = /^(https?:\/\/)?((www\.)?twitter\.com).+$/;
const instagramUrlRegex = /^(https?:\/\/)?((www\.)?instagram\.com).+$/;
const facebookUrlRegex = /^(https?:\/\/)?((www\.)?facebook\.com).+$/;
const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
const CreateOrEditSellerProfile = ({
  store,
  jwtToken,
  setStore,
  seller,
  getStore,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const plans = ["Diamond Special", "Diamond", "Platinum"];
  const handleAddVideoUrlsToList = (links) => {
    if (links.length > 3) {
      links = links.slice(0, 3);
    }

    setVideoLinks(links);
  };
  const [profileColor, setProfileColor] = useState();
  const [location, setLocation] = useState("");
  const [formValues, setFormValues] = useState(initialState);
  const [pickupOptions, setPickupOptions] = useState([]);
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [awayStatus, setAwayStatus] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [processing, setProcessing] = useState(false);
  const [videoLinks, setVideoLinks] = useState([]);
  const [paymentMethod, setPaymentMethods] = useState([]);
  const [profileVideo, setProfileVideo] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [trophyCases, setTrophyCases] = useState([]);
  const [trophyListings, setTrophyListings] = useState([]);
  const [allowedTrophyCases, setallowedTrophyCases] = useState(-1);
  const backgroundProfileColors = [
    { id: 0, color: "Default", colorcode: "#FFFFFF" },
    { id: 1, color: "Sandy Beach", colorcode: "#FFEAD7" },
    { id: 2, color: "Wavecrest", colorcode: "#D2E9E7" },
    { id: 3, color: "Papaya Whip", colorcode: "#F5F5DC" },
    { id: 4, color: "Olive", colorcode: "#F7F700" },
    { id: 5, color: "Honey Dew", colorcode: "#DDFFDD" },
    { id: 6, color: "Police Strobe", colorcode: "#54CBFF" },
  ];

  useEffect(() => {
    setLoading(true);
    if (seller.store) {
      getStore({ storeId: seller.store.id });
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    const loadListings = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/v1/seller/${seller.id}/animals`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;
      }

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 200) {
        setTrophyListings(
          registerRequestData.data.filter(
            (listing) => listing.status === "nfs" || listing.status === "sold"
          )
        );
        console.log("trophy", trophyListings);
      }
    };

    if (seller.user.subscriptions[0].plan.name === "Diamond" || "Diamond Special") {
      setallowedTrophyCases(15);
    } else if (seller.user.subscriptions[0].plan.name === "Platinum") {
      setallowedTrophyCases(5);
    }
    loadListings();
  }, [seller]);

  const isUrlValid = (url, regex) => {
    return regex.test(url);
  };

  const handleTrophyCase = (event) => {
    const {
      target: { value },
    } = event;

    if (
      trophyCases.length < allowedTrophyCases ||
      value.length < trophyCases.length
    ) {
      setTrophyCases(typeof value === "string" ? value.split(",") : value);
    }
  };

  const handlePickupOptionChange = (event) => {
    const option = event.target.name;
    const isChecked = event.target.checked;
    setPickupOptions((prevOptions) => {
      if (isChecked) {
        return [...prevOptions, option];
      } else {
        return prevOptions.filter((item) => item !== option);
      }
    });
  };

  const handlePaymentMethodChange = (event) => {
    const {
      target: { value },
    } = event;
    setPaymentMethods(typeof value === "string" ? value.split(",") : value);
  };

  const onFileChange = async (file) => {
    setImages([...images, file]);
    setPreviewImages([...previewImages, file]);
  };

  const onFileRemove = async (file) => {
    const newImages = images.filter((image) => image !== file);
    setImages(newImages);

    const newPreviewImages = previewImages.filter((image) => image !== file);
    setPreviewImages(newPreviewImages);
  };

  const onPhoneNumberChange = (e) => {
    e.preventDefault();
    if (
      e.target.value.length === 3 &&
      e.nativeEvent.inputType !== "deleteContentBackward"
    ) {
      setPhoneNumber(e.target.value + "-");
    } else if (
      e.target.value.length === 4 &&
      e.nativeEvent.inputType === "insertText"
    ) {
      setPhoneNumber(
        phoneNumber + "-" + e.target.value[e.target.value.length - 1]
      );
    } else if (
      e.target.value.length === 7 &&
      e.nativeEvent.inputType !== "deleteContentBackward"
    ) {
      setPhoneNumber(e.target.value + "-");
    } else if (
      e.target.value.length === 8 &&
      e.nativeEvent.inputType === "insertText"
    ) {
      setPhoneNumber(
        phoneNumber + "-" + e.target.value[e.target.value.length - 1]
      );
    } else {
      setPhoneNumber(e.target.value);
    }
  };

  const handleAwayStatusChange = (event) => {
    setAwayStatus(event.target.checked);
  };

  const handleVisibilityChange = (event) => {
    setVisibility(event.target.checked);
  };

  const handleProfileVideoChange = (event) => {
    setProfileVideo(event.target.value);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarStates({ ...snackbarStates, open: false });
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handleChangeProfleColor = (event) => {
    setProfileColor(event.target.value);
  };

  useEffect(() => {
    const isYoutubeUrlValid = formValues.youtube_url
      ? isUrlValid(formValues.youtube_url, youtubeUrlRegex)
      : true;
    const isFacebookUrlValid = formValues.facebook_url
      ? isUrlValid(formValues.facebook_url, facebookUrlRegex)
      : true;
    const isInstagramUrlValid = formValues.instagram_url
      ? isUrlValid(formValues.instagram_url, instagramUrlRegex)
      : true;
    const isTwitterUrlValid = formValues.twitter_url
      ? isUrlValid(formValues.twitter_url, twitterUrlRegex)
      : true;

    const isValid =
      isYoutubeUrlValid &&
      isFacebookUrlValid &&
      isInstagramUrlValid &&
      isTwitterUrlValid;
    setError(!isValid);
  }, [formValues]);

  useEffect(() => {
    if (store) {
      const options = store.delivery_option
        ? store.delivery_option.split(",")
        : [];
      setLocation(store.location);
      setFormValues({
        brand_name: store.brand_name,
        owner_name: store.owner_name,
        about: store.about,
        policy: store.policy,
        facebook_url: store.facebook_url,
        instagram_url: store.instagram_url,
        twitter_url: store.twitter_url,
        store_cover: store.store_cover && store.store_cover[0],
        youtube_url: store.youtube_url,
        currency: store.currency,
        payment_methods: store.payment_methods,
        away_message: store.away_message,
        min_price: store.min_price,
        max_price: store.max_price,
      });
      setPaymentMethods(store.payment_methods || []);
      setPhoneNumber(store.phone_number);
      setAwayStatus(store.away_status);
      setVisibility(store.store_status);
      setPickupOptions(options);
      setProfileColor(store.profile_color);
      if (seller.user.subscriptions[0].plan.name === "Diamond" || "Diamond Special") {
        if (store.store_cover) {
          setVideoLinks(store.store_cover);
        }
      } else {
        if (store.store_cover) {
          setProfileVideo(store.store_cover[0]);
        }
      }
      if (store.brand_logo) {
        setImages([store.brand_logo]);
        setPreviewImages([store.brand_logo]);
      }
      if (store.trophy_cases && store.trophy_cases.length > 0) {
        const listingNames = store.trophy_cases.map(
          (trophyCase) => trophyCase.listing_animal?.name
        );
        setTrophyCases(listingNames);
      }
    } else {
      console.log("Store not found");
    }
  }, [store, seller.user.subscriptions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    const data = {
      ...formValues,
      location: location,
      payment_methods: paymentMethod,
      brand_logo: images[0],
      store_status: visibility,
      away_status: awayStatus,
      delivery_option: pickupOptions.join(",").toString(),
      phone_number: phoneNumber,
      profile_color: profileColor,
      store_cover:
        videoLinks.length > 0
          ? videoLinks
          : profileVideo !== ""
            ? [profileVideo]
            : [],
      trophy_cases: (() => {
        const listingNamesSet = new Set(trophyCases);
        return trophyListings.reduce((ids, listing) => {
          if (listingNamesSet.has(listing.name)) {
            ids.push(listing.id);
          }
          return ids;
        }, []);
      })(),
    };
    let registerRequest;
    try {
      if (!(seller.store || store)) {
        registerRequest = await axios.post(
          `${config.SERVER_URL}/api/v1/seller/${seller.id}/stores`,
          {
            store: {
              ...data,
            },
          },
          {
            headers: {
              "Content-Type": "multipart/form-data; ",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } else {
        registerRequest = await axios.put(
          `${config.SERVER_URL}/api/v1/stores/${store.id}`,
          {
            store: {
              ...data,
            },
          },
          {
            headers: {
              "Content-Type": "multipart/form-data; ",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      }
    } catch ({ response }) {
      registerRequest = response;

      if (registerRequest.status === 422) {
        setSnackbarStates({
          open: true,
          message: registerRequest.data.message,
          severity: "danger",
        });
      }
      setProcessing(false);
    }

    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 201 || registerRequest.status === 200) {
      setStore(registerRequestData.data);
      setSnackbarStates({
        open: true,
        message: registerRequestData.message,
        severity: "success",
      });
      setProcessing(false);
      navigate("/seller/profile");
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack style={{ backgroundColor: "#ebecf0", height: "100vh" }}>
      <Snackbar
        transitionDuration={{ enter: 1000, exit: 2000 }}
        TransitionComponent={Slide}
        TransitionProps={{ enter: true, exit: false }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarStates.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alginItem="center"
      >
        <Grid item display="flex">
          <Box
            xl={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              paddingTop: 2,
              paddingBottom: 2,
              margin: "auto",
              width: { xs: "90%", sm: "90%", md: "60%" },
            }}
          >
            <Typography
              variant="h4"
              align="left"
              className={classes.headerStyle}
            >
              Seller Profile
            </Typography>
            <Typography
              align="left"
              sx={{ fontSize: { xs: "14px", md: "16px" } }}
            >
              Your Profile will only be visible to sellers that you choose to
              interact with by sending inquiries. It will save you time by
              providing information necessary to complete sales.
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={3} md={2}>
                  <Typography component={"p"} sx={{ fontWeight: 600, pt: 1 }}>
                    Full Brand Name
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                  <TextField
                    placeholder="Enter your brand name"
                    helperText="How your online brand page will be displayed. Does not have to be a registered business name."
                    size="small"
                    type="text"
                    name="brand_name"
                    value={formValues.brand_name}
                    onChange={handleFormChange}
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.textField,
                      },
                    }}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={3} md={2}>
                  <Typography
                    component={"p"}
                    sx={{ fontWeight: 600, pt: { md: 1 } }}
                  >
                    Owner Full Name
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                  <TextField
                    placeholder="Enter your full name"
                    helperText="For calculating shipping to your general area."
                    size="small"
                    type="text"
                    name="owner_name"
                    value={formValues.owner_name}
                    onChange={handleFormChange}
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.postalCodeTextField,
                      },
                    }}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={3} md={2}>
                  <Typography component={"p"} sx={{ fontWeight: 600, pt: 1 }}>
                    Location
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                  <FormControl sx={{ width: "100%" }} required>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      size="small"
                      fullWidth={true}
                      name="location"
                      value={location}
                      MenuProps={MenuProps}
                      onChange={handleLocationChange}
                      sx={{
                        backgroundColor: "white",
                      }}
                    >
                      {states.map((list) => (
                        <MenuItem key={list} value={list}>
                          <ListItem alignItems="center">
                            <Typography component={"span"}>{list}</Typography>
                          </ListItem>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container direction="row">
                <Grid item xs={3} md={2}>
                  <Typography component={"p"} sx={{ fontWeight: 600, pt: 1 }}>
                    Delivery Options
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                  <FormControlLabel
                    label="Local Pickup"
                    name="local_pickup"
                    control={
                      <Checkbox
                        checked={pickupOptions.includes("local_pickup")}
                        onChange={handlePickupOptionChange}
                        defaultChecked={false}
                        sx={{ color: "rgb(255, 192, 1)" }}
                      />
                    }
                  ></FormControlLabel>
                  <FormControlLabel
                    label="Regional Pickup"
                    name="regional_pickup"
                    control={
                      <Checkbox
                        checked={pickupOptions.includes("regional_pickup")}
                        onChange={handlePickupOptionChange}
                        defaultChecked={false}
                        sx={{ color: "rgb(255, 192, 1)" }}
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={3} md={2}>
                  <Typography component={"p"} sx={{ fontWeight: 600, pt: 1 }}>
                    Phone Number
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                  <TextField
                    helperText="Required in 415-555-2671 format"
                    placeholder="415-555-2671"
                    size="small"
                    type="tel"
                    value={phoneNumber}
                    name="phone_number"
                    fullWidth
                    autoComplete="off"
                    onChange={onPhoneNumberChange}
                    style={{ backgroundColor: "transparent" }}
                    inputProps={{
                      minLength: 12,
                      maxLength: 12,
                      pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
                    }}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={3} md={2}>
                  <Typography component={"p"} sx={{ fontWeight: 600, pt: 1 }}>
                    About
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                  <TextField
                    placeholder="Describe yourself and/or your company."
                    size="small"
                    type="text"
                    multiline
                    rows={3}
                    name="about"
                    value={formValues.about}
                    onChange={handleFormChange}
                    fullWidth
                    required
                    sx={{ backgroundColor: "white" }}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={3} md={2}>
                  <Typography component={"p"} sx={{ fontWeight: 600, pt: 1 }}>
                    Policy
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                  <TextField
                    placeholder="Describe your payment and shipping policies and guarantees that override the default store policy."
                    size="small"
                    type="text"
                    multiline
                    rows={3}
                    name="policy"
                    value={formValues.policy}
                    onChange={handleFormChange}
                    fullWidth
                    required
                    sx={{ backgroundColor: "white" }}
                  />
                </Grid>
              </Grid>
              {plans.includes(seller.user.subscriptions[0].plan.name) && (
                <Grid container direction="row" sx={{ mt: 2 }}>
                  <Grid item xs={3} md={2}>
                    <Typography component={"p"} sx={{ fontWeight: 600, pt: 1 }}>
                      Profile Video
                    </Typography>
                  </Grid>
                  <Grid item xs={9} md={10}>
                    {seller.user.subscriptions[0].plan.name === "Diamond" || "Diamond Special" ? (
                      <>
                        <TagsInput
                          name="video_link"
                          value={videoLinks}
                          onChange={handleAddVideoUrlsToList}
                          placeHolder="Enter video links upto 3"
                        />
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 100,
                            pt: 1,
                            ml: 2,
                            fontSize: "12px",
                          }}
                        >
                          Enter a Youtube Video Link and press Enter.
                        </Typography>
                      </>
                    ) : (
                      <TextField
                        placeholder="https://www.youtube.com/your-username"
                        size="small"
                        type="link"
                        name="profile_video"
                        value={profileVideo}
                        onChange={handleProfileVideoChange}
                        fullWidth
                        error={!isUrlValid(profileVideo, youtubeUrlRegex)}
                        helperText={
                          !isUrlValid(profileVideo, youtubeUrlRegex)
                            ? "Please enter a valid YouTube URL"
                            : "Enter a YouTube Video Link"
                        }
                        InputProps={{
                          classes: {
                            root: classes.textField,
                          },
                        }}
                        sx={{ color: "red" }}
                      />
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={3} md={2}>
                  <Typography component={"p"} sx={{ fontWeight: 600, pt: 1 }}>
                    Profile Color
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      size="small"
                      fullWidth={true}
                      value={profileColor}
                      MenuProps={MenuProps}
                      onChange={handleChangeProfleColor}
                      sx={{
                        backgroundColor: "white",
                      }}
                    >
                      {backgroundProfileColors.map((list) => (
                        <MenuItem key={list.id} value={list.colorcode}>
                          <ListItem alignItems="center">
                            <ListItemIcon xs={6}>
                              <Box
                                sx={{
                                  width: 24,
                                  height: 24,
                                  backgroundColor: list.colorcode,
                                  borderRadius: "50%",
                                }}
                              />
                            </ListItemIcon>
                            <Typography component={"span"}>
                              {list.color}
                            </Typography>
                          </ListItem>
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      Chosen color will appear as your store background color.
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              {allowedTrophyCases > 1 && (
                <Grid container direction="row" sx={{ mt: 2 }}>
                  <Grid item xs={3} md={2}>
                    <Typography component={"p"} sx={{ fontWeight: 600, pt: 1 }}>
                      Trophy Cases
                    </Typography>
                  </Grid>
                  <Grid item xs={9} md={10}>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        size="small"
                        fullWidth={true}
                        value={trophyCases}
                        onChange={handleTrophyCase}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        sx={{ backgroundColor: "white" }}
                      >
                        {trophyListings.map((list) => (
                          <MenuItem key={list.id} value={list.name}>
                            <Checkbox
                              checked={trophyCases.indexOf(list.name) > -1}
                            />
                            <ListItemText primary={list.name} />
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        You can add upto {allowedTrophyCases} trophy cases
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={3} md={2}>
                  <Typography component={"p"} sx={{ fontWeight: 600, pt: 1 }}>
                    Brand Logo
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                  <DropFileInput
                    onFileChange={(file) => onFileChange(file)}
                    filesUrls={previewImages}
                    disabled={previewImages.length > 0 ? true : false}
                    acceptFileTypes={".jpg, .jpeg, .gif, .png, .webp, .heic"}
                  />
                  {previewImages && previewImages.length > 0 ? (
                    <SelectedMediaView
                      media={previewImages}
                      onFileRemove={onFileRemove}
                    />
                  ) : null}
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Accordion
                    disableGutters
                    elevation={0}
                    sx={{ backgroundColor: "#ebecf0", ml: -2, mr: -2 }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon sx={{ color: "rgb(255, 192, 1)" }} />
                      }
                      sx={{
                        flexDirection: "row-reverse",
                      }}
                    >
                      <Typography
                        component={"p"}
                        sx={{
                          fontWeight: 600,
                          color: "rgb(255, 192, 1)",
                        }}
                      >
                        More Settings
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant="h5"
                        align="left"
                        className={classes.headerStyle}
                        sx={{ mt: 2 }}
                      >
                        Account Info
                      </Typography>
                      <Grid container direction="row">
                        <Grid item xs={3} md={2}>
                          <Typography
                            component={"p"}
                            sx={{ fontWeight: 600, pt: 1 }}
                          >
                            Store Type
                          </Typography>
                        </Grid>
                        <Grid item xs={9} md={10}>
                          <TextField
                            size="small"
                            type="text"
                            helperText="Seller"
                            readOnly={true}
                            defaultValue="Seller"
                            disabled
                            name="store_type"
                            fullWidth
                            InputProps={{
                              classes: {
                                root: classes.textField,
                              },
                            }}
                            required
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" sx={{ mt: 2 }}>
                        <Grid item xs={3} md={2}>
                          <Typography
                            component={"p"}
                            sx={{ fontWeight: 600, pt: 1 }}
                          >
                            Contact Email
                          </Typography>
                        </Grid>
                        <Grid item xs={9} md={10}>
                          <TextField
                            size="small"
                            value={seller.user.email}
                            readOnly={true}
                            defaultValue="contact@example.com"
                            disabled
                            type="email"
                            name="email"
                            fullWidth
                            InputProps={{
                              classes: {
                                root: classes.textField,
                              },
                            }}
                            required
                          />
                        </Grid>
                      </Grid>
                      <Typography
                        variant="h5"
                        align="left"
                        className={classes.headerStyle}
                        sx={{ mt: 2 }}
                      >
                        Links
                      </Typography>

                      <Typography
                        align="left"
                        sx={{ fontSize: { xs: "14px", md: "16px" } }}
                      >
                        You can share links to profiles about yourself on social
                        media sites like Facebook and Instagram or forums. You
                        can include your website if you have one.
                      </Typography>
                      <Grid container direction="row" sx={{ mt: 2 }}>
                        <Grid item xs={3} md={2}>
                          <Typography
                            component={"p"}
                            sx={{ fontWeight: 600, pt: 1 }}
                          >
                            Facebook Page
                          </Typography>
                        </Grid>
                        <Grid item xs={9} md={10}>
                          <TextField
                            placeholder="https://www.facebook.com/profile.php?id=1000303034839"
                            size="small"
                            type="link"
                            name="facebook_url"
                            value={formValues.facebook_url}
                            onChange={handleFormChange}
                            fullWidth
                            error={
                              formValues.facebook_url &&
                              !isUrlValid(
                                formValues.facebook_url,
                                facebookUrlRegex
                              )
                            }
                            helperText={
                              formValues.facebook_url &&
                              !isUrlValid(
                                formValues.facebook_url,
                                facebookUrlRegex
                              ) &&
                              "Please enter a valid Facebook URL"
                            }
                            InputProps={{
                              classes: {
                                root: classes.textField,
                              },
                            }}
                            sx={{ color: "red" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" sx={{ mt: 2 }}>
                        <Grid item xs={3} md={2}>
                          <Typography
                            component={"p"}
                            sx={{ fontWeight: 600, pt: 1 }}
                          >
                            Instagram Page
                          </Typography>
                        </Grid>
                        <Grid item xs={9} md={10}>
                          <TextField
                            placeholder="https://www.instagram.com/your-username"
                            size="small"
                            type="link"
                            value={formValues.instagram_url}
                            name="instagram_url"
                            onChange={handleFormChange}
                            fullWidth
                            error={
                              formValues.instagram_url &&
                              !isUrlValid(
                                formValues.instagram_url,
                                instagramUrlRegex
                              )
                            }
                            helperText={
                              formValues.instagram_url &&
                              !isUrlValid(
                                formValues.instagram_url,
                                instagramUrlRegex
                              ) &&
                              "Please enter a valid Instagram URL"
                            }
                            InputProps={{
                              classes: {
                                root: classes.textField,
                              },
                            }}
                            sx={{ color: "red" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" sx={{ mt: 2 }}>
                        <Grid item xs={3} md={2}>
                          <Typography
                            component={"p"}
                            sx={{ fontWeight: 600, pt: 1 }}
                          >
                            Twitter Page
                          </Typography>
                        </Grid>
                        <Grid item xs={9} md={10}>
                          <TextField
                            placeholder="https://www.twitter.com/your-username"
                            size="small"
                            type="link"
                            name="twitter_url"
                            value={formValues.twitter_url}
                            onChange={handleFormChange}
                            fullWidth
                            error={
                              formValues.twitter_url &&
                              !isUrlValid(
                                formValues.twitter_url,
                                twitterUrlRegex
                              )
                            }
                            helperText={
                              formValues.twitter_url &&
                              !isUrlValid(
                                formValues.twitter_url,
                                twitterUrlRegex
                              ) &&
                              "Please enter a valid Twitter URL"
                            }
                            InputProps={{
                              classes: {
                                root: classes.textField,
                              },
                            }}
                            sx={{ color: "red" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" sx={{ mt: 2 }}>
                        <Grid item xs={3} md={2}>
                          <Typography
                            component={"p"}
                            sx={{ fontWeight: 600, pt: 1 }}
                          >
                            YouTube Page
                          </Typography>
                        </Grid>
                        <Grid item xs={9} md={10}>
                          <TextField
                            placeholder="https://www.youtube.com/your-username"
                            size="small"
                            type="link"
                            name="youtube_url"
                            value={formValues.youtube_url}
                            onChange={handleFormChange}
                            fullWidth
                            error={
                              formValues.youtube_url &&
                              !isUrlValid(
                                formValues.youtube_url,
                                youtubeUrlRegex
                              )
                            }
                            helperText={
                              formValues.youtube_url &&
                              !isUrlValid(
                                formValues.youtube_url,
                                youtubeUrlRegex
                              ) &&
                              "Please enter a valid YouTube URL"
                            }
                            InputProps={{
                              classes: {
                                root: classes.textField,
                              },
                            }}
                            sx={{ color: "red" }}
                          />
                        </Grid>
                      </Grid>
                      <Typography
                        variant="h5"
                        align="left"
                        className={classes.headerStyle}
                        sx={{ mt: 2 }}
                      >
                        Payment & Delivery Settings
                      </Typography>
                      <Grid container direction="row" sx={{ mt: 2 }}>
                        <Grid item xs={3} md={2}>
                          <Typography
                            component={"p"}
                            sx={{ fontWeight: 600, pt: 1 }}
                          >
                            Currency
                          </Typography>
                        </Grid>
                        <Grid item xs={9} md={10}>
                          <TextField
                            size="small"
                            type="link"
                            name="currency"
                            value={formValues.currency}
                            helperText="Contact support to change this value."
                            readOnly={true}
                            defaultValue="USD"
                            disabled
                            fullWidth
                            InputProps={{
                              classes: {
                                root: classes.textField,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" sx={{ mt: 2 }}>
                        <Grid item xs={3} md={2}>
                          <Typography component={"p"} sx={{ fontWeight: 600 }}>
                            Payment Method
                          </Typography>
                        </Grid>
                        <Grid item xs={9} md={10}>
                          <FormControl sx={{ width: "100%" }}>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              multiple
                              size="small"
                              fullWidth={true}
                              value={paymentMethod}
                              onChange={handlePaymentMethodChange}
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={MenuProps}
                              sx={{ backgroundColor: "white" }}
                            >
                              {paymentMethods.map((method) => (
                                <MenuItem key={method} value={method}>
                                  <Checkbox
                                    checked={paymentMethod.indexOf(method) > -1}
                                  />
                                  <ListItemText primary={method} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" sx={{ mt: 2 }}>
                        <Grid item xs={3} md={2}>
                          <Typography component={"p"} sx={{ fontWeight: 600 }}>
                            Domestic Shipping
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <TextField
                              size="small"
                              name="min_price"
                              label="Min Price"
                              id="min_price"
                              type="number"
                              fullWidth
                              value={formValues.min_price}
                              onChange={handleFormChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {formValues.currency}
                                  </InputAdornment>
                                ),
                                classes: {
                                  root: classes.numberTextField,
                                },
                              }}
                              sx={{ mb: 2, mr: 2, backgroundColor: "white" }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <TextField
                              size="small"
                              name="max_price"
                              label="Max Price"
                              id="max_price"
                              type="number"
                              fullWidth
                              value={formValues.max_price}
                              onChange={handleFormChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {formValues.currency}
                                  </InputAdornment>
                                ),
                                classes: {
                                  root: classes.numberTextField,
                                },
                              }}
                              sx={{ mb: 2, mr: 2, backgroundColor: "white" }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="h5"
                        align="left"
                        className={classes.headerStyle}
                        sx={{ mt: 2 }}
                      >
                        Inquiry Settings
                      </Typography>

                      <Grid container direction="row" sx={{ mt: 2 }}>
                        <Grid item xs={3} md={2}>
                          <Typography
                            component={"p"}
                            sx={{ fontWeight: 600, mt: 1 }}
                          >
                            Away Message
                          </Typography>
                        </Grid>
                        <Grid item xs={9} md={10}>
                          <FormControlLabel
                            label="Enable Away Status"
                            control={
                              <Checkbox
                                value={awayStatus}
                                onChange={handleAwayStatusChange}
                                defaultChecked={awayStatus}
                                sx={{ color: "rgb(255, 192, 1)" }}
                              />
                            }
                          ></FormControlLabel>
                          <TextField
                            placeholder="When away, this text will be shown to user to inform them of delayed responses times for reasons such as vacation, weather, or other temporary conditions."
                            size="small"
                            type="text"
                            multiline
                            rows={3}
                            name="away_message"
                            value={formValues.away_message}
                            onChange={handleFormChange}
                            fullWidth
                            sx={{ backgroundColor: "white" }}
                          />
                        </Grid>
                      </Grid>
                      <Typography
                        variant="h5"
                        align="left"
                        className={classes.headerStyle}
                        sx={{ mt: 2 }}
                      >
                        Other Settings
                      </Typography>
                      <Grid container direction="row" sx={{ mt: 2 }}>
                        <Grid item xs={3} md={2}>
                          <Typography
                            component={"p"}
                            sx={{ fontWeight: 600, mt: 1 }}
                          >
                            Visibility
                          </Typography>
                        </Grid>
                        <Grid item xs={9} md={10}>
                          <FormControlLabel
                            label="Public visibility of your name/contact details."
                            control={
                              <Checkbox
                                value={visibility}
                                onChange={handleVisibilityChange}
                                defaultChecked={visibility}
                                sx={{ color: "rgb(255, 192, 1)" }}
                              />
                            }
                          ></FormControlLabel>
                          <Typography
                            align="left"
                            sx={{ fontSize: { xs: "14px", md: "16px" } }}
                          >
                            Uncheck if you want only logged in users, and no
                            search engines, to see your name/contact details.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="h5"
                        align="left"
                        className={classes.headerStyle}
                        sx={{ mt: 2 }}
                      >
                        QR Code
                      </Typography>
                      <Grid container direction="row" sx={{ mt: 2 }}>
                        <Grid item xs={3} md={2}></Grid>
                        <Grid item xs={9} md={10}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <img
                              alt="qr-code"
                              src={
                                window.location.origin + "/images/qr-code.png"
                              }
                              style={{ width: "150px" }}
                            ></img>
                            <Typography flexGrow={1}>
                              Download as <Link>PNG</Link> or <Link>SVG</Link>.
                              Moore Exotics logos can be downloaded{" "}
                              <Link>here</Link>.
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={3} md={2}></Grid>
                <Grid
                  item
                  display="flex"
                  justifyContent="space-between"
                  xs={9}
                  md={10}
                >
                  <Button
                    variant="outlined"
                    className={classes.buttons}
                    onClick={() => {
                      navigate("/seller/profile");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={processing || error ? true : false}
                    type="submit"
                    variant="contained"
                    className={classes.buttons}
                  >
                    {processing ? (
                      <CircularProgress
                        size="24px"
                        sx={{ color: "rgb(255 192 1)" }}
                      />
                    ) : seller.store ? (
                      "Update"
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  headerStyle: {
    fontStyle: "normal",
    color: "#000",
    fontWeight: "900 !important",
  },
  textField: {
    height: "40px",
    backgroundColor: "white",
    "&:hover": {
      borderColor: "rgb(255, 192, 1)",
    },
  },
  postalCodeTextField: {
    height: "40px",
    backgroundColor: "white",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:hover": {
      borderColor: "rgb(255, 192, 1)",
    },
  },
  buttons: {
    height: "40px",
    width: "150px",
    marginRight: "20px",
    fontSize: "15px !important",
    fontWeight: "900 !important",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "rgb(255, 192, 1)",
      color: "white !important",
    },
  },
});

const mapStateToProps = ({ Auth, Seller, Store }) => ({
  jwtToken: Auth.jwtToken,
  seller: Seller.seller,
  store: Store.store,
});

const mapDispatchToProps = (dispatch) => ({
  setStore: (data) => dispatch(Actions.setStore(data)),
  getStore: (storeId) => dispatch(Actions.thunkGetStore(storeId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrEditSellerProfile);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const paymentMethods = [
  "Credit Card",
  "Debit Card",
  "PayPal Goods & Services",
  "PayPal Friends & Family",
  "Venmo",
  "Zelle",
  "Apple Pay",
  "Google Pay",
  "Cash App",
  "Chime",
  "Personal Check",
  "Cashier's Check",
  "Wire Transfer",
  "Cash",
  "Money Order",
  "Western Union",
  "Walmart Pay",
  "MoneyGram",
  "Bitcoin",
  "Dogecoin",
  "Ehtermeum",
  "Other Crypto",
  "Other",
];
