import {
  Box,
  Container,
  Grid,
  Typography,
  styled,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from "react";

const Services = ({ showButton }) => {
  const [showAnimation, setShowAnimation] = useState(true);
  const handleShowAnimations = () => {
    if (showAnimation) {
      setShowAnimation(false);
    }
  };
  return (
    <ServiceMain>
      <Container fixed maxWidth={false}>
        <ColumnsBox container className="ColumnsBox">
          <Grid item className="gridStyleCenter" xs={12} sm={12}>
            <motion.Grid
              initial={{ opacity: 0, x: showAnimation ? -100 : 0 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: showAnimation ? 1.4 : 0 }}
              onViewportLeave={handleShowAnimations}
            >
              <Box sx={{ color: "white" }}>
                <Typography
                  className="subtitleService"
                  variant="h5"
                  component="h5"
                >
                  Service
                </Typography>
                <Typography
                  className="maintitleService"
                  variant="h2"
                  component="h2"
                >
                  The Best <br />
                  <Link
                    to="/services"
                    style={{
                      color: "white",
                      textDecorationColor: "white",
                    }}
                  >
                    <Typography
                      component="span"
                      variant="h2"
                      className="maintitleService"
                    >
                      {" "}
                      Service
                    </Typography>
                  </Link>{" "}
                  Ever
                </Typography>
                <Typography className="descriptionService" component={"p"}>
                  We are committed to providing the best possible experience for
                  all of our users. Whether you are looking for your next
                  companion, or utilizing our tools to help someone bring home
                  their new family member, we want to make the journey as easy
                  as possible. With our wide range of resources and services,
                  our platform is created to make the seller and buyer
                  relationship a symbiotic one.
                </Typography>
                {showButton && (
                  <ReadMoreButton
                    component={Link}
                    to={"/services"}
                    className="ReadMoreButton"
                  >
                    Read More
                  </ReadMoreButton>
                )}
              </Box>
            </motion.Grid>
          </Grid>
          <Grid item className="gridStyleCenter" xs={12} sm={12}>
            <motion.Grid
              initial={{ opacity: 0, x: showAnimation ? 100 : 0 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: showAnimation ? 1.4 : 0 }}
              onViewportLeave={handleShowAnimations}
            >
              <Box sx={{ textAlign: "right" }}>
                <img
                  className="ServiceAnimals"
                  src="https://res.cloudinary.com/dwezij7u7/image/upload/v1674834891/Moore%20Exotics%20Assets/services/pets_qktwoj.png"
                  alt="PetsImg"
                  style={{ width: "100%" }}
                />
              </Box>
            </motion.Grid>
          </Grid>
        </ColumnsBox>
      </Container>
    </ServiceMain>
  );
};

const ServiceMain = styled(Box)`
  background-color: #000000;
  padding: 120px 0;

  @media screen and (max-width: 1101px) {
    .ColumnsBox {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  & .gridStyleCenter {
    display: flex;
    align-items: center;
  }
  & .subtitleService {
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 8px;
  }
  & .maintitleService {
    font-weight: 700;
    font-size: 50px;
    margin-top: 8px;
    line-height: 66px;
  }
  & .descriptionService {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    margin-top: 15px;
  }
  @media screen and (max-width: 769px) {
    padding-top: 80px;
    padding-bottom: 150px;
    .descriptionService {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
    }
    .maintitleService {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 33px;
    }
    .subtitleService {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.02em;
    }
  }
`;

const ReadMoreButton = styled(Button)`
  margin-top: 44px;
  background-color: #ffc001;
  font-style: normal;
  color: #000000;
  font-weight: 600;
  font-size: 18px !important;
  line-height: 24px;
  text-transform: capitalize;
  min-width: 150px !important;
  height: 48px;
  text-align: center;
  &:hover {
    background-color: #ffc001;
  }
  &:focus {
    background-color: #ffc001;
  }
  @media screen and (max-width: 601px) {
    position: absolute;
    left: 0;
    right: 0;
    width: 60%;
    margin: 20px auto;
  }
`;
const ColumnsBox = styled(Grid)`
  @media screen and (min-width: 1100px) {
    .gridStyleCenter {
      width: 45%;
      margin: 10px auto;
      max-width: 45%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media screen and (max-width: 1550px) {
    .maintitleService {
      font-size: 44px;
      line-height: 56px;
    }
  }
  @media screen and (max-width: 1250px) {
    .maintitleService {
      font-size: 34px;
      line-height: 40px;
    }
  }
  @media screen and (max-width: 1100px) {
    .ServiceAnimals {
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 601px) {
    .maintitleService {
      text-align: center;
    }
    .subtitleService {
      text-align: center;
    }
    .descriptionService {
      text-align: center;
    }
  }
`;

export default Services;
