import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Box,
  InputAdornment,
  IconButton,
  CircularProgress,
  Alert,
  Chip,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import Snackbar from "@mui/material/Snackbar";
import AppleLogin from "react-apple-login";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useGoogleLogin } from "@react-oauth/google";
import { connect } from "react-redux";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import config from "../../config";
import * as Actions from "../../redux/actions";

const Signup = ({
  registerUser,
  responseErrors,
  responseFacebook,
  responseGoogle,
  user,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
    responseMessage: responseErrors && responseErrors,
  });
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const hideAppleLogin = true;
  const hideFacebookLogin = true;

  useEffect(() => {
    if (responseErrors) {
      setError({ ...error, responseMessage: responseErrors });
    }
    setLoading(false);
  }, [responseErrors]);

  useEffect(() => {
    if (responseErrors != "" && responseErrors) {
      setSnackbarStates({
        open: true,
        message: responseErrors,
        severity: "danger",
      });
    }
  }, [responseErrors]);
  const onInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "password" || name === "confirmPassword") validateInput(e);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };
  const handleFacebookSignup = (response) => {
    responseFacebook(response);
  };

  const googleSignup = useGoogleLogin({
    onSuccess: (response) => {
      responseGoogle(response);
    },
  });

  const validateInput = (e) => {
    e.preventDefault();

    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "password":
          if (value.length === 0) {
            stateObj[name] = "";
          } else if (value.length < 6) {
            stateObj[name] = "Password should be strong.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and confirm password does not match.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (value.length === 0) {
            stateObj[name] = "";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and confirm password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((show) => !show);
  };

  const handleClickShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword((show) => !show);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const fields = ["name", "email", "password"];
    const formElements = e.target.elements;

    const formValues = fields
      .map((field) => ({
        [field]: formElements.namedItem(field).value,
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    const res = await registerUser({
      name: formValues.name,
      email: formValues.email,
      password: formValues.password,
    });

    if (res && res.status === 200) {
      return navigate("/auth/signup/verification");
    }
  };

  if (user && user.id) {
    navigate("/");
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress sx={{ color: "rgb(255 192 1)" }} />
      </Backdrop>
      <Snackbar
        open={snackbarStates.open}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>

      <Stack style={{ backgroundColor: "#ebecf0" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alginItem="center"
        >
          <Grid item display="flex">
            <Card
              xl={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                padding: "40px",
                margin: "auto",
                width: { xs: "80%", sm: "45%", md: "30%", lg: "25%" },
              }}
            >
              <CardContent>
                <Typography
                  variant="h4"
                  align="center"
                  className={classes.headerStyle}
                >
                  Create account
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Typography component={"p"}>Name</Typography>
                  <TextField
                    placeholder="Enter your name"
                    fullWidth
                    size="small"
                    name="name"
                    InputProps={{
                      classes: {
                        root: classes.textField,
                      },
                    }}
                    required
                  />
                  <Typography component={"p"} sx={{ mt: "15px" }}>
                    Email
                  </Typography>
                  <TextField
                    helperText={
                      error.responseMessage && (
                        <span style={{ color: "red" }}>
                          {error.responseMessage}
                        </span>
                      )
                    }
                    placeholder="Enter your Email"
                    fullWidth
                    size="small"
                    type="email"
                    name="email"
                    InputProps={{
                      classes: {
                        root: classes.textField,
                      },
                    }}
                    required
                  />
                  <Typography component={"p"} sx={{ mt: "15px" }}>
                    Password
                  </Typography>
                  <TextField
                    helperText={
                      error.password && (
                        <span style={{ color: "red" }}>{error.password}</span>
                      )
                    }
                    size="small"
                    placeholder="Enter your password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    fullWidth
                    value={input.password}
                    onChange={onInputChange}
                    InputProps={{
                      classes: {
                        root: classes.textField,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    required
                  />

                  <Typography component={"p"} sx={{ mt: "15px" }}>
                    Confirm Password
                  </Typography>
                  <TextField
                    helperText={
                      error.confirmPassword && (
                        <span style={{ color: "red" }}>
                          {error.confirmPassword}
                        </span>
                      )
                    }
                    size="small"
                    placeholder="Confirm your password"
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    fullWidth
                    value={input.confirmPassword}
                    onChange={onInputChange}
                    onBlur={validateInput}
                    InputProps={{
                      classes: {
                        root: classes.textField,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    required
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.loginButton}
                    fullWidth
                  >
                    Sign Up
                  </Button>
                </form>
                <hr style={{ border: "0.02px solid #C5C5C5" }}></hr>
                <Button
                  startIcon={
                    <Box sx={{ display: "flex" }}>
                      <img
                        style={{ width: "25px" }}
                        src="https://res.cloudinary.com/dwezij7u7/image/upload/v1674836348/Moore%20Exotics%20Assets/login/google-icon_tg10fg.png"
                        alt="google-logo"
                      />
                    </Box>
                  }
                  variant="outlined"
                  onClick={googleSignup}
                  className={classes.ssoLoginButton}
                  fullWidth
                >
                  Sign up with Google
                </Button>
                {!hideFacebookLogin && (
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    fields="name,email,picture"
                    autoLoad={false}
                    scope="public_profile, email, user_birthday"
                    returnScopes={true}
                    callback={handleFacebookSignup}
                    render={(renderProps) => (
                      <Button
                        startIcon={
                          <Box sx={{ display: "flex" }}>
                            <img
                              style={{ width: "30px" }}
                              src={
                                window.location.origin +
                                "/images/facebook-logo.png"
                              }
                              alt="facebook"
                            />
                          </Box>
                        }
                        variant="outlined"
                        className={classes.ssoLoginButton}
                        onClick={renderProps.onClick}
                        fullWidth
                      >
                        Sign up with Facebook
                      </Button>
                    )}
                  />
                )}
                {!hideAppleLogin && (
                  <AppleLogin
                    clientId="YOUR_CLIENT_ID"
                    redirectURI="/"
                    usePopup={true}
                    scope="email name"
                    responseMode="query"
                    render={(renderProps) => (
                      <Button
                        onClick={renderProps.onClick}
                        startIcon={
                          <Box sx={{ display: "flex" }}>
                            <img
                              style={{ width: "25px" }}
                              src="https://res.cloudinary.com/dwezij7u7/image/upload/v1674836396/Moore%20Exotics%20Assets/login/apple-icon_ccai9h.png"
                              alt="apple"
                            />
                          </Box>
                        }
                        variant="outlined"
                        className={classes.ssoLoginButton}
                        fullWidth
                      >
                        Sign up with Apple
                        <Chip
                          label={
                            <Typography
                              sx={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Coming Soon
                            </Typography>
                          }
                          size="small"
                          sx={{
                            mt: "5px",
                            backgroundColor: "#FFC001",
                            color: "#fff",
                            borderRadius: "6px",
                            fontWeight: "bold",
                            fontSize: "12px",
                            wordBreak: "break-word",
                            p: "5px",
                            ml: "5px",
                          }}
                        />
                      </Button>
                    )}
                  />
                )}
                <Typography>
                  {" "}
                  Already have an account?{" "}
                  <Link
                    to="/auth/login"
                    className={classes.links}
                    style={{ color: "rgb(255, 192, 1)" }}
                  >
                    Log In
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

const mapStateToProps = ({ Auth }) => ({
  responseErrors: Auth.responseError,
  user: Auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  registerUser: (name, email, password) =>
    dispatch(Actions.thunkSignUp(name, email, password)),
  responseGoogle: (response) => dispatch(Actions.thunkResponseGoogle(response)),
  responseFacebook: (response) =>
    dispatch(Actions.thunkResponseFacebook(response)),
});

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  headerStyle: {
    fontStyle: "normal",
    color: "#000",
    fontWeight: "900 !important",
    marginBottom: "15px !important",
  },
  textField: {
    height: "40px",
    "&:hover": {
      borderColor: "rgb(255, 192, 1)",
    },
  },
  loginButton: {
    marginTop: "25px !important",
    marginBottom: "8px !important",
    height: "40px",
    color: "#000",
    fontSize: "15px !important",
    fontWeight: "900 !important",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "rgb(255, 192, 1)",
      color: "white !important",
    },
  },
  ssoLoginButton: {
    marginTop: "4px !important",
    marginBottom: "4px !important",
    minHeight: "40px",
    color: "#000 !important",
    fontSize: "15px",
    fontWeight: 900,
    borderColor: "#C5C5C5 !important",
    textTransform: "none !important",
    "&:hover": {
      color: "rgb(255, 192, 1) !important",
      borderColor: "rgb(255, 192, 1) !important",
    },
  },
  links: {
    fontSize: "16px",
    color: "black",
    textTransform: "none",
    textDecoration: "none",
    "&:hover": {
      color: "rgb(255, 192, 1)",
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
