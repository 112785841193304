import {
  Box,
  Avatar,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./message.css";

const Message = ({ childMessage, receiverOnlineStatus, otherUser }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const dateObj = new Date(childMessage.dateCreated);
  const formattedDate = format(dateObj, "h:mm a");
  const year = dateObj.getFullYear();
  const updatedHtmlContent = childMessage.body.replace(
    /<p/g,
    '<p class="no-margin"'
  );

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
      }}
    >
      <Box
        flex={{ md: 0.027, xs: 0.1, sm: 0.027 }}
        position="relative"
        mt={1.5}
      >
        <Avatar alt="Remy Sharp" src={childMessage.attributes.avatar_url} />
        <Box
          position="absolute"
          width={10}
          height={10}
          borderRadius="50%"
          bgcolor={
            childMessage.attributes.id !== otherUser.id
              ? "green"
              : receiverOnlineStatus
                ? "green"
                : "grey"
          }
          top={27}
          right={-2}
          border="2px solid white"
        />
      </Box>
      <Box display={"inline-flex"} sx={{ flexDirection: "row" }} mt={1}>
        <Box sx={{ pl: 1 }}>
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{ ml: 1, fontSize: "18px", fontWeight: "bold", mb: 0 }}
              component={"span"}
            >
              {childMessage.attributes.name}{" "}
            </Typography>
            <Typography
              sx={{ ml: 1, fontSize: "14px", color: "grey", mb: 0 }}
              component={"span"}
            >
              {formattedDate}{" "}
            </Typography>
          </Box>
          <Box pl={1} sx={{ wordBreak: "break-all", m: 0 }}>
            <Typography
              textAlign={"start"}
              dangerouslySetInnerHTML={{ __html: updatedHtmlContent }}
              className="typography-element"
            />
          </Box>
          {childMessage.mediaUrls &&
            childMessage.mediaUrls.map((mediaUrl, index) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    m: 1,
                    height: "200px",
                    width: "200px",
                  }}
                >
                  {mediaUrl.content_type.startsWith("image/") && (
                    <img
                      src={mediaUrl.url}
                      alt="media"
                      style={{
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                        objectFit: "contain",
                      }}
                      onClick={() => handleImageClick(mediaUrl.url)}
                    />
                  )}

                  <Dialog
                    open={Boolean(selectedImage)}
                    onClose={handleClose}
                    maxWidth="lg"
                    sx={{
                      backgroundColor: "transparent",
                      backdropFilter: "blur(5px)",
                    }}
                  >
                    <DialogTitle
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        // edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent
                      sx={{
                        height: "500px",
                      }}
                    >
                      {selectedImage && (
                        <img
                          src={selectedImage}
                          alt="full-view"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button
                        component="a"
                        href={selectedImage}
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        Download
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {mediaUrl.content_type.startsWith("video/") && (
                    <video
                      src={mediaUrl.url}
                      style={{
                        width: "100%",
                        marginBottom: "5px",
                        border: "2px solid grey",
                        borderRadius: "10px",
                      }}
                      controls
                      key={index}
                    >
                      Your browser doesn't support HTML5 video tag.
                    </video>
                  )}

                  {mediaUrl.content_type &&
                    mediaUrl.content_type.startsWith(
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    ) && (
                      <div>
                        <embed
                          src={mediaUrl.url}
                          width="500px"
                          height="200px"
                          title="Document Viewer"
                        />

                        <a href={mediaUrl.url} target="_blank" rel="noreferrer">
                          Download Document
                        </a>
                      </div>
                    )}

                  {mediaUrl.content_type &&
                    mediaUrl.content_type.startsWith("application/pdf") && (
                      <div>
                        <embed
                          src={mediaUrl.url}
                          width="500px"
                          height="200px"
                          type="application/pdf"
                        />

                        <a href={mediaUrl.url} target="_blank" rel="noreferrer">
                          Download Document
                        </a>
                      </div>
                    )}
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default Message;
