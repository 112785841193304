import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Card,
  Box,
  Modal,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

import { imageListItemClasses } from "@mui/material/ImageListItem";
import { makeStyles, withStyles } from "@mui/styles";
import { AccessTimeFilled, ExpandMore } from "@mui/icons-material";
import moment from "moment";
import axios from "axios";
import config from "../../config";
const MuiAccordionSummary = withStyles({
  content: {
    flexGrow: 0,
  },
})(AccordionSummary);

const Media = () => {
  const mediaUrls = [
    'https://www.youtube.com/embed/61dtNjwm8Zc?si=EDgYbNNOK9kyXYYT',
    'https://www.youtube.com/embed/neMSYpJYP54?si=x5wRGz9Dp97KCtl0',
    'https://www.youtube.com/embed/aUbE4gbRH1c?si=6kd0JnS6zPf0gec3'
  ]
  const classes = useStyles();
  const [playlistVideos, setPlaylistVideos] = useState([]);
  const [animalsMedia, setAnimalsMedia] = useState([]);
  const [recentUploadVideos, setRecentUploadVideos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalVid, setModalVid] = useState({});
  const [modalImage, setModalImage] = useState({});
  const [height, setHeight] = useState("0px");
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const handleOpenModal = (e, item) => {
    e.preventDefault();
    setModalVid(item);
    setOpenModal(true);
  };

  const handleOpenImageModal = (e, item) => {
    e.preventDefault();
    setModalImage(item);
    setOpenModal(true);
  };
  const handleCloseModal = (e) => {
    e.preventDefault();
    setModalImage({});
    setModalVid({});
    setOpenModal(false);
  };

  useEffect(() => {
    setHeight(window.innerHeight / 1.5 + "px");
    const getYoutubePlaylistVideos = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `https://www.googleapis.com/youtube/v3/playlistItems?playlistId=${process.env.REACT_APP_YT_PLAYLIST_ID}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}&part=id,snippet,contentDetails,status`
        );

        const { data: registerRequestData } = registerRequest;
        if (registerRequest.status === 200) {
          setPlaylistVideos(registerRequestData.items);
        }
      } catch ({ response }) {
        registerRequest = response;
        setSnackbarStates({
          open: true,
          message: "Unable to load media right now. Please try again later.",
          severity: "danger",
        });
      }
    };

    const getAnimalsMedia = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/v1/animals_media`
        );

        const { data: registerRequestData } = registerRequest;
        if (registerRequest.status === 200) {
          setAnimalsMedia(registerRequestData.data);
        }
      } catch ({ response }) {
        registerRequest = response;
        setSnackbarStates({
          open: true,
          message: "Unable to load media right now. Please try again later.",
          severity: "danger",
        });
      }
    };

    if (!playlistVideos.length) {
      console.log("playlistVideos", playlistVideos);
      getYoutubePlaylistVideos();
    }
    if (!animalsMedia.length) {
      console.log("animalsMedia", animalsMedia);
      getAnimalsMedia();
    }
  }, []);

  useEffect(() => {
    if (playlistVideos.length > 0) {
      setRecentUploadVideos(
        playlistVideos.sort((a, b) => {
          return (
            new Date(b.contentDetails.videoPublishedAt) -
            new Date(a.contentDetails.videoPublishedAt)
          );
        })
      );
    }
  }, [playlistVideos]);
  const handleCloseMediaAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };
  return (
    <>
      <Snackbar
        open={snackbarStates.open}
        autoHideDuration={3000}
        onClose={handleCloseMediaAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseMediaAlert}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <Stack
        className={classes.mediaContainer}
        sx={{
          padding: {
            xs: "24px 14px 24px 14px",
            sm: "44px 14px 44px 14px",
            md: "44px 44px 44px 44px",
            lg: "44px 104px 44px 104px",
          },
        }}
      >
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            left: { xs: "5%", sm: "5%", md: "10%", lg: "10%" },
            top: { xs: "10%", sm: "10%", md: "10%", lg: "10%" },
            width: { xs: "90%", sm: "90%", md: "80%", lg: "80%" },
          }}
        >
          <Card
            display="flex"
            sx={{
              margin: "auto",
            }}
          >
            <CardContent>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                {modalImage && modalImage.image && (
                  <img
                    style={{
                      width: "50%",
                      transition: "0.5s ease",
                      backfaceVisibility: "hidden",
                    }}
                    src={`${modalImage.image}`}
                    srcSet={`${modalImage.image}`}
                    alt={modalImage.title}
                    loading="lazy"
                  />
                )}
                {modalVid && modalVid.snippet && (
                  <iframe
                    style={{
                      overflow: "auto",
                    }}
                    width="100%"
                    height={height}
                    scrolling="no"
                    src={`https://www.youtube.com/embed/${modalVid.snippet.resourceId.videoId}`}
                    title={modalVid.snippet.title}
                    frameborder="0"
                    allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                )}
              </Box>
            </CardContent>
          </Card>
        </Modal>
        <Typography
          variant="h3"
          component={"h3"}
          sx={{
            fontWeight: 900,
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          Media
        </Typography>
        <Box
          sx={{
            backgroundColor: "transparent",
            mt: 2,
            display: "grid",
            gap: 2,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
              xl: "repeat(5, 1fr)",
            },
            [`& .${imageListItemClasses.root}`]: {
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          {mediaUrls &&
            mediaUrls.length > 0 &&
            mediaUrls.map((item) => (
              <Card
                elevation={2}
                display="flex"
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#eeeeee",
                  },
                }}
              >
                <Box display="flex" flexDirection="column" sx={{ p: 2 }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <iframe
                      width="100%"
                      transition="0.5s ease"
                      backfaceVisibility="hidden"
                      src={item}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </Box>

                  {/* <Box display="flex" flexDirection="column" sx={{ mt: 1 }}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="left"
                    >
                      <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                        {item.snippet.title}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Typography sx={{ fontSize: "14px", color: "grey" }}>
                        {item.snippet.channelTitle}
                      </Typography>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        <AccessTimeFilled
                          sx={{ fontSize: "18px", color: "grey", mr: 0.3 }}
                        />
                        <Typography sx={{ fontSize: "14px", color: "grey" }}>
                          {moment(
                            item.contentDetails.videoPublishedAt
                          ).fromNow()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box> */}
                </Box>
              </Card>
            ))}
        </Box>
        {playlistVideos && playlistVideos.length > 8 && (
          <Accordion
            elevation={0}
            disableGutters
            sx={{
              styleOverrides: {
                root: {
                  "&:before": {
                    backgroundColor: "transparent",
                  },
                },
              },
              backgroundColor: "transparent",
              mt: "24px",
            }}
          >
            <MuiAccordionSummary
              sx={{
                content: {
                  flexGrow: 0,
                },
                backgroundColor: "rgb(224,224,224)",
              }}
              expandIcon={
                <ExpandMore
                  size="large"
                  sx={{
                    ":hover": {
                      color: "rgb(255, 192, 1)",
                    },
                  }}
                />
              }
              aria-label="Expand"
              aria-controls="additional-actions1-content"
            ></MuiAccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  backgroundColor: "transparent",
                  mt: 2,
                  display: "grid",
                  gap: 2,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(5, 1fr)",
                  },
                  [`& .${imageListItemClasses.root}`]: {
                    display: "flex",
                    flexDirection: "column",
                  },
                }}
              >
                {playlistVideos &&
                  playlistVideos.length > 0 &&
                  playlistVideos.slice(8).map((item) => {
                    return (
                      <Card
                        elevation={2}
                        display="flex"
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#eeeeee",
                          },
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          sx={{ p: 2 }}
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: "100%" }}
                          >
                            <iframe
                              width="100%"
                              transition="0.5s ease"
                              backfaceVisibility="hidden"
                              src={`https://www.youtube.com/embed/${item.snippet.resourceId.videoId}`}
                              title={item.snippet.title}
                              frameborder="0"
                              allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowfullscreen
                            ></iframe>
                          </Box>

                          <Box
                            display="flex"
                            flexDirection="column"
                            sx={{ mt: 1 }}
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="left"
                            >
                              <Typography
                                sx={{ fontSize: "16px", fontWeight: 700 }}
                              >
                                {item.snippet.title}
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                            >
                              <Typography
                                sx={{ fontSize: "14px", color: "grey" }}
                              >
                                {item.snippet.channelTitle}
                              </Typography>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-around"
                                alignItems="center"
                              >
                                <AccessTimeFilled
                                  sx={{
                                    fontSize: "18px",
                                    color: "grey",
                                    mr: 0.3,
                                  }}
                                />
                                <Typography
                                  sx={{ fontSize: "14px", color: "grey" }}
                                >
                                  {moment(
                                    item.contentDetails.videoPublishedAt
                                  ).fromNow()}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    );
                  })}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        <hr
          style={{
            border: "0.5px solid grey",
            marginTop: "44px",
            width: "100%",
          }}
        ></hr>
        <Typography
          variant="h3"
          component={"h3"}
          sx={{
            mt: "22px",
            fontWeight: 900,
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          Animals Media
        </Typography>
        <Box
          sx={{
            backgroundColor: "transparent",
            mt: 2,
            display: "grid",
            gap: 2,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
              xl: "repeat(5, 1fr)",
            },
            [`& .${imageListItemClasses.root}`]: {
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          {animalsMedia &&
            animalsMedia.length > 0 &&
            animalsMedia.slice(0, 4).map((item) => (
              <Card
                onClick={(e) => handleOpenImageModal(e, item)}
                elevation={2}
                display="flex"
                sx={{
                  display: "flex",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#eeeeee",
                  },
                }}
              >
                <Box display="flex" flexDirection="column" sx={{ p: 2 }}>
                  <img
                    style={{
                      width: "100%",
                      transition: "0.5s ease",
                      backfaceVisibility: "hidden",
                    }}
                    src={`${item.image}`}
                    srcSet={`${item.image}`}
                    alt={item.title}
                    loading="lazy"
                  />
                  <Box display="flex" flexDirection="column" sx={{ mt: 1 }}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="left"
                    >
                      <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                        {item.title}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
            ))}
        </Box>
        {animalsMedia && animalsMedia.length > 4 && (
          <Accordion
            elevation={0}
            disableGutters
            sx={{
              styleOverrides: {
                root: {
                  "&:before": {
                    backgroundColor: "transparent",
                  },
                },
              },
              backgroundColor: "transparent",
              mt: "24px",
            }}
          >
            <MuiAccordionSummary
              sx={{
                content: {
                  flexGrow: 0,
                },
                backgroundColor: "rgb(224,224,224)",
              }}
              expandIcon={
                <ExpandMore
                  size="large"
                  sx={{
                    ":hover": {
                      color: "rgb(255, 192, 1)",
                    },
                  }}
                />
              }
              aria-label="Expand"
              aria-controls="additional-actions1-content"
            ></MuiAccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  backgroundColor: "transparent",
                  mt: 2,
                  display: "grid",
                  gap: 2,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(5, 1fr)",
                  },
                  [`& .${imageListItemClasses.root}`]: {
                    display: "flex",
                    flexDirection: "column",
                  },
                }}
              >
                {animalsMedia &&
                  animalsMedia.length > 0 &&
                  animalsMedia.slice(4).map((item) => {
                    return (
                      <Card
                        onClick={(e) => handleOpenImageModal(e, item)}
                        elevation={2}
                        display="flex"
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#eeeeee",
                          },
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          sx={{ p: 2 }}
                        >
                          <img
                            style={{
                              width: "100%",
                              transition: "0.5s ease",
                              backfaceVisibility: "hidden",
                            }}
                            src={`${item.image}`}
                            srcSet={`${item.image}`}
                            alt={item.title}
                            loading="lazy"
                          />
                          <Box
                            display="flex"
                            flexDirection="column"
                            sx={{ mt: 1 }}
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="left"
                            >
                              <Typography
                                sx={{ fontSize: "16px", fontWeight: 700 }}
                              >
                                {item.title}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    );
                  })}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        <hr
          style={{
            border: "0.5px solid grey",
            marginTop: "44px",
            width: "100%",
          }}
        ></hr>
        <Typography
          variant="h3"
          component={"h3"}
          sx={{
            mt: "22px",
            fontWeight: 900,
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          Recent Uploads
        </Typography>
        <Box
          sx={{
            backgroundColor: "transparent",
            mt: 2,
            display: "grid",
            gap: 2,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
              xl: "repeat(5, 1fr)",
            },
            [`& .${imageListItemClasses.root}`]: {
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          {mediaUrls.length > 0 &&
            mediaUrls.slice(0, 2).map((item) => (
              <Card
                elevation={2}
                display="flex"
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#eeeeee",
                  },
                }}
              >
                <Box display="flex" flexDirection="column" sx={{ p: 2 }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <iframe
                      width="100%"
                      transition="0.5s ease"
                      backfaceVisibility="hidden"
                      src={item}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </Box>

                  {/* <Box display="flex" flexDirection="column" sx={{ mt: 1 }}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="left"
                    >
                      <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                        {item.snippet.title}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Typography sx={{ fontSize: "14px", color: "grey" }}>
                        {item.snippet.channelTitle}
                      </Typography>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        <AccessTimeFilled
                          sx={{ fontSize: "18px", color: "grey", mr: 0.3 }}
                        />
                        <Typography sx={{ fontSize: "14px", color: "grey" }}>
                          {moment(
                            item.contentDetails.videoPublishedAt
                          ).fromNow()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box> */}
                </Box>
              </Card>
            ))}
        </Box>
      </Stack>
    </>
  );
};

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  mediaContainer: {
    backgroundColor: "#ebecf0",
  },
  modalStyle: {
    position: "absolute",
    backgroundColor: "white",
    top: "50%",
    left: "50%",
    border: "0px solid #000",
    boxShadow: 24,
  },
});

export default Media;
