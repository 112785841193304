import {
  Alert,
  Box,
  Button,
  Grid,
  Slide,
  Snackbar,
  Typography,
  Select,
  MenuItem,
  TablePagination,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ListingsList from "../../../components/seller/listings/ListingsList";
import CustomModal from "../../../components/seller/listings/Modal";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import config from "../../../config";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import ServiceList from "./ServiceList";

const useStyles = makeStyles((theme) => ({
  hover: {
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#eeeeee" },
  },
}));

const filters = [
  { id: "all", name: "All" },
  { id: "active", name: "Active" },
  { id: "unavailable", name: "Un-Available" },
];

const Services = ({ seller, jwtToken, user, store }) => {
  const classes = useStyles();

  const [filter, setFilter] = useState(filters[0].id);
  const [serviceListings, setServiceListings] = useState([]);
  const [confirmProp2, setConfirmProp2] = useState(false);
  const [confirmProp3, setConfirmProp3] = useState(false);
  const [confirmProp4, setConfirmProp4] = useState(false);
  const [action, setAction] = useState();
  const [openActionModal, setOpenActionModal] = useState(false);
  const [featuredList, setFeaturedList] = useState();
  const [openFeaturedModal, setOpenFeaturedModal] = useState();
  const [confirmProp, setConfirmProp] = useState(false);
  const [featureLimitMessage, setFeatureLimitMessage] = useState("");
  const [openFeatureLimiExceededModal, setOpenFeatureLimiExceededModal] =
    useState(false);
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (confirmProp4) {
      navigate("/seller/dashboard");
    }
  }, [confirmProp4]);
  const [paginationStates, setPaginationStates] = useState({
    page: 0,
    rowsPerPage: 10,
    total_records: 0,
  });

  const onPageChange = (event, newPage) => {
    setPaginationStates({ ...paginationStates, page: newPage });
  };

  const onRowsPerPageChange = (event) => {
    const updatedRowsPerPage = parseInt(event.target.value, 10);
    setPaginationStates({
      ...paginationStates,
      rowsPerPage: updatedRowsPerPage,
      page: 0,
    });
  };
  const handleCloseNoStoreModal = () => {
    navigate("/seller/profile");
  };
  const handleClose = () => {
    setOpen(false);
  };
  const fetchServiceListings = async () => {
    let registerRequest;
    try {
      registerRequest = await axios.get(
        `${config.SERVER_URL}/api/v1/listing_services?filter_status=${filter}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
          params: {
            seller_id: seller && seller.id,
          },
        }
      );
      if (registerRequest.status === 200) {
        setServiceListings(registerRequest.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchServiceListings();
  }, [filter, jwtToken, seller]);

  const handleUpdateStatus = async (data) => {
    setAction(data);
  };

  useEffect(() => {
    const updateStatus = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.patch(
          `${config.SERVER_URL}/api/v1/listing_services/${action.id}`,
          {
            listing_service: {
              status: action.status === "active" ? "unavailable" : "active",
            },
            seller_id: action.seller_id,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data; ",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;
        if (registerRequest.status === 400 || registerRequest.status === 422) {
          setOpen(true);
        }
      }
      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 201 || registerRequest.status === 200) {
        fetchServiceListings();
        setSnackbarStates({
          open: true,
          message: registerRequestData.message,
          severity: "success",
        });
      }
    };
    if (confirmProp2) {
      updateStatus();
    }

    return () => {
      setConfirmProp2(false);
    };
  }, [confirmProp2]);

  const handleCloseActionModal = () => setOpenActionModal(false);
  const handleCloseFeaturedModal = () => setOpenFeaturedModal(false);
  const handleCloseFeatureLimitModal = () =>
    setOpenFeatureLimiExceededModal(false);

  const handleOpenFeaturedModal = (data) => {
    if (data) {
      setFeaturedList(data);
      setOpenFeaturedModal(true);
    }
  };

  useEffect(() => {
    const updateFeatureListing = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.patch(
          `${config.SERVER_URL}/api/v1/listing_services/${featuredList.id}`,
          {
            is_featured: !featuredList.is_featured,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data; ",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;
        if (registerRequest.status === 422) {
          setOpenFeaturedModal(false);
          setConfirmProp3(false);
          setOpenFeatureLimiExceededModal(true);
          setFeatureLimitMessage(registerRequest.data.message);
        }
      }

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 201 || registerRequest.status === 200) {
        fetchServiceListings();

        setSnackbarStates({
          open: true,
          message: registerRequestData.message,
          severity: "success",
        });
        setConfirmProp3(false);
        setFeaturedList(null);
        setOpenFeaturedModal(false);
      }
    };

    if (confirmProp) {
      updateFeatureListing();
    }

    return () => {
      setConfirmProp(false);
    };
  }, [confirmProp]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };

  useEffect(() => {
    if (confirmProp3) {
      window.location.href = user.billingPortalUrl;
    }
  }, [confirmProp3]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Limit exceeded!!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Upgrade your plan to set more listings to active.{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button
            // onClick={handleUpgradePlan}
            color="primary"
            component={Link}
            to={`${user.billingPortalUrl}`}
          >
            Upgrade
          </Button>
        </DialogActions>
      </Dialog>
      <CustomModal
        title="Need to Complete Seller Profile First"
        content={"Please Complete your seller profile to use Seller Dashboard"}
        openModal={!Boolean(store)}
        closeModal={handleCloseNoStoreModal}
        actionTitle={"Go to store"}
        setConfirmProp={setConfirmProp4}
        actionColor={"green"}
        isHideCancelButton={true}
      />
      <Grid xs={12}>
        <Snackbar
          transitionDuration={{ enter: 1000, exit: 2000 }}
          TransitionComponent={Slide}
          TransitionProps={{ enter: true, exit: false }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbarStates.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant="filled"
            severity={snackbarStates.severity}
            sx={{ width: "100%" }}
          >
            {snackbarStates.message}
          </Alert>
        </Snackbar>
        <CustomModal
          title="Featured Alert"
          content={
            "Are you sure, you want to set this listing to featured, you will not be able to reverse this action and it will last for 24 hours."
          }
          openModal={openFeaturedModal}
          closeModal={handleCloseFeaturedModal}
          actionTitle={"Set Featured"}
          setConfirmProp={setConfirmProp}
          actionColor={"green"}
        />
        <CustomModal
          title="Featured Listing Limit Exceeded!"
          content={featureLimitMessage}
          openModal={openFeatureLimiExceededModal}
          closeModal={handleCloseFeatureLimitModal}
          actionTitle={"Update Plan"}
          actionColor={"primary"}
          setConfirmProp={setConfirmProp3}
        />
        <Box
          display="flex"
          justifyContent="right"
          alignItems="center"
          sx={{ textAlign: "right", mt: 2, mr: 2 }}
        >
          <Button
            component={Link}
            to={"/seller/service/create"}
            disableRipple
            sx={{
              color: "#fff",
            }}
            variant="contained"
          >
            Add New
          </Button>
        </Box>
        <Box
          px="5px"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "5px",
            border: "none",
            bgcolor: "white",
            m: 1,
          }}
        >
          <Typography sx={{ paddingTop: "5px", paddingLeft: "5px" }}>
            Services
          </Typography>
          <Select
            value={filter}
            size="small"
            disableUnderline
            onChange={(e) => setFilter(e.target.value)}
            className={classes.hover}
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
          >
            {filters.map((filter) => (
              <MenuItem
                key={filter.id}
                value={filter.id}
                sx={{ fontSize: "14px" }}
              >
                {filter.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{ height: "100vh" }}>
          <ServiceList
            serviceListings={serviceListings}
            setServiceListings={setServiceListings}
            handleUpdateStatus={handleUpdateStatus}
            setConfirmProp2={setConfirmProp2}
            setOpenActionModal={setOpenActionModal}
            openActionModal={openActionModal}
            handleCloseActionModal={handleCloseActionModal}
            handleOpenFeaturedModal={handleOpenFeaturedModal}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="bottom"
        >
          <TablePagination
            component="div"
            count={paginationStates.total_records}
            page={paginationStates.page}
            onPageChange={onPageChange}
            rowsPerPage={paginationStates.rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Box>
      </Grid>
    </>
  );
};
const mapStateToProps = ({ Auth, Seller, Store }) => ({
  jwtToken: Auth.jwtToken,
  user: Auth.user,
  seller: Seller.seller,
  store: Store.store,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Services);
