import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import { makeStyles } from "@mui/styles";
import { imageListItemClasses } from "@mui/material/ImageListItem";

const ListMedia = ({ listings, classes, page }) => {
  const styleclass = useStyles();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    return `${year}`;
  };

  const getCategoryName = (id) => {
    let category;
    if (classes.length > 0) {
      category = classes.filter((cat) => cat.id === id);
      return category[0].name;
    }
  };

  return (
    <>
      <Grid
        sx={{
          width: "100%",
          backgroundColor: "transparent",
          mt: 2,
          display: "grid",
          gap: 2,
          gridTemplateColumns: {
            xs: "repeat(2, minmax(0, 1fr))",
            sm: "repeat(3, minmax(0, 1fr))",
            md: "repeat(3, minmax(0, 1fr))",
            lg:
              page == "Home"
                ? "repeat(3, minmax(0, 1fr))"
                : "repeat(5, minmax(0, 1fr))",
          },
          [`& .${imageListItemClasses.root}`]: {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        {listings.map((list) => (
          <Box
            sx={{
              margin: { md: "2px" },
            }}
          >
            <Link
              to={"/listing/" + list.id}
              style={{
                textDecoration: "none",
              }}
            >
              <Card
                sx={{
                  border: "0.2px solid #eeeeee",
                  borderRadius: "10px",
                  cursor: "pointer",
                  transition: "0.3s all",
                  "&:hover": {
                    color: "rgb(255, 192, 1)",
                    backgroundColor: "#eeeeee",
                  },
                }}
              >
                <div className={styleclass.imageContainer}>
                  <CardMedia
                    sx={{ height: 180, backgroundSize: "contain" }}
                    image={list.media[0]?.url}
                    title={list.media[0]?.url}
                  />
                  {list.on_hold && (
                    <div className={list.on_hold && styleclass.onHoldText}>
                      on Hold
                    </div>
                  )}
                </div>
                <CardContent sx={{ height: 80 }}>
                  <Grid xs={12}>
                    <Typography variant="h6" noWrap fontWeight="bold">
                      {list.name}
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Typography variant="p" noWrap sx={{ color: "grey" }}>
                      {getCategoryName(list.category_id)}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs="auto">
                      <Typography
                        sx={{
                          fontSize: "18px",
                          color: "grey",
                          fontFamily: "SharpSans",
                        }}
                      >
                        {formatDate(list.created_at)}
                      </Typography>
                    </Grid>
                    <Grid item xs fontSize="medium">
                      {list.sex === "male" ? (
                        <MaleIcon sx={{ color: "blue" }} />
                      ) : list.sex === "female" ? (
                        <FemaleIcon sx={{ color: "#AA336A" }} />
                      ) : (
                        <>
                          <MaleIcon sx={{ color: "blue" }} />
                          <FemaleIcon sx={{ color: "#AA336A" }} />
                        </>
                      )}
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          fontSize: { xs: "22px", md: "24px", lg: "27px" },
                          fontFamily: "SharpSans",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        ${list.price}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Link>
          </Box>
        ))}
      </Grid>
    </>
  );
};
const useStyles = makeStyles({
  imageContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "500px",
  },
  onHoldText: {
    position: "absolute",
    top: 30,
    right: -25,
    width: "100px",
    textAlign: "center",
    backgroundColor: "#FFD300",
    color: "#fff",
    padding: "5px",
    fontSize: "14px",
    transform: "translate(-0%, 100%) rotate(45deg)",
    transformOrigin: "top right",
  },
});
export default ListMedia;
