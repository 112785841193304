import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const CustomModal = ({
  title,
  openModal,
  closeModal,
  content,
  actionTitle,
  actionTitle2,
  actionColor,
  actionColor2,
  setConfirmProp,
  setConfirmProp2,
  isHideCancelButton,
}) => {
  const handleConfirmProp = () => {
    setConfirmProp(true);
    closeModal();
  };

  const handleConfirmProp2 = () => {
    setConfirmProp2(true);
    closeModal();
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: { xs: "80vw", md: "30vw" },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: "red" }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              {content?.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
            {/* {content} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!isHideCancelButton && (
            <Button sx={{ color: "black" }} onClick={closeModal}>
              Cancel
            </Button>
          )}
          <Button
            sx={{ color: actionColor }}
            autoFocus
            onClick={handleConfirmProp}
          >
            {actionTitle}
          </Button>
          {actionTitle2 && (
            <Button
              sx={{ color: actionColor2 }}
              autoFocus
              onClick={handleConfirmProp2}
            >
              {actionTitle2}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomModal;
