import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useLayoutEffect,
  useRef,
} from "react";
import AuthVerify from "./config/AuthVerify";
import axios from "axios";
import config from "./config";
import * as Actions from "./redux/actions/";
import { Spinner } from "./components/spinner/Spinner";
import { routesConfig } from "./config/RoutesConfig";
import { useRoutes } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import "./App.css";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const App = ({
  user,
  getSeller,
  loadCategories,
  loadStore,
  store,
  jwtToken,
  thunkInitChatClient,
  thunkLoadGetConversations,
}) => {
  const [loading, setLoading] = useState(true);
  const [storeId, setStoreId] = useState();
  const [locationId, setLocationId] = useState();
  const [client, setClient] = useState();
  const routes = useRoutes(routesConfig);
  const location = useLocation();
  const dispatch = useDispatch();
  const [receiverOnlineStatus, setReceiverOnlineStatus] = useState(false);
  const [senderOnlineStatus, setSenderOnlineStatus] = useState(false);
  const roomEmittersRef = useRef({});
  const getToken = useCallback(async () => {
    try {
      const registerRequest = await axios.post(
        `${config.SERVER_URL}/tokens`,
        {
          user_id: user.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      const { data: registerRequestData, status } = registerRequest;
      if (status === 200) {
        return registerRequestData;
      }
    } catch (error) {
      console.log("Error while making the request:", error.message);
    }
  }, [user?.id, jwtToken]);

  async function setTokens() {
    if (user && !client) {
      const response = await getToken();
      if (response) {
        const { token } = response;
        thunkInitChatClient({ token, setClient });
      }
    }
    return () => {
      if (client) {
        return client.shutdown();
      }
    };
  }
  useEffect(() => {
    setTokens();
  }, [client, dispatch, location]);

  useEffect(() => {
    loadCategories();
    if (user?.is_subscribed) {
      getSeller();
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    function fetchStore() {
      if (location.pathname.includes("/neighbour/")) {
        const pathParams = location.pathname
          .split("/")
          .filter((param) => param !== "");
        const param2 = pathParams[1];
        setStoreId(param2);
        setLocationId(param2);
      } else {
        setStoreId(null);
      }
    }
    fetchStore();
  }, [location]);

  useEffect(() => {
    loadStore({ storeId });
  }, [storeId]);

  useEffect(() => {
    const refreshToken = async () => {
      const { token } = await getToken();
      const chatClient = await client.updateToken(token);
      setClient(chatClient);
    };
    if (client) {
      client.on("tokenAboutToExpire", async () => {
        await refreshToken();
      });
      client.on("tokenExpired", async () => {
        await refreshToken();
      });
      thunkLoadGetConversations({
        client,
        setLoading,
        setSenderOnlineStatus,
        setReceiverOnlineStatus,
        jwtToken,
        user,
        roomEmittersRef,
      });
    }
  }, [client, dispatch, getToken, user, location]);

  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Moore Exotics" />
        <meta
          property="og:image"
          content="https://prodappdata.s3.amazonaws.com/preview-images/home-preview.png"
        />
        <meta property="og:description" content="Moore Exotics frontend" />
        <meta property="og:url" content="https://mooreexotics.com" />
      </Helmet>
      <Box
        sx={{
          backgroundColor:
            storeId && store && store.profile_color && locationId == store.id
              ? store.profile_color
              : "white",
        }}
      >
        {loading ? <Spinner /> : routes}
        <AuthVerify />,
      </Box>
    </>
  );
};
const mapStateToProps = ({ Auth, Store, Messages }) => ({
  user: Auth.user,
  jwtToken: Auth?.jwtToken,
  store: Store.store,
  conversations: Messages.conversations,
});

const mapDispatchToProps = (dispatch) => ({
  getSeller: () => dispatch(Actions.thunkGetSeller()),
  loadCategories: () => dispatch(Actions.thunkLoadCategories()),
  loadStore: (storeId) => dispatch(Actions.thunkGetStore(storeId)),
  setUnreadCount: ({ unreadCount }) =>
    dispatch(Actions.setUnreadCount({ unreadCount })),
  thunkInitChatClient: ({ token, setClient }) =>
    dispatch(Actions.thunkInitChatClient({ token, setClient })),

  thunkLoadGetConversations: ({
    client,
    conversation,
    setLoading,
    jwtToken,
    setSenderOnlineStatus,
    setReceiverOnlineStatus,
    user,
    roomEmittersRef,
    getAllConversationPages,
  }) =>
    dispatch(
      Actions.thunkLoadGetConversations({
        client,
        setLoading,
        setSenderOnlineStatus,
        setReceiverOnlineStatus,
        jwtToken,
        user,
        roomEmittersRef,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
