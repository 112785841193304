import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Slide,
  Alert,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedSteppers from "./Stepper";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config";
import { Box, Button, Grid, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Rating from "@mui/material/Rating";

import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const ReviewDialog = ({
  open,
  jwtToken,
  listData,
  setOpen,
  setSnackbarStates,
  page,
}) => {
  const [reviewMessage, setReviewMessage] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [yesChecked, setYesChecked] = useState(false);
  const [noChecked, setNoChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const [rating, setRating] = useState(5);
  const isSmallToMediumScreen = useMediaQuery("(max-width: 960px)");
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleClose = () => {
    setReviewMessage("");
    setCheckboxChecked(false);
    setOpen(false);
    setOpenDialog(false);
    setYesChecked(false);
    setNoChecked(false);
  };

  const handleInputChange = (e) => {
    setShowError(false);
    setReviewMessage(e.target.value);
  };

  const handleSetRating = (event, newValue) => {
    setShowError(false);

    setRating(newValue);
  };

  const handleSubmitReview = async () => {
    handleNext();

    if (reviewMessage === "") {
      alert("Please enter review message");
      return;
    }

    if (rating === 0) {
      alert("Please select rating");
      return;
    }

    let registerRequest;

    try {
      const data = {
        rating: rating,
        content: reviewMessage,
        [page === "Services" ? "listing_service_id" : "listing_id"]:
          page === "Services" ? listData.id : listData.id,
      };
      registerRequest = await axios.post(
        `${config.SERVER_URL}/api/v1/reviews`,
        {
          review: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
    } catch ({ response }) {
      if (response.status === 422) {
        setSnackbarStates({
          open: true,
          message: response.data.errors,
          severity: "danger",
        });
      }
    }

    if (registerRequest && registerRequest.status === 201) {
      setSnackbarStates({
        open: true,
        message: "Review has been sent successfully!!",
        severity: "success",
      });
    }

    setOpenDialog(false);
    setOpen(false);
    setRating(5);
    setReviewMessage("");
    setYesChecked(false);
    setNoChecked(false);
    setActiveStep(0);
  };

  const handleShowError = () => {
    setShowError(true);
  };
  const handleYesClick = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const steps = [
    "Select master blaster campaign settings",
    "Create an ad group",
    "Create an ad",
  ];
  return (
    <>
      <Box position="relative">
        <Dialog
          open={openDialog}
          onClose={handleClose}
          maxWidth="md"
          fullScreen
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "30px",

              width: { xs: "90%", sm: "50%", md: "35%" },
              maxHeight: {
                md: "60%",
                xs: "80%",
                sm: "60%",
              },
            },
          }}
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={12}>
                <Box display={"flex"}>
                  <Box
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ flex: 1 }}
                  >
                    <Typography
                      variant="h5"
                      textAlign={"center"}
                      fontSize={{ xs: "16px", md: "20px" }}
                      sx={{ fontWeight: "bolder" }}
                    >
                      Review Seller
                    </Typography>
                  </Box>
                  <Box alignItems={"flex-end"} justifyContent={"flex-end"}>
                    <Box>
                      <IconButton>
                        <ClearIcon onClick={handleClose} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent onClick={handleShowError}>
            <CustomizedSteppers activeStep={activeStep} />
            <Box px={4} mt={4}>
              {activeStep === 0 && (
                <>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                    textAlign={"center"}
                  >
                    {" "}
                    Did you buy anything from{" "}
                    {listData.seller.store?.brand_name}
                  </Typography>

                  <Grid xs={12} display={"flex"} flex={1} mt={2}>
                    <Grid
                      xs={6}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleYesClick}
                        sx={{
                          borderRadius: "20px",
                          padding: "8px 16px",
                          backgroundColor: "rgb(255 192 1)",
                          color: "white",
                        }}
                      >
                        Yes
                      </Button>
                    </Grid>
                    <Grid
                      xs={6}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          borderRadius: "20px",
                          padding: "8px 16px",
                          backgroundColor: "#D3D3D3",
                          color: "white",
                        }}
                        onClick={handleClose}
                      >
                        No
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
              {activeStep === 1 && (
                <>
                  <Typography
                    mt={2}
                    textAlign={"center"}
                    sx={{ fontWeight: "bold", fontSize: "16px" }}
                  >
                    {" "}
                    How was your experience buying from?
                  </Typography>
                  <Box
                    mt={2}
                    mb={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                  >
                    <Rating
                      name="simple-controlled"
                      value={rating}
                      onChange={handleSetRating}
                      sx={{ fontSize: "3rem" }}
                      disabled={noChecked ? true : false}
                    />
                  </Box>
                </>
              )}

              {activeStep === 2 && (
                <>
                  <Typography
                    mt={2}
                    sx={{ fontWeight: "bold", fontSize: "16px" }}
                  >
                    {" "}
                    What went well?
                  </Typography>
                  <Box mt={2}>
                    <TextField
                      disabled={noChecked ? true : false}
                      placeholder={"Please write your review here..."}
                      value={reviewMessage}
                      onChange={handleInputChange}
                      fullWidth
                      multiline
                      rows={isSmallToMediumScreen ? 3 : 5}
                    />
                  </Box>
                  <Box display={"flex"} flex={1}>
                    <Box flex={0.9}>
                      {showError && reviewMessage.length < 10 && !noChecked && (
                        <Typography sx={{ color: "red" }}>
                          At least 10 characters are required!!
                        </Typography>
                      )}
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"flex-end"}
                      flex={0.3}
                    >
                      <Typography sx={{ color: "grey" }}>
                        {reviewMessage.length}/500
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
            }}
          >
            <Box flex={1}>
              <MobileStepper
                variant="dots"
                steps={3}
                position="static"
                activeStep={activeStep}
                sx={{ maxWidth: "100%", flexGrow: 1 }}
                nextButton={
                  activeStep < 2 && openDialog ? (
                    <Button
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === 2}
                    >
                      Next
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      onClick={handleSubmitReview}
                      disabled={
                        reviewMessage.length > 9 &&
                          reviewMessage.length <= 500 &&
                          !noChecked
                          ? false
                          : true
                      }
                    >
                      Submit
                    </Button>
                  )
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};
const mapStateToProps = ({ Auth }) => ({
  jwtToken: Auth.jwtToken,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ReviewDialog);
