import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as Actions from "../../../redux/actions/";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { navItems } from "./NavItemsData";
import { AccountBalanceWallet } from "@mui/icons-material";
import { connect } from "react-redux";

const SellerDashboardNav = ({ user, seller, store, getStore }) => {
  const [active, setActive] = useState(navItems[0]);
  const location = useLocation();
  useEffect(() => {
    if (!store) {
      getStore({ storeId: seller?.store?.id });
    }
  }, [store]);
  useEffect(() => {
    if (location.pathname === "/seller/dashboard") {
      setActive(navItems[0]);
    } else if (location.pathname === "/seller/reviews") {
      setActive(navItems[1]);
    } else if (location.pathname === "/seller/listing") {
      setActive(navItems[2]);
    } else if (location.pathname === "/seller/profile") {
      setActive(navItems[3]);
    } else if (location.pathname === "/seller/service") {
      setActive(navItems[4]);
    }
  }, [location]);
  return (
    <Box
      sx={{
        width: "100vw",
        maxWidth: "16vw",
        height: "100vw",
        bgcolor: "white",
        position: "fixed",
      }}
    >
      <nav aria-label="meun list">
        <Box
          spacing={4}
          sx={{
            margin: "20",
            textAlign: "center",
          }}
        >
          <Link to="/">
            <img
              style={{ width: "150px" }}
              alt="Exotics logo"
              src="https://res.cloudinary.com/dwezij7u7/image/upload/v1675115795/exoticslogo_w2oi9z.png"
            />
          </Link>
        </Box>
        <Divider />
        {seller && seller.store?.brand_logo && seller.store.brand_logo.url && (
          <Box
            sx={{
              display: "flex",
              paddingLeft: { md: "30px", lg: "40px", xl: "40px" },
            }}
          >
            <Avatar
              alt="crew1"
              src={seller?.store?.brand_logo?.url}
              sx={{
                width: "75px",
                height: "75px",
                marginTop: "20px",
                textAlign: "center",
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            marginTop: "20px",
          }}
        >
          <List>
            {navItems.map((item) => (
              <ListItem
                sx={{
                  transition: "0.3s all",
                  "&:hover": { color: "rgb(255, 192, 1)" },
                }}
              >
                <ListItemButton
                  component={Link}
                  to={item.path}
                  onClick={() => {
                    setActive(item);
                  }}
                  sx={active === item && { color: "rgb(255, 192, 1)" }}
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: "inherit",
                      m: 0,
                      padding: {
                        lg: "5px 5px 5px 10px",
                        md: "2px 2px 0 5px",
                      },
                      minWidth: 0,
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{ m: { md: 0 } }}
                    primaryTypographyProps={{
                      fontSize: { md: "12px", lg: "14px", xl: "16px" },
                    }}
                    primary={item.text}
                  />
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem
              sx={{
                transition: "0.3s all",
                "&:hover": { color: "rgb(255, 192, 1)" },
              }}
            >
              <ListItemButton
                component={Link}
                to={`${user.billingPortalUrl}`}
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <ListItemIcon
                  sx={{
                    color: "inherit",
                    m: 0,
                    padding: {
                      lg: "5px 5px 5px 10px",
                      md: "2px 2px 0 5px",
                    },
                    minWidth: 0,
                  }}
                >
                  <AccountBalanceWallet fontSize="16px" />
                </ListItemIcon>
                <ListItemText
                  sx={{ m: { md: 0 } }}
                  primaryTypographyProps={{
                    fontSize: { md: "12px", lg: "14px", xl: "16px" },
                  }}
                  primary={"Billing"}
                />
              </ListItemButton>
            </ListItem>
          </List>
          <List
            sx={{
              position: "fixed",
              bottom: "0",
              padding: 0,
              margin: 0,
            }}
          >
            <ListItem
              sx={{
                margin: { md: 0, lg: "10px" },
                transition: "0.3s all",
                "&:hover": { color: "rgb(255, 192, 1)" },
              }}
            >
              <ListItemButton
                component={Link}
                to="/"
                style={{
                  paddingY: 0,
                }}
              >
                <ListItemIcon
                  sx={{
                    color: "inherit",
                    m: 0,
                    minWidth: 0,
                  }}
                >
                  <ArrowBackIcon sx={{ fontSize: "16px" }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    paddingLeft: "2px",
                    fontWeight: "bold",
                    fontSize: { md: "12px", lg: "14px", xl: "16px" },
                  }}
                  primary={"Home"}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </nav>
    </Box>
  );
};

const mapStateToProps = ({ Auth, Seller, Store }) => ({
  user: Auth.user,
  // jwtToken: Auth.jwtToken,
  seller: Seller.seller,
  store: Store.store,
});

const mapDispatchToProps = (dispatch) => ({
  getStore: (storeId) => dispatch(Actions.thunkGetStore(storeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SellerDashboardNav);
