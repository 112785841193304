import React, { useState } from "react";
import {
  Typography,
  Button,
  Box,
  List,
  ListItem,
  Checkbox,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Dialog,
  Chip,
  Tooltip,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import config from "../../config";
import { useStripe } from "@stripe/react-stripe-js";
import * as Actions from "../../redux/actions";
import { connect } from "react-redux";

const PricingDialog = ({
  plan,
  handleClose,
  descriptionElementRef,
  open,
  user,
  jwtToken,
}) => {
  const stripe = useStripe();

  const handleSubscribe = async (e, planId) => {
    try {
      const registerRequest = await axios.post(
        `${config.SERVER_URL}/api/v1/checkout`,
        {
          plan_id: planId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      const { data: registerRequestData, status } = registerRequest;
      if (status === 200) {
        return stripe.redirectToCheckout({
          sessionId: registerRequestData.sessionId,
        });
      }
    } catch (error) {
      console.log("Error while making the request:", error.message);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          sx: {
            width: { xs: "80vw", md: "30vw" },
          },
        }}
      >
        <Box>
          <Typography
            variant="h4"
            sx={{ fontWeight: 900 }}
            textAlign={"center"}
          >
            {plan.name}
          </Typography>

          <Typography
            variant="h5"
            textAlign={"center"}
            sx={{ fontWeight: 900 }}
          >
            {`$${plan.price_cents / 100}`}
          </Typography>
          <Typography
            variant="h4"
            textAlign={"center"}
            sx={{ fontSize: "14px" }}
          >
            per {plan.interval}
          </Typography>
        </Box>

        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{ textAlign: "center" }}
          >
            <Box>
              <List>
                {plan.offers &&
                  plan.offers.map((offer) => {
                    return (
                      <ListItem
                        display="inline"
                        key={offer.name}
                        disablePadding
                      >
                        <Checkbox
                          defaultChecked={offer.value}
                          disabled
                          sx={{ color: "rgb(255, 192, 1) !important" }}
                        />
                        <Typography display="inline">{offer.name}</Typography>

                        <Tooltip
                          title={offer.description}
                          arrow
                          followCursor
                          enterTouchDelay={0}
                        >
                          <InfoIcon
                            sx={{
                              width: "16px",
                              m: 0.5,
                              "&:hover": {
                                color: "rgb(255, 192, 1)",
                                cursor: "pointer",
                              },
                            }}
                          />
                        </Tooltip>
                        <Typography display="inline">
                          {(offer.name === "QR Code" ||
                            offer.name === "Merch") && (
                            <Chip
                              label={<Typography>Coming Soon</Typography>}
                              size="small"
                              sx={{
                                mt: "5px",
                                backgroundColor: "#FFC001",
                                color: "#fff",
                                borderRadius: "6px",
                                fontWeight: "bold",
                                fontSize: "12px",
                                wordBreak: "break-word",
                                p: "5px",
                              }}
                            />
                          )}
                        </Typography>
                      </ListItem>
                    );
                  })}
              </List>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!user && (
            <>
              <Button onClick={handleClose}>Cancel</Button>
              <Button component={Link} to={`/auth/login`}>
                Login/Signup
              </Button>
            </>
          )}
          {user && !user.is_subscribed && (
            <>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                // component={Link}
                // to={`/seller/checkout/plan/${plan.id}${couponCode ? `?couponCode=${couponCode}` : ''}`}
                onClick={(e) => handleSubscribe(e, plan.id)}
              >
                Subscribe
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ Auth }) => ({
  responseErrors: Auth.responseError,
  user: Auth.user,
  jwtToken: Auth.jwtToken,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PricingDialog);
