import React, { useState, useEffect } from "react";
import * as Actions from "../../redux/actions/";
import Blogs from "../../components/home/Blogs";
import Categories from "../../components/home/HomeCategories";
import ListingGallery from "../../components/home/ListingGallery";
import PetAboutUs from "../../components/home/PetAboutUs";
import PetTrading from "../../components/home/PetTrading";
import Services from "../../components/home/Services";
import TopBanner from "../../components/home/TopBanner";
import Ad from "../../components/home/Ad";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config";
import { useLocation } from "react-router-dom";
import FacebookEmail from "../../components/home/FacebookEmail";
import { Spinner } from "../../components/spinner/Spinner";
const HomePage = ({
  user,
  getSeller,
  classes,
  productListings,
  loadListings,
  setUserIsSubscribed,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isFeatured, setIsFeatured] = useState(true);
  const [featuredListings, setIsFeaturedListings] = useState([]);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [adData, setAdData] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("success");

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    if (paramValue === "true") {
      setUserIsSubscribed();
    }
  }, [paramValue]);

  useEffect(() => {
    let tempEmail;

    if (user) {
      tempEmail = user.email.split("_");
      if (tempEmail[0] === "temp") {
        setEmailModalOpen(true);
      }
    }
  }, [user]);

  const fetchAd = async () => {
    let registerRequest;
    try {
      registerRequest = await axios.get(
        `${config.SERVER_URL}/api/v1/ads?status=active&page=home`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch ({ response }) {
      registerRequest = response;
    }

    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 200) {
      setAdData(registerRequestData.data);
    }
  };

  const handleClose = () => {
    setEmailModalOpen(false);
  };

  useEffect(() => {
    if (user?.is_subscribed) {
      getSeller();
    }
  }, []);

  useEffect(() => {
    loadListings({
      pageNumber,
      rowsPerPage,
      isFeatured: isFeatured,
    });
  }, [isFeatured, loadListings]);

  useEffect(() => {
    setIsFeaturedListings(productListings.slice(0, 8));
  }, [productListings]);

  useEffect(() => {
    fetchAd();
  }, []);

  return (
    <>
      {classes.length === 0 && <Spinner />}
      <FacebookEmail
        open={emailModalOpen}
        handleClose={handleClose}
        setEmailModalOpen={setEmailModalOpen}
      />
      <TopBanner />
      <Categories classes={classes} page={"Home"} />
      <PetTrading />
      <PetAboutUs description={description} />
      {adData.length > 0 && <Ad adData={adData} />}
      <Services showButton={true} />

      {featuredListings.length > 0 && (
        <ListingGallery featuredListings={featuredListings} />
      )}
      <Blogs />
    </>
  );
};

const description =
  "At Moore Exotics, we are passionate about connecting pet lovers with their perfect companion. We believe that every pet deserves a loving home and we are committed to providing a safe and secure marketplace for pet buyers and sellers.";

const mapStateToProps = ({ Auth, Categories, Listings }) => ({
  user: Auth.user,
  classes: Categories.classes,
  productListings: Listings.listings,
});

const mapDispatchToProps = (dispatch) => ({
  getSeller: () => dispatch(Actions.thunkGetSeller()),
  loadListings: ({ pageNumber, rowsPerPage, isFeatured }) =>
    dispatch(
      Actions.thunkLoadListing({
        pageNumber,
        rowsPerPage,
        isFeatured,
      })
    ),
  setUserIsSubscribed: () => dispatch(Actions.setUserIsSubscribed()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
