import {
  Alert,
  Box,
  Button,
  Grid,
  Slide,
  Snackbar,
  Typography,
  Select,
  MenuItem,
  TablePagination,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ListingsList from "../../../components/seller/reviews/ListingList";
import CustomModal from "../../../components/seller/reviews/Modal";
import DisplayReview from "../../../components/seller/reviews/DisplayReview";
import config from "../../../config";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  hover: {
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#eeeeee" },
  },
}));

let filters = [
  { id: "all", name: "All" },
  { id: "animal", name: "Animal" },
  { id: "service", name: "Service" },
];

const Reviews = ({ classes, seller, jwtToken, user, store }) => {
  const styleclasses = useStyles();
  const [openDisputeModal, setOpenDisputeModal] = useState(false);
  const [filter, setFilter] = useState(filters[0].id);
  const [confirmProp, setConfirmProp] = useState(false);
  const [confirmProp2, setConfirmProp2] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [reviewData, setReviewData] = useState();
  const [disputeMessage, setDisputeMessage] = useState();
  const [review, setReview] = useState();
  const [paginationStates, setPaginationStates] = useState({
    page: 0,
    rowsPerPage: 10,
    total_records: 0,
  });
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const plans = ["Silver", "Free"];
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setDisputeMessage(e.target.value);
  };
  const handleReviewTypeButton = async (type) => {
    setFilter(type);
  };
  const handleOpenDisputeModal = (id) => {
    if (id) {
      setOpenDisputeModal(true);
    }
  };
  const handleCloseDisputeModal = () => {
    setOpenDisputeModal(false);
  };
  const handleCloseNoStoreModal = () => {
    navigate("/seller/profile");
  };
  const fetchSellerReviews = async () => {
    let registerRequest;
    try {
      registerRequest = await axios.get(
        `${config.SERVER_URL}/api/v1/reviews?review_type=${filter}&seller_id=${seller.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
    } catch ({ response }) {
      if (response?.status === 422) {
        console.log("Show error snackbar");
      }
    }

    if (registerRequest && registerRequest.status === 200) {
      const filteredReviews = registerRequest.data.reviews.filter((data) => {
        if (plans.includes(seller.user.subscriptions[0].plan.name)) {
          if (data.reviewable_type === "ListingAnimal") {
            return data;
          }
        } else {
          return data;
        }
      });
      setReviewData(filteredReviews);
      // setReviewData(registerRequest.data.reviews);
    }
  };

  useEffect(() => {
    if (plans.includes(seller.user.subscriptions[0].plan.name)) {
      filters = [
        { id: "all", name: "All" },
        { id: "animal", name: "Animal" },
      ];
    }
    if (seller?.id) {
      fetchSellerReviews();
    }
  }, [
    paginationStates.page,
    paginationStates.rowsPerPage,
    seller,
    confirmProp,
    filter,
  ]);

  useEffect(() => {
    const addDispute = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.post(
          `${config.SERVER_URL}/api/v1/reviews/${review.id}/disputes`,
          {
            dispute: {
              description: disputeMessage,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        if (registerRequest.status === 201) {
          setSnackbarStates({
            ...snackbarStates,
            open: true,
            message: "Dispute Added Successfully",
            severity: "success",
          });
        }
      } catch ({ response }) {
        if (response.status === 422) {
          setSnackbarStates({
            ...snackbarStates,
            open: true,
            message: "Dispute already added",
            severity: "error",
          });
        }
      }

      setOpenDisputeModal(false);
      setConfirmProp(false);
      setDisputeMessage("");
    };

    if (confirmProp) {
      addDispute();
    }

    return () => {
      setConfirmProp(false);
    };
  }, [confirmProp]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };

  const onPageChange = (event, newPage) => {
    setPaginationStates({ ...paginationStates, page: newPage });

    const updatedListing = reviewData.slice(
      newPage * paginationStates.rowsPerPage,
      newPage * paginationStates.rowsPerPage + paginationStates.rowsPerPage
    );

    setReviewData(updatedListing);
  };

  const onRowsPerPageChange = (event) => {
    const updatedRowsPerPage = parseInt(event.target.value, 10);
    setPaginationStates({
      ...paginationStates,
      rowsPerPage: updatedRowsPerPage,
      page: 0,
    });

    const updatedRows = reviewData.slice(
      0 * updatedRowsPerPage,
      0 * updatedRowsPerPage + updatedRowsPerPage
    );

    setReviewData(updatedRows);
  };

  const handleCloseReviewModal = () => {
    setShowReview(false);
  };
  const handleNavigateToStore = () => {
    navigate("/seller/profile");
  };
  return (
    <>
      <Dialog
        open={!Boolean(store)}
        onClose={handleCloseNoStoreModal}
        aria-labelledby="alert-dialog"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: { xs: "80vw", md: "30vw" },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: "red" }}>
          Need to Complete Seller Profile First
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please Complete your seller profile to use Seller Dashboard.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: "green" }}
            autoFocus
            onClick={handleNavigateToStore}
          >
            Go to store
          </Button>
        </DialogActions>
      </Dialog>

      <Grid xs={12}>
        <Snackbar
          transitionDuration={{ enter: 1000, exit: 2000 }}
          TransitionComponent={Slide}
          TransitionProps={{ enter: true, exit: false }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbarStates.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant="filled"
            severity={snackbarStates.severity}
            sx={{ width: "100%" }}
          >
            {snackbarStates.message}
          </Alert>
        </Snackbar>
        {review && (
          <DisplayReview
            openModal={showReview}
            closeModal={handleCloseReviewModal}
            data={review}
          />
        )}

        <CustomModal
          title="Dispute Review"
          content={""}
          openModal={openDisputeModal}
          closeModal={handleCloseDisputeModal}
          actionTitle={"Submit"}
          setConfirmProp={setConfirmProp}
          actionColor={"#FFC001"}
          showTextBox={true}
          handleInputChange={handleInputChange}
          disputeMessage={disputeMessage}
        />

        <Box
          px="5px"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "5px",
            border: "none",
            bgcolor: "white",
            m: 1,
          }}
        >
          <Typography sx={{ paddingTop: "5px", paddingLeft: "5px" }}>
            Reviews
          </Typography>
          <Select
            value={filter}
            size="small"
            disableUnderline
            onChange={(e) => handleReviewTypeButton(e.target.value)}
            className={styleclasses.hover}
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
          >
            {filters.map((filter) => (
              <MenuItem
                key={filter.id}
                value={filter.id}
                sx={{ fontSize: "14px" }}
              >
                {filter.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{ height: "100vh" }}>
          <ListingsList
            handleOpenDisputeModal={handleOpenDisputeModal}
            data={reviewData}
            setOpenDisputeModal={setOpenDisputeModal}
            // loadListings={loadListings}
            setReview={setReview}
            setOpenReview={setShowReview}
            page={paginationStates.page}
            rowsPerPage={paginationStates.rowsPerPage}
            classes={classes}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="bottom"
        >
          <TablePagination
            component="div"
            count={paginationStates.total_records}
            page={paginationStates.page}
            onPageChange={onPageChange}
            rowsPerPage={paginationStates.rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Box>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ Categories, Auth, Seller, Store }) => ({
  classes: Categories.classes,
  user: Auth.user,
  seller: Seller.seller,
  jwtToken: Auth.jwtToken,
  store: Store.store,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
