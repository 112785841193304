import React from "react";
import { Box } from "@mui/material";
import Chat from "./chat";
import { connect } from "react-redux";

const Inbox = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "87.0vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Chat />
    </Box>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
