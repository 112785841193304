import { Box, Stack, Button, Chip, Typography, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  TablePagination,
  ButtonGroup,
  IconButton,
} from "@mui/material";
import ListView from "./ListView";

import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
import { connect } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { breedsRaw } from "../../components/listing/breedsRaw";
import ServiceCardListing from "../../components/serviceListings/ServiceCardListing";
import * as Actions from "../../redux/actions";
import Categories from "../../components/home/HomeCategories";
import { useLocation } from "react-router-dom";
import TuneIcon from "@mui/icons-material/Tune";
import { Filter } from "@mui/icons-material";
import axios from "axios";
import config from "../../config";
import ListingsList from "../../components/seller/listings/ListingsList";
import FilterModal from "../listing/FilterModal";
import ConstructionIcon from "@mui/icons-material/Construction";
import PetsIcon from "@mui/icons-material/Pets";
import { Switch, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";

const ServicesPageListings = ({
  services,
  subServices,
  loadServices,
  loadServiceListings,
  serviceListing,
  jwtToken,
  totalServiceListing,
  servicesLoading,
  error,
}) => {
  const { serviceId, subServiceId, sellerId, categoryId } = useParams();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [filters, setFilter] = useState([]);
  const store_id = sellerId ? sellerId : "";
  const service_id = serviceId ? serviceId : "";
  const sub_service_id = subServiceId ? subServiceId : "";
  const category_id = categoryId ? categoryId : "";
  const queryParams = new URLSearchParams(location.search);
  const is_Featured = queryParams.get("is_featured");
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [view, setView] = useState("Grid");
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  useEffect(() => {
    if (error != "") {
      setSnackbarStates({
        open: true,
        message: error,
        severity: "danger",
      });
    }
  }, [error]);
  const productBackgroundColor = "#D3D3D3";
  const handleRowsPerChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };
  const handlePageChange = (event, page) => {
    setPageNumber(page);
  };
  const openDialog = () => {
    setOpen(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };
  useEffect(() => {
    loadServiceListings({
      service_id,
      sub_service_id,
      store_id,
      category_id,
      jwtToken,
      is_Featured,
      pageNumber,
      rowsPerPage,
    });
  }, [
    services,
    subServices,
    categoryId,
    serviceId,
    is_Featured,
    subServiceId,
    sellerId,
  ]);
  useEffect(() => {
    if (services) {
      loadServices();
    }
  }, []);
  const handleShowAnimalListings = () => {
    navigate("/listings");
  };

  const handleShowServicesListings = () => {
    navigate("/services/listings");
  };
  const handleChange = (event) => {
    navigate("/listings");
  };
  const handleGridView = () => {
    setView("Grid");
  };

  const handleListView = () => {
    setView("List");
  };
  return (
    <>
      <Snackbar
        open={snackbarStates.open}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <FilterModal
        open={open}
        setOpen={setOpen}
        setFilter={setFilter}
        page={"Services"}
      />
      <Box
        style={{ flex: 1, justifyContent: "center", display: "flex" }}
        pt={2}
      >
        <ButtonGroup
          variant="contained"
          aria-label="Disabled elevation buttons"
        >
          <Button
            sx={{
              color: "white",
              backgroundColor: productBackgroundColor,
              "&:hover": {
                backgroundColor: "#D3D3D3", // Customize the hover background color
              },
            }}
            onClick={handleShowAnimalListings}
            startIcon={<PetsIcon sx={{ color: "white" }} />}
          >
            Animal Listings
          </Button>
          <Button
            sx={{
              color: "white",
              fontWeight: "bold",
            }}
            onClick={handleShowServicesListings}
            startIcon={<ConstructionIcon sx={{ color: "white" }} />}
          >
            Service Listings
          </Button>
        </ButtonGroup>
      </Box>
      <Box justifyContent={"center"} display={"flex"}>
        <FormControlLabel
          control={<Switch defaultChecked={true} onChange={handleChange} />}
        />
      </Box>

      <Categories classes={services} page={"Services"} />

      <Box display="flex" flex={1}>
        <Box
          display="flex"
          flex={0.8}
          flexWrap={"wrap"}
          ml={{ md: 10 }}
          sx={{ maxWidth: "80%" }}
        >
          {filters.length > 0 &&
            filters.map((tag, index) => (
              <Chip
                key={index}
                label={
                  <Typography noWrap sx={{ maxWidth: { xs: "235px" } }}>
                    {tag}
                  </Typography>
                }
                size="small"
                sx={{
                  ml: "5px",
                  mt: "5px",
                  backgroundColor: "#FFC001",
                  color: "#fff",
                  borderRadius: "6px",
                  fontWeight: "bold",
                  fontSize: "12px",
                  maxWidth: "50%",
                  wordBreak: "break-word",
                  p: 0,
                }}
              />
            ))}
        </Box>
        <Box display="flex" justifyContent="flex-end" flex={0.2}>
          <IconButton aria-label="delete" onClick={handleGridView}>
            <GridViewIcon sx={{ color: view === "Grid" && "#FFC001" }} />
          </IconButton>{" "}
          <IconButton aria-label="delete" onClick={handleListView}>
            <ListIcon sx={{ color: view !== "Grid" && "#FFC001" }} />
          </IconButton>{" "}
          <Button
            type="submit"
            variant="contained"
            startIcon={<TuneIcon sx={{ color: "white" }} />}
            onClick={openDialog}
            sx={{
              marginBottom: "8px !important",
              marginRight: { md: "100px", xs: "15px" },
              height: "40px",
              color: "white",
              fontSize: "15px !important",
              fontWeight: "400 !important",
              textTransform: "none !important",
              width: "100px",
              "&:hover": {
                backgroundColor: "rgb(255, 192, 1)",
                color: "white !important",
              },
            }}
          >
            FILTER
          </Button>
        </Box>
      </Box>
      <Stack sx={{ marginX: { xs: "20px", md: "100px" } }}>
        {servicesLoading ? (
          <Box
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {view === "Grid" ? (
              <ServiceCardListing
                listData={serviceListing}
                classes={services}
              />
            ) : (
              <ListView listData={serviceListing} services={services} />
            )}
          </>
        )}
        {serviceListing?.length > 0 && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ bottom: 0 }}
          >
            {totalServiceListing && (
              <TablePagination
                component="div"
                count={totalServiceListing}
                page={pageNumber}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerChange}
                labelRowsPerPage="Listings per page"
              />
            )}
          </Box>
        )}
      </Stack>
    </>
  );
};

const mapStateToProps = ({ Services, Listings, Auth }) => ({
  subServices: Services.subServices,
  services: Services.services,
  serviceListing: Listings.serviceListing,
  jwtToken: Auth.jwtToken,
  servicesLoading: Listings.servicesLoading,
  totalServiceListing: Listings.totalServiceListing,
  error: Listings.errorServices,
});

const mapDispatchToProps = (dispatch) => ({
  loadServices: () => dispatch(Actions.thunkLoadServices()),
  loadServiceListings: ({
    service_id,
    sub_service_id,
    store_id,
    category_id,
    is_Featured,
    jwtToken,
    pageNumber,
    rowsPerPage,
  }) =>
    dispatch(
      Actions.thunkLoadServiceListings({
        service_id,
        sub_service_id,
        store_id,
        is_Featured,
        category_id,
        jwtToken,
        pageNumber,
        rowsPerPage,
      })
    ),
});
const useStyles = makeStyles({
  switchBase: {
    // justifyContent: "flex-end",
    right: 0,
    display: "flex",
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesPageListings);
