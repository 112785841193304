import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import StarIcon from "@mui/icons-material/Star";
import { Star, StarHalf } from "@mui/icons-material";
import { Avatar, Box } from "@mui/material";
import { connect } from "react-redux";
import config from "../../../config";
import axios from "axios";

const AboutSeller = ({ user, jwtToken, seller }) => {
  const [responseRate, setResponseRate] = useState(100);
  const [deliveryOnTime, setDeliveryOnTime] = useState(90);
  const [orderCompletion, setOrderCompletion] = useState(100);
  const [userProfile, setUserProfile] = useState(null);
  const [reviewData, setReviewData] = useState([]);
  const [averageRating, setAverageRating] = useState();
  const plans = ["Silver", "Free"];
  useEffect(() => {
    const calculateAverageRating = () => {
      const ratings = reviewData.filter((data) => {
        if (plans.includes(seller.user.subscriptions[0].plan.name)) {
          if (data.reviewable_type === "ListingAnimal") {
            return data;
          }
        } else {
          return data;
        }
      });
      const filteredRating = ratings.map((data) => {
        return data.rating;
      });
      const sum = filteredRating.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const average = sum / filteredRating.length;
      setAverageRating(average.toFixed(2)); // Returns average rating with 2 decimal places
    };
    if (reviewData.length !== 0) {
      calculateAverageRating();
    } else {
      setAverageRating(0);
    }
  }, [reviewData]);

  useEffect(() => {
    const fetchUser = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/users/edit`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;
      }
      if (registerRequest.status === 200) {
        setUserProfile(registerRequest.data.data);
      }
    };
    fetchUser();
  }, [jwtToken, user]);
  const renderStarRating = (rating) => {
    const roundedRating = Math.floor(rating); // Round the average rating down to the nearest whole number
    const decimalPart = rating - roundedRating; // Calculate the decimal part of the average rating
    const stars = [];
    for (let i = 1; i <= roundedRating; i++) {
      stars.push(
        <StarIcon key={i} sx={{ color: "rgb(255 192 1)", fontSize: "30px" }} />
      );
    }

    if (decimalPart > 0) {
      const decimalPoints = Math.round(decimalPart * 10) / 10; // Round the decimal part to one decimal point
      stars.push(
        <span
          key="decimal"
          style={{ display: "inline-flex", alignItems: "center" }}
        >
          <StarHalf sx={{ color: "rgb(255 192 1)", fontSize: "30px" }} />
        </span>
      );
    }

    return stars;
  };

  useEffect(() => {
    const fetchSellerReviews = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/v1/reviews?seller_id=${seller.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        if (registerRequest && registerRequest.status === 200) {
          setReviewData(registerRequest.data.reviews);
        }
      } catch (err) {
        if (err) {
          console.log("Show error snackbar", err.message);
        }
      }
    };

    if (seller) {
      fetchSellerReviews();
    }
  }, [seller]);

  return (
    <Card
      sx={{
        margin: "10px",
        height: "50vh",
        borderRadius: "10px",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            marginTop: "20px",
            marginBottom: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {userProfile?.user_profile?.url ? (
            <Avatar
              alt="Profile Avatar"
              src={userProfile.user_profile.url}
              sx={{
                width: "120px",
                height: "120px",
              }}
            />
          ) : (
            <Avatar
              alt="User Profile Avatar"
              src={user.avatar_url}
              sx={{
                width: "120px",
                height: "120px",
              }}
            />
          )}
        </Box>
        <Box>
          <Typography sx={{ fontSize: "18px", textAlign: "center" }}>
            {user.full_name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              {renderStarRating(averageRating)}
              <Typography sx={{ color: "#444444", fontSize: "20px" }}>
                ({averageRating})
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
const mapStateToProps = ({ Auth, Seller }) => ({
  user: Auth.user,
  seller: Seller.seller,
  jwtToken: Auth.jwtToken,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AboutSeller);
