import React, { useEffect, useState } from "react";
import {
  ImageListItemBar,
  Stack,
  Typography,
  Paper,
  Button,
  TextField,
  Card,
  Breadcrumbs,
  InputAdornment,
  Alert,
  Box,
  useMediaQuery,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
import ListView from "../categories/ListView";
import ImageListItem, {
  imageListItemClasses,
} from "@mui/material/ImageListItem";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Search } from "@mui/icons-material";
import HomeServices from "../../components/home/Services";
import axios from "axios";
import config from "../../config";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions/";
import { Helmet } from "react-helmet-async";
import "./Services.css";

const Services = ({
  services,
  subServices,
  loadServices,
  loadSubServices,
  error,
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [view, setView] = useState("Grid");
  const handleGridView = () => {
    setView("Grid");
  };
  const isLargeScreen = useMediaQuery("(min-width: 2500px)");
  const isLarge38pxScreen = useMediaQuery("(min-width: 3800px)");

  const handleListView = () => {
    setView("List");
  };
  useEffect(() => {
    loadServices();
  }, [loadServices]);

  useEffect(() => {
    if (services.length > 0) {
      loadSubServices(services[0].id);
    }
    if (error != "") {
      setSnackbarStates({
        open: true,
        message: error,
        severity: "danger",
      });
    }
  }, [services, loadSubServices]);
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {}, [subServices]);

  const handleCloseServicesAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };
  return (
    <>
      <Helmet>
        <meta
          property="og:image"
          content="https://prodappdata.s3.amazonaws.com/preview-images/services-preview.png"
        />
        <meta property="og:title" content="Services" />
        <meta property="og:description" content="Moore Exotics Services" />
      </Helmet>
      <Snackbar
        open={snackbarStates.open}
        autoHideDuration={3000}
        onClose={handleCloseServicesAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseServicesAlert}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <HomeServices showButton={false} />

      <Stack className={classes.servicesContainer}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/" className={classes.breadcrumbs}>
            Home
          </Link>
          <Typography color="primary">Services</Typography>
        </Breadcrumbs>
        <Typography
          variant="h3"
          component={"h3"}
          sx={{
            fontWeight: 900,
            textAlign: "center",
            // mt: 4,
          }}
        >
          Community Services
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ textAlign: "center", flexGrow: 1 }}>
            <TextField
              placeholder="Search our services"
              type="search"
              size="small"
              sx={{
                width: { xs: "100%", sm: "70%", md: "50%" },
                mt: 2,
                backgroundColor: "white",
                "& .MuiOutlinedInput-root": {
                  paddingRight: 0,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{
                      backgroundColor: "rgb(255, 192, 1)",
                      padding: "27.5px 14px",
                      borderTopLeftRadius: (theme) =>
                        theme.shape.borderRadius + "px",
                      borderBottomLeftRadius: (theme) =>
                        theme.shape.borderRadius + "px",
                    }}
                    position="end"
                  >
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearchChange}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton aria-label="delete" onClick={handleGridView}>
              <GridViewIcon sx={{ color: view === "Grid" && "#FFC001" }} />
            </IconButton>{" "}
            <IconButton aria-label="delete" onClick={handleListView}>
              <ListIcon sx={{ color: view !== "Grid" && "#FFC001" }} />
            </IconButton>{" "}
          </Box>
        </Box>
        {view === "Grid" ? (
          <Card
            variant="outlined"
            sx={{
              p: 4,
              mt: 1,
              display: "grid",
              gap: 2,
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(3, 1fr)",
                md: "repeat(3, 1fr)",
                lg: "repeat(4, 1fr)",
                xl: isLargeScreen
                  ? isLarge38pxScreen
                    ? "repeat(7, 1fr)"
                    : "repeat(6, 1fr)"
                  : "repeat(5, 1fr)",
              },
              [`& .${imageListItemClasses.root}`]: {
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            {services &&
              services
                .filter((item) => {
                  if (search === "") {
                    return item;
                  } else if (
                    item.name.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return item;
                  }
                })
                .map((item) => (
                  <Paper elevation={24}>
                    <ImageListItem
                      key={item.image}
                      sx={{ height: "100% !important" }}
                    >
                      <div className="container">
                        <img
                          className="image"
                          src={`${item.image}`}
                          srcSet={`${item.image}`}
                          alt={item.name}
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "250px",
                            objectFit: "contain",
                          }}
                        />
                        <div className="middle">
                          <div className="layOver"></div>
                          <div className="button">
                            <Button
                              component={Link}
                              to={`/services/${item.id}`}
                              size="large"
                              variant="outlined"
                              sx={{
                                "&:hover": {
                                  backgroundColor: "rgb(255, 192, 1)",
                                  color: "#000",
                                },
                              }}
                            >
                              Go Now
                            </Button>
                          </div>
                        </div>
                      </div>

                      <ImageListItemBar
                        title={item.name}
                        sx={{ textAlign: "center" }}
                      />
                    </ImageListItem>
                  </Paper>
                ))}
          </Card>
        ) : (
          <ListView categories={services} page={"Services"} search={search} />
        )}
      </Stack>
    </>
  );
};

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  servicesContainer: {
    backgroundColor: "#ebecf0",
    padding: "24px 5px 24px 5px",
  },
  breadcrumbs: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      color: "rgb(255, 192, 1)",
      textDecoration: "underline",
    },
  },
});

const mapStateToProps = ({ Services }) => ({
  services: Services.services,
  subServices: Services.subServices,
  error: Services.error,
});

const mapDispatchToProps = (dispatch) => ({
  loadServices: () => dispatch(Actions.thunkLoadServices()),
  loadSubServices: (serviceId) =>
    dispatch(Actions.thunkLoadSubServices(serviceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Services);
