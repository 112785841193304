import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Avatar,
  Rating,
  Typography,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";

const DisplayReview = ({ data, openModal, closeModal }) => {
  const classes = useStyles();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const formattedDate = date.toLocaleDateString(undefined, options);
    return formattedDate;
  };
  return (
    <>
      <Dialog
        open={openModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "40px",

            width: { xs: "90%", sm: "50%", md: "25%" },
            // minHeight: { xs: "35%" },
            maxHeight: {
              md: "60%",
              xs: "80%",
              sm: "60%",
            },
          },
        }}
      >
        <DialogTitle>
          <Box display={"flex"} flex={1} justifyContent={"flex-end"}>
            <Box>
              <IconButton justifyContent={"flex-end"}>
                <ClearIcon onClick={closeModal} />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ padding: "50px 30px" }}>
          <Box display={"flex"} justifyContent={"center"}>
            <Rating
              name="simple-controlled"
              value={data.rating}
              readOnly
              size="large"
            />
          </Box>
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Avatar
              alt="User Avatar"
              src={data.user.avatar_url}
              className={classes.largeAvatar}
            />
          </Box>
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              {data.user.full_name}
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"center"} mt={1}>
            <Typography sx={{ fontSize: "14px", color: "grey" }}>
              {data.reviewable.name}.{formatDate(data.created_at)}
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"center"} mt={1}>
            <Typography sx={{ fontSize: "16px", wordBreak: "break-all" }}>
              {data.content}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

export default DisplayReview;
