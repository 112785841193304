import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Stack,
  Grid,
  Typography,
  Button,
  Breadcrumbs,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import ListTopBar from "../../../components/listdetails/ListTopBar";
import ListImage from "../../../components/listdetails/ListImage";
import Reviews from "../../../components/serviceListings/Reviews";
import ListDetailsCard from "../../../components/listdetails/ListDetailsCard";
import AboutPolicy from "../../../components/listdetails/AboutPolicy";
import ListMedia from "../../../components/listdetails/ListMedia";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import config from "../../../config";
import "./ListDetail.css";
import StoreIcon from "@mui/icons-material/Store";
import { Divider } from "@mui/material";
import * as Actions from "../../../redux/actions/";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const ListDetail = ({
  loading,
  loadListings,
  productListings,
  classes,
  jwtToken,
}) => {
  const navigate = useNavigate();
  const styleclasses = useStyles();
  const { listId } = useParams();
  const [listData, setListData] = useState();
  const [reviews, setReviews] = useState([]);
  const [featuredListings, setFeaturedListings] = useState([]);
  const [moreListings, setMoreListings] = useState([]);

  const handleShowAllListings = () => {
    if (listData?.category_id) {
      if (featuredListings.length > 0) {
        navigate("/listings/classes/" + listData.category_id, {
          state: { filter: "featured" },
        });
      } else {
        navigate("/listings/classes/" + listData.category_id);
      }
    }
  };

  useEffect(() => {
    const fetchSellerReviews = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/v1/reviews?seller_id=${listData.seller_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        if (response.status === 422) {
          console.log("Show error snackbar");
        }
      }

      if (registerRequest && registerRequest.status === 200) {
        setReviews(registerRequest.data.reviews);
      }
    };
    if (listData) {
      fetchSellerReviews();
    }
  }, [listData]);
  useEffect(() => {
    const getListingData = async (id) => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/v1/animals/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const { data: registerRequestData } = registerRequest;
        if (registerRequest.status === 200) {
          setListData(registerRequestData.data.listing);
          loadListings({
            categoryId: registerRequestData.data.listing.category_id,
          });
          window.scrollTo({ top: 0 });
        }
      } catch (error) {
        console.log("error", error.message);
      }
    };

    if (listId) {
      getListingData(listId);
    }
  }, [listId]);

  useEffect(() => {
    if (productListings) {
      setMoreListings(
        productListings.filter((item) => {
          return item.id !== listId;
        })
      );

      setFeaturedListings(
        productListings.filter((item) => {
          return item.is_featured && item.id !== listId;
        })
      );
    }
  }, [productListings]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Stack
          sx={{
            paddingX: { xs: "20px", md: "50px", lg: "150px", xl: "250px" },
            overflow: "hidden",
            bgcolor: "#ebecf0",
            paddingY: { xs: "20px", md: "50px", lg: "50px", xl: "50px" },
          }}
        >
          <Grid container md={12} className={styleclasses.categoriesContainer}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/" className={styleclasses.breadcrumbs}>
                Home
              </Link>
              {listData && (
                <Link
                  to={"/classes/" + listData.category_id + "/breeds"}
                  className={styleclasses.breadcrumbs}
                >
                  {listData.category && listData.category.name}
                </Link>
              )}
              {listData && (
                <Link
                  to={"/listings/breed/" + listData.breed_id}
                  className={styleclasses.breadcrumbs}
                >
                  {listData.breed && listData.breed.name}
                </Link>
              )}
            </Breadcrumbs>
          </Grid>
          <Grid
            container
            md={12}
            sx={{
              height: "50vh",
              marginX: "5px",
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
            }}
          >
            {listData && (
              <>
                <Grid item md={6} sx={{ paddingX: "10px" }}>
                  <ListImage listData={[...listData.media, listData.ped]} />
                </Grid>
                <Grid item md={6} sx={{ paddingRight: "20px" }}>
                  <ListDetailsCard listData={listData} />
                </Grid>
              </>
            )}
          </Grid>

          <Grid
            container
            xs={12}
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            {listData && (
              <>
                <Grid item xs={12}>
                  <ListImage listData={[...listData.media, listData.ped]} />
                </Grid>
                <Grid item xs={12}>
                  <ListDetailsCard listData={listData} />
                </Grid>
              </>
            )}
          </Grid>
          <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />

          {listData && (
            <Grid
              sx={{
                marginTop: "20px",
              }}
            >
              <AboutPolicy listData={listData} />
            </Grid>
          )}
          <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
          <Reviews
            reviews={reviews}
            listData={listData}
            reviewType={"animal"}
          />
          <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />

          <Box alignItems={"center"} justifyContent={"center"}>
            <Typography
              sx={{
                fontWeight: "bold",
                marginBottom: "10px",
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              More{featuredListings.length > 0 && " Featured"} Listings
            </Typography>
          </Box>

          <Box
            sx={{
              margin: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {featuredListings.length !== 0 || moreListings.length !== 0 ? (
                <ListMedia
                  listings={
                    featuredListings.length === 0
                      ? moreListings.slice(0, 5)
                      : featuredListings.slice(0, 5)
                  }
                  classes={classes}
                />
              ) : (
                <Typography
                  textAlign={"center"}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  {" "}
                  No Listings Found
                </Typography>
              )}
            </Box>
          </Box>

          {(featuredListings.length !== 0 || moreListings.length !== 0) && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                component={Button}
                size="large"
                variant="contained"
                sx={{
                  textAlign: "center",
                  marginTop: "10px",
                  bgcolor: "#FFC001",
                  width: { xs: "100vw", md: "20vw" },
                  // height: 40,
                  color: "#fff",
                  fontSize: 18,
                  fontWeight: 700,
                  fontFamily: "Roboto",
                  textTransform: "none !important",
                  transition: "0.3s all",
                }}
                onClick={handleShowAllListings}
              >
                See Similar {featuredListings.length > 0 && "Featured"} Listings
              </Box>
            </Box>
          )}
        </Stack>
      )}
    </>
  );
};

const mapStateToProps = ({ Listings, Categories, Auth, Seller }) => ({
  productListings: Listings.listings,
  loading: Listings.loading,
  classes: Categories.classes,
  user: Auth.user,
  jwtToken: Auth.jwtToken,
  seller: Seller.seller,
});

const mapDispatchToProps = (dispatch) => ({
  loadListings: ({ categoryId }) =>
    dispatch(
      Actions.thunkLoadListing({
        categoryId,
      })
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(ListDetail);

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  categoriesContainer: {
    backgroundColor: "#ebecf0",
    padding: "24px 25px 24px 25px",
  },
  breadcrumbs: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      color: "rgb(255, 192, 1)",
      textDecoration: "underline",
    },
  },
});
