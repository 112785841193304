import Backdrop from "@mui/material/Backdrop";
import { TextField, Button, Typography } from "@mui/material";
import {
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
  Dialog,
} from "@mui/material";
import { useState } from "react";
import axios from "axios";
import config from "../../config";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";

const FacebookEmail = ({
  open,
  handleClose,
  jwtToken,
  setEmailModalOpen,
  setUser,
}) => {
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const handleSubmit = async () => {
    if (email.length === 0) {
      setErrorEmail("Email is required");
      return;
    }

    let registerRequest;

    try {
      registerRequest = await axios.patch(
        `${config.SERVER_URL}/api/users`,
        {
          user: {
            email: email,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      const { data: registerRequestData } = registerRequest;
      console.log("Register request: ", registerRequestData);
      if (registerRequest.status === 200) {
        setUser({
          user: registerRequestData.data,
          token: jwtToken,
        })
        setEmailModalOpen(false);
        handleClose();
      }
    } catch ({ response }) {
      registerRequest = response;
      if (registerRequest.status === 422) {
        setErrorEmail(Object.keys(registerRequest.data.errors) +
          " " +
          Object.values(registerRequest.data.errors));
      }
    }
  };

  const handleEmailChange = (e) => {
    if (emailRegex.test(e.target.value)) {
      setErrorEmail("");
      setEmail(e.target.value);
    } else {
      setErrorEmail("Email is not valid. i.e example@email.com");
    }
  };
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Dialog
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            width: { md: "60%", xs: "90%" },
            height: { md: "35%", xs: "40%", sm: "25%" },
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          Email Required
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Please enter your email to continue
          </Typography>
          <TextField
            placeholder="Enter your Email"
            type="email"
            size="small"
            helperText={
              errorEmail && (
                <span style={{ color: "red" }}>{errorEmail}</span>
              )
            }
            fullWidth
            onChange={handleEmailChange}
            sx={{ backgroundColor: "white" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            disabled={errorEmail && !(email.length > 0) && true}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Backdrop>
  );
};

const mapStateToProps = ({ Auth }) => ({
  jwtToken: Auth.jwtToken
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch(Actions.setUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacebookEmail);
