export const navItems = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Classes",
    path: "/classes",
  },
  {
    name: "Services",
    path: "/services",
  },
  {
    name: "Shop",
    path: "/shop",
  },
  {
    name: "Media",
    path: "/media",
  },
  {
    name: "Education",
    path: "https://education.mooreexotics.com/",
  },
  {
    name: "Neighborhood",
    path: "/neighbour",
  },
  {
    name: "Listing",
    path: "/listings",
  },
  {
    name: "About Us",
    path: "/about",
  },

  {
    name: "Contact Us",
    path: "/contact",
  },
  {
    name: "Pricing",
    path: "/pricing",
  },
];
