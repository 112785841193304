import React from "react";
import { Stack, Typography, Box, Grid, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SwiperCore, { Autoplay, Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

const Crew = () => {
  const classes = useStyles();
  SwiperCore.use([Autoplay]);

  return (
    <Stack className={classes.mainContainer}>
      <Box className={classes.headerBox}>
        <Typography
          sx={{
            fontSize: {
              xs: "20px",
              sm: "36px",
              md: "44px",
              lg: "48px",
              xl: "54px",
            },
            fontWeight: "700",
          }}
        >
          The Crew
        </Typography>
      </Box>
      <Grid container direction="row" sx={{ display: "flex", paddingTop: 5 }}>
        <Swiper
          style={{
            "--swiper-navigation-color": "rgb(255, 192, 1)",
            "--swiper-pagination-color": "rgb(255, 192, 1)",
          }}
          navigation={true}
          modules={[Navigation, Scrollbar]}
          grabCursor={true}
          autoplay={true}
          //centeredSlides={true}
          loop={true}
          slidesPerView={4}
          breakpoints={{
            320: { slidesPerView: 1 },
            480: { slidesPerView: 1 },
            600: { slidesPerView: 1 },
            900: { slidesPerView: 3 },
            1200: { slidesPerView: 4 },
          }}
        >
          {crews &&
            crews
              // .slice()
              // .splice(0, Math.ceil(crews.length / 2))
              .map((crew) => {
                return (
                  <SwiperSlide>
                    <Grid
                      item
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                    >
                      <Box>
                        <Avatar
                          alt={crew.image}
                          src={crew.image}
                          sx={{
                            width: "200px",
                            height: "200px",
                          }}
                        ></Avatar>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: { xs: "18px", sm: "24px" },
                          color: "#000",
                          fontWeight: "700",
                        }}
                      >
                        {crew.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000",
                          textAlign: "center",
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "14px",
                            lg: "16px",
                            xl: "18px",
                          },
                          padding: {
                            xs: "0px 100px 0px 100px",
                            sm: "0px 200px 0px 200px",
                            md: "0px 10px 0px 10px",
                            lg: "0px 20px 0px 20px",
                            xl: "0px 30px 0px 30px",
                          },
                        }}
                      >
                        {crew.description}
                      </Typography>
                    </Grid>
                  </SwiperSlide>
                );
              })}
        </Swiper>
      </Grid>
    </Stack>
  );
};

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  mainContainer: {
    backgroundColor: "#D3D3D3",
    width: "100%",
    paddingTop: "60px",
    paddingBottom: "60px",
  },
  headerBox: {
    color: "#000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const crews = [
  {
    image: "https://stagingappdata.s3.amazonaws.com/crew/image.png",
    title: "Noah Watkins",
    description: "CEO - Founder",
  },
  {
    image: "https://stagingappdata.s3.amazonaws.com/crew/faseeh.jpg",
    title: "Muhammad Faseeh",
    description: "Project Lead",
  },
  {
    image:
      "https://res.cloudinary.com/dzsz395eb/image/upload/v1694612934/profile-pic_1_k3um2z.png",
    title: "Hamza Soban",
    description: "Technical Lead",
  },
  {
    image: "https://stagingappdata.s3.amazonaws.com/crew/maaz.jpeg",
    title: "Maaz Shah",
    description: "Backend Engineer",
  },
  {
    image:
      "https://res.cloudinary.com/dzsz395eb/image/upload/v1694536407/WhatsApp_Image_2023-09-12_at_9.24.03_PM_rxg9pe.jpg",
    title: "Ayiza Asghar",
    description: "Frontend Engineer",
  },

  {
    image: "https://stagingappdata.s3.amazonaws.com/crew/rida.jpg",
    title: "Rida Ejaz",
    description: "Animal Researcher",
  },
  {
    image: "https://stagingappdata.s3.amazonaws.com/crew/ibrahim.jpeg",
    title: "Muhammad Ibrahim",
    description: "Lead Designer",
  },
  {
    image: "https://stagingappdata.s3.amazonaws.com/crew/adnan.jpeg",
    title: "M. Adnan",
    description: "Creative Director",
  },
];

export default Crew;
