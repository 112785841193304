import React from "react";
import { Typography, Box, Grid } from "@mui/material";

import SwiperCore, { Autoplay, Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

const KnowUs = () => {
  SwiperCore.use([Autoplay]);
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ textAlign: "center", paddingTop: 8 }}
      >
        <Typography
          sx={{
            color: "#000",
            fontSize: {
              xs: "20px",
              sm: "36px",
              md: "44px",
              lg: "48px",
              xl: "54px",
            },
            textAlign: "center",
            fontWeight: "700",
          }}
        >
          Get To Know Us
        </Typography>
      </Box>
      <Grid
        container
        direction="row"
        spacing={4}
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          padding: 5,
          paddingBottom: 8,
        }}
      >
        {knowUs &&
          knowUs.map((item) => {
            return (
              <Grid
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
                direction="column"
                xs={12}
                sm={12}
                md={3}
              >
                <Box>
                  <img
                    alt="certificate"
                    src={window.location.origin + "/images/know-us.png"}
                    style={{ width: "75px" }}
                  ></img>
                </Box>
                <Typography
                  sx={{
                    fontSize: { xs: "18px", sm: "24px" },
                    color: "#000",
                    fontWeight: "700",
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "16px",
                      xl: "18px",
                    },
                    padding: {
                      sm: "0px 100px 0px 100px",
                      md: "0px 10px 0px 10px",
                      lg: "0px 20px 0px 20px",
                      xl: "0px 30px 0px 30px",
                    },
                  }}
                >
                  {item.description}
                </Typography>
              </Grid>
            );
          })}
      </Grid>
      <Grid
        container
        direction="row"
        sx={{
          display: { xs: "flex", sm: "flex", md: "none" },
          paddingBottom: 8,
          paddingTop: 5,
        }}
      >
        <Swiper
          style={{
            "--swiper-navigation-color": "rgb(255, 192, 1)",
            "--swiper-pagination-color": "rgb(255, 192, 1)",
          }}
          navigation={true}
          modules={[Navigation, Scrollbar]}
          grabCursor={true}
          autoplay={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={1}
        >
          {knowUs &&
            knowUs.map((item) => {
              return (
                <SwiperSlide>
                  <Grid
                    item
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    xs={12}
                    sm={12}
                    md={0}
                  >
                    <Box>
                      <img
                        alt="certificate"
                        src={window.location.origin + "/images/know-us.png"}
                        style={{ width: "75px" }}
                      ></img>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "18px", sm: "24px" },
                        color: "#000",
                        fontWeight: "700",
                      }}
                    >
                      {item.tittle}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "14px",
                          lg: "16px",
                          xl: "18px",
                        },
                        padding: {
                          xs: "0px 100px 0px 100px",
                          sm: "0px 200px 0px 200px",
                          md: "0px 10px 0px 10px",
                          lg: "0px 20px 0px 20px",
                          xl: "0px 30px 0px 30px",
                        },
                      }}
                    >
                      {item.description}
                    </Typography>
                  </Grid>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </Grid>
    </>
  );
};

const knowUs = [
  {
    title: "We’re Pet Owners",
    description:
      "As fellow pet owners, we know what it takes to care for an animal, especially an exotic one. Who better to turn to than experts like us.",
  },
  {
    title: "We support businesses",
    description:
      "We want to make it easy and accessible for you to build your business with us.",
  },
  {
    title: "We create connections",
    description:
      "With so many different people using our platform we want to help create numerous connections within our growing neighbour.",
  },
  {
    title: "We Care",
    description:
      "No matter what side you're on, we will always have your best interest in discovering what you need from our neighbour.",
  },
];

export default KnowUs;
