import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Typography,
  Snackbar,
  Slide,
  Alert,
  Menu,
  MenuItem,
} from "@mui/material";
import CustomModal from "../../../components/seller/listings/Modal";
import { makeStyles, withStyles } from "@mui/styles";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../utils/Utils";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import axios from "axios";
import config from "../../../config";
import { connect } from "react-redux";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    color: "inherit",
  },
})(MuiTableCell);

const TableRow = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    cursor: "pointer",
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#aaaaaa" },
  },
})(MuiTableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 8px 8px 8px",
    margin: "10px 10px 10px 10px",
    overflow: "auto",
    borderRadius: "10px",
    height: "95vh",
  },
  table: {
    padding: 0,
    margin: 0,
    borderCollapse: "separate",
    borderSpacing: "0px 9px",
    backgroundColor: "white",
  },
  hover: {
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#eeeeee" },
  },
  featuredRrow: {
    backgroundColor: "#FFF2CA",
  },
}));

const ListingTableHeadings = [
  "",
  "Name",
  "Category",
  "SubCategory",
  "Status",
  "Price",
  "Actions",
];

const ServiceList = ({
  seller,
  jwtToken,
  setServiceListings,
  serviceListings,
  handleUpdateStatus,
  setConfirmProp2,
  handleCloseActionModal,
  openActionModal,
  setOpenActionModal,
  handleOpenFeaturedModal,
}) => {
  const classes = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuData, setMenuData] = useState(null);
  const [confirmProp, setConfirmProp] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [deleteListId, setDeleteListId] = useState();
  const [processing, setProcessing] = useState(false);

  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const getTimeElapsed = (featuredAt) => {
    const currentTime = new Date();
    const createdTime = new Date(featuredAt);
    const timeDifference = currentTime - createdTime;
    const hoursElapsed = Math.floor(timeDifference / (1000 * 60 * 60));
    return hoursElapsed;
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteListId(id);
    setOpenDeleteModal(true);
  };

  // const getIconColor = (data) => {
  //   const hoursElapsed = getTimeElapsed(data.featured_at);
  //   if (hoursElapsed > 18 && hoursElapsed < 24) {
  //     return "#FFC001";
  //   } else if (hoursElapsed >= 24) {
  //     return "red";
  //   }
  //   return "green";
  // };

  const handleMenuClick = (event, data) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpen(true);
    setMenuData(data);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
    setMenuData(null);
  };

  useEffect(() => {
    if (confirmProp) {
      const deleteListing = async () => {
        setProcessing(true);
        let deleteRequest;
        try {
          deleteRequest = await axios.delete(
            `${config.SERVER_URL}/api/v1/listing_services/${deleteListId}`,
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          );
          if (deleteRequest.status === 200) {
            setProcessing(false);
            setConfirmProp(false);
            setOpenDeleteModal(false);
            setSnackbarStates({
              open: true,
              message: "Listing deleted successfully",
              severity: "success",
            });
            setServiceListings(
              serviceListings.filter((item) => item.id !== deleteListId)
            );
          }
        } catch (error) {
          setProcessing(false);
          setConfirmProp(false);
          setOpenDeleteModal(false);
          setSnackbarStates({
            open: true,
            message: "Something went wrong",
            severity: "error",
          });
        }
      };

      deleteListing();
    }
  }, [confirmProp]);

  const handleMarkAsUnavailable = (data) => {
    handleUpdateStatus(data);
    setModalText("Are you sure you want to mark the service as unavailable?");
    setOpenActionModal(true);
    handleMenuClose();
  };

  const handleMarkAsActive = (data) => {
    handleUpdateStatus(data);
    setModalText("Are you sure you want to mark the service as active?");
    setOpenActionModal(true);
    handleMenuClose();
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarStates({ ...snackbarStates, open: false });
  };

  const handleFeaturedListing = async (data) => {
    handleMenuClose();
    handleOpenFeaturedModal(data);
  };

  return (
    <>
      <Snackbar
        transitionDuration={{ enter: 1000, exit: 2000 }}
        TransitionComponent={Slide}
        TransitionProps={{ enter: true, exit: false }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarStates.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <CustomModal
        title="Service Alert"
        content={modalText}
        openModal={openActionModal}
        closeModal={handleCloseActionModal}
        actionTitle={"Confirm"}
        setConfirmProp={setConfirmProp2}
        actionColor={"green"}
      />

      <CustomModal
        title="Delete Alert"
        content={
          "Are you sure you want to delete this service? This action is irreversible."
        }
        openModal={openDeleteModal}
        closeModal={handleCloseDeleteModal}
        actionTitle={"Delete"}
        setConfirmProp={setConfirmProp}
        actionColor={"red"}
      />
      <Paper className={classes.root} elevation={2}>
        {serviceListings && serviceListings.length > 0 ? (
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                {ListingTableHeadings.map((item) => (
                  <TableCell
                    align="center"
                    sx={{
                      fontSize: { md: "14px", xl: "16px" },
                      fontWeight: "bold",
                    }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceListings.map((data) => (
                <TableRow
                  key={data.name}
                  className={data.is_featured ? classes.featuredRrow : ""}
                >
                  {data.media && data.media[0].url && (
                    <TableCell align="left" style={{ width: 0, padding: "5px" }}>
                      <Avatar
                        width={50}
                        height={50}
                        alt={data.name}
                        src={data.media && data.media?.[0]?.url}
                      />
                    </TableCell>
                  )}
                  <TableCell align="center" sx={{ width: "8%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {data.name}
                      {data.is_featured === true && (
                        <WorkspacePremiumIcon
                          size="small"
                          sx={{ ml: 1, mt: 0.2 }}
                          color="primary"
                        // style={{
                        //   color: getIconColor(data),
                        // }}
                        />
                      )}
                    </div>
                  </TableCell>

                  <TableCell align="center">{data.service.name}</TableCell>

                  <TableCell align="center">{data.sub_service.name}</TableCell>
                  <TableCell align="center">
                    {data.status === "active" ? "Active" : "Un-Available"}
                  </TableCell>

                  <TableCell align="center">${data.price}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      component={Link}
                      to={`/seller/service/edit/${data.id}`}
                      sx={{
                        "&:hover": {
                          bgcolor: "rgb(255, 192, 1)",
                          color: "black",
                        },
                      }}
                    >
                      <BorderColorIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                      onClick={() => handleOpenDeleteModal(data.id)}
                      sx={{
                        "&:hover": {
                          bgcolor: "rgb(255, 192, 1)",
                          color: "black",
                        },
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-controls={`menu-${data.id}`}
                      aria-haspopup="true"
                      onClick={(event) => handleMenuClick(event, data)}
                      sx={{
                        "&:hover": {
                          bgcolor: "rgb(255, 192, 1)",
                          color: "black",
                        },
                      }}
                    >
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                    <Menu
                      id={`menu-${data.id}`}
                      anchorEl={menuAnchorEl}
                      open={menuOpen && menuData?.id === data.id}
                      onClose={handleMenuClose}
                    >
                      {!processing && (
                        <>
                          <MenuItem
                            onClick={() => {
                              if (data.status !== "active") {
                                handleMarkAsActive(data);
                              } else {
                                handleMarkAsUnavailable(data);
                              }
                            }}
                          >
                            {data.status === "active"
                              ? "Mark as unavailable"
                              : "Mark as active"}
                          </MenuItem>

                          {!data.is_featured && data.status === "active" && (
                            <MenuItem
                              onClick={() => handleFeaturedListing(data)}
                            >
                              Add as Featured
                            </MenuItem>
                          )}
                        </>
                      )}
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            sx={{ height: "80vh" }}
            justifyContent="center"
            flexDirection="column"
          >
            <Typography variant="p" sx={{ fontSize: "14px" }}>
              You don't have any services. Please create some.
            </Typography>
            <Button
              component={Link}
              to={"/seller/service/create"}
              disableRipple
              sx={{
                mt: 2,
              }}
              variant="outlined"
            >
              Add a listing
            </Button>
          </Box>
        )}
      </Paper>
    </>
  );
};

const mapStateToProps = ({ Auth, Seller }) => ({
  jwtToken: Auth.jwtToken,
  user: Auth.user,
  seller: Seller.seller,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceList);
