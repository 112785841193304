import {
  Card,
  CardContent,
  CardMedia,
  Box,
  Container,
  Button,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { imageListItemClasses } from "@mui/material/ImageListItem";
// import * as Actions from "../../redux/actions";
import * as Actions from "../../redux/actions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ServiceListCard from "../serviceListings/ServiceListCard";

const RecentBlogBox = styled(Box)`
  background: #000000;
  padding: 50px 0 50px 0;

  @media screen and (max-width: 769px) {
    .TopBlogTitle {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.02em;
      margin-bottom: 5px;
    }
    .RecentAddedTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 33px;
      text-align: center;
      margin-bottom: 22px;
    }
    .RecentCardsTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #ffffff;
    }
    .ViewMoreBtn button {
      position: absolute;
      bottom: 50px;
      left: 0;
      right: 0;
      margin: auto;
      float: none;
      width: 150px;
    }
  }
`;

const RecentlyAdded = styled(Box)`
  @media screen and (max-width: 1550px) {
    .RecentAddedTitle {
      font-size: 44px;
      line-height: 56px;
    }
  }
  @media screen and (max-width: 1250px) {
    .RecentAddedTitle {
      font-size: 34px;
      line-height: 40px;
    }
  }
`;

const Blogs = ({ loadServicesListing, serviceListing, jwtToken }) => {
  console.log("listing", serviceListing);
  const is_Featured = true;
  const navigate = useNavigate();

  const handleShowAllFeaturedServiceListings = () => {
    navigate(`/services/listings?is_featured=true`);
  };
  useEffect(() => {
    loadServicesListing({ is_Featured, jwtToken });
  }, []);

  return (
    <>
      {serviceListing.length > 0 && (
        <>
          <RecentBlogBox className="RecentBlog">
            <Container fixed maxWidth={false}>
              <RecentlyAdded sx={{ color: "white" }}>
                <Typography
                  className="RecentAddedTitle"
                  variant="h2"
                  component="h2"
                  sx={{
                    fontWeight: "700",
                    fontSize: "50px",
                    lineHeight: "66px",
                    textAlign: "center",
                    marginBottom: "45px",
                  }}
                >
                  Featured Services Listings
                </Typography>
              </RecentlyAdded>
              <Grid container spacing={3}>
                <Grid
                  sx={{
                    width: "100%",
                    backgroundColor: "transparent",
                    mt: 2,
                    display: "grid",
                    gap: 2,
                    gridTemplateColumns: {
                      xs: "repeat(2, minmax(0, 1fr))",
                      sm: "repeat(3, minmax(0, 1fr))",
                      md: "repeat(3, minmax(0, 1fr))",
                      lg: `repeat(${
                        serviceListing.length > 4 ? "5" : "5"
                      }, minmax(0, 1fr))`,
                    },
                    [`& .${imageListItemClasses.root}`]: {
                      display: "flex",
                      flexDirection: "column",
                    },
                  }}
                >
                  <ServiceListCard listData={serviceListing.slice(0, 5)} />
                </Grid>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  {serviceListing.length > 0 && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        component={Button}
                        size="large"
                        variant="contained"
                        onClick={handleShowAllFeaturedServiceListings}
                        sx={{
                          textTransform: "none",
                          fontWeight: "bold",
                          marginTop: "30px",
                        }}
                      >
                        See Similar Featured Listings
                      </Box>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Container>
          </RecentBlogBox>
        </>
      )}
    </>
  );
};
const mapStateToProps = ({ Services, Listings, Auth }) => ({
  subServices: Services.subServices,
  services: Services.services,
  serviceListing: Listings.serviceListing,
  jwtToken: Auth.jwtToken,
});
const mapDispatchToProps = (dispatch) => ({
  loadServicesListing: ({
    service_id,
    sub_service_id,
    seller_id,
    category_id,
    is_Featured,
    jwtToken,
  }) =>
    dispatch(
      Actions.thunkLoadServiceListings({
        service_id,
        sub_service_id,
        seller_id,
        is_Featured,
        category_id,
        jwtToken,
      })
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
