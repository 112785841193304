import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import config from "../../../config";
import {
  Avatar,
  Box,
  MenuItem,
  Select,
  Typography,
  Alert,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { capitalizeFirstLetter } from "../../../utils/Utils";
import moment from "moment";

import Listing from "../../../pages/seller/listing/Listing";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    color: "inherit",
  },
})(MuiTableCell);

const TableRow = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    cursor: "pointer",
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#aaaaaa" },
  },
})(MuiTableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100",
    maxHeight: "85vh",
    minHeight: "50vh",
    padding: "0 8px 8px 8px",
    margin: "10px 10px 10px 0px",
    overflow: "auto",
    borderRadius: "10px",
  },
  table: {
    padding: 0,
    margin: 0,
    borderCollapse: "separate",
    borderSpacing: "0px 9px",
    backgroundColor: "white",
  },
  hover: {
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#eeeeee" },
  },
}));

const SoldItemTable = ({ seller }) => {
  const filters = [
    { name: "Last 1 day", value: 1 },
    { name: "Last 7 days", value: 7 },
    { name: "Last 15 days", value: 15 },
  ];
  const navigate = useNavigate();
  const SoldItemTableHeadings = [
    "",
    "Name",
    "Category",
    "LastUpdated",
    "Price",
    "Status",
  ];
  const classes = useStyles();
  const [filter, setFilter] = useState(filters[2].value);
  const [listings, setListings] = useState([]);
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const loadFilteredListings = async () => {
    let registerRequest;
    try {
      registerRequest = await axios.get(
        `${config.SERVER_URL}/api/v1/seller/${seller.id}/animals?filter_days=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 200) {
        setListings(registerRequestData.data);
      }
    } catch (err) {
      setSnackbarStates({
        open: true,
        message: "Unable to fetch seller content now",
        severity: "danger",
      });
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
    navigate("/");
  };
  useEffect(() => {
    loadFilteredListings();
  }, [filter, seller]);

  const handleOpenListing = (id) => {
    window.open(`/listing/${id}`, "_blank");
  };

  return (
    <>
      <Snackbar
        open={snackbarStates.open}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <Paper className={classes.root} elevation={2}>
        <Box
          px="5px"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "5px",
            border: "none",
          }}
        >
          <Typography sx={{ paddingTop: "5px", paddingLeft: "5px" }}>
            Recent Animal Listings
          </Typography>
          <Select
            value={filter}
            size="small"
            disableUnderline
            onChange={(e) => setFilter(e.target.value)}
            className={classes.hover}
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
          >
            {filters.map((filter) => (
              <MenuItem
                key={filter.value}
                value={filter.value}
                sx={{ fontSize: "14px" }}
              >
                {filter.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {listings.length > 0 ? (
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                {SoldItemTableHeadings.map((item) => (
                  <TableCell
                    align="center"
                    sx={{
                      fontSize: { md: "14px", xl: "16px" },
                      fontWeight: "bold",
                    }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {listings.map((data) => (
                <TableRow
                  key={data.animal}
                  onClick={() => handleOpenListing(data.id)}
                >
                  {data.media && data.media?.[0]?.url && (
                    <TableCell align="left" style={{ width: 0, padding: "5px" }}>
                      <Avatar
                        width={40}
                        height={40}
                        alt={data.animal}
                        src={data.media?.[0]?.url}
                      />
                    </TableCell>
                  )}
                  <TableCell align="center" style={{ width: 0, padding: 0 }}>
                    {data.name}
                  </TableCell>
                  <TableCell align="center">{data.category.name}</TableCell>
                  <TableCell align="center">
                    {" "}
                    {moment(data.last_updated).fromNow()}
                  </TableCell>
                  <TableCell align="center">${data.price}</TableCell>
                  {data.status.split("_")[0] === "nfs" ? (
                    <TableCell align="center">NFS</TableCell>
                  ) : (
                    <TableCell align="center">
                      {capitalizeFirstLetter(data.status.split("_")[0])}
                    </TableCell>
                  )}{" "}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography textAlign={"center"} sx={{ fontWeight: "bold" }}>
            {" "}
            No listings found
          </Typography>
        )}
      </Paper>
    </>
  );
};

const mapStateToProps = ({ Seller }) => ({
  seller: Seller.seller,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SoldItemTable);
