import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Snackbar,
  Typography,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../utils/Utils";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import axios from "axios";
import config from "../../../config";
import { connect } from "react-redux";
import CustomModal from "./Modal";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    color: "inherit",
  },
})(MuiTableCell);

const TableRow = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    cursor: "pointer",
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#aaaaaa" },
  },
})(MuiTableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 8px 8px 8px",
    margin: "10px 10px 10px 10px",
    overflow: "auto",
    borderRadius: "10px",
    height: "95vh",
  },
  table: {
    padding: 0,
    margin: 0,
    borderCollapse: "separate",
    borderSpacing: "0px 9px",
    backgroundColor: "white",
  },
  hover: {
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#eeeeee" },
  },
  featuredRrow: {
    backgroundColor: "#FFF2CA",
  },
}));

const ListingTableHeadings = [
  "",
  "Name",
  "Category",
  "Last Updated",
  "Price",
  "Status",
  "Actions",
];

const ListingsList = ({
  handleOpenRemoveNFSModal,
  handleOpenDeleteModal,
  handleOpenAddToNFSModal,
  handleOpenAddToSoldModal,
  handleRemoveSoldModal,
  setOnHoldModalText,
  setOnHoldDialogText,
  data,
  classes,
  jwtToken,
  setListings,
  user,
  loadListings,
  handleOpenFeaturedModal,
  handleOpenMarkAsActiveModal,
  handleOpenHoldModal,
}) => {
  const styleclasses = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuData, setMenuData] = useState(null);
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [confirmProp, setConfirmProp] = useState(false);

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  useEffect(() => {
    if (confirmProp) {
      window.location.href = user.billingPortalUrl;
    }
  }, [confirmProp]);

  const [processing, setProcessing] = useState(false);

  const findCategoryName = (id) => {
    const category = classes.filter((cat) => cat.id === parseInt(id))[0];
    if (category) {
      return category.name;
    } else {
      return "N/A";
    }
  };

  const getTimeElapsed = (featuredAt) => {
    const currentTime = new Date();
    const createdTime = new Date(featuredAt);
    const timeDifference = currentTime - createdTime;
    const hoursElapsed = Math.floor(timeDifference / (1000 * 60 * 60));
    return hoursElapsed;
  };

  const getIconColor = (data) => {
    const hoursElapsed = getTimeElapsed(data.featured_at);
    if (hoursElapsed > 18 && hoursElapsed < 24) {
      return "#FFC001";
    } else if (hoursElapsed >= 24) {
      return "red";
    }
    return "green";
  };

  const handleMarkAsActive = async (data) => {
    handleMenuClose();
    handleOpenMarkAsActiveModal(data);
  };

  const handleFeaturedListing = async (data) => {
    handleMenuClose();
    handleOpenFeaturedModal(data);
  };

  const handleMarkAsSold = async (data) => {
    setProcessing(true);
    let registerRequest;
    try {
      registerRequest = await axios.patch(
        `${config.SERVER_URL}/api/v1/animals/${data.id}`,
        {
          animal: {
            is_sold: !data.is_sold,
          },
          seller_id: data.seller_id,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data; ",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
    } catch ({ response }) {
      registerRequest = response;

      if (registerRequest.status === 422) {
      }
      setProcessing(false);
    }

    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 201 || registerRequest.status === 200) {
      loadListings();

      setSnackbarStates({
        open: true,
        message: registerRequestData.message,
        severity: "success",
      });
      setProcessing(false);
      handleMenuClose();
    }
  };

  const handleOpenSoldModal = async (data) => {
    handleOpenAddToSoldModal(data);
    handleMenuClose();
  };
  const handleCloseSoldModal = async (data) => {
    handleRemoveSoldModal(data);
    handleMenuClose();
  };

  const handleMarkAsNotForSale = async (data) => {
    handleOpenAddToNFSModal(data);
    handleMenuClose();
  };

  const handleNFS = (data) => {
    if (data.status === "nfs") {
      handleMenuClose();
      handleOpenRemoveNFSModal(data);
    } else {
      handleMarkAsNotForSale(data);
    }
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarStates({ ...snackbarStates, open: false });
  };

  const handleMenuClick = (event, data) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpen(true);
    setMenuData(data);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
    setMenuData(null);
  };

  const handleMarkAsHold = async (data) => {
    handleMenuClose();
    handleOpenHoldModal(data);
  };

  const handleMarkListingAsHold = (data) => {
    handleMarkAsHold(data);
    setOnHoldModalText("Remove from hold");
    setOnHoldDialogText(
      "Are you sure you want to remove from on-Hold? You can either Mark as Sold for this listing or set it to Active Listing."
    );
  };

  const handleRemoveListingAsHold = (data) => {
    handleMarkAsHold(data);
    setOnHoldModalText("Add to hold");
    setOnHoldDialogText(
      "Are you sure you want to put this listing on hold. This listing will still be visible to buyers indicating the deal is already in progress."
    );
  };

  return (
    <>
      <Snackbar
        transitionDuration={{ enter: 1000, exit: 2000 }}
        TransitionComponent={Slide}
        TransitionProps={{ enter: true, exit: false }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarStates.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <Paper className={styleclasses.root} elevation={2}>
        {data && data.length > 0 ? (
          <Table stickyHeader className={styleclasses.table}>
            <TableHead>
              <TableRow>
                {ListingTableHeadings.map((item) => (
                  <TableCell
                    align="center"
                    sx={{
                      fontSize: { md: "14px", xl: "16px" },
                      fontWeight: "bold",
                    }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((data) => (
                <TableRow
                  key={data.name}
                  className={data.is_featured ? styleclasses.featuredRrow : ""}
                >
                  {data.media && data.media[0]?.url && (
                    <TableCell align="left" style={{ width: 0, padding: "5px" }}>
                      <Avatar
                        width={50}
                        height={50}
                        alt={data.name}
                        src={data.media[0]?.url}
                      />
                    </TableCell>
                  )}
                  <TableCell style={{ width: "8%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography>{data.name}</Typography>
                      {data.is_featured === true && (
                        <WorkspacePremiumIcon
                          size="small"
                          sx={{ ml: 1, mt: 0.2 }}
                          color="primary"
                          style={{
                            color: getIconColor(data),
                          }}
                        />
                      )}
                      {data.on_hold === true && (
                        <PauseCircleOutlineIcon
                          size="small"
                          sx={{ ml: 1, mt: 0.2 }}
                          color="primary"
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    {findCategoryName(data.category_id)}
                  </TableCell>
                  <TableCell align="center">
                    {moment(data.last_updated).fromNow()}
                  </TableCell>
                  <TableCell align="center">{"$" + data.price}</TableCell>
                  {data.status.split("_")[0] === "nfs" ? (
                    <TableCell align="center">NFS</TableCell>
                  ) : (
                    <TableCell align="center">
                      {capitalizeFirstLetter(data.status.split("_")[0])}
                    </TableCell>
                  )}
                  <TableCell align="center">
                    <IconButton
                      component={Link}
                      to={`/seller/listing/edit/${data.id}`}
                      sx={{
                        "&:hover": {
                          bgcolor: "rgb(255, 192, 1)",
                          color: "black",
                        },
                      }}
                    >
                      <BorderColorIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                      onClick={() => handleOpenDeleteModal(data.id)}
                      sx={{
                        "&:hover": {
                          bgcolor: "rgb(255, 192, 1)",
                          color: "black",
                        },
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                      aria-controls={`menu-${data.id}`}
                      aria-haspopup="true"
                      onClick={(event) => handleMenuClick(event, data)}
                      sx={{
                        "&:hover": {
                          bgcolor: "rgb(255, 192, 1)",
                          color: "black",
                        },
                      }}
                    >
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                    <Menu
                      id={`menu-${data.id}`}
                      anchorEl={menuAnchorEl}
                      open={menuOpen && menuData?.id === data.id}
                      onClose={handleMenuClose}
                    >
                      {!processing &&
                        !data.is_featured &&
                        data.status === "active_listing" &&
                        !data.on_hold && (
                          <MenuItem onClick={() => handleFeaturedListing(data)}>
                            Add as Featured
                          </MenuItem>
                        )}
                      {!processing && data.status === "unavailable" && (
                        <MenuItem onClick={() => handleMarkAsActive(data)}>
                          Mark as active
                        </MenuItem>
                      )}
                      {processing ? (
                        <MenuItem
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <CircularProgress
                            size="24px"
                            sx={{ color: "rgb(255 192 1)" }}
                          />
                        </MenuItem>
                      ) : (
                        data.status === "active_listing" && (
                          <>
                            <MenuItem onClick={() => handleOpenSoldModal(data)}>
                              Mark as Sold
                            </MenuItem>
                            {data.is_featured !== true && (
                              <MenuItem
                                onClick={() => handleMarkAsNotForSale(data)}
                              >
                                Mark as NFS
                              </MenuItem>
                            )}
                            {data.on_hold ? (
                              <MenuItem
                                onClick={() => handleMarkListingAsHold(data)}
                              >
                                Remove from hold
                              </MenuItem>
                            ) : (
                              <MenuItem
                                onClick={() => handleRemoveListingAsHold(data)}
                              >
                                Mark on Hold
                              </MenuItem>
                            )}
                          </>
                        )
                      )}

                      {data.status === "nfs" && (
                        <MenuItem onClick={() => handleNFS(data)}>
                          Remove from NFS
                        </MenuItem>
                      )}

                      {data.status === "sold" && (
                        <MenuItem onClick={() => handleCloseSoldModal(data)}>
                          Remove from sold
                        </MenuItem>
                      )}

                      {data.status === "pending" && (
                        <MenuItem disabled>Pending Listing</MenuItem>
                      )}
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            sx={{ height: "80vh" }}
            justifyContent="center"
            flexDirection="column"
          >
            <Typography variant="p" sx={{ fontSize: "14px" }}>
              You don't have any listing. Please create some.
            </Typography>
            <Button
              component={Link}
              to={"/seller/listing/create"}
              disableRipple
              sx={{
                mt: 2,
              }}
              variant="outlined"
            >
              Add a listing
            </Button>
          </Box>
        )}
      </Paper>
    </>
  );
};

const mapStateToProps = ({ Auth }) => ({
  jwtToken: Auth.jwtToken,
  user: Auth.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListingsList);
