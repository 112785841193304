import * as Types from "../types";

const preState = {
  services: [],
  subServices: [],
  loading: false,
  error: "",
};

const ServicesReducer = (state = preState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.LOAD_SERVICES:
      return {
        ...state,
        services: payload.services,
        loading: payload.loading,
        error: payload.error,
      };
    case Types.LOAD_SUB_SERVICES:
      return {
        ...state,
        subServices: payload.subServices,
        loading: payload.loading,
      };
    case Types.SET_SERVICE_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default ServicesReducer;
