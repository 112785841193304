import React from "react";
import { Box, Typography } from "@mui/material";
import { Privacy } from "./Privacy";
const PrivacyComponent = () => {
  return (
    <Box className="App" sx={{ display: "column" }}>
      <Box px={{ md: 15, xs: 2 }} pt={5}>
        <Typography
          textAlign={"center"}
          fontWeight={"bold"}
          fontSize={30}
          fontStyle={"Times New Roman"}
        >
          {" "}
          Privacy Policy{" "}
        </Typography>
        <Typography
          textAlign={"center"}
          fontWeight={"bold"}
          fontSize={30}
          fontStyle={"Times New Roman"}
        >
          {" "}
        </Typography>
        {Privacy[0].pages.map((page) =>
          page.columns.map((column, index) =>
            column.paragraphs.map((paragraph, index) => (
              <Box>
                {paragraph.lines.map((line, index) =>
                  line.words.map((word, index) => (
                    // {word.text === "●" && "\n"}

                    <Typography
                      component={"span"}
                      key={index}
                      sx={{
                        left: `${word.left}px`,
                        top: `${word.top}px`,
                        width: `${word.width}px`,
                        height: `${word.height}px`,
                        color: word.color,
                        fontFamily: word.fontName,
                        fontSize: "20px",
                        fontWeight:
                          word.underline === "1" || word.fontWeight === "bold"
                            ? "bold"
                            : "none",
                        textDecoration:
                          word.underline === "1" ? "underline" : "none",
                        direction: word.dir,
                        transform: `rotate(${word.angle}deg)`,
                      }}
                    >
                      {word.text === "●" && <br />}

                      {word.text === "●" ? word.text : word.text + " "}
                    </Typography>
                  ))
                )}
              </Box>
            ))
          )
        )}
      </Box>
    </Box>
  );
};

export default PrivacyComponent;
