import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Reviews from "./Reviews";
import {
  Box,
  Stack,
  Grid,
  Typography,
  Button,
  Breadcrumbs,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import ListImage from "../listdetails/ListImage";
import ServiceDetailCard from "./ServiceDetailCard";
import config from "../../config";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { Divider } from "@mui/material";
import AboutPolicy from "../listdetails/AboutPolicy";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import ServiceListCard from "./ServiceListCard";
import { useNavigate } from "react-router-dom";
import { imageListItemClasses } from "@mui/material/ImageListItem";

const ServicesListingDetailPage = ({
  loading,
  loadServiceListings,
  serviceListing,
  jwtToken,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { listId, serviceId } = useParams();
  const [listData, setListData] = useState();
  const [reviews, setReviews] = useState([]);

  const handleShowAllServiceListings = () => {
    navigate(`/services/listings/classes/${serviceId}`);
  };

  useEffect(() => {
    const fetchSellerReviews = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/v1/reviews?listing_service_id=${listId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        if (registerRequest && registerRequest.status === 200) {
          setReviews(registerRequest.data.reviews);
        }
      } catch (error) {
        console.log("error", error.message);
      }
    };
    if (listData) {
      fetchSellerReviews();
    }
  }, [listData]);
  useEffect(() => {
    const getListingData = async (id) => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/v1/listing_services/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const { data: registerRequestData } = registerRequest;
        if (registerRequest.status === 200) {
          setListData(registerRequestData.data);

          window.scrollTo({ top: 0 });
        }
      } catch (error) {
        console.log("error", error.message);
      }
    };

    if (listId) {
      getListingData(listId);
    }
  }, [listId]);
  useEffect(() => {
    if (listData) {
      const serviceParams = {
        seller_id: "",
        service_id: "",
        sub_service_id: "",
        category_id: listData.service_id,
      };
      loadServiceListings(serviceParams);
    }
  }, [listData]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Stack
          sx={{
            paddingX: { xs: "20px", md: "50px", lg: "150px", xl: "250px" },
            overflow: "hidden",
            bgcolor: "#ebecf0",
            paddingY: { xs: "20px", md: "50px", lg: "50px", xl: "50px" },
          }}
        >
          <Grid
            container
            md={12}
            sx={{
              height: "50vh",
              marginX: "5px",
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
            }}
          >
            {listData && (
              <>
                <Grid item md={6} sx={{ paddingX: "10px" }}>
                  <ListImage listData={[...listData.media]} />
                </Grid>
                <Grid item md={6} sx={{ paddingRight: "20px" }}>
                  <ServiceDetailCard listData={listData} />
                </Grid>
              </>
            )}
          </Grid>

          <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
          <Grid
            container
            xs={12}
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            {listData && (
              <>
                <Grid item xs={12}>
                  <ListImage listData={[...listData.media]} />
                </Grid>
                <Grid item xs={12}>
                  <ServiceDetailCard listData={listData} />
                </Grid>
              </>
            )}
          </Grid>
          {listData && (
            <Grid
              sx={{
                marginTop: "20px",
              }}
            >
              <AboutPolicy listData={listData} />
            </Grid>
          )}
          <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
          <Reviews reviews={reviews} listData={listData} />
          <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />

          {serviceListing.length > 0 && (
            <Grid>
              <Typography
                variant="h5"
                component="p"
                sx={{ fontWeight: "700", mt: 4, textAlign: "center" }}
              >
                Similar Service Listings
              </Typography>
              <Grid
                sx={{
                  width: "100%",
                  backgroundColor: "transparent",
                  mt: 2,
                  display: "grid",
                  gap: 2,
                  gridTemplateColumns: {
                    xs: "repeat(2, minmax(0, 1fr))",
                    sm: "repeat(3, minmax(0, 1fr))",
                    md: "repeat(3, minmax(0, 1fr))",
                    lg: "repeat(5, minmax(0, 1fr))",
                  },
                  [`& .${imageListItemClasses.root}`]: {
                    display: "flex",
                    flexDirection: "column",
                  },
                }}
              >
                <ServiceListCard listData={serviceListing.slice(0, 4)} />
              </Grid>
              {serviceListing.length > 0 && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    component={Button}
                    size="large"
                    variant="contained"
                    onClick={handleShowAllServiceListings}
                    // to={`/services/listings/classes/${serviceId}`}
                    sx={{
                      textAlign: "center",
                      marginTop: "30px",
                      bgcolor: "#FFC001",
                      width: { xs: "70vw", md: "20vw" },
                      height: 40,
                      color: "#fff",
                      fontSize: 15,
                      fontWeight: 700,
                      textTransform: "none !important",
                      transition: "0.3s all",
                    }}
                  >
                    See Similar Service
                  </Box>
                </Box>
              )}
            </Grid>
          )}
        </Stack>
      )}
    </>
  );
};

const mapStateToProps = ({ Listings, Auth }) => ({
  loading: Listings.loading,
  serviceListing: Listings.serviceListing,
  jwtToken: Auth.jwtToken,
});

const mapDispatchToProps = (dispatch) => ({
  loadServiceListings: ({ }) => dispatch(Actions.thunkLoadServiceListings({})),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesListingDetailPage);

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  categoriesContainer: {
    backgroundColor: "#ebecf0",
    padding: "24px 25px 24px 25px",
  },
  breadcrumbs: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      color: "rgb(255, 192, 1)",
      textDecoration: "underline",
    },
  },
});
