import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import SettingNavList from "../../components/seller/navbar/SettingNavList";
import Navbar from "../../components/navbars/Navbar";
import SettingDrawer from "../../components/seller/navbar/SettingDrawer";
import * as Actions from "../../redux/actions";
import Footer from "../../components/footer/Footer";
function SellerLayout() {
  return (
    <>
      <Grid sx={{ height: "100vh" }}>
        <Navbar />

        <Grid
          container
          md={12}
          sx={{
            backgroundColor: "#ebecf0",
            width: "100vw",
            overflowX: "hidden",
            position: "relative", // Updated: Use relative position
          }}
        >
          <Grid
            item
            md={2}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                position: "sticky",
                bgcolor: "transparent",
                zIndex: 1,
                height: "100%",
              },
            }}
          >
            <SettingNavList />
          </Grid>
          <Grid item md={10}>
            <Outlet />
          </Grid>
        </Grid>
        <Footer />
      </Grid>
    </>
  );
}

const mapStateToProps = ({ Auth, Seller }) => ({
  responseErrors: Auth.responseError,
  user: Auth.user,
  seller: Seller.seller,
});

const mapDispatchToProps = (dispatch) => ({
  getUserBillingPortal: (userId) =>
    dispatch(Actions.thunkGetUserBillingPortal(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SellerLayout);
