import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Alert,
  CardMedia,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Snackbar from "@mui/material/Snackbar";

import axios from "axios";
import config from "../../config";
import { imageListItemClasses } from "@mui/material/ImageListItem";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions/";

const Stores = ({ jwtToken }) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [paginationStates, setPaginationStates] = useState({
    page: 0,
    rowsPerPage: 10,
    total_records: 0,
  });

  useEffect(() => {
    const getStores = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/v1/all_stores?per_page=${paginationStates.rowsPerPage
          }&page=${paginationStates.page + 1}`,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        const { data: registerRequestData } = registerRequest;
        if (registerRequest.status === 200) {
          setLoading(false);
          setFilteredStores(registerRequestData.data);
          setStores(registerRequestData.data);
          setPaginationStates({
            ...paginationStates,
            total_records: registerRequestData.total_stores,
          });
        }
      } catch (err) {
        setLoading(false);
        setSnackbarStates({
          open: true,
          message: "Unable to load store right now. Please try again later.",
          severity: "danger",
        });
      }
    };
    getStores();
  }, [paginationStates.page, paginationStates.rowsPerPage]);

  const onPageChange = (event, newPage) => {
    setPaginationStates({ ...paginationStates, page: newPage });

    const updatedListing = stores.slice(
      newPage * paginationStates.rowsPerPage,
      newPage * paginationStates.rowsPerPage + paginationStates.rowsPerPage
    );

    setFilteredStores(updatedListing);
  };

  const onRowsPerPageChange = (event) => {
    const updatedRowsPerPage = parseInt(event.target.value, 1);
    setPaginationStates({
      ...paginationStates,
      rowsPerPage: updatedRowsPerPage,
      page: 0,
    });

    const updatedRows = stores.slice(
      0 * updatedRowsPerPage,
      0 * updatedRowsPerPage + updatedRowsPerPage
    );

    setFilteredStores(updatedRows);
  };

  const handleCloseCommunityAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };
  return (
    <>
      <Snackbar
        open={snackbarStates.open}
        autoHideDuration={3000}
        onClose={handleCloseCommunityAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseCommunityAlert}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <Stack>
        <Box
          className={classes.headerBox}
          sx={{ width: "100%", height: "200px" }}
        >
          <Typography
            variant={"h3"}
            sx={{ fontWeight: "600", textAlign: "center" }}
          >
            Moore Exotics Neighbors
          </Typography>
        </Box>
        {!loading ? (
          filteredStores.length > 0 ? (
            <Box
              sx={{
                marginX: { xs: "20px", md: "100px" },
                marginTop: 4,
                marginBottom: 4,
                overflow: "auto",
                border: "1px solid #eeeeee",
                borderRadius: "10px",
                padding: "5px",
              }}
            >
              <Grid
                sx={{
                  margin: "10px",
                  maxWidth: "100vw",
                  backgroundColor: "transparent",
                  mt: 2,
                  display: "grid",
                  gap: 2,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(5, 1fr)",
                  },
                  [`& .${imageListItemClasses.root}`]: {
                    display: "flex",
                    flexDirection: "column",
                  },
                }}
              >
                {filteredStores.map((item) => (
                  <Box
                    sx={{
                      margin: { md: "2px" },
                    }}
                  >
                    <Link
                      to={"/neighbour/" + item.id}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <Card
                        sx={{
                          border: "0.2px solid #eeeeee",
                          borderRadius: "10px",
                          cursor: "pointer",
                          transition: "0.3s all",
                          "&:hover": {
                            color: "rgb(255, 192, 1)",
                            backgroundColor: "#eeeeee",
                          },
                        }}
                      >
                        <CardMedia
                          sx={{ height: 180, backgroundSize: "contain" }}
                          image={item.brand_logo?.url}
                          title={item.brand_name}
                        />
                        <CardContent sx={{ height: 30 }}>
                          <Typography
                            component="div"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            {item.owner_name}
                          </Typography>
                          <Typography
                            gutterBottom
                            sx={{ fontSize: "14px", textAlign: "center" }}
                          >
                            {item.type}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Link>
                  </Box>
                ))}
              </Grid>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="bottom"
                sx={{ mt: 1 }}
              >
                <TablePagination
                  component="div"
                  count={paginationStates.total_records}
                  page={paginationStates.page}
                  onPageChange={onPageChange}
                  rowsPerPage={paginationStates.rowsPerPage}
                  onRowsPerPageChange={onRowsPerPageChange}
                  labelRowsPerPage="Communities per page"
                />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                mt: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                No Neighbors Found
              </Typography>
            </Box>
          )
        ) : (
          <Box
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Stack>
    </>
  );
};

const mapStateToProps = ({ Auth, Seller, Store }) => ({
  jwtToken: Auth.jwtToken,
  seller: Seller.seller,
  storeId: Store.store,
});

const mapDispatchToProps = (dispatch) => ({
  setStore: (data) => dispatch(Actions.setStore(data)),
});

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  headerBox: {
    backgroundColor: "#000",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Stores);
