import React, { useEffect, useState } from "react";
import { CircularProgress, Box } from "@mui/material";
import axios from "axios";
import config from "../../config";
import { useNavigate, useSearchParams } from "react-router-dom";

const EmailConfirmation = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleConfirmEmail = async (e) => {
      setLoading(true);
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL
          }/api/users/confirmation?confirmation_token=${params.get(
            "confirmation_token"
          )}`,
          {}
        );
      } catch ({ response }) {
        registerRequest = response;
        if (registerRequest.status === 422) {
          setError(
            registerRequest.data.email &&
            Object.keys(registerRequest.data) +
            " " +
            Object.values(registerRequest.data.email)
          );
        }
      }

      if (registerRequest.status === 200) {
        setLoading(false);
        return navigate("/auth/login");
      }
    };
    handleConfirmEmail();
  }, [])

  return (
    loading && (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <CircularProgress />
      </Box>
    )
  )
};

export default EmailConfirmation;
