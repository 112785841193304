import config from "../../config";
const catIcons = [
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector.png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(1).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(4).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(24).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(2).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(5).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(6).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector99.png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/centipedies.png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(11).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(55).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(45).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(9).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(12).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/crocodile.png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(16).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(15).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(17).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(18).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(19).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(20).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(43).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/24.png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(37).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(63).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(73).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(338).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(93).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(103).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Leopard+Geckos.png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(63).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(133).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(143).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(153).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(107).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+()99.png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(22).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(136).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(23).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(3).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(25).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(26).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(27).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(28).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(29).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(30).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(31).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(390).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(32).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(33).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(35).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(34).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(36).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(37).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(38).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector(44).png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Ven-Snake.png`,
  `https://${config.BUCKET_URL}.s3.amazonaws.com/ICONS/Vector+(40).png`,
];
export default catIcons;
