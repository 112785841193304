import React, { useContext } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";

import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { navItems } from "./BuyerNavItemsData";
import { connect } from "react-redux";

const BuyerDashboardDrawer = ({
  handleDrawerToggle,
  mobileOpen,
  setMobileOpen,
  user,
  seller,
  categories,
}) => {
  const navigate = useNavigate();

  const handleOptionClick = (selectedOption) => {
    if (selectedOption) {
      navigate(`/listings/classes/${selectedOption.id}`);
    }
  };
  const handleNavigateToEducation = (e) => {
    window.location.href = "https://education.mooreexotics.com";
  };
  const handleCloseDrawer = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleSearchTextChange = (e) => {
    e.preventDefault();
  };
  const drawer = (
    <Box sx={{ textAlign: "center", paddingX: "10px" }}>
      <Typography variant="h6" sx={{ my: 2, color: "rgb(255, 192, 1)" }}>
        Moore Exotics
      </Typography>
      <Divider sx={{ marginY: "10px" }} />
      <List>
        {navItems.map((item) => (
          <>
            {item.name === "Education" ? (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    onClick={handleNavigateToEducation}
                    to={item.path}
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "14px",
                      }}
                      primary={item.name}
                    />
                  </ListItemButton>
                </ListItem>
              </>
            ) : (
              <ListItem disablePadding>
                <ListItemButton
                  component={Link}
                  to={item.path}
                  onClick={handleCloseDrawer}
                  target={"_self"}
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "14px",
                    }}
                    primary={item.name}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </>
        ))}
        <Divider sx={{ marginY: "10px" }} />
        {user && user.email ? (
          <List>
            <ListItem disablePadding>
              {seller && seller.approval_status === "approved" ? (
                <ListItemButton
                  component={Link}
                  to="/seller/dashboard"
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      m: 0,
                      padding: "5px",
                      minWidth: 0,
                    }}
                  >
                    <ArrowBackIcon sx={{ fontSize: "16px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                    primary={"Seller Dashboard"}
                  />
                </ListItemButton>
              ) : (
                <ListItemButton
                  component={Link}
                  to={
                    user && user.is_subscribed ? "/seller/register" : "/pricing"
                  }
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "14px",
                    }}
                    primary={"Seller Register"}
                  />
                </ListItemButton>
              )}
            </ListItem>
          </List>
        ) : (
          <>
            <ListItem key="Login" disablePadding>
              <ListItemButton
                component={Link}
                to="/auth/login"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "14px",
                  }}
                  primary={"Login"}
                />
              </ListItemButton>
            </ListItem>
            <ListItem key="Signup" disablePadding>
              <ListItemButton
                component={Link}
                to="/auth/signup"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "14px",
                  }}
                  primary={"Sign Up"}
                />
              </ListItemButton>
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );
  return (
    <Box component="nav">
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "block", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
const mapStateToProps = ({ Auth, Seller, Categories }) => ({
  user: Auth.user,
  seller: Seller.seller,
  categories: Categories.classes,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyerDashboardDrawer);
