import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DropDown from "../../../components/seller/listings/DropDown";
import DropFileInput from "../../../components/dropFileInput/DropFileInput";
import { TagsInput } from "react-tag-input-component";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import * as Actions from "../../../redux/actions";

import config from "../../../config";
import {
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@mui/material";

import {
  Alert,
  CircularProgress,
  InputAdornment,
  Slide,
  Snackbar,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SelectedMediaView from "../../../components/seller/listings/SelectedMediaView";
import CustomModal from "../../../components/seller/listings/Modal";

const initialState = {
  name: "",
  description: "",
  price: "",
};

const CreateOrEditService = ({
  services,
  store,
  loadServiceSubCategory,
  subServices,
  loadServiceCategories,
  jwtToken,
  seller,
  user,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [categorySelected, setCategorySelected] = useState("");
  const [shippingDetails, setShippingDetails] = useState(null);
  const [formValues, setFormValues] = useState(initialState);
  const [processing, setProcessing] = useState(false);
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [mediaDeletedUrls, setMediaDeletedUrls] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [serviceListData, setServiceListData] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [confirmProp, setConfirmProp] = useState(false);
  const [planLimitMessage, setPlanLimitMessage] = useState("");
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const params = useParams();
  const handleSetCategory = (event) => {
    setCategorySelected(event.target.value);
  };

  useEffect(() => {
    loadServiceCategories();
  }, []);

  useEffect(() => {
    loadServiceSubCategory(categorySelected);
  }, [categorySelected]);

  useEffect(() => {
    if (!store) {
      navigate("/");
    }
  }, []);

  const handleSubCategory = (event) => {
    setSubCategory(event.target.value);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    const data = {
      ...formValues,
      shipping_details: shippingDetails,
      service_id: categorySelected,
      sub_service_id: subCategory,
      media: images,
    };

    let registerRequest;
    try {
      if (params.serviceId) {
        registerRequest = await axios.patch(
          `${config.SERVER_URL}/api/v1/listing_services/${params.serviceId}`,
          {
            listing_service: {
              ...data,
            },
          },
          {
            headers: {
              "Content-Type": "multipart/form-data; ",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } else {
        registerRequest = await axios.post(
          `${config.SERVER_URL}/api/v1/seller/${seller.id}/listing_services`,
          {
            listing_service: {
              ...data,
            },
          },
          {
            headers: {
              "Content-Type": "multipart/form-data; ",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      }
    } catch ({ response }) {
      registerRequest = response;
      if (registerRequest.status === 400) {
        setOpenDeleteModal(true);
        setPlanLimitMessage(registerRequest.data.message);
      } else if (registerRequest.status === 422) {
        setSnackbarStates({
          open: true,
          message: registerRequest.data.message,
          severity: "danger",
        });
      }
      setProcessing(false);
    }

    if (registerRequest.status === 201 || registerRequest.status === 200) {
      setProcessing(false);
      navigate("/seller/service");
    }
  };

  useEffect(() => {
    const getServiceListingData = async (id) => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/v1/listing_services/${id}/edit`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;
      }

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 200) {
        setServiceListData(registerRequestData.data);
      }
    };

    if (params.serviceId) {
      getServiceListingData(params.serviceId);
    }
  }, []);

  useEffect(() => {
    if (params.serviceId && serviceListData) {
      setFormValues({
        name: serviceListData.name,
        description: serviceListData.description,
        price: serviceListData.price,
      });

      setCategorySelected(serviceListData.service_id);
      setSubCategory(serviceListData.sub_service_id);
      setShippingDetails(serviceListData.shipping_details);
      setImages(serviceListData.media);
      setPreviewImages(serviceListData.media);
    }
  }, [serviceListData, params.serviceId]);
  const onFileChange = async (file) => {
    setImages([...images, file]);
    setPreviewImages([...previewImages, file]);
  };

  const onFileRemove = async (file) => {
    const newImages = images.filter((image) => image !== file);
    setImages(newImages);

    if (file.url) {
      setMediaDeletedUrls([...mediaDeletedUrls, file.url]);
    }

    const newPreviewImages = previewImages.filter((image) => image !== file);
    setPreviewImages(newPreviewImages);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarStates({ ...snackbarStates, open: false });
  };

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  useEffect(() => {
    if (confirmProp) {
      window.location.href = user.billingPortalUrl;
    }
  }, [confirmProp]);

  return (
    <Grid xs={12}>
      <Snackbar
        transitionDuration={{ enter: 1000, exit: 2000 }}
        TransitionComponent={Slide}
        TransitionProps={{ enter: true, exit: false }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarStates.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <CustomModal
        title="Plan Limit Exceeded!"
        content={planLimitMessage}
        openModal={openDeleteModal}
        closeModal={handleCloseDeleteModal}
        actionTitle={"Update Plan"}
        actionColor={"primary"}
        setConfirmProp={setConfirmProp}
      />
      <Box
        sx={{
          m: 3,
          p: 2,
          height: "95vh",
          textAlign: "center",
          bgcolor: "white",
          overflow: "auto",
        }}
      >
        <Typography component="h1" variant="h5" fontWeight={"bold"}>
          {params.id ? "Edit Animal Service" : "Create Animal Service"}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid
            container
            spacing={2}
            xs={12}
            sx={{
              paddingTop: 2,
              paddingBottom: 2,
              margin: "auto",
              width: { xs: "90%", sm: "90%", md: "80%" },
            }}
          >
            <Grid item xl={12} sm={12} md={12} lg={12}>
              <TextField
                autoComplete
                size="small"
                name="name"
                fullWidth
                id="name"
                label="Name"
                value={formValues.name}
                onChange={handleFormChange}
                sx={{ mb: 4, mr: 2 }}
                required
              />
              <Box sx={{ mb: 2 }}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel
                    id="my-select-label"
                    sx={{ backgroundColor: "white" }}
                  >
                    Select an service classes
                  </InputLabel>
                  {services && (
                    <Select
                      value={categorySelected}
                      onChange={handleSetCategory}
                    >
                      {services.map((option, index) => (
                        <MenuItem key={index} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Box>
              {categorySelected != "" && subServices.length > 0 && (
                <Box sx={{ mb: 2 }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel
                      id="my-select-label"
                      sx={{ backgroundColor: "white" }}
                    >
                      Select an service sub-classes
                    </InputLabel>

                    <Select value={subCategory} onChange={handleSubCategory}>
                      {subServices.map((option, index) => (
                        <MenuItem key={index} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
              <TextField
                size="small"
                fullWidth
                id="price"
                label="Price"
                name="price"
                type="number"
                autoComplete
                value={formValues.price}
                onChange={handleFormChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  classes: {
                    root: classes.numberTextField,
                  },
                }}
                sx={{ mr: 2, mb: 4 }}
                required
              />

              <TagsInput
                value={shippingDetails}
                onChange={setShippingDetails}
                name="Shipping_Details"
                placeHolder="Shipping Details"
              />
              <Typography
                variant="p"
                component="p"
                sx={{
                  color: "grey",
                  fontSize: "12px",
                  textAlign: "left",
                  mb: 4,
                }}
              >
                Enter multiple shipping details by pressing Enter.
              </Typography>
              <TextField
                size="small"
                fullWidth
                name="description"
                label="Description"
                multiline
                rows={5}
                id="description"
                value={formValues.description}
                onChange={handleFormChange}
                sx={{ mb: 4, mr: 2 }}
                required
              />
              <Box sx={{ mb: 4 }}>
                <Typography
                  component="p"
                  variant="p"
                  sx={{
                    textAlign: "left",
                    fontSize: "14px",
                    color: "#454545",
                  }}
                >
                  Service's Media
                </Typography>
                <DropFileInput
                  onFileChange={(file) => onFileChange(file)}
                  filesUrls={previewImages}
                  disabled={false}
                  acceptFileTypes={".jpg, .jpeg, .gif, .png, .webp, .heic"}
                />
                {previewImages && previewImages.length > 0 ? (
                  <SelectedMediaView
                    media={previewImages}
                    onFileRemove={onFileRemove}
                  />
                ) : null}
              </Box>
            </Grid>
            <Box sx={{ width: "100%" }}>
              <Button
                type="submit"
                variant="contained"
                disabled={processing ? true : false}
                sx={{
                  width: { xs: "50vw", md: "30vw" },
                  backgroundColor: "#FEC20C",
                  "&:hover": { backgroundColor: "black", color: "white" },
                  m: 2,
                }}
              >
                {processing ? (
                  <CircularProgress
                    size="24px"
                    sx={{ color: "rgb(255 192 1)" }}
                  />
                ) : params.serviceId ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles({
  numberTextField: {
    height: "40px",
    backgroundColor: "white",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:hover": {
      borderColor: "rgb(255, 192, 1)",
    },
  },
});

const mapStateToProps = ({ Services, Auth, Seller, Store }) => ({
  services: Services.services,
  subServices: Services.subServices,
  jwtToken: Auth.jwtToken,
  user: Auth.user,
  seller: Seller.seller,
  store: Store.store,
});

const mapDispatchToProps = (dispatch) => ({
  loadServiceCategories: () => dispatch(Actions.thunkLoadServices()),
  loadServiceSubCategory: (serviceId) =>
    dispatch(Actions.thunkLoadSubServices(serviceId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrEditService);
