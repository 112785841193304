import React, { useEffect, useState } from "react";
import * as Actions from "../../../redux/actions/";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  Stack,
  Snackbar,
  Alert,
  Slide,
  CircularProgress,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import DropFileInput from "../../../components/dropFileInput/DropFileInput";
import axios from "axios";
import config from "../../../config";
import SelectedMediaView from "../../../components/seller/listings/SelectedMediaView";
import { useLocation } from "react-router-dom";

const SellerDocuments = ({
  user,
  jwtToken,
  seller,
  setSeller,
  setUserIsSubscribed,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("success");

  const [previousSellerNumber, setPreviousSellerNumber] = useState(null);
  const [processing, setProcessing] = useState(false);

  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const [media, setMedia] = useState({
    idMedia: [],
    sellerMedia: [],
    vetMedia: [],
  });
  console.log("User", user);
  console.log("Seller", seller);

  useEffect(() => {
    if (
      user &&
      user.is_subscribed &&
      seller &&
      seller.approval_status === "approved"
    ) {
      return navigate("/");
    }
    if (user && !user.is_subscribed) {
      return navigate("/pricing");
    }
  }, [user, seller]);

  useEffect(() => {
    if (paramValue === "true") {
      setUserIsSubscribed();
    }
  }, [paramValue]);

  const onIdMediaChange = async (file) => {
    if (file.size > 0) {
      setMedia({
        ...media,
        idMedia: [...media.idMedia, file],
      });
    } else {
      setMedia({
        ...media,
        idMedia: [...media.idMedia],
      });
    }
  };

  const onSellerMediaChange = async (file) => {
    if (file.size > 0) {
      setMedia({
        ...media,
        sellerMedia: [...media.sellerMedia, file],
      });
    } else {
      setMedia({
        ...media,
        sellerMedia: [...media.sellerMedia],
      });
    }
  };

  const onVetMediaChange = async (file) => {
    if (file.size > 0) {
      setMedia({
        ...media,
        vetMedia: [...media.vetMedia, file],
      });
    } else {
      setMedia({
        ...media,
        vetMedia: [...media.vetMedia],
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const fields = ["previous_seller_number", "address", "references"];
    const formElements = e.target.elements;

    const formValues = fields
      .map((field) => ({
        [field]: formElements.namedItem(field).value,
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    if (
      media.idMedia.length > 0 &&
      media.sellerMedia.length > 0 &&
      media.vetMedia.length > 0
    ) {
      let registerRequest;
      try {
        registerRequest = await axios.post(
          `${config.SERVER_URL}/api/v1/seller`,
          {
            seller: {
              id_media: media.idMedia,
              seller_media: media.sellerMedia,
              contact: formValues.previous_seller_number,
              address: formValues.address,
              reference: formValues.references,
              vet_media: media.vetMedia,
              user_id: user.id,
            },
          },
          {
            headers: {
              "Content-Type": "multipart/form-data; ",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;
        if (registerRequest.status === 422) {
          setSnackbarStates({
            open: true,
            message:
              Object.keys(registerRequest.data.errors) +
              ": " +
              Object.values(registerRequest.data.errors),
            severity: "danger",
          });
          setProcessing(false);
        }
      }

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 201) {
        setSeller(registerRequestData.data);
        setSnackbarStates({
          open: true,
          message: registerRequestData.message,
          severity: "success",
        });
        setProcessing(false);
      }
    }
  };

  const onIdMediaRemove = async (file) => {
    const newIdMedia = media.idMedia.filter((item) => item !== file);
    setMedia({
      ...media,
      idMedia: newIdMedia,
    });
  };

  const onSellerMediaRemove = async (file) => {
    const newSellerMedia = media.sellerMedia.filter((item) => item !== file);
    setMedia({
      ...media,
      sellerMedia: newSellerMedia,
    });
  };

  const onVetMediaRemove = async (file) => {
    const newVetMedia = media.vetMedia.filter((item) => item !== file);
    setMedia({
      ...media,
      vetMedia: newVetMedia,
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };

  const onPhoneNumberChange = (e) => {
    const pattern = /^[A-Za-z!@#$%^&*()_+`~=[\\\]/.,{}|:;"'<>?*]+$/;
    e.preventDefault();
    if (
      e.target.value.length === 3 &&
      e.nativeEvent.inputType !== "deleteContentBackward"
    ) {
      setPreviousSellerNumber(e.target.value + "-");
    } else if (
      e.target.value.length === 4 &&
      e.nativeEvent.inputType === "insertText"
    ) {
      setPreviousSellerNumber(
        previousSellerNumber + "-" + e.target.value[e.target.value.length - 1]
      );
    } else if (
      e.target.value.length === 7 &&
      e.nativeEvent.inputType !== "deleteContentBackward"
    ) {
      setPreviousSellerNumber(e.target.value + "-");
    } else if (
      e.target.value.length === 8 &&
      e.nativeEvent.inputType === "insertText"
    ) {
      setPreviousSellerNumber(
        previousSellerNumber + "-" + e.target.value[e.target.value.length - 1]
      );
    } else {
      if (!pattern.test(e.target.value)) {
        setPreviousSellerNumber(e.target.value);
      }
    }
  };

  return (
    <Stack style={{ backgroundColor: "#ebecf0" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={processing}
      >
        <CircularProgress sx={{ color: "rgb(255 192 1)" }} />
      </Backdrop>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alginItem="center"
      >
        <Snackbar
          transitionDuration={{ enter: 1000, exit: 2000 }}
          TransitionComponent={Slide}
          TransitionProps={{ enter: true, exit: false }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbarStates.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            variant="filled"
            severity={snackbarStates.severity}
            sx={{ width: "100%" }}
          >
            {snackbarStates.message}
          </Alert>
        </Snackbar>
        {seller && seller.approval_status === "pending" ? (
          <>
            <Box
              sx={{
                marginTop: "40vh",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: "32px",
                  fontWeight: "bold",
                  width: "100%",
                  paddingBottom: "40vh",
                  textAlign: "center",
                }}
              >
                Please wait until you are approved!
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Grid item display="flex">
              <Box
                xl={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  paddingTop: 2,
                  paddingBottom: 2,
                  margin: "auto",
                  width: { xs: "90%", sm: "90%", md: "60%" },
                }}
              >
                <Typography
                  variant="h4"
                  align="left"
                  className={classes.headerStyle}
                >
                  Seller Documents
                </Typography>
                <Typography
                  align="left"
                  sx={{ fontSize: { xs: "14px", md: "16px" } }}
                >
                  These documents are needed for your verification as a seller
                  at our platform. We will keep these confidential and will only
                  use them for verification purposes.
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid container direction="row" sx={{ mt: 2 }}>
                    <Grid item xs={3} md={3}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: 600, pt: 1 }}
                      >
                        Your ID Picture
                      </Typography>
                    </Grid>
                    <Grid item xs={9} md={9}>
                      <DropFileInput
                        onFileChange={(file) => onIdMediaChange(file)}
                        filesUrls={media.idMedia}
                        disabled={false}
                        acceptFileTypes={
                          ".jpg, .jpeg, .gif, .png, .webp, .heic"
                        }
                      />
                      {media.idMedia && media.idMedia.length > 0 ? (
                        <SelectedMediaView
                          media={media.idMedia}
                          onFileRemove={onIdMediaRemove}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container direction="row" sx={{ mt: 2 }}>
                    <Grid item xs={3} md={3}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: 600, pt: 1 }}
                      >
                        Pics and Videos
                      </Typography>
                    </Grid>
                    <Grid item xs={9} md={9}>
                      <DropFileInput
                        onFileChange={(file) => onSellerMediaChange(file)}
                        filesUrls={media.sellerMedia}
                        disabled={false}
                        acceptFileTypes={
                          ".jpg, .jpeg, .gif, .png, .webp, .heic, .mp4, .mkv, .mov"
                        }
                      />
                      {media.sellerMedia && media.sellerMedia.length > 0 ? (
                        <SelectedMediaView
                          media={media.sellerMedia}
                          onFileRemove={onSellerMediaRemove}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container direction="row" sx={{ mt: 2 }}>
                    <Grid item xs={3} md={3}>
                      <Typography component={"p"} sx={{ fontWeight: 600 }}>
                        Previous Seller Number
                      </Typography>
                    </Grid>
                    <Grid item xs={9} md={9}>
                      <TextField
                        helperText="Required in 415-555-2671 format"
                        placeholder="415-555-2671"
                        size="small"
                        type="tel"
                        value={previousSellerNumber}
                        name="previous_seller_number"
                        fullWidth
                        autoComplete="off"
                        onChange={onPhoneNumberChange}
                        inputProps={{
                          minLength: 12,
                          maxLength: 12,
                          pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
                        }}
                        InputProps={{
                          classes: {
                            root: classes.sellerTextField,
                          },
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" sx={{ mt: 2 }}>
                    <Grid item xs={3} md={3}>
                      <Typography component={"p"} sx={{ fontWeight: 600 }}>
                        Previous Seller Address
                      </Typography>
                    </Grid>
                    <Grid item xs={9} md={9}>
                      <TextField
                        placeholder="Enter your address"
                        size="small"
                        type="text"
                        name="address"
                        fullWidth
                        InputProps={{
                          classes: {
                            root: classes.textField,
                          },
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" sx={{ mt: 2 }}>
                    <Grid item xs={3} md={3}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: 600, pt: 1 }}
                      >
                        Vet References
                      </Typography>
                    </Grid>
                    <Grid item xs={9} md={9}>
                      <TextField
                        placeholder="Enter Vet References, Phone Number or Address"
                        size="small"
                        type="text"
                        name="references"
                        fullWidth
                        InputProps={{
                          classes: {
                            root: classes.textField,
                          },
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" sx={{ mt: 2 }}>
                    <Grid item xs={3} md={3}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: 600, pt: 1 }}
                      >
                        Vet Paperwork
                      </Typography>
                    </Grid>
                    <Grid item xs={9} md={9}>
                      <DropFileInput
                        onFileChange={(file) => onVetMediaChange(file)}
                        filesUrls={media.vetMedia}
                        disabled={false}
                        acceptFileTypes={
                          ".jpg, .jpeg, .gif, .png, .webp, .heic, .pdf, .docx"
                        }
                      />
                      {media.vetMedia && media.vetMedia.length > 0 ? (
                        <SelectedMediaView
                          media={media.vetMedia}
                          onFileRemove={onVetMediaRemove}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container direction="row" sx={{ mt: 2 }}>
                    <Grid item xs={3} md={3}></Grid>
                    <Grid
                      item
                      display="flex"
                      justifyContent="start"
                      xs={9}
                      md={9}
                    >
                      <Button
                        variant="outlined"
                        className={classes.buttons}
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={processing ? true : false}
                        className={classes.buttons}
                      >
                        {processing ? (
                          <CircularProgress
                            size="24px"
                            sx={{ color: "rgb(255 192 1)" }}
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
};

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  headerStyle: {
    fontStyle: "normal",
    color: "#000",
    fontWeight: "900 !important",
  },
  textField: {
    height: "40px",
    backgroundColor: "white",
    "&:hover": {
      borderColor: "rgb(255, 192, 1)",
    },
  },
  sellerTextField: {
    height: "40px",
    backgroundColor: "white",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:hover": {
      borderColor: "rgb(255, 192, 1)",
    },
  },
  buttons: {
    height: "40px",
    width: "150px",
    marginRight: "20px",
    fontSize: "15px !important",
    fontWeight: "900 !important",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "rgb(255, 192, 1)",
      color: "white !important",
    },
  },
});

const mapStateToProps = ({ Auth, Seller }) => ({
  user: Auth.user,
  jwtToken: Auth.jwtToken,
  seller: Seller.seller,
});

const mapDispatchToProps = (dispatch) => ({
  setSeller: (data) => dispatch(Actions.setSeller(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SellerDocuments);
