import React, { useState } from "react";
import {
  Modal,
  TextField,
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import config from "../../config";

const ForgotPasswordModal = ({ openModal, handleCloseModal, jwtToken }) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fields = ["email"];
    const formElements = e.target.elements;

    const formValues = fields
      .map((field) => ({
        [field]: formElements.namedItem(field).value,
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    try {
      registerRequest = await axios.post(
        `${config.SERVER_URL}/api/users/password`,
        {
          user: {
            ...formValues,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
    } catch ({ response }) {
      registerRequest = response;
      if (registerRequest.status === 422) {
        setError(
          registerRequest.data.errors &&
            Object.keys(registerRequest.data.errors) +
              " " +
              Object.values(registerRequest.data.errors)
        );
      }
    }
    if (registerRequest.status === 201) {
      handleCloseModal();
      return navigate("/auth/login/reset-password/verification");
    }
  };

  return (
    <Modal
      sx={{
        left: { xs: "10%", sm: "20%", md: "35%", lg: "35%" },
        top: { xs: "10%", sm: "10%", md: "10%", lg: "20%" },
        width: { xs: "80%", sm: "55%", md: "35%", lg: "30%" },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={{ padding: "40px", margin: "auto", width: { md: "80%" } }}>
        <CardContent>
          <Typography
            id="modal-modal-title"
            sx={{
              fontWeight: 600,
            }}
            align="center"
            component={"h4"}
            variant={"h4"}
          >
            Password Reset
          </Typography>
          <Typography
            id="modal-modal-title"
            align="left"
            sx={{
              mt: 1,
            }}
            component={"p"}
            variant={"p"}
          >
            Enter your email address below to receive instructions to reset your
            password.
          </Typography>
          <Typography
            component={"p"}
            sx={{
              mt: 2,
              fontWeight: 600,
            }}
          >
            Email
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              helperText={
                error && <span style={{ color: "red" }}>{error}</span>
              }
              placeholder="Enter your email"
              type="email"
              name="email"
              size="small"
              fullWidth
              required
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 4,
                mb: 1,
                color: "#fff",
                fontWeight: 700,
                textTransform: "none !important",
              }}
            >
              Send Reset instructions
            </Button>
          </form>
          <Typography>
            Back to{" "}
            <Link
              to="/auth/login"
              style={{
                textDecoration: "none",
                color: "rgb(255, 192, 1)",
              }}
              onClick={handleCloseModal}
            >
              Login
            </Link>
          </Typography>
        </CardContent>
      </Card>
    </Modal>
  );
};

const mapStateToProps = ({ Auth }) => ({
  jwtToken: Auth.jwtToken,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordModal);
