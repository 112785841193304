import { Close } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import { Container, Box } from "@mui/system";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles, createStyles } from "@mui/styles";

const PetAboutUs = ({ description, loadMoreText }) => {
  const [showMore, setShowMore] = useState(true);

  const handleShowMore = (e) => {
    e.preventDefault();
    setShowMore(!showMore);
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    setShowMore(!showMore);
  };
  const classes = useStyles();

  return (
    <>
      <Modal
        open={!showMore}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          left: { xs: "5%", sm: "5%", md: "10%", lg: "10%" },
          top: { xs: "5%", sm: "5%", md: "5%", lg: "5%", xl: "20%" },
          width: { xs: "90%", sm: "90%", md: "80%", lg: "80%" },
          overflow: "scroll",
        }}
      >
        <div style={{ position: "relative" }}>
          <IconButton
            onClick={handleCloseModal}
            style={{ position: "absolute", top: "0", right: "0" }}
          >
            <Close />
          </IconButton>
          <Card
            display="flex"
            sx={{
              margin: "auto",
            }}
          >
            <CardContent>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <img
                  className={classes.logo}
                  style={{
                    transition: "0.5s ease",
                    backfaceVisibility: "hidden",
                    objectFit: "contain",
                  }}
                  src={
                    "https://res.cloudinary.com/dwezij7u7/image/upload/v1675115795/exoticslogo_w2oi9z.png"
                  }
                  srcSet={
                    "https://res.cloudinary.com/dwezij7u7/image/upload/v1675115795/exoticslogo_w2oi9z.png"
                  }
                  alt={"Moore exotics logo"}
                  height={"160px"}
                  loading="lazy"
                />
              </Box>
              <Box
                sx={{ textAlign: { xs: "judtify", sm: "justify", md: "left" } }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "25px",
                    lineHeight: "33px",
                    color: "#000000",
                  }}
                >
                  Connecting your pets with the best
                </Typography>
                <TypePara sx={{ lineHeight: "20px" }}>{description}</TypePara>
                <TypePara sx={{ lineHeight: "20px" }}>{loadMoreText}</TypePara>
              </Box>
            </CardContent>
          </Card>
        </div>
      </Modal>
      <PetAboutBox my={"120px"}>
        <Container fixed maxWidth={false}>
          <BuySellerGrid container>
            <Grid className="ChildColumns" item xs={12} sm={12}>
              <Box textAlign="center">
                <img
                  src={
                    "https://stagingappdata.s3.amazonaws.com/uploads/home-ss.png"
                  }
                  alt="GermanShephrd"
                  style={{ width: "100%" }}
                />
              </Box>
            </Grid>

            <Grid className="ChildColumns" item xs={12} sm={12}>
              <Box
                sx={{ textAlign: { xs: "center", sm: "center", md: "left" } }}
              >
                <Typography component="h2" className="Typehead">
                  We link{" "}
                  <Link
                    to="/neighbour"
                    style={{
                      color: "black",
                      textDecorationColor: "black",
                    }}
                  >
                    <Typography component="span" className="Typehead">
                      breeders
                    </Typography>
                  </Link>
                  <Typography component="span" className="Typehead">
                    {" "}
                    with{" "}
                  </Typography>
                  <Link
                    to="/listings/classes/28"
                    style={{
                      color: "black",
                      textDecorationColor: "black",
                    }}
                  >
                    <Typography component="span" className="Typehead">
                      Plant
                    </Typography>
                  </Link>
                  <Typography component="span" className="Typehead">
                    {" "}
                    &{" "}
                  </Typography>
                  <Link
                    to="/listings"
                    style={{
                      color: "black",
                      textDecorationColor: "black",
                    }}
                  >
                    <Typography component="span" className="Typehead">
                      Animal
                    </Typography>
                  </Link>
                  <Typography
                    component="span"
                    className="Typehead"
                    sx={{ color: "black" }}
                  >
                    {" "}
                    Enthusiasts
                  </Typography>
                </Typography>

                <TypePara>{description}</TypePara>
                {loadMoreText && showMore ? (
                  <Button
                    onClick={handleShowMore}
                    variant="contained"
                    sx={{ mt: 2, textAlign: "center" }}
                  >
                    Read More
                  </Button>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </BuySellerGrid>
        </Container>
      </PetAboutBox>
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    logo: {
      width: "100%" /* Default width */,
      maxWidth: 400 /* Maximum width for larger screens */,
    },
    "@media (min-width: 250px)": {
      logo: {
        width: "100%" /* Width for medium-sized screens */,
      },
    },
    "@media (min-width: 1200px)": {
      logo: {
        width: "100%" /* Width for larger screens */,
      },
    },
  })
);

const BuySellerGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .ChildColumns {
    padding: 0 20px;
  }
  @media screen and (min-width: 1100px) {
    .ChildColumns {
      width: 48%;
      max-width: 48%;
    }
  }
  @media screen and (max-width: 1100px) {
    .AboutUsText {
      margin-top: 20px;
    }
    & .ChildColumns {
      padding: 0px;
    }
  }
  @media screen and (max-width: 769px) {
    .AboutUsText {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      text-align: center !important;
      color: #000000;
    }
    .Typehead {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 33px;
      text-align: center;
      color: #000000;
    }
  }
`;
const PetAboutBox = styled(Box)`
  & .ChildColumns .Typehead {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 66px;
    margin-top: 8px;
  }
  @media screen and (max-width: 1550px) {
    .ChildColumns .Typehead {
      font-size: 44px;
      line-height: 56px;
    }
  }
  @media screen and (max-width: 1250px) {
    .ChildColumns .Typehead {
      font-size: 34px;
      line-height: 40px;
    }
  }
  @media screen and (max-width: 769px) {
    margin-top: 80px;
    margin-bottom: 80px;
    .ChildColumns .Typehead {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 33px;
    }
  }
`;
const TypePara = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  margin-top: 15px;

  @media screen and (max-width: 1550px) {
    .ChildColumns .Typehead {
      font-size: 14px;
      line-height: 22px;
    }
  }
  @media screen and (max-width: 769px) {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
  }
`;

export default PetAboutUs;
