import React, { useEffect, useState } from "react";
import GradeIcon from "@mui/icons-material/Grade";
import { Avatar, Box, Grid, Typography, Rating } from "@mui/material";
import DisplayReview from "../reviews/DisplayReview";
const Messege = ({ messege }) => {
  const [showReview, setShowReview] = useState(false);
  const handleShowReview = () => {
    setShowReview(true);
  };
  const handleCloseReviewModal = () => {
    setShowReview(false);
  };
  return (
    <>
      {messege && (
        <DisplayReview
          openModal={showReview}
          closeModal={handleCloseReviewModal}
          data={messege}
        />
      )}
      <Grid
        container
        onClick={handleShowReview}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s all",
          "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#eeeeee" },
        }}
      >
        <Grid item xs={2} sm={1} md={2}>
          <Avatar
            alt="crew1"
            src={messege.user.avatar_url}
            sx={{
              width: { md: "30px", lg: "45px", xl: "60px" },
              height: { md: "30px", lg: "45px", xl: "60px" },
              margin: "10px",
            }}
          />
        </Grid>
        <Grid item xs={6} sm={7} md={6} pl={{ md: 1 }}>
          <Typography
            sx={{ fontSize: { md: "14px", xl: "16px" }, fontWeight: "bold" }}
          >
            {messege.user.full_name}
          </Typography>
          <Box
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              paddingRight: "2px",
            }}
          >
            <Typography
              noWrap
              sx={{
                fontSize: { md: "12px", xl: "14px" },
                color: "#444444",
              }}
            >
              {messege.content}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: { md: "14px", xl: "16px" }, color: "#444444" }}
            >
              1d
            </Typography>
            <Rating
              name="simple-controlled"
              value={messege.rating}
              sx={{ fontSize: "16px" }}
              readOnly
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Messege;
