import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  Stack,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import config from "../../config";
import DropFileInput from "../../components/dropFileInput/DropFileInput";
import SelectedMediaView from "../../components/seller/listings/SelectedMediaView";
import { connect } from "react-redux";
import { isValid } from "date-fns";

const initialState = {
  full_name: "",
  phone: "",
  about: "",
  date_of_birth: "",
  avatar_url: "",
  facebook_url: "",
  instagram_url: "",
  twitter_url: "",
};

const UserProfile = ({ user, jwtToken, seller }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [formValues, setFormValues] = useState(initialState);
  const [processing, setProcessing] = useState(false);

  const { userId } = useParams();

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    if (userProfile) {
      setFormValues({
        full_name: userProfile.full_name,
        about: userProfile.about,
        date_of_birth: userProfile.date_of_birth,
        avatar_url: userProfile.avatar_url,
        facebook_url: userProfile.facebook_url,
        youtube_url: userProfile.youtube_url,
        twitter_url: userProfile.twitter_url,
        instagram_url: userProfile.instagram_url,
      });
      if (userProfile.user_profile.url) {
        setImages([userProfile.user_profile]);
        setPreviewImages([userProfile.user_profile]);
      }
      setPhoneNumber(userProfile.phone);
    }
  }, [userProfile]);

  useEffect(() => {
  }, [previewImages, images]);

  useEffect(() => {
    const fetchUser = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/users/edit`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;
      }
      if (registerRequest.status === 200) {
        setUserProfile(registerRequest.data.data);
      }
    };
    fetchUser();
  }, [jwtToken]);

  const twitterUrlRegex = /^(https?:\/\/)?((www\.)?twitter\.com).+$/;
  const youtubeUrlRegex = /^(https?:\/\/)?((www\.)?youtube\.com).+$/;
  const facebookUrlRegex = /^(https?:\/\/)?((www\.)?facebook\.com).+$/;
  const instagramUrlRegex = /^(https?:\/\/)?((www\.)?instagram\.com).+$/;

  const onPhoneNumberChange = (e) => {
    e.preventDefault();
    const numericValue = e.target.value.replace(/[^\d-]/g, '');

    if (
      numericValue.length === 3 &&
      e.nativeEvent.inputType !== "deleteContentBackward"
    ) {
      setPhoneNumber(numericValue + "-");
    } else if (
      numericValue.length === 4 &&
      e.nativeEvent.inputType === "insertText"
    ) {
      setPhoneNumber(
        phoneNumber + "-" + numericValue[numericValue.length - 1]
      );
    } else if (
      numericValue.length === 7 &&
      e.nativeEvent.inputType !== "deleteContentBackward"
    ) {
      setPhoneNumber(numericValue + "-");
    } else if (
      numericValue.length === 8 &&
      e.nativeEvent.inputType === "insertText"
    ) {
      setPhoneNumber(
        phoneNumber + "-" + numericValue[numericValue.length - 1]
      );
    } else {
      setPhoneNumber(numericValue);
    }
  };

  const handleSubmit = async (e) => {
    setProcessing(true);
    e.preventDefault();

    if (!phoneNumberError) {
      const data = {
        full_name: formValues.full_name,
        phone: phoneNumber,
        about: formValues.about,
        date_of_birth: formValues.date_of_birth,
        avatar_url: formValues.avatar_url,
        facebook_url: formValues.facebook_url,
        youtube_url: formValues.youtube_url,
        twitter_url: formValues.twitter_url,
        instagram_url: formValues.instagram_url,
        user_profile: images[0],
      };

      let registerRequest;
      try {
        registerRequest = await axios.patch(
          `${config.SERVER_URL}/api/users`,
          {
            user: data,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data; ",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;
        if (registerRequest.status === 422) {
          setError({
            ...error,
            responseMessage:
              registerRequest.data.errors &&
              Object.keys(registerRequest.data.errors) +
              " " +
              Object.values(registerRequest.data.errors),
          });
        }
      }
      if (registerRequest.status === 200) {
        setProcessing(false);
        return navigate("/auth/signup/verification");
      }
    }
  };

  const isUrlValid = (url, regex) => {
    return regex.test(url);
  };

  const onAvatarFileChange = async (file) => {
    if (file) {
      setImages([file]);
      setPreviewImages([file]);
    } else {
      setImages([]);
      setPreviewImages([]);
    }
  };

  const onFileRemove = async (file) => {
    const newImages = images.filter((image) => image !== file);
    setImages(newImages);

    const newPreviewImages = previewImages.filter((image) => image !== file);
    setPreviewImages(newPreviewImages);
  };

  return (
    <Stack style={{ backgroundColor: "#ebecf0" }}>
      <Box
        container
        direction="column"
        justifyContent="center"
        alginItem="center"
      >
        <Grid item display="flex">
          <Box
            xl={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              paddingTop: 2,
              paddingBottom: 2,
              margin: "auto",
              width: { xs: "90%", sm: "90%", md: "60%" },
            }}
          >
            <Box display={"flex"}>
              <Typography
                variant="h4"
                align="left"
                className={classes.headerStyle}
              >
                User Profile
              </Typography>
            </Box>
            <Typography
              align="left"
              sx={{ fontSize: { xs: "14px", md: "16px" } }}
            >
              Your Profile will only be visible to sellers that you choose to
              interact with by sending inquiries. It will save you time by
              providing information necessary to complete sales.
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={3} md={2}>
                  <Typography component={"p"} sx={{ fontWeight: 600, pt: 1 }}>
                    Full Name
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                  <TextField
                    placeholder="Enter your name"
                    helperText="We advise using your real name."
                    size="small"
                    type="text"
                    value={formValues.full_name}
                    onChange={handleFormChange}
                    name="full_name"
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.textField,
                      },
                    }}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={3} md={2}>
                  <Typography component={"p"} sx={{ fontWeight: 600, pt: 1 }}>
                    Email
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                  <TextField
                    size="small"
                    type="email"
                    name="email"
                    defaultValue={user.email}
                    readOnly={true}
                    disabled
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.textField,
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={3} md={2}>
                  <Typography component={"p"} sx={{ fontWeight: 600, pt: 1 }}>
                    Phone
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                  <TextField
                    helperText="Required in 415-555-2671 format"
                    placeholder="415-555-2671"
                    size="small"
                    type="tel"
                    value={phoneNumber}
                    name="phone_number"
                    fullWidth
                    autoComplete="off"
                    onChange={onPhoneNumberChange}
                    style={{ backgroundColor: "transparent" }}
                    inputProps={{
                      minLength: 12,
                      maxLength: 12,
                      pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={3} md={2}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: 600, pt: 2 }}
                      >
                        About You
                      </Typography>
                    </Grid>
                    <Grid item xs={9} md={10}>
                      <TextField
                        placeholder="Tell us something about you."
                        size="small"
                        type="text"
                        multiline
                        rows={2}
                        name="about"
                        onChange={handleFormChange}
                        fullWidth
                        required
                        value={formValues.about}
                        sx={{ backgroundColor: "white" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" sx={{ mt: 2 }}>
                    <Grid item xs={3} md={2}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: 600, pt: 1 }}
                      >
                        Birthday
                      </Typography>
                    </Grid>
                    <Grid item xs={9} md={10}>
                      <TextField
                        placeholder=""
                        helperText="Only age or age-range (if over 20) will be shown."
                        size="small"
                        type="date"
                        name="date_of_birth"
                        onChange={handleFormChange}
                        fullWidth
                        InputProps={{
                          classes: {
                            root: classes.textField,
                          },
                        }}
                        value={formValues.date_of_birth}
                        required
                      />
                    </Grid>
                  </Grid>

                  <Grid container direction="row" sx={{ mt: 2 }}>
                    <Grid item xs={3} md={2}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: 600, pt: 1 }}
                      >
                        Avatar
                      </Typography>
                    </Grid>
                    <Grid item xs={9} md={10}>
                      <DropFileInput
                        onFileChange={(file) => onAvatarFileChange(file)}
                        filesUrls={previewImages}
                        disabled={previewImages.length > 0 ? true : false}
                        isrequired={false}
                        acceptFileTypes={
                          ".jpg, .jpeg, .gif, .png, .webp, .heic"
                        }
                      />
                      {previewImages && previewImages.length > 0 && (
                        <SelectedMediaView
                          media={previewImages}
                          onFileRemove={onFileRemove}
                        />
                      )}
                      {/* <DropFileInput
                        onFileChange={(file) => onAvatarFileChange(file)}
                        filesUrls={images}
                        disabled={images.length > 0 ? true : false}
                        acceptFileTypes={
                          ".jpg, .jpeg, .gif, .png, .webp, .heic"
                        }
                      />
                      {images && images.length > 0 ? (
                        <SelectedMediaView
                          media={images}
                          onFileRemove={onFileRemove}
                        />
                      ) : null} */}
                    </Grid>
                  </Grid>
                  <Typography
                    variant="h5"
                    align="left"
                    className={classes.headerStyle}
                    sx={{ mt: 2 }}
                  >
                    Other Profiles
                  </Typography>
                  <Typography
                    align="left"
                    sx={{ fontSize: { xs: "14px", md: "16px" } }}
                  >
                    You can share links to profiles about yourself on social
                    media sites like Facebook and Instagram or forums. You can
                    include your website if you have one.
                  </Typography>
                  <Grid container direction="row" sx={{ mt: 2 }}>
                    <Grid item xs={3} md={2}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: 600, pt: 1 }}
                      >
                        Facebook
                      </Typography>
                    </Grid>
                    <Grid item xs={9} md={10}>
                      <TextField
                        placeholder="https://www.facebook.com/profile.php?id=1000303034839"
                        size="small"
                        type="link"
                        name="facebook_url"
                        fullWidth
                        value={formValues.facebook_url}
                        onChange={handleFormChange}
                        InputProps={{
                          classes: {
                            root: classes.textField,
                          },
                        }}
                        error={
                          formValues.facebook_url &&
                          !isUrlValid(formValues.facebook_url, facebookUrlRegex)
                        }
                        helperText={
                          formValues.facebook_url &&
                          !isUrlValid(
                            formValues.facebook_url,
                            facebookUrlRegex
                          ) &&
                          "Please enter a valid Facebook URL"
                        }
                        facebook_url
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" sx={{ mt: 2 }}>
                    <Grid item xs={3} md={2}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: 600, pt: 1 }}
                      >
                        Youtube
                      </Typography>
                    </Grid>
                    <Grid item xs={9} md={10}>
                      <TextField
                        placeholder="https://www.youtube.com/your-username"
                        size="small"
                        type="link"
                        value={formValues.youtube_url}
                        name="youtube_url"
                        onChange={handleFormChange}
                        fullWidth
                        error={
                          formValues.youtube_url &&
                          !isUrlValid(formValues.youtube_url, youtubeUrlRegex)
                        }
                        helperText={
                          formValues.youtube_url &&
                          !isUrlValid(
                            formValues.youtube_url,
                            youtubeUrlRegex
                          ) &&
                          "Please enter a valid Youtube URL"
                        }
                        InputProps={{
                          classes: {
                            root: classes.textField,
                          },
                        }}
                        sx={{ color: "red" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" sx={{ mt: 2 }}>
                    <Grid item xs={3} md={2}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: 600, pt: 1 }}
                      >
                        Twitter
                      </Typography>
                    </Grid>
                    <Grid item xs={9} md={10}>
                      <TextField
                        placeholder="https://www.twitter.com/your-username"
                        size="small"
                        type="link"
                        name="twitter_url"
                        value={formValues.twitter_url}
                        onChange={handleFormChange}
                        fullWidth
                        error={
                          formValues.twitter_url &&
                          !isUrlValid(formValues.twitter_url, twitterUrlRegex)
                        }
                        helperText={
                          formValues.twitter_url &&
                          !isUrlValid(
                            formValues.twitter_url,
                            twitterUrlRegex
                          ) &&
                          "Please enter a valid Twitter URL"
                        }
                        InputProps={{
                          classes: {
                            root: classes.textField,
                          },
                        }}
                        sx={{ color: "red" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" sx={{ mt: 2 }}>
                    <Grid item xs={3} md={2}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: 600, pt: 1 }}
                      >
                        Instagram
                      </Typography>
                    </Grid>
                    <Grid item xs={9} md={10}>
                      <TextField
                        placeholder="https://www.instagram.com/your-username"
                        size="small"
                        type="link"
                        name="instagram_url"
                        value={formValues.instagram_url}
                        onChange={handleFormChange}
                        fullWidth
                        error={
                          formValues.instagram_url &&
                          !isUrlValid(formValues.instagram_url, instagramUrlRegex)
                        }
                        helperText={
                          formValues.instagram_url &&
                          !isUrlValid(
                            formValues.instagram_url,
                            instagramUrlRegex
                          ) &&
                          "Please enter a valid Instagram URL"
                        }
                        InputProps={{
                          classes: {
                            root: classes.textField,
                          },
                        }}
                        sx={{ color: "red" }}
                        instagram_url
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item md={2}></Grid>
                <Grid
                  item
                  display="flex"
                  justifyContent="start"
                  xs={12}
                  md={10}
                >
                  <Button
                    variant="outlined"
                    className={classes.buttons}
                    onClick={() => {
                      navigate(`/users/${userId}`);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.buttons}
                    sx={{
                      color: "white",
                    }}
                  >
                    {/* Save */}
                    {processing ? (
                      <CircularProgress size="24px" sx={{ color: "white" }} />
                    ) : (
                      "Save"
                    )}
                  </Button>

                  <Button
                    component={Link}
                    to={!seller ? "/seller/register" : "/seller/profile"}
                    size="small"
                    sx={{
                      textAlign: "center",
                      border: "1px solid #bbbbbb",
                      textTransform: "none",
                      backgroundColor: "rgb(255, 192, 1) !important",
                      color: "white !important",
                      "&:hover": {
                        backgroundColor: "rgb(255, 192, 1)",
                      },
                    }}
                    paddingX={2}
                    variant="contained"
                  >
                    <Typography
                      color={"black"}
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "white",
                      }}
                    >
                      {!seller ? "Register As A Seller" : "Seller Dashboard"}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Box>
    </Stack>
  );
};

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  headerStyle: {
    fontStyle: "normal",
    color: "#000",
    fontWeight: "900 !important",
  },
  textField: {
    height: "40px",
    backgroundColor: "white",
    "&:hover": {
      borderColor: "rgb(255, 192, 1)",
    },
  },
  postalCodeTextField: {
    height: "40px",
    backgroundColor: "white",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:hover": {
      borderColor: "rgb(255, 192, 1)",
    },
  },
  buttons: {
    height: "40px",
    width: "150px",
    marginRight: "20px",
    fontSize: "15px !important",
    fontWeight: "900 !important",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "rgb(255, 192, 1)",
      color: "white !important",
    },
  },
});

const mapStateToProps = ({ Auth, Seller }) => ({
  user: Auth.user,
  jwtToken: Auth.jwtToken,
  seller: Seller.seller,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
