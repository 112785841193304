import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import QuickreplyIcon from "@mui/icons-material/Quickreply";
import MuiTableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Alert,
  Avatar,
  Box,
  IconButton,
  Slide,
  Snackbar,
  Typography,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";

import { connect } from "react-redux";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    color: "inherit",
  },
})(MuiTableCell);

const TableRow = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    cursor: "pointer",
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#aaaaaa" },
  },
})(MuiTableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 8px 8px 8px",
    margin: "10px 10px 10px 10px",
    overflow: "auto",
    borderRadius: "10px",
    height: "95vh",
  },
  table: {
    padding: 0,
    margin: 0,
    borderCollapse: "separate",
    borderSpacing: "0px 9px",
    backgroundColor: "white",
  },
  hover: {
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#eeeeee" },
  },
  featuredRrow: {
    backgroundColor: "#FFF2CA",
  },
}));

const ListingTableHeadings = [
  "",
  "Reviewer",
  "Listing/Service Name",
  "Category",
  "Price",
  "Actions",
];

const ListingsList = ({
  data,
  setReview,
  setOpenReview,
  setOpenDisputeModal,
}) => {
  const classes = useStyles();

  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarStates({ ...snackbarStates, open: false });
  };

  const handleOpenDisputeModal = (event, data) => {
    event.stopPropagation();
    setOpenDisputeModal(true);
    setReview(data);
  };

  const handleDisplayReview = (data) => {
    setReview(data);
    setOpenReview(true);
  };

  return (
    <>
      <Snackbar
        transitionDuration={{ enter: 1000, exit: 2000 }}
        TransitionComponent={Slide}
        TransitionProps={{ enter: true, exit: false }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarStates.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <Paper className={classes.root} elevation={2}>
        {data && data.length > 0 ? (
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                {ListingTableHeadings.map((item) => (
                  <TableCell
                    align="center"
                    sx={{
                      fontSize: { md: "14px", xl: "16px" },
                      fontWeight: "bold",
                    }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((data) => (
                <TableRow
                  key={data.id}
                  onClick={() => handleDisplayReview(data)}
                >
                  <TableCell align="left" style={{ width: 0, padding: "5px" }}>
                    <Avatar
                      width={50}
                      height={50}
                      alt={data.id}
                      src={data.user.avatar_url}
                    />
                  </TableCell>

                  <TableCell align="center" sx={{ width: "8%" }}>
                    {" "}
                    {data.user.full_name}
                  </TableCell>
                  <TableCell align="center"> {data.reviewable.name}</TableCell>
                  <TableCell align="center">
                    {data.reviewable.category_or_service.category.name}
                  </TableCell>
                  <TableCell align="center">${data.reviewable.price}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={(event) => handleOpenDisputeModal(event, data)}
                      sx={{
                        "&:hover": {
                          bgcolor: "rgb(255, 192, 1)",
                          color: "black",
                        },
                      }}
                    >
                      <QuickreplyIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            sx={{ height: "80vh" }}
            justifyContent="center"
            flexDirection="column"
          >
            <Typography variant="p" sx={{ fontSize: "14px" }}>
              No one has reviewed yet.
            </Typography>
          </Box>
        )}
      </Paper>
    </>
  );
};

const mapStateToProps = ({ Auth }) => ({
  jwtToken: Auth.jwtToken,
  user: Auth.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListingsList);
