import React from "react";
import AboutSeller from "../../../components/seller/dashboard/AboutSeller";
import ActiveOrdersTable from "../../../components/seller/dashboard/ActiveOrdersTables";
import SellerDashboadInbox from "../../../components/seller/dashboard/Inbox";
import SellerDashboardMessegeList from "../../../components/seller/dashboard/MessegeList";
import SoldItemTable from "../../../components/seller/dashboard/SoldItemTable";
import { Grid } from "@mui/material";

const SellerDashboard = () => {
  return (
    <>
      <Grid container xs={12} md={12}>
        <Grid item xs={12} sm={12} md={4}>
          <AboutSeller />
          <SellerDashboadInbox />
          <SellerDashboardMessegeList />
        </Grid>
        <Grid item xs={12} sm={12} md={8} maxHeight="90vh">
          <ActiveOrdersTable />
          <SoldItemTable />
        </Grid>
      </Grid>
    </>
  );
};

export default SellerDashboard;
