import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
  Alert,
  Modal,
  Divider,
  ButtonGroup,
  Slider,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import SeeAllReviews from "./SeeAllReviews";
import StarIcon from "@mui/icons-material/Star";
import {
  Star,
  StarHalf,
  Twitter,
  YouTube,
  Facebook,
  Instagram,
} from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import TuneIcon from "@mui/icons-material/Tune";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { imageListItemClasses } from "@mui/material/ImageListItem";
import { Close } from "@mui/icons-material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import ReactPlayer from "react-player";
import SwiperCore, { Autoplay, Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { makeStyles } from "@mui/styles";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import PaymentIcon from "@mui/icons-material/Payment";
import * as Actions from "../../../redux/actions/";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Radio } from "@mui/material";
import config from "../../../config";
import Snackbar from "@mui/material/Snackbar";
import ListCard from "../../../components/listing/ListCard";
import MaleIcon from "@mui/icons-material/Male";

import ClearIcon from "@mui/icons-material/Clear";
import FemaleIcon from "@mui/icons-material/Female";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../components/seller/listings/Modal";
import ServiceListCard from "../../../components/serviceListings/ServiceListCard";

const StoreDetails = ({
  user,
  jwtToken,
  seller,
  loadListings,
  listings,
  classes,
  serviceListing,
  loadServiceListings,
}) => {
  const navigate = useNavigate();
  const styleclasses = useStyles();
  const { storeId } = useParams();
  const [store, setStore] = useState({});
  const [isPlay, setIsPlay] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [isPlay2, setIsPlay2] = useState(false);
  const [isPlay3, setIsPlay3] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [about, setAbout] = useState("");
  const [storeName, setStoreName] = useState("");
  const [policy, setPolicy] = useState("");
  const [confirmProp, setConfirmProp] = useState(false);
  const [openSucessAlert, setOpenSucessAlert] = useState(false);
  const [trophyListings, setTrophyListing] = useState([]);
  const [showModal, setShowModal] = useState("");
  const [reviewData, setReviewData] = useState([]);
  const [selectedRatingValue, setSelectedRatingValue] = useState("");
  const [showReadMoreReview, setShowReadMoreReview] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);
  const playerRef = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const [selectedSortValue, setSelectedSortValue] = useState("Newest");
  const [displayServiceListings, setDisplayServiceListings] = useState([]);
  const [averageRating, setAverageRating] = useState();
  const [sortOrder, setSortOrder] = useState("desc");
  const [displayAnimalListings, setDisplayAnimalListings] = useState([]);
  const [loadData, setLoadData] = useState(true);

  const plans = ["Silver", "Free"];
  useEffect(() => {
    setDisplayAnimalListings([]);
    setDisplayServiceListings([]);
  }, []);
  useEffect(() => {
    const calculateAverageRating = () => {
      const ratings = reviewData.map((data) => {
        return data.rating;
      });
      const sum = ratings.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const average = sum / ratings.length;
      setAverageRating(average.toFixed(2)); // Returns average rating with 2 decimal places
    };
    if (reviewData.length !== 0) {
      calculateAverageRating();
    } else {
      setAverageRating(0);
    }
  }, [reviewData]);

  const renderStarRating = (rating) => {
    const roundedRating = Math.floor(rating); // Round the average rating down to the nearest whole number
    const decimalPart = rating - roundedRating; // Calculate the decimal part of the average rating
    const stars = [];

    for (let i = 1; i <= roundedRating; i++) {
      stars.push(<StarIcon key={i} sx={{ color: "rgb(255 192 1)" }} />);
    }

    if (decimalPart > 0) {
      const decimalPoints = Math.round(decimalPart * 10) / 10; // Round the decimal part to one decimal point
      stars.push(
        <span
          key="decimal"
          style={{ display: "inline-flex", alignItems: "center" }}
        >
          <StarHalf sx={{ color: "rgb(255 192 1)" }} />
        </span>
      );
    }

    return stars;
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const formattedDate = date.toLocaleDateString(undefined, options);
    return formattedDate;
  };
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [reviewType, setReviewType] = useState("all");

  const handleOpenFilterDialog = () => {
    setOpenFilterDialog(true);
  };

  const handleCloseFilterDialog = () => {
    setOpenFilterDialog(false);
  };

  const handleSortChange = async (event) => {
    const sortValue = event.target.value;
    setSelectedSortValue(sortValue);

    if (sortValue === "Newest") {
      setSortOrder("desc");
    } else if (sortValue === "Oldest") {
      setSortOrder("asc");
    } else {
      setSortOrder("default");
    }

    try {
      const response = await axios.get(
        `${config.SERVER_URL}/api/v1/reviews?sort_by=${sortOrder}&seller_id=${store.seller.id}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (response.status === 200) {
        const filteredReviews = response.data.reviews.filter((data) => {
          if (plans.includes(seller.user.subscriptions[0].plan.name)) {
            if (data.reviewable_type === "ListingAnimal") {
              return data;
            }
          } else {
            return data;
          }
        });
        setReviewData(filteredReviews);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRadioChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    setShowMore(!showMore);
  };

  const handleSeeAllReview = () => {
    setOpenDialog(true);
  };

  const handleReviewTypeButton = async (type) => {
    setReviewType(type);
    let registerRequest;
    try {
      registerRequest = await axios.get(
        `${config.SERVER_URL}/api/v1/reviews?review_type=${type}&seller_id=${store.seller.id}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (registerRequest.status === 200) {
        const filteredReviews = registerRequest.data.reviews.filter((data) => {
          if (plans.includes(seller.user.subscriptions[0].plan.name)) {
            if (data.reviewable_type === "ListingAnimal") {
              return data;
            }
          } else {
            return data;
          }
        });
        setReviewData(filteredReviews);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getStore = async () => {
      try {
        const {
          data: { data },
        } = await axios.get(`${config.SERVER_URL}/api/v1/stores/${storeId}`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        setStore(data.store);
        setPolicy(data.store.policy);
        setAbout(data.store.about);
        loadListings({ sellerId: data.store.seller_id });
        loadServiceListings({ store_id: data.store.seller_id });
        setLoadData(false);
      } catch (err) {
        setLoadData(false);

        console.log(err);
      }
    };
    getStore();
  }, [jwtToken, loadListings, storeId]);

  useEffect(() => {
    const blockUser = async (id) => {
      let registerRequest;
      try {
        registerRequest = await axios.post(
          `${config.SERVER_URL}/api/v1/blocks`,
          {
            block: {
              id,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {}
    };
    if (confirmProp && store) {
      if (store.seller) {
        blockUser(store.seller.user.id);
        setOpenSucessAlert(true);
        navigate("/neighbour");
      }
    }

    return () => {
      setConfirmProp(false);
    };
  }, [confirmProp]);
  useEffect(() => {
    if (listings.length > 0 && !loadData) {
      setDisplayAnimalListings(listings);
    }
  }, [listings]);
  useEffect(() => {
    if (serviceListing.length > 0 && !loadData) {
      setDisplayServiceListings(serviceListing);
    }
  }, [serviceListing]);
  useEffect(() => {
    const fetchSellerReviews = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/v1/reviews?seller_id=${store?.seller_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        if (response.status === 422) {
          console.log("Show error snackbar");
        }
      }

      if (registerRequest && registerRequest.status === 200) {
        const filteredReviews = registerRequest.data.reviews.filter((data) => {
          if (plans.includes(seller.user.subscriptions[0].plan.name)) {
            if (data.reviewable_type === "ListingAnimal") {
              return data;
            }
          } else {
            return data;
          }
        });
        setReviewData(filteredReviews);
      }
    };

    if (store?.seller_id) {
      fetchSellerReviews();
    }
  }, [store]);

  const handleBlockClick = (event) => {
    setOpenBlockModal(true);
  };

  const getCategoryName = (id) => {
    let category;
    if (classes.length > 0) {
      category = classes.filter((cat) => cat.id === id);
    }
    return category[0].name;
  };

  const countRatings = (reviews) => {
    const initialCount = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };

    return reviews.reduce((acc, review) => {
      const rating = review.rating;
      if (rating >= 1 && rating <= 5) {
        acc[rating] += 1;
      }
      return acc;
    }, initialCount);
  };

  useEffect(() => {
    if (store && store?.trophy_cases?.length < 4) {
      setTrophyListing(
        store.trophy_cases.map(({ listing_animal }) => listing_animal)
      );
    }
  }, [store]);

  // useEffect(() => {
  //   loadServiceListings({ store_id: store?.seller?.id });
  // }, [jwtToken, store?.seller?.id]);

  SwiperCore.use([Autoplay]);

  const handleSlideChange = (swiper) => {
    if (swiper.activeIndex === 1 || swiper.activeIndex === 4) {
      setIsPlay(true);
      setIsPlay3(false);
      setIsPlay2(false);
    } else if (swiper.activeIndex === 2) {
      setIsPlay(false);
      setIsPlay2(true);
      setIsPlay3(false);
    } else if (swiper.activeIndex === 3 || swiper.activeIndex === 0) {
      setIsPlay(false);
      setIsPlay2(false);
      setIsPlay3(true);
    }
  };

  const handlePause = () => {
    setIsPlay(false);
  };
  const handleShowAllListings = () => {
    navigate("/listings/seller/" + store.seller_id);
  };

  const handleShowAllServiceListings = () => {
    navigate("/service/listings/seller/" + store.seller_id);
  };

  const arrowStyles = {
    position: "absolute",
    top: "60%",
    transform: "translateY(-50%)",
    width: "40px",
    height: "40px",
    color: "rgb(255, 192, 1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
  };

  const handleCloseBlockModal = () => {
    setOpenBlockModal(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSucessAlert(false);
  };

  const handleAboutReadMore = () => {
    setShowMore(true);
    setShowModal("About");
  };

  const handleCloseDialog = () => {
    setOpenFilterDialog(false);
  };

  const handlePolicyReadMore = () => {
    setShowMore(true);
    setShowModal("Policy");
  };

  const handleReadMoreReview = () => {
    setShowReadMoreReview(!showReadMoreReview);
  };

  const handleResetClick = () => {
    setSelectedFilter("all");
  };

  const ratingCount = countRatings(reviewData);

  const handleApplyFilter = async () => {
    setOpenFilterDialog(false);
    let registerRequest;
    let url;
    if (selectedFilter === "all") {
      url = `${config.SERVER_URL}/api/v1/reviews?seller_id=${store?.seller_id}`;
    } else {
      url = `${config.SERVER_URL}/api/v1/reviews?rating=${selectedFilter}&seller_id=${store?.seller?.id}`;
    }
    try {
      registerRequest = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (registerRequest.status === 200) {
        setReviewData(registerRequest.data.reviews);
        setSelectedFilter("all");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Dialog
        open={openFilterDialog}
        onClose={handleCloseFilterDialog}
        maxWidth="lg"
        sx={{
          backgroundColor: "transparent",
          "& .MuiDialog-paper": {
            width: { md: "20%", xs: "90%" },
            borderRadius: "10px",
            maxHeight: {
              md: "68%",
              xs: "60%",
              sm: "58%",
            },
          },
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={12}>
              <Box display={"flex"}>
                <Box
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ flex: 1 }}
                >
                  <Typography
                    variant="h5"
                    textAlign={"center"}
                    fontSize={{ xs: "16px", md: "20px" }}
                    sx={{ fontWeight: "bolder" }}
                  >
                    Review Filter
                  </Typography>
                </Box>
                <Box alignItems={"flex-end"} justifyContent={"flex-end"}>
                  <Box>
                    <IconButton>
                      <ClearIcon onClick={handleCloseFilterDialog} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
        // sx={{
        //   height: "500px",

        // }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
              Rating
            </Typography>
            <Box>
              <Button
                variant="text"
                color="primary"
                onClick={handleResetClick}
                sx={{ fontWeight: "bold" }}
              >
                Reset
              </Button>
            </Box>
          </Box>
          <RadioGroup
            aria-label="options"
            name="options"
            value={selectedFilter}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel value="5" control={<Radio />} label="5 Stars" />
            <FormControlLabel value="4" control={<Radio />} label="4 Stars" />
            <FormControlLabel value="3" control={<Radio />} label="3 Stars" />
            <FormControlLabel value="2" control={<Radio />} label="2 stars" />
            <FormControlLabel value="1" control={<Radio />} label="1 stars" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyFilter}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      <SeeAllReviews
        data={reviewData}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
      <Snackbar
        open={openSucessAlert}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert onClose={handleClose} severity="success">
            Message Sent Successfully!
          </Alert>
        </div>
      </Snackbar>
      <Box
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <Modal
          open={showMore}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            left: { xs: "5%", sm: "5%", md: "10%", lg: "15%" },
            top: { xs: "5%", sm: "5%", md: "25%", lg: "25%", xl: "25%" },

            width: { xs: "90%", sm: "90%", md: "80%", lg: "70%" },
            height: { xs: "90%", md: "450px" },
            overflow: "scroll",
          }}
        >
          <div style={{ position: "relative" }}>
            <IconButton
              onClick={handleCloseModal}
              style={{ position: "absolute", top: "0", right: "0" }}
            >
              <Close />
            </IconButton>
            <Card
              display="flex"
              sx={{
                margin: "auto",
              }}
            >
              <CardContent>
                <Box display="flex">
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "45px",
                        pb: 2,
                        color: "#000000",
                        textAlign: "center",
                      }}
                    >
                      {showModal === "About"
                        ? `About - ${store.brand_name}`
                        : `${store.brand_name} - Policy`}
                    </Typography>
                    <Typography align="justify" sx={{ lineHeight: "20px" }}>
                      {showModal === "About" ? about : policy}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </div>
        </Modal>
      </Box>
      <Snackbar
        open={openSucessAlert}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert onClose={handleClose} severity="success">
            User Blocked Successfully!
          </Alert>
        </div>
      </Snackbar>
      <CustomModal
        title="Block Alert"
        content={
          "Are you sure you want to block this user? This will remove all the conversations, related listings and neighbour stores."
        }
        openModal={openBlockModal}
        closeModal={handleCloseBlockModal}
        actionTitle={"Block"}
        setConfirmProp={setConfirmProp}
        actionColor={"red"}
      />
      {store && (
        <Stack>
          <Stack
            sx={{
              marginX: { xs: "20px", md: "100px" },
              marginTop: 4,
              marginBottom: 4,
              overflowX: "hidden",
            }}
          >
            <Grid container display="flex" direction="row" spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                sx={{
                  mt: 4,
                }}
              >
                <Grid xs={12}>
                  <img
                    src={store.brand_logo && store.brand_logo.url}
                    alt="testing"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "400px",
                      borderRadius: "10px",
                    }}
                  />
                </Grid>
                <Box display={"flex"} flex={1}>
                  <Box flex={0.8}>
                    <Typography
                      variant="h5"
                      component={"h5"}
                      sx={{ fontSize: "18px", fontWeight: "bold", mt: 2 }}
                    >
                      {store.brand_name}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="p"
                  component={"p"}
                  sx={{ fontSize: "14px", mt: 2 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Grid container pr={1}>
                        <PersonIcon
                          fontSize="small"
                          sx={{ pr: 0.5, color: "grey" }}
                        />
                        <Typography
                          variant="p"
                          component={"p"}
                          sx={{ fontSize: "15px" }}
                        >
                          {store.owner_name}
                        </Typography>
                      </Grid>
                    </Grid>
                    {store.delivery_option && (
                      <>
                        {store.delivery_option.includes("local_pickup") && (
                          <Grid item xs={6}>
                            <Grid container pr={1}>
                              <DirectionsCarIcon
                                fontSize="small"
                                sx={{ pr: 0.5, color: "grey" }}
                              />
                              <Typography
                                variant="p"
                                component={"p"}
                                sx={{ fontSize: "14px" }}
                              >
                                Local Pickup
                              </Typography>
                            </Grid>
                          </Grid>
                        )}

                        {store.delivery_option.includes("regional_pickup") && (
                          <Grid item xs={6}>
                            <Grid container pr={1}>
                              <LocalShippingIcon
                                fontSize="small"
                                sx={{ pr: 0.5, color: "grey" }}
                              />
                              <Typography
                                variant="p"
                                component={"p"}
                                sx={{ fontSize: "14px" }}
                              >
                                Regional Pickup
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </Typography>
                <Typography
                  variant="p"
                  component={"p"}
                  sx={{ fontSize: "14px", mt: 2 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Grid container>
                        <LocalPhoneIcon
                          fontSize="small"
                          sx={{ pr: 0.5, color: "grey" }}
                        />
                        <Typography
                          variant="p"
                          component={"p"}
                          sx={{ fontSize: "14px" }}
                        >
                          {store.phone_number}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Typography>
                <Grid container sx={{ mt: 2 }}>
                  {store.payment_methods && (
                    <Grid item>
                      <Grid container pr={1}>
                        <PaymentIcon
                          fontSize="small"
                          sx={{ pr: 0.5, color: "grey" }}
                        />
                        <Typography
                          variant="p"
                          component={"p"}
                          sx={{ fontSize: "14px" }}
                        >
                          {store.payment_methods.join(", ")}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid container sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <Typography
                      variant="p"
                      component={"p"}
                      align="left"
                      sx={{ fontSize: "14px" }}
                    >
                      <b>Location:</b>&nbsp;{store.location}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      component="p"
                      style={{
                        textAlign: "justify",
                        fontSize: "14px",
                        position: "relative",
                      }}
                    >
                      <b>About:</b>&nbsp;
                      {about.length > 500
                        ? about.substring(0, 500) + "..."
                        : about}
                      {about.length > 500 && (
                        <Button variant="text" onClick={handleAboutReadMore}>
                          Read More
                        </Button>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      component="p"
                      style={{
                        textAlign: "justify",
                        fontSize: "14px",
                        position: "relative",
                      }}
                    >
                      <b>Policy:</b>&nbsp;
                      {policy.length > 500
                        ? policy.substring(0, 500) + "..."
                        : policy}
                      {policy.length > 500 && (
                        <Button variant="text" onClick={handlePolicyReadMore}>
                          Read More
                        </Button>
                      )}
                    </Typography>
                  </Grid>
                </Grid>

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: 3 }}
                >
                  {store.twitter_url && (
                    <Link
                      to={store.twitter_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton sx={{ border: "1px solid grey", mr: 1 }}>
                        <Twitter />
                      </IconButton>
                    </Link>
                  )}
                  {store.youtube_url && (
                    <Link
                      to={store.youtube_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton sx={{ border: "1px solid grey", mr: 1 }}>
                        <YouTube />
                      </IconButton>
                    </Link>
                  )}
                  {store.facebook_url && (
                    <Link
                      to={store.facebook_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton sx={{ border: "1px solid grey", mr: 1 }}>
                        <Facebook />
                      </IconButton>
                    </Link>
                  )}
                  {store.instagram_url && (
                    <Link
                      to={store.instagram_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton sx={{ border: "1px solid grey", mr: 1 }}>
                        <Instagram />
                      </IconButton>
                    </Link>
                  )}
                </Box>
                {user?.id !== store?.seller?.user?.id && (
                  <Box
                    display={"flex"}
                    flex={0.2}
                    justifyContent={"center"}
                    mt={3}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={() => handleBlockClick(user.id)}
                      sx={{
                        marginBottom: "8px !important",
                        // marginRight: { md: "100px" },
                        height: "40px",
                        color: "white",
                        fontSize: "15px !important",
                        fontWeight: "400 !important",
                        textTransform: "none !important",
                        width: "100px",
                        "&:hover": {
                          backgroundColor: "rgb(255, 192, 1)",
                          color: "white !important",
                        },
                      }}
                    >
                      BLOCK
                    </Button>
                  </Box>
                )}
                <Box display={"flex"} flex={1} mt={4}>
                  <Box flex={0.6}>
                    <Typography
                      variant="p"
                      component="p"
                      sx={{ fontSize: "16px", fontWeight: "bold" }}
                    >
                      Rating
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="left"
                      alignItems="center"
                      sx={{ mt: 1 }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        sx={{ fontSize: "16px" }}
                      >
                        {averageRating}
                      </Typography>
                      <Box sx={{ ml: 0.5 }}>
                        {renderStarRating(averageRating)}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    flex={0.4}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <FormControl
                      sx={{
                        marginBottom: "8px !important",
                        height: "40px",
                        pr: 2,
                      }}
                    >
                      <Select
                        labelId="dropdown-label"
                        id="dropdown-button"
                        value={selectedSortValue}
                        sx={{ height: "30px" }}
                        onChange={handleSortChange}
                      >
                        <MenuItem value="Newest">Newest</MenuItem>
                        <MenuItem value="Oldest">Oldest</MenuItem>
                        <MenuItem value="Default">Default</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                      type="submit"
                      variant="contained"
                      startIcon={<TuneIcon sx={{ color: "white" }} />}
                      onClick={handleOpenFilterDialog}
                      sx={{
                        marginBottom: "8px !important",
                        // marginRight: { md: "100px", xs: "15px" },
                        height: "30px",
                        color: "white",
                        fontSize: "15px !important",
                        fontWeight: "400 !important",
                        textTransform: "none !important",
                        width: "100px",
                        "&:hover": {
                          backgroundColor: "rgb(255, 192, 1)",
                          color: "white !important",
                        },
                      }}
                    >
                      FILTER
                    </Button>
                  </Box>
                </Box>
                <Box
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    height: "30px",
                    display: "flex",
                  }}
                  pt={2}
                >
                  <Button
                    sx={{
                      color: "white",
                      marginLeft: "10px",
                      backgroundColor:
                        reviewType !== "all" ? "#D3D3D3" : "#FFC001",
                      "&:hover": {
                        backgroundColor:
                          reviewType !== "all" ? "#D3D3D3" : "#FFC001", // Customize the hover background color
                      },
                    }}
                    onClick={() => handleReviewTypeButton("all")}
                  >
                    <Typography
                      sx={{ fontSize: { xs: "10px", md: "8px", lg: "12px" } }}
                    >
                      All Reviews
                    </Typography>
                  </Button>
                  <Button
                    sx={{
                      color: "white",
                      marginLeft: "10px",

                      backgroundColor:
                        reviewType !== "animal" ? "#D3D3D3" : "#FFC001",
                      "&:hover": {
                        backgroundColor:
                          reviewType !== "animal" ? "#D3D3D3" : "#FFC001", // Customize the hover background color
                      },
                    }}
                    onClick={() => handleReviewTypeButton("animal")}
                  >
                    <Typography
                      sx={{ fontSize: { xs: "10px", md: "8px", lg: "12px" } }}
                    >
                      Animal Reviews
                    </Typography>
                  </Button>
                  <Button
                    sx={{
                      color: "white",
                      marginLeft: "10px",

                      backgroundColor:
                        reviewType !== "service" ? "#D3D3D3" : "#FFC001",
                      "&:hover": {
                        backgroundColor:
                          reviewType !== "service" ? "#D3D3D3" : "#FFC001", // Customize the hover background color
                      },
                    }}
                    onClick={() => handleReviewTypeButton("service")}
                  >
                    <Typography
                      sx={{ fontSize: { xs: "10px", md: "8px", lg: "12px" } }}
                    >
                      Service Reviews
                    </Typography>
                  </Button>
                </Box>
                <Box mt={3}>
                  <Box display="flex" alignItems="center">
                    <Box flex={0.1}>
                      <Rating
                        name="rating"
                        value={5}
                        max={5}
                        precision={1}
                        icon={<Star sx={{ color: "rgb(255, 192, 1)" }} />}
                        emptyIcon={<Star />}
                        readOnly
                      />
                    </Box>
                    <Box flex={0.9} pl={1} pr={1}>
                      <Slider
                        classes={{ root: styleclasses.root }}
                        value={ratingCount[5]}
                        min={0}
                        max={reviewData?.length}
                        onChange={(event, value) => {}}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box flex={0.1}>
                      <Rating
                        name="rating"
                        value={4}
                        max={5}
                        precision={1}
                        icon={<Star sx={{ color: "rgb(255, 192, 1)" }} />}
                        emptyIcon={<Star />}
                        readOnly
                      />
                    </Box>
                    <Box flex={0.9} pl={1} pr={1}>
                      <Slider
                        classes={{ root: styleclasses.root }}
                        value={ratingCount[4]}
                        min={0}
                        max={reviewData?.length}
                        onChange={(event, value) => {}}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box flex={0.1}>
                      <Rating
                        name="rating"
                        value={3}
                        max={5}
                        precision={1}
                        icon={<Star sx={{ color: "rgb(255, 192, 1)" }} />}
                        emptyIcon={<Star />}
                        readOnly
                      />
                    </Box>
                    <Box flex={0.9} pl={1} pr={1}>
                      <Slider
                        classes={{ root: styleclasses.root }}
                        value={ratingCount[3]}
                        min={0}
                        max={reviewData?.length}
                        onChange={(event, value) => {}}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box flex={0.1}>
                      <Rating
                        name="rating"
                        value={2}
                        max={5}
                        precision={1}
                        icon={<Star sx={{ color: "rgb(255, 192, 1)" }} />}
                        emptyIcon={<Star />}
                        readOnly
                      />
                    </Box>
                    <Box flex={0.9} pl={1} pr={1}>
                      <Slider
                        classes={{ root: styleclasses.root }}
                        value={ratingCount[2]}
                        min={0}
                        max={reviewData?.length}
                        onChange={(event, value) => {}}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box flex={0.1}>
                      <Rating
                        name="rating"
                        value={1}
                        max={5}
                        precision={1}
                        icon={<Star sx={{ color: "rgb(255, 192, 1)" }} />}
                        emptyIcon={<Star />}
                        readOnly
                      />
                    </Box>
                    <Box flex={0.9} pl={1} pr={1}>
                      <Slider
                        classes={{ root: styleclasses.root }}
                        value={ratingCount[1]}
                        min={0}
                        max={reviewData?.length}
                        onChange={(event, value) => {}}
                      />
                    </Box>
                  </Box>
                </Box>
                <Typography
                  variant="p"
                  component="p"
                  sx={{ fontSize: "16px", mt: 4 }}
                >
                  <b> Reviews </b> ({reviewData.length})
                </Typography>

                {reviewData.slice(0, 3).map((data) => {
                  return (
                    <>
                      <Divider
                        sx={{ marginBottom: "10px", marginTop: "10px" }}
                      />

                      <Box flex={1} display={"flex"} mt={2}>
                        <Box display={"flex"}>
                          <Avatar alt="Remy Sharp" src={data.user.avatar_url} />
                        </Box>
                        <Box pl={1}>
                          <Typography fontWeight={"bold"}>
                            {data.user.full_name}
                          </Typography>

                          <Typography fontSize={"12px"} color={"grey"}>
                            {formatDate(data.created_at)} . &nbsp;
                            <Link
                              to={
                                data.reviewable_type === "ListingService"
                                  ? `/services/${data.reviewable.category_or_service.category.id}/subService/${data.reviewable.category_or_service.sub_category.id}/listings/${data.reviewable.id}`
                                  : "/listing/" + data.reviewable.id
                              }
                              style={{
                                color: "rgb(255, 192, 1)",
                              }}
                            >
                              {data.reviewable.name}
                            </Link>
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Rating
                          name="simple-controlled"
                          value={data.rating}
                          sx={{ fontSize: "16px" }}
                          readOnly
                        />
                      </Box>
                      <Box style={{ wordBreak: "break-all" }}>
                        <Typography>
                          {data.content.length > 150 && !showReadMoreReview
                            ? data.content.substring(0, 150)
                            : data.content}
                          {!showReadMoreReview && data.content.length > 150 && (
                            <Button
                              varient="text"
                              onClick={handleReadMoreReview}
                              sx={{ textTransform: "none" }}
                            >
                              Read More
                            </Button>
                          )}
                          {showReadMoreReview && data.content.length > 150 && (
                            <Button
                              varient="text"
                              onClick={handleReadMoreReview}
                              sx={{ textTransform: "none" }}
                            >
                              Read less
                            </Button>
                          )}
                        </Typography>
                      </Box>
                    </>
                  );
                })}
                {reviewData.length > 2 && (
                  <>
                    <Box
                      justifyContent={"center"}
                      alignItems={"center"}
                      display={"flex"}
                      mt={2}
                    >
                      <Button variant="text" onClick={handleSeeAllReview}>
                        See All
                      </Button>
                    </Box>
                  </>
                )}
                {reviewData.length === 0 && (
                  <Typography textAlign={"center"}>No review found</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Grid
                  container
                  alignContent="center"
                  direction="row"
                  sx={{
                    marginTop: 4,
                  }}
                >
                  {store.store_cover && store.store_cover.length > 1 ? (
                    <Swiper
                      ref={swiperRef}
                      style={{
                        "--swiper-navigation-color": "rgb(255, 192, 1)",
                        "--swiper-pagination-color": "rgb(255, 192, 1)",
                      }}
                      onSlideChange={handleSlideChange}
                      navigation={true}
                      modules={[Navigation, Scrollbar]}
                      grabCursor={true}
                      autoplay={true}
                      centeredSlides={true}
                      // loopAdditionalSlides={3}
                      loop={true}
                    >
                      {store.store_cover.map((video, index) =>
                        index === 0 ? (
                          <SwiperSlide>
                            <ReactPlayer
                              ref={playerRef}
                              playing={isPlay}
                              url={video}
                              controls={true}
                              onPause={handlePause}
                              width={"100%"}
                              height={"40vh"}
                              style={{
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                              onReady={handlePlayerReady}
                            />
                          </SwiperSlide>
                        ) : (
                          <SwiperSlide>
                            <ReactPlayer
                              url={video}
                              playing={index === 1 ? isPlay2 : isPlay3}
                              controls={true}
                              width={"100%"}
                              height={"40vh"}
                              style={{
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                            />
                          </SwiperSlide>
                        )
                      )}
                    </Swiper>
                  ) : (
                    <ReactPlayer
                      url={
                        store.store_cover && store.store_cover[0]
                          ? store.store_cover[0]
                          : "https://youtu.be/w-sPllijSRU"
                      }
                      playing={true}
                      controls={true}
                      width={"100%"}
                      height={"40vh"}
                      style={{
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                    />
                  )}
                </Grid>

                {store?.trophy_cases?.length > 0 && (
                  <>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        variant="h5"
                        component="p"
                        sx={{ fontWeight: "700", m: 1 }}
                      >
                        Trophy Case
                      </Typography>
                    </Box>
                    <Grid
                      container
                      alignContent="center"
                      // justifyContent="center"
                      direction="row"
                      sx={{
                        marginTop: 4,
                        position: "relative",
                      }}
                    >
                      {store?.trophy_cases && store.trophy_cases.length > 3 && (
                        <>
                          <div
                            className="swiper-button-prev"
                            style={{ ...arrowStyles, left: 0 }}
                          ></div>
                          <div
                            className="swiper-button-next"
                            style={{ ...arrowStyles, right: 0 }}
                          ></div>
                        </>
                      )}
                      <Grid container paddingX={"45px"}>
                        {store?.trophy_cases &&
                          store.trophy_cases.length > 3 && (
                            <Swiper
                              style={{
                                "--swiper-navigation-color": "rgb(255, 192, 1)",
                                "--swiper-pagination-color": "rgb(255, 192, 1)",
                              }}
                              navigation={{
                                nextEl: ".swiper-button-next",
                                prevEl: ".swiper-button-prev",
                              }}
                              modules={[Navigation, Scrollbar]}
                              grabCursor={true}
                              autoplay={true}
                              loop={true}
                              slidesPerView={4}
                              breakpoints={{
                                320: { slidesPerView: 1 },
                                480: { slidesPerView: 1 },
                                600: { slidesPerView: 2 },
                                900: { slidesPerView: 2 },
                                1200: { slidesPerView: 4 },
                              }}
                            >
                              {store.trophy_cases &&
                                store.trophy_cases.map(({ listing_animal }) => {
                                  return (
                                    <SwiperSlide>
                                      <Grid>
                                        <Box
                                          sx={{
                                            margin: { md: "2px" },
                                          }}
                                        >
                                          <Link
                                            to={"/listing/" + listing_animal.id}
                                            style={{
                                              textDecoration: "none",
                                            }}
                                          >
                                            <Card
                                              sx={{
                                                border: "0.2px solid #eeeeee",
                                                borderRadius: "10px",
                                                cursor: "pointer",
                                                transition: "0.3s all",
                                                "&:hover": {
                                                  color: "rgb(255, 192, 1)",
                                                  backgroundColor: "#eeeeee",
                                                },
                                              }}
                                            >
                                              <div
                                                className={
                                                  styleclasses.imageContainer
                                                }
                                              >
                                                <CardMedia
                                                  sx={{ height: 150 }}
                                                  image={
                                                    listing_animal.media[0].url
                                                  }
                                                  title={listing_animal.name}
                                                />
                                                <div
                                                  className={
                                                    (listing_animal.status ===
                                                      "sold" &&
                                                      styleclasses.soldOutStripeText) ||
                                                    (listing_animal.status ===
                                                      "nfs" &&
                                                      styleclasses.nfsStripeText) ||
                                                    (listing_animal.status ===
                                                      "unavailable" &&
                                                      styleclasses.nfsStripeText)
                                                  }
                                                >
                                                  {listing_animal.status.toUpperCase()}
                                                </div>
                                              </div>

                                              <CardContent sx={{ height: 80 }}>
                                                <Grid xs={12}>
                                                  <Typography
                                                    variant="h6"
                                                    noWrap
                                                    fontWeight="bold"
                                                  >
                                                    {listing_animal.name}
                                                  </Typography>
                                                </Grid>
                                                <Grid xs={12}>
                                                  <Typography
                                                    variant="p"
                                                    noWrap
                                                    sx={{ color: "grey" }}
                                                  >
                                                    {getCategoryName(
                                                      listing_animal.category_id
                                                    )}
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  container
                                                  sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Grid item xs="auto">
                                                    <Typography
                                                      sx={{
                                                        fontSize: "18px",
                                                        color: "grey",
                                                        fontFamily: "SharpSans",
                                                      }}
                                                    >
                                                      {formatDate(
                                                        listing_animal.created_at
                                                      )}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs
                                                    fontSize="medium"
                                                  >
                                                    {listing_animal.sex ===
                                                    "male" ? (
                                                      <MaleIcon
                                                        sx={{ color: "blue" }}
                                                      />
                                                    ) : listing_animal.sex ===
                                                      "female" ? (
                                                      <FemaleIcon
                                                        sx={{
                                                          color: "#AA336A",
                                                        }}
                                                      />
                                                    ) : (
                                                      <>
                                                        <MaleIcon
                                                          sx={{ color: "blue" }}
                                                        />
                                                        <FemaleIcon
                                                          sx={{
                                                            color: "#AA336A",
                                                          }}
                                                        />
                                                      </>
                                                    )}
                                                  </Grid>
                                                  <Grid item>
                                                    <Typography
                                                      sx={{
                                                        fontSize: {
                                                          xs: "22px",
                                                          md: "24px",
                                                          lg: "27px",
                                                        },
                                                        fontFamily: "SharpSans",
                                                        textAlign: "right",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      ${listing_animal.price}
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              </CardContent>
                                            </Card>
                                          </Link>
                                        </Box>
                                      </Grid>
                                    </SwiperSlide>
                                  );
                                })}
                              {/* </Box> */}
                            </Swiper>
                          )}
                      </Grid>
                      {trophyListings.length > 0 && (
                        <Grid
                          sx={{
                            width: "100%",
                            backgroundColor: "transparent",
                            mt: 2,
                            display: "grid",
                            gap: 2,
                            gridTemplateColumns: {
                              xs: "repeat(2, minmax(0, 1fr))",
                              sm: "repeat(3, minmax(0, 1fr))",
                              md: "repeat(3, minmax(0, 1fr))",
                              lg: "repeat(4, minmax(0, 1fr))",
                            },
                            [`& .${imageListItemClasses.root}`]: {
                              display: "flex",
                              flexDirection: "column",
                            },
                          }}
                        >
                          <ListCard
                            listData={trophyListings}
                            classes={classes}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
                {displayAnimalListings.length > 0 && (
                  <Grid>
                    <Typography
                      variant="h5"
                      component="p"
                      sx={{ fontWeight: "700", mt: 4, textAlign: "center" }}
                    >
                      Recent Animal Listings
                    </Typography>
                    <Grid
                      sx={{
                        width: "100%",
                        backgroundColor: "transparent",
                        mt: 2,
                        display: "grid",
                        gap: 2,
                        gridTemplateColumns: {
                          xs: "repeat(2, minmax(0, 1fr))",
                          sm: "repeat(3, minmax(0, 1fr))",
                          md: "repeat(3, minmax(0, 1fr))",
                          lg: "repeat(4, minmax(0, 1fr))",
                        },
                        [`& .${imageListItemClasses.root}`]: {
                          display: "flex",
                          flexDirection: "column",
                        },
                      }}
                    >
                      <ListCard
                        listData={displayAnimalListings.slice(0, 4)}
                        classes={classes}
                      />
                    </Grid>
                    {displayAnimalListings.length > 0 && (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box
                          component={Button}
                          size="large"
                          variant="contained"
                          onClick={handleShowAllListings}
                          sx={{
                            textAlign: "center",
                            marginTop: "30px",
                            bgcolor: "#FFC001",
                            width: { xs: "70vw", md: "20vw" },
                            height: 40,
                            color: "#fff",
                            fontSize: 15,
                            fontWeight: 700,
                            textTransform: "none !important",
                            transition: "0.3s all",
                          }}
                        >
                          See All Animal Listings
                        </Box>
                      </Box>
                    )}
                  </Grid>
                )}

                {displayServiceListings.length > 0 && (
                  <Grid>
                    <Typography
                      variant="h5"
                      component="p"
                      sx={{ fontWeight: "700", mt: 4, textAlign: "center" }}
                    >
                      Recent Service Listings
                    </Typography>
                    <Grid
                      sx={{
                        width: "100%",
                        backgroundColor: "transparent",
                        mt: 2,
                        display: "grid",
                        gap: 2,
                        gridTemplateColumns: {
                          xs: "repeat(2, minmax(0, 1fr))",
                          sm: "repeat(3, minmax(0, 1fr))",
                          md: "repeat(3, minmax(0, 1fr))",
                          lg: "repeat(4, minmax(0, 1fr))",
                        },
                        [`& .${imageListItemClasses.root}`]: {
                          display: "flex",
                          flexDirection: "column",
                        },
                      }}
                    >
                      <ServiceListCard
                        listData={displayServiceListings.slice(0, 4)}
                        classes={classes}
                      />
                    </Grid>
                    {displayServiceListings.length > 0 && (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box
                          component={Button}
                          size="large"
                          variant="contained"
                          onClick={handleShowAllServiceListings}
                          sx={{
                            textAlign: "center",
                            marginTop: "30px",
                            bgcolor: "#FFC001",
                            width: { xs: "70vw", md: "20vw" },
                            height: 40,
                            color: "#fff",
                            fontSize: 15,
                            fontWeight: 700,
                            textTransform: "none !important",
                            transition: "0.3s all",
                          }}
                        >
                          See All Service Listings
                        </Box>
                      </Box>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      )}
    </>
  );
};

const mapStateToProps = ({ Auth, Seller, Listings, Categories }) => ({
  user: Auth.user,
  jwtToken: Auth.jwtToken,
  seller: Seller.seller,
  listings: Listings.listings,
  classes: Categories.classes,
  serviceListing: Listings.serviceListing,
});

const mapDispatchToProps = (dispatch) => ({
  setStore: (data) => dispatch(Actions.setStore(data)),
  loadListings: (sellerId) => dispatch(Actions.thunkLoadListing(sellerId)),
  loadServiceListings: ({ store_id }) =>
    dispatch(Actions.thunkLoadServiceListings({ store_id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreDetails);

const useStyles = makeStyles({
  imageContainer: {
    position: "relative",
    width: "100%",
  },
  root: {
    "& .MuiSlider-thumb": {
      display: "none",
    },
  },
  onHoldText: {
    position: "absolute",
    top: 30,
    right: -25,
    width: "100px",
    textAlign: "center",
    backgroundColor: "#FFD300",
    color: "#fff",
    padding: "5px",
    fontSize: "14px",
    transform: "translate(-0%, 100%) rotate(45deg)",
    transformOrigin: "top right",
  },
  soldOutStripeText: {
    position: "absolute",
    top: 30,
    right: -25,
    width: "100px",
    textAlign: "center",
    backgroundColor: "#EF0107",
    color: "#fff",
    padding: "5px",
    fontSize: "14px",
    transform: "translate(-0%, 100%) rotate(45deg)",
    transformOrigin: "top right",
  },
  nfsStripeText: {
    position: "absolute",
    top: 30,
    right: -25,
    width: "100px",
    textAlign: "center",
    backgroundColor: "black",
    color: "#fff",
    padding: "5px",
    fontSize: "14px",
    transform: "translate(-0%, 100%) rotate(45deg)",
    transformOrigin: "top right",
  },
  logo: {
    transition: "0.5s ease",
    backfaceVisibility: "hidden",
    objectFit: "contain",
    backgroundColor: "transparent",
    // paddingTop: "10px",
    // paddingRight: "20px",
    // paddingBottom: "20px",
    width: "100%",
    maxWidth: "500px",
    float: "left",
  },
  "@media (min-width: 250px)": {
    logo: {
      width: "100%" /* Width for medium-sized screens */,
      maxWidth: "100%",
    },
  },
  "@media (min-width: 800px)": {
    logo: {
      width: "100%" /* Width for larger screens */,
      maxWidth: "500px",
    },
  },
});
