import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Button,
  Box,
  List,
  ListItem,
  Checkbox,
  ButtonGroup,
  Tooltip,
  Grid,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PricingDialog from "./PricingDialog";
import { motion } from "framer-motion";

const PricingCard = ({
  plan,
  user,
  planType,
  handleShowYearly,
  handleShowMonthly,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Card
          sx={{
            minHeight: "500px",
          }}
        >
          <CardContent>
            <Box>
              <motion.Grid
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ duration: 2 }}
              >
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {plan.name}
                </Typography>
              </motion.Grid>
              <motion.Grid
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ duration: 2 }}
              >
                <Typography variant="h3" sx={{ fontWeight: 900 }}>
                  {`$${plan.price_cents / 100}`}
                </Typography>
              </motion.Grid>
              <motion.Grid
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ duration: 2 }}
              >
                <Typography variant="p">
                  per{" "}
                  {plan.name === "Diamond Special" ? "5 years" : plan.interval}
                </Typography>
              </motion.Grid>
            </Box>
            {plan.name !== "Diamond Special" && (
              <Box
                style={{ flex: 1, justifyContent: "center", display: "flex" }}
                pt={2}
              >
                <ButtonGroup
                  variant="contained"
                  aria-label="Disabled elevation buttons"
                >
                  <Button
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      backgroundColor:
                        planType === "month" &&
                        plan.interval === "month" &&
                        "#D3D3D3",
                    }}
                    onClick={() => handleShowYearly(plan.id)}
                  >
                    YEARLY
                  </Button>
                  <Button
                    sx={{
                      color: "white",
                      backgroundColor:
                        planType === "year" &&
                        plan.interval === "year" &&
                        "#D3D3D3",
                    }}
                    onClick={() => handleShowMonthly(plan.id)}
                  >
                    MONTHLY
                  </Button>
                </ButtonGroup>
              </Box>
            )}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{ textAlign: "center", marginTop: 2 }}
            >
              <List>
                {plan.offers &&
                  plan.offers.slice(0, 8).map((offer) => {
                    return (
                      <ListItem display="inline" key={offer.id} disablePadding>
                        <motion.Grid
                          initial={{ opacity: 0, x: -100 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: 100 }}
                          transition={{ duration: 2 }}
                        >
                          {" "}
                          <Checkbox
                            defaultChecked={offer.value}
                            disabled
                            sx={{ color: "rgb(255, 192, 1) !important" }}
                          />
                        </motion.Grid>
                        <motion.Grid
                          initial={{ opacity: 0, x: -100 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: 100 }}
                          transition={{ duration: 2 }}
                        >
                          <Typography display="inline">{offer.name}</Typography>
                        </motion.Grid>
                        <motion.Grid
                          initial={{ opacity: 0, x: -100 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: 100 }}
                          transition={{ duration: 1 }}
                        >
                          <Tooltip
                            title={offer.description}
                            arrow
                            followCursor
                            enterTouchDelay={0}
                          >
                            <InfoIcon
                              sx={{
                                width: "16px",
                                m: 0.5,
                                "&:hover": {
                                  cursor: "pointer",
                                  color: "rgb(255, 192, 1)",
                                },
                              }}
                            />
                          </Tooltip>
                        </motion.Grid>
                      </ListItem>
                    );
                  })}
              </List>
              <Button
                onClick={handleClickOpen()}
                variant="contained"
                sx={{ marginTop: 2 }}
              >
                Read More
              </Button>
              <Typography variant="p" sx={{ marginTop: 1 }}>
                save $22 per year
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <PricingDialog
        plan={plan}
        open={open}
        user={user}
        handleClose={handleClose}
        descriptionElementRef={descriptionElementRef}
      />
    </>
  );
};

export default PricingCard;
