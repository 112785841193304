import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  InputAdornment,
  Box,
  CircularProgress,
  Stack,
  Alert,
  Chip,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";
import Backdrop from "@mui/material/Backdrop";
import { connect } from "react-redux";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import config from "../../config";
import ForgotPasswordModal from "../../components/login/ForgotPasswordModal";
import * as Actions from "../../redux/actions";
import Snackbar from "@mui/material/Snackbar";
import { set } from "lodash";

const Login = ({
  loginUser,
  user,
  responseError,
  responseGoogle,
  responseFacebook,
  responseApple,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [openForgotPassModal, setOpenForgotPassModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const hideAppleLogin = true;
  const hideFacebookLogin = true;

  useEffect(() => {
    if (responseError != "" && responseError) {
      setLoading(false);
      setSnackbarStates({
        open: true,
        message: responseError,
        severity: "danger",
      });
    }
    setLoading(false);
  }, [responseError]);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleOpenForgotPassModal = () => {
    setOpenForgotPassModal(true);
  };

  const handleCloseForgotPassModal = () => {
    setOpenForgotPassModal(false);
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (response) => {
      responseGoogle(response);
    },
  });
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };
  const handleFacebookLogin = (response) => {
    responseFacebook(response);
  };

  const handleAppleLogin = (response) => {
    responseApple(response);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const fields = ["email", "password"];
    const formElements = e.target.elements;

    const formValues = fields
      .map((field) => ({
        [field]: formElements.namedItem(field).value,
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    loginUser({ email: formValues.email, password: formValues.password });
  };

  if (user && user.id) {
    navigate("/");
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress sx={{ color: "rgb(255 192 1)" }} />
      </Backdrop>
      <Snackbar
        open={snackbarStates.open}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <Stack style={{ backgroundColor: "#ebecf0" }}>
        <ForgotPasswordModal
          openModal={openForgotPassModal}
          handleCloseModal={handleCloseForgotPassModal}
        />

        <Grid
          container
          direction="column"
          justifyContent="center"
          alginItem="center"
        >
          <Grid item display="flex">
            <Card
              sx={{
                padding: "40px",
                margin: "auto",
                width: { xs: "80%", sm: "45%", md: "30%", lg: "25%" },
              }}
            >
              <CardContent>
                <Typography
                  variant="h4"
                  align="center"
                  className={classes.headerStyle}
                >
                  Log In
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Typography component={"p"}>Email</Typography>

                  <TextField
                    helperText={
                      responseError && (
                        <span style={{ color: "red" }}>{responseError}</span>
                      )
                    }
                    placeholder="Enter email"
                    name="email"
                    type="email"
                    size="small"
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.textField,
                      },
                    }}
                    required
                  />
                  <Typography component={"p"} sx={{ mt: "15px" }}>
                    Password
                  </Typography>
                  <TextField
                    placeholder="Enter password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    size="small"
                    InputProps={{
                      classes: {
                        root: classes.textField,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                  <Box
                    display="flex"
                    justifyContent="right"
                    alginItem="right"
                    sx={{ mt: 1, mb: 2 }}
                  >
                    <Link
                      to="#"
                      className={classes.links}
                      onClick={handleOpenForgotPassModal}
                    >
                      Forgot Password?
                    </Link>
                  </Box>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.loginButton}
                    fullWidth
                  >
                    Login
                  </Button>
                </form>

                <hr style={{ border: "0.02px solid #C5C5C5" }}></hr>
                <Button
                  startIcon={
                    <Box sx={{ display: "flex" }}>
                      <img
                        style={{ width: "25px" }}
                        src="https://res.cloudinary.com/dwezij7u7/image/upload/v1674836348/Moore%20Exotics%20Assets/login/google-icon_tg10fg.png"
                        alt="facebook"
                      />
                    </Box>
                  }
                  onClick={handleGoogleLogin}
                  variant="outlined"
                  className={classes.ssoLoginButton}
                  fullWidth
                >
                  Log In with Google
                </Button>
                {!hideFacebookLogin && (
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    fields="name,email,picture"
                    autoLoad={false}
                    scope="public_profile, email, user_birthday"
                    returnScopes={true}
                    callback={handleFacebookLogin}
                    render={(renderProps) => (
                      <Button
                        onClick={renderProps.onClick}
                        startIcon={
                          <Box sx={{ display: "flex" }}>
                            <img
                              style={{ width: "30px" }}
                              src={
                                window.location.origin +
                                "/images/facebook-logo.png"
                              }
                              alt="facebook"
                            />
                          </Box>
                        }
                        variant="outlined"
                        className={classes.ssoLoginButton}
                        fullWidth
                      >
                        Log In with Facebook
                      </Button>
                    )}
                  />
                )}

                {!hideAppleLogin && (
                  <AppleLogin
                    clientId="YOUR_CLIENT_ID"
                    redirectURI="/"
                    usePopup={true}
                    callback={handleAppleLogin}
                    scope="email name"
                    responseMode="query"
                    render={(renderProps) => (
                      <Button
                        onClick={renderProps.onClick}
                        startIcon={
                          <Box sx={{ display: "flex" }}>
                            <img
                              style={{ width: "25px" }}
                              src="https://res.cloudinary.com/dwezij7u7/image/upload/v1674836396/Moore%20Exotics%20Assets/login/apple-icon_ccai9h.png"
                              alt="apple"
                            />
                          </Box>
                        }
                        variant="outlined"
                        className={classes.ssoLoginButton}
                        fullWidth
                      >
                        Log in with Apple{" "}
                        <Chip
                          label={
                            <Typography
                              sx={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Coming Soon
                            </Typography>
                          }
                          size="small"
                          sx={{
                            mt: "5px",
                            backgroundColor: "#FFC001",
                            color: "#fff",
                            borderRadius: "6px",
                            fontWeight: "bold",
                            fontSize: "12px",
                            wordBreak: "break-word",
                            p: "5px",
                            ml: "5px",
                          }}
                        />
                      </Button>
                    )}
                  />
                )}

                <Typography>
                  {" "}
                  Don't have an account?{" "}
                  <Link
                    to="/auth/signup"
                    className={classes.links}
                    style={{ color: "rgb(255, 192, 1)" }}
                  >
                    Sign Up
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

const mapStateToProps = ({ Auth }) => ({
  responseError: Auth.responseError,
  user: Auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (email, password) => dispatch(Actions.thunkLogin(email, password)),
  responseGoogle: (response) => dispatch(Actions.thunkResponseGoogle(response)),
  responseFacebook: (response) =>
    dispatch(Actions.thunkResponseFacebook(response)),
  responseApple: (response) => dispatch(Actions.thunkResponseApple(response)),
});

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  headerStyle: {
    fontStyle: "normal",
    color: "#000",
    fontWeight: "900 !important",
    marginBottom: "15px !important",
  },
  textField: {
    "&:hover": {
      borderColor: "rgb(255, 192, 1)",
    },
  },
  loginButton: {
    marginTop: "8px !important",
    marginBottom: "8px !important",
    height: "40px",
    color: "#000",
    fontSize: "15px !important",
    fontWeight: "900 !important",
    textTransform: "none !important",
    backgroundColor: "rgb(255, 192, 1)",
    "&:hover": {
      backgroundColor: "rgb(255, 192, 1)",
      color: "white !important",
    },
  },
  ssoLoginButton: {
    marginTop: "4px !important",
    marginBottom: "4px !important",
    minHeight: "40px",
    color: "#000 !important",
    fontSize: "15px",
    fontWeight: 900,
    borderColor: "#C5C5C5 !important",
    textTransform: "none !important",
    "&:hover": {
      color: "rgb(255, 192, 1) !important",
      borderColor: "rgb(255, 192, 1) !important",
    },
  },
  links: {
    fontSize: "16px",
    color: "black",
    textTransform: "none",
    textDecoration: "none",
    "&:hover": {
      color: "rgb(255, 192, 1)",
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
