import DashboardIcon from "@mui/icons-material/Dashboard";

function createNav(icon, text, path) {
  return { icon, text, path };
}

export const navItems = [
  createNav(
    <DashboardIcon sx={{ fontSize: "16px" }} />,
    "Privacy",
    "settings"
  ),
];
