import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import theme from "./utils/Theme.js";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { HelmetProvider } from 'react-helmet-async';
import "./index.css";

console.log("NODE_ENV:", process.env.REACT_APP_NODE_ENV);

const root = ReactDOM.createRoot(document.getElementById("root"));
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Elements stripe={stripePromise}>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </Elements>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>
);
