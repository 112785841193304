import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const SellerDashboardInbox = () => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        margin: "10px",
        height: "7vh",
        borderRadius: "10px",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: "14px" }}>Recent Reviews</Typography>
        <Link to="/seller/reviews" style={{ textDecoration: "none" }}>
          <Typography
            sx={{
              transition: "0.3s all",
              "&:hover": { color: "rgb(255, 192, 1)" },
              fontSize: "12px",
              fontWeight: "bold",
              color: "rgb(255, 192, 1)",
            }}
          >
            View All
          </Typography>
        </Link>
      </CardContent>
    </Card>
  );
};

export default SellerDashboardInbox;
