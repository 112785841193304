import React, { useRef } from "react";
import PropTypes from "prop-types";
import "./DropFileInput.css";

const DropFileInput = (props) => {
  const wrapperRef = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      props.onFileChange(newFile);
    }
  };

  return (
    <>
      <div
        ref={wrapperRef}
        className="drop-file-input"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <img
            src={
              window.location.origin +
              "/images/dropFileInput/cloud-upload-regular-240.png"
            }
            alt=""
          />
          <p>Drag & Drop your files here</p>
        </div>
        {props.isrequired ? (
          <input
            type="file"
            onChange={onFileDrop}
            accept={
              props.acceptFileTypes
                ? props.acceptFileTypes
                : ".jpg, .jpeg, .gif, .png, .webp, .heic, .mp4, .mkv, .mov"
            }
            required={props.filesUrls?.length > 0 ? false : true}
            disabled={props.disabled}
          />
        ) : (
          <input
            type="file"
            onChange={onFileDrop}
            accept={
              props.acceptFileTypes
                ? props.acceptFileTypes
                : ".jpg, .jpeg, .gif, .png, .webp, .heic, .mp4, .mkv, .mov"
            }
            required={false}
            disabled={props.disabled}
          />
        )}
      </div>
    </>
  );
};

DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};

export default DropFileInput;
