import axios from "axios";
import * as Types from "../types/Categories.types";
import config from "../../config";

export const thunkLoadCategories = () => async (dispatch) => {
  dispatch(setLoading());
  let registerRequest;
  try {
    registerRequest = await axios.get(
      `${config.SERVER_URL}/api/v1/categories`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 200) {
      const sortedData = registerRequestData.data.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      dispatch(loadCategories({ categories: sortedData, loading: false }));
    }
  } catch (error) {
    // registerRequest = response;
    dispatch(
      errorLoadCategories({ error: "Unable to fetch classes right now." })
    );
  }
};

export const thunkLoadBreeds = (categoryId) => async (dispatch) => {
  dispatch(setLoading());
  let registerRequest;
  try {
    registerRequest = await axios.get(
      `${config.SERVER_URL}/api/v1/categories/${categoryId}/breeds`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 200) {
      dispatch(
        loadBreeds({ breeds: registerRequestData.data.breeds, loading: false })
      );
    }
  } catch (error) {
    // registerRequest = response;
    console.log("error", error.message);
  }
};
export const thunkLoadGenetics = (breedId) => async (dispatch) => {
  dispatch(setLoading());
  let registerRequest;
  try {
    registerRequest = await axios.get(
      `${config.SERVER_URL}/api/v1/breeds/${breedId}/genetics`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 200) {
      dispatch(
        loadGenetics({
          genetics: registerRequestData.data,
          loading: false,
        })
      );
    }
  } catch (error) {
    // registerRequest = response;
    console.log("error", error.message);
  }
};

export const thunkLoadSubBreedGenetics = (subBreedId) => async (dispatch) => {
  dispatch(setLoading());
  let registerRequest;
  try {
    registerRequest = await axios.get(
      `${config.SERVER_URL}/api/v1/sub_breeds/${subBreedId}/genetics`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 200) {
      dispatch(
        loadSubBreedGenetics({
          subBreedGenetics: registerRequestData.data,
          loading: false,
        })
      );
    }
  } catch (error) {
    // registerRequest = response;
    console.log("error", error.message);
  }
};
export const thunkLoadSubBreeds = (breedId) => async (dispatch) => {
  dispatch(setLoading());
  let registerRequest;
  try {
    registerRequest = await axios.get(
      `${config.SERVER_URL}/api/v1/breeds/${breedId}/sub_breeds`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { data: registerRequestData } = registerRequest;
    if (registerRequest.status === 200) {
      dispatch(
        loadSubBreeds({ subBreeds: registerRequestData.data, loading: false })
      );
    }
  } catch (error) {
    console.log("error", error.message);
  }
};

const loadGenetics = (payload) => ({
  type: Types.LOAD_GENETICS,
  payload,
});
const loadBreeds = (payload) => ({
  type: Types.LOAD_BREEDS,
  payload,
});

const errorLoadCategories = (payload) => ({
  type: Types.ERROR_LOADING_CATEGORIES,
  payload,
});

const loadCategories = (payload) => ({
  type: Types.LOAD_CATEGORIES,
  payload,
});

const setLoading = (payload) => ({
  type: Types.SET_LOADING,
});

const loadSubBreeds = (payload) => ({
  type: Types.LOAD_SUB_BREEDS,
  payload,
});
const loadSubBreedGenetics = (payload) => ({
  type: Types.LOAD_GENETICS_SUB_BREEDS,
  payload,
});
