import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormControl,
  MenuItem,
  IconButton,
  useMediaQuery,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { FiberManualRecord, Filter } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config";
import { Box, Button, Grid, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";
import * as Actions from "../../redux/actions";
import { Radio, RadioGroup, FormLabel } from "@mui/material";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import { AttachMoney } from "@mui/icons-material";
import { styled } from "@mui/system";

const FilterModal = ({
  open,
  brandName,
  user,
  jwtToken,
  listData,
  Categories,
  setOpen,
  setFilter,
  subBreedGenetics,
  setOpenSucessAlert,
  loadBreeds,
  breedsRaw,
  subBreedsRaw,
  loadSubBreeds,
  loadSubBreedsGenetics,
  loadListings,
  loadServices,
  loadSubServices,
  page,
  loadServiceListings,
}) => {
  const DUMMY_PAYMENT_METHODS = [
    "Credit Card",
    "Debit Card",
    "PayPal Goods & Services",
    "PayPal Friends & Family",
    "Venmo",
    "Zelle",
    "Apple Pay",
    "Google Pay",
    "Cash App",
    "Chime",
    "Personal Check",
    "Cashier's Check",
    "Wire Transfer",
    "Cash",
    "Money Order",
    "Western Union",
    "Walmart Pay",
    "MoneyGram",
    "Bitcoin",
    "Dogecoin",
    "Ehtermeum",
    "Other Crypto",
    "Other",
  ];

  const [openDialog, setOpenDialog] = useState(false);

  const [priceMinValue, setPriceMinValue] = useState("");
  const [priceMaxValue, setPriceMaxValue] = useState("");
  const [location, setLocation] = useState("");
  const [minAge, setMinAge] = useState("");
  const [maxAge, setMaxAge] = useState("");
  const [availabilityStatus, setAvailabilityStatus] = useState("");
  const [gender, setGender] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [category, setCategory] = useState("");
  const [breed, setBreed] = useState();
  const [subBreed, setSubBreed] = useState("");
  const [genetics, setGenetics] = useState("");

  const availability = [
    { name: "Active", key: "active_listing" },
    { name: "On hold", key: "on_hold" },
    { name: "Featured", key: "featured" },
    { name: "Sold", key: "sold" },
  ];
  const [selectedBreed, setSelectedBreed] = useState("");
  const [selectedSubBreed, setSelectedSubBreed] = useState("");

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    setBreed(breedsRaw);
  }, [breedsRaw]);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleMinValue = (event) => {
    setPriceMinValue(event.target.value);
  };
  const handleMaxValue = (event) => {
    setPriceMaxValue(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClearPrice = () => {
    setPriceMaxValue("");
    setPriceMinValue("");
  };
  const handleClearLocation = () => {
    setLocation("");
  };
  const handleClearAge = () => {
    setMinAge("");
    setMaxAge("");
  };

  const handleClearAvailabilityStatus = () => {
    setAvailabilityStatus("");
  };
  const handleClearGender = () => {
    setGender("");
  };
  const handleClearPaymentMethod = () => {
    setPaymentMethods([]);
  };
  const handleClearCategory = () => {
    setCategory("");
    setSubBreed("");
    setSelectedBreed("");
  };
  const handleClearBreed = () => {
    setSelectedBreed("");
    setSubBreed("");
  };
  const handleClearSubBreed = () => {
    setSubBreed("");
    setGenetics("");
  };
  const handleClearGenetics = () => {
    setGenetics("");
  };
  const handleClearAll = () => {
    setPriceMaxValue("");
    setPriceMinValue("");
    setLocation("");
    setMinAge("");
    setMaxAge("");
    setAvailabilityStatus("");
    setGender("");
    setPaymentMethods([]);
    setCategory("");
    setSelectedBreed("");
    setSubBreed("");
    setGenetics("");
  };

  const handleApplyFilter = async () => {
    let updatedArray = [];

    if (priceMinValue) {
      updatedArray = [...updatedArray, "Min price:" + priceMinValue];
    }
    if (priceMaxValue) {
      updatedArray = [...updatedArray, "Max price:" + priceMaxValue];
    }
    if (location) {
      updatedArray = [...updatedArray, "Location: " + location];
    }
    if (minAge) {
      updatedArray = [...updatedArray, "Min age: " + minAge];
    }
    if (maxAge) {
      updatedArray = [...updatedArray, "Max age: " + maxAge];
    }
    if (availabilityStatus) {
      updatedArray = [...updatedArray, "Availability: " + availabilityStatus];
    }

    if (category) {
      updatedArray = [...updatedArray, "Category: " + category.name];
    }
    if (selectedBreed) {
      updatedArray = [
        ...updatedArray,
        page !== "Services"
          ? "Breed: " + selectedBreed.name
          : "Sub-Services: " + selectedBreed.name,
      ];
    }
    if (gender) {
      updatedArray = [...updatedArray, "Gender: " + gender];
    }
    if (subBreed) {
      updatedArray = [...updatedArray, "Sub-breed: " + subBreed.name];
    }
    if (genetics) {
      updatedArray = [...updatedArray, "Genetics: " + genetics.name];
    }
    if (paymentMethods.length > 0) {
      updatedArray = [...updatedArray, "Payment: " + paymentMethods];
    }

    setFilter(updatedArray);

    if (page === "Services") {
      const filtersData = {
        listing: {
          service_id: category && category.id,
          sub_service_id: selectedBreed && selectedBreed.id,
          min_price: priceMinValue && priceMinValue,
          max_price: priceMaxValue && priceMaxValue,
          location: location && location,
          payment_methods: paymentMethods && paymentMethods,
        },
      };

      try {
        loadServiceListings({
          filtersData,
          jwtToken,
        });
      } catch (error) {
        console.log("Error", error);
      }
    } else {
      const filtersData = {
        listing: {
          breed_id: selectedBreed && selectedBreed.id,
          category_id: category && category.id,
          sub_breed_id: subBreed && subBreed.id,
          gender: gender && gender,
          min_price: priceMinValue && priceMinValue,
          max_price: priceMaxValue && priceMaxValue,
          min_age: minAge && minAge,
          max_age: maxAge && maxAge,
          genetics: genetics && genetics.id,
          location: location && location,
          payment_methods: paymentMethods && paymentMethods,
          status: availabilityStatus && availabilityStatus,
        },
      };

      try {
        loadListings({
          filtersData,
          jwtToken,
        });
      } catch (error) {
        console.log("Error", error);
      }
    }
    handleClose();
  };

  const handleMinAgeChange = (event) => {
    setMinAge(event.target.value);
  };

  const handleMaxAgeChange = (event) => {
    setMaxAge(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleAvailabilityStatus = (event) => {
    setAvailabilityStatus(event.target.value);
  };

  const handleSelectedCategoryChange = (event) => {
    if (event.target.value) {
      if (page !== "Services") {
        loadBreeds(event.target.value.id);
      } else {
        loadSubServices(event.target.value.id);
      }

      setCategory(event.target.value);
      setSelectedBreed("");
      setSubBreed("");
      setGenetics("");
    }
  };

  const handleSelectedBreedChange = (event) => {
    if (event.target.value) {
      if (page !== "Services") {
        loadSubBreeds(event.target.value.id);
      }
      setSelectedBreed(event.target.value);
      setGenetics("");
      setSelectedSubBreed("");
    }
  };
  useEffect(() => {
    if (subBreed != "") {
      loadSubBreedsGenetics(subBreed.id);
    }
  }, [subBreed]);

  const handleSelectedSubBreedChange = (event) => {
    if (event.target.value) {
      setSubBreed(event.target.value);
      // loadSubBreedsGenetics(event.target.value);
    }
    setSelectedSubBreed(event.target.value);
    setGenetics("");
  };

  const handleSelectedGeneticsChange = (event) => {
    if (event.target.value) {
      setGenetics(event.target.value);
    }
  };
  const handlePaymentMethodChange = (event) => {
    const {
      target: { value },
    } = event;
    setPaymentMethods(typeof value === "string" ? value.split(",") : value);
  };

  const StyledRadio = styled(Radio)(({ theme }) => ({
    "& .MuiSvgIcon-root": {
      width: "16px",
      height: "16px",
    },
  }));

  return (
    <>
      <Box position="relative">
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          fullScreen
          sx={{
            "& .MuiDialog-paper": {
              width: { lg: "40%", md: "53%", xs: "90%" },
              maxHeight: {
                md: page !== "Services" ? "90%" : "65%",
                xs: page !== "Services" ? "90%" : "70%",
                sm: page !== "Services" ? "70%" : "60%",
              },
              borderRadius: "30px",
            },
          }}
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={12}>
                <Box display={"flex"}>
                  <Box
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ flex: 1 }}
                  >
                    <Typography
                      textAlign={"center"}
                      sx={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      Filters
                    </Typography>
                  </Box>
                  <Box alignItems={"flex-end"} justifyContent={"flex-end"}>
                    <Box>
                      <IconButton>
                        <ClearIcon onClick={handleClose} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Divider sx={{ marginBottom: "20px" }} />
            </Box>
            <Grid container>
              <Grid xs={4} md={3} display={"flex"} alignItems={"center"}>
                <Typography
                  sx={{
                    fontSize: { md: "20px", xs: "16px" },
                    fontWeight: "bold",
                  }}
                >
                  Price Range
                </Typography>
              </Grid>
              <Grid container xs={8} md={9}>
                <Grid
                  container
                  xs={10}
                  md={11}
                  justifyContent={"space-between"}
                >
                  <Grid item xs={5}>
                    <TextField
                      label="Min"
                      fullWidth
                      onChange={handleMinValue}
                      value={priceMinValue}
                      type="number"
                      inputProps={{ min: 0 }}
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment
                              position="end"
                              sx={{ marginRight: { xs: "-20px", md: "0px" } }}
                            >
                              <AttachMoney />
                            </InputAdornment>
                          </>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label={"Max"}
                      fullWidth
                      value={priceMaxValue}
                      onChange={handleMaxValue}
                      type="number"
                      inputProps={{ min: 0 }}
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment
                              position="end"
                              sx={{ marginRight: { xs: "-20px", md: "0px" } }}
                            >
                              <AttachMoney />
                            </InputAdornment>
                          </>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid xs={2} md={1}>
                  <Grid
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    height={"100%"}
                  >
                    {(priceMinValue != "" || priceMaxValue != "") && (
                      <IconButton onClick={handleClearPrice}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: "20px" }} />
            <Grid container mt={2}>
              <Grid xs={4} md={3} display={"flex"} alignItems={"center"}>
                <Typography
                  sx={{
                    fontSize: { md: "20px", xs: "16px" },
                    fontWeight: "bold",
                  }}
                  //   justifySelf={"center"}
                  //   textJustify
                >
                  Classes
                </Typography>
              </Grid>
              <Grid xs={8} md={9} container>
                <Grid xs={10} md={11}>
                  <FormControl sx={{ width: "100%" }} required>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      size="small"
                      fullWidth={true}
                      name="category"
                      value={category}
                      MenuProps={MenuProps}
                      onChange={handleSelectedCategoryChange}
                      sx={{
                        backgroundColor: "white",
                      }}
                    >
                      {Categories.map((list) => (
                        <MenuItem key={list.id} value={list}>
                          <ListItem alignItems="center">
                            <Typography component={"span"}>
                              {list.name}
                            </Typography>
                          </ListItem>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  xs={2}
                  md={1}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  height={"100%"}
                >
                  {category && (
                    <IconButton onClick={handleClearCategory}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: "20px" }} />

            {category && (
              <>
                <Grid container mt={2}>
                  <Grid xs={4} md={3} display={"flex"} alignItems={"center"}>
                    <Typography
                      sx={{
                        fontSize: { md: "20px", xs: "16px" },
                        fontWeight: "bold",
                      }}
                    >
                      {page !== "Services" ? "Breed" : "Sub-Services"}
                    </Typography>
                  </Grid>

                  <Grid xs={8} md={9} container>
                    <Grid md={11} xs={10}>
                      <FormControl sx={{ width: "100%" }} required>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          size="small"
                          fullWidth={true}
                          name="breed"
                          value={selectedBreed}
                          MenuProps={MenuProps}
                          onChange={handleSelectedBreedChange}
                          sx={{
                            backgroundColor: "white",
                          }}
                        >
                          {breedsRaw.map((list) => (
                            <MenuItem key={list.id} value={list}>
                              <ListItem alignItems="center">
                                <Typography component={"span"}>
                                  {list.name}
                                </Typography>
                              </ListItem>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      md={1}
                      xs={2}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      height={"100%"}
                    >
                      {selectedBreed != "" && (
                        <IconButton onClick={handleClearBreed}>
                          <ClearIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ marginTop: "20px" }} />
              </>
            )}
            {page !== "Services" &&
              subBreedsRaw?.length > 0 &&
              selectedBreed && (
                <>
                  <Grid container mt={2}>
                    <Grid xs={4} md={3} display={"flex"} alignItems={"center"}>
                      <Typography
                        sx={{
                          fontSize: { md: "20px", xs: "16px" },
                          fontWeight: "bold",
                        }}
                      >
                        SubBreed
                      </Typography>
                    </Grid>

                    <Grid xs={8} md={9} container>
                      <Grid md={11} xs={10}>
                        <FormControl sx={{ width: "100%" }} required>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            size="small"
                            fullWidth={true}
                            name="subbreeds"
                            value={subBreed}
                            MenuProps={MenuProps}
                            onChange={handleSelectedSubBreedChange}
                            sx={{
                              backgroundColor: "white",
                            }}
                          >
                            {subBreedsRaw.map((list) => (
                              <MenuItem key={list.id} value={list}>
                                <ListItem alignItems="center">
                                  <Typography component={"span"}>
                                    {list.name}
                                  </Typography>
                                </ListItem>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        md={1}
                        xs={2}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        height={"100%"}
                      >
                        {subBreed != "" && (
                          <IconButton onClick={handleClearSubBreed}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: "20px" }} />
                </>
              )}
            {page !== "Services" &&
              subBreedGenetics?.length > 0 &&
              subBreed != "" && (
                <>
                  <Grid container mt={2}>
                    <Grid xs={4} md={3} display={"flex"} alignItems={"center"}>
                      <Typography
                        sx={{
                          fontSize: { md: "20px", xs: "16px" },
                          fontWeight: "bold",
                        }}
                      >
                        Genetics
                      </Typography>
                    </Grid>

                    <Grid xs={8} md={9} container>
                      <Grid md={11} xs={10}>
                        <FormControl sx={{ width: "100%" }} required>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            size="small"
                            fullWidth={true}
                            name="genetics"
                            value={genetics}
                            MenuProps={MenuProps}
                            onChange={handleSelectedGeneticsChange}
                            sx={{
                              backgroundColor: "white",
                            }}
                          >
                            {subBreedGenetics.map((list) => (
                              <MenuItem key={list.id} value={list}>
                                <ListItem alignItems="center">
                                  <Typography component={"span"}>
                                    {list.name}
                                  </Typography>
                                </ListItem>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        md={1}
                        xs={2}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        height={"100%"}
                      >
                        {genetics != "" && (
                          <IconButton onClick={handleClearGenetics}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: "20px" }} />
                </>
              )}

            <Grid container mt={4}>
              <Grid xs={4} md={3} display={"flex"} alignItems={"center"}>
                <Typography
                  sx={{
                    fontSize: { md: "20px", xs: "16px" },
                    fontWeight: "bold",
                  }}
                >
                  Locations
                </Typography>
              </Grid>
              <Grid item xs={8} md={9} container>
                <Grid md={11} xs={10}>
                  <FormControl sx={{ width: "100%" }} required>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      size="small"
                      fullWidth={true}
                      name="location"
                      value={location}
                      MenuProps={MenuProps}
                      onChange={handleLocationChange}
                      sx={{
                        backgroundColor: "white",
                      }}
                    >
                      {states.map((list) => (
                        <MenuItem key={list} value={list}>
                          <ListItem alignItems="center">
                            <Typography component={"span"}>{list}</Typography>
                          </ListItem>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  md={1}
                  xs={2}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  height={"100%"}
                >
                  {location != "" && (
                    <IconButton onClick={handleClearLocation}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: "20px" }} />
            {page !== "Services" && (
              <>
                <Grid mt={4} container>
                  <Grid xs={4} md={3} display={"flex"} alignItems={"center"}>
                    <Typography
                      sx={{
                        fontSize: { md: "20px", xs: "16px" },
                        fontWeight: "bold",
                      }}
                    >
                      Gender
                    </Typography>
                  </Grid>
                  <Grid container xs={8} md={9}>
                    <Grid md={11} xs={10}>
                      <RadioGroup value={gender} onChange={handleGenderChange}>
                        <Grid container>
                          <Grid xs={4} md={4}>
                            <FormControlLabel
                              value="male"
                              control={<StyledRadio />}
                              label={
                                //   <div
                                //     style={{ display: "flex", alignItems: "center" }}
                                //   >
                                <Box display="flex" alignItems="center">
                                  <Typography
                                    component={"span"}
                                    sx={{ fontSize: "16px" }}
                                  >
                                    Male
                                  </Typography>{" "}
                                  <MaleIcon
                                    sx={{
                                      color: "blue",
                                      fontSize: "20px",
                                      marginTop: "auto",
                                      marginBottom: "auto",
                                    }}
                                  />
                                </Box>
                                //   </div>
                              }
                            />
                          </Grid>
                          <Grid xs={12} md={4}>
                            <FormControlLabel
                              value="female"
                              control={<StyledRadio />}
                              label={
                                <Box display="flex" alignItems="center">
                                  <Typography
                                    component={"span"}
                                    sx={{ fontSize: "16px" }}
                                  >
                                    Female
                                  </Typography>{" "}
                                  <FemaleIcon
                                    sx={{
                                      color: "#AA336A",
                                      fontSize: "20px",
                                      marginTop: "auto",
                                      marginBottom: "auto",
                                    }}
                                  />
                                </Box>

                                // <FemaleIcon sx={{ color: "#AA336A" }} />
                              }
                              checkedIcon={<StyledRadio />}
                            />
                          </Grid>
                          <Grid xs={12} md={4}>
                            <FormControlLabel
                              value="both"
                              control={<StyledRadio />}
                              label={
                                <Box display="flex" alignItems="center">
                                  <Typography
                                    component={"span"}
                                    sx={{ fontSize: "16px" }}
                                  >
                                    Pair
                                  </Typography>{" "}
                                  <FemaleIcon
                                    sx={{
                                      color: "#AA336A",
                                      fontSize: "20px",
                                      marginTop: "auto",
                                      marginBottom: "auto",
                                    }}
                                  />
                                  <MaleIcon
                                    sx={{
                                      color: "blue",
                                      fontSize: "20px",
                                      marginTop: "auto",
                                      marginBottom: "auto",
                                    }}
                                  />
                                </Box>
                              }
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                    <Grid
                      md={1}
                      xs={2}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      height={"100%"}
                    >
                      {gender != "" && (
                        <IconButton>
                          <ClearIcon onClick={handleClearGender} />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Divider sx={{ marginTop: "20px" }} />
              </>
            )}
            {page !== "Services" && (
              <>
                <Grid container mt={4}>
                  <Grid xs={4} md={3} display={"flex"} alignItems={"center"}>
                    <Typography
                      sx={{
                        fontSize: { md: "20px", xs: "16px" },
                        fontWeight: "bold",
                      }}
                    >
                      Availability
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={9} container>
                    <Grid md={11} xs={10}>
                      <FormControl sx={{ width: "100%" }} required>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          size="small"
                          fullWidth={true}
                          name="availability"
                          value={availabilityStatus}
                          MenuProps={MenuProps}
                          onChange={handleAvailabilityStatus}
                          sx={{
                            backgroundColor: "white",
                          }}
                        >
                          {availability.map((list) => (
                            <MenuItem key={list.key} value={list.key}>
                              <ListItem alignItems="center">
                                <Typography component={"span"}>
                                  {list.name}
                                </Typography>
                              </ListItem>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      md={1}
                      xs={2}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      height={"100%"}
                    >
                      {availabilityStatus != "" && (
                        <IconButton>
                          <ClearIcon onClick={handleClearAvailabilityStatus} />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ marginTop: "20px" }} />
              </>
            )}

            <Grid container direction="row" sx={{ mt: 4 }}>
              <Grid item xs={4} md={3} display={"flex"} alignItems={"center"}>
                <Typography
                  component={"p"}
                  sx={{
                    fontSize: { md: "20px", xs: "16px" },
                    fontWeight: "bold",
                  }}
                >
                  Payment
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    size="small"
                    fullWidth={true}
                    value={paymentMethods}
                    onChange={handlePaymentMethodChange}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                    sx={{ backgroundColor: "white" }}
                  >
                    {DUMMY_PAYMENT_METHODS.map((method) => (
                      <MenuItem key={method} value={method}>
                        <Checkbox
                          checked={paymentMethods.indexOf(method) > -1}
                        />
                        <ListItemText primary={method} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: "20px" }} />
            {page !== "Services" && (
              <Grid mt={4} container>
                <Grid md={3} xs={4} display={"flex"} alignItems={"center"}>
                  <Typography
                    sx={{
                      fontSize: { md: "20px", xs: "16px" },
                      fontWeight: "bold",
                    }}
                  >
                    Age
                  </Typography>
                </Grid>
                <Grid md={9} xs={8} container>
                  <Grid
                    container
                    md={11}
                    xs={10}
                    justifyContent={"space-between"}
                  >
                    <Grid xs={5}>
                      <TextField
                        type="number"
                        value={minAge}
                        label="Min"
                        onChange={handleMinAgeChange}
                        inputProps={{ min: 0 }}
                        InputProps={{
                          endAdornment: (
                            <>
                              <InputAdornment
                                position="end"
                                sx={{ marginRight: { xs: "-20px", md: "0px" } }}
                              >
                                YR
                              </InputAdornment>
                            </>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid xs={5}>
                      <TextField
                        type="number"
                        value={maxAge}
                        label="Max"
                        onChange={handleMaxAgeChange}
                        inputProps={{ min: 0 }}
                        InputProps={{
                          endAdornment: (
                            <>
                              <InputAdornment
                                position="end"
                                sx={{ marginRight: { xs: "-20px", md: "0px" } }}
                              >
                                YR
                              </InputAdornment>
                            </>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    md={1}
                    xs={2}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    height={"100%"}
                  >
                    {(minAge != "" || maxAge != "") && (
                      <IconButton>
                        <ClearIcon onClick={handleClearAge} />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
            }}
          >
            <Grid
              container
              display={"flex"}
              flex={1}
              sx={{ justifyContent: "space-between", padding: "15px" }}
            >
              <Grid item sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Button
                  onClick={handleClearAll}
                  sx={{
                    background: "#DCA500",
                    maxWidth: "150px",
                    marginX: { md: "10px" },
                  }}
                  variant="contained"
                  autoFocus
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Clear All{" "}
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button onClick={handleClose} sx={{ marginRight: "10px" }}>
                  Cancel
                </Button>

                <Button
                  onClick={handleApplyFilter}
                  sx={{ background: "#DCA500", maxWidth: "150px" }}
                  variant="contained"
                  autoFocus
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Apply{" "}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: { md: 250, sm: 200 },
    },
  },
};
const useStyles = makeStyles({
  capitalizedButton: {
    color: "#d89700",
    fontFamily: "SharpSans",
    fontWeight: "bolder",
    fontSize: "16px",
  },
});

const mapStateToProps = ({ Auth, Categories, Services }, ownProps) => ({
  jwtToken: Auth.jwtToken,
  user: Auth.user,
  Categories:
    ownProps.page !== "Services" ? Categories.classes : Services.services,
  breedsRaw:
    ownProps.page !== "Services" ? Categories.breeds : Services.subServices,
  subBreedsRaw: Categories.subBreeds,
  subBreedGenetics: Categories.subBreedGenetics,
});

const mapDispatchToProps = (dispatch) => ({
  loadBreeds: (categoryId) => {
    dispatch(Actions.thunkLoadBreeds(categoryId));
  },
  loadListings: ({ filtersData, jwtToken }) =>
    dispatch(
      Actions.thunkLoadListing({
        filtersData,
        jwtToken,
      })
    ),
  loadSubBreeds: (breedId) => {
    dispatch(Actions.thunkLoadSubBreeds(breedId));
  },
  loadServices: () => dispatch(Actions.thunkLoadServices()),

  loadSubServices: (serviceId) =>
    dispatch(Actions.thunkLoadSubServices(serviceId)),

  loadServiceListings: ({ filtersData, jwtToken }) =>
    dispatch(
      Actions.thunkLoadServiceListings({
        filtersData,
        jwtToken,
      })
    ),
  loadSubBreedsGenetics: (geneticsId) =>
    dispatch(Actions.thunkLoadSubBreedGenetics(geneticsId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterModal);
