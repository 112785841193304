import { connect } from "react-redux";
import axios from "axios";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Button,
  FormControl,
  MenuItem,
  Avatar,
  Stack,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  Radio,
  Rating,
  FormControlLabel,
} from "@mui/material";
import DisplayReview from "../seller/reviews/DisplayReview";
import { makeStyles } from "@mui/styles";
import TuneIcon from "@mui/icons-material/Tune";
import { useEffect, useState, useRef } from "react";
import SeeAllReviews from "../../pages/stores/storedetails/SeeAllReviews";
import ClearIcon from "@mui/icons-material/Clear";
import { Swiper, SwiperSlide } from "swiper/react";
import config from "../../config";
import SwiperCore, { Autoplay, Navigation, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

const Reviews = ({ reviews, jwtToken, listId, listData, reviewType }) => {
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Newest");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [sortOrder, setSortOrder] = useState("");
  const [review, setReview] = useState([]);
  const [showReview, setShowReview] = useState(false);
  const [completeReview, setCompleteReview] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  useEffect(() => {
    setReview(reviews);
  }, [reviews]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const formattedDate = date.toLocaleDateString(undefined, options);
    return formattedDate;
  };

  const handleRadioChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const handleOpenFilterDialog = () => {
    setOpenFilterDialog(true);
  };

  const handleCloseFilterDialog = () => {
    setOpenFilterDialog(false);
  };
  const handleResetClick = () => {
    setSelectedFilter("all");
  };
  const handleSeeMore = (data) => {
    setCompleteReview(data);
    setShowReview(true);
  };
  const handleCloseReviewModal = () => {
    setShowReview(false);
  };
  const handleApplyFilter = async () => {
    setOpenFilterDialog(false);
    let registerRequest;
    let url;
    if (selectedFilter === "all" && reviewType === "animal") {
      url = `${config.SERVER_URL}/api/v1/reviews?seller_id=${listData.seller_id}`;
    } else {
      if (reviewType === "animal") {
        url = `${config.SERVER_URL}/api/v1/reviews?rating=${selectedFilter}&seller_id=${listData?.seller?.id}`;
      } else {
        if (selectedFilter === "all") {
          url = `${config.SERVER_URL}/api/v1/reviews?listing_service_id=${listData.id}`;
        } else {
          url = `${config.SERVER_URL}/api/v1/reviews?rating=${selectedFilter}&listing_service_id=${listData.id}`;
        }
      }
    }

    try {
      registerRequest = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (registerRequest.status === 200) {
        setReview(registerRequest.data.reviews);
        setSelectedFilter("all");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSortChange = async (event) => {
    const sortValue = event.target.value;
    setSelectedValue(sortValue);
    let url;
    if (sortValue === "Newest") {
      setSortOrder("asc");
    } else if (sortValue === "Oldest") {
      setSortOrder("desc");
    } else {
      setSortOrder("default");
    }
    if (reviewType === "animal") {
      url = `${config.SERVER_URL}/api/v1/reviews?sort_by=${sortOrder}&seller_id=${listData.seller.id}`;
    } else {
      url = `${config.SERVER_URL}/api/v1/reviews?sort_by=${sortOrder}&listing_service_id=${listData.id}`;
    }

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (response.status === 200) {
        setReview(response.data.reviews);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDisplayAllReview = () => {
    setOpenDialog(true);
  };

  const handleSeeAllReview = () => {
    setOpenDialog(true);
  };

  const classes = useStyles();
  SwiperCore.use([Autoplay]);

  return (
    <>
      {completeReview && (
        <DisplayReview
          openModal={showReview}
          closeModal={handleCloseReviewModal}
          data={completeReview}
        />
      )}
      {review.length > 0 && (
        <SeeAllReviews
          data={review}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
      )}
      <Dialog
        open={openFilterDialog}
        onClose={handleCloseFilterDialog}
        maxWidth="lg"
        sx={{
          backgroundColor: "transparent",
          "& .MuiDialog-paper": {
            width: { md: "20%", xs: "90%" },
            borderRadius: "10px",
            maxHeight: {
              md: "68%",
              xs: "60%",
              sm: "58%",
            },
          },
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={12}>
              <Box display={"flex"}>
                <Box
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ flex: 1 }}
                >
                  <Typography
                    variant="h5"
                    textAlign={"center"}
                    fontSize={{ xs: "16px", md: "20px" }}
                    sx={{ fontWeight: "bolder" }}
                  >
                    Review Filter
                  </Typography>
                </Box>
                <Box alignItems={"flex-end"} justifyContent={"flex-end"}>
                  <Box>
                    <IconButton>
                      <ClearIcon onClick={handleCloseFilterDialog} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
        // sx={{
        //   height: "500px",

        // }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
              Rating
            </Typography>
            <Box>
              <Button
                variant="text"
                color="primary"
                onClick={handleResetClick}
                sx={{ fontWeight: "bold" }}
              >
                Reset
              </Button>
            </Box>
          </Box>
          <RadioGroup
            aria-label="options"
            name="options"
            value={selectedFilter}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel
              value="5 stars"
              control={<Radio />}
              label="5 Stars"
            />
            <FormControlLabel
              value="4 stars"
              control={<Radio />}
              label="4 Stars"
            />
            <FormControlLabel
              value="3 stars"
              control={<Radio />}
              label="3 Stars"
            />
            <FormControlLabel
              value="2 stars"
              control={<Radio />}
              label="2 stars"
            />
            <FormControlLabel
              value="1 stars"
              control={<Radio />}
              label="1 stars"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyFilter}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
      <Box display={"flex"} flex={1} mt={4}>
        <Box flex={0.6} display={"flex"}>
          <Typography sx={{ fontSize: { xs: "18px", md: "24px" } }}>
            <b> Reviews </b> ({review.length})
          </Typography>
        </Box>

        <Box flex={0.4} display={"flex"} justifyContent={"flex-end"}>
          <FormControl
            sx={{
              marginBottom: "8px !important",
              height: "40px",
              pr: 2,
            }}
          >
            <Select
              labelId="dropdown-label"
              id="dropdown-button"
              value={selectedValue}
              sx={{ height: "30px" }}
              onChange={handleSortChange}
            >
              <MenuItem value="Newest">Newest</MenuItem>
              <MenuItem value="Oldest">Oldest</MenuItem>
              <MenuItem value="Default">Default</MenuItem>
            </Select>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            startIcon={<TuneIcon sx={{ color: "white" }} />}
            onClick={handleOpenFilterDialog}
            sx={{
              marginBottom: "8px !important",
              // marginRight: { md: "100px", xs: "15px" },
              height: "30px",
              color: "white",
              fontSize: "15px !important",
              fontWeight: "400 !important",
              textTransform: "none !important",
              width: "100px",
              "&:hover": {
                backgroundColor: "rgb(255, 192, 1)",
                color: "white !important",
              },
            }}
          >
            FILTER
          </Button>
        </Box>
      </Box>

      {review.length > 0 ? (
        review.length > 3 ? (
          <>
            <Stack className={classes.mainContainer}>
              <Grid
                container
                direction="row"
                sx={{ display: "flex", paddingTop: 2 }}
              >
                <Swiper
                  effect={"cards"}
                  style={{
                    "--swiper-navigation-color": "rgb(255, 192, 1)",
                    "--swiper-pagination-color": "rgb(255, 192, 1)",
                  }}
                  navigation={true}
                  modules={[Navigation, Scrollbar]}
                  grabCursor={true}
                  autoplay={false}
                  // centeredSlides={true}
                  loop={true}
                  slidesPerView={4}
                  breakpoints={{
                    320: { slidesPerView: 1 },
                    480: { slidesPerView: 1 },
                    600: { slidesPerView: 1 },
                    900: { slidesPerView: 3 },
                    1200: { slidesPerView: 4 },
                  }}
                >
                  {review.length > 0 &&
                    review.map((data) => {
                      return (
                        <SwiperSlide>
                          <Grid
                            // item
                            display="flex"
                            justifyContent="center"
                            onClick={() => handleSeeMore(data)}
                            alignItems="center"
                            direction="column"
                            sx={{
                              borderRadius: "8px",
                            }}
                          >
                            <Box>
                              <Avatar
                                alt={data.user.avatar_url}
                                src={data.user.avatar_url}
                                sx={{ width: "100px", height: "100px" }}
                              ></Avatar>
                            </Box>
                            <Box>
                              <Rating
                                name="simple-controlled"
                                value={data.rating}
                                readOnly
                                size="large"
                              />
                            </Box>
                            <Typography
                              sx={{
                                fontSize: { xs: "18px" },
                                color: "#000",
                                fontWeight: "700",
                                wordBreak: "break-word",
                              }}
                            >
                              {data.user.full_name}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#000",
                                textAlign: "center",
                                wordBreak: "break-word",
                                fontSize: {
                                  xs: "12px",
                                  sm: "12px",
                                  md: "14px",
                                },
                                padding: {
                                  xs: "0px 100px 0px 100px",
                                  sm: "0px 200px 0px 200px",
                                  md: "0px 10px 0px 10px",
                                  lg: "0px 20px 0px 20px",
                                  xl: "0px 30px 0px 30px",
                                },
                              }}
                            >
                              {data.content.length > 111
                                ? data.content.substring(0, 111) + "..."
                                : data.content}
                              {data.content.length > 111 && (
                                <Button
                                  varient="text"
                                  onClick={() => handleSeeMore(data)}
                                >
                                  See More
                                </Button>
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: { xs: "12px" },
                                color: "grey",
                              }}
                            >
                              {formatDate(data.created_at)}
                            </Typography>
                          </Grid>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </Grid>
            </Stack>

            <Box
              alignItems={"center"}
              justifyContent={"center"}
              flex={1}
              display={"flex"}
            >
              <Button
                type="submit"
                variant="contained"
                onClick={handleDisplayAllReview}
                sx={{
                  marginBottom: "8px !important",
                  // marginRight: { md: "100px", xs: "15px" },
                  height: "30px",
                  color: "white",
                  fontSize: "15px !important",
                  fontWeight: "400 !important",
                  textTransform: "none !important",
                  width: "100px",
                  "&:hover": {
                    backgroundColor: "rgb(255, 192, 1)",
                    color: "white !important",
                  },
                }}
              >
                SEE ALL
              </Button>
            </Box>
          </>
        ) : (
          // <Grid display={"flex"} sx={{ width: "100%" }}>
          <>
            <Grid container md={12}>
              {review.map((data) => (
                <Grid
                  item
                  display="flex"
                  // justifyContent="center"
                  alignItems="center"
                  direction="column"
                  p={1}
                  mt={1}
                  onClick={() => handleSeeMore(data)}
                  sx={{
                    // backgroundColor: "white",
                    // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    borderRadius: "8px",
                  }}
                  // elavation={10}
                  md={3}
                >
                  <Box>
                    <Avatar
                      alt={data.user.avatar_url}
                      src={data.user.avatar_url}
                      sx={{ width: "100px", height: "100px" }}
                    ></Avatar>
                  </Box>
                  <Box>
                    <Rating
                      name="simple-controlled"
                      value={data.rating}
                      readOnly
                      size="large"
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "18px" },
                      color: "#000",
                      fontWeight: "700",
                      wordBreak: "break-word",
                    }}
                  >
                    {data.user.full_name}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      textAlign: "center",
                      wordBreak: "break-word",
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "14px",
                      },
                      padding: {
                        xs: "0px 100px 0px 100px",
                        sm: "0px 200px 0px 200px",
                        md: "0px 10px 0px 10px",
                        lg: "0px 20px 0px 20px",
                        xl: "0px 30px 0px 30px",
                      },
                    }}
                  >
                    {data.content.length > 111
                      ? data.content.substring(0, 111) + "..."
                      : data.content}
                    {data.content.length > 111 && (
                      <Button
                        varient="text"
                        onClick={() => handleSeeMore(data)}
                      >
                        See More
                      </Button>
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px" },
                      color: "grey",
                    }}
                  >
                    {formatDate(data.created_at)}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </>
          // </Grid>
        )
      ) : (
        <Box>
          <Typography textAlign={"center"} fontWeight={"bold"}>
            No review found
          </Typography>
        </Box>
      )}
    </>
  );
};
const mapStateToProps = ({ Auth }) => ({
  jwtToken: Auth.jwtToken,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);

const crews = [
  {
    image:
      "https://res.cloudinary.com/dzsz395eb/image/upload/v1694008178/WhatsApp_Image_2023-09-06_at_5.54.37_PM_1_ojvp9i.jpg",
    title: "Ipsum",
    rating: 4,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque volutpat tincidunt diam quis pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque volutpat tincidunt diam quis pulvinar.",
  },
  {
    image: "/images/crew2.jpeg",
    title: "Lorem Ipsum",
    rating: 4,

    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque volutpat tincidunt diam quis pulvinar.",
  },
  {
    image: "/images/crew3.jpeg",
    title: "Lorem Ipsum",
    rating: 4,

    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque volutpat tincidunt diam quis pulvinar.",
  },
  {
    image: "/images/crew4.webp",
    title: "Lorem Ipsum",
    rating: 4,

    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque volutpat tincidunt diam quis pulvinar.",
  },
  {
    image: "/images/crew1.webp",
    title: "Lorem Ipsum",
    rating: 4,

    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque volutpat tincidunt diam quis pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque volutpat tincidunt diam quis pulvinar.",
  },
  {
    image: "/images/crew2.jpeg",
    title: "Lorem Ipsum",
    rating: 4,

    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque volutpat tincidunt diam quis pulvinar.",
  },
  {
    image: "/images/crew3.jpeg",
    title: "Lorem Ipsum",
    rating: 4,

    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque volutpat tincidunt diam quis pulvinar.",
  },
  {
    image: "/images/crew4.webp",
    title: "Lorem Ipsum",
    rating: 4,

    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque volutpat tincidunt diam quis pulvinar.",
  },
];
const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  mainContainer: {
    backgroundColor: "transparent",
    width: "100%",
    paddingTop: "60px",
    paddingBottom: "60px",
  },
  headerBox: {
    color: "#000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
