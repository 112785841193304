import * as Types from "../types/";
import axios from "axios";
import config from "../../config";
import { store } from "../store";

export const setSeller = (payload) => ({
  type: Types.SET_SELLER,
  payload,
});

export const removeSeller = () => ({
  type: Types.REMOVE_SELLER,
});

export const networkError = (payload) => ({
  type: Types.NETWORK_ERROR,
  payload,
});

export const clearNetworkError = () => ({
  type: Types.CLEAR_NETWORK_ERROR,
});

export const removeSellerContent = () => async (dispatch) => {
  dispatch(removeSeller());
};

export const thunkGetSeller = () => async (dispatch) => {
  let registerRequest;
  try {
    registerRequest = await axios.get(
      `${config.SERVER_URL}/api/v1/seller/${store.getState().Auth.user.id}`
    );
    const { data } = registerRequest;

    if (registerRequest.status === 200 && data) {
      dispatch(setSeller(data.seller));
    }
  } catch (error) {
    dispatch(networkError({ error: "Service is not available" }));

    setTimeout(() => {
      dispatch(clearNetworkError());
    }, 5000);
  }
};
