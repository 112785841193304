import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import GradeIcon from "@mui/icons-material/Grade";
import PersonIcon from "@mui/icons-material/Person";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import HandymanIcon from "@mui/icons-material/Handyman";
import { FactCheck } from "@mui/icons-material";

function createNav(icon, text, path) {
  return { icon, text, path };
}

export const navItems = [
  createNav(
    <DashboardIcon sx={{ fontSize: "16px" }} />,
    "Dashboard",
    "seller/dashboard"
  ),

  createNav(
    <GradeIcon sx={{ fontSize: "16px" }} />,
    "Reviews",
    "seller/reviews"
  ),
  createNav(
    <FactCheck sx={{ fontSize: "16px" }} />,
    "Listings",
    "seller/listing"
  ),
  createNav(
    <PersonIcon sx={{ fontSize: "16px" }} />,
    "Profile",
    "seller/profile"
  ),

  createNav(
    <HandymanIcon sx={{ fontSize: "16px" }} />,
    "Services",
    "seller/service"
  ),
];

export const dummyData = {
  Messege: [
    {
      senderName: "John Smith",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      picture: `${window.location.origin}/images/crew1.webp`,
    },
    {
      senderName: "Json Roy",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      picture: `${window.location.origin}/images/crew4.webp`,
    },
    {
      senderName: "Steve Smit",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      picture: `${window.location.origin}/images/crew2.jpeg`,
    },
    {
      senderName: "Vincent",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      picture: `${window.location.origin}/images/crew3.jpeg`,
    },
    {
      senderName: "Aljendra",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      picture: `${window.location.origin}/images/crew1.webp`,
    },
    {
      senderName: "James",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      picture: `${window.location.origin}/images/crew2.webp`,
    },
  ],
};

function createData(image, animal, type, date, price, status) {
  return { image, animal, type, date, price, status };
}

export const tableDummyData = [
  createData(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
    "Reptiles",
    "Recessive",
    "23/02/2023",
    "20$"
  ),
  createData(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
    "Amphibians",
    "Recessive",
    "23/02/2023",
    "50$"
  ),
  createData(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
    "Insects",
    "Recessive",
    "23/02/2023",
    "80$"
  ),
  createData(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
    "Plants",
    "Recessive",
    "23/02/2023",
    "70$"
  ),
  createData(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    "Amphibian",
    "Recessive",
    "23/02/2023",
    "10$"
  ),
  createData(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    "Amphibian",
    "Recessive",
    "23/02/2023",
    "10$"
  ),
  createData(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    "Amphibian",
    "Recessive",
    "23/02/2023",
    "10$"
  ),
  createData(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    "Amphibian",
    "Recessive",
    "23/02/2023",
    "10$"
  ),
  createData(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    "Amphibian",
    "Recessive",
    "23/02/2023",
    "10$"
  ),
  createData(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    "Amphibian",
    "Recessive",
    "23/02/2023",
    "10$"
  ),
  createData(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    "Amphibian",
    "Recessive",
    "23/02/2023",
    "10$"
  ),
];

function createDataForListing(
  image,
  name,
  classes,
  creation_date,
  price,
  status
) {
  return { image, name, classes, creation_date, price, status };
}
export const ActiveListingsTableDummyData = [
  createDataForListing(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834304/Moore%20Exotics%20Assets/filter%20category/Snakes_xgcilt.jpg",
    "Reptiles",
    "Recessive",
    "23/02/2023",
    "20$",
    "Active"
  ),
  createDataForListing(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834335/Moore%20Exotics%20Assets/filter%20category/Frog_vqgyio.jpg",
    "Amphibians",
    "Recessive",
    "23/02/2023",
    "50$",
    "Sold"
  ),
  createDataForListing(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834365/Moore%20Exotics%20Assets/filter%20category/Insects_qcz3bd.png",
    "Insects",
    "Recessive",
    "23/02/2023",
    "80$",
    "Pending"
  ),
  createDataForListing(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674834400/Moore%20Exotics%20Assets/filter%20category/Plants_czcssx.jpg",
    "Plants",
    "Recessive",
    "23/02/2023",
    "70$",
    "Rejected"
  ),
  createDataForListing(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    "Amphibian",
    "Recessive",
    "23/02/2023",
    "10$",
    "NFS"
  ),
  createDataForListing(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    "Amphibian",
    "Recessive",
    "23/02/2023",
    "10$",
    "Sold"
  ),
  createDataForListing(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    "Amphibian",
    "Recessive",
    "23/02/2023",
    "10$",
    "Sold"
  ),
  createDataForListing(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    "Amphibian",
    "Recessive",
    "23/02/2023",
    "10$",
    "Sold"
  ),
  createDataForListing(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    "Amphibian",
    "Recessive",
    "23/02/2023",
    "10$",
    "Sold"
  ),
  createDataForListing(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    "Amphibian",
    "Recessive",
    "23/02/2023",
    "10$",
    "Sold"
  ),
  createDataForListing(
    "https://res.cloudinary.com/dwezij7u7/image/upload/v1674833960/Moore%20Exotics%20Assets/filter%20category/All_xllo4o.jpg",
    "Amphibian",
    "Recessive",
    "23/02/2023",
    "10$",
    "Sold"
  ),
];
