import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config";
import { capitalizeFirstLetter } from "../../../utils/Utils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { connect } from "react-redux";
import { Avatar, Box } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { tableDummyData } from "../navbar/NavItemsData";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    color: "inherit",
  },
})(MuiTableCell);

const TableRow = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    cursor: "pointer",
    transition: "0.3s all",
    "&:hover": { color: "rgb(255, 192, 1)", backgroundColor: "#aaaaaa" },
  },
})(MuiTableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100",
    marginTop: theme.spacing(1.5),
    margin: "10px 10px 10px 0",
    overflow: "auto",
    borderRadius: "10px",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0px 9px",
    backgroundColor: "white",
  },
  AccordionSummary: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
  },
}));

const ActiveOrdersTable = ({ seller, jwtToken, user }) => {
  const [serviceListings, setServiceListings] = useState([]);
  const classes = useStyles();
  const SoldItemTableHeadings = [
    "",
    "Name",
    "Category",
    "SubCategory",
    "Status",
    "Price",
  ];
  const handleOpenListing = (data) => {
    window.open(
      `/services/${data.service_id}/subService/${data.sub_service_id}/listings/${data.id}`,
      "_blank"
    );
  };

  const fetchServiceListings = async () => {
    let registerRequest;
    try {
      registerRequest = await axios.get(
        `${config.SERVER_URL}/api/v1/listing_services?filter_status=all`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
          params: {
            seller_id: seller && seller.id,
          },
        }
      );
      if (registerRequest.status === 200) {
        setServiceListings(registerRequest.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchServiceListings();
  }, [jwtToken, seller]);

  return (
    <Paper className={classes.root} elevation={2}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box className={classes.AccordionSummary}>
            <Typography>Active Services</Typography>
            <Typography sx={{ fontSize: "14px", paddingRight: "2px" }}>
              All Services
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ height: "30vh" }}>
          {serviceListings.length > 0 ? (
            <Table stickyHeader className={classes.table}>
              <TableHead>
                <TableRow>
                  {SoldItemTableHeadings.map((item) => (
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: { md: "14px", xl: "16px" },
                        fontWeight: "bold",
                      }}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceListings &&
                  serviceListings.map((data) => (
                    <TableRow
                      key={data.animal}
                      onClick={() => handleOpenListing(data)}
                    >
                      {data.media && data.media?.[0]?.url && (
                        <TableCell
                          align="left"
                          style={{ width: 0, padding: "5px" }}
                        >
                          <Avatar
                            width={40}
                            height={40}
                            alt={data.animal}
                            src={data.media?.[0]?.url}
                          />
                        </TableCell>
                      )}
                      <TableCell align="center" style={{ padding: 0 }}>
                        {data.name}
                      </TableCell>
                      <TableCell align="center" style={{ padding: 0 }}>
                        {data.service.name}
                      </TableCell>
                      <TableCell align="center">
                        {data.sub_service.name}
                      </TableCell>

                      <TableCell align="center">
                        {capitalizeFirstLetter(data.status.split("_")[0])}
                      </TableCell>
                      <TableCell align="center">${data.price}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <Typography textAlign={"center"} sx={{ fontWeight: "bold" }}>
              No Services found.
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

const mapStateToProps = ({ Auth, Seller }) => ({
  jwtToken: Auth.jwtToken,
  user: Auth.user,
  seller: Seller.seller,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveOrdersTable);
