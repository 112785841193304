import React, { useEffect, useState } from "react";
import {
  ImageListItemBar,
  Stack,
  Typography,
  Paper,
  Button,
  Breadcrumbs,
  Card,
  TextField,
  InputAdornment,
  Box,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import ImageListItem, {
  imageListItemClasses,
} from "@mui/material/ImageListItem";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Search } from "@mui/icons-material";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions/";
import IconButton from "@mui/material/IconButton";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
import ListView from "../categories/ListView";

const ServiceList = ({
  subServices,
  loadSubServices,
  services,
  loadServices,
}) => {
  const classes = useStyles();
  const { serviceId } = useParams();
  console.log(serviceId);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [view, setView] = useState("Grid");
  const isLargeScreen = useMediaQuery("(min-width: 2500px)");
  const isLarge38pxScreen = useMediaQuery("(min-width: 3800px)");

  const handleGridView = () => {
    setView("Grid");
  };

  const handleListView = () => {
    setView("List");
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const service = services.filter(
    (service) => service.id === parseInt(serviceId)
  )[0];
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    setLoading(true);
    loadSubServices(serviceId);
    if (!service) {
      loadServices();
    }
    setLoading(false);
  }, [loadSubServices, serviceId]);

  return (
    <Stack className={classes.breedsContainer}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/" className={classes.breadcrumbs}>
          Home
        </Link>
        <Link to="/services" className={classes.breadcrumbs}>
          Services
        </Link>
        <Link className={classes.breadcrumbs}>
          <Typography color="primary">{service?.name}</Typography>
        </Link>
      </Breadcrumbs>
      <Typography
        variant="h3"
        component={"h3"}
        sx={{
          color: "",
          fontWeight: 900,
          textAlign: "center",
        }}
      >
        {service?.name}
      </Typography>
      <Box display={"flex"}>
        <Box sx={{ textAlign: "center" }} flexGrow={1}>
          {" "}
          <TextField
            placeholder="Search your Subbreed"
            type="search"
            size="small"
            sx={{
              width: { xs: "100%", sm: "70%", md: "50%" },
              mt: 2,
              backgroundColor: "white",
              "& .MuiOutlinedInput-root": {
                paddingRight: 0,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  sx={{
                    backgroundColor: "rgb(255, 192, 1)",
                    padding: "27.5px 14px",
                    borderTopLeftRadius: (theme) =>
                      theme.shape.borderRadius + "px",
                    borderBottomLeftRadius: (theme) =>
                      theme.shape.borderRadius + "px",
                  }}
                  position="end"
                >
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearchChange}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton aria-label="delete" onClick={handleGridView}>
            <GridViewIcon sx={{ color: view === "Grid" && "#FFC001" }} />
          </IconButton>{" "}
          <IconButton aria-label="delete" onClick={handleListView}>
            <ListIcon sx={{ color: view !== "Grid" && "#FFC001" }} />
          </IconButton>{" "}
        </Box>
      </Box>
      {loading ? (
        <Box
          sx={{
            mt: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {view === "Grid" ? (
            <Card
              sx={{
                mt: 1,
                p: 4,
                display: "grid",
                gap: 0.5,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                  lg: "repeat(4, 1fr)",
                  xl: isLargeScreen
                    ? isLarge38pxScreen
                      ? "repeat(7, 1fr)"
                      : "repeat(6, 1fr)"
                    : "repeat(5, 1fr)",
                },
                [`& .${imageListItemClasses.root}`]: {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              {subServices &&
                subServices
                  .filter((item) => {
                    if (search === "") {
                      return item;
                    } else if (
                      item.name.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <Paper elevation={24}>
                      <ImageListItem
                        key={item.id}
                        sx={{ height: "100% !important" }}
                      >
                        <div className="container">
                          <img
                            style={{ width: "100%", height: "250px" }}
                            className="image"
                            src={`${item.image}`}
                            srcSet={`${item.image}`}
                            alt={item.name}
                            loading="lazy"
                          />
                          <div className="middle">
                            <div className="layOver"></div>
                            <div className="button">
                              <Button
                                component={Link}
                                to={`/services/${serviceId}/subService/${item.id}/listings`}
                                // to={`/listings/breed/${item.id}`}
                                //   to={`/listings/subbreed/${item.id}`}
                                size="large"
                                variant="outlined"
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "rgb(255, 192, 1)",
                                    color: "#000",
                                  },
                                }}
                              >
                                Go Now
                              </Button>
                            </div>
                          </div>
                        </div>

                        <ImageListItemBar
                          title={item.name}
                          sx={{ textAlign: "center" }}
                        />
                      </ImageListItem>
                    </Paper>
                  ))}
            </Card>
          ) : (
            <ListView
              categories={subServices}
              page={"subservice"}
              serviceId={serviceId}
              search={search}
            />
          )}
        </>
      )}
      <Box sx={{ textAlign: "center" }} mt={2}>
        <Button
          component={Link}
          //   to={`/listings/breed/${breedId}`}
          //   to={"#"}
          to={`/services/listings/classes/${serviceId}`}
          size="large"
          variant="contained"
          sx={{
            width: { md: "90%", lg: "30%" },
            height: 40,
            color: "#fff",
            fontSize: 18,
            fontWeight: 700,
            textTransform: "none !important",
          }}
        >
          See {service?.name}
        </Button>
      </Box>
    </Stack>
  );
};

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  breedsContainer: {
    backgroundColor: "#ebecf0",
    padding: "24px 5px 24px 5px",
  },
  breadcrumbs: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      color: "rgb(255, 192, 1)",
      textDecoration: "underline",
    },
  },
});

const mapStateToProps = ({ Services }) => ({
  subServices: Services.subServices,
  services: Services.services,
});

const mapDispatchToProps = (dispatch) => ({
  loadServices: () => dispatch(Actions.thunkLoadServices()),

  loadSubServices: (serviceId) =>
    dispatch(Actions.thunkLoadSubServices(serviceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceList);
