import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { imageListItemClasses } from "@mui/material/ImageListItem";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Grid,
  Snackbar,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";

const Education = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("search");
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [paginationStates, setPaginationStates] = useState({
    page: 0,
    rowsPerPage: 10,
    total_records: 0,
  });
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });

  useEffect(() => {
    console.log("searchQuery changed:", searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    const fetchPosts = async () => {
      let url;
      setLoading(true);

      if (searchQuery) {
        url = `https://education.mooreexotics.com/wp-json/wp/v2/posts?_embed&search=${searchQuery}&per_page=${paginationStates.rowsPerPage}&page=${paginationStates.page + 1}`;
      } else {
        url = `https://education.mooreexotics.com/wp-json/wp/v2/posts?_embed&per_page=${paginationStates.rowsPerPage}&page=${paginationStates.page + 1}`;
      }
      try {
        const response = await axios.get(url);
        const { data } = response;
        if (response.status === 200) {
          setPosts(data);
          setLoading(false);
          setPaginationStates({
            ...paginationStates,
            total_records: parseInt(
              response.headers["x-wp-total"] || 0,
              10
            ),
          });
        }
      } catch (err) {
        setSnackbarStates({
          open: true,
          message: "Unable to load posts right now. Please try again later.",
          severity: "error",
        });
        console.error("Something went wrong....", err);
      }
    };
    fetchPosts();
  }, [searchQuery, paginationStates.page, paginationStates.rowsPerPage, navigate]);

  const onPageChange = (event, newPage) => {
    setPaginationStates({ ...paginationStates, page: newPage });
  };

  const onRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPaginationStates({
      ...paginationStates,
      rowsPerPage: newRowsPerPage,
      page: 0,
    });
  };

  const handleCloseCommunityAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };

  return (
    <>
      <Snackbar
        open={snackbarStates.open}
        autoHideDuration={3000}
        onClose={handleCloseCommunityAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseCommunityAlert}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <Stack>
        <Box className={classes.headerBox} sx={{ width: "100%", height: "200px" }}>
          <Typography variant={"h3"} sx={{ fontWeight: "600", textAlign: "center" }}>
            Moore Exotics Education
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 4,
          }}
        >
          {searchQuery && (
            <>
              <Chip
                label={
                  <Typography noWrap sx={{ maxWidth: { xs: "235px" } }}>
                    Search results for "{searchQuery}"
                  </Typography>
                }
                size="small"
                sx={{
                  ml: "5px",
                  mt: "5px",
                  backgroundColor: "#FFC001",
                  color: "#fff",
                  borderRadius: "6px",
                  fontWeight: "bold",
                  fontSize: "12px",
                  maxWidth: "50%",
                  wordBreak: "break-word",
                  p: 0,
                }}
              />
              <CloseIcon
                sx={{
                  mt: 0.6,
                  fontSize: { xs: "22px", md: "27px" },
                  color: "rgb(255, 192, 1)",
                }}
                onClick={() => navigate("/education")}
              />
            </>
          )}
        </Box>
        {!loading ? (
          posts.length > 0 ? (
            <Box
              sx={{
                marginX: { xs: "20px", md: "100px" },
                marginTop: 4,
                marginBottom: 4,
                overflow: "auto",
                border: "1px solid #eeeeee",
                borderRadius: "10px",
                padding: "5px",
              }}
            >
              <Grid
                sx={{
                  margin: "10px",
                  maxWidth: "100vw",
                  backgroundColor: "transparent",
                  mt: 2,
                  display: "grid",
                  gap: 2,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)",
                    xl: "repeat(3, 1fr)",
                  },
                  [`& .${imageListItemClasses.root}`]: {
                    display: "flex",
                    flexDirection: "column",
                  },
                }}
              >
                {posts.map((item) => (
                  <Box
                    key={item.id}
                    sx={{
                      margin: { md: "2px" },
                    }}
                  >
                    <Card
                      sx={{
                        border: "0.2px solid #eeeeee",
                        borderRadius: "10px",
                        transition: "0.3s all",
                      }}
                    >
                      <CardMedia
                        sx={{ height: 180, widht: "100%", backgroundSize: "contain" }}
                        image={item?._embedded?.["wp:featuredmedia"][0]?.source_url}
                        title={item.title.rendered}
                      />
                      <CardContent sx={{ height: "150px" }}>
                        <Typography
                          component="div"
                          sx={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          {item.title.rendered}
                        </Typography>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `${item.excerpt.rendered.slice(0, 150)}...`,
                          }}
                        />
                        <Link
                          to={item.link}
                          target="_blank"
                          style={{
                            textDecoration: "none",
                          }}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              color: "rgb(255, 192, 1)",
                              backgroundColor: "#eeeeee",
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              textAlign: "center",
                              color: "#FFC001",
                              cursor: "pointer",
                            }}
                          >
                            Read More
                          </Typography>
                        </Link>
                      </CardContent>
                    </Card>
                  </Box>
                ))}
              </Grid>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="bottom"
                sx={{ mt: 1 }}
              >
                <TablePagination
                  component="div"
                  count={paginationStates.total_records}
                  page={paginationStates.page}
                  onPageChange={onPageChange}
                  rowsPerPage={paginationStates.rowsPerPage}
                  onRowsPerPageChange={onRowsPerPageChange}
                  labelRowsPerPage="Posts per page"
                />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                mt: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                No Posts Found
              </Typography>
            </Box>
          )
        ) : (
          <Box
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Stack>
    </>
  );
};

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  headerBox: {
    backgroundColor: "#000",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default Education;
