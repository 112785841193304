import * as Types from "../types/index";

const preState = {
  messagesByRoom: {},
  conversations: [],
  unreadCount: 0,
  loadConversation: true,
};

const MessageReducer = (state = preState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_MESSAGES:
      return {
        ...state,
        messagesByRoom: {
          ...state.messagesByRoom,
          [action.payload.roomId]: action.payload.messages,
        },
      };

    // case Types.NEW_MESSAGE:
    //   const { roomId, message } = action.payload;
    //   const updatedConversations = state.conversations.map(
    //     (conversationObj) => {
    //       if (conversationObj.conversation.uniqueName === roomId) {
    //         return {
    //           ...conversationObj,
    //           lastMessage: message,
    //           unreadCount: conversationObj.unreadCount + 1,
    //         };
    //       }
    //       return conversationObj;
    //     }
    //   );

    //   return {
    //     ...state,
    //     conversations: updatedConversations,
    //     messagesByRoom: {
    //       ...state.messagesByRoom,
    //       [roomId]: [...state.messagesByRoom[roomId], message],
    //     },
    //   };
    case Types.NEW_MESSAGE:
      const { roomId, message } = action.payload;

      const updatedConversations = state.conversations.map((conversationObj) => {
        if (conversationObj.conversation.uniqueName === roomId) {
          return {
            ...conversationObj,
            lastMessage: message,
            unreadCount: conversationObj.unreadCount + 1,
          };
        }
        return conversationObj;
      });

      const isMessageExists = state.messagesByRoom[roomId]?.some(
        (existingMessage) => existingMessage.index === message.index
      );

      const updatedMessagesByRoom = {
        ...state.messagesByRoom,
        [roomId]: isMessageExists
          ? state.messagesByRoom[roomId]
          : [...(state.messagesByRoom[roomId] || []), message],
      };

      return {
        ...state,
        conversations: updatedConversations,
        messagesByRoom: updatedMessagesByRoom,
      };

    case Types.UPDATE_MESSAGE_MEDIA_URL:
      return {
        ...state,
        MessageByRoom: payload.roomId.map((message) => {
          if (message.sid === payload.messageId) {
            return {
              ...message,
              mediaUrl: payload.mediaUrl,
            };
          }
          return message;
        }),
      };
    case Types.DELETE_MESSAGE:
      return {
        ...state,
        MessageByRoom: state[payload.roomId].filter(
          (message) => message.sid !== payload.messageId
        ),
      };
    case Types.UNREAD_COUNT:
      return {
        ...state,
        unreadCount: payload.unreadCount,
      };
    case Types.SET_CONVERSATIONS:
      return {
        ...state,
        conversations: payload.conversations,
        loadConversation: false,
      };

    case Types.UPDATE_CONVERSATION_COUNT:
      const { conversation, unreadCount } = action.payload;
      const updtConv = state.conversations.map((conversationObj) => {
        if (
          conversationObj.conversation.uniqueName === conversation.uniqueName
        ) {
          return {
            ...conversationObj,
            unreadCount: unreadCount,
          };
        }
        return conversationObj;
      });

      return {
        ...state,
        conversations: updtConv,
      };
    default:
      return state;
  }
};

export default MessageReducer;
