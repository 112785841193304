import { Box, Container, Grid, Typography } from "@mui/material";
import SwiperCore, { Autoplay, Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import ListMedia from "../listdetails/ListMedia";
import { makeStyles } from "@mui/styles";

const FeaturedListings = ({ classes, featuredListings }) => {
  const styleclass = useStyles();

  const getCategoryName = (id) => {
    let category;
    if (classes.length > 0) {
      category = classes.filter((cat) => cat.id === id);
    }
    return category[0].name;
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    return `${year}`;
  };

  SwiperCore.use([Autoplay]);
  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingY: "60px",
      }}
    >
      <Container fixed maxWidth={false}>
        {featuredListings.length >= 4 ? (
          <Swiper
            style={{
              "--swiper-navigation-color": "rgb(255, 192, 1)",
              "--swiper-pagination-color": "rgb(255, 192, 1)",
            }}
            spaceBetween={30}
            navigation={true}
            modules={[Navigation, Scrollbar]}
            grabCursor={true}
            justifyContent={"center"}
            alignItems={"center"}
            autoplay={true}
            loop={true}
            breakpoints={{
              320: { slidesPerView: 1 },
              480: { slidesPerView: 1 },
              600: { slidesPerView: 2 },
              900: { slidesPerView: 3 },
              1200: { slidesPerView: 4 },
              1500: { slidesPerView: 4 },
            }}
          >
            {featuredListings &&
              featuredListings.map((list) => {
                return (
                  <SwiperSlide>
                    <Box
                      sx={{
                        margin: { md: "2px" },
                      }}
                    >
                      <Link
                        to={"/listing/" + list.id}
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Card
                          sx={{
                            border: "0.2px solid #eeeeee",
                            borderRadius: "10px",
                            cursor: "pointer",
                            transition: "0.3s all",
                            "&:hover": {
                              color: "rgb(255, 192, 1)",
                              backgroundColor: "#eeeeee",
                            },
                          }}
                        >
                          <div className={styleclass.imageContainer}>
                            <CardMedia
                              sx={{ height: 180, backgroundSize: "contain" }}
                              image={list.media[0]?.url}
                              title={list.media[0]?.url}
                            />
                            {list.on_hold && (
                              <div
                                className={
                                  list.on_hold && styleclass.onHoldText
                                }
                              >
                                on Hold
                              </div>
                            )}
                          </div>
                          <CardContent sx={{ height: 80 }}>
                            <Grid xs={12}>
                              <Typography variant="h6" noWrap fontWeight="bold">
                                {list.name}
                              </Typography>
                            </Grid>
                            <Grid xs={12}>
                              <Typography
                                variant="p"
                                noWrap
                                sx={{ color: "grey" }}
                              >
                                {getCategoryName(list.category_id)}
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Grid item xs="auto">
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    color: "grey",
                                    fontFamily: "SharpSans",
                                  }}
                                >
                                  {formatDate(list.created_at)}
                                </Typography>
                              </Grid>
                              <Grid item xs fontSize="medium">
                                {list.sex === "male" ? (
                                  <MaleIcon sx={{ color: "blue" }} />
                                ) : list.sex === "female" ? (
                                  <FemaleIcon sx={{ color: "#AA336A" }} />
                                ) : (
                                  <>
                                    <MaleIcon sx={{ color: "blue" }} />
                                    <FemaleIcon sx={{ color: "#AA336A" }} />
                                  </>
                                )}
                              </Grid>
                              <Grid item>
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: "22px",
                                      md: "24px",
                                      lg: "27px",
                                    },
                                    fontFamily: "SharpSans",
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ${list.price}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Link>
                    </Box>
                    {/* </Grid> */}
                  </SwiperSlide>
                );
              })}
          </Swiper>
        ) : (
          <ListMedia
            listings={featuredListings}
            classes={classes}
            page={"Home"}
          />
        )}
      </Container>
    </Box>
  );
};

const useStyles = makeStyles({
  imageContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "500px",
  },
  onHoldText: {
    position: "absolute",
    top: 30,
    right: -25,
    width: "100px",
    textAlign: "center",
    backgroundColor: "#FFD300",
    color: "#fff",
    padding: "5px",
    fontSize: "14px",
    transform: "translate(-0%, 100%) rotate(45deg)",
    transformOrigin: "top right",
  },
});
const mapStateToProps = ({ Categories, Listings }) => ({
  classes: Categories.classes,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedListings);
