import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navbars/Navbar";
import { useLocation } from 'react-router-dom';
import { connect } from "react-redux";


function BuyerLayout() {
  const location = useLocation();

  return (
    <>
      <NavBar />
      <Outlet />

      {!location.pathname.includes('/conversations') && <Footer />
      }
    </>
  );
}
const mapStateToProps = ({ Store }) => ({
  store: Store.store
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BuyerLayout);
