import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import axios from "axios";
import config from "../../config";

const CheckoutForm = ({ user, setUserIsSubscribed, jwtToken }) => {
  const stripe = useStripe();
  const elements = useElements();
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const couponCode = queryParams.get('couponCode');
  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] = useState({});
  const [paymentIntent, setPaymentIntent] = useState();
  const [processing, setProcessing] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const createSubscription = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.post(
          `${config.SERVER_URL}/api/v1/subscriptions`,
          {
            data: {
              plan_id: params.planId,
              user_id: user.id,
              coupon_code: couponCode,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;
        if (registerRequest.status === 422) {
          setError(registerRequest.data.error);
          //return navigate("/seller/register");
        }
      }
      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 201) {
        setSubscriptionData(registerRequestData);
      }
    };

    createSubscription();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setProcessing(true);
    const fields = ["name"];
    const formElements = e.target.elements;

    const formValues = fields
      .map((field) => ({
        [field]: formElements.namedItem(field).value,
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    const cardElement = elements.getElement(CardElement);

    let { error, paymentIntent } = await stripe.confirmCardPayment(
      subscriptionData.clientSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: formValues.name,
          },
        },
      }
    );

    if (error) {
      setError(error.message);
      setProcessing(false);
      return;
    }
    setPaymentIntent(paymentIntent);
    setProcessing(false);
  };

  if (paymentIntent && paymentIntent.status === "succeeded") {
    setUserIsSubscribed();
    return navigate("/seller/register");
  }

  if (user && user.is_subscribed) {
    return navigate("/seller/register");
  }

  return (
    <Stack style={{ backgroundColor: "#ebecf0" }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alginItem="center"
      >
        <Grid item display="flex">
          <Card
            sx={{
              padding: "40px",
              margin: "auto",
              width: { xs: "80%", sm: "60%", md: "40%", lg: "30%", xl: "25%" },
            }}
          >
            <CardContent>
              <Typography
                id="modal-modal-title"
                sx={{
                  fontWeight: 600,
                }}
                align="center"
                component={"h4"}
                variant={"h4"}
              >
                Checkout
              </Typography>
              <Typography
                id="modal-modal-title"
                align="left"
                sx={{
                  mt: 1,
                  mb: 1,
                }}
                component={"p"}
                variant={"p"}
              >
                Enter your card details below to get the subscription at our
                platform.
              </Typography>
              <form onSubmit={handleSubmit}>
                <Typography
                  component={"p"}
                  sx={{
                    mt: 2,
                    fontWeight: 600,
                  }}
                >
                  Full Name
                </Typography>
                <TextField
                  placeholder="Enter your full name"
                  type="text"
                  name="name"
                  size="small"
                  fullWidth
                  required
                />
                <Typography
                  component={"p"}
                  sx={{
                    mt: 2,
                    fontWeight: 600,
                  }}
                >
                  Card Details
                </Typography>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!stripe || !elements}
                  fullWidth
                  sx={{
                    mt: 4,
                    mb: 1,
                    color: "#fff",
                    fontWeight: 700,
                    textTransform: "none !important",
                  }}
                >
                  {processing ? (
                    <CircularProgress size="24px" sx={{ color: "#fff" }} />
                  ) : (
                    "Pay"
                  )}
                </Button>
                {error && <span style={{ color: "red" }}>{error}</span>}
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};

const mapStateToProps = ({ Auth }) => ({
  responseErrors: Auth.responseError,
  user: Auth.user,
  jwtToken: Auth.jwtToken,
});

const mapDispatchToProps = (dispatch) => ({
  setUserIsSubscribed: () => dispatch(Actions.setUserIsSubscribed()),
});

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: "#000",
      color: "#000",
      fontWeight: "500",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "grey",
      },
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#red",
    },
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
