import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { store } from "../redux/store";
import axios from "axios";
import config from "../config";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const user = store.getState().Auth.user;
  const jwtToken = store.getState().Auth.jwtToken;
  const storeCreated = store.getState().Store.store;
  const planName = ["Diamond Special", "Diamond", "Gold", "Platinum"];
  const [open, setOpen] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState();
  let location = useLocation();
  const navigate = useNavigate();

  const handleUpgradePlan = () => {
    console.log("in handle upgrade plan");
    navigate("/seller/dashboard");
  };

  const getSubscriptionDetails = async () => {
    let registerRequest;

    try {
      registerRequest = await axios.get(
        `${config.SERVER_URL}/api/v1/subscriptions`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (registerRequest.data) {
        setSubscriptionData(registerRequest.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (location.pathname.includes("/seller/service") && !open) {
      getSubscriptionDetails();
      if (
        subscriptionData &&
        !planName.includes(subscriptionData?.plan?.name)
      ) {
        handleOpen();
      }
    }
  }, [location, subscriptionData?.plan?.name]);

  const handleClose = () => {
    setOpen(false);

    navigate("/seller/dashboard");
  };

  if (user && !user.email) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  if (!user) {
    if (
      !location.pathname.includes("policy") &&
      !location.pathname.includes("condition") &&
      !location.pathname.includes("services") &&
      !location.pathname.includes("contact") &&
      !location.pathname.includes("about")
    ) {
      return <Navigate to="/auth/login" state={{ from: location }} replace />;
    }
  }

  if (user && user.email && user.email.split("_")[0] === "temp") {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upgrade your plan to sell services</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can sell services using Gold Plan and above.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button
            onClick={handleUpgradePlan}
            color="primary"
            component={Link}
            to={`${user?.billingPortalUrl}`}
          >
            Upgrade
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </>
  );
};

export const ProtectedAuthRoute = ({ children }) => {
  const user = store.getState().Auth.user;
  let location = useLocation();

  if (user && user.email) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return children;
  }
};
