import * as Types from "../types";

const preState = {
  listings: [],
  serviceListing: [],
  loading: false,
  totalListing: 0,
  servicesListing: [],
  totalServiceListing: 0,
  servicesLoading: false,
  errorServices: "",
  errorListings: "",
};

const ListingsReducer = (state = preState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.LOAD_LISTING:
      return {
        ...state,
        listings: payload.listings,
        loading: payload.loading,
        totalListing: payload.totalListing,
      };

    case Types.ERROR_LOAD_LISTING:
      return {
        ...state,
        errorListings: payload.errorList,
      };

    case Types.LOAD_SERVICE_LISTING:
      return {
        ...state,
        serviceListing: payload.listings,
        totalServiceListing: payload.totalListing,
        servicesLoading: payload.loading,
      };

    case Types.ERROR_LOAD_SERVICE_LISTING:
      return {
        ...state,
        errorServices: payload.errorServices,
      };
    case Types.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case Types.LOADING_SERVICE_LISTING:
      return {
        ...state,
        servicesLoading: true,
      };

    case Types.REMOVE_LISTINGS:
      return {
        ...preState,
      };
    default:
      return state;
  }
};

export default ListingsReducer;
