import * as Types from "../types/";
import axios from "axios";
import config from "../../config";
import { store } from "../store";
import sortBy from "lodash.sortby";
import { Client as ConversationsClient } from "@twilio/conversations";

import { EventEmitter } from "fbemitter";

export const setMessages = (payload) => ({
  type: Types.SET_MESSAGES,
  payload,
});

export const newMessages = (payload) => ({
  type: Types.NEW_MESSAGE,
  payload,
});

export const updateMessage = (payload) => ({
  type: Types.UPDATE_MESSAGE_MEDIA_URL,
  payload,
});

export const deleteMessage = () => ({
  type: Types.DELETE_MESSAGE,
});

export const setUnreadCount = (payload) => ({
  type: Types.UNREAD_COUNT,
  payload,
});

export const setConversations = (payload) => ({
  type: Types.SET_CONVERSATIONS,
  payload,
});

export const updateConversationCount = (payload) => ({
  type: Types.UPDATE_CONVERSATION_COUNT,
  payload,
});

export const thunkInitChatClient =
  ({ token, setClient }) =>
    async (dispatch) => {
      try {
        const chatClient = await ConversationsClient.create(token);
        setClient(chatClient);
      } catch (err) {
        console.log(err);
      }
    };

const getAllConversationPages = async (items, chatConversationsPaginator) => {
  if (chatConversationsPaginator.hasNextPage) {
    const nextChatConversationsPaginator =
      await chatConversationsPaginator.nextPage();
    const allItems = items.concat(nextChatConversationsPaginator.items);
    return getAllConversationPages(allItems, nextChatConversationsPaginator);
  }
  return items;
};

export const thunkLoadGetConversations =
  ({
    client,
    setLoading,
    setSenderOnlineStatus,
    setReceiverOnlineStatus,
    jwtToken,
    user,
    roomEmittersRef,
  }) =>
    async (dispatch) => {
      if (user?.id) {
        let unreadTotalCount = 0;

        const chatConversationsPaginator =
          await client.getSubscribedConversations();
        const allChatConversationItems = await getAllConversationPages(
          chatConversationsPaginator.items,
          chatConversationsPaginator
        );
        const sortedAllChatConversationItems = sortBy(
          allChatConversationItems,
          (item) => item.lastMessage?.dateCreated || item.dateCreated
        ).reverse();

        const mappedConversations = await Promise.all(
          sortedAllChatConversationItems.map(async (conversation) => {
            const msgsPaginator = await conversation.getMessages();
            const messages = await Promise.all(
              msgsPaginator.items.map(async (msg) => {
                if (msg.type === "media" && msg.attachedMedia) {
                  if (Array.isArray(msg.attachedMedia)) {
                    const mediaUrls = await Promise.all(
                      msg.attachedMedia.map(async (media) => ({
                        url: await media.getContentTemporaryUrl(),
                        content_type: media.contentType,
                        author: msg.author,
                      }))
                    );
                    msg.mediaUrls = mediaUrls;
                  }
                }
                return msg;
              })
            );

            const emitter = new EventEmitter();
            roomEmittersRef.current[conversation.uniqueName] = emitter;
            dispatch(
              setMessages({
                roomId: conversation.uniqueName,
                messages,
              })
            );

            let unreadCount = await conversation.getUnreadMessagesCount();
            unreadTotalCount += unreadCount;

            const participants = await conversation.getParticipants();

            if (unreadCount === null) {
              await conversation.updateLastReadMessageIndex(0);
            }

            conversation.on("messageAdded", async (msg) => {
              if (msg.type === "media" && msg.attachedMedia) {
                if (Array.isArray(msg.attachedMedia)) {
                  const mediaUrls = await Promise.all(
                    msg.attachedMedia.map(async (media) => ({
                      url: await media.getContentTemporaryUrl(),
                      content_type: media.contentType,
                      author: msg.author,
                    }))
                  );
                  msg.mediaUrls = mediaUrls;
                }
              }

              const hiddenParticipants = participants.filter(
                (participant) => participant.attributes.hidden
              );
              if (hiddenParticipants.length) {
                const hiddenParticipant = hiddenParticipants[0];
                try {
                  const { data: statusUpdateResponse } = await axios.patch(
                    `${config.SERVER_URL}/conversations/update_conversation_participant`,
                    {
                      participant: {
                        conversation_id: conversation.sid,
                        participant_id: hiddenParticipant.sid,
                        status: false,
                      },
                    },
                    {
                      headers: {
                        "Content-Type": "multipart/form-data;",
                        Authorization: `Bearer ${jwtToken}`,
                      },
                    }
                  );
                } catch (err) {
                  console.log(err);
                }
              }
              dispatch(
                newMessages({
                  roomId: msg.conversation.uniqueName,
                  unreadCount: msg.conversation.unreadCount + 1,
                  message: msg,
                })
              );
              emitter.emit("messageAdded", msg);
            });

            emitter.addListener("messagesConsumed", (roomId) => {
              dispatch(
                setConversations({
                  conversations: (chs) =>
                    chs.map((ch) => {
                      if (ch.uniqueName === roomId) {
                        return {
                          conversation: ch.conversation,
                          lastMessage: ch.lastMessage,
                          unreadCount: 0,
                        };
                      }
                      return ch;
                    }),
                })
              );
            });

            const lastMessage = messages.find(
              (msg) => msg.index === conversation.lastMessage.index
            );

            return {
              conversation: conversation,
              lastMessage: lastMessage,
              unreadCount: unreadCount,
            };
          })
        );

        dispatch(setConversations({ conversations: mappedConversations }));
        setLoading(false);
        dispatch(setUnreadCount({ unreadCount: unreadTotalCount }));
      }
    };
