import React, { useEffect, useState } from "react";
import Messege from "./Messege";
import { Card, Grid, Typography } from "@mui/material";
import axios from "axios";
import config from "../../../config";
import { connect } from "react-redux";

const SellerDashboardMessegeList = ({ seller, jwtToken }) => {
  const [reviewData, setReviewData] = useState([]);
  const plans = ["Silver", "Free"];

  useEffect(() => {
    const fetchSellerReviews = async () => {
      let registerRequest;
      try {
        registerRequest = await axios.get(
          `${config.SERVER_URL}/api/v1/reviews?seller_id=${seller.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        if (registerRequest && registerRequest.status === 200) {
          const filteredReviews = registerRequest.data.reviews.filter(
            (data) => {
              if (plans.includes(seller.user.subscriptions[0].plan.name)) {
                if (data.reviewable_type === "ListingAnimal") {
                  return data;
                }
              } else {
                return data;
              }
            }
          );
          setReviewData(filteredReviews);
        }
      } catch (err) {
        if (err.status === 422) {
          console.log("Show error snackbar");
        }
        if (err) {
          console.log("Show error snackbar", err.messege);
        }
      }
    };

    if (seller) {
      fetchSellerReviews();
    }
  }, [seller]);
  return (
    <Card
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "10px",
        height: "35vh",
        borderRadius: "10px",
        backgroundColor: "white",
        cursor: "pointer",
      }}
    >
      <Grid sx={{ overflow: "auto" }}>
        {reviewData.length > 0 ? (
          reviewData.map((messege) => <Messege messege={messege} />)
        ) : (
          <Typography textAlign={"center"}>No reviews found</Typography>
        )}
      </Grid>
    </Card>
  );
};
const mapStateToProps = ({ Auth, Seller }) => ({
  seller: Seller.seller,
  jwtToken: Auth.jwtToken,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SellerDashboardMessegeList);
