import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";
import FeaturedListings from "../../components/home/FeaturedListings";

const ListingGallery = ({ featuredListings }) => {
  return (
    <>
      <GalleryContainer
        sx={{
          paddingY: "120px",
        }}
      >
        <Container fixed maxWidth={false}>
          <RecentlyBlogBox>
            <Grid
              className="recentlyBlog"
              container
              sx={{ marginBottom: "40px" }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h2"
                    component="h2"
                    sx={{
                      fontWeight: "700",
                      fontSize: "50px",
                      lineHeight: "66px",
                    }}
                    className="recenttitle"
                  >
                    Featured Listings
                  </Typography>
                  <Typography
                    className="galleryContent"
                    component={"p"}
                    sx={{
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "30px",
                      marginTop: "10px",
                    }}
                  >
                    Here are some of the featured listings
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </RecentlyBlogBox>
          <FeaturedListings featuredListings={featuredListings} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              component={Link}
              to={`/listings?is_featured=true`}
              sx={{ textTransform: "none", fontWeight: "bold" }}
            >
              See Similar Featured Listing
            </Button>
          </Box>
        </Container>
      </GalleryContainer>
    </>
  );
};

const RecentlyBlogBox = styled(Grid)`
  @media screen and (max-width: 901px) {
    .recentlyBlog {
      display: block !important;
    }
  }
  @media screen and (max-width: 1550px) {
    .recenttitle {
      font-size: 44px;
      line-height: 66px;
    }
  }
  @media screen and (max-width: 1250px) {
    .recenttitle {
      font-size: 34px;
      line-height: 40px;
    }
  }
  @media screen and (max-width: 601px) {
    text-align: center;
  }
`;

const ViewMoreBtn = styled(Box)`
  text-align: right;
  & Button {
    background-color: #ffc001;
    font-style: normal;
    text-decoration: none !important;
    color: #000000;
    font-weight: 500;
    margin-top: 25px;
    font-size: 18px !important;
    line-height: 24px;
    text-transform: capitalize;
    min-width: 350px !important;
    height: 48px;
  }
  @media screen and (max-width: 901px) {
    button {
      float: left;
      margin-bottom: 20px;
    }
  }
`;

const GalleryContainer = styled(Box)`
  @media screen and (max-width: 769px) {
    padding-top: 50px;
    padding-bottom: 150px;
    position: relative;
    .gallery {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.02em;
      margin-bottom: 5px;
      text-align: center;
    }
    .galleryContent {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
    }
    .ViewMoreBtn button {
      position: absolute;
      bottom: 60px;
      left: 0;
      right: 0;
      margin: auto;
      float: none;
      width: 150px;
    }
  }
`;
export default ListingGallery;
