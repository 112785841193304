import * as Types from "../types/index";

const preState = {
  seller: null,
  error: "",
};

const SellerReducer = (state = preState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_SELLER:
      return {
        ...state,
        seller: payload,
      };
    case Types.REMOVE_SELLER:
      return {
        ...preState,
      };
    case Types.NETWORK_ERROR:
      return {
        ...preState,
        error: payload.error,
      };
    case Types.CLEAR_NETWORK_ERROR:
      return {
        ...preState,
        error: "",
      };
    default:
      return state;
  }
};

export default SellerReducer;
