import { Box, Paper, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "@mui/system";

const Ad = ({ adData }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <BannerTypo>
        <Container fixed maxWidth={false}>
          <Typography className="BanTypo" component="h1">
            Advertisement
          </Typography>
        </Container>
      </BannerTypo>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "70vh",
          overflow: "hidden",
        }}
      >
        {adData && (
          <Paper
            elevation={0}
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              position: "absolute",
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={
                isMobile
                  ? adData[0]?.mobile_media[0]?.url
                  : adData[0]?.desktop_media[0]?.url
              }
              alt="Advertisement"
              style={{
                width: "95%",
                height: "90%",
                objectFit: "cover",
                borderRadius: "25px",
              }}
            />
          </Paper>
        )}
      </Box>
    </>
  );
};

export default Ad;

const BannerTypo = styled(Box)`
  & .BanTypo {
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    line-height: 66px;
    font-style: normal;
    margin-top: 120px;
  }
  @media screen and (max-width: 1201px) {
    .BanTypo {
      font-size: 34px;
      line-height: 48px;
    }
  }
  @media screen and (max-width: 601px) {
    .BanTypo {
      margin-top: 50px;
      font-weight: 700px;
      font-size: 25px;
      line-height: 30px;
    }
  }
`;
