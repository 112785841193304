import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Paper,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import React, { useEffect, useState } from "react";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import moment from "moment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { Link, useNavigate } from "react-router-dom";
import MailIcon from "@mui/icons-material/Mail";
import GridViewIcon from "@mui/icons-material/GridView";
import LoginIcon from "@mui/icons-material/Login";
import ClearIcon from "@mui/icons-material/Clear";
import {
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import { makeStyles, createStyles } from "@mui/styles";
import EmailIcon from "@mui/icons-material/Email";
import InquireDialog from "./InquireDialog";
import ReviewModal from "../serviceListings/ReviewModal";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import axios from "axios";
import config from "../../config";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const ListDetailsCard = ({
  listData,
  seller,
  jwtToken,
  user,
  store,
  loadStore,
}) => {
  // console.log("USER", user);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [openSucessAlert, setOpenSucessAlert] = useState(false);
  const [openReviewSucessAlert, setReviewOpenSucessAlert] = useState(false);
  const [hideInquireToBuy, setIsHideInquireToBuy] = useState(false);
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [reviewOpenSucessAlertText, setReviewOpenSucessAlertText] =
    useState("");

  const navigate = useNavigate();

  const calculateAge = (birthDay) => {
    let today = new Date();
    let birthDate = new Date(birthDay);
    const daysDifference = differenceInDays(today, birthDate) % 30;
    const monthsDifference = differenceInMonths(today, birthDate) % 12;
    const yearDifference = differenceInYears(today, birthDate);

    return {
      years: yearDifference,
      months: monthsDifference,
      days: daysDifference,
    };
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };
  const responseData = {
    Sex: listData.sex,
    Color: listData.color,
    Traits: listData.traits,
    Weight: listData.weight,
    Height: listData.height,
    Length: listData.length,
    Birth: listData.birth,
    breedName: listData.breed.name,
    img: listData.seller?.store?.brand_logo.url,
    store: listData.seller?.store,
    brandName:
      listData.seller?.store?.brand_name && listData.seller?.store?.brand_name,
    maxPrice:
      listData.seller.store?.max_price != null
        ? parseFloat(listData.seller?.store.max_price).toFixed(2).toString()
        : null,
    minPrice:
      listData.seller.store?.max_price != null
        ? parseFloat(listData.seller?.store?.min_price).toFixed(2).toString()
        : null,
    shippingType: listData.seller?.store?.delivery_option,
    Age: calculateAge(listData.birth),
    Diet: listData.diet,
    Location: listData?.seller?.store?.location,
    Price:
      "$" + Number.isInteger(listData.price)
        ? listData.price.toFixed(2)
        : listData.price,
    Shipping: listData.shipping_details,
    FirstPosted: formatDate(listData.first_posted),
    LastUpdated: formatDate(listData.last_updated),
    AnimalId: listData.id,
    storeId: listData.seller.store?.id,
    sellerId: listData.seller.id,
    userId: listData.seller.user.id,
    categoryId: listData.category_id,
    listingId: listData.id,
    status: listData.status,
  };

  useEffect(() => {
    if (seller) {
      loadStore({ storeId: responseData.storeId });
    }
  }, [responseData.storeId]);

  useEffect(() => {
    if (store) {
      const trophyCaseIds = store.trophy_cases.map((trophyCase) => {
        return trophyCase.listing_animal_id;
      });

      setIsHideInquireToBuy(
        trophyCaseIds.includes(responseData.listingId) &&
          responseData.status === "nfs"
      );
    }
  }, [store]);

  useEffect(() => {
    const getConversation = async () => {
      setLoading(true);
      let registerRequest;
      try {
        registerRequest = await axios.post(
          `${config.SERVER_URL}/conversations/check_existing_conversation`,
          {
            conversation: {
              listing_id: responseData.AnimalId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } catch ({ response }) {
        registerRequest = response;
      }

      const { data: registerRequestData } = registerRequest;
      if (registerRequest.status === 200) {
        if (registerRequestData.conversation_sid !== null) {
          setConversationId(registerRequestData.conversation_sid);
        }
      }
      setLoading(false);
    };

    getConversation();
  }, [responseData.AnimalId, jwtToken, openSucessAlert]);

  const handleOpenReviewModal = () => {
    setOpenReviewDialog(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSucessAlert(false);
  };
  const handleCloseReviewAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };
  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleOpen = () => {
    if (conversationId !== null) {
      navigate("/conversations", {
        state: { conversationId: conversationId },
      });
    } else {
      setOpen(true);
    }
  };

  const handleNavigateToLogin = () => {
    navigate("/auth/login");
  };

  return (
    <>
      <Snackbar
        open={openSucessAlert}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert onClose={handleClose} severity="success">
            Message Sent Successfully!
          </Alert>
        </div>
      </Snackbar>
      <Snackbar
        open={snackbarStates.open}
        autoHideDuration={3000}
        onClose={handleCloseReviewAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert
            onClose={handleCloseReviewAlert}
            severity={snackbarStates.severity}
          >
            {snackbarStates.message}
          </Alert>
        </div>
      </Snackbar>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <InquireDialog
            open={open}
            brandName={responseData.brandName}
            listData={listData}
            setOpen={setOpen}
            setOpenSucessAlert={setOpenSucessAlert}
          />
          <ReviewModal
            open={openReviewDialog}
            brandName={responseData.brandName}
            setReviewOpenSucessAlertText={setReviewOpenSucessAlertText}
            listData={listData}
            setOpen={setOpenReviewDialog}
            setReviewOpenSucessAlert={setReviewOpenSucessAlert}
            setSnackbarStates={setSnackbarStates}
          />
          <Paper
            elevation={2}
            sx={{
              border: "1px solid #eeeeee",
              marginY: "5px",
              borderRadius: "10px",
            }}
          >
            <Card
              sx={{
                bgcolor: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingBottom: "30px",
              }}
            >
              <div className={classes.imageContainer}>
                {listData.on_hold && (
                  <div className={listData.on_hold && classes.onHoldText}>
                    On Hold
                  </div>
                )}
                {(listData.status === "sold" || listData.status === "nfs") && (
                  <div
                    className={
                      (listData.status === "sold" &&
                        classes.soldOutStripeText) ||
                      (listData.status === "nfs" && classes.nfsStripeText)
                    }
                  >
                    {listData.status.toUpperCase()}
                  </div>
                )}
              </div>
              <Typography
                sx={{
                  textAlign: "left",
                  marginLeft: "40px",
                  marginRight: "40px",
                }}
              >
                <Typography
                  variant="h1"
                  fontFamily="Roboto"
                  sx={{
                    marginTop: "40px",
                    marginBottom: "4px",
                    fontSize: "32px",
                    fontWeight: "bolder",
                  }}
                >
                  {listData.name}
                </Typography>
                <Typography
                  variant="p"
                  fontFamily="Roboto"
                  sx={{
                    marginTop: "40px",
                    fontSize: "18px",
                    fontWeight: "400px",
                    letterSpacing: "0.01em",
                  }}
                >
                  {listData.category.name}
                </Typography>
                <br />
                <Typography
                  variant="p"
                  fontFamily="Roboto"
                  sx={{
                    marginTop: "40px",
                    fontSize: "14px",
                    fontWeight: "400px",
                    letterSpacing: "0.01em",
                  }}
                >
                  <em> {listData.breed.name}</em>
                </Typography>
                <Typography
                  variant="h1"
                  fontFamily="Roboto"
                  sx={{
                    marginTop: "20px",
                    marginRight: "40px",
                    marginBottom: "4px",
                    fontSize: "32px",
                    fontWeight: "bolder",
                  }}
                >
                  US ${responseData.Price}
                </Typography>

                <Grid container pt={4}>
                  <Typography
                    variant="p"
                    component={"p"}
                    fontFamily="Roboto"
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                    }}
                  >
                    <em> Sex:</em>
                  </Typography>
                  <Typography
                    variant="p"
                    component={"p"}
                    fontFamily="Roboto"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    {responseData.Sex != "pair" ? (
                      responseData.Sex == "male" ? (
                        <MaleIcon
                          fontSize="small"
                          sx={{ pr: 0.5, color: "blue" }}
                        />
                      ) : (
                        <FemaleIcon
                          fontSize="small"
                          sx={{ pr: 0.5, color: "#AA336A" }}
                        />
                      )
                    ) : (
                      <>
                        <MaleIcon
                          fontSize="small"
                          sx={{ pr: 0.5, color: "blue" }}
                        />
                        <FemaleIcon
                          fontSize="small"
                          sx={{ color: "#AA336A" }}
                        />
                      </>
                    )}{" "}
                  </Typography>
                </Grid>
                {responseData.Birth && (
                  <Grid container sx={{ mb: "4px" }}>
                    <Typography
                      variant="p"
                      component={"p"}
                      fontFamily="Roboto"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      <em> Birth:</em>
                    </Typography>
                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{
                        fontSize: "18px",
                        pl: 0.5,
                        fontFamily: "Roboto",
                      }}
                    >
                      {" "}
                      {formatDate(responseData.Birth)}{" "}
                    </Typography>
                  </Grid>
                )}
                {responseData.Age && (
                  <Grid container sx={{ mb: "4px" }}>
                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      <em>Age:</em>
                    </Typography>
                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{
                        fontSize: "18px",
                        pl: 0.5,
                        fontFamily: "Roboto",
                      }}
                    >
                      {`${
                        responseData.Age.years === -1
                          ? 0
                          : responseData.Age.years
                      } ${
                        responseData.Age.years === 1 ? "year " : "years "
                      }`}{" "}
                      {`${
                        responseData.Age.months === -1
                          ? 0
                          : responseData.Age.months
                      }  ${
                        responseData.Age.months === 1 ? "month " : "months "
                      } `}{" "}
                      {`${
                        responseData.Age.days === -1 ? 0 : responseData.Age.days
                      } ${responseData.Age.days === 1 ? "day " : "days "}`}
                    </Typography>
                  </Grid>
                )}

                {responseData.Length && (
                  <Grid container sx={{ mb: "4px" }}>
                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      <em> Length:</em>
                    </Typography>
                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{
                        fontSize: "18px",
                        pl: 0.5,
                        fontFamily: "Roboto",
                      }}
                    >
                      {responseData.Length}
                    </Typography>
                  </Grid>
                )}
                {responseData.Traits && (
                  <Grid container sx={{ mb: "4px" }}>
                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      <em> Traits:</em>
                    </Typography>

                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{ fontSize: "16px", pl: 0.5 }}
                    >
                      {responseData.Traits.map((tag) => (
                        <Chip
                          key={tag}
                          label={tag}
                          size="small"
                          sx={{
                            ml: "5px",
                            backgroundColor: "#FFD34E",
                            color: "#fff",
                            borderRadius: "6px",
                            fontWeight: "bold",
                            fontSize: "12px",
                            p: 0,
                          }}
                        />
                      ))}
                    </Typography>
                  </Grid>
                )}
                {responseData.Height && (
                  <Grid container sx={{ mb: "4px" }}>
                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      <em> Height:</em>
                    </Typography>
                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{
                        fontSize: "18px",
                        pl: 0.5,
                        fontFamily: "Roboto",
                      }}
                    >
                      {responseData.Height}
                    </Typography>
                  </Grid>
                )}

                {responseData.Weight && (
                  <Grid container sx={{ mb: "4px" }}>
                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      <em> Weight:</em>
                    </Typography>
                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{
                        fontSize: "18px",

                        pl: 0.5,
                        fontFamily: "Roboto",
                      }}
                    >
                      {responseData.Weight}
                    </Typography>
                  </Grid>
                )}
                {responseData.Color && (
                  <Grid container sx={{ mb: "4px" }}>
                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      <em> Color:</em>
                    </Typography>
                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{
                        fontSize: "18px",
                        pl: 0.5,
                        fontFamily: "Roboto",
                      }}
                    >
                      {responseData.Color}
                    </Typography>
                  </Grid>
                )}
                {responseData.Diet && (
                  <Grid container sx={{ mb: "4px" }}>
                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      <em> Diet:</em>
                    </Typography>
                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{
                        fontSize: "18px",
                        pl: 0.5,
                        fontFamily: "Roboto",
                      }}
                    >
                      {responseData.Diet}
                    </Typography>
                  </Grid>
                )}
                <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
                <Grid container sx={{ mb: "4px" }}>
                  <Typography
                    variant="p"
                    component={"p"}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bolder",
                      fontFamily: "Roboto",
                    }}
                  >
                    {" "}
                    <em> Payments:</em>
                  </Typography>
                  <Typography
                    variant="p"
                    component={"p"}
                    sx={{ fontSize: "18px", pl: 0.5, fontFamily: "Roboto" }}
                  >
                    USD
                  </Typography>
                </Grid>
                <Grid container sx={{ mb: "4px" }}>
                  <Typography
                    variant="p"
                    component={"p"}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bolder",
                      fontFamily: "Roboto",
                    }}
                  >
                    <em> Shipping:</em>
                  </Typography>
                  <Typography
                    variant="p"
                    component={"p"}
                    sx={{ fontSize: "18px", pl: 0.5, fontFamily: "Roboto" }}
                  >
                    {responseData.maxPrice &&
                      responseData.minPrice &&
                      responseData.minPrice + "-" + responseData.maxPrice}
                    {responseData.maxPrice &&
                      responseData.minPrice == null &&
                      "Max " + responseData.maxPrice}
                    {responseData.maxPrice == null &&
                      responseData.minPrice &&
                      "Starting from " + responseData.minPrice}
                    {responseData.maxPrice == null &&
                      responseData.minPrice == null &&
                      "Please inquire from the store "}
                    &nbsp;(Domestic)
                  </Typography>
                </Grid>
                <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
                <Grid container>
                  <Typography
                    variant="p"
                    component={"p"}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bolder",
                      fontFamily: "Roboto",
                    }}
                  >
                    {" "}
                    <em> First Posted:</em>
                  </Typography>
                  <Typography
                    variant="p"
                    component={"p"}
                    sx={{ fontSize: "18px", pl: 0.5, fontFamily: "Roboto" }}
                  >
                    {responseData.FirstPosted}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography
                    variant="p"
                    component={"p"}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bolder",
                      fontFamily: "Roboto",
                    }}
                  >
                    {" "}
                    <em> Last Updated:</em>
                  </Typography>
                  <Typography
                    variant="p"
                    component={"p"}
                    sx={{ fontSize: "18px", fontFamily: "Roboto", pl: 0.5 }}
                  >
                    {responseData.LastUpdated}
                  </Typography>
                </Grid>
                <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />

                {responseData.store && (
                  <Grid container mb={4} pt={1}>
                    <Grid item xs={5} mr={2}>
                      <Link
                        to={"/neighbour/" + responseData.storeId}
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <img
                          alt={responseData.img}
                          src={responseData.img}
                          key={responseData.img}
                          onClick={(e) => {}}
                          style={{
                            width: "90%",
                            height: "100%",
                            cursor: "pointer",
                            objectFit: "contain",
                          }}
                        />
                      </Link>
                    </Grid>

                    <Grid xs={6}>
                      <Link
                        to={"/neighbour/" + responseData.storeId}
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#FFC001",
                            fontWeight: "bold",
                            fontFamily: "Roboto",
                            fontSize: "18px",
                          }}
                        >
                          {responseData.brandName}
                        </Typography>
                      </Link>
                      <Grid container sx={{ mt: 2 }}>
                        <Box display="flex" alignItems="flex-end">
                          <FmdGoodIcon
                            fontSize="small"
                            fontFamily="Roboto"
                            sx={{
                              marginLeft: "auto",
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginRight: "8px",
                              color: "#D89700",
                            }}
                          />
                          <Typography
                            gutterTop
                            sx={{
                              fontWeight: "bold",
                              fontSize: "18px",
                              fontFamily: "Roboto",
                            }}
                          >
                            {" "}
                            {responseData.Location}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid container sx={{ mt: 2 }}>
                        <Box display="flex" alignItems="flex-end">
                          <LocalShippingIcon
                            fontSize="small"
                            fontFamily="Roboto"
                            sx={{
                              marginLeft: "auto",
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginRight: "8px",
                              color: "#D89700",
                            }}
                          />
                          <Typography
                            gutterTop
                            sx={{
                              fontWeight: "bold",
                              marginTop: "auto",
                              fontSize: "18px",
                              fontFamily: "Roboto",
                            }}
                          >
                            {responseData.shippingType === "regional_pickup"
                              ? "Regional Pickup"
                              : "Local Pickup"}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Typography>
              <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
                {!user && (
                  <Box
                    component={Button}
                    size="large"
                    variant="contained"
                    sx={{
                      textAlign: "center",
                      marginTop: "10px",
                      bgcolor: "#FFC001",
                      width: { xs: "70vw", md: "30vw" },
                      height: 40,
                      color: "#fff",
                      fontSize: 18,
                      fontWeight: 700,
                      fontFamily: "Roboto",
                      textTransform: "none !important",
                      transition: "0.3s all",
                    }}
                    onClick={handleNavigateToLogin}
                  >
                    <LoginIcon
                      fontSize="small"
                      sx={{
                        mr: 1,
                      }}
                    ></LoginIcon>
                    Login/Sign Up
                  </Box>
                )}
                {user &&
                  user.id !== responseData.userId &&
                  !hideInquireToBuy && (
                    <Box
                      component={Button}
                      size="large"
                      variant="contained"
                      sx={{
                        textAlign: "center",
                        marginTop: "10px",
                        bgcolor: "#FFC001",
                        width: { xs: "70vw", md: "30vw" },
                        height: 40,
                        color: "#fff",
                        fontSize: 18,
                        fontWeight: 700,
                        fontFamily: "Roboto",
                        textTransform: "none !important",
                        transition: "0.3s all",
                      }}
                      onClick={handleOpen}
                    >
                      <MailIcon
                        fontSize="small"
                        sx={{
                          mr: 1,
                        }}
                      ></MailIcon>
                      {conversationId ? "Go to Conversation" : "Inquire to Buy"}
                    </Box>
                  )}
                {conversationId && (
                  <Button
                    onClick={handleOpenReviewModal}
                    size="large"
                    variant="outlined"
                    sx={{
                      textAlign: "center",
                      fontSize: 18,
                      marginTop: "10px",
                      fontFamily: "Roboto",
                      border: "1px solid #FFC001",
                      color: "#FFC001",
                      width: { xs: "70vw", md: "30vw" },
                      transition: "0.3s all",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "rgb(255, 192, 1)",
                        color: "#000",
                      },
                      fontWeight: 600,
                    }}
                  >
                    <BorderColorIcon
                      fontSize="small"
                      sx={{
                        mr: 1,
                      }}
                    />
                    Review Seller
                  </Button>
                )}

                <Button
                  component={Link}
                  to={"/listings/classes/" + responseData.categoryId}
                  size="large"
                  variant="outlined"
                  sx={{
                    textAlign: "center",
                    marginTop: "10px",
                    border: "1px solid #FFC001",
                    fontSize: 18,
                    fontFamily: "Roboto",
                    color: "#FFC001",
                    width: { xs: "70vw", md: "30vw" },
                    transition: "0.3s all",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "rgb(255, 192, 1)",
                      color: "#000",
                    },
                    fontWeight: 600,
                  }}
                >
                  <GridViewIcon
                    fontSize="small"
                    sx={{
                      mr: 1,
                    }}
                  />
                  More Like this
                </Button>
                {responseData.storeId && (
                  <Button
                    component={Link}
                    to={"/listings/seller/" + responseData.sellerId}
                    size="large"
                    variant="outlined"
                    sx={{
                      textAlign: "center",
                      fontSize: 18,
                      marginTop: "10px",
                      fontFamily: "Roboto",
                      border: "1px solid #FFC001",
                      color: "#FFC001",
                      width: { xs: "70vw", md: "30vw" },
                      transition: "0.3s all",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "rgb(255, 192, 1)",
                        color: "#000",
                      },
                      fontWeight: 600,
                    }}
                  >
                    <GridViewIcon
                      fontSize="small"
                      sx={{
                        mr: 1,
                      }}
                    />
                    More from this Store
                  </Button>
                )}
              </Box>
            </Card>
          </Paper>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles({
  imageContainer: {
    position: "relative",
    width: "100%",
  },
  onHoldText: {
    position: "absolute",
    top: 140,
    right: "-7%",
    width: "100%",
    textAlign: "end",
    paddingRight: "25%",
    backgroundColor: "#FFD300",
    color: "#fff",
    padding: "10px",
    fontSize: "18px",
    transform: "translate(-0%, 100%) rotate(45deg)",
    transformOrigin: "top right",
  },
  soldOutStripeText: {
    position: "absolute",
    top: 140,
    right: "-7%",
    width: "100%",
    textAlign: "end",
    paddingRight: "25%",
    backgroundColor: "#EF0107",
    color: "#fff",
    padding: "10px",
    fontSize: "18px",
    transform: "translate(-0%, 100%) rotate(45deg)",
    transformOrigin: "top right",
  },
  nfsStripeText: {
    position: "absolute",
    top: 140,
    right: "-7%",
    width: "100%",
    textAlign: "end",
    paddingRight: "25%",
    backgroundColor: "black",
    color: "#fff",
    padding: "10px",
    fontSize: "18px",
    transform: "translate(-0%, 100%) rotate(45deg)",
    transformOrigin: "top right",
  },
});

const mapStateToProps = ({ Seller, Auth, Store }) => ({
  seller: Seller.seller,
  jwtToken: Auth.jwtToken,
  user: Auth.user,
  store: Store.store,
});

const mapDispatchToProps = (dispatch) => ({
  loadStore: (storeId) => dispatch(Actions.thunkGetStore(storeId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ListDetailsCard);
