import {
  Box,
  Stack,
  Button,
  Chip,
  Typography,
  ButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { CircularProgress, TablePagination, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { breedsRaw } from "../../components/listing/breedsRaw";
import CardListing from "../../components/listing/CardListing";
import * as Actions from "../../redux/actions";
import Categories from "../../components/home/HomeCategories";
import IconButton from "@mui/material/IconButton";
import { useLocation } from "react-router-dom";
import TuneIcon from "@mui/icons-material/Tune";
import FilterModal from "./FilterModal";
import { useNavigate } from "react-router-dom";
import ListView from "./ListView";
import { Filter } from "@mui/icons-material";
import PetsIcon from "@mui/icons-material/Pets";
import ConstructionIcon from "@mui/icons-material/Construction";
import { Switch, FormControlLabel, Alert } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
import { Helmet } from "react-helmet-async";
import CloseIcon from "@mui/icons-material/Close";

const ProductListing = ({
  classes,
  productListings,
  loading,
  loadListings,
  totalListing,
  jwtToken,
  error,
}) => {
  const params = useParams();
  const styleclasses = useStyles();
  const filter = params.genetics ? params.genetics : "All";
  const [selected, setSelected] = useState(filter);
  const [breedsFilter, setBreedsFilter] = useState(breedsRaw);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [featuredListings, setFeaturedListings] = useState([]);
  const [open, setOpen] = useState(false);
  const productBackgroundColor = "#D89700";
  const navigate = useNavigate();
  const location = useLocation();
  const [filters, setFilter] = useState([]);
  const categoryFilter = location.state;
  const [view, setView] = useState("Grid");

  const queryParams = new URLSearchParams(location.search);
  const isFeatured = queryParams.get("is_featured");
  const searchQuery = queryParams.get("search");

  const [snackbarStates, setSnackbarStates] = useState({
    open: false,
    message: "",
    severity: "",
  });

  useEffect(() => {
    if (error != "") {
      setSnackbarStates({
        open: true,
        message: error,
        severity: "danger",
      });
    }
  }, [error]);

  useEffect(() => {
    loadListings({
      pageNumber,
      rowsPerPage,
      breedId: params.breedId,
      categoryId: params.categoryId,
      sellerId: params.sellerId,
      subbreedId: params.subBreedId,
      geneticId: params.geneticsId,
      isFeatured: isFeatured,
      jwtToken: jwtToken ? jwtToken : null,
      searchQuery: searchQuery ? searchQuery : null,
    });
  }, [
    pageNumber,
    rowsPerPage,
    params.breedId,
    params.sellerId,
    params.categoryId,
    loadListings,
    jwtToken,
    isFeatured,
    searchQuery,
  ]);

  useEffect(() => {
    if (selected === "All") {
      setBreedsFilter(breedsRaw);
    } else {
      const breedFilter = breedsRaw.filter(
        (breed) => breed.category === selected
      );
      setBreedsFilter(breedFilter);
    }
  }, [params.genetics, selected]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStates({ ...snackbarStates, open: false });
  };
  const handlePageChange = (event, page) => {
    setPageNumber(page);
  };

  const handleRowsPerChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  const openDialog = () => {
    setOpen(true);
  };

  const handleShowAnimalListings = () => {
    navigate("/listings");
  };

  const handleShowServicesListings = () => {
    navigate("/services/listings");
  };

  const handleChange = (event) => {
    navigate("/services/listings");
  };

  const handleGridView = () => {
    setView("Grid");
  };

  const handleListView = () => {
    setView("List");
  };

  return (
    <>
      <Helmet>
        <meta
          property="og:image"
          content="https://prodappdata.s3.amazonaws.com/preview-images/animal-listings-preview.png"
        />
        <meta property="og:title" content="Animal Listings" />
        <meta
          property="og:description"
          content="Moore Exotics Animal Listings"
        />
      </Helmet>
      <Snackbar
        open={snackbarStates.open}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseAlert}
          variant="filled"
          sx={{ width: "100%" }}
          severity={snackbarStates.severity}
        >
          {snackbarStates.message}
        </Alert>
      </Snackbar>
      <FilterModal open={open} setOpen={setOpen} setFilter={setFilter} />
      <Box
        style={{ flex: 1, justifyContent: "center", display: "flex" }}
        pt={2}
      >
        <ButtonGroup
          variant="contained"
          aria-label="Disabled elevation buttons"
        >
          <Button
            sx={{ color: "white", fontWeight: "bold" }}
            startIcon={<PetsIcon sx={{ color: "white" }} />}
            onClick={handleShowAnimalListings}
          >
            Animal Listings
          </Button>
          <Button
            sx={{
              color: "white",
              backgroundColor: "#D3D3D3",
              "&:hover": {
                backgroundColor: "#D3D3D3", // Customize the hover background color
              },
            }}
            onClick={handleShowServicesListings}
            startIcon={<ConstructionIcon sx={{ color: "white" }} />}
          >
            Service Listings
          </Button>
        </ButtonGroup>
      </Box>
      <Box justifyContent={"center"} display={"flex"}>
        <FormControlLabel
          control={<Switch checked={false} onChange={handleChange} />}
        />
      </Box>
      <Categories classes={classes} page={"Listing"} />
      <Box display="flex" flex={1}>
        <Box
          display="flex"
          flex={0.8}
          flexWrap={"wrap"}
          ml={{ md: 10 }}
          sx={{ maxWidth: "80%" }}
        >
          {searchQuery && (
            <>
              <Chip
                label={
                  <Typography noWrap sx={{ maxWidth: { xs: "235px" } }}>
                    Search results for "{searchQuery}"
                  </Typography>
                }
                size="small"
                sx={{
                  ml: "5px",
                  mt: "5px",
                  backgroundColor: "#FFC001",
                  color: "#fff",
                  borderRadius: "6px",
                  fontWeight: "bold",
                  fontSize: "12px",
                  maxWidth: "50%",
                  wordBreak: "break-word",
                  p: 0,
                }}
              />
              <CloseIcon
                sx={{
                  mt: 0.6,
                  fontSize: { xs: "22px", md: "27px" },
                  color: "rgb(255, 192, 1)",
                }}
                onClick={() => navigate("/listings")}
              />
            </>
          )}
          {filters.length > 0 &&
            filters.map((tag, index) => (
              <Chip
                key={index}
                label={
                  <Typography noWrap sx={{ maxWidth: { xs: "235px" } }}>
                    {tag}
                  </Typography>
                }
                size="small"
                sx={{
                  ml: "5px",
                  mt: "5px",
                  backgroundColor: "#FFC001",
                  color: "#fff",
                  borderRadius: "6px",
                  fontWeight: "bold",
                  fontSize: "12px",
                  maxWidth: "50%",
                  wordBreak: "break-word",
                  p: 0,
                }}
              />
            ))}
        </Box>
        <Box display="flex" justifyContent="flex-end" flex={0.2}>
          <IconButton aria-label="delete" onClick={handleGridView}>
            <GridViewIcon sx={{ color: view === "Grid" && "#FFC001" }} />
          </IconButton>{" "}
          <IconButton aria-label="delete" onClick={handleListView}>
            <ListIcon sx={{ color: view !== "Grid" && "#FFC001" }} />
          </IconButton>{" "}
          <Button
            type="submit"
            variant="contained"
            startIcon={<TuneIcon sx={{ color: "white" }} />}
            onClick={openDialog}
            sx={{
              marginBottom: "8px !important",
              marginRight: { md: "100px", xs: "15px" },
              height: "40px",
              color: "white",
              fontSize: "15px !important",
              fontWeight: "400 !important",
              textTransform: "none !important",
              width: "100px",
              "&:hover": {
                backgroundColor: "rgb(255, 192, 1)",
                color: "white !important",
              },
            }}
          >
            FILTER
          </Button>
        </Box>
      </Box>
      <Stack sx={{ marginX: { xs: "20px", md: "100px" } }}>
        {loading ? (
          <Box
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {view === "Grid" ? (
              <CardListing
                listData={productListings}
                classes={classes}
                view={"listing tab"}
              />
            ) : (
              <ListView listData={productListings} classes={classes} />
            )}
          </>
        )}
        {productListings?.length > 0 && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ bottom: 0 }}
          >
            {" "}
            {totalListing && (
              <TablePagination
                component="div"
                count={totalListing}
                page={pageNumber}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerChange}
                labelRowsPerPage="Listings per page"
              />
            )}
          </Box>
        )}
      </Stack>
    </>
  );
};

const useStyles = makeStyles({
  switchBase: {
    // justifyContent: "flex-end",
  },
});

const mapStateToProps = ({ Categories, Listings, Auth }) => ({
  classes: Categories.classes,
  productListings: Listings.listings,
  loading: Listings.loading,
  totalListing: Listings.totalListing,
  jwtToken: Auth.jwtToken,
  error: Listings.errorListings,
});

const mapDispatchToProps = (dispatch) => ({
  loadListings: ({
    pageNumber,
    rowsPerPage,
    breedId,
    categoryId,
    sellerId,
    isFeatured,
    jwtToken,
    subbreedId,
    geneticId,
    searchQuery,
  }) =>
    dispatch(
      Actions.thunkLoadListing({
        pageNumber,
        rowsPerPage,
        breedId,
        categoryId,
        sellerId,
        isFeatured,
        jwtToken,
        subbreedId,
        geneticId,
        searchQuery,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductListing);
