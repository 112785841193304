import axios from "axios";
import * as Types from "../types/";
import config from "../../config";

export const setStore = (payload) => ({
  type: Types.SET_STORE,
  payload,
});

export const thunkGetStore = (storeId) => async (dispatch) => {
  let registerRequest;
  try {
    registerRequest = await axios.get(
      `${config.SERVER_URL}/api/v1/stores/${storeId.storeId}`
    );
    const { data } = registerRequest;

    if (registerRequest.status === 200 && data) {
      dispatch(setStore(data.data.store));
    }
  } catch ({ response }) {
    console.log("not Seller");
  }
};
