const serviceIcon = [
  //   "https://stagingappdata.s3.amazonaws.com/ICONS/Group(1).png",
  "https://stagingappdata.s3.amazonaws.com/ICONS/Group(3).png",
  "https://stagingappdata.s3.amazonaws.com/ICONS/Group(2).png",
  "https://stagingappdata.s3.amazonaws.com/ICONS/Group(4).png",
  "https://stagingappdata.s3.amazonaws.com/ICONS/Group(5).png",
  "https://stagingappdata.s3.amazonaws.com/ICONS/Group(6).png",
  "https://stagingappdata.s3.amazonaws.com/ICONS/Group(7).png",
  "https://stagingappdata.s3.amazonaws.com/ICONS/Group(9).png",
  "https://stagingappdata.s3.amazonaws.com/ICONS/Group(8).png",
  "https://stagingappdata.s3.amazonaws.com/ICONS/Group(10).png",
  "https://stagingappdata.s3.amazonaws.com/ICONS/Group(11).png",
  "https://stagingappdata.s3.amazonaws.com/ICONS/Group(12).png",
  "https://stagingappdata.s3.amazonaws.com/ICONS/Group(13).png",
  "https://stagingappdata.s3.amazonaws.com/ICONS/Group(14).png",
];
export default serviceIcon;
